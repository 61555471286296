import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'アボート',
    cancel: 'キャンセル',
    caption: 'エラー',
    close: '閉じる',
    confirm: '確認する',
    dont_save: '保存しない',
    dont_show_again: '次回から表示しない',
    duplicated_preset_name: '重複したプリセット名',
    error: 'おっと',
    info: '情報',
    instruction: 'ヒント',
    learn_more: 'もっと詳しく知る',
    no: 'いいえ',
    ok: 'OK',
    ok2: 'はい',
    oops: 'おっと',
    retry: '再試行',
    save: '保存する',
    stop: '停止',
    warning: '警告',
    yes: 'はい',
  },
  auto_fit: {
    artwork_size: 'アートワークのサイズ',
    error_tip1: '1. アートワークが素材に正しく配置されていますか？',
    error_tip2: '2. 素材の輪郭は認識できるほど明確ですか？',
    failed_to_find_contour: '自動適合のための輪郭が見つかりませんでした',
    learn_more: 'チュートリアルを表示します。',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'オフセット X',
    offset_y: 'オフセット Y',
    pattern_size: 'パターンのサイズ',
    position_artwork: 'アートワークを配置',
    position_step1: 'アートワークのサイズと位置をパターン上で調整します。',
    position_step2: '「適用する」をクリックして、このパターンにオートフィットを適用します。',
    preview_first: '自動フィットの前にカメラプレビューを行ってください。',
    reset_position: '位置をリセット',
    rotation: '回転',
    select_a_pattern: 'パターンを選択',
    selected_artwork: '選択されたアートワーク',
    step1: 'アートワークを配置したいパターンを選択します。',
    step2: 'パターンが見つからない場合は、カメラプレビューをもう一度行い、「自動フィット」を適用してください。',
    step3: 'それでも正しいパターンが見つからない場合は、作業対象物がはっきりと認識できる状態であること、および作業エリアがゴミや汚れがない状態であることを確認してください。',
    title: '自動フィット',
  },
  barcode_generator: {
    bar_height: 'バーの高さ',
    bar_width: 'バー幅',
    barcode: {
      invalid_value: '選択した形式に対して値が無効です。',
    },
    font: 'フォント',
    font_size: 'フォントサイズ',
    hide_text: 'テキストを隠す',
    invert_color: '色を反転',
    text_margin: 'テキスト余白',
  },
  beambox: {
    announcement_panel: {
      title: 'お知らせ',
    },
    banner: {
      auto_feeder: 'オートフィーダーモード',
      camera_preview: 'プレビュー',
      camera_preview_borderless_mode: '(オープンボトム)',
      curve_engraving: 'カーブ彫刻モード',
      pass_through: 'パススルーモード',
      rotary: 'ロータリーモード',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'テキストをパスに変換…',
      export_file_error_ask_for_upload: 'Failed to export task. Are you willing to provide working scene to develop team for bug report?',
      retreive_image_data: '画像データを読み出す…',
    },
    context_menu: {
      copy: 'コピー',
      cut: '切り取り',
      delete: '削除',
      duplicate: '複製',
      group: 'グループ化',
      move_back: '最後に送る',
      move_down: '後に送る',
      move_front: '最前に持ってくる',
      move_up: '前に持ってくる',
      paste: '貼り付け',
      paste_in_place: '所定の位置に貼り付け',
      ungroup: 'グループ化解除',
    },
    document_panel: {
      add_on: 'アドオン',
      auto_feeder: 'オートフィーダー',
      auto_feeder_scale: 'オートフィーダースケール',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'ボーダーレスモード',
      current_position: '現在の位置',
      disable: '無効',
      document_settings: 'ドキュメント設定',
      enable: '有効',
      enable_autofocus: 'オートフォーカス',
      enable_diode: 'ダイオードレーザー',
      engrave_dpi: '解像度',
      frame_before_start: '実行前にフレーム',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: '高',
      job_origin: 'ジョブの原点',
      laser_source: 'レーザー源',
      low: '低',
      machine: '機械',
      medium: '中',
      notification: {
        changeFromPrintingWorkareaTitle: '印刷レイヤーをレーザーレイヤーに変換しますか？',
      },
      origin: '原点',
      pass_through: 'パススルー',
      pass_through_height_desc: '作業エリアを拡張するためにオブジェクトの長さを入力してください。',
      rotary_mode: 'ロータリー',
      scale: 'スケール',
      start_from: '開始位置',
      start_position: '開始位置',
      start_work_button: '実行ボタン',
      ultra: '超高',
      workarea: '作業領域',
    },
    image_trace_panel: {
      back: '戻る',
      brightness: '輝度',
      cancel: 'キャンセル',
      contrast: 'コントラスト',
      next: '次へ',
      okay: 'OK',
      threshold: 'しきい値',
      tuning: 'パラメーター',
    },
    layer_color_config_panel: {
      add: '追加する',
      add_config: '色を追加',
      color: '色',
      default: 'デフォルトにリセット',
      in_use: 'この色は使用中です。',
      layer_color_config: 'レイヤーの色設定',
      no_input: '16進数の有効なカラーコードを入力してください。',
      power: '出力',
      repeat: '実行',
      speed: '速度',
      sure_to_delete: 'この色の設定を削除してもよろしいですか？',
      sure_to_reset: 'カスタマイズされたパラメータはすべて失われます。本当にデフォルトにリセットしますか？',
    },
    left_panel: {
      borderless_blind_area: '非彫刻エリア',
      borderless_preview: 'ボーダーレスモードのカメラプレビュー',
      diode_blind_area: 'ハイブリッドアドオンのブラインドエリア',
      ellipse: '楕円',
      image: '画像',
      label: {
        adjust_height: '高さ調整',
        array: '配列',
        boxgen: 'Boxgen',
        choose_camera: 'カメラ',
        clear_preview: 'プレビューをクリア',
        cursor: '選択する',
        curve_engraving: {
          clear_area: '選択したエリアをクリア',
          exit: '終了',
          preview_3d_curve: 'カーブ彫刻のプレビュー',
          select_area: 'エリアを選択',
          title: 'カーブ彫刻',
        },
        end_preview: 'プレビューを終了',
        line: 'ライン',
        live_feed: 'ライブフィード',
        my_cloud: 'マイクラウド',
        oval: '楕円形',
        pass_through: 'パススルー',
        pen: 'ペン',
        photo: '画像',
        polygon: '多角形',
        preview: 'カメラプレビュー',
        qr_code: 'QRコード',
        rect: '長方形',
        shapes: '要素',
        text: 'テキスト',
        trace: 'トレース画像',
      },
      line: 'ライン',
      rectangle: '長方形',
      text: 'テキスト',
      unpreviewable_area: 'ブラインドエリア',
    },
    network_testing_panel: {
      average_response: '平均応答時間',
      cannot_connect_1: '#840 ターゲットIPに接続することができません。',
      cannot_connect_2: '#840 ターゲットIPに接続することができません。ターゲットが同じネットワークにあることを確認してください。',
      cannot_get_local: 'ローカルIPアドレスへのアクセスに失敗しました。',
      connection_quality: '接続品質',
      device_not_on_list: '#842 マシンはリストに含まれていませんが、接続品質 > 70、平均応答時間 <100ms',
      empty_ip: '#818 最初にターゲットデバイスのIPを入力してください。',
      end: '終了',
      fail_to_start_network_test: '#817 ネットワーク試験を開始できません。',
      hint_connect_camera_timeout: 'カメラのプレビューを開始するとタイムアウトが発生しますか？',
      hint_connect_failed_when_sending_job: 'タスクの送信時に接続に失敗しましたか？',
      hint_device_often_on_list: 'マシンがリストに見つからないことがよくありますか？',
      insert_ip: 'ターゲットデバイスのIPアドレス：',
      invalid_ip: '#818 無効なIPアドレス',
      ip_startswith_169: '#843 マシンのIPアドレスは169.254で始まります',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'このエラーは通常、権限が不十分なために発生します。\nターミナルで「sudo beam-studio --no-sandbox」を実行して、権限を取得し、ネットワークテストを実行してください。',
      local_ip: 'ローカルIPアドレス：',
      network_testing: 'ネットワーク試験',
      network_unhealthy: '#841 接続品質 <　70 または 平均応答時間 > 100ms',
      start: '開始',
      test_completed: 'テストが完了しました',
      test_fail: 'テストに失敗しました',
      testing: 'ネットワークの試験中…',
    },
    object_panels: {
      lock_desc: '幅と高さの比率を保持（SHIFT）',
      text_to_path: {
        check_thumbnail_warning: 'テキストをパスに解析するときに一部の書体が他のフォントに変更され、一部の文字が正常に変換されないことがあります.\nタスクを送信する前に、プレビュー画像をもう一度確認してください。',
        error_when_parsing_text: 'テキストをパスに変換するときにエラーが発生しました',
        font_substitute_pop: 'テキストに現在のフォントではサポートされていない文字が含まれています。<br/>代わりに<strong>%s</strong>を使用しますか?',
        retry: '後で再試行するか、別のフォントを選択してください',
        use_current_font: '現在のフォントを使用',
      },
      wait_for_parsing_font: 'フォントの解析中です… 少々お待ちください',
    },
    path_preview: {
      current_position: '現在位置',
      cut_distance: '切断距離',
      cut_time: '切断時間',
      end_preview: 'プレビュー終了',
      estimated_time: '推定合計時間',
      invert: '色反転',
      pause: '一時停止',
      play: '再生',
      play_speed: '再生スピード',
      preview_info: 'プレビュー',
      rapid_distance: '快速移動距離',
      rapid_time: '快速移動時間',
      remark: '＊全てのパラメータは推定値であり、ご参考までに。',
      size: 'サイズ',
      start_here: 'ここから始める',
      stop: '停止',
      travel_path: '運行軌道',
    },
    photo_edit_panel: {
      aspect_ratio: 'アスペクト比',
      brightness: '輝度',
      brightness_and_contrast: '輝度 / コントラスト',
      cancel: 'キャンセル',
      circumference: '円周',
      compare: '比較する',
      contrast: 'コントラスト',
      crop: '切り抜き',
      curve: '曲線',
      diameter: '直径',
      free: '任意',
      invert: '色を反転',
      okay: 'OK',
      original: '元のサイズ',
      phote_edit: '写真編集',
      processing: '処理中',
      radius: '半径',
      rotary_warped: '回転ゆがみ',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'シャープにする',
      sharpness: 'シャープネス',
      start: '開始',
      warp: 'ゆがみ',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: '行く',
        insufficient_credit: 'クレジットが不足しています',
        insufficient_credit_msg: '%s は使用できません。メンバー センターにアクセスして AI Credit を購入してください。',
        relogin_to_use: 'この機能を使用するには再度ログインしてください。',
      },
      auto_feeder_origin: 'フレーミングプレビューを使用してレーザーヘッドの軌道と範囲を確認し、結果に基づいて材料の配置や彫刻の開始位置を調整して、彫刻プロセス中の衝突を回避してください。',
      auto_switch_tab: {
        message: 'レイヤーパネルとオブジェクトパネルの間を自動的に切り替える新しいオプションが環境設定に追加されました。このオプションはデフォルトではオフになっています。自動切り替えを今すぐオンにしますか？ <br/>この設定はいつでも環境設定で変更できます。',
        title: 'レイヤーとオブジェクトパネルの自動切り替え',
      },
      backend_connect_failed_ask_to_upload: '#802 バックエンドに接続しようとすると、エラーが発生し続けます。バグレポートログをアップロードしますか？',
      backend_error_hint: 'バックエンドのエラーのため、機能が正しく動作しない可能性があります。',
      both_power_and_speed_too_high: 'より低いレーザー出力を使用すると、レーザー管の寿命が延びます。\nまた、この解像度で速度が速すぎると、シェーディング彫刻の品質が低下する場合があります。',
      bug_report: 'バグ報告',
      change_workarea_before_preview: '%sのワークエリアが、現在設定されているワークエリアと一致しません。現在のワークエリアを切り替えますか？',
      convert_to_path_fail: 'パスへの変換に失敗しました。',
      dxf_bounding_box_size_over: '図面サイズがワークエリア外です。図面をCADソフトウェアの原点に近づけるか、単位が正しく設定されていることを確認してください。',
      dxf_version_waring: 'このDXFファイルのバージョンは2013ではありません。非互換性の問題が発生する可能性があります。',
      facebook_group_invitation: {
        already_joined: 'すでに参加済み',
        join_now: '今すぐ参加する',
        later: '後で参加する',
        message: '公式Facebookグループに参加して、他のFLUXレーザーユーザーとつながり、FLUXレーザーについて語り合い、レーザーアート作品を共有し、製品に関する最新情報を入手しませんか？ご参加をお待ちしております！',
        title: 'FLUX公式ユーザーグループへの参加',
      },
      import_file_contain_invalid_path: '#808 インポートされたSVGファイルに無効は画像パスが含まれています。すべての画像ファイルが存在することを確認するか、ファイルに画像を埋め込んでください',
      import_file_error_ask_for_upload: 'インポートされたSVGファイルに失敗しました。バグレポート用のチームを開発するためのファイルを提供してもよろしいですか？',
      layer_by_color: '色',
      layer_by_layer: '層',
      loading_image: '画像を読み込んでいます。お待ちください…',
      more_than_two_object: 'オブジェクトが多すぎます。2つのオブジェクトのみをサポートします',
      mouse: 'マウス',
      no_support_text: 'Beam Studioは現在、テキストタグをサポートしていません。インポートする前にテキストをパスに変換してください。',
      nolayer: '単一層',
      not_support_object_type: 'オブジェクトタイプをサポートしていません',
      or_turn_off_borderless_mode: ' または、ボーダーレスモードをオフにします。',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 エラー：コマンド pdf2svg が見つかりません。パッケージマネージャーで pdf2svg をインストールしてください（例："yum install pdf2svg" or "apt-get install pdf2svg"）。',
        error_when_converting_pdf: '#824 エラー：PDFをSVGに変換するときのエラー：',
      },
      progress: {
        calculating: '計算中',
        uploading: 'アップロード中',
      },
      questionnaire: {
        caption: 'アンケートへの記入にご協力ください',
        message: 'Beam Studioを改善するために、アンケートへの記入にご協力ください。',
        no_questionnaire_available: '現在、利用可能なアンケートはありません。',
        unable_to_get_url: 'インターネット経由でアンケートへのリンクを取得できませんでした。インターネット接続を確認してください。',
      },
      recommend_downgrade_software: '古いファームウェアバージョンが検出されました。私たちは現在、互換性の問題に積極的に取り組んでいますが、今のところ<a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>に戻すことをお勧めします。',
      recommend_upgrade_firmware: '古いファームウェアバージョンを検出しました。現在、互換性の問題に積極的に取り組んでいますが、今のところ、最新のファームウェアにアップデートすることをお勧めします。',
      save_unsave_changed: '未保存の変更を保存しますか？',
      select_at_least_two: '2つのオブジェクトを選択して続行します',
      select_first: '最初にオブジェクトを選択します。',
      select_import_method: '積層スタイルを選択：',
      select_import_module: 'モジュールを選択：',
      sentry: {
        message: 'エラーが発生したときに、関連情報を開発チームに自動的にアップロードすることに同意しますか？',
        title: '一緒にBeamStudioをもっと良くしましょう',
      },
      should_update_firmware_to_continue: '#814 ご使用のファームウェアは、Beam Studioの一部の改善をサポートしていません。パフォーマンスとユーザー体験を向上させるには、ファームウェアをアップデートして続行してください。（メニュー>マシン>[お使いのマシン]>ファームウェアのアップデート',
      speed_too_high_lower_the_quality: 'この解像度で速すぎる速度を使用すると、シェーディング彫刻の品質が低下する場合があります。',
      still_continue: '続行',
      successfully_uploaded: 'ファイルのアップロードに成功しました。',
      svg_1_1_waring: 'このSVGファイルのバージョンはv 1.1です。非互換性の問題が発生する可能性があります。',
      svg_image_path_waring: 'このSVGファイルには、ファイルパスからロードされた<image>が含まれています。ロード時に失敗する可能性があります。\nこの可能性を回避するには、SVGをエクスポートするときに埋め込み画像（embed）を使用してください。',
      text_to_path: {
        caption: 'テキストからパスへの変換 2.0',
        message: 'Beam Studioに新しいテキストからパスへの変換機能(変換 2.0)が追加されました。これにより、より信頼できる結果が得られます。今すぐ変換を切り替えますか?\n「テキストからパスへの変換」の設定セクションから、後でこの設定を調整することもできます。',
      },
      too_fast_for_auto_feeder: 'パスオブジェクトを含むレイヤーで速度が高すぎると、切断精度が低下する可能性があります。\nオートフィーダーで切断する際は、%(limit)sより速い速度はお勧めしません。',
      too_fast_for_auto_feeder_and_constrain: '以下のレイヤー：%(layers)s \nにはベクトルパスオブジェクトが含まれ、速度が%(limit)sを超えています。\nオートフィーダーでのベクトルパスオブジェクトの切断速度は%(limit)sに制限されます。\nこの制限は設定の「優先設定」から解除できます。',
      too_fast_for_curve: '曲面彫刻オブジェクトを含むレイヤーの速度が高すぎると、彫刻の精度が低下する可能性があります。\n最適な結果を得るために、速度を%(limit)s以下に保つことをお勧めします。',
      too_fast_for_curve_and_constrain: '次のレイヤー: %(layers)s は速度制限%(limit)sを超えています。\n曲面オブジェクトの彫刻速度は%(limit)sに制限されます。この制限は設定で解除できます。',
      too_fast_for_path: 'パスオブジェクトを含む層で速すぎる速度を使用すると、切断時に精度が低下する場合があります。\n切断時に %(limit)s より速い速度を使用することはお勧めしません。',
      too_fast_for_path_and_constrain: '次のレイヤー：%(layers)s \nベクトルパスオブジェクトが含まれていて、速度が %(limit)sを超えています。\nベクトルパスオブジェクトの切断速度は %(limit)s に制限されます。\nこの制限は[好み]で削除できます。',
      touchpad: 'タッチパッド',
      ungroup_use: 'これにより、インポートされた DXF または SVG がグループ解除されます。ファイルには大量の要素が含まれている可能性があるため、グループ解除に時間がかかる場合があります。続行してもよろしいですか？',
      upload_failed: '#819 ファイルのアップロードに失敗しました。',
      upload_file_too_large: '#819 ファイルが大きすぎてアップロードできません。',
      vectorize_shading_image: 'シェーディング画像はベクトル化に時間がかかり、ノイズが発生しやすくなります。実行する前に画像のシェーディングをオフにしてください。',
    },
    rating_panel: {
      description: 'Beam Studio が気に入ったら、評価していただければ幸いです。',
      thank_you: 'ありがとうございました',
      title: 'Beam Studio は好きですか？',
    },
    right_panel: {
      laser_panel: {
        advanced: '詳細設定',
        backlash: 'バックラッシュ',
        bi_directional: '双方向',
        by: '', // keep empty on purpose
        ce_z_high_speed: '高速 Z 軸',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'カラーバージョン調整',
        color_adjustment_short: 'カラーバージョン',
        color_strength: '強度',
        cross_hatch: '交差ハッチ',
        custom_preset: 'カスタム',
        cut: 'カット',
        diode: 'ダイオードレーザー',
        do_not_adjust_default_para: 'デフォルトのパラメーターは調整できません。',
        dottingTime: '打点時間',
        dropdown: {
          inches: {
            abs_engraving: 'ABSプラスチック - 彫刻',
            acrylic_3mm_cutting: 'アクリル - 1/8″ 切断',
            acrylic_5mm_cutting: 'アクリル - 3/16″ 切断',
            acrylic_8mm_cutting: 'アクリル - 1/4″ 切断',
            acrylic_10mm_cutting: 'アクリル - 3/8″ 切断',
            acrylic_engraving: 'アクリル - 彫刻',
            acrylic_printing: 'アクリル - 印刷',
            aluminum_engraving: 'アルミニウム - 彫刻',
            aluminum_light: 'アルミニウム (明るい)',
            bamboo_printing: '竹 - 印刷',
            black_abs: '黒い ABS',
            black_acrylic_3mm_cutting: 'ブラックアクリル - 1/8″ 切断',
            black_acrylic_5mm_cutting: 'ブラックアクリル - 3/16″ 切断',
            black_acrylic_engraving: 'ブラックアクリル - 彫刻',
            brass_dark: '真鍮 (暗い)',
            brass_engraving: '黄銅 - 彫刻',
            brass_light: '真鍮 (明るい)',
            canvas_printing: 'キャンバス - 印刷',
            cardstock_printing: 'カード紙 - 印刷',
            copper: '銅',
            cork_printing: 'コルク - 印刷',
            denim_1mm_cutting: 'デニム - 1/32″ 切断',
            fabric_3mm_cutting: '布地 - 1/8″ 切断',
            fabric_5mm_cutting: '布地 - 3/16″ 切断',
            fabric_engraving: '布地 - 彫刻',
            fabric_printing: '布地 - 印刷',
            flat_stone_printing: '平石 - 印刷',
            glass_bw_engraving: 'ガラス - 彫刻',
            glass_printing: 'ガラス - 印刷',
            gloss_leather_printing: 'グロス・レザー - 印刷',
            gold_engraving: '金 - 彫刻',
            iron_engraving: '鉄 - 彫刻',
            leather_3mm_cutting: 'レザー - 1/8″ 切断',
            leather_5mm_cutting: 'レザー - 3/16″ 切断',
            leather_engraving: 'レザー - 彫刻',
            mdf_3mm_cutting: 'ニュージーランドのMDF - 1/8″ 切断',
            mdf_5mm_cutting: 'ニュージーランドのMDF - 3/16″ 切断',
            mdf_engraving: 'ニュージーランドのMDF - 彫刻',
            metal_bw_engraving: '金属 - 彫刻',
            opaque_acrylic: '不透明アクリル',
            pc_printing: 'PC - 印刷',
            rubber_bw_engraving: 'ゴム - 彫刻',
            silver_engraving: '銀 - 彫刻',
            stainless_steel_bw_engraving_diode: '金属 - 彫刻 (ダイオードレーザー)',
            stainless_steel_dark: 'ステンレス鋼 (暗い)',
            stainless_steel_engraving: 'ステンレス鋼 - 彫刻',
            stainless_steel_light: 'ステンレス鋼 (明るい)',
            stainless_steel_printing: 'ステンレス鋼 - 印刷',
            steel_engraving_spray_engraving: '金属 - 彫刻',
            stone: '石',
            ti_engraving: 'チタン - 彫刻',
            titanium_dark: 'チタン (暗い)',
            titanium_light: 'チタン (明るい)',
            white_abs: '白い ABS',
            wood_3mm_cutting: '木材 - 1/8″ 切断',
            wood_5mm_cutting: '木材 - 3/16″ 切断',
            wood_7mm_cutting: '木材 - 1/4″ 切断',
            wood_8mm_cutting: '木材 - 1/4″ 切断',
            wood_10mm_cutting: '木材 - 3/8″ 切断',
            wood_engraving: '木材 - 彫刻',
            wood_printing: '木材 - 印刷',
          },
          mm: {
            abs_engraving: 'ABSプラスチック - 彫刻',
            acrylic_3mm_cutting: 'アクリル - 3mm 切断',
            acrylic_5mm_cutting: 'アクリル - 5mm 切断',
            acrylic_8mm_cutting: 'アクリル - 8mm 切断',
            acrylic_10mm_cutting: 'アクリル - 10mm 切断',
            acrylic_engraving: 'アクリル - 彫刻',
            acrylic_printing: 'アクリル - 印刷',
            aluminum_engraving: 'アルミニウム - 彫刻',
            aluminum_light: 'アルミニウム (明るい)',
            bamboo_printing: '竹 - 印刷',
            black_abs: '黒い ABS',
            black_acrylic_3mm_cutting: 'ブラックアクリル - 3mm 切断',
            black_acrylic_5mm_cutting: 'ブラックアクリル - 5mm 切断',
            black_acrylic_engraving: 'ブラックアクリル - 彫刻',
            brass_dark: '真鍮 (暗い)',
            brass_engraving: '黄銅 - 彫刻',
            brass_light: '真鍮 (明るい)',
            canvas_printing: 'キャンバス - 印刷',
            cardstock_printing: 'カード紙 - 印刷',
            copper: '銅',
            cork_printing: 'コルク - 印刷',
            denim_1mm_cutting: 'デニム - 1mm 切断',
            fabric_3mm_cutting: '布地 - 3mm 切断',
            fabric_5mm_cutting: '布地 - 5mm 切断',
            fabric_engraving: '布地 - 彫刻',
            fabric_printing: '布地 - 印刷',
            flat_stone_printing: '平石 - 印刷',
            glass_bw_engraving: 'ガラス - 彫刻',
            glass_printing: 'ガラス - 印刷',
            gloss_leather_printing: 'グロス・レザー - 印刷',
            gold_engraving: '金 - 彫刻',
            iron_engraving: '鉄 - 彫刻',
            leather_3mm_cutting: 'レザー - 3mm 切断',
            leather_5mm_cutting: 'レザー - 5mm 切断',
            leather_engraving: 'レザー - 彫刻',
            mdf_3mm_cutting: 'ニュージーランドのMDF - 3mm 切断',
            mdf_5mm_cutting: 'ニュージーランドのMDF - 5mm 切断',
            mdf_engraving: 'ニュージーランドのMDF - 彫刻',
            metal_bw_engraving: '金属 - 彫刻',
            opaque_acrylic: '不透明アクリル',
            pc_printing: 'PC - 印刷',
            rubber_bw_engraving: 'ゴム - 彫刻',
            silver_engraving: '銀 - 彫刻',
            stainless_steel_bw_engraving_diode: '金属 - 彫刻 (ダイオードレーザー)',
            stainless_steel_dark: 'ステンレス鋼 (暗い)',
            stainless_steel_engraving: 'ステンレス鋼 - 彫刻',
            stainless_steel_light: 'ステンレス鋼 (明るい)',
            stainless_steel_printing: 'ステンレス鋼 - 印刷',
            steel_engraving_spray_engraving: '金属 - 彫刻',
            stone: '石',
            ti_engraving: 'チタン - 彫刻',
            titanium_dark: 'チタン (暗い)',
            titanium_light: 'チタン (明るい)',
            white_abs: '白い ABS',
            wood_3mm_cutting: '木材 - 3mm 切断',
            wood_5mm_cutting: '木材 - 5mm 切断',
            wood_7mm_cutting: '木材 - 7mm 切断',
            wood_8mm_cutting: '木材 - 8mm 切断',
            wood_10mm_cutting: '木材 - 10mm 切断',
            wood_engraving: '木材 - 彫刻',
            wood_printing: '木材 - 印刷',
          },
          parameters: 'パラメーター…',
          save: '現在のパラメーターを追加',
        },
        engrave: '彫刻する',
        existing_name: 'このパラメーター名は使用されています。',
        fill_angle: '填充角度',
        fill_interval: '填充間隔',
        fill_setting: 'フィル設定',
        filled_path_only: '塗りつぶしパスのみ対応',
        focus_adjustment: 'フォーカスを調整する',
        frequency: '周波数',
        gradient_only: 'グラデーション画像のみ対応',
        halftone: 'ハーフトーン',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: '高さ',
        ink_saturation: '飽和',
        ink_type: {
          normal: '普通のインク',
          text: 'インクの種類',
          UV: 'UVインク',
        },
        laser_speed: {
          fast: '高速',
          max: 300,
          min: 3,
          slow: '低速',
          step: 0.1,
          text: '速度',
          unit: 'mm/秒',
        },
        low_power_warning: 'レーザー出力が低い (10% 未満) と、レーザー光が放出されない場合があります。',
        low_speed_warning: '低速では、材料が焼き付く可能性があります。',
        lower_focus: 'フォーカスを下げる',
        lower_focus_desc: '切断性能を向上させるために、フォーカス後に特定の距離だけ焦点高さを下げます。',
        module: 'モジュール',
        more: '管理する',
        multi_layer: '複数のレイヤ',
        para_in_use: 'このパラメーターは使用中です。',
        parameters: 'パラメーター',
        power: {
          high: '高',
          low: '低',
          max: 100,
          min: 1,
          step: 0.1,
          text: '出力',
        },
        preset_management: {
          add_new: '新規追加',
          delete: '削除する',
          export: '書き出す',
          export_preset_title: 'パラメータのエクスポート',
          import: 'インポート',
          laser: 'レーザー',
          lower_focus_by: 'フォーカスを下げる',
          new_preset_name: '新しいパラメータ名',
          preset: 'プリセット',
          print: '印刷',
          reset: 'リセット',
          save_and_exit: '保存して終了',
          show_all: 'すべて表示',
          sure_to_import_presets: 'これによりプリセットの配置が読み込まれ、カスタマイズしたパラメータが上書きされます。続行してもよろしいですか？',
          sure_to_reset: 'これによりカスタマイズしたパラメータが削除され、すべてのプリセットがリセットされます。続行してもよろしいですか？',
          title: 'パラメーターを管理する',
          wobble_diameter: 'ウォブル直径',
          wobble_step: 'ウォブルステップサイズ',
        },
        preset_setting: 'パラメータ設定 （%s）',
        presets: 'プリセット',
        print_multipass: '複数の印刷',
        promark_speed_desc: '速度パラメーターはグラデーション画像には適用できません。',
        pulse_width: 'パルス幅',
        pwm_advanced_desc: '深度モードの最小電力を設定してください。',
        pwm_advanced_hint: 'この設定は、深度モードを使用する際のグラデーション画像に特に適用されます。',
        pwm_advanced_setting: '深度モードの電力設定',
        repeat: 'パス数',
        single_color: '単色',
        single_color_desc: 'フルカラーレイヤーにのみ適用され、拡張された単色レイヤーでは使用できません。',
        slider: {
          fast: '速い',
          high: '高',
          low: '低',
          regular: '標準',
          slow: '遅い',
          very_fast: '最速',
          very_high: '最大',
          very_low: '最小',
          very_slow: '最遅',
        },
        speed: '速度',
        speed_constrain_warning: 'ベクトルパスオブジェクトの切断速度は %(limit)s に制限されます。この制限は「好み」で削除できます。',
        speed_constrain_warning_auto_feeder: 'オートフィーダーオブジェクトの切断速度は%(limit)sに制限されます。この制限は「設定」から削除できます。',
        speed_constrain_warning_curve_engraving: '曲面彫刻オブジェクトの速度は%(limit)sに制限されます。この制限は設定で解除できます。',
        stepwise_focusing: 'ステップフォーカシング',
        stepwise_focusing_desc: '各パス数の間、オブジェクトの高さに基づいて焦点距離を段階的に下げます。',
        strength: '出力',
        times: '回',
        various_preset: '複数のプリセット',
        white_ink: 'ホワイトインク',
        white_ink_settings: 'ホワイトインク設定',
        wobble: 'ウォブル',
        wobble_desc: '経路に沿って小さな円を作成します。これにより、切断作業が改善されます。（パスオブジェクト専用。）',
        wobble_diameter: '直径',
        wobble_step: 'ステップサイズ',
        z_step: 'Z Step',
      },
      layer_panel: {
        current_layer: '現在のレイヤー',
        layer1: 'レイヤ 1',
        layer_bitmap: 'ビットマップ',
        layer_cutting: '切る',
        layer_engraving: '彫る',
        layers: {
          del: 'レイヤの削除',
          dupe: 'レイヤーを複製',
          fullColor: 'フルカラー',
          layer: 'レイヤ',
          layers: 'Layers',
          lock: 'レイヤーをロック',
          merge_all: 'すべてをマージ',
          merge_down: 'マージダウン',
          merge_selected: '選択したレイヤーをマージ',
          move_down: 'レイヤを下へ移動',
          move_elems_to: '移動先レイヤ:',
          move_selected: '選択対象を別のレイヤに移動',
          move_up: 'レイヤを上へ移動',
          new: '新規レイヤ',
          rename: 'レイヤの名前を変更',
          splitFullColor: 'レイヤーを展開',
          switchToFullColor: 'フルカラーレイヤーに切り替える',
          switchToSingleColor: 'シングルカラーレイヤーに切り替える',
          unlock: '解除する',
        },
        move_elems_to: '移動先レイヤ:',
        notification: {
          dupeLayerName: '同名のレイヤーが既に存在します。',
          enterNewLayerName: 'レイヤの新しい名前を入力してください。',
          enterUniqueLayerName: '新規レイヤの一意な名前を入力してください。',
          layerHasThatName: '既に同名が付いています。',
          mergeLaserLayerToPrintingLayerMsg: 'この手順を完了すると、レーザーレイヤーの設定が削除され、現在のレイヤーに設定されますので、注意してください。',
          mergeLaserLayerToPrintingLayerTitle: 'レイヤーを1つの印刷レイヤーにマージしますか？',
          mergePrintingLayerToLaserLayerMsg: 'この手順を完了すると、印刷レイヤーのカラー設定が削除され、現在のレイヤーに設定されますので、注意してください。',
          mergePrintingLayerToLaserLayerTitle: 'これらのレイヤーを1つのレーザーレイヤーに統合しますか？',
          moveElemFromPrintingLayerMsg: 'この操作を完了すると、選択した要素の色設定が削除され、%s に設定されます。',
          moveElemFromPrintingLayerTitle: '選択した要素を %s に移動し、レーザー要素に変換しますか？',
          moveElemToPrintingLayerMsg: 'この操作を完了すると、選択した要素の設定が削除され、%s に設定されます。',
          moveElemToPrintingLayerTitle: '選択した要素を %s に移動し、印刷要素に変換しますか？',
          newName: '新しい名前',
          QmoveElemsToLayer: "選択した要素をレイヤー '%s' に移動しますか？",
          splitColorMsg: 'この操作を続行すると、元のカラーレイヤに戻ることはできなくなります。',
          splitColorTitle: '選択したレイヤーを CMYK レイヤーに展開しますか？',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'AI 背景リムーバー',
          ai_bg_removal_reminder: 'ボタンを押すと、すぐに 0.2 クレジットが使用されます。続行しますか?',
          ai_bg_removal_short: '背景削除',
          array: '配列',
          auto_fit: '自動フィット',
          bevel: 'テーパー',
          brightness: '輝度',
          convert_to_path: 'パスに変換',
          create_textpath: 'パステキスト作成',
          create_textpath_short: 'パステキスト',
          crop: 'クロップ',
          decompose_path: '離散パスを分解する',
          detach_path: 'パステキスト作成',
          detach_path_short: '解散',
          disassemble_use: '逆アセンブル',
          disassembling: '逆アセンブル中…',
          edit_path: 'パス編集',
          fetching_web_font: 'ウェブフォント読み込み中…',
          grading: 'カーブ',
          invert: '色を反転',
          offset: 'オフセット',
          outline: '輪郭描画',
          replace_with: '置き換える',
          replace_with_short: '置き換える',
          sharpen: 'シャープ化',
          simplify: '簡略化',
          smart_nest: 'スマートネスト',
          trace: 'ベクトル化',
          ungrouping: 'グループ解除中…',
          uploading_font_to_machine: 'フォントアップロード中…',
          wait_for_parsing_font: 'フォントの解析中です… 少々お待ちください',
          weld_text: 'テキストを結合',
        },
        align: '整列',
        boolean: 'ブール',
        bottom_align: '下部に整列',
        center_align: '中央に整列',
        difference: '差',
        distribute: '配置',
        flip: 'フリップ',
        group: 'グループ化',
        hdist: '水平配置',
        hflip: '左右反転',
        intersect: '交差',
        left_align: '左に整列',
        lock_aspect: '縦横比を固定',
        middle_align: '中間に整列',
        option_panel: {
          color: '色',
          fill: 'インフィル',
          font_family: 'フォント',
          font_size: 'サイズ',
          font_style: 'スタイル',
          letter_spacing: '文字間隔',
          line_spacing: '行間',
          path_infill: 'パスインフィル',
          pwm_engraving: '深度モード',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: '角丸',
          shading: 'シェーディング',
          sides: '辺の数',
          start_offset: 'テキストオフセット',
          stroke: 'フレーム',
          stroke_color: 'ボーダの色',
          stroke_width: '境界線の幅',
          text_infill: 'テキストインフィル',
          threshold: 'しきい値',
          threshold_short: 'しきい値',
          vertical_align: '整列',
          vertical_text: '縦書き',
        },
        path_edit_panel: {
          connect: '接続する ',
          delete: '削除',
          disconnect: '切断する',
          node_type: 'ノードタイプ',
          round: '滑らか',
          sharp: '鋭い',
        },
        right_align: '右に整列',
        subtract: '引く',
        top_align: '上部に整列',
        ungroup: 'グループ化解除',
        union: 'ユニオン',
        unlock_aspect: '縦横比をロック解除',
        vdist: '垂直配置',
        vflip: '上下反転',
        zoom: 'ズーム',
      },
      tabs: {
        layers: 'レイヤ',
        objects: 'オブジェクト',
        path_edit: 'パス編集',
      },
      uv_print_block: {
        title: 'UV印刷',
      },
    },
    shapes_panel: {
      animals: '動物',
      arrow: '矢印',
      basic: '基本',
      birds: '鳥',
      celebration: 'お祝い',
      circular: '円形',
      CNY: '春節',
      corner: 'コーナー',
      decor: 'デコ',
      easter: 'イースター',
      elements: '要素',
      environment: '環境',
      graphics: 'グラフィックス',
      halloween: 'ハロウィン',
      holidays: '祝日',
      label: 'ラベル',
      land: '陸',
      line: '線',
      nature: '自然',
      photo: '写真',
      plants: '植物',
      ribbon: 'リボン',
      sea: '海',
      shape: '形状',
      speech: 'スピーチ',
      text: 'テキストフレーム',
      title: '要素',
      valentines: 'バレンタインデー',
      weather: '天気',
      Xmas: 'クリスマス',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: '画像をクリップボードから転送失敗しました。',
      unnsupported_file_type: 'ファイルタイプは直接サポートされていません。ファイルをSVGまたはビットマップに変換してください',
    },
    tag: {
      g: 'グループ',
      image: '画像',
      text: 'テキスト',
      use: 'Import Svg',
    },
    time_est_button: {
      calculate: '時間を計算',
      estimate_time: '推定所要時間：',
    },
    tool_panels: {
      _nest: {
        end: '終了する',
        no_element: '整列できるオブジェクトがありません',
        rotations: 'Possible Rotation',
        spacing: '間隔',
        start_nest: '整列開始',
        stop_nest: '整列停止',
      },
      _offset: {
        corner_type: 'コーナー',
        direction: 'オフセット方向',
        dist: 'オフセット距離',
        fail_message: 'オブジェクトのオフセットに失敗しました。',
        inward: '内向き',
        not_support_message: 'サポートされていないSVGタグを含む選択された要素：\nイメージ、グループ、テキスト、インポートされたオブジェクト。',
        outward: '外向き',
        round: 'ラウンド',
        sharp: 'シャープ',
      },
      array_dimension: '配列の次元',
      array_interval: '配列の間隔',
      cancel: 'キャンセル',
      columns: '列',
      confirm: '確認する',
      dx: 'X',
      dy: 'Y',
      grid_array: 'グリッド配列を作成',
      nest: '整列の最適化',
      offset: 'オフセット',
      rows: '行',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'ウィンドウに合わせる',
    },
  },
  boxgen: {
    add_option: 'オプションを追加',
    basic_box: '基本ボックス',
    beam_radius: 'レーザー半径補正',
    beam_radius_warning: '箱の端や接合部が短い場合は、組み立てを確実にするためにカーブ補正を削除してください',
    cancel: 'キャンセル',
    coming_soon: '近日公開',
    continue_import: 'インポートを続行する',
    control_tooltip: '左クリックで回転\nスクロールでズーム\n右クリックでパン',
    control_tooltip_touch: 'ドラッグで回転\nピンチでズーム\n2本指でパン',
    cover: 'カバー',
    customize: 'カスタマイズ',
    depth: '奥行き',
    edge: 'エッジ',
    finger: 'フィンガー',
    finger_warning: 'フィンガージョイントとの互換性のために、ボックス内の長さは最低6mm必要です。',
    height: '高さ',
    import: 'インポート',
    inner: '内側',
    joints: 'ジョイント',
    max_dimension_tooltip: '最大幅/高さ/奥行きの設定は %s です。',
    merge: 'マージ',
    outer: '外側',
    reset: 'リセット',
    tCount: 'T の数',
    tCount_tooltip: 'Tスロットの数は短辺に適用されます。長辺の数は、長さに基づいて計算されます。',
    tDiameter: 'T の直径',
    text_label: 'ラベル',
    thickness: '厚さ',
    title: 'BOXGEN',
    tLength: 'T の長さ',
    tSlot: 'T スロット',
    tSlot_warning: 'Tスロットジョイントとの互換性のためには、ボックス側面の長さが最低30mm必要です。',
    volume: '体積',
    width: '幅',
    workarea: '作業エリア',
    zoom: 'ズーム',
  },
  buttons: {
    back: '戻る',
    back_to_beam_studio: 'Beam Studio に戻る',
    done: '完了',
    next: '次へ',
  },
  calibration: {
    ador_autofocus_focusing_block: 'マシンのメインページで「AF」アイコンを3秒間押し、プローブをフォーカシングブロックに触れさせてください。',
    ador_autofocus_material: 'マシンのメインページで「AF」アイコンを3秒間押して、プローブが材料に軽く触れるのを許してください。',
    align_ilb: '位置: 内側の左下の彫刻ポイントに合わせてください。',
    align_ilt: '位置: 内側の左上の彫刻ポイントに合わせてください。',
    align_irb: '位置: 内側の右下の彫刻ポイントに合わせてください。',
    align_irt: '位置: 内側の右上の彫刻ポイントに合わせてください。',
    align_olb: '位置: 外側の左下の彫刻ポイントに合わせてください。',
    align_olt: '位置: 外側の左上の彫刻ポイントに合わせてください。',
    align_orb: '位置: 外側の右下の彫刻ポイントに合わせてください。',
    align_ort: '位置: 外側の右上の彫刻ポイントに合わせてください。',
    align_red_cross_cut: '赤い十字の中心を切断された十字と合わせてください。',
    align_red_cross_print: '赤い十字の中心を印刷された十字と合わせてください。',
    analyze_result_fail: 'キャプチャした画像を分析することができません。<br/>次のことを確認してください：<br/>1. キャプチャした画像が白い紙で完全に覆われている。<br/>2. プラットフォームは適切にフォーカスされている。',
    ask_for_readjust: '彫刻ステップをスキップして写真を撮り、キャリブレーションを行いますか？',
    back: '戻る',
    calculating_camera_matrix: 'カメラ行列の計算中...',
    calculating_regression_parameters: '回帰パラメータの計算中...',
    calibrate_camera_before_calibrate_modules: 'モジュールのキャリブレーションを行う前にカメラのキャリブレーションを行ってください。',
    calibrate_chessboard_success_msg: 'チェスボードの写真を正常にキャプチャしました。<br/>この写真のスコアは %s (%.2f) です。',
    calibrate_done: '較正が完了しました。正確に焦点を合わせると、カメラの精度が向上します。',
    calibrate_done_diode: '較正が完了しました。ダイオードレーザーオフセットを保存しました。',
    calibrating: 'キャリブレーション中...',
    calibrating_with_device_pictures: 'デバイスの写真でキャリブレーション中...',
    camera_calibration: 'カメラの較正',
    camera_parameter_saved_successfully: 'カメラパラメータを正常に保存しました。',
    cancel: 'キャンセル',
    check_checkpoint_data: 'チェックポイントデータ',
    check_device_pictures: 'デバイスの写真を確認',
    checking_checkpoint: 'チェックポイントデータをチェックしています...',
    checking_pictures: 'デバイスの写真を確認しています...',
    diode_calibration: 'ダイオードレーザーモジュールの較正',
    do_engraving: '彫刻をする',
    download_chessboard_file: 'チェッカーボードファイルをダウンロード',
    downloading_checkpoint: 'チェックポイントデータのダウンロード中...',
    downloading_pictures: '画像をダウンロード中...',
    drawing_calibration_image: '較正画像の描画中…',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: '高さを上げて切り取る',
    elevate_and_cut_step_1: 'A4サイズの明るい木材を作業スペースの中心に置き、20mmに高くします。',
    elevate_and_cut_step_1_prism_lift: 'Ador Prism Liftを最大14mmの長さで、少なくとも6mmの厚さの木材と一緒に使用します。',
    failed_to_calibrate_camera: '#848 カメラのキャリブレーションに失敗しました。FLUX サポートにご連絡ください。',
    failed_to_calibrate_chessboard: 'チェッカーボード画像でのキャリブレーションに失敗しました。',
    failed_to_calibrate_with_pictures: '#848 デバイスの写真でのキャリブレーションに失敗しました。',
    failed_to_download_pictures: '#848 画像のダウンロードに失敗しました。FLUX サポートにご連絡ください。 ',
    failed_to_move_laser_head: 'レーザーヘッドの移動に失敗しました。',
    failed_to_parse_checkpoint: 'チェックポイントデータの解析に失敗しました。',
    failed_to_save_calibration_results: '#849 キャリブレーション結果の保存に失敗しました。再試行してください。これが続く場合、FLUX サポートにご連絡ください。',
    failed_to_save_camera_parameter: 'カメラパラメータの保存に失敗しました。',
    failed_to_solve_pnp: 'カメラの位置を解決できませんでした。',
    finish: '完了済み',
    found_checkpoint: 'デバイスにチェックポイントデータが見つかりました。 チェックポイントから復元しますか？',
    getting_plane_height: '平面の高さを取得しています...',
    hint_adjust_parameters: 'これらのパラメーターを使用して、赤い正方形を調整します',
    hint_red_square: '赤い枠の外側をカットした枠に合わせてください。',
    module_calibration_2w_ir: '赤外線モジュールのキャリブレーション',
    module_calibration_printer: 'プリンターモジュールのキャリブレーション',
    moving_laser_head: 'レーザーヘッドを移動中...',
    next: '次へ',
    no_picutre_found: '#846 あなたのデバイスには、キャリブレーション用の利用可能な生の写真がありません。FLUXサポートにお問い合わせください。',
    perform_autofocus_bb2: '機械のコントロールパネルに移動し、AFを押してオートフォーカスを実行してください。',
    please_do_camera_calibration_and_focus: {
      beambox: 'ダイオードレーザーモジュールを校正する場合、カメラが必要です。\nプラットフォームを焦点に合わせてください（折り返されたアクリルの高さ）',
      beamo: 'ダイオードレーザーモジュールを校正する場合、カメラが必要です。\nレーザーヘッドを調整して、彫刻オブジェクトに焦点を合わせます（折り返されたアクリルの高さ）',
    },
    please_goto_beambox_first: 'この機能を使用するには、彫刻モード（Beambox）に切り替えてください。',
    please_place_dark_colored_paper: 'A4 サイズまたはレターサイズの黒紙を作業エリアの中央に置いてください。',
    please_place_paper: 'ワークエリアの左上隅にA4またはレターサイズの白い用紙を置いてください',
    please_place_paper_center: 'A4 サイズまたはレターサイズの白紙を作業エリアの中央に置いてください。',
    please_refocus: {
      beambox: 'プラットフォームを焦点に合わせてください（折り返されたアクリルの高さ）',
      beamo: 'レーザーヘッドを調整して、彫刻オブジェクトに焦点を合わせます（折り返されたアクリルの高さ',
      beamo_af: 'オートフォーカスアドオンのサイドボタンをダブルタップして、プローブを素材にそっと触れさせます。',
      hexa: 'Double click the height adjustment button to rise the honeycomb table up and make the probe touch the engraving material.',
    },
    preparing_to_take_picture: '写真撮影の準備中...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'チェスボードを配置',
    put_chessboard_1: '機械のコントロールパネルに移動し、AFを押してオートフォーカスを実行してください。',
    put_chessboard_2: 'チェッカーボード用紙またはレーザーヘッドを移動して、ライブプレビューウィンドウ内の赤い枠内にチェッカーボード全体が確認されるまで移動してください。',
    put_chessboard_3: '現在の位置でチェスボード画像をダウンロードするには右クリックし、画像の鮮明さを確認してください。',
    put_chessboard_bb2_desc_1: '以下のチェスボードファイルをダウンロードして、A4用紙に印刷してください（印刷されたチェスボードのマス目は1x1cmである必要があります）。',
    put_chessboard_bb2_desc_2: '印刷したチェスボードをアクリルやガラスなどの変形しないボードに固定し、チェスボードが平らで、しわや浮きがないことを確認してください。',
    put_chessboard_bb2_desc_3: 'チェスボードをボードごと作業エリアの中央に平らに置いてください。',
    put_chessboard_promark_1: 'フィールドレンズに適した焦点距離に調整してください。',
    put_chessboard_promark_2: 'チェッカーボード用紙が反射せずに明確に見えることを確認してください。「次へ」をクリックして写真を撮影します。',
    put_chessboard_promark_desc_1: '付属ボックス内のチェッカーボード用紙を使用するか、以下のチェッカーボードパターンをA4用紙に印刷して、カメラのキャリブレーションに使用してください。',
    put_chessboard_promark_desc_2: 'チェッカーボードを平らにして作業台の中央に置いてください。',
    put_paper: '紙を置く',
    put_paper_promark_1: '付属品の黒いボール紙を作業台に置いてください。',
    put_paper_promark_2: '焦点を適切に調整し、「彫刻開始」をクリックして彫刻を続行します。',
    put_paper_skip: 'キャリブレーション図が自動的に写真撮影に進まない場合は、「スキップ」を押して写真を撮ってください。',
    put_paper_step1: 'A4またはレターサイズの白い紙を作業領域の中心に置いてください。',
    put_paper_step2: '紙の四隅を固定して、平らになるようにします。',
    put_paper_step3: '「彫刻開始」をクリックしてください。',
    res_average: '普通',
    res_excellent: '優秀',
    res_poor: '悪い',
    retake: '写真を撮り直す',
    rotation_angle: '回転',
    show_last_config: '前回の結果を表示',
    skip: 'スキップ',
    solve_pnp_step1: '各赤いマーカーポイントの番号と予想される位置に従って彫刻ポイントを配置してください。',
    solve_pnp_step2: '「写真を撮り直す」を押してマーカーの位置をリセットするか、手動で調整するか、マーカーポイントを整列してください。',
    solve_pnp_step3: '撮影した画像が実際のシーンと一致しない場合、画面をズームまたはドラッグして黒い校正カードの校正点を中央に調整し、その後「写真を撮り直す」を試みてください。',
    solve_pnp_title: 'マーカーポイントを整列',
    start_engrave: '彫刻開始',
    start_printing: '印刷開始',
    taking_picture: '写真撮影中…',
    unable_to_load_camera_parameters: '#851 お使いのデバイスにはカメラのキャリブレーションパラメータが利用できません。「キャリブレーション」 > 「カメラを校正（高度な設定）」に進んで、キャリブレーションを完了し、パラメータを取得してください。',
    update_firmware_msg1: 'ファームウェアはこの機能をサポートしていません。ファームウェアを v',
    update_firmware_msg2: 'またはそれ以上にアップデートして。続行してください。（メニュー > マシン > [お使いのマシン] >ファームウェアのアップデート）',
    uploading_images: '画像をアップロード中...',
    use_last_config: '前回の校正値を使用する',
    use_old_camera_parameter: '現在のカメラレンズパラメータを使用しますか？',
    with_af: 'オートフォーカスオン',
    with_prism_lift: 'Ador Prism Liftで',
    without_af: 'オートフォーカスなし',
    without_prism_lift: 'Ador Prism Liftなし',
    x_ratio: 'X比',
    y_ratio: 'Y比',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'カメラデータのダウンロードに成功しました。',
    downloading_data: 'データをダウンロード中...',
    estimated_time_left: '推定残り時間：',
    folder_not_exists: '選択したフォルダは存在しません。',
    incorrect_folder: 'キャリブレーションデータのアップロードに失敗しました。選択したフォルダが正しいかどうかを確認してください。',
    no_picture_found: 'マシン内に画像が見つかりません。',
    title: 'カメラデータのバックアップ',
    upload_success: 'カメラデータのアップロードに成功しました。',
    uploading_data: 'データをアップロード中...',
  },
  caption: {
    connectionTimeout: '接続タイムアウト',
  },
  change_logs: {
    added: '追加：',
    change_log: '変更ログ：',
    changed: '変更：',
    fixed: '修正：',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: '以前のバージョンを見る',
  },
  code_generator: {
    barcode: 'バーコード',
    qr_code: 'QRコード',
  },
  curve_engraving: {
    '921': 'オートフォーカスに失敗しました。',
    '922': '赤色光測定に失敗しました。',
    amount: '数量',
    apply_arkwork: 'カーブにアートワークを適用',
    apply_camera: 'カーブにカメラ画像を適用',
    click_to_select_point: '再度測定するためにポイントを選択または選択解除するにはクリックしてください。',
    coloumns: '列',
    column_gap: '列間',
    err_object_over_range: '対象が測定範囲を超えています。',
    failed_to_take_reference: '参照の取得に失敗しました。',
    gap: 'ギャップ',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'オートフォーカスエリアを測定',
    preview_3d_curve: 'カーブ彫刻のプレビュー',
    remeasure: '再測定',
    remeasuring_points: 'ポイントを再測定しています...',
    reselect_area: 'エリアを再選択',
    row_gap: '行間',
    rows: '行',
    set_object_height: 'オブジェクトの高さを設定',
    set_object_height_desc: 'オブジェクトの最大厚さを測定します。',
    start_autofocus: 'オートフォーカスを開始',
    starting_measurement: '測定開始...',
    sure_to_delete: 'カーブ彫刻のフォーカスデータを削除しますか？',
    take_reference: '参照を取得',
    take_reference_desc: 'レーザーヘッドを対象物の最も高い点に移動させ、フォーカスプローブを下げて「確認」をクリックしてフォーカスしてください。',
  },
  device: {
    abort: 'アボート',
    aborted: 'アボートしました',
    aborting: 'アボート処理中',
    busy: 'ビジー',
    cartridge_info_read_failed: 'インクカートリッジが完全に挿入されていることを確認してください。インクを取り出して再度取り付けてみてください。',
    cartridge_info_verification_failed: 'ご使用のインクがFLUX正規品であることを確認してください。',
    cartridge_serial_number: 'インクカートリッジ製造番号',
    close_door_to_read_cartridge_info: 'インク情報にアクセスするには、マシンのドアカバーを閉じてください。',
    completed: '完了済み',
    completing: '完了処理中',
    deviceList: 'マシンリスト',
    disable: '無効にする',
    disconnectedError: {
      caption: 'マシンが切断されました',
      message: '%sのネットワークアクセスが利用可能かどうか確認してください',
    },
    enable: '有効にする',
    firmware_version: 'ファームウェアバージョン',
    ink_color: 'インキ色',
    ink_level: 'インク残量',
    ink_type: 'インクタイプ',
    IP: 'IP',
    model_name: 'モデル名',
    occupied: '維持中',
    pause: '一時停止',
    paused: '一時停止中',
    pausedFromError: 'エラーにより一時停止しました',
    pausing: '一時停止処理中',
    please_wait: 'お待ちください…',
    preparing: '準備中',
    processing: '処理中',
    quit: '終了する',
    ready: '準備完了',
    reset: 'リセット（Kick）',
    resuming: '再開中',
    retry: '再試行',
    running: '作業中',
    scanning: 'スキャン中',
    select: '選択する',
    select_printer: 'プリンターを選択',
    serial_number: 'マシン番号',
    start: '開始',
    starting: '開始処理中',
    status: 'ステータス',
    submodule_type: 'モジュール',
    toolhead_change: 'ツールヘッドを交換',
    unknown: '不明',
    uploading: 'アップロード中',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 お使いのマシンがネットワーク上で見つかりません。\n<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">このガイド</a>に従って、接続の問題をトラブルシューティングしてください。',
    no_device_web: '#801 マシンのステータスを確認するか、下の「マシン設定」をクリックしてマシンを設定してください。',
    select_usb_device: 'USBデバイスを選択',
  },
  editor: {
    exposure: 'プレビュー露出',
    opacity: 'プレビューの不透明度',
    prespray_area: 'プライミングエリア',
  },
  error_pages: {
    screen_size: 'Please note that Beam Studio may not work optimally on your device. For an optimal experience, it should be a device with a screen width of at least 1024 pixels width.',
  },
  flux_id_login: {
    connection_fail: '#847 FLUXメンバーサービスへの接続に失敗しました。',
    email: 'Eメール',
    flux_plus: {
      access_monotype_feature: 'Monotype フォント アドオンがありません。',
      access_monotype_feature_note: 'この機能をご利用いただくには FLUX+ Pro メンバーシップまたは Monotype フォント アドオンが必要です。',
      access_plus_feature_1: 'アクセスしている機能。',
      access_plus_feature_2: 'この機能。',
      access_plus_feature_note: 'この機能をご利用いただくには FLUX+ メンバーシップが必要です。',
      ai_credit_tooltip: 'AI バックグラウンド除去のため',
      explore_plans: 'FLUX+ プランを見る',
      features: {
        ai_bg_removal: 'AI背景除去',
        boxgen: '3Dボックスジェネレーター',
        dmkt: '1000以上のデザインテンプレート',
        monotype: '250以上のプレミアムフォント',
        my_cloud: '無制限のクラウドストレージ',
      },
      flux_credit_tooltip: 'デザイン マーケットのファイルと AI バックグラウンド除去のため',
      get_addon: 'アドオンを取得',
      goto_member_center: 'メンバーセンターへ移動',
      learn_more: '詳細を見る',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: '今すぐ購読する',
      thank_you: 'ご利用いただきありがとうございます!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'パスワードをお忘れの場合',
    incorrect: 'メールアドレスまたはパスワードが正しくありません。',
    login: 'ログイン',
    login_success: '正常にログインしました。',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'FLUXは初めてですか？ アカウントの作成してください。',
    not_verified: 'メールアドレスが未認証です。',
    offline: 'オフライン作業',
    password: 'パスワード',
    register: 'FLUXアカウントを作成する',
    remember_me: 'ログインしたままにする',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'ログインして形状データベースのロックを解除します。',
    work_offline: 'オフライン作業',
  },
  framing: {
    area_check: 'エリアチェック',
    areacheck_desc: '作業エリアが安全であることを、オブジェクトのバウンディングボックスとレーザーヘッドの加速ゾーンをプレビューして確認します。',
    calculating_task: 'タスクを計算中...',
    framing: 'フレーミング',
    framing_desc: 'オブジェクトの境界ボックスをプレビューします。',
    hull: '凸包',
    hull_desc: 'オブジェクトの周りに巻かれた輪ゴムのように、デザインに密接に従う形状をプレビューします。',
    low_laser: '低出力レーザー',
    low_laser_desc: 'フレーミング作業用に低出力のレーザー値を設定します。',
    rotate_axis: '回転軸',
    rotateaxis_desc: 'ロータリー軸中心をプレビューします。ロータリー自体は動きません。',
    rotation_frame_warning: '外枠プレビューは1回転します。途中で停止すると、マーク位置がずれる可能性があるため、停止しないでください。',
    rotation_framing_desc: 'ロータリーの完全な回転範囲をプレビューします。ロータリーはプレビューに従って回転します。',
    start_task: 'タスクを開始',
    start_task_description: 'フレームプレビューが完了したら、「タスクを開始」をクリックしてジョブを送信します。',
  },
  general: {
    choose_folder: 'フォルダーの選択',
    processing: '処理中...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] ステータスの競合が発生しました。アクションを再試行してください。',
    SUBSYSTEM_ERROR: '[SE] マシンファームウェアがタスクを実行しているときにエラーが発生しました。マシンを再起動してください',
    UNKNOWN_COMMAND: '[UC] 「マシンファームウェア」をアップデートしてください',
    UNKNOWN_ERROR: '[UE] Beam Studioを再起動してください',
  },
  global: {
    apply: '適用する',
    back: '戻る',
    cancel: 'キャンセル',
    editing: {
      redo: 'やり直す',
      reset: 'リセット',
      undo: '元に戻す',
      zoom_in: 'ズームイン',
      zoom_out: 'ズームアウト',
    },
    mode_conflict: 'このオプションは現在のモードでは利用できません。',
    ok: 'OK',
    save: '保存する',
    stop: '停止',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'ブラシサイズ',
      description: 'クリックまたはドラッグして不要な領域を手動で消去します。',
      title: '消しゴム',
    },
    magic_wand: {
      description: '色の類似性に基づいて隣接する領域を選択して削除します。',
      title: '魔法の杖',
      tolerance: '許容範囲',
    },
    title: '画像編集',
  },
  initialize: {
    back: '戻る',
    cancel: 'キャンセル',
    confirm: '確認する',
    connect: '接続する',
    connect_ethernet: {
      title: '直接接続',
      tutorial1: '1. イーサネットケーブルでマシンをコンピュータに接続してください。',
      tutorial2_1: '2. この',
      tutorial2_2: 'に従ってコンピュータをルーターにしてください。',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'ガイド',
      tutorial3: '3. 「次へ」をクリックしてください。',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'サーバーに接続できません。Beam Studioを再起動して、もう一度お試しください。',
      },
      check_camera: 'カメラの可用性を確認しています',
      check_connection: 'マシン接続の確認しています',
      check_firmware: 'ファームウェアバージョンの確認しています',
      check_ip: 'IP 可用性の確認しています',
      check_swiftray_connection: 'サーバー接続を確認中',
      check_swiftray_connection_unreachable: 'サーバーに接続できません',
      check_usb: 'USB接続の確認',
      enter_ip: 'マシン IP を入力してください',
      finish_setting: '作り始めます！',
      invalid_format: '無効な形式',
      invalid_ip: 'IPが無効です：',
      promark_hint: 'USB経由での接続に繰り返し失敗する場合は、<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">ヘルプセンターの記事</a>をご参照ください。',
      retry: '再試行',
      starts_with_169254: '169.254で始まる',
      succeeded_message: '接続に成功しました 🎉',
      unreachable: 'IP 到達不能',
    },
    connect_usb: {
      connect_camera: '機械のカメラをUSBケーブルでコンピューターに接続してください。',
      title: 'USB接続',
      title_sub: '（HEXA と Ador 専用）',
      turn_off_machine: 'マシンの電源を切ってください。',
      turn_on_machine: 'マシンの電源を入れてください。',
      tutorial1: 'USBケーブルでコンピュータに機器を接続してください。',
      tutorial2: '「次へ」をクリックしてください。',
      wait_for_turning_on: '起動プロセスが完了し、メイン画面にアクセスした後、 「次へ」をクリックしてください。',
    },
    connect_wifi: {
      title: 'Wi-Fiで接続する',
      tutorial1: '1. タッチパネル > [インターネット]> [WiFi 設定] をクリックしてください。',
      tutorial1_ador: '1. タッチパネル > [マシン] > [インターネット]> [WiFi 設定] をクリックしてください。',
      tutorial2: '2. 使用したい Wi-Fi を選択して接続します。',
      what_if_1: '接続したい Wi-Fi が見えません',
      what_if_1_content: '1. Wi-Fi 暗号化モードは WPA2 またはパスワードなしである必要があります。\n2. 暗号化モードは、 Wi-Fi ルーターの管理インターフェイスで設定できます。ルーターが WPA2 をサポートしておらず、適切なルーターの選択についてサポートが必要な場合は、 FLUX サポートにお問い合わせください。',
      what_if_2: 'Wi-Fi が見えません',
      what_if_2_content: '1. Wi-Fi ドングルが完全に差し込まれていることを確認してください。\n2. タッチパネルに無線接続の MAC アドレスがない場合は、 FLUX サポートに連絡してください。\n3. Wi-Fi チャネルは 2.4Ghz である必要があります（ 5Ghz はサポートされていません）。',
    },
    connect_wired: {
      title: '有線ネットワークで接続する',
      tutorial1: '1. マシンをルーターに接続する.',
      tutorial2: '2. 「インターネット」を押して有線ネットワークIPを取得します。',
      tutorial2_ador: '2. 「マシン」 >「インターネット」を押して有線ネットワークIPを取得します。',
      what_if_1: 'IP が空の場合',
      what_if_1_content: '1. イーサネットケーブルが完全に差し込まれていることを確認します。\n2. タッチパネルに有線接続の MAC アドレスがない場合は、 FLUX サポートに連絡してください。',
      what_if_2: 'IP が 169 で始まる場合',
      what_if_2_content: '1. IP アドレスが 169.254 で始まる場合は、 DHCP 設定の問題であるはずです。詳細については、ISP（インターネットサービスプロバイダー）にお問い合わせください。\n2. コンピューターが PPPoE を使用して直接インターネットに接続している場合は、ルーターを使用して PPPoE を使用して接続し、ルーターで DHCP 機能を有効にしてください。',
    },
    connecting: '接続中…',
    connection_types: {
      ether2ether: '直接接続',
      usb: 'USB接続',
      wifi: 'Wi-Fi',
      wired: '有線ネットワーク',
    },
    next: '次へ',
    no_machine: '私は今マシンを持っていません。',
    promark: {
      configuration_confirmation: 'これにより、Promarkが最適な効率と精度を発揮するよう適切に構成されていることが確認されます。',
      or_complete_later: `または、この手順をスキップして、後で以下の場所でPromark設定を完了してください：
    マシン > 「Promark名」 > Promark設定`,
      qc_instructions: '「QC Pass」カードの裏に記載されたパラメータを入力してください',
      select_laser_source: 'Promarkを選択してください',
      select_workarea: '作業エリアを選択してください',
      settings: 'Promarkの設定',
    },
    retry: '再試行する',
    select_beambox: 'Beamboxを選択してください',
    select_connection_type: 'どのように接続しますか',
    select_language: '言語を選択',
    select_machine_type: 'マシンを選択してください',
    setting_completed: {
      back: '戻る',
      great: 'Beam Studioへようこそ',
      ok: '作成を開始',
      setup_later: 'タイトルバ >「マシン」>「マシン設定」からいつでもマシンをセットアップできます。',
      start: '開始',
    },
    skip: 'スキップ',
    start: '開始',
  },
  input_machine_password: {
    connect: '接続する',
    password: 'パスワード',
    require_password: '「%s」にはパスワードが必要です。',
  },
  insecure_websocket: {
    extension_detected: 'Beam Studio Connect 拡張機能が検出されました',
    extension_detected_description: 'Beam Studio Connect 拡張機能がインストールされていることが検出されました。HTTPS にリダイレクトするには「確認」をクリックするか、HTTP を継続するには「キャンセル」をクリックしてください。',
    extension_not_deteced: 'Beam Studio Connect 拡張機能を検出できません',
    extension_not_deteced_description: 'HTTPSを使用するには、「確認」をクリックしてBeam Studio Connect拡張機能をインストールしてください。拡張機能をインストールした後、ページをリフレッシュして有効にしてください。<br/>それ以外の場合は、下のリンクをクリックしてChromeでHTTPを使用する方法を確認してください。',
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">リンク</a>',
  },
  layer_module: {
    general_laser: 'レーザー',
    laser_2w_infrared: '2W赤外線レーザー',
    laser_10w_diode: '10Wダイオードレーザー',
    laser_20w_diode: '20Wダイオードレーザー',
    non_working_area: '非作業エリア',
    none: 'なし',
    notification: {
      convertFromLaserModuleMsg: 'この操作を完了すると、レーザーレイヤの設定が削除され、現在のレイヤに設定されます。',
      convertFromLaserModuleTitle: 'レーザーモジュールを印刷モジュールに変換しますか？',
      convertFromPrintingModuleMsg: 'この操作を完了すると、印刷レイヤーの色設定が削除され、現在のレイヤに設定されます。',
      convertFromPrintingModuleTitle: '印刷モジュールをレーザーモジュールに変換しますか？',
      importedDocumentContainsPrinting: 'このドキュメントには印刷レイヤーが含まれています。Adorの作業領域に切り替えますか？',
      performIRCaliMsg: '「確認」をクリックしてキャリブレーションを実行する、または上部メニューからアクセスしてください。<br />（機器 > [ご使用の機器名] > 赤外線モジュールのキャリブレーション）',
      performIRCaliTitle: '赤外線モジュールのキャリブレーション実行',
      performPrintingCaliMsg: '「確認」をクリックしてキャリブレーションを実行する、または上部メニューからアクセスしてください。<br />（機器 > [ご使用の機器名] > プリンターモジュールのキャリブレーション',
      performPrintingCaliTitle: 'プリンターモジュールのキャリブレーション実行',
      printingLayersConverted: '印刷レイヤーはレーザーレイヤーに変換されました。',
    },
    printing: '印刷',
    unknown: '不明なモジュール',
    uv_print: 'UV印刷',
  },
  machine_status: {
    '-17': 'Cartridge IO Mode',
    '-10': '維持モード',
    '-2': 'スキャン中',
    '-1': '維持中',
    0: 'アイドル状態',
    1: '初期化中',
    2: 'ST_TRANSFORM',
    4: '開始処理中',
    6: '再開中',
    16: '作業中',
    18: '再開中',
    32: '一時停止中',
    36: '一時停止中',
    38: '一時停止処理中',
    48: '一時停止中',
    50: '一時停止処理中',
    64: '完了済み',
    66: '完了処理中',
    68: '準備中',
    128: 'アボートしました',
    256: 'Alarm',
    512: 'Fatal',
    UNKNOWN: '不明',
  },
  material_test_generator: {
    block_settings: 'ブロック設定',
    columns: '列',
    count: '数量',
    cut: '切断',
    engrave: '彫刻',
    export: 'エクスポート',
    max: '最大',
    min: '最小',
    parameter: 'パラメータ',
    preview: 'プレビュー',
    rows: '行',
    size: 'サイズ (H×W)',
    spacing: '間隔',
    table_settings: 'テーブル設定',
    text_settings: 'テキスト設定',
    title: '材料テストジェネレーター',
  },
  menu: {
    inches: 'インチ',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 認証エラー：Beam Studioとマシンのファームウェアを最新バージョンに更新してください。',
    authenticating: '認証中…',
    camera: {
      abort_preview: '中止する',
      camera_cable_unstable: 'カメラが写真を不安定に転送していることが検出されました。プレビューは引き続き正常に実行できますが、プレビューが遅い、またはタイムアウトになる問題がある可能性があります。',
      continue_preview: '継続する',
      fail_to_transmit_image: '#845 画像の送信に不具合が発生しました。マシンを再起動してください。このエラーが続く場合は、この<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">ガイド</a>に従ってください。',
      ws_closed_unexpectly: '#844 マシンカメラへの接続が予期せず閉じられました。このエラーが続く場合は、この<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402755805071">ガイド</a>に従ってください。',
    },
    cancelling: 'キャンセル中…',
    cant_connect_to_device: '#827 マシンを接続できません。接続を確認してください',
    connected: '接続済み',
    connecting: '接続中…',
    connectingCamera: 'カメラを接続中…',
    connectingMachine: '%sを接続しています…',
    connectionTimeout: '#805 デバイス接続タイムアウト，ネットワークの状態とマシンのWi-Fiインジケーターを確認してください。',
    device_blocked: {
      caption: '未承認のシリアル番号',
      offline: 'デバイスのシリアル番号はオフラインでの使用に制限されています。デバイスの背面に記載されているシリアル番号を販売店に提供し、support@flux3dp.comに連絡してオンライン認証を有効にしてください。オフラインでの使用を希望する場合は、直接support@flux3dp.comにお問い合わせください。',
      online: 'デバイスのシリアル番号が無効になっているようです。デバイスの背面に記載されているシリアル番号を販売店に提供し、support@flux3dp.comに連絡してデバイス認証を有効にしてもらってください。',
    },
    device_busy: {
      caption: 'マシンビジー状態',
      message: 'マシンは別のタスクを実行しています。後で再試行してください。動作しなくなった場合は、マシンを再起動してください。',
    },
    device_is_used: 'マシンは使用中です。現在のタスクをアボートしますか？',
    device_not_found: {
      caption: '出るフォトのマシンが見つかりません',
      message: '#812 マシンのWi-Fiインジケーターを確認してください',
    },
    disconnected: '接続が不安定です。デバイスの接続を確認して、後で再試行してください',
    endingLineCheckMode: 'ラインチェックモードを終了中…',
    endingRawMode: 'RAWモードを終了中…',
    enteringLineCheckMode: 'ラインチェックモードに入る中…',
    enteringRawMode: 'RAWモードの準備中…',
    enteringRedLaserMeasureMode: '赤色レーザー測定モードに入ります...',
    exitingRotaryMode: 'ロータリーモードを終了中…',
    getProbePosition: 'プローブ位置の取得中...',
    gettingLaserSpeed: 'レーザーヘッド速度の読み取り中…',
    homing: 'ホーミング…',
    need_password: 'マシンに接続するには、パスワードが必要です',
    please_enter_dpi: 'ファイルの単位を入力してください (in mm)',
    preview: {
      adjust: '調整',
      adjust_height_tooltip: '編集を有効にするにはチェックボックスをクリックしてください。',
      already_performed_auto_focus: 'すでにオートフォーカスを行っています。既存の値を使用しますか？',
      auto_focus: 'オートフォーカス',
      auto_focus_instruction: 'モジュールヘッドをオブジェクトの上に移動し、アニメーションの指示に従ってAFを押してフォーカスしてください。',
      camera_preview: 'カメラプレビュー',
      enter_manually: '手動で入力',
      please_enter_height: '正確なカメラショットを撮影するために、物体の高さを入力してください。',
    },
    promark_disconnected: '#850 デバイスの接続が中断されました。デバイスの接続状態を確認してください。',
    redLaserTakingReference: '参照を取得しています...',
    retrievingCameraOffset: 'カメラオフセットの読み取り中…',
    settingLaserSpeed: 'レーザーヘッド速度を設定中…',
    swiftray_disconnected: 'バックエンドに接続できません。再接続を試みています。',
    swiftray_reconnected: 'バックエンドが再接続されました。ジョブを再送信してください。',
    time_remaining: '残り時間：',
    tryingToConenctMachine: 'マシンに接続しようとしています…',
    turningOffAirPump: 'エアポンプをオフにする…',
    turningOffFan: 'ファンをオフにする…',
    unable_to_find_machine: 'マシンを見つけることができません',
    unable_to_start: '#830 タスクを開始することができません。これが再び起きた場合は、バグレポートでご連絡ください：\n',
    unavailableWorkarea: '#804 セットした作業領域がマシンの作業領域を超えます。マシンの作業領域を確認や[編集]>[ドキュメント設定]で作業領域をセットくださいい。',
    unknown_device: '#826 マシンに接続できません。USBがマシンに接続されていることを確認してください',
    unknown_error: '#821 アプリケーションで不明なエラーが発生しました。[ヘルプ]>[メニュー]>[バグレポート]を使用してください。',
    unsupport_osx_version: '現在のmacOSバージョン%sは一部の機能をサポートしていない可能性があります。macOS 11+に更新してください。',
    unsupport_win_version: '現在の Windows OSバージョンX ％s は一部の機能をサポートしていない場合があります。最新のバージョンに更新してください。',
    unsupported_example_file: '選択したサンプルファイルは現在の作業領域ではサポートされていません。',
    uploading_fcode: 'FCodeのアップロード中',
    usb_unplugged: 'USB接続が失われました。USB接続を確認してください。',
    wrong_swiftray_version_message: 'バックエンドのバージョンが正しくありません (バージョン: {version})。複数の Beam Studio インスタンスが実行されていないか確認し、余分なインスタンスを閉じてから再試行してください。',
    wrong_swiftray_version_title: 'バックエンドのバージョンが正しくありません',
  },
  monitor: {
    ask_reconnect: 'マシンとの接続が切断されました。再接続しますか？',
    bug_report: 'バグ報告',
    camera: 'カメラ',
    cancel: 'キャンセル',
    confirmFileDelete: 'このファイルを削除してもよろしいですか？',
    connecting: '接続しています。お待ちください…',
    DEVICE_ERROR: '何かがうまくいかなかった\nマシンを再起動してください', // Deprecated in FW 3.3.1
    download: 'ダウンロード',
    extensionNotSupported: 'このファイル形式はサポートされていません',
    fileExistContinue: 'ファイルはすでに存在します。ファイルを置き換えますか？',
    forceStop: '現在のタスクをアボートしますか？',
    go: '開始',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 エアフローセンサー異常',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 ボトムカバーオープン。ドアを閉めて続行します。',
    HARDWARE_ERROR_DOOR_OPENED: '#901 続行するにはドアを閉じてください。',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 引き出しが開きました',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 火炎センサー異常',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 正しくないモジュールが検出されました。続行するには正しいモジュールをインストールしてください。',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 モジュールが検出されません。続行するにはモジュールが正しくインストールされていることを確認してください。',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 未知のモジュールが検出されました。続行するには正しいモジュールをインストールしてください。',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off Error',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 重大なエラー：メインボードがオフラインです。 FLUXサポートにお問い合わせください。', // Deprecated in FW 3.3.1
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 過熱。数分お待ちください。',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z軸リセットに失敗しました',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 プリンターモジュールが応答しません。',
    HARDWARE_ERROR_PROBE_SHOWED: 'プローブを格納してください。',
    HARDWARE_ERROR_PUMP_ERROR: '#900 水槽にご確認ください。',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 ロータリーモジュールが検出されません',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 重大なエラー：サブシステムが応答しません。 FLUXサポートにお問い合わせください。', // Deprecated in FW 3.3.1
    HARDWARE_FAILURE: '何かがうまくいかなかった\nマシンを再起動してください', // Deprecated in FW 3.3.1
    hour: 'h',
    left: '左',
    MAINBOARD_OFFLINE: '#905 メインボードへの接続中にエラーが発生しました。\nマシンを再起動してください。',
    minute: 'm',
    monitor: 'モニター',
    NO_RESPONSE: '#905 メインボードへの接続中にエラーが発生しました。\nマシンを再起動してください。',
    pause: '一時停止',
    prepareRelocate: '再配置の準備',
    processing: '処理中',
    record: '記録',
    relocate: '再配置',
    RESOURCE_BUSY: 'マシンはビジーです\n実行されていない場合は、マシンを再起動してください',
    resume: '再開',
    savingPreview: 'サムネイルの生成中',
    second: 's',
    start: '開始',
    stop: '停止',
    SUBSYSTEM_ERROR: '#402 重大なエラー：サブシステムが応答しません。 FLUXサポートにお問い合わせください。',
    task: {
      BEAMBOX: 'レーザー彫刻',
      'N/A': 'フリーモード',
    },
    taskTab: 'タスク',
    temperature: '温度',
    upload: 'アップロード',
    USER_OPERATION: '続行するためには、デバイスパネルの指示に従ってください。',
    USER_OPERATION_CHANGE_CARTRIDGE: '続行するには正しいカートリッジを挿入してください。',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: '続行するにはカートリッジを挿入してください。',
    USER_OPERATION_CHANGE_TOOLHEAD: '続行するには正しいモジュールをインストールしてください。',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 正しくないモジュールが検出されました。続行するには正しいモジュールをインストールしてください。',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 モジュールが検出されません。続行するにはモジュールが正しくインストールされていることを確認してください。',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 未知のモジュールが検出されました。続行するには正しいモジュールをインストールしてください。',
    USER_OPERATION_REMOVE_CARTRIDGE: '続行するためにカートリッジを取り外してください。',
    USER_OPERATION_ROTARY_PAUSE: 'ロータリーモーターに切り替えてください',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'このファイルを削除してもよろしいですか?この操作は元に戻せません。',
      delete: '削除',
      download: 'ダウンロード',
      duplicate: '複製',
      open: '開く',
      rename: '名前の変更',
    },
    file_limit: '無料ファイル',
    loading_file: '読み込み中...',
    no_file_subtitle: 'メニュー > 「ファイル」 > 「クラウドに保存」 に移動します。',
    no_file_title: 'マイクラウドにファイルを保存して開始します。',
    save_file: {
      choose_action: 'ファイルを保存:',
      input_file_name: 'ファイル名を入力:',
      invalid_char: '無効な文字:',
      save: '保存',
      save_new: '新規ファイルとして保存',
      storage_limit_exceeded: 'クラウドストレージの上限に達しました。新しいファイルを保存する前に、不要なファイルを削除してください。',
    },
    sort: {
      a_to_z: '名前: A - Z',
      most_recent: '最新の',
      oldest: '最古の',
      z_to_a: '名前: Z - A',
    },
    title: 'マイクラウド',
    upgrade: 'アップグレード',
  },
  noun_project_panel: {
    clear: '消去',
    elements: '要素',
    enjoy_shape_library: '形状データベースをお楽しみください。',
    export_svg_title: 'SVGをエクスポートできません',
    export_svg_warning: 'このプロジェクトには、知的財産法によって保護されているオブジェクトが含まれています。したがって、Beam Studioは、エクスポート中にこれらのオブジェクトを自動的に除外します。プロジェクトをBeam Studioシーン（.beam形式）内に保存して、すべてのデータを保持することもできます。それでもエクスポートしますか？',
    learn_more: 'もっと詳しく知る',
    login_first: 'ログインして形状データベースのロックを解除します。',
    recent: '最近使用された',
    search: '搜尋',
    shapes: '形',
  },
  pass_through: {
    export: '作業エリアにエクスポート',
    exporting: 'エクスポート中...',
    guide_mark: 'ガイドマーク',
    guide_mark_desc: 'ガイドマークはアートワークを整列させるための基準点として刻まれます。',
    guide_mark_length: '長さ:',
    guide_mark_x: 'X座標:',
    height_desc: '各作業エリアセクションの高さを設定します。',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: '%(model)sのパススルーモードの設定方法',
    object_length: 'オブジェクトの長さ',
    ref_layer: '参照レイヤー:',
    ref_layer_desc: '参照レイヤーの実行はデフォルトで0に設定されています。 実行されることはなく、整列参照用です。',
    ref_layer_name: '参照レイヤー',
    title: 'パススルーモード',
    workarea_height: '作業エリア（高さ）:',
  },
  promark_connection_test: {
    description: 'システムは約2分間の赤色光操作を実行し、接続の安定性を確認します。',
    health: '安定性：',
    healthy_description: '安定性は良好ですが、彫刻中にコンピューターを大きく動かさないでください。',
    res_0: '優秀',
    res_1: '良好',
    res_2: '低い',
    res_3: '非常に低い',
    res_4: '極めて低い',
    restart: 'テスト再開',
    start: 'テスト開始',
    stop: 'テスト停止',
    title: '接続安定性テスト',
    unhealthy_description: '安定性が低すぎます。ネットワークアダプターまたは電源ケーブルの交換を推奨します。',
  },
  promark_settings: {
    angle: '角度',
    bulge: '膨らみ',
    field: 'フィールド',
    galvo_configuration: 'ガルボ設定',
    mark: 'マーク',
    mark_parameters: 'マーキングパラメータ',
    offsetX: 'オフセット X',
    offsetY: 'オフセット Y',
    preview: 'プレビュー',
    red_dot: '赤い点',
    scale: 'スケール',
    scaleX: 'スケール X',
    scaleY: 'スケール Y',
    skew: '歪み',
    switchXY: 'X/Y 切り替え',
    title: 'Promark 設定',
    trapezoid: '台形',
    workarea_hint: 'ワークエリアは「ドキュメント設定」で変更できます。',
    z_axis_adjustment: {
      section1: 'Z軸の高さを調整してフォーカスを微調整します。',
      title: 'Z軸調整',
      tooltip1: '現在の焦点距離が適切かどうかを確認するために、1x1 cmの四角を試しにマークしてください。',
    },
  },
  qr_code_generator: {
    error_tolerance: 'エラー許容度',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: '背景色を反転',
    placeholder: 'リンクまたはテキストを入力してください',
    preview: 'プレビュー',
    title: 'QRコードジェネレーター',
  },
  rotary_settings: {
    circumference: '円周',
    extend_workarea: '作業エリアを拡張する',
    mirror: 'ミラー',
    object_diameter: 'オブジェクトの直径',
    overlap_size: 'オーバーラップサイズ',
    rotary_scale: 'ロータリースケール',
    split_setting: '分割設定',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: '分割サイズ',
    type: '種類',
  },
  select_device: {
    auth_failure: '#811 認証失敗',
    select_device: 'デバイスを選択',
    unable_to_connect: '#810 マシンとの安定した接続を構築できません',
  },
  settings: {
    anti_aliasing: 'アンチエイリアス',
    auto_connect: '自動的に唯一のマシンを選択する',
    auto_switch_tab: 'レイヤーとオブジェクトパネルの自動切り替え',
    autofocus_offset: 'オートフォーカスオフセット',
    autosave_enabled: '自動保存',
    autosave_interval: '保存間隔',
    autosave_number: '自動保存の数',
    autosave_path: '自動保存位置',
    autosave_path_not_correct: '指定されたパスが見つかりません',
    blade_precut_position: 'Precut Position',
    blade_precut_switch: 'Blade Precut',
    blade_radius: 'Blade Radius',
    bottom_up: '下から上へ',
    calculation_optimization: 'パス計算の加速',
    cancel: 'キャンセル',
    caption: '設定',
    check_updates: '自動チェック',
    close: '閉じる',
    confirm_remove_default: 'デフォルトマシンが削除されます。',
    confirm_reset: 'Beam Studioのリセットを確認しますか？',
    continuous_drawing: '連続描画',
    curve_engraving_speed_limit: 'カーブ彫刻の速度制限',
    custom_preview_height: 'カスタムプレビュー高さ',
    default_beambox_model: 'デフォルト作業エリア',
    default_borderless_mode: 'オープンボトム既定',
    default_enable_autofocus_module: 'オートフォーカス既定',
    default_enable_diode_module: 'ダイオードレーザー既定',
    default_font_family: 'デフォルトのフォント',
    default_font_style: 'デフォルトのフォントスタイル',
    default_laser_module: 'デフォルトレーザーモジュール',
    default_machine: 'デフォルトマシン',
    default_machine_button: '空いている',
    default_units: 'デフォルト単位',
    diode_offset: 'ダイオードレーザーオフセット',
    diode_one_way_engraving: 'ダイオードレーザー一方向彫刻',
    diode_two_way_warning: '二方向で光を発するので、速度が一段と速くなりますが、レーザー照射位置に誤差が生じる可能性があります。そのため、事前に他の材料でテストしておいでください。',
    disabled: '無効',
    done: '適用する',
    enable_custom_backlash: 'カスタムバックラッシュを有効にする',
    enable_low_speed: '低速モードを有効にする',
    enable_uv_print_file: 'UV印刷ファイルを有効にする',
    enabled: '有効',
    engraving_direction: '彫刻方向',
    fast_gradient: '速度の最適化',
    font_convert: 'テキストからパスへの変換機能',
    font_substitute: 'サポートされていない文字を置き換える',
    grouped_objects: 'グループ化されたオブジェクト',
    groups: {
      ador_modules: 'Ador モジュール',
      autosave: '自動保存',
      camera: 'カメラ',
      connection: '接続',
      editor: 'エディター',
      engraving: '彫刻（スキャン）',
      general: '一般',
      mask: 'ワークエリアのクリッピング',
      modules: '拡張機能',
      path: 'パス（線分）',
      privacy: 'プライバシー',
      text_to_path: 'テキスト',
      update: 'ソフトウェア更新',
    },
    guess_poke: 'マシンIPを自動的に検索',
    guides: 'ガイド',
    guides_origin: 'ガイドの原点',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      uv_print_export: 'https://support.flux3dp.com/hc/en-us/articles/12320887477135',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: '高',
    image_downsampling: 'ビットマップのプレビュー品質',
    ip: 'マシンIPアドレス',
    keep_preview_result: 'プレビュー結果を保持',
    language: '言語',
    loop_compensation: 'ループ補正',
    low: '低',
    low_laser_for_preview: 'フレームプレビューのレーザー',
    mask: 'ワークエリアのクリッピング',
    medium: '中',
    module_offset_2w_ir: '2W赤外線レーザーオフセット',
    module_offset_10w: '10Wダイオードレーザーオフセット',
    module_offset_20w: '20Wダイオードレーザーオフセット',
    module_offset_printer: 'プリンターオフセット',
    none: 'なし',
    normal: '普通',
    notification_off: 'オフ',
    notification_on: 'オン',
    notifications: 'デスクトップ通知',
    off: 'オフ',
    on: 'オン',
    preview_movement_speed: 'プレビューの移動速度',
    printer_advanced_mode: 'プリンターの高級なモード',
    remove_default_machine_button: '削除する',
    reset: 'Beam Studioをリセット',
    reset_now: 'Beam Studioをリセット',
    segmented_engraving: 'セグメント彫刻',
    share_with_flux: 'Beam Studio Analyticsを共有する',
    simplify_clipper_path: '計算されたパスを最適化',
    single_object: '単一のオブジェクト',
    tabs: {
      device: 'マシン',
      general: '一般',
    },
    text_path_calc_optimization: 'パス計算の最適化',
    top_down: '上から下へ',
    trace_output: 'ベクトル化/トレース画像出力',
    update_beta: 'ベータ',
    update_latest: '最新',
    vector_speed_constraint: '速度制限',
    wrong_ip_format: '間違ったIP形式',
  },
  social_media: {
    facebook: 'FLUXユーザーとチャットし、質問してヒントを学ぼう！',
    instagram: '最新のインスピレーション、セール情報、無料アイテムを手に入れよう！',
    youtube: 'Beam Studio のチュートリアルとクラフトアイデアを確認しよう。',
  },
  support: {
    no_vcredist: '「Visual C++ Redistributable 2015」をインストールしてください。<br/>それはflux3dp.comでダウンロードできます',
    no_webgl: 'WebGLはサポートされていません。他のデバイスを使用してください。',
  },
  topbar: {
    alerts: {
      add_content_first: '最初にオブジェクトを追加してください',
      door_opened: 'ドアカバーを閉じて、フレームを実行するための低レーザーを有効にしてください。',
      fail_to_connect_with_camera: '#803 マシンカメラとの接続を確立できませんでした。マシンまたはBeam Studioを再起動してください。このエラーが続く場合は、この<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ガイド</a>に従ってください。',
      fail_to_get_door_status: 'ドアカバーが閉まっていることを確認して、フレームを実行するための低レーザーを有効にしてください。',
      fail_to_start_preview: '#803 プレビューモードを開始できません。マシンまたはBeam Studioを再起動してください。このエラーが続く場合は、この<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ガイド</a>に従ってください。',
      headtype_mismatch: 'モジュールの種類が正しくありません。',
      headtype_none: 'モジュールが検出されませんでした。',
      headtype_unknown: '不明なモジュールが検出されました。',
      install_correct_headtype: '10W/20Wダイオードレーザーモジュールを正しく取り付けて、フレームを実行するための低レーザーを有効にしてください。',
      job_origin_unavailable: 'ジョブの原点を設定するには、ファームウェアバージョン4.3.5 / 5.3.5以上が必要です。今すぐファームウェアを更新しますか？',
      job_origin_warning: '現在、「現在位置」を開始地点として使用しています。衝突を避けるために、レーザーヘッドが正しい位置に移動していることを確認してください。',
      power_too_high: 'パワーが高すぎる',
      power_too_high_confirm: '了解',
      power_too_high_msg: 'より低いレーザー出力（70％未満）を使用すると、レーザー管の寿命が延びます。\n「了解」と入力して続行します。',
      pwm_unavailable: '深度モードは、ファームウェアバージョン 4.3.4 / 5.3.4 以上が必要です。今すぐファームウェアを更新しますか？',
      QcleanScene: '描画をクリアしますか？<br/>これにより、元に戻す履歴も消去されます！',
      start_preview_timeout: '#803 プレビューモードの開始時にタイムアウトが発生しました。マシンまたはBeam Studioを再起動してください。このエラーが続く場合は、この<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ガイド</a>に従ってください。',
    },
    frame_task: 'フレームプレビュー',
    hint: {
      polygon: '+ / - キーを押して、辺を増減します。',
    },
    menu: {
      about: 'Beam Studioについて',
      about_beam_studio: 'Beam Studioについて',
      account: 'アカウント',
      add_new_machine: 'マシン設定',
      align_center: '中央揃え',
      anti_aliasing: 'アンチエイリアス',
      auto_align: '自動整列',
      borderless_mode: 'ボーダーレスモード',
      bug_report: 'バグ報告',
      calibrate_beambox_camera: 'カメラを校正',
      calibrate_beambox_camera_borderless: 'カメラを校正（ボーダーレス）',
      calibrate_camera_advanced: 'カメラを校正（高度な設定）',
      calibrate_diode_module: 'ダイオードレーザーモジュールを校正',
      calibrate_ir_module: '赤外線モジュールを校正',
      calibrate_printer_module: '印刷モジュールを校正',
      calibration: 'キャリブレーション',
      camera_calibration_data: 'カメラキャリブレーションデータ',
      change_logs: '変更ログ',
      clear_scene: '新しいファイル',
      close: 'ウィンドウを閉じる',
      commands: 'コマンド',
      contact: 'お問い合わせ',
      copy: 'コピー',
      cut: '切り取り',
      dashboard: 'ダッシュボード',
      decompose_path: '離散パスを分解する',
      delete: '削除',
      design_market: 'Design Market',
      dev_tool: 'デバッグツール',
      disassemble_use: '逆アセンブル',
      document_setting: 'ドキュメント設定',
      document_setting_short: 'ドキュメント',
      download_data: 'データをダウンロード',
      download_log: 'ログをダウンロード',
      download_log_canceled: 'ログのダウンロードがキャンセルされました',
      download_log_error: '不明のエラーが発生しました、後ほど再試行してください',
      duplicate: '複製',
      edit: '編集',
      example_files: 'サンプルファイル',
      export_BVG: 'BVG',
      export_flux_task: 'FLUXタスク',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'エクスポート',
      export_UV_print: 'UV印刷PDF',
      file: 'ファイル',
      fit_to_window: 'ウィンドウサイズに合わせる',
      follow_us: 'フォローしてください',
      forum: 'コミュニティフォーラム',
      group: 'グループ化',
      help: 'ヘルプ',
      help_center: 'ヘルプセンター',
      hide: 'Beam Studioを隠す',
      hideothers: 'その他を隠す',
      image_crop: 'クロップ',
      image_curve: 'カーブ',
      image_invert: '色を反転',
      image_sharpen: 'シャープ化',
      image_stamp: '面取りを生成',
      image_vectorize: 'ベクトル化',
      import_acrylic_focus_probe: 'アクリルフォーカスプローブ - 3mm',
      import_ador_laser_example: 'Ador レーザーの例',
      import_ador_printing_example_full: 'Ador プリントの例 - フルカラー',
      import_ador_printing_example_single: 'Ador プリントの例 - シングルカラー',
      import_beambox_2_example: 'Beambox IIの例',
      import_beambox_2_focus_probe: 'Beambox II フォーカスプローブ - 3mm',
      import_hello_beambox: 'Beamboxの例',
      import_hello_beamo: 'beamoの例',
      import_hexa_example: 'HEXAの例',
      import_material_printing_test: '材料プリントテスト',
      import_material_testing_cut: '材料切りテスト',
      import_material_testing_engrave: '材料彫りテスト',
      import_material_testing_line: '材料ラインテスト',
      import_material_testing_old: '材料彫りテスト - クラシック',
      import_material_testing_simple_cut: '材料切りテスト - シンプル',
      import_promark_example: 'Promarkの例',
      import_promark_mopa_20w_color: 'MOPA 20W カラーテスト',
      import_promark_mopa_60w_color: 'MOPA 60W カラーテスト',
      import_promark_mopa_100w_color: 'MOPA 100W カラーテスト',
      keyboard_shortcuts: 'ショートカットキー',
      layer_color_config: 'カラー設定',
      layer_setting: 'レイヤー',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'カメラ',
        cloud: 'クラウド',
        discover: 'Discover',
        hardware: 'ハードウェア',
        network: 'ネットワーク',
        player: 'プレイヤー',
        robot: 'ロボット',
        usb: 'USB',
        usblist: 'USBリスト',
      },
      machine_info: 'マシン情報',
      machines: 'マシン',
      manage_account: 'アカウントを管理する',
      material_test: '素材テスト',
      minimize: '最小化',
      my_account: 'マイアカウント',
      network_testing: 'ネットワーク設定をテスト',
      object: 'オブジェクト',
      offset: 'オフセット',
      open: '開く',
      paste: '貼り付け',
      paste_in_place: '所定の位置に貼り付け',
      path: 'パス',
      photo_edit: '画像',
      preferences: '好み',
      promark_color_test: 'Promark カラーテスト',
      questionnaire: 'フィードバックアンケート',
      quit: '終了',
      recent: '最近使用したファイルを開く',
      redo: 'やり直し',
      reload_app: 'アプリをリロード',
      reset: 'リセット',
      rotary_setup: 'ロータリー',
      rotate: '回転',
      samples: 'サンプル',
      save_as: '名前を付けて保存',
      save_scene: 'シーンを保存',
      save_to_cloud: 'クラウドに保存',
      scale: '拡大縮小',
      service: 'サービス',
      set_as_default: 'デフォルトに設定',
      show_gesture_tutorial: 'タッチスクリーンジェスチャー説明',
      show_grids: 'グリッドを表示',
      show_layer_color: 'レイヤーカラーを表示',
      show_rulers: '定規を表示',
      show_start_tutorial: '初めてのチュートリアルを表示',
      show_ui_intro: 'インターフェイスの紹介を表示',
      sign_in: 'サインイン',
      sign_out: 'サインアウト',
      software_update: 'ソフトウェア更新',
      svg_edit: 'SVG',
      switch_to_beta: 'ベータリリースに切り替える',
      switch_to_latest: '安定版リリースに切り替える',
      tools: {
        box_generator: 'ボックスジェネレーター',
        code_generator: 'コードジェネレーター',
        material_test_generator: '材料テストジェネレーター',
        title: 'ツール',
      },
      tutorial: 'デルタファミリープリントのチュートリアルを開始',
      undo: '元に戻す',
      ungroup: 'グループ化解除',
      update: 'アップデートを確認',
      update_firmware: 'ファームウェアをアップデート',
      upload_data: 'データをアップロード',
      using_beam_studio_api: 'Beam Studio APIを使用する',
      view: '見る',
      window: 'ウィンドウ',
      zoom_in: 'ズームイン',
      zoom_out: 'ズームアウト',
      zoom_with_window: '自動的にウィンドウサイズに合わせる',
    },
    preview: 'プレビュー',
    preview_press_esc_to_stop: 'ESCを押してカメラプレビューを停止します。',
    rename_tab: 'タブの名前を変更',
    select_machine: 'マシンの選択',
    tag_names: {
      dxf: 'DXFオブジェクト',
      ellipse: '楕円',
      g: 'グループ',
      image: 'イメージ',
      line: 'ライン',
      multi_select: '複数オブジェクト',
      no_selection: '選択なし',
      pass_through_object: 'オブジェクトを通過',
      path: 'パス',
      polygon: 'ポリゴン',
      rect: '矩形',
      svg: 'SVGオブジェクト',
      text: 'テキスト',
      text_path: 'パステキスト',
      use: 'インポートされたオブジェクト',
    },
    task_preview: 'パスプレビュー',
    titles: {
      settings: '環境設定',
    },
    untitled: '無題',
  },
  topmenu: {
    credit: 'Beam Studioの開発は<a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a>オープンソースプロジェクトと他の<a target="_blank" href="https://flux3dp.com/credits/">オープンソースソフトウェア</a>の協力で完成されたものです。',
    device: {
      download_log_canceled: 'ログのダウンロードをキャンセルしました',
      download_log_error: '不明なエラーが発生しました。後で再試行してください',
      log: {
        usblist: 'USBリスト',
      },
      network_test: 'ネットワークをテスト',
    },
    file: {
      all_files: 'すべてのファイル',
      clear_recent: '最近開いた項目をクリア',
      converting: '画像に変換中…',
      fcode_files: 'FLUXコード',
      import: 'インポート',
      jpg_files: 'JPG',
      label: 'ファイル',
      path_not_exit: 'このパスは、デスク上にもう存在しないようです。',
      png_files: 'PNG',
      save_fcode: 'FLUXタスクをエクスポート',
      save_jpg: 'JPGをエクスポート',
      save_png: 'PNGをエクスポート',
      save_scene: 'シーンを保存',
      save_svg: 'SVGをエクスポート',
      scene_files: 'Beam Studioシーン',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'バージョン',
  },
  tutorial: {
    ask_retry_calibration: 'カメラをもう一度キャリブレーションしますか？',
    camera_calibration_failed: 'カメラの較正に失敗しました',
    gesture: {
      click: 'タッチしてオブジェクトを選択します。',
      drag: 'ドラッグして複数のオブジェクトを選択します。',
      hold: 'ロングプレスしてコンテキストメニューを開きます。',
      pan: '二本指でキャンバスをスクロールします。',
      zoom: '二本指を閉じてキャンバスを縮小し、広げてキャンバスを拡大します。',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'チュートリアル用のマシンを探しています',
    needNewInterfaceTutorial: 'Beam Studioの新しいインターフェースのチュートリアルを開始しますか？<br/>([ヘルプ] > [インターフェース紹介を表示]をクリックして、後で開始することもできます。)',
    needNewUserTutorial: 'チュートリアルを開始しますか？<br/>([ヘルプ] > [スタートチュートリアルを表示]をクリックして、後で開始することもできます。)',
    newInterface: {
      add_new_layer: '新しいレイヤーを追加',
      align_controls: '整列管理',
      basic_shapes: '基本形状',
      camera_preview: 'カメラプレビュー',
      drag_to_sort: 'ドラッグして並べ替える',
      end_alert: '新しいインターフェースの紹介を終了してもよろしいですか？',
      flip: 'フリップ',
      group_controls: 'グループ管理',
      layer_controls: '右クリックしてレイヤーコントロールを呼び出す:\nレイヤーの複製 / マージ / ロック / 削除',
      object_actions: 'オブジェクトアクション',
      pen_tool: 'ペンツール',
      rename_by_double_click: 'ダブルクリックで名前を変更',
      select_image_text: '選択 / 画像 / テキスト',
      select_machine: 'マシンの選択',
      shape_operation: '形状操作',
      start_work: '作業開始',
      switch_between_layer_panel_and_object_panel: 'レイヤーパネルとオブジェクトパネルを切り替える',
    },
    newUser: {
      add_new_layer: '新しいレイヤーを追加する',
      adjust_focus: '2. フォーカスを調整する',
      close_cover: '3. カバーを閉じます',
      drag_to_draw: 'ドラッグして描画',
      draw_a_circle: '円を描く',
      draw_a_rect: '長方形を描く',
      end_alert: 'チュートリアルを終了してもよろしいですか？',
      end_preview_mode: 'プレビューモードを終了する',
      infill: 'インフィルをオンにする',
      please_select_wood_cutting: '「木材 - 切断」プリセットを選択してください。',
      please_select_wood_engraving: '「木材 - 彫刻」プリセットを選択してください。',
      preview_the_platform: 'プラットフォームをプレビューする',
      put_wood: '1. サンプル木材を入れます',
      send_the_file: 'ファイルを送信',
      set_preset_wood_cut: 'プリセットの設定：木材 - 切断',
      set_preset_wood_engraving: 'プリセットの設定：木材 - 彫刻',
      switch_to_layer_panel: 'レイヤーパネルに切り替え',
      switch_to_object_panel: 'オブジェクトパネルに切り替える',
      switch_to_preview_mode: 'プレビューモードに切り替え',
    },
    next: '次ヘ',
    retry: 'リトライ',
    set_connection: '接続を設定',
    skip: 'スキップ',
    skip_tutorial: 'チュートリアルをスキップしました。 [ヘルプ]> [初めてのチュートリアルを表示]をクリックすると、いつでもチュートリアルを開始できます',
    suggest_calibrate_camera_first: '最適な結果を得るために、ユーザーには各プレビューの前にカメラを最初にキャリブレートし、再びフォーカスを合わせることをお勧めします。<br/>キャリブレーションを実行するかどうかを確認しますか？<br/>([メニュー] > [マシン] > [あなたのマシン] > "カメラのキャリブレーション"をクリックして、後で実行するかどうかを確認することもできます。)',
    tutorial_complete: '紹介が終わりました、作っていきましょう！',
    unable_to_find_machine: 'チュートリアル用のマシンが見つかりません。接続設定ページに移動しますか？再試行するか、チュートリアルをスキップしますか？',
    welcome: 'ようこそ',
  },
  update: {
    cannot_reach_internet: '#823 サーバーにアクセスできません。<br/>インターネット接続を確認してください',
    download: 'オンラインアップデート',
    firmware: {
      caption: 'このマシンのファームウェアアップデートがご利用いただけます',
      confirm: 'アップロード',
      firmware_too_old_update_by_sdcard: 'ファームウェアバージョンが古すぎます。SDカードを使用してファームウェアを更新してください。',
      force_update_message: '#814 最新のファームウェアバージョンにアップデートしてください。',
      latest_firmware: {
        cant_get_latest: '最新のファームウェアの情報を取得できません。',
        caption: 'マシンのファームウェアアップデート',
        message: 'あなたは最新のマシンファームウェアを使用しています',
        still_update: 'アップデート',
      },
      message_pattern_1: '「%s」は現在、ファームウェアアップデートの準備ができています。',
      message_pattern_2: '%sファームウェアv%sは現在ご利用いただけます - v%sがあります。',
      too_old_for_web: '現在のファームウェアバージョンはv%sです。\nオンライン版Beam Studioを使用されたい場合、最新バージョンに更新してください。',
      update_fail: '#822 アップデート失敗',
      update_success: 'ファームウェアアップデートが正常にアップロードされました',
      upload_file: 'ファームウェアのアップロード（*.bin / *.fxfw）',
    },
    install: 'インストール',
    later: '後で',
    preparing: '準備中…',
    release_note: 'リリースノート：',
    skip: 'このバージョンをスキップ',
    software: {
      available_switch: 'Beam Studio v%s が現在、ご利用いただけます\nv%sがあります。\nこのバージョンを切り替えますか？',
      available_update: 'Beam Studio v%s が現在、ご利用いただけます\nv%sがあります。\nアップデートをダウンロードしますか？',
      caption: 'Beam Studioのソフトウェアアップデートがご利用いただけます',
      check_update: 'アップデートを確認',
      checking: 'アップデートの確認中',
      downloading: 'アップデートのダウンロード中…',
      install_or_not: 'アップデートがダウンロードされました。今すぐ再起動してインストールしますか？',
      no: 'いいえ',
      no_response: 'サーバーに接続できませんでした。ネットワーク設定を確認してください。',
      not_found: 'あなたは、最新バージョンのBeam Studioを使用しています。',
      switch_or_not: 'がダウンロードされました。今すぐ再起動してインストールしますか？',
      switch_version: 'バージョンを切り替える',
      switch_version_not_found: '切り替えられるバージョンが見つかりません',
      update_for_ador: '現在のソフトウェアバージョン %s は古いです。Ador用のBeam Studioの最新バージョンをダウンロードしてください。',
      yes: 'はい',
    },
    update: 'アップデート',
    updating: 'アップデート中…',
    upload: 'アップロード',
  },
  web_cam: {
    no_device: 'カメラデバイスを検出できません。カメラを再接続して、もう一度お試しください。',
    no_permission: 'Beam Studioにはカメラにアクセスする許可がありません。ブラウザ設定またはシステム設定でBeam Studioに許可が付与されていることを確認してください。',
  },
  z_speed_limit_test: {
    alert_before: '曲面彫刻を開始する前に、システムは負荷テストを実施し、過剰な重量によるステップ損失を防ぎます。',
    alert_failed: '現在のオブジェクトの重量が、現在のZ軸速度の負荷制限を超えています。マシンパネルでZ軸の速度を下げるか、彫刻オブジェクトを交換してから再テストしてください。',
    ignore: '無視する',
    retest: '再テスト',
    testing: '負荷テストを実行中...',
  },
};

export default lang;
