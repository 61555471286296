import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Interrompi',
    cancel: 'Annulla',
    caption: 'Errore',
    close: 'Chiudi',
    confirm: 'Conferma',
    dont_save: 'Non salvare',
    dont_show_again: 'Non mostrare nuovamente',
    duplicated_preset_name: 'Nome preset duplicato',
    error: 'UH-OH',
    info: 'INFO',
    instruction: 'Istruzione',
    learn_more: 'Per saperne di più',
    no: 'No',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Oops...',
    retry: 'Riprova',
    save: 'Salva',
    stop: 'Interrompi',
    warning: 'ATTENZIONE',
    yes: 'Sì',
  },
  auto_fit: {
    artwork_size: "Dimensione dell'opera",
    error_tip1: "1. L'opera d'arte è posizionata correttamente sul materiale?",
    error_tip2: '2. I contorni del materiale sono abbastanza chiari per essere riconosciuti?',
    failed_to_find_contour: "Impossibile trovare contorni per l'adattamento automatico",
    learn_more: 'Visualizza il tutorial.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Spostamento X',
    offset_y: 'Spostamento Y',
    pattern_size: 'Dimensione del motivo',
    position_artwork: "Posiziona l'opera d'arte",
    position_step1: 'Regola la dimensione e la posizione della tua opera sul motivo.',
    position_step2: 'Fai clic su "Applica" per applicare Auto Fit a questo motivo.',
    preview_first: "Eseguire prima l'anteprima della fotocamera.",
    reset_position: 'Ripristina posizione',
    rotation: 'Ruota',
    select_a_pattern: 'Seleziona un modello',
    selected_artwork: "Opera d'arte selezionata",
    step1: "Scegli un modello in cui posizionare l'opera d'arte.",
    step2: 'Se non viene trovato alcun modello, anteprima nuovamente la fotocamera e applica Adattamento Automatico.',
    step3: "Se non riesce ancora a trovare il modello corretto, assicurati che il tuo pezzo sia chiaramente riconoscibile e che l'area di lavoro sia pulita da detriti.",
    title: 'Adattamento Automatico',
  },
  barcode_generator: {
    bar_height: 'Altezza della barra',
    bar_width: 'Larghezza della barra',
    barcode: {
      invalid_value: 'Il valore non è valido per il formato selezionato.',
    },
    font: 'Carattere',
    font_size: 'Dimensione del carattere',
    hide_text: 'Nascondi testo',
    invert_color: 'Inverti colore',
    text_margin: 'Margine del testo',
  },
  beambox: {
    announcement_panel: {
      title: 'Annuncio',
    },
    banner: {
      auto_feeder: 'Modalità Alimentazione Automatica',
      camera_preview: 'Anteprima',
      camera_preview_borderless_mode: '(Parte Inferiore Aperta)',
      curve_engraving: 'Modalità Incisione Curva',
      pass_through: 'Modalità Passante',
      rotary: 'Modalità Rotativa',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Converti testo in percorso...',
      export_file_error_ask_for_upload: 'Esportazione file non riuscita. Sei disposto a fornire la scena di lavoro al team di sviluppo per la segnalazione di bug?',
      retreive_image_data: 'Recupera dati immagine...',
    },
    context_menu: {
      copy: 'Copia',
      cut: 'Taglia',
      delete: 'Elimina',
      duplicate: 'Duplica',
      group: 'Raggruppa',
      move_back: 'Sposta in fondo',
      move_down: 'Sposta indietro',
      move_front: 'Porta in primo piano',
      move_up: 'Sposta avanti',
      paste: 'Incolla',
      paste_in_place: 'Incolla nella posizione originale',
      ungroup: 'Separa',
    },
    document_panel: {
      add_on: 'Componenti aggiuntivi',
      auto_feeder: 'Alimentatore automatico',
      auto_feeder_scale: 'Scala del alimentatore automatico',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Aperto in basso',
      current_position: 'Posizione Attuale',
      disable: 'Disabilita',
      document_settings: 'Impostazioni documento',
      enable: 'Abilita',
      enable_autofocus: 'Messa a fuoco automatica',
      enable_diode: 'Laser a diodi',
      engrave_dpi: 'Risoluzione',
      frame_before_start: 'Frame prima di eseguire',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Alto',
      job_origin: 'Origine del Lavoro',
      laser_source: 'Sorgente laser',
      low: 'Basso',
      machine: 'Macchina',
      medium: 'Medio',
      notification: {
        changeFromPrintingWorkareaTitle: 'Vuoi convertire gli strati di stampa nelle aree di taglio laser?',
      },
      origin: 'Origine',
      pass_through: 'Passaggio',
      pass_through_height_desc: "Inserisci la lunghezza dell'oggetto per estendere l'area di lavoro.",
      rotary_mode: 'Rotativo',
      scale: 'Scala',
      start_from: 'Inizia Da',
      start_position: 'Posizione di Partenza',
      start_work_button: 'Pulsante Esegui',
      ultra: 'Altissimo',
      workarea: 'Area di lavoro',
    },
    image_trace_panel: {
      back: 'Indietro',
      brightness: 'Luminosità',
      cancel: 'Annulla',
      contrast: 'Contrasto',
      next: 'Avanti',
      okay: 'OK',
      threshold: 'Soglia',
      tuning: 'Parametri',
    },
    layer_color_config_panel: {
      add: 'Aggiungi',
      add_config: 'Aggiungi colore',
      color: 'Colore',
      default: 'Ripristina predefiniti',
      in_use: 'Questo colore è in uso.',
      layer_color_config: 'Configurazioni colore strato',
      no_input: 'Inserire un codice colore esadecimale valido.',
      power: 'Potenza',
      repeat: 'Esegui',
      speed: 'Velocità',
      sure_to_delete: 'Sei sicuro di voler eliminare questa impostazione colore?',
      sure_to_reset: 'Perderai tutti i parametri personalizzati, sei sicuro di ripristinare le impostazioni predefinite?',
    },
    left_panel: {
      borderless_blind_area: 'Area non incisa',
      borderless_preview: 'Anteprima fotocamera modalità fondo aperto',
      diode_blind_area: 'Area cieca aggiuntiva laser ibrido',
      ellipse: 'Ellisse',
      image: 'Immagine',
      label: {
        adjust_height: 'Regola altezza',
        array: 'Matrice',
        boxgen: 'Boxgen',
        choose_camera: 'Fotocamera',
        clear_preview: 'Cancella anteprima',
        cursor: 'Seleziona',
        curve_engraving: {
          clear_area: 'Cancella area selezionata',
          exit: 'Esci',
          preview_3d_curve: 'Anteprima dell’incisione della curva',
          select_area: 'Seleziona area',
          title: 'Incisione della curva',
        },
        end_preview: 'Termina anteprima',
        line: 'Linea',
        live_feed: 'Live Feed',
        my_cloud: 'La mia nuvola',
        oval: 'Ovale',
        pass_through: 'Passaggio',
        pen: 'Penna',
        photo: 'Immagine',
        polygon: 'Poligono',
        preview: 'Anteprima fotocamera',
        qr_code: 'Codice QR',
        rect: 'Rettangolo',
        shapes: 'Elementi',
        text: 'Testo',
        trace: 'Traccia immagine',
      },
      line: 'Linea',
      rectangle: 'Rettangolo',
      text: 'Testo',
      unpreviewable_area: 'Area cieca',
    },
    network_testing_panel: {
      average_response: 'Tempo medio di risposta',
      cannot_connect_1: "#840 Impossibile connettersi all'IP di destinazione.",
      cannot_connect_2: "#840 Impossibile connettersi all'IP di destinazione. Assicurarsi che il dispositivo di destinazione sia sulla stessa rete.",
      cannot_get_local: "Accesso all'indirizzo IP locale non riuscito.",
      connection_quality: 'Qualità della connessione',
      device_not_on_list: "#842 La macchina non è nell'elenco, ma la qualità della connessione è >70 e il tempo medio di risposta è <100ms",
      empty_ip: "#818 Inserire prima l'indirizzo IP di destinazione.",
      end: 'Termina',
      fail_to_start_network_test: '#817 Impossibile avviare il test di rete.',
      hint_connect_camera_timeout: "Timeout durante l'avvio dell'anteprima della fotocamera?",
      hint_connect_failed_when_sending_job: "Connessione non riuscita durante l'invio di un lavoro?",
      hint_device_often_on_list: "La macchina spesso non viene trovata nell'elenco?",
      insert_ip: 'Indirizzo IP dispositivo di destinazione:',
      invalid_ip: '#818 Indirizzo IP non valido',
      ip_startswith_169: "#843 L'indirizzo IP della macchina inizia con 169.254",
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Questo errore si verifica di solito a causa di autorizzazioni insufficienti.\nEseguire "sudo beam-studio --no-sandbox" nel terminale per ottenere le autorizzazioni e eseguire il test di rete.',
      local_ip: 'Indirizzo IP locale:',
      network_testing: 'Test di rete',
      network_unhealthy: '#841 Qualità della connessione <70 o tempo medio di risposta >100ms',
      start: 'Inizia',
      test_completed: 'Test completato',
      test_fail: 'Test fallito',
      testing: 'Test di rete in corso...',
    },
    object_panels: {
      lock_desc: 'Mantieni proporzioni (MAIUSC)',
      text_to_path: {
        check_thumbnail_warning: "Alcuni testi sono stati modificati in altri font durante la conversione in percorsi e alcuni caratteri potrebbero non essere convertiti correttamente.\nSi prega di controllare nuovamente l'anteprima prima di inviare il task.",
        error_when_parsing_text: 'Errore durante la conversione del testo in percorso',
        font_substitute_pop: 'Il tuo testo contiene caratteri non supportati dal carattere corrente. <br/>Vuoi usare <strong>%s</strong> come sostituzione?',
        retry: 'Riprova più tardi o scegli un altro carattere',
        use_current_font: 'Usa font corrente',
      },
      wait_for_parsing_font: 'Analisi font in corso... Attendere un secondo',
    },
    path_preview: {
      current_position: 'Posizione corrente',
      cut_distance: 'Distanza di taglio',
      cut_time: 'Tempo di taglio',
      end_preview: 'Fine anteprima',
      estimated_time: 'Tempo totale stimato',
      invert: 'Inverti',
      pause: 'Pausa',
      play: 'Riproduci',
      play_speed: 'Velocità di riproduzione',
      preview_info: 'Informazioni di anteprima',
      rapid_distance: 'Distanza di spostamento',
      rapid_time: 'Tempo di spostamento',
      remark: '* Tutte le informazioni sono valori stimati come riferimento.',
      size: 'Dimensioni',
      start_here: 'Inizia qui',
      stop: 'Stop',
      travel_path: 'Percorso di spostamento',
    },
    photo_edit_panel: {
      aspect_ratio: "Rapporto d'aspetto",
      brightness: 'Luminosità',
      brightness_and_contrast: 'Luminosità / Contrasto',
      cancel: 'Annulla',
      circumference: 'Circonferenza',
      compare: 'Confronta',
      contrast: 'Contrasto',
      crop: 'Ritaglia',
      curve: 'Curva',
      diameter: 'Diametro',
      free: 'Libero',
      invert: 'Inverti colore',
      okay: 'OK',
      original: 'Originale',
      phote_edit: 'Modifica foto',
      processing: 'Elaborazione',
      radius: 'Raggio',
      rotary_warped: 'Deformato Rotativo',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Nitidezza',
      sharpness: 'Nitidezza',
      start: 'Inizio',
      warp: 'Deformare',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Vai',
        insufficient_credit: 'Sei a corto di credito',
        insufficient_credit_msg: 'Non puoi utilizzare %s. Vai al centro membri e acquista crediti AI',
        relogin_to_use: "Effettua nuovamente l'accesso per utilizzare questa funzione",
      },
      auto_feeder_origin: 'Utilizza l’anteprima della cornice per verificare il percorso e l’area della testa laser, quindi regola la posizione del materiale o la posizione di inizio incisione in base ai risultati per evitare collisioni durante il processo di incisione.',
      auto_switch_tab: {
        message: 'Una nuova opzione per passare automaticamente tra il pannello Livelli e il pannello Oggetti è stata aggiunta alle Preferenze. Questa opzione è disabilitata per impostazione predefinita. Vuoi abilitare il cambio automatico ora? <br/>Puoi cambiare questa impostazione in qualsiasi momento nelle Preferenze.',
        title: 'Commutazione Automatica tra il Pannello dei Livelli e degli Oggetti',
      },
      backend_connect_failed_ask_to_upload: '#802 Si sono verificati errori continui durante il tentativo di connessione al backend. Vuoi caricare il rapporto di bug?',
      backend_error_hint: 'Le funzionalità potrebbero non funzionare correttamente a causa di errori backend.',
      both_power_and_speed_too_high: 'Utilizzare una potenza laser inferiore prolungherà la durata del tubo laser. Inoltre, una velocità troppo elevata a questa risoluzione può comportare una qualità inferiore della tonalità di incisione.',
      bug_report: 'Segnalazione di bug',
      change_workarea_before_preview: "L'area di lavoro di %s non corrisponde all'area di lavoro attualmente impostata. Vuoi passare all'area di lavoro corrente?",
      convert_to_path_fail: 'Impossibile convertire in percorso.',
      dxf_bounding_box_size_over: "Le dimensioni del disegno superano l'area di lavoro. Sposta il tuo disegno più vicino all'origine nel tuo software CAD, o assicurati che l'unità sia impostata correttamente.",
      dxf_version_waring: 'La versione di questo file DXF non è 2013, potrebbero esserci potenziali problemi di incompatibilità.',
      facebook_group_invitation: {
        already_joined: 'Già iscritto',
        join_now: 'Iscriviti ora',
        later: 'Forse più tardi',
        message: "Unisciti al nostro gruppo Facebook ufficiale per connetterti con altri utenti laser FLUX, discutere dei laser FLUX, condividere opere laser e rimanere aggiornato sulle ultime novità sui nostri prodotti. Non vediamo l'ora di vederti lì!",
        title: 'Unisciti al gruppo ufficiale degli utenti FLUX',
      },
      import_file_contain_invalid_path: '#808 Il file SVG importato contiene percorsi immagine non validi. Assicurarsi che tutti i file immagine esistano o incorporare le immagini nel file',
      import_file_error_ask_for_upload: 'Non è stato possibile importare il file SVG. Vuoi fornire il file al team di sviluppo per la segnalazione di bug?',
      layer_by_color: 'Colore',
      layer_by_layer: 'Strato',
      loading_image: 'Caricamento immagine in corso...',
      more_than_two_object: 'Troppi oggetti. Supporto solo per 2 oggetti',
      mouse: 'Mouse',
      no_support_text: 'Beam Studio al momento non supporta il testo. Convertire il testo in tracciato prima di importare.',
      nolayer: 'Strato singolo',
      not_support_object_type: 'Tipo di oggetto non supportato',
      or_turn_off_borderless_mode: ' O disattiva la modalità Open Bottom.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Errore: comando pdf2svg non trovato. Installa pdf2svg con il tuo gestore di pacchetti (es. "yum install pdf2svg" o "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Errore durante la conversione da PDF a SVG:',
      },
      progress: {
        calculating: 'Calcolo',
        uploading: 'Caricamento',
      },
      questionnaire: {
        caption: 'Aiutaci a compilare il questionario',
        message: 'Aiutaci a compilare il questionario per migliorare Beam Studio.',
        no_questionnaire_available: 'Al momento non è disponibile alcun questionario.',
        unable_to_get_url: 'Impossibile ottenere il link al questionario tramite internet. Verifica la tua connessione internet.',
      },
      recommend_downgrade_software: 'Abbiamo rilevato una versione firmware precedente. Stiamo lavorando attivamente per risolvere i problemi di compatibilità, ma per ora consigliamo di tornare a Beam Studio 1.9.5.',
      recommend_upgrade_firmware: "Abbiamo rilevato una versione firmware obsoleta. Stiamo lavorando attivamente per risolvere eventuali problemi di compatibilità, ma per il momento consigliamo di aggiornare all'ultima versione firmware.",
      save_unsave_changed: 'Vuoi salvare le modifiche non salvate?',
      select_at_least_two: 'Selezionare due oggetti per procedere',
      select_first: 'Selezionare prima un oggetto.',
      select_import_method: 'Seleziona lo stile di stratificazione:',
      select_import_module: 'Seleziona modulo:',
      sentry: {
        message: "Sei d'accordo a caricare automaticamente le informazioni rilevanti al team di sviluppo in caso di errori?",
        title: 'Rendiamo Beam Studio migliore insieme',
      },
      should_update_firmware_to_continue: '#814 Il firmware non supporta questa versione di Beam Studio. Aggiornare il firmware per continuare. (Menu > Macchina > [La tua macchina] > Aggiorna firmware)',
      speed_too_high_lower_the_quality: 'Una velocità troppo elevata a questa risoluzione può comportare una qualità inferiore della tonalità di incisione.',
      still_continue: 'Continua',
      successfully_uploaded: 'Caricamento file riuscito.',
      svg_1_1_waring: 'La versione di questo file SVG è v 1.1, potrebbero esserci potenziali problemi di incompatibilità.',
      svg_image_path_waring: 'Questo file SVG contiene <image> che caricano dal percorso file. Questo potrebbe causare errori durante il caricamento.\\\\nPer evitare questo rischio, utilizza immagini incorporate quando esporti in SVG.',
      text_to_path: {
        caption: 'Convertitore di testo in percorso 2.0',
        message: "Beam Studio introduce ora un nuovo convertitore di testo in percorso (Convertitore 2.0), che produce risultati più affidabili! Vuoi passare ad esso ora?\\nPuoi anche regolare questa impostazione più tardi nella sezione 'Convertitore di testo in percorso' all'interno delle preferenze.",
      },
      too_fast_for_auto_feeder: "L'uso di velocità troppo elevate in strati contenenti oggetti di percorso può ridurre la precisione di taglio.\nNon consigliamo di utilizzare velocità superiori a %(limit)s durante il taglio con l'Auto Feeder.",
      too_fast_for_auto_feeder_and_constrain: 'I seguenti strati: %(layers)s \ncontengono oggetti di percorso vettoriale e hanno una velocità superiore a %(limit)s.\nLa velocità di taglio per gli oggetti di percorso vettoriale con Auto Feeder sarà limitata a %(limit)s.\nPuoi rimuovere questa limitazione nelle Impostazioni Preferenze.',
      too_fast_for_curve: 'L’uso di velocità troppo elevate per strati contenenti oggetti di incisione a curva può ridurre la precisione dell’incisione.\nSi consiglia di mantenere la velocità al di sotto di %(limit)s per risultati ottimali.',
      too_fast_for_curve_and_constrain: 'I seguenti strati: %(layers)s superano il limite di velocità di %(limit)s.\nLa velocità di incisione per gli oggetti curvi sarà limitata a %(limit)s. Puoi rimuovere questo limite nelle Impostazioni delle Preferenze.',
      too_fast_for_path: 'Una velocità troppo elevata negli strati contenenti oggetti tracciato può comportare una precisione inferiore durante il taglio. Non consigliamo di usare una velocità superiore a %(limit)s durante il taglio.',
      too_fast_for_path_and_constrain: 'I seguenti strati: %(layers)s contengono oggetti tracciato vettoriali e hanno una velocità che supera i %(limit)s. La velocità di taglio degli oggetti tracciato vettoriali sarà limitata a %(limit)s. È possibile rimuovere questo limite nelle impostazioni delle preferenze.',
      touchpad: 'Touchpad',
      ungroup_use: 'Questo separerà il gruppo di file DXF o SVG importati. Poiché il file potrebbe contenere un gran numero di elementi, potrebbe volerci del tempo per separare il gruppo. Sei sicuro di voler procedere?',
      upload_failed: '#819 Caricamento file non riuscito.',
      upload_file_too_large: '#819 Il file è troppo grande per il caricamento.',
      vectorize_shading_image: "Le immagini sfumate richiedono più tempo per essere tracciate e sono soggette a rumore. Disattiva il gradiente dell'immagine prima di eseguire.",
    },
    rating_panel: {
      description: 'Se ti piace Beam Studio, ti saremmo molto grati se potessi valutarci.',
      thank_you: 'Grazie!',
      title: 'Ti piace Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Avanzate',
        backlash: 'Gioco',
        bi_directional: 'Bidirezionale',
        by: 'di',
        ce_z_high_speed: 'Asse Z ad alta velocità',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Regolazione canale',
        color_adjustment_short: 'Canale',
        color_strength: 'Intensità',
        cross_hatch: 'Tratteggio Incrociato',
        custom_preset: 'Personalizzato',
        cut: 'Taglia',
        diode: 'Laser a diodi',
        do_not_adjust_default_para: 'I parametri predefiniti non possono essere modificati.',
        dottingTime: 'Tempo di puntatura',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Incisione',
            acrylic_3mm_cutting: 'Acrilico - 1/8″ Taglio',
            acrylic_5mm_cutting: 'Acrilico - 3/16″ Taglio',
            acrylic_8mm_cutting: 'Acrilico - 1/4″ Taglio',
            acrylic_10mm_cutting: 'Acrilico - 3/8″ Taglio',
            acrylic_engraving: 'Acrilico - Incisione',
            acrylic_printing: 'Acrilico - Stampa',
            aluminum_engraving: 'Alluminio - Incisione',
            aluminum_light: 'Alluminio (chiaro)',
            bamboo_printing: 'Bamboo - Stampa',
            black_abs: 'ABS nero',
            black_acrylic_3mm_cutting: 'Acrilico nero - 1/8″ Taglio',
            black_acrylic_5mm_cutting: 'Acrilico nero - 3/16″ Taglio',
            black_acrylic_engraving: 'Acrilico nero - Incisione',
            brass_dark: 'Ottone (scuro)',
            brass_engraving: 'Ottone - Incisione',
            brass_light: 'Ottone (chiaro)',
            canvas_printing: 'Tela - Stampa',
            cardstock_printing: 'Cartoncino - Stampa',
            copper: 'Rame',
            cork_printing: 'Sughero - Stampa',
            denim_1mm_cutting: 'Denim - 1/32″ Taglio',
            fabric_3mm_cutting: 'Tessuto - 1/8″ Taglio',
            fabric_5mm_cutting: 'Tessuto - 3/16″ Taglio',
            fabric_engraving: 'Tessuto - Incisione',
            fabric_printing: 'Tessuto - Stampa',
            flat_stone_printing: 'Pietra piatta - Stampa',
            glass_bw_engraving: 'Vetro - Incisione',
            glass_printing: 'Vetro - Stampa',
            gloss_leather_printing: 'Pelle lucida - Stampa',
            gold_engraving: 'Oro - Incisione',
            iron_engraving: 'Ferro - Incisione',
            leather_3mm_cutting: 'Pelle - 1/8″ Taglio',
            leather_5mm_cutting: 'Pelle - 3/16″ Taglio',
            leather_engraving: 'Pelle - Incisione',
            mdf_3mm_cutting: 'MDF - 1/8″ Taglio',
            mdf_5mm_cutting: 'MDF - 3/16″ Taglio',
            mdf_engraving: 'MDF - Incisione',
            metal_bw_engraving: 'Metallo - Incisione',
            opaque_acrylic: 'Acrilico opaco',
            pc_printing: 'PC - Stampa',
            rubber_bw_engraving: 'Gomma - Incisione',
            silver_engraving: 'Argento - Incisione',
            stainless_steel_bw_engraving_diode: 'Metallo - Incisione (Laser a diodi)',
            stainless_steel_dark: 'Acciaio inossidabile (scuro)',
            stainless_steel_engraving: 'Acciaio inossidabile - Incisione',
            stainless_steel_light: 'Acciaio inossidabile (chiaro)',
            stainless_steel_printing: 'Acciaio inossidabile - Stampa',
            steel_engraving_spray_engraving: 'Metallo - Incisione',
            stone: 'Pietra',
            ti_engraving: 'Titanio - Incisione',
            titanium_dark: 'Titanio (scuro)',
            titanium_light: 'Titanio (chiaro)',
            white_abs: 'ABS bianco',
            wood_3mm_cutting: 'Legno - 1/8″ Taglio',
            wood_5mm_cutting: 'Legno - 3/16″ Taglio',
            wood_7mm_cutting: 'Legno - 1/4″ Taglio',
            wood_8mm_cutting: 'Legno - 1/4″ Taglio',
            wood_10mm_cutting: 'Legno - 3/8″ Taglio',
            wood_engraving: 'Legno - Incisione',
            wood_printing: 'Legno - Stampa',
          },
          mm: {
            abs_engraving: 'ABS - Incisione',
            acrylic_3mm_cutting: 'Acrilico - 3mm Taglio',
            acrylic_5mm_cutting: 'Acrilico - 5mm Taglio',
            acrylic_8mm_cutting: 'Acrilico - 8mm Taglio',
            acrylic_10mm_cutting: 'Acrilico - 10mm Taglio',
            acrylic_engraving: 'Acrilico - Incisione',
            acrylic_printing: 'Acrilico - Stampa',
            aluminum_engraving: 'Alluminio - Incisione',
            aluminum_light: 'Alluminio (chiaro)',
            bamboo_printing: 'Bambù - Stampa',
            black_abs: 'ABS nero',
            black_acrylic_3mm_cutting: 'Acrilico nero - 3mm Taglio',
            black_acrylic_5mm_cutting: 'Acrilico nero - 5mm Taglio',
            black_acrylic_engraving: 'Acrilico nero - Incisione',
            brass_dark: 'Ottone (scuro)',
            brass_engraving: 'Ottone - Incisione',
            brass_light: 'Ottone (chiaro)',
            canvas_printing: 'Tela - Stampa',
            cardstock_printing: 'Cartoncino - Stampa',
            copper: 'Rame',
            cork_printing: 'Sughero - Stampa',
            denim_1mm_cutting: 'Denim - 1mm Taglio',
            fabric_3mm_cutting: 'Tessuto - 3mm Taglio',
            fabric_5mm_cutting: 'Tessuto - 5mm Taglio',
            fabric_engraving: 'Tessuto - Incisione',
            fabric_printing: 'Tessuto - Stampa',
            flat_stone_printing: 'Pietra piatta - Stampa',
            glass_bw_engraving: 'Vetro - Incisione',
            glass_printing: 'Vetro - Stampa',
            gloss_leather_printing: 'Pelle lucida - Stampa',
            gold_engraving: 'Oro - Incisione',
            iron_engraving: 'Ferro - Incisione',
            leather_3mm_cutting: 'Pelle - 3mm Taglio',
            leather_5mm_cutting: 'Pelle - 5mm Taglio',
            leather_engraving: 'Pelle - Incisione',
            mdf_3mm_cutting: 'MDF Nuova Zelanda - 3mm Taglio',
            mdf_5mm_cutting: 'MDF Nuova Zelanda - 5mm Taglio',
            mdf_engraving: 'MDF Nuova Zelanda - Incisione',
            metal_bw_engraving: 'Metallo - Incisione',
            opaque_acrylic: 'Acrilico opaco',
            pc_printing: 'PC - Stampa',
            rubber_bw_engraving: 'Gomma - Incisione',
            silver_engraving: 'Argento - Incisione',
            stainless_steel_bw_engraving_diode: 'Metallo - Incisione (Laser a diodi)',
            stainless_steel_dark: 'Acciaio inossidabile (scuro)',
            stainless_steel_engraving: 'Acciaio inossidabile - Incisione',
            stainless_steel_light: 'Acciaio inossidabile (chiaro)',
            stainless_steel_printing: 'Acciaio inossidabile - Stampa',
            steel_engraving_spray_engraving: 'Metallo - Incisione',
            stone: 'Pietra',
            ti_engraving: 'Titanio - Incisione',
            titanium_dark: 'Titanio (scuro)',
            titanium_light: 'Titanio (chiaro)',
            white_abs: 'ABS bianco',
            wood_3mm_cutting: 'Legno - 3mm Taglio',
            wood_5mm_cutting: 'Legno - 5mm Taglio',
            wood_7mm_cutting: 'Legno - 7mm taglio',
            wood_8mm_cutting: 'Legno - 8mm Taglio',
            wood_10mm_cutting: 'Legno - 10mm Taglio',
            wood_engraving: 'Legno - Incisione',
            wood_printing: 'Legno - Stampa',
          },
          parameters: 'Preset',
          save: 'Aggiungi parametri correnti',
        },
        engrave: 'Incide',
        existing_name: 'Questo nome è già stato utilizzato.',
        fill_angle: 'Angolo di Riempimento',
        fill_interval: 'Intervallo di Riempimento',
        fill_setting: 'Impostazioni di Riempimento',
        filled_path_only: 'Solo per percorsi di riempimento',
        focus_adjustment: 'Regolazione messa a fuoco',
        frequency: 'Frequenza',
        gradient_only: 'Solo per immagini sfumate',
        halftone: 'Semitono',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Altezza oggetto',
        ink_saturation: 'Saturazione',
        ink_type: {
          normal: 'Inchiostro',
          text: 'Tipo di inchiostro',
          UV: 'Inchiostro UV',
        },
        laser_speed: {
          fast: 'Veloce',
          max: 300,
          min: 3,
          slow: 'Lento',
          step: 0.1,
          text: 'Velocità',
          unit: 'mm/s',
        },
        low_power_warning: 'Una potenza laser inferiore (sotto il 10%) potrebbe non emettere il raggio laser.',
        low_speed_warning: 'Una velocità bassa potrebbe causare la combustione del materiale.',
        lower_focus: 'Abbassa la Messa a Fuoco',
        lower_focus_desc: "Abbassare l'altezza del fuoco di una distanza specifica dopo la messa a fuoco per migliorare le prestazioni di taglio.",
        module: 'Modulo',
        more: 'Gestisci',
        multi_layer: 'Livelli multipli',
        para_in_use: 'Questo parametro è già in uso.',
        parameters: 'Parametri',
        power: {
          high: 'Alto',
          low: 'Basso',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Potenza',
        },
        preset_management: {
          add_new: 'Aggiungi Nuovo',
          delete: 'Elimina',
          export: 'Esporta',
          export_preset_title: 'Esporta Preset',
          import: 'Importa',
          laser: 'Laser',
          lower_focus_by: 'Abbassa il Fuoco di',
          new_preset_name: 'Nuovo Nome Preset',
          preset: 'Preimpostazione',
          print: 'Stampa',
          reset: 'Ripristina',
          save_and_exit: 'Salva ed Esci',
          show_all: 'Mostra Tutto',
          sure_to_import_presets: 'Questo caricherà la disposizione delle preimpostazioni e sovrascriverà i parametri personalizzati, sei sicuro di voler procedere?',
          sure_to_reset: 'Questo eliminerà i tuoi parametri personalizzati e ripristinerà tutte le preimpostazioni, sei sicuro di voler procedere?',
          title: 'Gestisci Parametri',
          wobble_diameter: "Diametro dell'oscillazione",
          wobble_step: 'Dimensione del passo oscillante',
        },
        preset_setting: 'Impostazioni parametri (%s)',
        presets: 'Predefinito',
        print_multipass: 'Multi-passaggio',
        promark_speed_desc: 'I parametri di velocità non si applicano alle immagini con sfumature.',
        pulse_width: 'Larghezza Impulso',
        pwm_advanced_desc: 'Imposta una potenza minima per il modo profondità.',
        pwm_advanced_hint: 'Questa impostazione si applica specificamente alle immagini in gradiente quando si utilizza il modo profondità.',
        pwm_advanced_setting: 'Impostazioni di Potenza del Modo Profondità',
        repeat: 'Conteggio dei Passaggi',
        single_color: 'Colore Singolo',
        single_color_desc: 'Applicabile solo al livello a colori completi e non utilizzabile con il livello a colore singolo espanso.',
        slider: {
          fast: 'Veloce',
          high: 'Alto',
          low: 'Basso',
          regular: 'Normale',
          slow: 'Lento',
          very_fast: 'Molto veloce',
          very_high: 'Massimo',
          very_low: 'Minimo',
          very_slow: 'Molto lento',
        },
        speed: 'Velocità',
        speed_constrain_warning: 'La velocità di taglio degli oggetti vettoriali sarà limitata a %(limit)s. È possibile rimuovere questo limite nelle impostazioni delle preferenze.',
        speed_constrain_warning_auto_feeder: 'La velocità di taglio degli oggetti Alimentatore Automatico sarà limitata a %(limit)s. Puoi rimuovere questo limite nelle Impostazioni Preferenze.',
        speed_constrain_warning_curve_engraving: 'La velocità di incisione degli oggetti curvi sarà limitata a %(limit)s. Puoi rimuovere questo limite nelle Impostazioni delle Preferenze.',
        stepwise_focusing: 'Messa a Fuoco Graduale',
        stepwise_focusing_desc: "Abbassare gradualmente la distanza di messa a fuoco in base all'altezza dell'oggetto durante ogni conteggio dei passaggi.",
        strength: 'Potenza',
        times: 'volte',
        various_preset: 'Vari Preset',
        white_ink: 'Inchiostro bianco',
        white_ink_settings: 'Impostazioni inchiostro bianco',
        wobble: 'Oscillazione',
        wobble_desc: 'Crea piccoli cerchi lungo il percorso, utile per i compiti di taglio. (Solo per oggetti tracciati.)',
        wobble_diameter: 'Diametro',
        wobble_step: 'Dimensione del passo',
        z_step: 'Passo Z',
      },
      layer_panel: {
        current_layer: 'Livello corrente',
        layer1: 'Livello 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Taglio',
        layer_engraving: 'Incisione',
        layers: {
          del: 'Elimina livello',
          dupe: 'Duplica livello',
          fullColor: 'Colori completi',
          layer: 'Livello',
          layers: 'Livelli',
          lock: 'Blocca livello',
          merge_all: 'Unisci tutti',
          merge_down: 'Unisci verso il basso',
          merge_selected: 'Unisci livelli selezionati',
          move_down: 'Sposta giù livello',
          move_elems_to: 'Sposta elementi in:',
          move_selected: 'Sposta elementi selezionati in un altro livello',
          move_up: 'Sposta su livello',
          new: 'Nuovo livello',
          rename: 'Rinomina livello',
          splitFullColor: 'Espandi livello',
          switchToFullColor: 'Passa al livello a colori completo',
          switchToSingleColor: 'Passa al livello a colore singolo',
          unlock: 'Sblocca',
        },
        move_elems_to: 'Sposta elementi a:',
        notification: {
          dupeLayerName: 'Esiste già un livello con quel nome!',
          enterNewLayerName: 'Inserisci il nuovo nome del livello',
          enterUniqueLayerName: 'Inserisci un nome di livello univoco',
          layerHasThatName: 'Il livello ha già quel nome',
          mergeLaserLayerToPrintingLayerMsg: 'Si prega di notare che se si completa questa operazione, le impostazioni del livello laser verranno rimosse e impostate in base al livello corrente.',
          mergeLaserLayerToPrintingLayerTitle: 'Vuoi unire questi livelli in un unico livello di stampa?',
          mergePrintingLayerToLaserLayerMsg: 'Si prega di notare che se si completa questa operazione, le impostazioni di colore del livello di stampa verranno rimosse e impostate in base al livello corrente.',
          mergePrintingLayerToLaserLayerTitle: 'Vuoi unire questi livelli in un unico livello laser?',
          moveElemFromPrintingLayerMsg: "Si prega di notare che se si completa questa operazione, le impostazioni colore dell'elemento selezionato verranno rimosse e impostate in base a %s.",
          moveElemFromPrintingLayerTitle: "Spostare l'elemento selezionato in %s e convertirlo in elemento laser?",
          moveElemToPrintingLayerMsg: "Si prega di notare che se si completa questa operazione, le impostazioni dell'elemento selezionato verranno rimosse e impostate in base a %s.",
          moveElemToPrintingLayerTitle: "Spostare l'elemento selezionato in %s e convertirlo in elemento di stampa?",
          newName: 'NUOVO NOME',
          QmoveElemsToLayer: "Spostare gli elementi selezionati nel livello '%s'?",
          splitColorMsg: 'Si prega di notare che se si prosegue con questa operazione, non sarà possibile tornare agli originali livelli colore.',
          splitColorTitle: 'Vuoi espandere il livello selezionato nei livelli CMYK?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Rimozione sfondo',
          ai_bg_removal_reminder: 'Premendo il pulsante verranno immediatamente utilizzati 0,2 crediti, vuoi continuare?',
          ai_bg_removal_short: 'Rimozione sfondo',
          array: 'Array',
          auto_fit: 'Adattamento Automatico',
          bevel: 'Smusso',
          brightness: 'Luminosità',
          convert_to_path: 'Converti in percorso',
          create_textpath: 'Crea testo su percorso',
          create_textpath_short: 'Testo percorso',
          crop: 'Ritaglia',
          decompose_path: 'Scomponi',
          detach_path: 'Scomponi testo su percorso',
          detach_path_short: 'Scomponi',
          disassemble_use: 'Disassembla',
          disassembling: 'Disassemblaggio in corso...',
          edit_path: 'Modifica percorso',
          fetching_web_font: 'Recupero carattere online...',
          grading: 'Classificazione',
          invert: 'Inverti',
          offset: 'Offset',
          outline: 'Contorno',
          replace_with: 'Sostituisci con...',
          replace_with_short: 'Sostituisci',
          sharpen: 'Nitidezza',
          simplify: 'Semplifica',
          smart_nest: 'Nidificazione Intelligente',
          trace: 'Traccia',
          ungrouping: 'Separa gruppo in corso...',
          uploading_font_to_machine: 'Caricamento carattere sulla macchina...',
          wait_for_parsing_font: 'Analisi carattere... Attendere un secondo',
          weld_text: 'Salda testo',
        },
        align: 'Allinea',
        boolean: 'Operazioni booleane',
        bottom_align: 'Allinea in basso',
        center_align: 'Allinea al centro',
        difference: 'Differenza',
        distribute: 'Distribuisci',
        flip: 'Capovolgi',
        group: 'Gruppo',
        hdist: 'Distribuzione orizzontale',
        hflip: 'Capovolgi orizzontalmente',
        intersect: 'Intersezione',
        left_align: 'Allinea a sinistra',
        lock_aspect: 'Blocca proporzioni',
        middle_align: 'Allinea al centro',
        option_panel: {
          color: 'Colore',
          fill: 'Riempimento',
          font_family: 'Carattere',
          font_size: 'Dimensione',
          font_style: 'Stile',
          letter_spacing: 'Spaziatura',
          line_spacing: 'Interlinea',
          path_infill: 'Riempimento percorso',
          pwm_engraving: 'Modalità Profondità',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Angoli arrotondati',
          shading: 'Sfumatura',
          sides: 'Lati',
          start_offset: 'Offset testo',
          stroke: 'Contorno',
          stroke_color: 'Colore tracciato',
          stroke_width: 'Larghezza tracciato',
          text_infill: 'Riempimento testo',
          threshold: 'Soglia luminosità',
          threshold_short: 'Soglia',
          vertical_align: 'Allineamento',
          vertical_text: 'Testo verticale',
        },
        path_edit_panel: {
          connect: 'Connetti',
          delete: 'Elimina',
          disconnect: 'Disconnetti',
          node_type: 'TIPO NODO',
          round: 'Arrotondato',
          sharp: 'Affilato',
        },
        right_align: 'Allinea a destra',
        subtract: 'Sottrazione',
        top_align: 'Allinea in alto',
        ungroup: 'Separa',
        union: 'Unione',
        unlock_aspect: 'Sblocca proporzioni',
        vdist: 'Distribuzione verticale',
        vflip: 'Capovolgi verticalmente',
        zoom: 'Zoom',
      },
      tabs: {
        layers: 'Livelli',
        objects: 'Oggetti',
        path_edit: 'Modifica percorso',
      },
      uv_print_block: {
        title: 'Stampa UV',
      },
    },
    shapes_panel: {
      animals: 'Animale',
      arrow: 'Freccia',
      basic: 'Di base',
      birds: 'Uccello',
      celebration: 'Celebrazione',
      circular: 'Circolare',
      CNY: 'Capodanno cinese',
      corner: 'Angolo',
      decor: 'Decorazione',
      easter: 'Pasqua',
      elements: 'Elementi',
      environment: 'Ambiente',
      graphics: 'Grafica',
      halloween: 'Halloween',
      holidays: 'Festività',
      label: 'Etichetta',
      land: 'Terra',
      line: 'Linea',
      nature: 'Natura',
      photo: 'Foto',
      plants: 'Piante',
      ribbon: 'Nastro',
      sea: 'Mare',
      shape: 'Forma',
      speech: 'Discorso',
      text: 'Riquadro di testo',
      title: 'Elementi',
      valentines: 'San Valentino',
      weather: 'Meteo',
      Xmas: 'Natale',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: "Impossibile recuperare l'immagine dagli appunti",
      unnsupported_file_type: 'Il tipo di file non è supportato direttamente. Convertire il file in SVG o bitmap',
    },
    tag: {
      g: 'Gruppo',
      image: 'Immagine',
      text: 'Testo',
      use: 'Importa SVG',
    },
    time_est_button: {
      calculate: 'Stima tempo',
      estimate_time: 'Tempo stimato:',
    },
    tool_panels: {
      _nest: {
        end: 'Chiudi',
        no_element: 'Non ci sono elementi da disporre.',
        rotations: 'Possibili rotazioni',
        spacing: 'Spaziatura',
        start_nest: 'Disponi',
        stop_nest: 'Interrompi',
      },
      _offset: {
        corner_type: 'Angolo',
        direction: 'Direzione offset',
        dist: 'Distanza di offset',
        fail_message: 'Impossibile offsettare gli oggetti.',
        inward: 'Interno',
        not_support_message: 'Elementi selezionati contenenti tag SVG non supportati:\nImmagine, Gruppo, Testo e Oggetto importato.',
        outward: "Verso l'esterno",
        round: 'Arrotondato',
        sharp: 'Appuntito',
      },
      array_dimension: 'Dimensioni griglia ',
      array_interval: 'Spaziatura griglia',
      cancel: 'Annulla',
      columns: 'Colonne',
      confirm: 'Conferma',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Crea griglia',
      nest: 'Ottimizza disposizione',
      offset: 'Offset',
      rows: 'Righe',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Adatta a finestra',
    },
  },
  boxgen: {
    add_option: 'Aggiungi opzione',
    basic_box: 'Scatola base',
    beam_radius: 'Compensazione della tacca',
    beam_radius_warning: 'Rimuovere il compenso della tacca quando i bordi o le giunzioni della scatola sono corti per garantire il montaggio della scatola',
    cancel: 'Annulla',
    coming_soon: 'Prossimamente',
    continue_import: 'Continua a importare',
    control_tooltip: 'Sinistro per ruotare\nScorri per lo zoom\nDestro per spostare',
    control_tooltip_touch: 'Trascina per ruotare\nStringi per lo zoom\nDue dita per spostare',
    cover: 'Coperchio',
    customize: 'Personalizza',
    depth: 'Profondità',
    edge: 'Bordo',
    finger: 'Dito',
    finger_warning: 'La lunghezza interna del lato della scatola deve essere di almeno 6 mm (0,24 pollici) per essere compatibile con il collegamento a dita.',
    height: 'Altezza',
    import: 'Importa',
    inner: 'Interno',
    joints: 'Giunzione',
    max_dimension_tooltip: 'Impostazione max larghezza/altezza/profondità è %s.',
    merge: 'Unisci',
    outer: 'Esterno',
    reset: 'Ripristina',
    tCount: 'Conteggio T',
    tCount_tooltip: 'Il conteggio degli slot a T si applica al lato corto; la quantità sul lato lungo viene calcolata in base alla sua lunghezza.',
    tDiameter: 'Diametro T',
    text_label: 'Etichetta',
    thickness: 'Spessore',
    title: 'BOXGEN',
    tLength: 'Lunghezza T',
    tSlot: 'T-Slot',
    tSlot_warning: 'La lunghezza del lato della scatola deve essere di almeno 30 mm (1,18 pollici) per essere compatibile con il collegamento a T.',
    volume: 'Volume',
    width: 'Larghezza',
    workarea: 'Area di lavoro',
    zoom: 'Zoom',
  },
  buttons: {
    back: 'INDIETRO',
    back_to_beam_studio: 'Torna a Beam Studio',
    done: 'FATTO',
    next: 'AVANTI',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Premere l\'icona "AF" nella pagina principale della macchina per 3 secondi e lasciare che la sonda tocchi il blocco di messa a fuoco.',
    ador_autofocus_material: 'Premere l\'icona "AF" nella pagina principale della macchina per 3 secondi e lasciare che la sonda tocchi delicatamente il materiale.',
    align_ilb: 'Posizione: Allineare con il punto inciso in basso a sinistra interno.',
    align_ilt: 'Posizione: Allineare con il punto inciso in alto a sinistra interno.',
    align_irb: 'Posizione: Allineare con il punto inciso in basso a destra interno.',
    align_irt: 'Posizione: Allineare con il punto inciso in alto a destra interno.',
    align_olb: 'Posizione: Allineare con il punto inciso in basso a sinistra esterno.',
    align_olt: 'Posizione: Allineare con il punto inciso in alto a sinistra esterno.',
    align_orb: 'Posizione: Allineare con il punto inciso in basso a destra esterno.',
    align_ort: 'Posizione: Allineare con il punto inciso in alto a destra esterno.',
    align_red_cross_cut: 'Si prega di allineare il centro della croce rossa con la croce di taglio.',
    align_red_cross_print: 'Si prega di allineare il centro della croce rossa con la croce stampata.',
    analyze_result_fail: "Impossibile analizzare l'immagine acquisita.<br/>Assicurarsi che:<br/>1. L'immagine acquisita copra interamente il foglio bianco.<br/>2. La piattaforma sia a fuoco (all'altezza dell'acrilico ribaltato).",
    ask_for_readjust: 'Vuoi saltare il passaggio di incisione e poi scattare una foto e calibrare direttamente?',
    back: 'Indietro',
    calculating_camera_matrix: 'Calcolo della matrice della fotocamera in corso...',
    calculating_regression_parameters: 'Calcolo dei parametri di regressione in corso...',
    calibrate_camera_before_calibrate_modules: 'Si prega di eseguire prima la calibrazione della fotocamera e poi la calibrazione dei moduli.',
    calibrate_chessboard_success_msg: 'Foto della scacchiera catturata con successo.<br/>Il punteggio per questa foto è %s (%.2f).',
    calibrate_done: 'Calibrazione completata. Si ottiene una maggiore precisione della fotocamera quando a fuoco.',
    calibrate_done_diode: "Calibrazione completata. L'offset del modulo laser a diodi è stato salvato.",
    calibrating: 'Calibrazione...',
    calibrating_with_device_pictures: 'Calibrazione con le immagini del dispositivo in corso...',
    camera_calibration: 'Calibrazione fotocamera',
    camera_parameter_saved_successfully: 'Parametri della fotocamera salvati con successo.',
    cancel: 'Annulla',
    check_checkpoint_data: 'Dati del checkpoint',
    check_device_pictures: 'Controlla le immagini del dispositivo',
    checking_checkpoint: 'Controllo dei dati del checkpoint...',
    checking_pictures: 'Controllo delle immagini del dispositivo in corso...',
    diode_calibration: 'Calibrazione modulo laser a diodi',
    do_engraving: 'Esegui Intaglio',
    download_chessboard_file: 'Scarica file scacchiera',
    downloading_checkpoint: 'Download dei dati del checkpoint...',
    downloading_pictures: 'Download delle foto in corso...',
    drawing_calibration_image: 'Generazione immagine di calibrazione in corso...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Solleva e Taglia',
    elevate_and_cut_step_1: "Posiziona un pezzo di legno di dimensioni A4 di colore chiaro al centro dell'area di lavoro, elevato a 20 mm.",
    elevate_and_cut_step_1_prism_lift: 'Utilizza Ador Prism Lift con una lunghezza massima di 14 mm insieme a un pezzo di legno di almeno 6 mm di spessore.',
    failed_to_calibrate_camera: "#848 Impossibile calibrare la fotocamera, si prega di contattare l'assistenza FLUX.",
    failed_to_calibrate_chessboard: "Calibrazione con l'immagine della scacchiera non riuscita.",
    failed_to_calibrate_with_pictures: '#848 Impossibile calibrare con le immagini del dispositivo.',
    failed_to_download_pictures: "#848 Impossibile scaricare le foto, si prega di contattare l\\'assistenza FLUX.",
    failed_to_move_laser_head: 'Impossibile spostare la testa del laser.',
    failed_to_parse_checkpoint: 'Impossibile analizzare i dati del checkpoint.',
    failed_to_save_calibration_results: "#849 Impossibile salvare i risultati della calibrazione, si prega di riprovare. Se il problema persiste, si prega di contattare l'assistenza FLUX.",
    failed_to_save_camera_parameter: 'Impossibile salvare i parametri della fotocamera.',
    failed_to_solve_pnp: 'Impossibile risolvere la posizione della fotocamera.',
    finish: 'Completato',
    found_checkpoint: 'I dati del checkpoint sono stati trovati sul tuo dispositivo. Desideri recuperarli dal checkpoint?',
    getting_plane_height: 'Ottenere Altezza del Piano...',
    hint_adjust_parameters: 'Usare questi parametri per regolare il quadrato rosso',
    hint_red_square: 'Allineare il bordo esterno del quadrato rosso con il quadrato di taglio.',
    module_calibration_2w_ir: 'Calibrazione modulo a infrarossi',
    module_calibration_printer: 'Calibrazione modulo stampante',
    moving_laser_head: 'Spostamento della testa laser...',
    next: 'Avanti',
    no_picutre_found: '#846 Il tuo dispositivo non dispone di foto raw disponibili per la calibrazione. Si prega di contattare il supporto FLUX.',
    perform_autofocus_bb2: "Per favore, vai al pannello di controllo della macchina e premi AF per eseguire l'autofocus.",
    please_do_camera_calibration_and_focus: {
      beambox: "Per calibrare il modulo laser a diodi è necessaria la fotocamera.\nAssicurarsi che la fotocamera di questa macchina sia stata calibrata.\nE regolare delicatamente la piattaforma al punto focale (l'altezza dell'acrilico ribaltato).",
      beamo: "Per calibrare il modulo laser a diodi è necessaria la fotocamera.\nAssicurarsi che la fotocamera di questa macchina sia stata calibrata.\nE regolare delicatamente la testa laser a fuoco sull'oggetto di incisione (l'altezza dell'acrilico ribaltato).",
    },
    please_goto_beambox_first: 'Per utilizzare questa funzione, passa prima alla Modalità Incisione (Beambox).',
    please_place_dark_colored_paper: "Posiziona un foglio di carta colorata scura formato A4 o Letter al centro dell'area di lavoro.",
    please_place_paper: "Posiziona un foglio di carta bianca formato A4 o Letter nell'angolo in alto a sinistra dell'area di lavoro.",
    please_place_paper_center: "Posiziona un foglio di carta bianca formato A4 o Letter al centro dell'area di lavoro.",
    please_refocus: {
      beambox: "Regola delicatamente la piattaforma al punto focale (l'altezza dell'acrilico ribaltato)",
      beamo: "Regola delicatamente la testa laser per mettere a fuoco l'oggetto di incisione (l'altezza dell'acrilico ribaltato)",
      beamo_af: "Tocca delicatamente due volte il pulsante laterale dell'accessorio di messa a fuoco automatica e lascia che la sonda tocchi delicatamente il materiale.",
      hexa: "Fai doppio clic sul pulsante di regolazione dell'altezza per sollevare il tavolo a nido d'ape e fare in modo che la sonda tocchi il materiale di incisione.",
    },
    preparing_to_take_picture: 'Preparazione per scattare una foto...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Posiziona la Scacchiera',
    put_chessboard_1: "Per favore, vai al pannello di controllo della macchina e premi AF per eseguire l'autofocus.",
    put_chessboard_2: "Si prega di spostare il foglio a scacchiera o la testa del laser finché l'intera scacchiera non sia confermata all'interno della cornice rossa nella finestra di anteprima in tempo reale.",
    put_chessboard_3: "Fai clic con il tasto destro per scaricare l'immagine della scacchiera nella posizione attuale e verificarne la chiarezza.",
    put_chessboard_bb2_desc_1: 'Scarica il seguente file della scacchiera e stampalo su carta A4 (la scacchiera stampata deve avere quadrati di 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Fissa la scacchiera stampata su una tavola non deformabile, come acrilico o vetro, assicurandoti che la scacchiera sia liscia e priva di pieghe o sollevamenti.',
    put_chessboard_bb2_desc_3: "Posiziona la tavola con la scacchiera piatta al centro dell'area di lavoro.",
    put_chessboard_promark_1: "Regola la distanza focale per la messa a fuoco appropriata dell'obiettivo di campo.",
    put_chessboard_promark_2: 'Assicurarsi che il foglio a scacchiera sia chiaramente visibile senza riflessi. Clicca su "Avanti" per scattare una foto.',
    put_chessboard_promark_desc_1: 'Utilizzare il foglio a scacchiera fornito nella confezione degli accessori, oppure stampare il seguente motivo a scacchiera su un foglio A4 per la calibrazione della fotocamera.',
    put_chessboard_promark_desc_2: 'Si prega di posizionare il modello a scacchiera piatto e centrato sulla piattaforma di lavoro.',
    put_paper: 'Metti Carta',
    put_paper_promark_1: 'Posiziona il cartoncino nero dalla scatola degli accessori sulla piattaforma di lavoro.',
    put_paper_promark_2: 'Regola correttamente la messa a fuoco, quindi fai clic su "Avvia Intaglio" per procedere con l\'incisione.',
    put_paper_skip: 'Se il disegno di calibrazione non procede automaticamente con lo scatto, premi "Salta" per scattare la foto.',
    put_paper_step1: "Si prega di posizionare un foglio di carta bianco di formato A4 o Lettera al centro dell'area di lavoro.",
    put_paper_step2: 'Fissare i quattro angoli della carta per garantire che sia piatta.',
    put_paper_step3: 'Fare clic su "Avvia Intaglio".',
    res_average: 'Medio',
    res_excellent: 'Eccellente',
    res_poor: 'Scarso',
    retake: 'Riprendi immagine',
    rotation_angle: 'Rotazione',
    show_last_config: 'Mostra ultima calibrazione',
    skip: 'Salta',
    solve_pnp_step1: 'Allineare i punti di incisione in base al numero e alla posizione prevista di ciascun punto di riferimento rosso.',
    solve_pnp_step2: 'È possibile premere "Riprendi immagine" per riallineare o regolare manualmente le posizioni dei marcatori.',
    solve_pnp_step3: 'Se l\'immagine catturata non corrisponde alla scena reale, ingrandisci o trascina lo schermo per regolare il punto di calibrazione sulla scheda di calibrazione nera al centro, quindi prova a "Riprendi immagine".',
    solve_pnp_title: 'Allineare i Punti di Riferimento',
    start_engrave: 'Avvia Intaglio',
    start_printing: 'Inizia Stampa',
    taking_picture: 'Acquisizione immagine in corso...',
    unable_to_load_camera_parameters: "#851 Non ci sono parametri di calibrazione della fotocamera disponibili sul tuo dispositivo. Vai su 'Calibrazione' > 'Calibra la fotocamera (Avanzato)' per completare la calibrazione e ottenere i parametri.",
    update_firmware_msg1: 'Il firmware non supporta questa funzione. Aggiornare il firmware alla versione v',
    update_firmware_msg2: 'o superiore per continuare。 (Menu > Macchina > [La tua macchina] > Aggiorna firmware)',
    uploading_images: 'Caricamento delle immagini in corso...',
    use_last_config: 'Usa ultimi valori di calibrazione',
    use_old_camera_parameter: 'Vuoi utilizzare i parametri attuali della lente della fotocamera?',
    with_af: 'Con messa a fuoco automatica',
    with_prism_lift: 'Con Ador Prism Lift',
    without_af: 'Senza messa a fuoco automatica',
    without_prism_lift: 'Senza Ador Prism Lift',
    x_ratio: 'Rapporto X',
    y_ratio: 'Rapporto Y',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Dati della fotocamera scaricati con successo.',
    downloading_data: 'Download dei dati...',
    estimated_time_left: 'Tempo stimato rimanente:',
    folder_not_exists: 'La cartella selezionata non esiste.',
    incorrect_folder: 'Impossibile caricare i dati di calibrazione. Verifica se la cartella selezionata è corretta.',
    no_picture_found: 'Nessuna immagine trovata nella macchina.',
    title: 'Backup dati della fotocamera',
    upload_success: 'Dati della fotocamera caricati con successo.',
    uploading_data: 'Caricamento dei dati...',
  },
  caption: {
    connectionTimeout: 'Timeout connessione',
  },
  change_logs: {
    added: 'Aggiunto:',
    change_log: 'Registro delle modifiche:',
    changed: 'Modificato:',
    fixed: 'Risolto:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Guarda le versioni precedenti',
  },
  code_generator: {
    barcode: 'Codice a barre',
    qr_code: 'Codice QR',
  },
  curve_engraving: {
    '921': 'Messa a fuoco automatica non riuscita.',
    '922': 'MIsurazione con luce rossa non riuscita.',
    amount: 'Quantità',
    apply_arkwork: 'Applica opera d’arte sulla curva',
    apply_camera: 'Applica immagine della fotocamera sulla curva',
    click_to_select_point: 'Clicca per selezionare o deselezionare i punti da misurare nuovamente.',
    coloumns: 'Colonne',
    column_gap: 'Spazio tra le colonne',
    err_object_over_range: "L'oggetto supera l'intervallo di misurazione.",
    failed_to_take_reference: 'Acquisizione del riferimento non riuscita',
    gap: 'Spazio',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Misura area autofocus',
    preview_3d_curve: 'Anteprima dell’incisione della curva',
    remeasure: 'Rimisura',
    remeasuring_points: 'Rimisurazione dei punti...',
    reselect_area: 'Reseleziona area',
    row_gap: 'Spazio tra le righe',
    rows: 'Righe',
    set_object_height: 'Imposta altezza oggetto',
    set_object_height_desc: "Misurare lo spessore massimo dell'oggetto.",
    start_autofocus: 'Avvia autofocus',
    starting_measurement: 'Inizio della misurazione...',
    sure_to_delete: 'Vuoi eliminare i dati di messa a fuoco dell’incisione della curva?',
    take_reference: 'Prendi riferimento',
    take_reference_desc: 'Sposta la testa del laser al punto più alto dell\'oggetto, abbassa la sonda di messa a fuoco e fai clic su "Conferma" per mettere a fuoco.',
  },
  device: {
    abort: 'Interrompi',
    aborted: 'Interrotto',
    aborting: 'Interrompendo',
    busy: 'Occupato',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Completato',
    completing: 'Completamento',
    deviceList: 'Elenco dispositivi',
    disable: 'Disabilita',
    disconnectedError: {
      caption: 'Macchina disconnessa',
      message: 'Si prega di confermare se la rete di %s è disponibile',
    },
    enable: 'Abilita',
    firmware_version: 'Versione firmware',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Nome modello',
    occupied: 'Manutenzione',
    pause: 'Pausa',
    paused: 'In pausa',
    pausedFromError: 'In pausa da errore',
    pausing: 'In pausa',
    please_wait: 'Attendere prego...',
    preparing: 'Preparazione',
    processing: 'Elaborazione',
    quit: 'Esci',
    ready: 'Pronto',
    reset: 'Ripristina',
    resuming: 'Riprendendo',
    retry: 'Riprova',
    running: 'Lavorazione',
    scanning: 'Scansione',
    select: 'Seleziona',
    select_printer: 'Seleziona stampante',
    serial_number: 'Numero di serie',
    start: 'Avvia',
    starting: 'Avviando',
    status: 'Stato',
    submodule_type: 'Module',
    toolhead_change: "Cambia la testa dell'utensile",
    unknown: 'Sconosciuto',
    uploading: 'Caricamento',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Impossibile trovare la macchina sulla rete.\nSeguire la <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guida</a> per risolvere il problema di connessione.',
    no_device_web: "#801 Controlla lo stato della tua macchina o clicca su 'Configura macchina' qui sotto per configurare la macchina.",
    select_usb_device: 'Seleziona dispositivo USB',
  },
  editor: {
    exposure: 'Anteprima esposizione',
    opacity: 'Anteprima opacità',
    prespray_area: 'Area di preparazione',
  },
  error_pages: {
    screen_size: "Si prega di notare che Beam Studio potrebbe non funzionare in modo ottimale sul dispositivo. Per un'esperienza ottimale, dovrebbe essere un dispositivo con una larghezza dello schermo di almeno 1024 pixel.",
  },
  flux_id_login: {
    connection_fail: '#847 Impossibile connettersi al servizio membri FLUX.',
    email: 'Email',
    flux_plus: {
      access_monotype_feature: "Non hai l'add-on Caratteri Monotype.",
      access_monotype_feature_note: "Devi avere l'abbonamento FLUX+ Pro o l'add-on Caratteri Monotype per accedere a questa funzionalità.",
      access_plus_feature_1: 'Stai accedendo a una',
      access_plus_feature_2: 'funzionalità.',
      access_plus_feature_note: "Devi avere l'abbonamento FLUX+ per accedere a questa funzionalità.",
      ai_credit_tooltip: 'Per la rimozione dello sfondo AI',
      explore_plans: 'Esplora i piani FLUX+',
      features: {
        ai_bg_removal: 'Rimozione sfondo AI',
        boxgen: 'Generatore di scatole 3D',
        dmkt: '1000+ file di progettazione',
        monotype: '250+ caratteri premium',
        my_cloud: 'Archiviazione cloud illimitata',
      },
      flux_credit_tooltip: 'Per i file Design Market e la rimozione dello sfondo AI',
      get_addon: "Ottieni l'add-on",
      goto_member_center: 'Vai al Centro membri',
      learn_more: 'Per saperne di più',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Iscriviti adesso',
      thank_you: 'Grazie per essere un membro prezioso!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Password dimenticata?',
    incorrect: 'Email o password errati.',
    login: 'Accedi',
    login_success: 'Accesso effettuato con successo.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Nuovo su FLUX? Registrati.',
    not_verified: "L'indirizzo email non è stato ancora verificato.",
    offline: 'Lavora offline',
    password: 'Password',
    register: 'Crea il tuo account FLUX',
    remember_me: 'Ricordami',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Accedi per sbloccare il database delle forme.',
    work_offline: 'Lavora offline',
  },
  framing: {
    area_check: 'Controllo Area',
    areacheck_desc: "Garantisce la sicurezza dell'area di lavoro visualizzando il riquadro di delimitazione dell'oggetto e la zona di accelerazione della testa laser.",
    calculating_task: 'Calcolo del compito...',
    framing: 'Inquadratura',
    framing_desc: "Anteprima del riquadro di delimitazione dell'oggetto.",
    hull: 'Involucro',
    hull_desc: "Visualizza in anteprima una forma che segue da vicino il disegno, come un elastico avvolto intorno all'oggetto.",
    low_laser: 'Laser basso',
    low_laser_desc: "Imposta un valore laser a bassa potenza per l'attività di inquadratura.",
    rotate_axis: 'Asse di rotazione',
    rotateaxis_desc: "Anteprima del centro dell'asse di rotazione, l'asse stesso non si muove.",
    rotation_frame_warning: "L'anteprima della cornice ruoterà una volta. Evita di fermarti a metà per evitare disallineamenti della posizione del marcatore.",
    rotation_framing_desc: "Anteprima dell'intero range di rotazione, l'asse ruota secondo l'anteprima.",
    start_task: 'Avvia Attività',
    start_task_description: 'Dopo aver completato l\'anteprima del frame, clicca su "Avvia Attività" per inviare il lavoro.',
  },
  general: {
    choose_folder: 'Scegli cartella',
    processing: 'Elaborazione...',
  },
  generic_error: {
    OPERATION_ERROR: "[OE] Conflitto di stato, riprovare l'azione.",
    SUBSYSTEM_ERROR: "[SE] Errore durante l'esecuzione del firmware della macchina. Riavviare la macchina.",
    UNKNOWN_COMMAND: '[UC] Aggiornare il firmware del dispositivo',
    UNKNOWN_ERROR: '[UE] Si è verificato un errore sconosciuto. Riavviare Beam Studio e la macchina.',
  },
  global: {
    apply: 'Applica',
    back: 'Indietro',
    cancel: 'Annulla',
    editing: {
      redo: 'Ripeti',
      reset: 'Ripristina',
      undo: 'Annulla',
      zoom_in: 'Ingrandisci',
      zoom_out: 'Riduci',
    },
    mode_conflict: 'Questa opzione non è disponibile nella modalità attuale.',
    ok: 'OK',
    save: 'Salva',
    stop: 'Ferma',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Dimensione Pennello',
      description: 'Clicca o trascina per cancellare manualmente le aree indesiderate.',
      title: 'Gomma',
    },
    magic_wand: {
      description: 'Seleziona e rimuovi aree adiacenti in base alla somiglianza dei colori.',
      title: 'Bacchetta Magica',
      tolerance: 'Tolleranza',
    },
    title: 'Modifica Immagine',
  },
  initialize: {
    back: 'Indietro',
    cancel: 'Annulla',
    confirm: 'Conferma',
    connect: 'Connetti',
    connect_ethernet: {
      title: 'Connessione diretta',
      tutorial1: '1. Collega la macchina con il tuo computer con il cavo ethernet.',
      tutorial2_1: '2. Segui ',
      tutorial2_2: ' per rendere il tuo computer un router.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: "'questa guida'",
      tutorial3: '3. Fai clic su Avanti.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Impossibile connettersi al server. Riavvia Beam Studio e riprova.',
      },
      check_camera: 'Controllo della disponibilità della fotocamera',
      check_connection: 'Controllo connessione macchina',
      check_firmware: 'Controllo della versione del firmware',
      check_ip: 'Controllo disponibilità IP',
      check_swiftray_connection: 'Verifica connessione al server',
      check_swiftray_connection_unreachable: 'server non raggiungibile',
      check_usb: 'Controlla connessione USB',
      enter_ip: "Inserisci l\\'indirizzo IP della tua macchina",
      finish_setting: 'Inizia a creare!',
      invalid_format: 'Formato non valido',
      invalid_ip: 'IP non valido: ',
      promark_hint: 'Se non riesci a connetterti tramite USB ripetutamente, fai riferimento all\'<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">articolo del Centro assistenza</a>.',
      retry: 'Riprova',
      starts_with_169254: 'Inizia con 169.254',
      succeeded_message: 'Connessione riuscita 🎉',
      unreachable: 'IP irraggiungibile',
    },
    connect_usb: {
      connect_camera: 'Collega la fotocamera della macchina al computer tramite cavo USB.',
      title: 'Connessione USB',
      title_sub: ' (solo HEXA)',
      turn_off_machine: 'Spegni la macchina.',
      turn_on_machine: 'Accendi la macchina.',
      tutorial1: '1. Collega la macchina con il tuo computer con il cavo USB.',
      tutorial2: '2. Fai clic su Avanti.',
      wait_for_turning_on: 'Fai clic su "Avanti" dopo che il processo di avvio è completo e sei entrato nella schermata principale.',
    },
    connect_wifi: {
      title: 'Connessione a Wi-Fi',
      tutorial1: '1. Vai a Touch Panel > Fai clic su "Rete" > "Connetti a WiFi".',
      tutorial1_ador: '1. Vai a Pannello Touch > Fai clic su "MACCHINA" > Fai clic su "Rete" > "Connetti a Wi-Fi".',
      tutorial2: '2. Seleziona e connetti il Wi-Fi preferito.',
      what_if_1: 'Cosa fare se non vedo il mio Wi-Fi?',
      what_if_1_content: "1. La modalità di crittografia Wi-Fi dovrebbe essere WPA2 o nessuna password.\n2. La modalità di crittografia può essere impostata nell'interfaccia di amministrazione del router Wi-Fi. Se il router non supporta WPA2 e hai bisogno di aiuto nella scelta del router giusto, contatta l'assistenza FLUX.",
      what_if_2: 'Cosa succede se non vedo alcuna rete Wi-Fi?',
      what_if_2_content: "1. Assicurati che il dongle Wi-Fi sia completamente inserito.\n2. Se non c'è alcun indirizzo MAC della rete wireless sul touchscreen, contatta l'assistenza FLUX.\n3. Il canale Wi-Fi dovrebbe essere 2.4Ghz (5Ghz non è supportato).",
    },
    connect_wired: {
      title: 'Connessione alla rete cablata',
      tutorial1: '1. Collega la macchina con il tuo router.',
      tutorial2: '2. Premi "Rete" per ottenere l\'IP di rete cablata.',
      tutorial2_ador: '2. Premi "MACCHINA" > "Rete" per ottenere l\'indirizzo IP cablato.',
      what_if_1: "Cosa succede se l'IP è vuoto?",
      what_if_1_content: "1. Assicurati che il cavo Ethernet sia completamente inserito.\n2. Se non c'è alcun indirizzo MAC della rete cablata sul touchscreen, contatta l'assistenza FLUX.",
      what_if_2: "Cosa succede se l'IP inizia con 169?",
      what_if_2_content: "1. Se l'indirizzo IP inizia con 169.254, dovrebbe trattarsi di un problema di impostazione DHCP, contatta il tuo ISP (Internet service provider) per ulteriore assistenza.\n2. Se il tuo computer si connette a Internet direttamente tramite PPPoE, passa alla connessione tramite router utilizzando PPPoE e abilita la funzione DHCP nel router.",
    },
    connecting: 'Connessione in corso...',
    connection_types: {
      ether2ether: 'Connessione diretta',
      usb: 'Connessione USB',
      wifi: 'Wi-Fi',
      wired: 'Rete cablata',
    },
    next: 'Avanti',
    no_machine: 'Non ho una macchina ora.',
    promark: {
      configuration_confirmation: 'Ciò garantisce che il tuo Promark sia configurato correttamente per la massima efficienza e precisione.',
      or_complete_later: `Oppure, salta questo passaggio e completa le impostazioni Promark in seguito in:
    Macchine > "Nome Promark" > Impostazioni Promark`,
      qc_instructions: 'Compila i parametri sul retro della carta "QC Pass"',
      select_laser_source: 'Seleziona il tuo Promark',
      select_workarea: 'Seleziona Area di Lavoro',
      settings: 'Impostazioni Promark',
    },
    retry: 'Riprova',
    select_beambox: 'Seleziona il tuo Beambox',
    select_connection_type: 'Come desideri connetterti?',
    select_language: 'Seleziona lingua',
    select_machine_type: 'Seleziona il tuo modello',
    setting_completed: {
      back: 'Indietro',
      great: 'Benvenuto in Beam Studio',
      ok: 'INIZIA A CREARE',
      setup_later: 'Puoi sempre configurare la tua macchina dalla Barra del titolo > "Macchine" > "Configurazione macchina"',
      start: 'Inizia',
    },
    skip: 'Salta',
    start: 'Inizia',
  },
  input_machine_password: {
    connect: 'CONNETTI',
    password: 'Password',
    require_password: '"%s" richiede una password',
  },
  insecure_websocket: {
    extension_detected: "Rilevata l'estensione Beam Studio Connect",
    extension_detected_description: "Abbiamo rilevato che hai installato l'estensione Beam Studio Connect. Fare clic su 'Conferma' per reindirizzare a HTTPS, o fare clic su 'Annulla' per continuare a utilizzare HTTP.",
    extension_not_deteced: "Impossibile rilevare l'estensione Beam Studio Connect",
    extension_not_deteced_description: "Per utilizzare HTTPS, fare clic su 'Conferma' per installare l'estensione Beam Studio Connect. Dopo aver installato l'estensione, aggiornare la pagina per attivarla.<br/>Altrimenti, fare clic sul link sottostante per vedere come utilizzare HTTP su Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Collegamento</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: 'Laser a infrarossi da 2 W',
    laser_10w_diode: 'Laser a diodi da 10 W',
    laser_20w_diode: 'Laser a diodi da 20 W',
    non_working_area: 'Area Non Lavorativa',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Tieni presente che se completi questa operazione, le impostazioni dello strato laser verranno rimosse e impostate in base allo strato corrente.',
      convertFromLaserModuleTitle: 'Vuoi convertire il modulo laser in modulo di stampa?',
      convertFromPrintingModuleMsg: 'Tieni presente che se completi questa operazione, le impostazioni di colore dello strato di stampa verranno rimosse e impostate in base allo strato corrente.',
      convertFromPrintingModuleTitle: 'Vuoi convertire il modulo di stampa in modulo laser?',
      importedDocumentContainsPrinting: "Il documento contiene uno strato di stampa, vuoi cambiare l'area di lavoro in Ador?",
      performIRCaliMsg: 'Fare clic su "Conferma" per eseguire la calibrazione o accedere alla calibrazione dal menu in alto.<br />(Macchina> [Nome macchina] > Calibra modulo a infrarossi)',
      performIRCaliTitle: 'Esegui la calibrazione del modulo a infrarossi',
      performPrintingCaliMsg: 'Fare clic su "Conferma" per eseguire la calibrazione o accedere alla calibrazione dal menu in alto.<br />(Macchina> [Nome macchina] > Calibra modulo di stampa)',
      performPrintingCaliTitle: 'Esegui la calibrazione del modulo di stampa',
      printingLayersConverted: 'Gli strati di stampa sono stati convertiti in strati laser.',
    },
    printing: 'Stampa',
    unknown: 'Unknown Module',
    uv_print: 'Stampa UV',
  },
  machine_status: {
    '-17': '.machine_status.-17',
    '-10': 'Modalità manutenzione',
    '-2': 'Scansione',
    '-1': 'Manutenzione',
    '0': 'Inattivo',
    '1': 'Inizializzazione',
    '2': 'TRASFORMAZIONE',
    '4': 'Avvio',
    '6': 'Ripresa',
    '16': 'Lavorazione',
    '18': 'Ripresa',
    '32': 'In pausa',
    '36': 'In pausa',
    '38': 'Messa in pausa',
    '48': 'In pausa',
    '50': 'Messa in pausa',
    '64': 'Completato',
    '66': 'Completamento',
    '68': 'Preparazione',
    '128': 'Interrotto',
    '256': 'Allarme',
    '512': 'Fatale',
    UNKNOWN: 'Sconosciuto',
  },
  material_test_generator: {
    block_settings: 'Impostazioni Blocco',
    columns: 'Colonne',
    count: 'Quantità',
    cut: 'Taglia',
    engrave: 'Incidi',
    export: 'Esporta',
    max: 'Max',
    min: 'Min',
    parameter: 'Parametro',
    preview: 'Anteprima',
    rows: 'Righe',
    size: 'Dimensioni (AxL)',
    spacing: 'Spaziatura',
    table_settings: 'Impostazioni Tabella',
    text_settings: 'Impostazioni testo',
    title: 'Generatore di Test Materiale',
  },
  menu: {
    inches: 'Pollici',
    mm: 'mm',
  },
  message: {
    auth_error: "#820 Errore di autenticazione: aggiornare Beam Studio e il firmware della macchina all'ultima versione.",
    authenticating: 'Autenticazione in corso...',
    camera: {
      abort_preview: 'Interrompi',
      camera_cable_unstable: "È stato rilevato che la fotocamera sta trasferendo le foto in modo instabile. L'anteprima può essere eseguita normalmente, ma potrebbero esserci problemi di lentezza o timeout.",
      continue_preview: 'Continua',
      fail_to_transmit_image: '#845 Si è verificato un errore durante la trasmissione dell\'immagine. Riavviare la macchina o Beam Studio. Se l\'errore persiste, seguire <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">questa guida</a>.',
      ws_closed_unexpectly: '#844 La connessione alla fotocamera della macchina è stata chiusa inaspettatamente. Se l\'errore persiste, seguire <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">questa guida</a>.',
    },
    cancelling: 'Annullamento...',
    cant_connect_to_device: '#827 Impossibile connettersi alla macchina, controllare la connessione',
    connected: 'Connesso',
    connecting: 'Connessione in corso...',
    connectingCamera: 'Connessione telecamera...',
    connectingMachine: 'Connessione a %s in corso...',
    connectionTimeout: "#805 Timeout connessione dispositivo. Controllare lo stato della rete e l'indicatore Wi-Fi della macchina.",
    device_blocked: {
      caption: 'Numero di serie non autorizzato',
      offline: "Il numero di serie del dispositivo è limitato all'uso offline poiché sembra essere disattivato. Fornire il numero di serie del dispositivo (posizionato sul retro del dispositivo) al rivenditore e chiedere di contattare support@flux3dp.com per attivare l'autorizzazione online. Se desideri utilizzare il dispositivo offline, contatta direttamente support@flux3dp.com.",
      online: "Il numero di serie del dispositivo sembra essere disattivato. Fornire il numero di serie del dispositivo (posizionato sul retro del dispositivo) al rivenditore e chiedere di contattare support@flux3dp.com per attivare l'autorizzazione del dispositivo.",
    },
    device_busy: {
      caption: 'Macchina occupata',
      message: 'La macchina sta eseguendo un altro compito, riprovare più tardi. Se smette di funzionare, riavviare la macchina.',
    },
    device_is_used: "La macchina è in uso, interrompere l'attività corrente?",
    device_not_found: {
      caption: 'Macchina predefinita non trovata',
      message: "#812 Controllare l'indicatore Wi-Fi della macchina",
    },
    disconnected: 'Connessione instabile, controllare la connessione al dispositivo e riprovare più tardi',
    endingLineCheckMode: 'Uscita dalla modalità controllo linea...',
    endingRawMode: 'Uscita dalla modalità raw...',
    enteringLineCheckMode: 'Accesso alla modalità controllo linea...',
    enteringRawMode: 'Accesso alla modalità raw...',
    enteringRedLaserMeasureMode: 'Entrando in modalità di misurazione con laser rosso...',
    exitingRotaryMode: 'Uscita dalla modalità rotante...',
    getProbePosition: 'Ottenimento della posizione della sonda...',
    gettingLaserSpeed: 'Lettura velocità testa laser...',
    homing: 'Riporto a zero...',
    need_password: 'Necessaria password per connettersi alla macchina',
    please_enter_dpi: 'Inserire la dimensione del file (in mm)',
    preview: {
      adjust: 'Regola',
      adjust_height_tooltip: 'Selezionare la casella di controllo per abilitare la modifica.',
      already_performed_auto_focus: 'Hai già eseguito la messa a fuoco automatica, vuoi utilizzare i valori esistenti?',
      auto_focus: 'Messa a fuoco automatica',
      auto_focus_instruction: "Spostare il modulo laser sopra l'oggetto e seguire le istruzioni animate per premere AF per mettere a fuoco.",
      camera_preview: 'Anteprima fotocamera',
      enter_manually: 'Inserisci manualmente',
      please_enter_height: "Inserire l'altezza dell'oggetto. Per catturare uno scatto di fotocamera preciso.",
    },
    promark_disconnected: '#850 Connessione al dispositivo interrotta, controlla lo stato della connessione del dispositivo.',
    redLaserTakingReference: 'Acquisizione della referenza...',
    retrievingCameraOffset: 'Lettura offset telecamera...',
    settingLaserSpeed: 'Impostazione velocità testa laser...',
    swiftray_disconnected: 'Impossibile connettersi al backend, tentativo di riconnessione.',
    swiftray_reconnected: 'Il backend è stato riconnesso, riprova a inviare il lavoro.',
    time_remaining: 'Tempo rimanente:',
    tryingToConenctMachine: 'Tentativo di connessione alla macchina...',
    turningOffAirPump: 'Spegnimento pompa aria...',
    turningOffFan: 'Spegnimento ventola...',
    unable_to_find_machine: 'Impossibile trovare la macchina ',
    unable_to_start: "#830 Impossibile avviare l'attività. Riprovare. Se il problema persiste, contattarci con segnalazione bug:",
    unavailableWorkarea: "#804 L'area di lavoro corrente supera quella della macchina selezionata. Controllare l'area di lavoro della macchina selezionata o impostare l'area di lavoro da Modifica > Impostazioni documento.",
    unknown_device: '#826 Impossibile connettersi alla macchina, assicurarsi che la USB sia collegata alla macchina',
    unknown_error: "#821 L'applicazione ha rilevato un errore sconosciuto, utilizzare Aiuto > Menu > Segnalazione bug.",
    unsupport_osx_version: 'La versione corrente di macOS %s potrebbe non supportare alcune funzioni. Aggiornare a macOS 11+.',
    unsupport_win_version: "La versione corrente del sistema operativo %s potrebbe non supportare alcune funzioni. Aggiornare all'ultima versione.",
    unsupported_example_file: "Il file di esempio selezionato non è supportato dall'area di lavoro corrente.",
    uploading_fcode: 'Caricamento FCode',
    usb_unplugged: 'Connessione USB persa. Controllare la connessione USB',
    wrong_swiftray_version_message: 'Versione backend errata (versione: {version}). Controlla se sono in esecuzione più istanze di Beam Studio e chiudi quelle extra prima di riprovare.',
    wrong_swiftray_version_title: 'Versione backend errata',
  },
  monitor: {
    ask_reconnect: 'Connessione persa. Riconnettersi?',
    bug_report: 'Segnalazione bug',
    camera: 'Telecamera',
    cancel: 'Annulla',
    confirmFileDelete: 'Eliminare questo file?',
    connecting: 'Connessione in corso, attendere...',
    DEVICE_ERROR: 'Errore imprevisto\nRiavvia la macchina',
    download: 'Scarica',
    extensionNotSupported: 'Formato file non supportato',
    fileExistContinue: 'Il file esiste già, sovrascrivere?',
    forceStop: "Interrompere l'attività corrente?",
    go: 'Avvia',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Sensore flusso aria anomalo',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Fondo aperto. Chiudere fondo per continuare.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Chiudere porta per continuare.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Cassetto aperto',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Sensore fiamma anomalo',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Rilevato modulo non corretto. Installare il modulo corretto per continuare.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modulo non rilevato. Assicurarsi che il modulo sia installato correttamente per continuare.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Modulo sconosciuto rilevato. Installare il modulo corretto per continuare.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Errore pull-off homing',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Errore critico: Scheda madre offline. Contattare assistenza FLUX.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Surriscaldamento. Attendere qualche minuto.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Reset asse Z non riuscito',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Nessuna risposta dal modulo stampante.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Si prega di ritrarre la sonda.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Controllare serbatoio acqua.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Nessun modulo rotante rilevato',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Errore critico: Nessuna risposta dal sottosistema. Contattare assistenza FLUX.',
    HARDWARE_FAILURE: 'Errore imprevisto\nRiavvia la macchina',
    hour: 'h',
    left: 'sinistra',
    MAINBOARD_OFFLINE: '#905 Errore di connessione alla scheda madre.\nRiavvia la macchina.',
    minute: 'm',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 Errore di connessione alla scheda madre.\nRiavvia la macchina.',
    pause: 'Pausa',
    prepareRelocate: 'Preparazione spostamento',
    processing: 'Elaborazione in corso',
    record: 'Registra',
    relocate: 'Sposta',
    RESOURCE_BUSY: 'La macchina è occupata\nSe non è in esecuzione, riavvia la macchina',
    resume: 'Riprendi',
    savingPreview: 'Generazione anteprime',
    second: 's',
    start: 'Avvia',
    stop: 'Interrompi',
    SUBSYSTEM_ERROR: "#402 Errore critico: nessuna risposta dal sottosistema. Contatta l'assistenza FLUX.",
    task: {
      BEAMBOX: 'Taglio laser',
      'N/A': 'Modalità libera',
    },
    taskTab: 'Attività',
    temperature: 'Temperatura',
    upload: 'Carica',
    USER_OPERATION: 'Si prega di seguire le istruzioni sul pannello del dispositivo per continuare.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Si prega di inserire la cartuccia corretta per continuare.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Si prega di inserire la cartuccia per continuare.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Installare il modulo corretto per continuare.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Rilevato modulo non corretto. Installare il modulo corretto per continuare.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modulo non rilevato. Assicurarsi che il modulo sia installato correttamente per continuare.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Modulo sconosciuto rilevato. Installare il modulo corretto per continuare.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Rimuovere la cartuccia per continuare.',
    USER_OPERATION_ROTARY_PAUSE: 'Passare a motore rotante',
  },
  my_cloud: {
    action: {
      confirmFileDelete: "Sei sicuro di voler eliminare questo file? Quest'azione non può essere annullata.",
      delete: 'Elimina',
      download: 'Scarica',
      duplicate: 'Duplica',
      open: 'Apri',
      rename: 'Rinomina',
    },
    file_limit: 'File gratuito',
    loading_file: 'Caricamento...',
    no_file_subtitle: 'Vai al Menu > "File" > "Salva sul cloud"',
    no_file_title: 'Salva i file nel cloud per iniziare.',
    save_file: {
      choose_action: 'Salva file:',
      input_file_name: 'Inserisci nome file:',
      invalid_char: 'Caratteri non validi:',
      save: 'Salva',
      save_new: 'Salva come nuovo file',
      storage_limit_exceeded: "L'archiviazione cloud ha raggiunto il limite massimo. Elimina eventuali file non necessari prima di salvarne di nuovi.",
    },
    sort: {
      a_to_z: 'Nome: A-Z',
      most_recent: 'Più recenti',
      oldest: 'Meno recenti',
      z_to_a: 'Nome: Z-A',
    },
    title: 'La mia nuvola',
    upgrade: 'Aggiorna',
  },
  noun_project_panel: {
    clear: 'Pulisci',
    elements: 'Elementi',
    enjoy_shape_library: 'Goditi il database delle forme.',
    export_svg_title: 'Esportazione SVG non riuscita',
    export_svg_warning: "Questo progetto contiene oggetti protetti da copyright. Pertanto Beam Studio escluderà automaticamente questi oggetti durante l'esportazione. È comunque possibile salvare l'intero progetto nel formato Beam Studio Scene (.beam) per conservare tutti i dati. Vuoi comunque esportare?",
    learn_more: 'Per saperne di più',
    login_first: 'Accedi per sbloccare il database delle forme.',
    recent: 'Recenti',
    search: 'Cerca',
    shapes: 'Forme',
  },
  pass_through: {
    export: "Esporta nell'Area di Lavoro",
    exporting: 'Esportazione...',
    guide_mark: 'Segno di Guida',
    guide_mark_desc: "I segni di guida saranno incisi come punto di riferimento per allineare l'opera d'arte.",
    guide_mark_length: 'Lunghezza:',
    guide_mark_x: 'Coordinata X:',
    height_desc: "Imposta l'altezza di ciascuna sezione dell'area di lavoro.",
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Come configurare il passaggio per %(model)s?',
    object_length: 'Lunghezza Oggetto',
    ref_layer: 'Livello di Riferimento',
    ref_layer_desc: "Si noti che l'esecuzione del livello di riferimento è impostata su 0 per impostazione predefinita. Non verrà eseguita, ma è solo per riferimento di allineamento.",
    ref_layer_name: 'Riferimento',
    title: 'Modalità passaggio',
    workarea_height: 'Area di Lavoro (Altezza):',
  },
  promark_connection_test: {
    description: 'Il sistema eseguirà un’operazione con luce rossa per circa due minuti per verificare se la connessione è stabile.',
    health: 'Stabilità:',
    healthy_description: 'La stabilità è buona, ma evita di spostare significativamente il computer durante l’incisione.',
    res_0: 'Eccellente',
    res_1: 'Buona',
    res_2: 'Bassa',
    res_3: 'Troppo bassa',
    res_4: 'Molto bassa',
    restart: 'Riavvia il test',
    start: 'Avvia il test',
    stop: 'Interrompi il test',
    title: 'Test di stabilità della connessione',
    unhealthy_description: 'La stabilità è troppo bassa. Si consiglia di sostituire l’adattatore di rete o il cavo di alimentazione.',
  },
  promark_settings: {
    angle: 'Angolo',
    bulge: 'Rigonfiamento',
    field: 'Campo',
    galvo_configuration: 'Configurazione Galvo',
    mark: 'Marca',
    mark_parameters: 'Parametri di marcatura',
    offsetX: 'Offset X',
    offsetY: 'Offset Y',
    preview: 'Anteprima',
    red_dot: 'Punto rosso',
    scale: 'Scala',
    scaleX: 'Scala X',
    scaleY: 'Scala Y',
    skew: 'Inclinazione',
    switchXY: 'Scambia X/Y',
    title: 'Impostazioni Promark',
    trapezoid: 'Trapezio',
    workarea_hint: 'Puoi cambiare l\'area di lavoro nelle "Impostazioni del documento".',
    z_axis_adjustment: {
      section1: 'Regola l’altezza dell’asse Z per perfezionare la messa a fuoco.',
      title: 'Regolazione Asse Z',
      tooltip1: 'Prova a segnare un quadrato di 1x1 cm per confermare se la distanza focale attuale è adeguata.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Tolleranza errore',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Inverti colore sfondo',
    placeholder: 'Inserisci un link o un testo',
    preview: 'Anteprima',
    title: 'Generatore codici QR',
  },
  rotary_settings: {
    circumference: 'Circonferenza',
    extend_workarea: 'Espandi area di lavoro',
    mirror: 'Specchio',
    object_diameter: "Diametro dell'Oggetto",
    overlap_size: 'Dimensione della sovrapposizione',
    rotary_scale: 'Scala di rotazione',
    split_setting: 'Impostazione di divisione',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Dimensione della divisione',
    type: 'Tipo',
  },
  select_device: {
    auth_failure: '#811 Autenticazione non riuscita',
    select_device: 'Seleziona dispositivo',
    unable_to_connect: '#810 Impossibile stabilire una connessione stabile con la macchina',
  },
  settings: {
    anti_aliasing: 'Anti-aliasing',
    auto_connect: "Seleziona automaticamente l'unica macchina",
    auto_switch_tab: 'Commutazione automatica tra pannello livelli e oggetti',
    autofocus_offset: 'Offset messa a fuoco automatica',
    autosave_enabled: 'Salvataggio automatico',
    autosave_interval: 'Salva ogni',
    autosave_number: 'Numero di salvataggi automatici',
    autosave_path: 'Percorso salvataggio automatico',
    autosave_path_not_correct: 'Percorso specificato non trovato.',
    blade_precut_position: 'Posizione taglio preliminare',
    blade_precut_switch: 'Taglio preliminare lama',
    blade_radius: 'Raggio lama',
    bottom_up: "Dal basso verso l'alto",
    calculation_optimization: 'Accelerazione del calcolo del percorso',
    cancel: 'Annulla',
    caption: 'Impostazioni',
    check_updates: 'Controllo aggiornamenti automatico',
    close: 'Chiudi',
    confirm_remove_default: 'La macchina predefinita verrà rimossa.',
    confirm_reset: 'Confermare reset Beam Studio?',
    continuous_drawing: 'Disegno continuo',
    curve_engraving_speed_limit: 'Limite di velocità per l’incisione su curva',
    custom_preview_height: 'Altezza anteprima personalizzata',
    default_beambox_model: 'Impostazione documento predefinita',
    default_borderless_mode: 'Apri inferiore predefinito',
    default_enable_autofocus_module: 'Messa a fuoco automatica predefinita',
    default_enable_diode_module: 'Laser a diodi predefinito',
    default_font_family: 'Carattere predefinito',
    default_font_style: 'Stile carattere predefinito',
    default_laser_module: 'Modulo Laser Predefinito',
    default_machine: 'Macchina predefinita',
    default_machine_button: 'Vuoto',
    default_units: 'Unità predefinite',
    diode_offset: 'Offset laser a diodi',
    diode_one_way_engraving: 'Incisione laser a diodi monodirezionale',
    diode_two_way_warning: "L'emissione bidirezionale è più veloce e probabilmente genera qualche imprecisione sulla posizione di incisione laser. Si consiglia di testare prima.",
    disabled: 'Disabilitato',
    done: 'Applica',
    enable_custom_backlash: 'Abilita compensazione personalizzata del gioco',
    enable_low_speed: 'Abilita movimento a bassa velocità',
    enable_uv_print_file: 'Abilita file di stampa UV',
    enabled: 'Abilitato',
    engraving_direction: 'Direzione',
    fast_gradient: 'Ottimizzazione velocità',
    font_convert: 'Convertitore di testo in percorso',
    font_substitute: 'Sostituisci caratteri non supportati',
    grouped_objects: 'Oggetti raggruppati',
    groups: {
      ador_modules: 'Moduli Ador',
      autosave: 'Salvataggio automatico',
      camera: 'Fotocamera',
      connection: 'Connessione',
      editor: 'Editor',
      engraving: 'Rasterizzazione (Scansione)',
      general: 'Generale',
      mask: 'Ritaglio area di lavoro',
      modules: 'Componente aggiuntivo',
      path: 'Vettore (Contorni)',
      privacy: 'Privacy',
      text_to_path: 'Testo',
      update: 'Aggiornamenti software',
    },
    guess_poke: 'Cerca indirizzo IP della macchina',
    guides: 'Guide',
    guides_origin: 'Origine guide',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      uv_print_export: 'https://support.flux3dp.com/hc/en-us/articles/12320887477135',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Alto',
    image_downsampling: 'Qualità anteprima bitmap',
    ip: 'Indirizzo IP della macchina',
    keep_preview_result: 'Mantieni Risultato Anteprima',
    language: 'Lingua',
    loop_compensation: 'Compensazione ciclo',
    low: 'Basso',
    low_laser_for_preview: 'Laser per Esecuzione cornice',
    mask: 'Maschera area di lavoro',
    medium: 'Medio',
    module_offset_2w_ir: 'Offset Laser a Infrarossi 2W',
    module_offset_10w: 'Offset Laser a Diodi 10W',
    module_offset_20w: 'Offset Laser a Diodi 20W',
    module_offset_printer: 'Offset Stampante',
    none: 'Nessuno',
    normal: 'Normale',
    notification_off: 'Spento',
    notification_on: 'Acceso',
    notifications: 'Notifiche desktop',
    off: 'Spento',
    on: 'Acceso',
    preview_movement_speed: 'Velocità anteprima movimento',
    printer_advanced_mode: 'Modalità Avanzata Stampante',
    remove_default_machine_button: 'Rimuovi',
    reset: 'Reimposta Beam Studio',
    reset_now: 'Reset Beam Studio',
    segmented_engraving: 'Incisione segmentata',
    share_with_flux: 'Condividi analisi Beam Studio',
    simplify_clipper_path: 'Ottimizza il percorso calcolato',
    single_object: 'Oggetto singolo',
    tabs: {
      device: 'Macchina',
      general: 'Generale',
    },
    text_path_calc_optimization: 'Ottimizzazione calcolo percorso',
    top_down: "Dall'alto verso il basso",
    trace_output: 'Uscita traccia immagine',
    update_beta: 'Beta',
    update_latest: 'Ultima',
    vector_speed_constraint: 'Limite velocità',
    wrong_ip_format: 'Formati IP errati',
  },
  social_media: {
    facebook: 'Chatta con gli utenti FLUX, fai domande e scopri suggerimenti!',
    instagram: 'Ottieni le ultime ispirazioni, offerte e omaggi!',
    youtube: 'Guarda i tutorial di Beam Studio e le idee creative.',
  },
  support: {
    no_vcredist: 'Installare Visual C++ Redistributable 2015<br/>Scaricabile da flux3dp.com',
    no_webgl: 'WebGL non è supportato. Utilizzare altri dispositivi.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Si prega di aggiungere prima gli oggetti',
      door_opened: "Chiudere lo sportello per abilitare il laser a bassa potenza per l'esecuzione della cornice.",
      fail_to_connect_with_camera: '#803 Impossibile stabilire la connessione con la videocamera della macchina. Riavviare la macchina o Beam Studio. Se l\'errore persiste, seguire <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">questa guida</a>.',
      fail_to_get_door_status: "Assicurarsi che lo sportello sia chiuso per abilitare il laser a bassa potenza per l'esecuzione della cornice.",
      fail_to_start_preview: '#803 Impossibile avviare la modalità anteprima. Riavviare la macchina o Beam Studio. Se l\'errore persiste, seguire <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">questa guida</a>.',
      headtype_mismatch: 'Modulo non corrispondente rilevato. ',
      headtype_none: 'Modulo non rilevato. ',
      headtype_unknown: 'Modulo sconosciuto rilevato. ',
      install_correct_headtype: "Installare correttamente i moduli laser da 10W/20W per abilitare il laser a bassa potenza per l'esecuzione della cornice.",
      job_origin_unavailable: "Impostare l'origine del lavoro richiede la versione firmware 4.3.5 / 5.3.5 o superiore. Vuoi aggiornare il firmware ora?",
      job_origin_warning: 'Stai utilizzando la “posizione corrente” come punto di partenza. Assicurati che la testa laser sia spostata nella posizione corretta per evitare collisioni.',
      power_too_high: 'POTENZA TROPPO ALTA',
      power_too_high_confirm: 'HO CAPITO',
      power_too_high_msg: 'Utilizzare una potenza laser inferiore (sotto il 70%) estende la durata del tubo laser.\nInserire "HO CAPITO" per procedere.',
      pwm_unavailable: 'La Modalità Profondità richiede una versione del firmware 4.3.4 / 5.3.4 o successiva. Vuoi aggiornare il firmware adesso?',
      QcleanScene: 'Vuoi cancellare il disegno?<br/>Questo cancellerà anche la tua cronologia di annullamento!',
      start_preview_timeout: '#803 Timeout durante l\'avvio della modalità anteprima. Riavviare la macchina o Beam Studio. Se l\'errore persiste, seguire <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">questa guida</a>.',
    },
    frame_task: 'Esecuzione cornice',
    hint: {
      polygon: 'Premere + / - per aumentare / diminuire i lati.',
    },
    menu: {
      about: 'Informazioni su Beam Studio',
      about_beam_studio: 'Informazioni su Beam Studio',
      account: 'Account',
      add_new_machine: 'Configura macchina',
      align_center: 'Allinea al centro',
      anti_aliasing: 'Anti-aliasing',
      auto_align: 'Allineamento Automatico',
      borderless_mode: 'Modalità a schermo intero',
      bug_report: 'Segnalazione bug',
      calibrate_beambox_camera: 'Calibra telecamera',
      calibrate_beambox_camera_borderless: 'Calibra telecamera (parte inferiore aperta)',
      calibrate_camera_advanced: 'Calibra la fotocamera (Avanzato)',
      calibrate_diode_module: 'Calibra modulo laser',
      calibrate_ir_module: 'Calibra modulo a infrarossi',
      calibrate_printer_module: 'Calibra modulo di stampa',
      calibration: 'Calibrazioni',
      camera_calibration_data: 'Dati di Calibrazione della Fotocamera',
      change_logs: 'Registro modifiche',
      clear_scene: 'Nuovi file',
      close: 'Chiudi finestra',
      commands: 'Comandi',
      contact: 'Contattaci',
      copy: 'Copia',
      cut: 'Taglia',
      dashboard: 'Dashboard',
      decompose_path: 'Scomponi',
      delete: 'Elimina',
      design_market: 'Mercato progetti',
      dev_tool: 'Strumento di Debug',
      disassemble_use: 'Disassembla',
      document_setting: 'Impostazioni documento',
      document_setting_short: 'Documento',
      download_data: 'Scarica Dati',
      download_log: 'Scarica registri',
      download_log_canceled: 'Download registri annullato',
      download_log_error: 'Si è verificato un errore sconosciuto, riprova più tardi',
      duplicate: 'Duplica',
      edit: 'Modifica',
      example_files: 'File di Esempio',
      export_BVG: 'BVG',
      export_flux_task: 'Attività FLUX',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Esporta su...',
      export_UV_print: 'Stampa UV PDF',
      file: 'File',
      fit_to_window: 'Adatta alla finestra',
      follow_us: 'Seguici',
      forum: 'Forum community',
      group: 'Raggruppa',
      help: 'Aiuto',
      help_center: 'Centro assistenza',
      hide: 'Nascondi Beam Studio',
      hideothers: 'Nascondi altri',
      image_crop: 'Ritaglia',
      image_curve: 'Curva',
      image_invert: 'Inverti',
      image_sharpen: 'Nitidezza',
      image_stamp: 'Smusso',
      image_vectorize: 'Traccia',
      import_acrylic_focus_probe: 'Sonda messa a fuoco acrilico - 3mm',
      import_ador_laser_example: 'Esempio di Ador Laser',
      import_ador_printing_example_full: 'Esempio di stampa Ador - Colore completo',
      import_ador_printing_example_single: 'Esempio di stampa Ador - Monocolore',
      import_beambox_2_example: 'Esempio di Beambox II',
      import_beambox_2_focus_probe: 'Sonda di Messa a Fuoco Beambox II - 3 mm',
      import_hello_beambox: 'Esempio di Beambox',
      import_hello_beamo: 'Esempio di beamo',
      import_hexa_example: 'Esempio di HEXA',
      import_material_printing_test: 'Test di stampa materiale',
      import_material_testing_cut: 'Test taglio materiale',
      import_material_testing_engrave: 'Test incisione materiale',
      import_material_testing_line: 'Test linea materiale',
      import_material_testing_old: 'Test incisione materiale - Classico',
      import_material_testing_simple_cut: 'Test taglio materiale - Semplice',
      import_promark_example: 'Esempio di Promark',
      import_promark_mopa_20w_color: 'Test Colore MOPA 20W',
      import_promark_mopa_60w_color: 'Test Colore MOPA 60W',
      import_promark_mopa_100w_color: 'Test Colore MOPA 100W',
      keyboard_shortcuts: 'Scorciatoie da tastiera',
      layer_color_config: 'Impostazioni colore',
      layer_setting: 'Livello',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Fotocamera',
        cloud: 'Cloud',
        discover: 'Rileva',
        hardware: 'Hardware',
        network: 'Rete',
        player: 'Lettore',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'Elenco USB',
      },
      machine_info: 'Informazioni macchina',
      machines: 'Macchine',
      manage_account: 'Gestisci account',
      material_test: 'Test del Materiale',
      minimize: 'Riduci a icona',
      my_account: 'Il mio account',
      network_testing: 'Test impostazioni rete',
      object: 'Oggetto',
      offset: 'Sposta',
      open: 'Apri',
      paste: 'Incolla',
      paste_in_place: 'Incolla sul posto',
      path: 'Tracciato',
      photo_edit: 'Immagine',
      preferences: 'Preferenze',
      promark_color_test: 'Test Colore Promark',
      questionnaire: 'Questionario feedback',
      quit: 'Esci',
      recent: 'Apri Recenti',
      redo: 'Ripeti',
      reload_app: "Ricarica l'App",
      reset: 'Ripristina',
      rotary_setup: 'Configurazione Rotativa',
      rotate: 'Ruota',
      samples: 'Esempi',
      save_as: 'Salva con nome...',
      save_scene: 'Salva',
      save_to_cloud: 'Salva sul cloud',
      scale: 'Scala',
      service: 'Servizi',
      set_as_default: 'Imposta come predefinito',
      show_gesture_tutorial: 'Introduzione gesti',
      show_grids: 'Mostra griglie',
      show_layer_color: 'Usa colore del livello',
      show_rulers: 'Mostra righelli',
      show_start_tutorial: 'Mostra tutorial introduttivo',
      show_ui_intro: 'Mostra introduzione interfaccia',
      sign_in: 'Accedi',
      sign_out: 'Disconnetti',
      software_update: 'Aggiornamento software',
      svg_edit: 'SVG',
      switch_to_beta: 'Passa alla Versione Beta',
      switch_to_latest: 'Passa alla Versione Stabile',
      tools: {
        box_generator: 'Generatore di Scatole',
        code_generator: 'Generatore di codice',
        material_test_generator: 'Generatore di Test Materiale',
        title: 'Strumenti',
      },
      tutorial: 'Tutorial stampa Delta',
      undo: 'Annulla',
      ungroup: 'Separa',
      update: 'Controlla Aggiornamenti',
      update_firmware: 'Aggiorna firmware',
      upload_data: 'Carica Dati',
      using_beam_studio_api: 'Utilizzo Beam Studio API',
      view: 'Visualizza',
      window: 'Finestra',
      zoom_in: 'Ingrandisci',
      zoom_out: 'Rimpicciolisci',
      zoom_with_window: 'Adatta automaticamente alla finestra',
    },
    preview: 'ANTEPRIMA',
    preview_press_esc_to_stop: 'Premi ESC per interrompere l’anteprima della fotocamera.',
    rename_tab: 'Rinomina scheda',
    select_machine: 'Seleziona una macchina',
    tag_names: {
      dxf: 'Oggetto DXF',
      ellipse: 'Ovale',
      g: 'Gruppo',
      image: 'Immagine',
      line: 'Linea',
      multi_select: 'Più oggetti',
      no_selection: 'Nessuna selezione',
      pass_through_object: 'Oggetto Passante',
      path: 'Percorso',
      polygon: 'Poligono',
      rect: 'Rettangolo',
      svg: 'Oggetto SVG',
      text: 'Testo',
      text_path: 'Testo su percorso',
      use: 'Oggetto importato',
    },
    task_preview: 'Anteprima percorso',
    titles: {
      settings: 'Preferenze',
    },
    untitled: 'Senza titolo',
  },
  topmenu: {
    credit: 'Beam Studio è reso possibile dal progetto open source <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> e da altri <a target="_blank" href="https://flux3dp.com/credits/">software open source</a>.',
    device: {
      download_log_canceled: 'Download log annullato',
      download_log_error: 'Si è verificato un errore sconosciuto, riprova più tardi',
      log: {
        usblist: 'Elenco USB',
      },
      network_test: 'Testa rete',
    },
    file: {
      all_files: 'Tutti i file',
      clear_recent: 'Cancella recenti',
      converting: 'Conversione in immagine in corso...',
      fcode_files: 'FLUX Code',
      import: 'Importa',
      jpg_files: 'JPG',
      label: 'File',
      path_not_exit: 'Questo percorso non sembra più esistere sul disco.',
      png_files: 'PNG',
      save_fcode: 'Esporta attività FLUX',
      save_jpg: 'Esporta JPG',
      save_png: 'Esporta PNG',
      save_scene: 'Salva scena',
      save_svg: 'Esporta SVG',
      scene_files: 'Beam Studio Scene',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Versione',
  },
  tutorial: {
    ask_retry_calibration: 'Vuoi calibrare nuovamente la fotocamera?',
    camera_calibration_failed: 'Calibrazione fotocamera non riuscita',
    gesture: {
      click: 'Tocca per selezionare oggetto.',
      drag: 'Trascina per selezionare più oggetti.',
      hold: 'Premi e tieni premuto per aprire menu contestuale.',
      pan: 'Scorri la tela con due dita.',
      zoom: 'Avvicina/allontana con due dita per ingrandire/rimpicciolire la tela.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Ricerca macchina per tutorial...',
    needNewInterfaceTutorial: 'Vorresti iniziare un tutorial per la nuova interfaccia di Beam Studio?<br/>(Puoi saltarlo ora e iniziarlo più tardi cliccando su "Aiuto" > "Mostra Introduzione all\'Interfaccia".)',
    needNewUserTutorial: 'Vorresti iniziare un tutorial?<br/>(Puoi saltarlo ora e iniziarlo più tardi cliccando su "Aiuto" > "Mostra Tutorial di Avvio".)',
    newInterface: {
      add_new_layer: 'Aggiungi nuovo livello',
      align_controls: 'Controlli allineamento',
      basic_shapes: 'Forme di base',
      camera_preview: 'Anteprima fotocamera',
      drag_to_sort: 'Trascina per ordinare',
      end_alert: 'Sei sicuro di terminare la presentazione della nuova interfaccia utente?',
      flip: 'Capovolgi',
      group_controls: 'Controlli gruppo',
      layer_controls: 'Clic destro per selezionare i controlli del livello:\nDuplica / Unisci / Blocca / Elimina livelli',
      object_actions: 'Azioni oggetto',
      pen_tool: 'Strumento penna',
      rename_by_double_click: 'Rinomina con doppio clic',
      select_image_text: 'Seleziona / Immagine / Testo',
      select_machine: 'Seleziona una macchina',
      shape_operation: 'Operazione forma',
      start_work: 'Inizia il lavoro',
      switch_between_layer_panel_and_object_panel: 'Passa tra pannello livelli e pannello oggetti',
    },
    newUser: {
      add_new_layer: 'Aggiungi nuovo livello',
      adjust_focus: '2. Regola la messa a fuoco',
      close_cover: '3. Chiudi il coperchio',
      drag_to_draw: 'Trascina per disegnare',
      draw_a_circle: 'Disegna un cerchio',
      draw_a_rect: 'Disegna un rettangolo',
      end_alert: 'Sei sicuro di voler terminare il tutorial?',
      end_preview_mode: 'Termina modalità anteprima',
      infill: 'Attiva riempimento',
      please_select_wood_cutting: 'Seleziona gentilmente il preset "Legno - Taglio".',
      please_select_wood_engraving: 'Seleziona gentilmente il preset "Legno - Incisione".',
      preview_the_platform: 'Anteprima la piattaforma',
      put_wood: '1. Metti il campione di legno',
      send_the_file: 'Invia il file',
      set_preset_wood_cut: 'Imposta predefinito: Legno - Taglio',
      set_preset_wood_engraving: 'Imposta predefinito: Legno - Incisione',
      switch_to_layer_panel: 'Passa al pannello livelli',
      switch_to_object_panel: 'Passa al pannello degli oggetti',
      switch_to_preview_mode: 'Passa alla modalità anteprima',
    },
    next: 'AVANTI',
    retry: 'Riprova',
    set_connection: 'Imposta connessione',
    skip: 'Salta',
    skip_tutorial: 'Hai saltato il tutorial. Puoi sempre avviare il tutorial facendo clic su "Aiuto" > "Mostra tutorial di avvio"',
    suggest_calibrate_camera_first: 'Consigliamo agli utenti di calibrare la fotocamera inizialmente e rifocalizzare prima di ogni anteprima per risultati ottimali.<br/>Vuoi confermare per effettuare la calibrazione ora?<br/>(Puoi saltarlo ora e farlo più tardi cliccando su "Menu" > "Macchina" > [La Tua Macchina] > "Calibra Fotocamera".)',
    tutorial_complete: 'Ecco fatto per il tutorial. Ora è il momento di creare!',
    unable_to_find_machine: 'Impossibile trovare la macchina per il tutorial. Vuoi andare alla pagina delle impostazioni di connessione, riprovare o saltare il tutorial?',
    welcome: 'BENVENUTO',
  },
  update: {
    cannot_reach_internet: '#823 Server irraggiungibile<br/>Controlla la connessione a internet',
    download: 'AGGIORNAMENTO ONLINE',
    firmware: {
      caption: 'Disponibile aggiornamento firmware per la macchina',
      confirm: 'CARICA',
      firmware_too_old_update_by_sdcard: 'Versione del firmware troppo vecchia. Aggiornare il firmware utilizzando una scheda SD.',
      force_update_message: "#814 Aggiornare la macchina all'ultima versione del firmware.",
      latest_firmware: {
        cant_get_latest: "Impossibile ottenere informazioni sull'ultimo firmware.",
        caption: 'Aggiornamento firmware macchina',
        message: "Hai l'ultima versione del firmware della macchina",
        still_update: 'AGGIORNA',
      },
      message_pattern_1: '"%s" è pronto per l\'aggiornamento del firmware.',
      message_pattern_2: 'Firmware %s v%s disponibile - Hai la v%s.',
      too_old_for_web: "La versione corrente del firmware della macchina è la v%s.\nPer utilizzare la versione online di Beam Studio, aggiornare il firmware all'ultima versione.",
      update_fail: '#822 Aggiornamento non riuscito',
      update_success: 'Aggiornamento firmware caricato correttamente',
      upload_file: 'Caricamento firmware (*.bin / *.fxfw)',
    },
    install: 'INSTALLA',
    later: 'PIÙ TARDI',
    preparing: 'Preparazione in corso...',
    release_note: 'Note di rilascio:',
    skip: 'Salta questa versione',
    software: {
      available_switch: 'Beam Studio v%s è disponibile ora. Hai la v%s. Vuoi passare a questa versione?',
      available_update: "Beam Studio v%s è disponibile ora. Hai la v%s. Vuoi scaricare l'aggiornamento?",
      caption: 'Disponibile aggiornamento software per Beam Studio',
      check_update: 'Controlla aggiornamenti',
      checking: 'Controllo aggiornamenti',
      downloading: 'Download degli aggiornamenti in background, fare clic su "OK" per continuare.',
      install_or_not: "è pronto per l'aggiornamento. Vuoi riavviare e aggiornare ora?",
      no: 'No',
      no_response: 'Impossibile connettersi al server, controlla le impostazioni di rete.',
      not_found: "Stai utilizzando l'ultima versione di Beam Studio.",
      switch_or_not: 'è pronto per il passaggio. Vuoi riavviare e passare ora?',
      switch_version: 'Cambia versione',
      switch_version_not_found: 'Versione passabile non trovata.',
      update_for_ador: "La versione software corrente %s non è compatibile, scarica l'ultima versione di Beam Studio per Ador.",
      yes: 'Sì',
    },
    update: 'Aggiorna',
    updating: 'Aggiornamento in corso...',
    upload: 'CARICA',
  },
  web_cam: {
    no_device: 'Impossibile rilevare il dispositivo della fotocamera. Ricollega la fotocamera e riprova.',
    no_permission: "Beam Studio non ha l'autorizzazione per accedere alla fotocamera. Assicurati che l'autorizzazione sia concessa a Beam Studio nelle impostazioni del browser o del sistema.",
  },
  z_speed_limit_test: {
    alert_before: 'Prima di iniziare l’incisione della curva, il sistema eseguirà un test di carico per evitare la perdita di passi dovuta a un peso eccessivo.',
    alert_failed: "Il peso dell'oggetto attuale supera il limite di carico per la velocità attuale dell'asse Z. Riduci la velocità dell'asse Z nel pannello della macchina o sostituisci l'oggetto di incisione prima di ripetere il test.",
    ignore: 'Ignora',
    retest: 'Riprova',
    testing: 'Esecuzione del test di carico...',
  },
};

export default lang;
