import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Avbryt',
    cancel: 'Avbryt',
    caption: 'Feil',
    close: 'Lukk',
    confirm: 'Bekreft',
    dont_save: 'Ikke lagre',
    dont_show_again: 'Ikke vis igjen',
    duplicated_preset_name: 'Duplisert forhåndsinnstilt navn',
    error: 'FEIL',
    info: 'INFO',
    instruction: 'Instruksjon',
    learn_more: 'Lær mer',
    no: 'Nei',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Oops...',
    retry: 'Prøv igjen',
    save: 'Lagre',
    stop: 'Stopp',
    warning: 'ADVARSEL',
    yes: 'Ja',
  },
  auto_fit: {
    artwork_size: 'Kunstverkets størrelse',
    error_tip1: '1. Er kunstverket plassert riktig på materialet?',
    error_tip2: '2. Er materialkonturene klare nok for gjenkjenning?',
    failed_to_find_contour: 'Fant ingen konturer for automatisk tilpasning',
    learn_more: 'Vis veiledning.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Forskyvning X',
    offset_y: 'Forskyvning Y',
    pattern_size: 'Mønsterstørrelse',
    position_artwork: 'Plasser kunstverket',
    position_step1: 'Juster størrelsen og plasseringen av kunstverket ditt på mønsteret.',
    position_step2: 'Klikk på "Bruk" for å bruke Auto Fit på dette mønsteret.',
    preview_first: 'Utfør kameraforhåndsvisningen først.',
    reset_position: 'Tilbakestill posisjon',
    rotation: 'Roter',
    select_a_pattern: 'Velg et mønster',
    selected_artwork: 'Valgt kunstverk',
    step1: 'Velg et mønster hvor du vil plassere kunstverket.',
    step2: 'Hvis ingen mønster blir funnet, forhåndsvis kameraet på nytt og bruk Automatisk Tilpasning.',
    step3: 'Hvis det fortsatt ikke lykkes å finne riktig mønster, sørg for at arbeidsstykket ditt er tydelig gjenkjennelig og at arbeidsområdet er fritt for rusk.',
    title: 'Automatisk Tilpasning',
  },
  barcode_generator: {
    bar_height: 'Stolpehøyde',
    bar_width: 'Stolpebredde',
    barcode: {
      invalid_value: 'Verdien er ugyldig for det valgte formatet.',
    },
    font: 'Skrift',
    font_size: 'Skriftstørrelse',
    hide_text: 'Skjul tekst',
    invert_color: 'Inverter farge',
    text_margin: 'Tekstmarg',
  },
  beambox: {
    announcement_panel: {
      title: 'Kunngjøring',
    },
    banner: {
      auto_feeder: 'Automatisk Matermodus',
      camera_preview: 'Forhåndsvisning',
      camera_preview_borderless_mode: '(Åpen Bunn)',
      curve_engraving: 'Kurvegravering Modus',
      pass_through: 'Gjennomgangsmodus',
      rotary: 'Rotasjonsmodus',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Konverter tekst til bane...',
      export_file_error_ask_for_upload: 'Kunne ikke eksportere oppgave. Er du villig til å sende arbeidsscene til utviklingsteamet for feilrapportering?',
      retreive_image_data: 'Hente bildedata...',
    },
    context_menu: {
      copy: 'Kopier',
      cut: 'Klipp ut',
      delete: 'Slett',
      duplicate: 'Dupliser',
      group: 'Grupper',
      move_back: 'Flytt bakerst',
      move_down: 'Flytt bakover',
      move_front: 'Flytt fremst',
      move_up: 'Flytt fremover',
      paste: 'Lim inn',
      paste_in_place: 'Lim inn på plass',
      ungroup: 'Del opp gruppe',
    },
    document_panel: {
      add_on: 'Tillegg',
      auto_feeder: 'Automatisk mater',
      auto_feeder_scale: 'Skala for automatisk mater',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Åpen bunn',
      current_position: 'Gjeldende Posisjon',
      disable: 'Deaktiver',
      document_settings: 'Dokumentinnstillinger',
      enable: 'Aktiver',
      enable_autofocus: 'Autofokus',
      enable_diode: 'Diode-laser',
      engrave_dpi: 'Oppløsning',
      frame_before_start: 'Ramme før utførelse',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Høy',
      job_origin: 'Jobb Opprinnelse',
      laser_source: 'Laserkilde',
      low: 'Lav',
      machine: 'Maskin',
      medium: 'Medium',
      notification: {
        changeFromPrintingWorkareaTitle: 'Vil du konvertere Utskriftslag til Laserlag?',
      },
      origin: 'Opprinnelse',
      pass_through: 'Gjennomgang',
      pass_through_height_desc: 'Skriv inn lengden på objektet for å utvide arbeidsområdet.',
      rotary_mode: 'Roterende',
      scale: 'Skala',
      start_from: 'Start Fra',
      start_position: 'Startposisjon',
      start_work_button: 'Utføre Knapp',
      ultra: 'Ultra høy',
      workarea: 'Arbeidsområde',
    },
    image_trace_panel: {
      back: 'Tilbake',
      brightness: 'Lysstyrke',
      cancel: 'Avbryt',
      contrast: 'Kontrast',
      next: 'Neste',
      okay: 'OK',
      threshold: 'Terskel',
      tuning: 'Innstillinger',
    },
    layer_color_config_panel: {
      add: 'Legg til',
      add_config: 'Legg til farge',
      color: 'Farge',
      default: 'Tilbakestill til standard',
      in_use: 'Denne fargen er i bruk.',
      layer_color_config: 'Lagfargekonfigurasjoner',
      no_input: 'Vennligst skriv inn gyldig hex fargekode.',
      power: 'Effekt',
      repeat: 'Gjenta',
      speed: 'Hastighet',
      sure_to_delete: 'Er du sikker på at du vil slette denne fargeinnstillingen?',
      sure_to_reset: 'Du vil miste alle tilpassede innstillinger, er du sikker på at du vil tilbakestille til standard?',
    },
    left_panel: {
      borderless_blind_area: 'Ikke-graveringsområde',
      borderless_preview: 'Åpen bunnmodus kameravisning',
      diode_blind_area: 'Hybrid laser blind sone',
      ellipse: 'Ellipse',
      image: 'Bilde',
      label: {
        adjust_height: 'Juster høyde',
        array: 'Matrise',
        boxgen: 'Boxgen',
        choose_camera: 'Kamera',
        clear_preview: 'Fjern forhåndsvisning',
        cursor: 'Velg',
        curve_engraving: {
          clear_area: 'Fjern valgt område',
          exit: 'Avslutt',
          preview_3d_curve: 'Forhåndsvisning av kurvegravering',
          select_area: 'Velg område',
          title: 'Kurvegravering',
        },
        end_preview: 'Avslutt forhåndsvisning',
        line: 'Linje',
        live_feed: 'Direktestrøm',
        my_cloud: 'Min sky',
        oval: 'Oval',
        pass_through: 'Gjennomgang',
        pen: 'Penn',
        photo: 'Bilde',
        polygon: 'Polygon',
        preview: 'Kamera forhåndsvisning',
        qr_code: 'QR-kode',
        rect: 'Rektangel',
        shapes: 'Elementer',
        text: 'Tekst',
        trace: 'Spor bilde',
      },
      line: 'Linje',
      rectangle: 'Rektangel',
      text: 'Tekst',
      unpreviewable_area: 'Blind sone',
    },
    network_testing_panel: {
      average_response: 'Gjennomsnittlig responstid',
      cannot_connect_1: '#840 Kunne ikke koble til mål-IP.',
      cannot_connect_2: '#840 Kunne ikke koble til mål-IP. Vennligst kontroller at målet er i samme nettverk.',
      cannot_get_local: 'Kunne ikke hente lokal IP-adresse.',
      connection_quality: 'Tilkoblingskvalitet',
      device_not_on_list: '#842 Maskinen er ikke på listen, men god tilkoblingskvalitet',
      empty_ip: '#818 Vennligst skriv inn målenhetens IP-adresse først',
      end: 'Slutt',
      fail_to_start_network_test: '#817 Kunne ikke starte nettverkstest.',
      hint_connect_camera_timeout: 'Tidsavbrudd ved kameraoppkobling?',
      hint_connect_failed_when_sending_job: 'Mislyktes å koble til ved jobbsending?',
      hint_device_often_on_list: 'Maskinen blir ofte ikke funnet på listen?',
      insert_ip: 'Målenhetens IP-adresse:',
      invalid_ip: '#818 Ugyldig IP-adresse',
      ip_startswith_169: '#843 Maskin-IP-adressen starter med 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Denne feilen oppstår vanligvis på grunn av utilstrekkelige rettigheter.\nKjør "sudo beam-studio --no-sandbox" i terminalen for å få rettigheter og utføre nettverkstest.',
      local_ip: 'Lokal IP-adresse:',
      network_testing: 'Nettverkstest',
      network_unhealthy: '#841 Dårlig tilkoblingskvalitet eller høy responstid',
      start: 'Start',
      test_completed: 'Test fullført',
      test_fail: 'Test mislyktes',
      testing: 'Tester nettverk...',
    },
    object_panels: {
      lock_desc: 'Bevar forholdet mellom bredde og høyde (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Noen tekster ble endret til andre skrifttyper når tekst ble konvertert til baner, og noen tegn ble kanskje ikke konvertert normalt. \nSjekk forhåndsvisningsbildet igjen før du sender oppgaven.',
        error_when_parsing_text: 'Feil ved konvertering av tekst til bane',
        font_substitute_pop: 'Teksten din inneholder tegn som ikke støttes av gjeldende skrift. <br/>Vil du bruke <strong>%s</strong> som erstatning?',
        retry: 'Prøv igjen senere eller velg en annen skrift',
        use_current_font: 'Bruk gjeldende skrifttype',
      },
      wait_for_parsing_font: 'Analyserer skrifttype ... Vennligst vent et øyeblikk',
    },
    path_preview: {
      current_position: 'Gjeldende posisjon',
      cut_distance: 'Skjæreavstand',
      cut_time: 'Skjæretid',
      end_preview: 'Avslutt forhåndsvisning',
      estimated_time: 'Estimert totaltid',
      invert: 'Inverter',
      pause: 'Pause',
      play: 'Spill av',
      play_speed: 'Avspillingshastighet',
      preview_info: 'Forhåndsvisningsinformasjon',
      rapid_distance: 'Reiseavstand',
      rapid_time: 'Reisetid',
      remark: '* All informasjon er estimert verdi for referanse.',
      size: 'Størrelse',
      start_here: 'Start her',
      stop: 'Stopp',
      travel_path: 'Reisevei',
    },
    photo_edit_panel: {
      aspect_ratio: 'Størrelsesforhold',
      brightness: 'Lysstyrke',
      brightness_and_contrast: 'Lysstyrke / Kontrast',
      cancel: 'Avbryt',
      circumference: 'Omkrets',
      compare: 'Sammenligne',
      contrast: 'Kontrast',
      crop: 'Beskjær',
      curve: 'Kurve',
      diameter: 'Diameter',
      free: 'Fritt',
      invert: 'Inverter farge',
      okay: 'OK',
      original: 'Opprinnelig',
      phote_edit: 'Bildebehandling',
      processing: 'Behandler',
      radius: 'Radius',
      rotary_warped: 'Roterende Forvrengt',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Skarphet',
      sharpness: 'Skarphet',
      start: 'Start',
      warp: 'Forvrenge',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Gå',
        insufficient_credit: 'Du har gått tom for kreditt',
        insufficient_credit_msg: 'Du kan ikke bruke %s. Gå til medlemssenteret og kjøp AI-kreditt',
        relogin_to_use: 'Logg inn på nytt for å bruke denne funksjonen',
      },
      auto_feeder_origin: 'Bruk rammeprevisningen for å kontrollere laserhodets bane og område, og juster deretter materialets plassering eller startposisjon for gravering basert på resultatene for å unngå kollisjoner under graveringsprosessen.',
      auto_switch_tab: {
        message: 'Et nytt alternativ for automatisk å bytte mellom lagpanelet og objektpanelet er lagt til i Innstillinger. Dette alternativet er deaktivert som standard. Vil du aktivere automatisk bytting nå? <br/>Du kan endre denne innstillingen når som helst i Innstillinger.',
        title: 'Automatisk Bytte Mellom Lag- og Objektpanel',
      },
      backend_connect_failed_ask_to_upload: '#802 Feil oppstår kontinuerlig ved tilkobling til serveren. Vil du laste opp feilrapportloggen?',
      backend_error_hint: 'Funksjoner fungerer kanskje ikke ordentlig på grunn av serverfeil.',
      both_power_and_speed_too_high: 'Bruk av lavere laserstyrke vil forlenge levetiden til laser-røret.\nOgså, for høy hastighet med denne oppløsningen kan resultere i lavere kvalitet på skyggegravering.',
      bug_report: 'Feilrapport',
      change_workarea_before_preview: 'Arbeidsområdet for %s samsvarer ikke med det gjeldende arbeidsområdet. Vil du bytte gjeldende arbeidsområde?',
      convert_to_path_fail: 'Kunne ikke konvertere til bane.',
      dxf_bounding_box_size_over: 'Tegnestørrelsen er utenfor arbeidsområdet. Flytt tegningen nærmere opprinnelsen i CAD-programvaren, eller kontroller at enheten er riktig satt.',
      dxf_version_waring: 'Versjonen av denne DXF-filen er ikke 2013, det kan være potensielle kompatibilitetsproblemer.',
      facebook_group_invitation: {
        already_joined: 'Allerede deltatt',
        join_now: 'Telle meg inn',
        later: 'Kanskje senere',
        message: 'Bli med i vår offisielle Facebook-gruppe for å koble deg sammen med andre FLUX laser-brukere, diskutere FLUX lasere, dele laserkunst og holde deg oppdatert på de siste oppdateringene på produktene våre. Vi gleder oss til å se deg der!',
        title: 'Bli med i FLUX brukergruppe',
      },
      import_file_contain_invalid_path: '#808 Importert SVG-fil inneholder ugyldig bildebane. Sjekk at alle bildefiler eksisterer eller integrer bildet i filen',
      import_file_error_ask_for_upload: 'Kunne ikke importere SVG-filen. Ønsker du å sende filen til utviklingsteamet for feilrapportering?',
      layer_by_color: 'Farge',
      layer_by_layer: 'Lag',
      loading_image: 'Laster inn bilde, vennligst vent...',
      more_than_two_object: 'For mange objekter. Støtter kun 2 objekter',
      mouse: 'Mus',
      no_support_text: 'Beam Studio støtter ikke tekst-tag for øyeblikket. Konverter tekst til bane før import.',
      nolayer: 'Enkelt lag',
      not_support_object_type: 'Støtter ikke objekttypen',
      or_turn_off_borderless_mode: 'Eller slå av åpen bunn-modus.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Feil: pdf2svg-kommandoen ble ikke funnet. Installer pdf2svg med pakkehåndtereren din (f.eks. "yum install pdf2svg" eller "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Feil ved konvertering av PDF til SVG:',
      },
      progress: {
        calculating: 'Beregner',
        uploading: 'Laster opp',
      },
      questionnaire: {
        caption: 'Hjelp oss med å fylle ut spørreskjemaet',
        message: 'Hjelp oss med å fylle ut spørreskjemaet for å gjøre Beam Studio bedre.',
        no_questionnaire_available: 'Det er ingen spørreskjemaer tilgjengelig for øyeblikket.',
        unable_to_get_url: 'Kunne ikke hente lenken til spørreskjemaet via internett. Bekreft internettilkoblingen.',
      },
      recommend_downgrade_software: 'Vi oppdaget en eldre fastvareversjon. Vi jobber med kompatibilitetsproblemer, men foreløpig anbefaler vi å gå tilbake til <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: 'Vi oppdaget en eldre fastvareversjon. Vi jobber aktivt med kompatibilitetsproblemer, men foreløpig anbefaler vi å oppdatere til nyeste fastvare.',
      save_unsave_changed: 'Vil du lagre ulagrede endringer?',
      select_at_least_two: 'Velg to objekter for å fortsette',
      select_first: 'Velg et objekt først.',
      select_import_method: 'Velg importmetode:',
      select_import_module: 'Velg modul:',
      sentry: {
        message: 'Godtar du å automatisk laste opp relevant informasjon til utviklingsteamet når feil oppstår?',
        title: 'La oss gjøre Beam Studio bedre sammen',
      },
      should_update_firmware_to_continue: '#814 Fastvaren din støtter ikke denne versjonen av Beam Studio. Oppdater fastvaren for å fortsette. (Meny > Maskin > [Din maskin] > Oppdater fastvare)',
      speed_too_high_lower_the_quality: 'For høy hastighet med denne oppløsningen kan resultere i lavere kvalitet på skyggegravering.',
      still_continue: 'Fortsett',
      successfully_uploaded: 'Filopplasting vellykket.',
      svg_1_1_waring: 'SVG-filen er i versjon 1.1, det kan være potensielle kompatibilitetsproblemer.',
      svg_image_path_waring: 'Denne SVG-filen inneholder <image> som laster fra filbane. Dette kan føre til feil ved lasting.\nFor å unngå denne risikoen, vennligst bruk innebygd bilde ved eksport av SVG.',
      text_to_path: {
        caption: 'Tekst til bane konverterer 2.0',
        message: "Beam Studio introduserer nå en ny tekst til bane konverterer (Konverterer 2.0), som produserer mer pålitelige resultater! Vil du bytte til den nå? \nDu kan også justere denne innstillingen senere i 'Tekst til bane konverterer' delen i innstillingene.",
      },
      too_fast_for_auto_feeder: 'Bruk av for høye hastigheter på lag som inneholder baneobjekter kan redusere kuttepresisjonen.\nVi anbefaler ikke å bruke hastigheter høyere enn %(limit)s når du kutter med Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'Følgende lag: %(layers)s \ninneholder vektor baneobjekter og har hastigheter som overskrider %(limit)s.\nKuttingshastigheten for vektor baneobjekter med Auto Feeder vil være begrenset til %(limit)s.\nDu kan fjerne denne begrensningen i Preferanseinnstillingene.',
      too_fast_for_curve: 'Å bruke for høy hastighet på lag som inneholder kurvegraveringsobjekter kan redusere graveringspresisjonen.\nVi anbefaler å holde hastigheten under %(limit)s for optimale resultater.',
      too_fast_for_curve_and_constrain: 'Følgende lag: %(layers)s overstiger hastighetsgrensen på %(limit)s.\nGraveringshastigheten for kurveobjekter vil bli begrenset til %(limit)s. Du kan fjerne denne grensen i Innstillinger.',
      too_fast_for_path: 'For høy hastighet i lag som inneholder baner kan resultere i lavere presisjon ved kutting.\nVi anbefaler ikke å bruke hastigheter over %(limit)s ved kutting.',
      too_fast_for_path_and_constrain: 'Følgende lag: %(layers)s \ninneholder vektorbaner, og har hastighet over %(limit)s.\nKuttehastigheten for vektorbaner vil bli begrenset til %(limit)s.\nDu kan fjerne denne begrensningen under Innstillinger.',
      touchpad: 'Touchpad',
      ungroup_use: 'Dette vil oppheve grupperingen av importerte DXF eller SVG. Fordi filen kan inneholde et stort antall elementer, kan det ta tid å oppheve grupperingen. Er du sikker på at du vil fortsette?',
      upload_failed: '#819 Filopplasting mislyktes.',
      upload_file_too_large: '#819 Filen er for stor til opplasting.',
      vectorize_shading_image: 'Gradientbilder tar lengre tid å spore og er utsatt for støy. Slå av bildegradienten før du utfører.',
    },
    rating_panel: {
      description: 'Hvis du liker Beam Studio, setter vi stor pris på om du kunne vurdere oss.',
      thank_you: 'Takk!',
      title: 'Liker du Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Avansert',
        backlash: 'Tilbakeslag',
        bi_directional: 'Toveis',
        by: 'Med',
        ce_z_high_speed: 'Hogesnelheids-Z-as',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Kanaljustering',
        color_adjustment_short: 'Kanal',
        color_strength: 'Styrke',
        cross_hatch: 'Krysskjerming',
        custom_preset: 'Tilpasset',
        cut: 'Kutt',
        diode: 'Diodlaser',
        do_not_adjust_default_para: 'Standardinnstillinger kan ikke justeres.',
        dottingTime: 'Punktid',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Gravering',
            acrylic_3mm_cutting: 'Akryl - 1/8″ Skjæring',
            acrylic_5mm_cutting: 'Akryl - 3/16″ Skjæring',
            acrylic_8mm_cutting: 'Akryl - 1/4″ Skjæring',
            acrylic_10mm_cutting: 'Akryl - 3/8″ Skjæring',
            acrylic_engraving: 'Akryl - Gravering',
            acrylic_printing: 'Akryl - Utskrift',
            aluminum_engraving: 'Aluminium - Gravering',
            aluminum_light: 'Aluminium (lys)',
            bamboo_printing: 'Bambus - Utskrift',
            black_abs: 'Svart ABS',
            black_acrylic_3mm_cutting: 'Svart akryl - 1/8″ Skjæring',
            black_acrylic_5mm_cutting: 'Svart akryl - 3/16″ Skjæring',
            black_acrylic_engraving: 'Svart akryl - Gravering',
            brass_dark: 'Messing (mørk)',
            brass_engraving: 'Messing - Gravering',
            brass_light: 'Messing (lys)',
            canvas_printing: 'Lerret - Utskrift',
            cardstock_printing: 'Kartong - Utskrift',
            copper: 'Kobber',
            cork_printing: 'Kork - Utskrift',
            denim_1mm_cutting: 'Denim - 1/32″ Skjæring',
            fabric_3mm_cutting: 'Stoff - 1/8″ Skjæring',
            fabric_5mm_cutting: 'Stoff - 3/16″ Skjæring',
            fabric_engraving: 'Stoff - Gravering',
            fabric_printing: 'Stoff - Utskrift',
            flat_stone_printing: 'Flat stein - Utskrift',
            glass_bw_engraving: 'Glass - Gravering',
            glass_printing: 'Glass - Utskrift',
            gloss_leather_printing: 'Blankt lær - Utskrift',
            gold_engraving: 'Gull - Gravering',
            iron_engraving: 'Jern - Gravering',
            leather_3mm_cutting: 'Lær - 1/8″ Skjæring',
            leather_5mm_cutting: 'Lær - 3/16″ Skjæring',
            leather_engraving: 'Lær - Gravering',
            mdf_3mm_cutting: 'MDF - 1/8″ Skjæring',
            mdf_5mm_cutting: 'MDF - 3/16″ Skjæring',
            mdf_engraving: 'MDF - Gravering',
            metal_bw_engraving: 'Metall - Gravering',
            opaque_acrylic: 'Ugjennomsiktig akryl',
            pc_printing: 'PC - Utskrift',
            rubber_bw_engraving: 'Gummi - Gravering',
            silver_engraving: 'Sølv - Gravering',
            stainless_steel_bw_engraving_diode: 'Metall - Gravering (diode laser)',
            stainless_steel_dark: 'Rustfritt stål (mørk)',
            stainless_steel_engraving: 'Rustfritt stål - Gravering',
            stainless_steel_light: 'Rustfritt stål (lys)',
            stainless_steel_printing: 'Rustfritt stål - Utskrift',
            steel_engraving_spray_engraving: 'Metall - Gravering',
            stone: 'Stein',
            ti_engraving: 'Titan - Gravering',
            titanium_dark: 'Titan (mørk)',
            titanium_light: 'Titan (lys)',
            white_abs: 'Hvit ABS',
            wood_3mm_cutting: 'Tre - 1/8″ Skjæring',
            wood_5mm_cutting: 'Tre - 3/16″ Skjæring',
            wood_7mm_cutting: 'Tre - 1/4″ Skjæring',
            wood_8mm_cutting: 'Tre - 1/4″ Skjæring',
            wood_10mm_cutting: 'Tre - 3/8″ Skjæring',
            wood_engraving: 'Tre - Gravering',
            wood_printing: 'Tre - Utskrift',
          },
          mm: {
            abs_engraving: 'ABS - Gravering',
            acrylic_3mm_cutting: 'Akryl - 3 mm Skjæring',
            acrylic_5mm_cutting: 'Akryl - 5 mm Skjæring',
            acrylic_8mm_cutting: 'Akryl - 8 mm Skjæring',
            acrylic_10mm_cutting: 'Akryl - 10 mm Skjæring',
            acrylic_engraving: 'Akryl - Gravering',
            acrylic_printing: 'Akryl - Utskrift',
            aluminum_engraving: 'Aluminium - Gravering',
            aluminum_light: 'Aluminium (lys)',
            bamboo_printing: 'Bambus - Utskrift',
            black_abs: 'Svart ABS',
            black_acrylic_3mm_cutting: 'Svart akryl - 3 mm Skjæring',
            black_acrylic_5mm_cutting: 'Svart akryl - 5 mm Skjæring',
            black_acrylic_engraving: 'Svart akryl - Gravering',
            brass_dark: 'Messing (mørk)',
            brass_engraving: 'Messing - Gravering',
            brass_light: 'Messing (lys)',
            canvas_printing: 'Lerret - Utskrift',
            cardstock_printing: 'Kartong - Utskrift',
            copper: 'Kobber',
            cork_printing: 'Kork - Utskrift',
            denim_1mm_cutting: 'Denim - 1 mm Skjæring',
            fabric_3mm_cutting: 'Stoff - 3 mm Skjæring',
            fabric_5mm_cutting: 'Stoff - 5 mm Skjæring',
            fabric_engraving: 'Stoff - Gravering',
            fabric_printing: 'Stoff - Utskrift',
            flat_stone_printing: 'Flat stein - Utskrift',
            glass_bw_engraving: 'Glass - Gravering',
            glass_printing: 'Glass - Utskrift',
            gloss_leather_printing: 'Blankt lær - Utskrift',
            gold_engraving: 'Gull - Gravering',
            iron_engraving: 'Jern - Gravering',
            leather_3mm_cutting: 'Lær - 3 mm Skjæring',
            leather_5mm_cutting: 'Lær - 5 mm Skjæring',
            leather_engraving: 'Lær - gravering',
            mdf_3mm_cutting: 'New Zealand MDF - 3mm Skjæring',
            mdf_5mm_cutting: 'New Zealand MDF - 5mm Skjæring',
            mdf_engraving: 'New Zealand MDF - Gravering',
            metal_bw_engraving: 'Metall - Gravering',
            opaque_acrylic: 'Ugjennomsiktig akryl',
            pc_printing: 'PC - Utskrift',
            rubber_bw_engraving: 'Gummi - Gravering',
            silver_engraving: 'Sølv - Gravering',
            stainless_steel_bw_engraving_diode: 'Metall - Gravering (diode laser)',
            stainless_steel_dark: 'Rustfritt stål (mørk)',
            stainless_steel_engraving: 'Rustfritt stål - Gravering',
            stainless_steel_light: 'Rustfritt stål (lys)',
            stainless_steel_printing: 'Rustfritt stål - Utskrift',
            steel_engraving_spray_engraving: 'Metall - Gravering',
            stone: 'Stein',
            ti_engraving: 'Titan - Gravering',
            titanium_dark: 'Titan (mørk)',
            titanium_light: 'Titan (lys)',
            white_abs: 'Hvit ABS',
            wood_3mm_cutting: 'Tre - 3 mm Skjæring',
            wood_5mm_cutting: 'Tre - 5 mm Skjæring',
            wood_7mm_cutting: 'Tre - 7 mm Skjæring',
            wood_8mm_cutting: 'Tre - 8 mm Skjæring',
            wood_10mm_cutting: 'Tre - 10 mm Skjæring',
            wood_engraving: 'Tre - Gravering',
            wood_printing: 'Tre - Utskrift',
          },
          parameters: 'Forhåndsinnstillinger',
          save: 'Legg til gjeldende parametere',
        },
        engrave: 'Graver',
        existing_name: 'Dette navnet er allerede i bruk.',
        fill_angle: 'Fyll Vinkel',
        fill_interval: 'Fyll Interval',
        fill_setting: 'Fyllingsinnstillinger',
        filled_path_only: 'Kun for fyllingsbaner',
        focus_adjustment: 'Fokuseringsjustering',
        frequency: 'Frekvens',
        gradient_only: 'Kun for gradientbilder',
        halftone: 'Halvtoner',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Objekthøyde',
        ink_saturation: 'Metning',
        ink_type: {
          normal: 'Blekk',
          text: 'Blekktype',
          UV: 'UV-blekk',
        },
        laser_speed: {
          fast: 'Rask',
          max: 300,
          min: 3,
          slow: 'Sakte',
          step: 0.1,
          text: 'Hastighet',
          unit: 'mm/s',
        },
        low_power_warning: 'Lavere laserkraft (under 10%) kan hende ikke sender ut laserlys.',
        low_speed_warning: 'Lav hastighet kan føre til brenning av materialet.',
        lower_focus: 'Senk Fokus',
        lower_focus_desc: 'Senke fokushøyden med en spesifikk avstand etter fokusering for å forbedre kutteytelsen.',
        module: 'Modul',
        more: 'Administrer',
        multi_layer: 'Flere lag',
        para_in_use: 'Denne parameteren er i bruk.',
        parameters: 'Parametere',
        power: {
          high: 'Høy',
          low: 'Lav',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Kraft',
        },
        preset_management: {
          add_new: 'Legg til Ny',
          delete: 'Slett',
          export: 'Eksporter',
          export_preset_title: 'Eksporter forhåndsinnstillinger',
          import: 'Importer',
          laser: 'Laser',
          lower_focus_by: 'Senk Fokus med',
          new_preset_name: 'Nytt forhåndsinnstillingsnavn',
          preset: 'Forhåndsinnstilling',
          print: 'Skriv Ut',
          reset: 'Tilbakestill',
          save_and_exit: 'Lagre og lukk',
          show_all: 'Vis Alle',
          sure_to_import_presets: 'Dette vil laste inn arrangementet av forhåndsinnstillinger og overskrive tilpassede parametere, er du sikker på at du vil fortsette?',
          sure_to_reset: 'Dette vil slette dine tilpassede parametere og tilbakestille alle forhåndsinnstillinger, er du sikker på at du vil fortsette?',
          title: 'Administrer Parametere',
          wobble_diameter: 'Wobble-diameter',
          wobble_step: 'Størrelse på wobble-trinn',
        },
        preset_setting: 'Parameterinnstillinger (%s)',
        presets: 'Forhåndsinnstilling',
        print_multipass: 'Flerpass',
        promark_speed_desc: 'Hastighetsparametere gjelder ikke for gradientbilder.',
        pulse_width: 'Pulsbredde',
        pwm_advanced_desc: 'Sett en minimumseffekt for dybdemodus.',
        pwm_advanced_hint: 'Denne innstillingen gjelder spesielt for gradientbilder når du bruker dybdemodus.',
        pwm_advanced_setting: 'Strøminnstillinger for Dybdemodus',
        repeat: 'Pass Antall',
        single_color: 'Enkelt Farge',
        single_color_desc: 'Gjelder kun for full fargelag og kan ikke brukes med utvidet enkelt fargelag.',
        slider: {
          fast: 'Rask',
          high: 'Høy',
          low: 'Lav',
          regular: 'Vanlig',
          slow: 'Sakte',
          very_fast: 'Raskest',
          very_high: 'Maksimum',
          very_low: 'Minimum',
          very_slow: 'Saktest',
        },
        speed: 'Hastighet',
        speed_constrain_warning: 'Kuttehastigheten for vektorbaner vil være begrenset til %(limit)s. Du kan fjerne denne grensen under Innstillinger.',
        speed_constrain_warning_auto_feeder: 'Skjærehastigheten for Auto Feeder-objekter vil bli begrenset til %(limit)s. Du kan fjerne denne grensen i Innstillinger for preferanser.',
        speed_constrain_warning_curve_engraving: 'Hastigheten for kurvegravering vil bli begrenset til %(limit)s. Du kan fjerne denne grensen i preferanseinnstillingene.',
        stepwise_focusing: 'Trinnvis Fokusering',
        stepwise_focusing_desc: 'Senke fokuseringsavstanden gradvis basert på objektets høyde under hvert pass antall.',
        strength: 'Styrke',
        times: 'ganger',
        various_preset: 'Diverse forhåndsinnstillinger',
        white_ink: 'Hvit blekk',
        white_ink_settings: 'Innstillinger for hvitt blekk',
        wobble: 'Vibrasjon',
        wobble_desc: 'Oppretter små sirkler langs banen, som er nyttig for skjæreoppgaver. (Kun for baneobjekter.)',
        wobble_diameter: 'Diameter',
        wobble_step: 'Trinnstørrelse',
        z_step: 'Z-trinn',
      },
      layer_panel: {
        current_layer: 'Gjeldende lag',
        layer1: 'Lag 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Kutting',
        layer_engraving: 'Gravering',
        layers: {
          del: 'Slett lag',
          dupe: 'Dupliser lag',
          fullColor: 'Fullfarge',
          layer: 'Lag',
          layers: 'Lag',
          lock: 'Lås lag',
          merge_all: 'Slå sammen alle',
          merge_down: 'Slå sammen nedover',
          merge_selected: 'Slå sammen valgte lag',
          move_down: 'Flytt lag ned',
          move_elems_to: 'Flytt elementer til:',
          move_selected: 'Flytt valgte elementer til et annet lag',
          move_up: 'Flytt lag opp',
          new: 'Nytt lag',
          rename: 'Gi lag nytt navn',
          splitFullColor: 'Utvid lag',
          switchToFullColor: 'Bytt til fullfargelag',
          switchToSingleColor: 'Bytt til enkelt fargelag',
          unlock: 'Lås opp',
        },
        move_elems_to: 'Flytt element til:',
        notification: {
          dupeLayerName: 'Det finnes allerede et lag med det navnet!',
          enterNewLayerName: 'Vennligst skriv inn det nye lagnavnet',
          enterUniqueLayerName: 'Vennligst skriv inn et unikt lagnavn',
          layerHasThatName: 'Laget har allerede det navnet',
          mergeLaserLayerToPrintingLayerMsg: 'Vær oppmerksom på at hvis du fullfører denne operasjonen, vil innstillingene for laserlaget bli fjernet og satt i henhold til gjeldende lag.',
          mergeLaserLayerToPrintingLayerTitle: 'Vil du slå sammen disse lagene til ett utskriftslag?',
          mergePrintingLayerToLaserLayerMsg: 'Vær oppmerksom på at hvis du fullfører denne operasjonen, vil fargeinnstillingene for utskriftslaget bli fjernet og satt i henhold til gjeldende lag.',
          mergePrintingLayerToLaserLayerTitle: 'Vil du slå sammen disse lagene til ett laserlag?',
          moveElemFromPrintingLayerMsg: 'Vennligst merk at hvis du fullfører denne operasjonen, vil fargeinnstillingene for det valgte elementet bli fjernet og satt i henhold til %s.',
          moveElemFromPrintingLayerTitle: 'Flytt valgt element til %s og konverter det til laserelement?',
          moveElemToPrintingLayerMsg: 'Vennligst merk at hvis du fullfører denne operasjonen, vil innstillingene for det valgte elementet bli fjernet og satt i henhold til %s.',
          moveElemToPrintingLayerTitle: 'Flytt valgt element til %s og konverter det til et utskriftselement?',
          newName: 'NYTT NAVN',
          QmoveElemsToLayer: "Flytte valgte elementer til lag '%s'?",
          splitColorMsg: 'Vær oppmerksom på at hvis du fortsetter med denne operasjonen, vil du ikke kunne gå tilbake til de opprinnelige fargelagene.',
          splitColorTitle: 'Vil du utvide det valgte laget til CMYK-lag?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Bakgrunnsfjerning',
          ai_bg_removal_reminder: 'Ved å trykke på knappen bruker du umiddelbart 0,2 kreditt. Vil du fortsette?',
          ai_bg_removal_short: 'BG-fjerning',
          array: 'Matrise',
          auto_fit: 'Automatisk Tilpasning',
          bevel: 'Fas',
          brightness: 'Lysstyrke',
          convert_to_path: 'Konverter til bane',
          create_textpath: 'Lag tekstbane',
          create_textpath_short: 'Tekstbane',
          crop: 'Beskjær',
          decompose_path: 'Dekomponer',
          detach_path: 'Dekomponer tekstbane',
          detach_path_short: 'Dekomponer',
          disassemble_use: 'Demonter',
          disassembling: 'Demonterer...',
          edit_path: 'Rediger bane',
          fetching_web_font: 'Henter nettfont...',
          grading: 'Justering',
          invert: 'Inverter',
          offset: 'Forskyvning',
          outline: 'Omriss',
          replace_with: 'Erstatt med...',
          replace_with_short: 'Erstatt',
          sharpen: 'Skarphet',
          simplify: 'Forenklet',
          smart_nest: 'Smart Nest',
          trace: 'Spor',
          ungrouping: 'Grupperer opp...',
          uploading_font_to_machine: 'Laster opp font til maskinen...',
          wait_for_parsing_font: 'Tolker font... Vennligst vent et øyeblikk',
          weld_text: 'Slå sammen tekst',
        },
        align: 'Juster',
        boolean: 'Boolsk',
        bottom_align: 'Bunnjustér',
        center_align: 'Midtstill',
        difference: 'Forskjell',
        distribute: 'Distribuer',
        flip: 'Snur',
        group: 'Gruppere',
        hdist: 'Horisontal distribusjon',
        hflip: 'Horisontal snur',
        intersect: 'Skjæringspunkt',
        left_align: 'Venstrejuster',
        lock_aspect: 'Lås størrelsesforhold',
        middle_align: 'Midtstill vertikalt',
        option_panel: {
          color: 'Farge',
          fill: 'Fyll',
          font_family: 'Skrifttype',
          font_size: 'Størrelse',
          font_style: 'Stil',
          letter_spacing: 'Bokstavavstand',
          line_spacing: 'Linjeavstand',
          path_infill: 'Baneutfylling',
          pwm_engraving: 'Dybde-modus',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Avrundet hjørne',
          shading: 'Gradient',
          sides: 'Sider',
          start_offset: 'Tekstforskyvning',
          stroke: 'Strek',
          stroke_color: 'Strekfarge',
          stroke_width: 'Stregbredde',
          text_infill: 'Tekstutfylling',
          threshold: 'Terskel lysstyrke',
          threshold_short: 'Terskel',
          vertical_align: 'Justering',
          vertical_text: 'Vertikal tekst',
        },
        path_edit_panel: {
          connect: 'Koble til',
          delete: 'Slett',
          disconnect: 'Koble fra',
          node_type: 'NODETYPE',
          round: 'Rund',
          sharp: 'Skarp',
        },
        right_align: 'Høyrejuster',
        subtract: 'Subtraksjon',
        top_align: 'Toppjuster',
        ungroup: 'Del opp',
        union: 'Union',
        unlock_aspect: 'Lås opp størrelsesforhold',
        vdist: 'Vertikal distribusjon',
        vflip: 'Vertikal snur',
        zoom: 'Zoom',
      },
      tabs: {
        layers: 'Lag',
        objects: 'Objekter',
        path_edit: 'Stibane redigering',
      },
      uv_print_block: {
        title: 'UV-utskrift',
      },
    },
    shapes_panel: {
      animals: 'Dyr',
      arrow: 'Pil',
      basic: 'Grunnleggende',
      birds: 'Fugler',
      celebration: 'Feiring',
      circular: 'Sirkulær',
      CNY: 'Kinesisk nyttår',
      corner: 'Hjørne',
      decor: 'Dekor',
      easter: 'Påske',
      elements: 'Elementer',
      environment: 'Miljø',
      graphics: 'Grafikk',
      halloween: 'Halloween',
      holidays: 'Høytider',
      label: 'Etikett',
      land: 'Land',
      line: 'Linje',
      nature: 'Natur',
      photo: 'Foto',
      plants: 'Planter',
      ribbon: 'Bånd',
      sea: 'Hav',
      shape: 'Form',
      speech: 'Tale',
      text: 'Tekstboks',
      title: 'Elementer',
      valentines: 'Valentinsdag',
      weather: 'Vær',
      Xmas: 'Jul',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Kunne ikke hente bilde fra utklippstavlen',
      unnsupported_file_type: 'Filtypen støttes ikke direkte. Konverter filen til SVG eller bitmap',
    },
    tag: {
      g: 'Gruppe',
      image: 'Bilde',
      text: 'Tekst',
      use: 'Importer SVG',
    },
    time_est_button: {
      calculate: 'Estimér tid',
      estimate_time: 'Estimert tid:',
    },
    tool_panels: {
      _nest: {
        end: 'Lukk',
        no_element: 'Det er ingen elementer å plassere',
        rotations: 'Mulig rotasjon',
        spacing: 'Mellomrom',
        start_nest: 'Plassere',
        stop_nest: 'Stopp',
      },
      _offset: {
        corner_type: 'Hjørne',
        direction: 'Forskyvningsretning',
        dist: 'Forskyvningsavstand',
        fail_message: 'Kunne ikke forskyve objekter',
        inward: 'Innover',
        not_support_message: 'Valgte elementer som inneholder ikke-støttede SVG-tagger:\nBilde, Gruppe, Tekst og Importert objekt',
        outward: 'Utad',
        round: 'Rund',
        sharp: 'Skarp',
      },
      array_dimension: 'Rutenettdimensjon',
      array_interval: 'Rutenettintervall',
      cancel: 'Avbryt',
      columns: 'Kolonner',
      confirm: 'Bekreft',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Lag rutenett',
      nest: 'Plassering',
      offset: 'Forskyvning',
      rows: 'Rader',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Tilpass til vindu',
    },
  },
  boxgen: {
    add_option: 'Legg til valg',
    basic_box: 'Grunnleggende boks',
    beam_radius: 'Kompensasjon for kuttebredde',
    beam_radius_warning: 'Fjern kompensasjon for kuttebredde når kantene eller sammenføyningene på boksen er korte for å sikre riktig sammensetting',
    cancel: 'Avbryt',
    coming_soon: 'Kommer snart',
    continue_import: 'Fortsett å importere',
    control_tooltip: 'Venstre mus for å rotere\nRull for å zoome\nHøyre mus for å panorere',
    control_tooltip_touch: 'Dra for å rotere\nKlyp for å zoome\nTo fingre for å panorere',
    cover: 'Deksel',
    customize: 'Tilpass',
    depth: 'Dybde',
    edge: 'Kant',
    finger: 'Finger',
    finger_warning: 'Den indre sidelengden på boksen må være minst 6 mm (0,24 tommer) for å være kompatibel med fingerføyning.',
    height: 'Høyde',
    import: 'Importer',
    inner: 'Indre',
    joints: 'Sammenføyning',
    max_dimension_tooltip: 'Maks bredde/høyde/dybde er %s.',
    merge: 'Slå sammen',
    outer: 'Ytre',
    reset: 'Tilbakestill',
    tCount: 'T-antall',
    tCount_tooltip: 'Antall T-spor gjelder for den korte siden; mengden på den lange siden beregnes basert på lengden.',
    tDiameter: 'T-diameter',
    text_label: 'Tekstetikett',
    thickness: 'Tykkelse',
    title: 'BOXGEN',
    tLength: 'T-lengde',
    tSlot: 'T-spor',
    tSlot_warning: 'Bokssidelengden må være minst 30 mm (1,18 tommer) for å være kompatibel med T-sporføyning.',
    volume: 'Volum',
    width: 'Bredde',
    workarea: 'Arbeidsområde',
    zoom: 'Zoom',
  },
  buttons: {
    back: 'TILBAKE',
    back_to_beam_studio: 'Tilbake til Beam Studio',
    done: 'FERDIG',
    next: 'NESTE',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Trykk på "AF"-ikonet på hovedsiden til maskinen i 3 sekunder og la sonden berøre fokuseringsblokken.',
    ador_autofocus_material: 'Trykk på "AF"-ikonet på hovedsiden til maskinen i 3 sekunder og la sonden berøre materialet forsiktig.',
    align_ilb: 'Posisjon: Juster med det graverte punktet nederst til venstre på innsiden.',
    align_ilt: 'Posisjon: Juster med det graverte punktet øverst til venstre på innsiden.',
    align_irb: 'Posisjon: Juster med det graverte punktet nederst til høyre på innsiden.',
    align_irt: 'Posisjon: Juster med det graverte punktet øverst til høyre på innsiden.',
    align_olb: 'Posisjon: Juster med det graverte punktet nederst til venstre på utsiden.',
    align_olt: 'Posisjon: Juster med det graverte punktet øverst til venstre på utsiden.',
    align_orb: 'Posisjon: Juster med det graverte punktet nederst til høyre på utsiden.',
    align_ort: 'Posisjon: Juster med det graverte punktet øverst til høyre på utsiden.',
    align_red_cross_cut: 'Vennligst juster midten av det røde korset med kors-snittet.',
    align_red_cross_print: 'Vennligst juster midten av det røde korset med det utskrevne korset.',
    analyze_result_fail: 'Kunne ikke analysere bildet som ble tatt.<br/>Sjekk:<br/>1. Bildet dekker hele det hvite papiret.<br/>2. Plattformen er fokusert ordentlig.',
    ask_for_readjust: 'Vil du hoppe over graveringstrinnet og deretter ta bilde og kalibrere direkte?',
    back: 'Tilbake',
    calculating_camera_matrix: 'Beregner kameramatrise...',
    calculating_regression_parameters: 'Beregner regresjonsparametere...',
    calibrate_camera_before_calibrate_modules: 'Vennligst utfør kamera-kalibrering før du kalibrerer moduler.',
    calibrate_chessboard_success_msg: 'Sjakkbrett-bildet er tatt med suksess.<br/>Poengsummen for dette bildet er %s (%.2f).',
    calibrate_done: 'Kalibrering fullført. Bedre kamera-nøyaktighet oppnås ved presis fokusering.',
    calibrate_done_diode: 'Kalibrering fullført. Forskyvningen til diodlasermodulen er lagret.',
    calibrating: 'Kalibrerer...',
    calibrating_with_device_pictures: 'Kalibrerer med enhetsbilder...',
    camera_calibration: 'Kamerajustering',
    camera_parameter_saved_successfully: 'Kameraparametrene er lagret.',
    cancel: 'Avbryt',
    check_checkpoint_data: 'Kontrollpunktdata',
    check_device_pictures: 'Sjekk enhetsbilder',
    checking_checkpoint: 'Sjekker kontrollpunktdata...',
    checking_pictures: 'Sjekker enhetsbilder...',
    diode_calibration: 'Diode lasermoduljustering',
    do_engraving: 'Utfør Gravering',
    download_chessboard_file: 'Last ned sjakkbrett-fil',
    downloading_checkpoint: 'Laster ned kontrollpunktdata...',
    downloading_pictures: 'Laster ned bilder...',
    drawing_calibration_image: 'Tegner kalibreringsbilde...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Heve og Kutte',
    elevate_and_cut_step_1: 'Plasser en A4-størrelse lysfarget tre midt i arbeidsområdet, hevet til 20mm.',
    elevate_and_cut_step_1_prism_lift: 'Bruk Ador Prism Lift med maksimal lengde på 14mm sammen med et tre på minst 6mm tykkelse for å løfte treet til en høyde på 20mm.',
    failed_to_calibrate_camera: '#848 Kunne ikke kalibrere kamera, vennligst kontakt FLUX-støtte.',
    failed_to_calibrate_chessboard: 'Mislyktes i å kalibrere med sjakkbrettbildet.',
    failed_to_calibrate_with_pictures: '#848 Klarte ikke å kalibrere med enhetsbilder.',
    failed_to_download_pictures: '#848 Kunne ikke laste ned bilder, vennligst kontakt FLUX-støtte.',
    failed_to_move_laser_head: 'Kunne ikke flytte laserhodet.',
    failed_to_parse_checkpoint: 'Kunne ikke analysere kontrollpunktdata.',
    failed_to_save_calibration_results: '#849 Kunne ikke lagre kalibreringsresultater, vennligst prøv igjen. Hvis dette fortsetter, vennligst kontakt FLUX-støtte.',
    failed_to_save_camera_parameter: 'Feilet i å lagre kameraparametre.',
    failed_to_solve_pnp: 'Klarte ikke å løse kameraposisjonen.',
    finish: 'Ferdig',
    found_checkpoint: 'Kontrollpunktdata funnet på enheten din. Vil du gjenopprette fra kontrollpunktet?',
    getting_plane_height: 'Få Høyde på Plan...',
    hint_adjust_parameters: 'Vennligst bruk disse parameterne for å justere den røde firkanten',
    hint_red_square: 'Vennligst juster den røde firkanten til den stemmer overens med den skårne firkanten.',
    module_calibration_2w_ir: 'Infrarød moduljustering',
    module_calibration_printer: 'Skrivermoduljustering',
    moving_laser_head: 'Flytter laserhodet...',
    next: 'Neste',
    no_picutre_found: '#846 Enheten din har ingen tilgjengelige rå bilder for kalibrering. Vennligst kontakt FLUX-støtte.',
    perform_autofocus_bb2: 'Vennligst gå til maskinens kontrollpanel og trykk AF for å utføre autofokus.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Når du kalibrerer diode lasermodulen, trengs kamera.\nVennligst sørg for at kameraet på denne maskinen er kalibrert.\nOg vennligst juster plattformen til brennpunktet (høyden på nedvendt akryl)',
      beamo: 'Når du kalibrerer diode lasermodulen, trengs kamera.\nVennligst sørg for at kameraet på denne maskinen er kalibrert.\nOg vennligst juster laserhodet for å fokusere på graveringsobjektet (høyden på nedvendt akryl)',
    },
    please_goto_beambox_first: 'Vennligst bytt til graveringsmodus (Beambox) for å bruke denne funksjonen.',
    please_place_dark_colored_paper: 'Vennligst plasser et A4- eller brevstørrelse mørkt papir i midten av arbeidsområdet.',
    please_place_paper: 'Vennligst plasser et A4- eller brevstørrelse hvitt papir i venstre øverste hjørne av arbeidsområdet.',
    please_place_paper_center: 'Vennligst plasser et A4- eller brevstørrelse hvitt papir i midten av arbeidsområdet.',
    please_refocus: {
      beambox: 'Vennligst juster plattformen til fokuspunktet (høyden på nedsnudd akryl)',
      beamo: 'Vennligst juster laserhodet for å fokusere på graveringsobjektet (høyden på nedsnudd akryl)',
      beamo_af: 'Vennligst dobbelttrykk sideknappen på autofokustillegget og la sonden mykt berøre materialet.',
      hexa: 'Dobbeltklikk på høydejusteringsknappen for å heve honningkakebordet opp og la sonden berøre graveringsmaterialet.',
    },
    preparing_to_take_picture: 'Forbereder for å ta bilde...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Plasser Sjakkbrett',
    put_chessboard_1: 'Vennligst gå til maskinens kontrollpanel og trykk AF for å utføre autofokus.',
    put_chessboard_2: 'Vennligst flytt sjakkbrettpapiret eller laserhodet til hele sjakkbrettet er bekreftet innenfor den røde rammen i det direkte forhåndsvisningsvinduet.',
    put_chessboard_3: 'Høyreklikk for å laste ned sjakkbrettbildet på den nåværende plasseringen og sjekk dets klarhet.',
    put_chessboard_bb2_desc_1: 'Last ned den følgende sjakkbrettfilen og skriv den ut på A4-papir (det utskrevne sjakkbrettet skal ha ruter på 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Fest det utskrevne sjakkbrettet på en ikke-deformerbar plate, for eksempel akryl eller glass, og sørg for at sjakkbrettet er glatt og uten rynker eller løft.',
    put_chessboard_bb2_desc_3: 'Plasser platen med sjakkbrettet flatt i midten av arbeidsområdet.',
    put_chessboard_promark_1: 'Juster brennvidden til riktig fokus for feltlinsen.',
    put_chessboard_promark_2: 'Sørg for at sjakkbrettpapiret er tydelig synlig uten gjenskinn. Klikk på "Neste" for å ta et bilde.',
    put_chessboard_promark_desc_1: 'Vennligst bruk sjakkbrettpapiret som følger med i tilbehørsboksen, eller skriv ut det følgende sjakkbrettmønsteret på A4-papir for kamerakalibrering.',
    put_chessboard_promark_desc_2: 'Plasser sjakkbrettet flatt og sentrert på arbeidsplattformen.',
    put_paper: 'Legg papir',
    put_paper_promark_1: 'Plasser den svarte pappen fra tilbehørsboksen på arbeidsplattformen.',
    put_paper_promark_2: 'Juster fokus riktig, og klikk deretter på "Start Gravering" for å fortsette med graveringen.',
    put_paper_skip: 'Hvis kalibreringstegningen ikke automatisk går videre til å ta bilde, trykk på "Hopp over" for å ta bilde.',
    put_paper_step1: 'Vennligst plasser et A4- eller brevstørrelse hvitt papir i midten av arbeidsområdet.',
    put_paper_step2: 'Fest de fire hjørnene på papiret for å sikre at det ligger flatt.',
    put_paper_step3: 'Klikk på "Start Gravering".',
    res_average: 'Gjennomsnittlig',
    res_excellent: 'Utmerket',
    res_poor: 'Dårlig',
    retake: 'Ta bilde på nytt',
    rotation_angle: 'Rotasjon',
    show_last_config: 'Vis siste resultat',
    skip: 'Hopp Over',
    solve_pnp_step1: 'Vennligst juster graveringpunktene i henhold til nummeret og den forventede posisjonen til hvert røde markørpunkt.',
    solve_pnp_step2: 'Du kan trykke på "Ta bilde på nytt" for å justere på nytt eller manuelt justere markørposisjonene.',
    solve_pnp_step3: 'Hvis det fangede bildet ikke stemmer med den faktiske scenen, kan du zoome inn eller dra skjermen for å justere kalibreringspunktet på det svarte kalibreringskortet til sentrum, og deretter prøve å "Ta bilde på nytt".',
    solve_pnp_title: 'Juster markørpunkter',
    start_engrave: 'Start Gravering',
    start_printing: 'Start Utskrift',
    taking_picture: 'Tar bilde...',
    unable_to_load_camera_parameters: "#851 Det er ingen tilgjengelige kamerakalibreringsparametere på enheten din. Gå til 'Kalibrering' > 'Kalibrer kamera (Avansert)' for å fullføre kalibreringen og få parametrene.",
    update_firmware_msg1: 'Fastvaren støtter ikke denne funksjonen. Oppdater fastvare til v',
    update_firmware_msg2: 'eller nyere for å fortsette。 (Meny > Maskin > [Din maskin] > Oppdater fastvare)',
    uploading_images: 'Laster opp bilder...',
    use_last_config: 'Bruk siste kalibreringsverdi',
    use_old_camera_parameter: 'Vil du bruke gjeldende kamerobjektivparametere?',
    with_af: 'Med autofokus',
    with_prism_lift: 'Med Ador Prism Lift',
    without_af: 'Uten autofokus',
    without_prism_lift: 'Uten Ador Prism Lift',
    x_ratio: 'X-forhold',
    y_ratio: 'Y-forhold',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Kameradata er lastet ned.',
    downloading_data: 'Laster ned data...',
    estimated_time_left: 'Estimert gjenværende tid:',
    folder_not_exists: 'Valgt mappe eksisterer ikke.',
    incorrect_folder: 'Kunne ikke laste opp kalibreringsdataene. Sjekk om mappen du har valgt er riktig.',
    no_picture_found: 'Ingen bilde funnet i maskinen.',
    title: 'Sikkerhetskopiering av kameradata',
    upload_success: 'Kameradata er lastet opp.',
    uploading_data: 'Laster opp data...',
  },
  caption: {
    connectionTimeout: 'Tilkoblingstidsavbrudd',
  },
  change_logs: {
    added: 'Lagt til:',
    change_log: 'Endringslogger:',
    changed: 'Endret:',
    fixed: 'Fikset:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Se eldre versjoner',
  },
  code_generator: {
    barcode: 'Strekkode',
    qr_code: 'QR-kode',
  },
  curve_engraving: {
    '921': 'Automatisk fokusering mislyktes.',
    '922': 'Måling med rødt lys mislyktes.',
    amount: 'Mengde',
    apply_arkwork: 'Bruk kunstverk på kurve',
    apply_camera: 'Bruk kamerabilde på kurve',
    click_to_select_point: 'Klikk for å velge eller velge bort punkter for å måle på nytt.',
    coloumns: 'Kolonner',
    column_gap: 'Kolonner Avstand',
    err_object_over_range: 'Objektet overstiger måleområdet.',
    failed_to_take_reference: 'Kunne ikke ta referanse',
    gap: 'Avstand',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Mål autofokusområde',
    preview_3d_curve: 'Forhåndsvisning av kurvegravering',
    remeasure: 'Mål på nytt',
    remeasuring_points: 'Måler punkter på nytt...',
    reselect_area: 'Velg område på nytt',
    row_gap: 'Rader Avstand',
    rows: 'Rader',
    set_object_height: 'Sett objektets høyde',
    set_object_height_desc: 'Mål den maksimale tykkelsen på objektet.',
    start_autofocus: 'Start autofokus',
    starting_measurement: 'Starter måling...',
    sure_to_delete: 'Vil du slette fokuseringsdataene for kurvegravering?',
    take_reference: 'Ta referanse',
    take_reference_desc: 'Vennligst flytt laserhodet til objektets høyeste punkt, senk fokuseringsproben, og klikk på "Bekreft" for å fokusere.',
  },
  device: {
    abort: 'Avbryt',
    aborted: 'Avbrutt',
    aborting: 'Avbryter',
    busy: 'Opptatt',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Fullført',
    completing: 'Fullfører',
    deviceList: 'Enhetsliste',
    disable: 'Deaktiver',
    disconnectedError: {
      caption: 'Maskinen frakoblet',
      message: 'Bekreft om nettverkstilgangen til %s er tilgjengelig',
    },
    enable: 'Aktiver',
    firmware_version: 'Firmwareversjon',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Modellnavn',
    occupied: 'Opptatt',
    pause: 'Pause',
    paused: 'Pauset',
    pausedFromError: 'Pauset grunnet feil',
    pausing: 'Pauser',
    please_wait: 'Vennligst vent...',
    preparing: 'Forbereder',
    processing: 'Behandler',
    quit: 'Avslutt',
    ready: 'Klar',
    reset: 'Tilbakestill',
    resuming: 'Gjenopptar',
    retry: 'Prøv igjen',
    running: 'Kjører',
    scanning: 'Skanner',
    select: 'Velg',
    select_printer: 'Velg skriver',
    serial_number: 'Serienummer',
    start: 'Start',
    starting: 'Starter',
    status: 'Status',
    submodule_type: 'Module',
    toolhead_change: 'Bytt verktøyhode',
    unknown: 'Ukjent',
    uploading: 'Laster opp',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Vi finner ikke maskinen din på nettverket.\nFølg <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">veiledningen</a> for å løse tilkoblingsproblemet.',
    no_device_web: "#801 Sjekk statusen til maskinen din, eller klikk på 'Maskinoppsett' nedenfor for å sette opp maskinen.",
    select_usb_device: 'Velg USB-enhet',
  },
  editor: {
    exposure: 'Forhåndsvisningseksponering',
    opacity: 'Forhåndsvisning gjennomsiktighet',
    prespray_area: 'Primeområde',
  },
  error_pages: {
    screen_size: 'Vær oppmerksom på at Beam Studio kanskje ikke fungerer optimalt på enheten din. For best mulig opplevelse bør skjermbredden være minst 1024 piksler.',
  },
  flux_id_login: {
    connection_fail: '#847 Kunne ikke koble til FLUX medlemstjeneste.',
    email: 'E-post',
    flux_plus: {
      access_monotype_feature: 'Du har ikke Monotype Fonts-tillegget.',
      access_monotype_feature_note: 'Du må ha FLUX+ Pro medlemskap eller Monotype Fonts-tillegg for å aksessere denne funksjonen.',
      access_plus_feature_1: 'Du aksesserer en',
      access_plus_feature_2: 'funksjon.',
      access_plus_feature_note: 'Du må ha FLUX+ medlemskap for å aksessere denne funksjonen.',
      ai_credit_tooltip: 'For AI bakgrunnsfjerning',
      explore_plans: 'Utforsk FLUX+ planene',
      features: {
        ai_bg_removal: 'AI bakgrunnsfjerning',
        boxgen: '3D boks generator',
        dmkt: '1000+ designfiler',
        monotype: '250+ premium skrifttyper',
        my_cloud: 'Ubegrenset skylagring',
      },
      flux_credit_tooltip: 'For Design Market filer og AI bakgrunnsfjerning',
      get_addon: 'Få tillegget',
      goto_member_center: 'Gå til medlemssenteret',
      learn_more: 'Lær mer',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Abonner nå',
      thank_you: 'Takk for å være et verdsatt medlem!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Glemt passord?',
    incorrect: 'E-postadresse eller passord er ikke riktig.',
    login: 'Logg inn',
    login_success: 'Logget inn.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Ny til FLUX? Opprett en konto.',
    not_verified: 'E-postadressen er ikke verifisert ennå.',
    offline: 'Jobb offline',
    password: 'Passord',
    register: 'Opprett FLUX-kontoen din',
    remember_me: 'Husk meg',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Logg inn for å låse opp formdatabasen.',
    work_offline: 'Jobb offline',
  },
  framing: {
    area_check: 'Områdekontroll',
    areacheck_desc: 'Sikrer at arbeidsområdet er trygt ved å vise objektets avgrensningsboks og laserhodets akselerasjonssone.',
    calculating_task: 'Beregner oppgave...',
    framing: 'Innramming',
    framing_desc: 'Forhåndsviser objektets avgrensningsboks.',
    hull: 'Skrog',
    hull_desc: 'Viser en form som følger designet tett, som et gummibånd rundt objektet.',
    low_laser: 'Lav laser',
    low_laser_desc: 'Sett en lav effektverdi for laser for innrammingsoppgaven.',
    rotate_axis: 'Roter akse',
    rotateaxis_desc: 'Forhåndsvisning av rotasjonsakslens sentrum, akselen selv beveger seg ikke.',
    rotation_frame_warning: 'Rammeforhåndsvisningen vil rotere én gang. Unngå å stoppe midtveis for å forhindre feiljustering av markeringsposisjonen.',
    rotation_framing_desc: 'Forhåndsvisning av det fullstendige rotasjonsområdet, akselen roterer i henhold til forhåndsvisningen.',
    start_task: 'Start Oppgave',
    start_task_description: 'Etter å ha fullført rammeforhåndsvisningen, klikk på "Start Oppgave" for å sende inn oppgaven.',
  },
  general: {
    choose_folder: 'Velg mappe',
    processing: 'Behandler...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] En statuskonflikt oppsto, prøv handlingen på nytt.',
    SUBSYSTEM_ERROR: '[SE] Feil under utføring av oppgave i maskinvare. Start maskinen på nytt.',
    UNKNOWN_COMMAND: '[UC] Oppdater enhetsfastvare',
    UNKNOWN_ERROR: '[UE] Ukjent feil oppstod. Start Beam Studio og maskinen på nytt.',
  },
  global: {
    apply: 'Bruk',
    back: 'Tilbake',
    cancel: 'Avbryt',
    editing: {
      redo: 'Gjenta',
      reset: 'Tilbakestill',
      undo: 'Angre',
      zoom_in: 'Zoom Inn',
      zoom_out: 'Zoom Ut',
    },
    mode_conflict: 'Dette alternativet er ikke tilgjengelig i gjeldende modus.',
    ok: 'OK',
    save: 'Lagre',
    stop: 'Stopp',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Penselstørrelse',
      description: 'Klikk eller dra for å manuelt slette uønskede områder.',
      title: 'Viskelær',
    },
    magic_wand: {
      description: 'Velg og fjern tilstøtende områder basert på fargelikhet.',
      title: 'Tryllestav',
      tolerance: 'Toleranse',
    },
    title: 'Rediger Bilde',
  },
  initialize: {
    back: 'Tilbake',
    cancel: 'Avbryt',
    confirm: 'Bekreft',
    connect: 'Koble til',
    connect_ethernet: {
      title: 'Direkte tilkobling',
      tutorial1: '1. Koble maskinen til datamaskinen din med ethernetkabel.',
      tutorial2_1: '2. Følg ',
      tutorial2_2: ' for å gjøre datamaskinen din til en ruter.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'denne guiden',
      tutorial3: '3. Klikk Neste.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Kan ikke koble til serveren. Start Beam Studio på nytt og prøv igjen.',
      },
      check_camera: 'Kontrollerer kameratilgjengelighet',
      check_connection: 'Sjekker maskintilkobling',
      check_firmware: 'Kontrollerer fastvareversjon',
      check_ip: 'Sjekker IP-tilgjengelighet',
      check_swiftray_connection: 'Sjekker serverforbindelse',
      check_swiftray_connection_unreachable: 'server utilgjengelig',
      check_usb: 'Sjekk USB-tilkobling',
      enter_ip: 'Skriv inn maskin-IP-en din',
      finish_setting: 'Start å lage!',
      invalid_format: 'Ugyldig format',
      invalid_ip: 'Ugyldig IP: ',
      promark_hint: 'Hvis du gjentatte ganger ikke kan koble til via USB, se <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">Hjelpesenter-artikkelen</a>.',
      retry: 'Prøv igjen',
      starts_with_169254: 'Starter med 169.254',
      succeeded_message: 'Tilkoblet 🎉',
      unreachable: 'IP utilgjengelig',
    },
    connect_usb: {
      connect_camera: 'Koble maskinens kamera til datamaskinen din med en USB-kabel.',
      title: 'USB-tilkobling',
      title_sub: ' (HEXA Only)',
      turn_off_machine: 'Slå av maskinen.',
      turn_on_machine: 'Slå på maskinen.',
      tutorial1: '1. Koble maskinen til datamaskinen din med USB-kabel.',
      tutorial2: '2. Klikk Neste.',
      wait_for_turning_on: 'Klikk "Neste" etter at oppstartsprosessen er fullført og du har kommet til hovedskjermen.',
    },
    connect_wifi: {
      title: 'Kobler til Wi-Fi',
      tutorial1: '1. Gå til berøringsskjerm > Klikk "Nettverk" > "Koble til WiFi".',
      tutorial1_ador: '1. Gå til berøringspanelet > klikk på "MASKIN" > klikk på "Nettverk" > "Koble til WiFi".',
      tutorial2: '2. Velg og koble til foretrukket Wi-Fi.',
      what_if_1: 'Hva hvis jeg ikke ser mitt Wi-Fi?',
      what_if_1_content: '1. Wi-Fi-krypteringen bør være WPA2 eller ingen passord.\n2. Krypteringen kan angis i Wi-Fi-ruteradministrasjon. Hvis ruteren ikke støtter WPA2 og du trenger hjelp med å velge riktig ruter, kontakt FLUX-støtte.',
      what_if_2: 'Hva hvis jeg ikke ser noe Wi-Fi?',
      what_if_2_content: '1. Sjekk at Wi-Fi-dongelen er ordentlig tilkoblet.\n2. Hvis det ikke er noen MAC-adresse for trådløst nettverk på berøringsskjermen, kontakt FLUX-støtte.\n3. Wi-Fi-kanalen bør være 2.4Ghz (5Ghz støttes ikke).',
    },
    connect_wired: {
      title: 'Koble til kablet nettverk',
      tutorial1: '1. Koble maskinen til ruteren.',
      tutorial2: '2. Trykk på "Nettverk" for å få kablet nettverks-IP.',
      tutorial2_ador: '2. Trykk på "MASKIN" > "Nettverk" for å få den kablede nettverks-IP-en.',
      what_if_1: 'Hva hvis IP-en er tom?',
      what_if_1_content: '1. Sjekk at Ethernet-kabelen er ordentlig tilkoblet.\n2. Hvis det ikke er noen MAC-adresse for kablet nettverk på berøringsskjermen, kontakt FLUX-støtte.',
      what_if_2: 'Hva hvis IP-en starter med 169?',
      what_if_2_content: '1. Hvis IP-adressen starter med 169.254, bør det være et DHCP-innstillingsproblem, kontakt internettleverandøren for hjelp.\n2. Hvis datamaskinen kobler direkte til internett med PPPoE, bytt til å bruke ruter med PPPoE, og aktiver DHCP-funksjon i ruteren.',
    },
    connecting: 'Kobler til...',
    connection_types: {
      ether2ether: 'Direkte tilkobling',
      usb: 'USB-tilkobling',
      wifi: 'Wi-Fi',
      wired: 'Kablet nettverk',
    },
    next: 'Neste',
    no_machine: 'Jeg har ikke en maskin nå.',
    promark: {
      configuration_confirmation: 'Dette sikrer at Promark-en din er riktig konfigurert for optimal effektivitet og nøyaktighet.',
      or_complete_later: `Eller hopp over dette trinnet og fullfør Promark-innstillingene senere på:
    Maskiner > "Promark-navn" > Promark-innstillinger`,
      qc_instructions: 'Fyll inn parametrene som finnes på baksiden av "QC Pass"-kortet',
      select_laser_source: 'Velg din Promark',
      select_workarea: 'Velg Arbeidsområde',
      settings: 'Promark-innstillinger',
    },
    retry: 'Prøv igjen',
    select_beambox: 'Velg din Beambox',
    select_connection_type: 'Hvordan vil du koble til?',
    select_language: 'Velg språk',
    select_machine_type: 'Velg din modell',
    setting_completed: {
      back: 'Tilbake',
      great: 'Velkommen til Beam Studio',
      ok: 'START Å LAGE',
      setup_later: 'Du kan alltid konfigurere maskinen fra toppmenyen > "Maskiner" > "Maskinkonfigurasjon"',
      start: 'Start',
    },
    skip: 'Hopp over',
    start: 'Start',
  },
  input_machine_password: {
    connect: 'KOBLE TIL',
    password: 'Passord',
    require_password: '"%s" krever passord',
  },
  insecure_websocket: {
    extension_detected: 'Beam Studio Connect-utvidelsen er oppdaget',
    extension_detected_description: "Vi har oppdaget at du har installert Beam Studio Connect-utvidelsen. Klik på 'Bekreft' for å omdirigere til HTTPS, eller klikk på 'Avbryt' for å fortsette å bruke HTTP.",
    extension_not_deteced: 'Kunne ikke oppdage Beam Studio Connect-utvidelsen',
    extension_not_deteced_description: "For å bruke HTTPS, klikk på 'Bekreft' for å installere Beam Studio Connect-utvidelsen. Etter å ha installert utvidelsen, vennligst oppdater siden for å aktivere den.<br/>Hvis ikke, klikk på lenken nedenfor for å se hvordan du bruker HTTP i Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Lenke</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: '2W Infrarød Laser',
    laser_10w_diode: '10W Diode Laser',
    laser_20w_diode: '20W Diode Laser',
    non_working_area: 'Ikke-Arbeidsområde',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Vær oppmerksom på at hvis du fullfører denne operasjonen, vil innstillingene for laserlaget fjernes og settes i henhold til gjeldende lag.',
      convertFromLaserModuleTitle: 'Vil du konvertere Lasermodulen til Utskriftsmodul?',
      convertFromPrintingModuleMsg: 'Vær oppmerksom på at hvis du fullfører denne operasjonen, vil fargeinnstillingene for utskriftslaget fjernes og settes i henhold til gjeldende lag.',
      convertFromPrintingModuleTitle: 'Vil du konvertere Utskriftsmodulen til Lasermodul?',
      importedDocumentContainsPrinting: 'Dokumentet inneholder utskriftslag, vil du endre arbeidsområde til Ador?',
      performIRCaliMsg: 'Klikk på "Bekreft" for å utføre kalibreringen, eller gå til kalibrering via toppmenyen.<br />(Maskin > [Maskinnavn] > Kalibrer infrarød modul)',
      performIRCaliTitle: 'Utfør infrarød modulkalibrering',
      performPrintingCaliMsg: 'Klikk på "Bekreft" for å utføre kalibreringen, eller gå til kalibrering via toppmenyen.<br />(Maskin > [Maskinnavn] > Kalibrer utskriftsmodul)',
      performPrintingCaliTitle: 'Utfør utskriftsmodulkalibrering',
      printingLayersConverted: 'Skriverlagene har blitt konvertert til laserskriverlag.',
    },
    printing: 'Utskrift',
    unknown: 'Unknown Module',
    uv_print: 'UV-utskrift',
  },
  machine_status: {
    '-17': 'Patron IO-modus',
    '-10': 'Vedlikeholdsmodus',
    '-2': 'Skanner',
    '-1': 'Vedlikeholder',
    '0': 'Ledig',
    '1': 'Initialiserer',
    '2': 'ST_TRANSFORM',
    '4': 'Starter',
    '6': 'Gjenopptar',
    '16': 'Arbeider',
    '18': 'Gjenopptar',
    '32': 'Pauset',
    '36': 'Pauset',
    '38': 'Setter på pause',
    '48': 'Pauset',
    '50': 'Setter på pause',
    '64': 'Fullført',
    '66': 'Fullfører',
    '68': 'Forbereder',
    '128': 'Avbrutt',
    '256': 'Alarm',
    '512': 'Fatal',
    UNKNOWN: 'Ukjent',
  },
  material_test_generator: {
    block_settings: 'Blokkinstillinger',
    columns: 'Kolonner',
    count: 'Antall',
    cut: 'Klipp',
    engrave: 'Gravere',
    export: 'Eksportere',
    max: 'Maks',
    min: 'Min',
    parameter: 'Parameter',
    preview: 'Forhåndsvisning',
    rows: 'Rader',
    size: 'Størrelse (HxB)',
    spacing: 'Avstand',
    table_settings: 'Tabellinnstillinger',
    text_settings: 'Tekstinnstillinger',
    title: 'Materialtestgenerator',
  },
  menu: {
    inches: 'tommer',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Autentiseringsfeil: Oppdater Beam Studio og maskinens fastvare til nyeste versjon.',
    authenticating: 'Autentiserer...',
    camera: {
      abort_preview: 'Avbryt',
      camera_cable_unstable: 'Det er oppdaget at kameraet overfører bilder ustabilt. Forhåndsvisning kan fortsatt utføres normalt, men det kan være problemer med treg forhåndsvisning eller tidsavbrudd.',
      continue_preview: 'Fortsett',
      fail_to_transmit_image: '#845 Noe gikk galt med bildeoverføringen. Start maskinen eller Beam Studio på nytt. Hvis denne feilen vedvarer, følg denne veiledningen.',
      ws_closed_unexpectly: '#844 Tilkoblingen til maskinkameraet ble uventet lukket. Hvis denne feilen vedvarer, følg denne veiledningen.',
    },
    cancelling: 'Avbryter...',
    cant_connect_to_device: '#827 Kan ikke koble til maskinen, sjekk tilkoblingen',
    connected: 'Tilkoblet',
    connecting: 'Kobler til...',
    connectingCamera: 'Kobler til kamera...',
    connectingMachine: 'Kobler til %s...',
    connectionTimeout: '#805 Tilkobling til enhet tidsavbrudd. Sjekk nettverkstilstanden og maskinens Wi-Fi-indikator.',
    device_blocked: {
      caption: 'Uautorisert serienummer',
      offline: 'Serienummeret til enheten din er begrenset til offline bruk, da det ser ut til å være deaktivert. Vennligst oppgi enhetens serienummer (plassert på baksiden av enheten) til forhandleren din og be dem kontakte support@flux3dp.com for å aktivere online-autorisasjon. Hvis du ønsker å bruke enheten offline, vennligst kontakt support@flux3dp.com direkte.',
      online: 'Serienummeret til enheten din ser ut til å være deaktivert. Vennligst oppgi enhetens serienummer (plassert på baksiden av enheten) til forhandleren din og be dem kontakte support@flux3dp.com for å aktivere enhetsautorisasjon.',
    },
    device_busy: {
      caption: 'Maskinen er opptatt',
      message: 'Maskinen utfører en annen oppgave, prøv igjen senere. Hvis den slutter å fungere, start maskinen på nytt.',
    },
    device_is_used: 'Maskinen er i bruk, vil du avbryte gjeldende oppgave?',
    device_not_found: {
      caption: 'Standardmaskin ikke funnet',
      message: '#812 Sjekk maskinens Wi-Fi-indikator',
    },
    disconnected: 'Ustabil tilkobling, sjekk enhetstilkobling og prøv igjen senere',
    endingLineCheckMode: 'Avslutter linjekontrollmodus...',
    endingRawMode: 'Forlater råmodus...',
    enteringLineCheckMode: 'Går inn i linjesjekkmodus...',
    enteringRawMode: 'Går inn i råmodus...',
    enteringRedLaserMeasureMode: 'Går inn i rød laser-målingsmodus...',
    exitingRotaryMode: 'Avslutter roteringsmodus...',
    getProbePosition: 'Henter probeposisjon...',
    gettingLaserSpeed: 'Leser lasershodehastighet...',
    homing: 'Hjemkjører...',
    need_password: 'Trenger passord for å koble til maskinen',
    please_enter_dpi: 'Angi enheten for filen (i mm)',
    preview: {
      adjust: 'Juster',
      adjust_height_tooltip: 'Klikk avkrysningsboksen for å aktivere redigering.',
      already_performed_auto_focus: 'Du har allerede utført autofokus, bruke eksisterende verdier?',
      auto_focus: 'Autofokus',
      auto_focus_instruction: 'Flytt laserhodet over objektet og følg animasjonsinstruksjonene for å trykke AF for å fokusere.',
      camera_preview: 'Kameravisning',
      enter_manually: 'Oppgi manuelt',
      please_enter_height: 'Oppgi høyden på objektet for å ta presis kamerabilde.',
    },
    promark_disconnected: '#850 Enhetens tilkobling er brutt, vennligst sjekk enhetens tilkoblingsstatus.',
    redLaserTakingReference: 'Tar referanse...',
    retrievingCameraOffset: 'Leser kameraforskyvning...',
    settingLaserSpeed: 'Angir lasershodehastighet...',
    swiftray_disconnected: 'Kan ikke koble til backend, prøver på nytt.',
    swiftray_reconnected: 'Backend er tilkoblet igjen, prøv å sende jobben på nytt.',
    time_remaining: 'Gjenstående tid:',
    tryingToConenctMachine: 'Prøver å koble til maskin...',
    turningOffAirPump: 'Slår av luftpumpe...',
    turningOffFan: 'Slår av vifte...',
    unable_to_find_machine: 'Kan ikke finne maskin ',
    unable_to_start: '#830 Kan ikke starte oppgaven. Prøv igjen. Hvis dette skjer igjen, kontakt oss med en feilrapport:',
    unavailableWorkarea: '#804 Gjeldende arbeidsområde overskrider maskinens arbeidsområde. Sjekk arbeidsområdet for valgt maskin eller angi arbeidsområde fra Rediger > Dokumentinnstillinger.',
    unknown_device: '#826 Kan ikke koble til maskinen, kontroller at USB er tilkoblet maskinen',
    unknown_error: '#821 Programmet har støtt på en ukjent feil, bruk Hjelp > Meny > Rapporter feil.',
    unsupport_osx_version: 'Gjeldende macOS-versjon %s støtter kanskje ikke alle funksjoner. Oppdater til macOS 11+.',
    unsupport_win_version: 'Gjeldende OS-versjon %s støtter kanskje ikke alle funksjoner. Oppdater til nyeste versjon.',
    unsupported_example_file: 'Det valgte eksempelfilen støttes ikke av gjeldende arbeidsområde.',
    uploading_fcode: 'Laster opp FCode',
    usb_unplugged: 'USB-tilkobling tapt. Sjekk USB-tilkoblingen',
    wrong_swiftray_version_message: 'Feil backend-versjon (versjon: {version}). Sjekk om flere forekomster av Beam Studio kjører, og lukk eventuelle extra forekomster før du prøver igjen.',
    wrong_swiftray_version_title: 'Feil backend-versjon',
  },
  monitor: {
    ask_reconnect: 'Tilkoblingen til maskinen ble brutt. Vil du koble til på nytt?',
    bug_report: 'Feilrapport',
    camera: 'Kamera',
    cancel: 'Avbryt',
    confirmFileDelete: 'Er du sikker på at du vil slette denne filen?',
    connecting: 'Kobler til, vennligst vent...',
    DEVICE_ERROR: 'Noe gikk galt. Vennligst start maskinen på nytt',
    download: 'Last ned',
    extensionNotSupported: 'Dette filformatet støttes ikke',
    fileExistContinue: 'Filen finnes allerede, vil du erstatte den?',
    forceStop: 'Ønsker du å avbryte gjeldende oppgave?',
    go: 'Start',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Luftstrømsensor unormal',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Bunn åpnet. Lukk bunnen for å fortsette.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Lukk døren for å fortsette.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Skuffen åpnet',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Flammesensor unormal',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Feil modul oppdaget. Installer riktig modul for å fortsette.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul ikke oppdaget. Kontroller at modulen er riktig installert for å fortsette.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Ukjent modul oppdaget. Installer riktig modul for å fortsette.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off-feil',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritisk feil: Hovedkort frakoblet. Kontakt FLUX-støtte.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Overopphetet. Vent noen minutter.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-akse nullstilling mislyktes',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Ingen respons fra skrivermodul.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Vennligst trekk sonden tilbake.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Sjekk vannbeholderen.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Ingen roterende modul oppdaget',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritisk feil: Undersystem svarer ikke. Kontakt FLUX-støtte.',
    HARDWARE_FAILURE: 'Noe gikk galt. Vennligst start maskinen på nytt',
    hour: 't',
    left: 'venstre',
    MAINBOARD_OFFLINE: '#905 Feil ved tilkobling til hovedkort. Vennligst start maskinen på nytt.',
    minute: 'm',
    monitor: 'OVERVÅK',
    NO_RESPONSE: '#905 Feil ved tilkobling til hovedkort. Vennligst start maskinen på nytt.',
    pause: 'Pause',
    prepareRelocate: 'Forbereder for flytting',
    processing: 'Behandler',
    record: 'Ta opp',
    relocate: 'Flytt',
    RESOURCE_BUSY: 'Maskinen er opptatt. Hvis den ikke kjører, vennligst start maskinen på nytt',
    resume: 'Gjenoppta',
    savingPreview: 'Genererer miniatyrbilder',
    second: 's',
    start: 'Start',
    stop: 'Stopp',
    SUBSYSTEM_ERROR: '#402 Kritisk feil: Delsystem svarer ikke. Vennligst kontakt FLUX-support.',
    task: {
      BEAMBOX: 'Lasergravering',
      'N/A': 'Fritt modus',
    },
    taskTab: 'Oppgave',
    temperature: 'Temperatur',
    upload: 'Last opp',
    USER_OPERATION: 'Følg instruksjonene på enhetspanelet for å fortsette.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Vennligst sett inn riktig patron for å fortsette.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Vennligst sett inn patronen for å fortsette.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Installer riktig modul for å fortsette.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Feil modul oppdaget. Installer riktig modul for å fortsette.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul ikke oppdaget. Kontroller at modulen er riktig installert for å fortsette.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Ukjent modul oppdaget. Installer riktig modul for å fortsette.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Ta ut patronen for å fortsette.',
    USER_OPERATION_ROTARY_PAUSE: 'Vennligst bytt til roterende motor',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Er du sikker på at du vil slette denne filen? Denne handlingen kan ikke angres.',
      delete: 'Slett',
      download: 'Last ned',
      duplicate: 'Dupliser',
      open: 'Åpne',
      rename: 'Gi nytt navn',
    },
    file_limit: 'Gratis fil',
    loading_file: 'Laster inn...',
    no_file_subtitle: 'Gå til Meny > "Fil" > "Lagre til skyen"',
    no_file_title: 'Lagre filer til Min sky for å komme i gang.',
    save_file: {
      choose_action: 'Lagre fil:',
      input_file_name: 'Skriv inn filnavn:',
      invalid_char: 'Ugyldige tegn:',
      save: 'Lagre',
      save_new: 'Lagre som ny fil',
      storage_limit_exceeded: 'Skyen din har nådd øvre grense. Vennligst slett unødvendige filer før du lagrer nye.',
    },
    sort: {
      a_to_z: 'Navn: A - Å',
      most_recent: 'Nylig',
      oldest: 'Eldst',
      z_to_a: 'Navn: Å - A',
    },
    title: 'Min sky',
    upgrade: 'Oppgrader',
  },
  noun_project_panel: {
    clear: 'Tøm',
    elements: 'Elementer',
    enjoy_shape_library: 'Nyt formdatabasen.',
    export_svg_title: 'Kan ikke eksportere SVG',
    export_svg_warning: 'Dette prosjektet inneholder objekter som er beskyttet av opphavsretten. Derfor vil Beam Studio automatisk utelate disse objektene under eksporten. Du kan fremdeles lagre prosjektet ditt i Beam Studio Scene (.beam-format) for å beholde alle dataene dine. Vil du fremdeles eksportere?',
    learn_more: 'Lær mer',
    login_first: 'Logg inn for å låse opp formdatabasen.',
    recent: 'Nylig',
    search: 'Søk',
    shapes: 'Former',
  },
  pass_through: {
    export: 'Eksporter til Arbeidsområde',
    exporting: 'Eksporterer...',
    guide_mark: 'Veiledningsmerke',
    guide_mark_desc: 'Veiledningsmerker vil bli gravert som et referansepunkt for å justere kunstverket.',
    guide_mark_length: 'Lengde:',
    guide_mark_x: 'X-koordinat:',
    height_desc: 'Sett høyden på hver arbeidsområdeseksjon.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Hvordan sette opp gjennomgang for %(model)s?',
    object_length: 'Objekt Lengde',
    ref_layer: 'Referanselag',
    ref_layer_desc: 'Vær oppmerksom på at utførelsen av referanselaget er satt til 0 som standard. Det vil ikke bli utført, men er kun for justeringsreferanse.',
    ref_layer_name: 'Referanse',
    title: 'Gjennomgangsmodus',
    workarea_height: 'Arbeidsområde (Høyde):',
  },
  promark_connection_test: {
    description: 'Systemet vil kjøre en rødlysoperasjon i omtrent to minutter for å sjekke om tilkoblingen er stabil.',
    health: 'Stabilitet:',
    healthy_description: 'Stabiliteten er god, men unngå å flytte datamaskinen betydelig under gravering.',
    res_0: 'Utmerket',
    res_1: 'God',
    res_2: 'Lav',
    res_3: 'For Lav',
    res_4: 'Svært Lav',
    restart: 'Start Test På Nytt',
    start: 'Start Test',
    stop: 'Stopp Test',
    title: 'Tilkoblingsstabilitetstest',
    unhealthy_description: 'Stabiliteten er for lav. Det anbefales å bytte nettverksadapter eller strømkabel.',
  },
  promark_settings: {
    angle: 'Vinkel',
    bulge: 'Bulking',
    field: 'Felt',
    galvo_configuration: 'Galvo-konfigurasjon',
    mark: 'Merk',
    mark_parameters: 'Merkingsparametere',
    offsetX: 'Forskyvning X',
    offsetY: 'Forskyvning Y',
    preview: 'Forhåndsvisning',
    red_dot: 'Rød prikk',
    scale: 'Skala',
    scaleX: 'Skala X',
    scaleY: 'Skala Y',
    skew: 'Skjevhet',
    switchXY: 'Bytt X/Y',
    title: 'Promark-innstillinger',
    trapezoid: 'Trapes',
    workarea_hint: 'Du kan endre arbeidsområdet i "Dokumentinnstillinger".',
    z_axis_adjustment: {
      section1: 'Juster høyden på Z-aksen for å finjustere fokus.',
      title: 'Z-Akse Justering',
      tooltip1: 'Prøv å merke en 1x1 cm firkant for å bekrefte om den nåværende fokuseringsavstanden er passende.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Feiltoleranse',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Inverter bakgrunnsfarge',
    placeholder: 'Skriv inn en lenke eller tekst',
    preview: 'Forhåndsvisning',
    title: 'QR-kodegenerator',
  },
  rotary_settings: {
    circumference: 'Omkrets',
    extend_workarea: 'Utvid arbeidsområdet',
    mirror: 'Speiling',
    object_diameter: 'Objekt Diameter',
    overlap_size: 'Overlappingsstørrelse',
    rotary_scale: 'Rotasjonsskala',
    split_setting: 'Delingsinnstilling',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Delingsstørrelse',
    type: 'Type',
  },
  select_device: {
    auth_failure: '#811 Autentisering mislyktes',
    select_device: 'Velg enhet',
    unable_to_connect: '#810 Kunne ikke opprette en stabil tilkobling til maskinen',
  },
  settings: {
    anti_aliasing: 'Anti-Aliasing',
    auto_connect: 'Velg automatisk den eneste maskinen',
    auto_switch_tab: 'Automatisk bytte mellom lag- og objektpanel',
    autofocus_offset: 'Autofokusforskyvning',
    autosave_enabled: 'Autoslagre',
    autosave_interval: 'Lagre hver',
    autosave_number: 'Antall autoslagringer',
    autosave_path: 'Autoslagre plassering',
    autosave_path_not_correct: 'Angitt sti ble ikke funnet.',
    blade_precut_position: 'Forhåndskuttposisjon',
    blade_precut_switch: 'Blad forhåndskutt',
    blade_radius: 'Bladradius',
    bottom_up: 'Nedopp',
    calculation_optimization: 'Baneutregningsakselerasjon',
    cancel: 'Avbryt',
    caption: 'Innstillinger',
    check_updates: 'Autosjekk',
    close: 'Lukk',
    confirm_remove_default: 'Standardmaskinen vil bli fjernet.',
    confirm_reset: 'Bekreft tilbakestilling av Beam Studio?',
    continuous_drawing: 'Kontinuerlig tegning',
    curve_engraving_speed_limit: 'Hastighetsgrense for kurvegravering',
    custom_preview_height: 'Egen forhåndsvisningshøyde',
    default_beambox_model: 'Standard dokumentinnstilling',
    default_borderless_mode: 'Åpne bunn som standard',
    default_enable_autofocus_module: 'Autofokus som standard',
    default_enable_diode_module: 'Diodlaser som standard',
    default_font_family: 'Standardskrift',
    default_font_style: 'Standard skriftstil',
    default_laser_module: 'Standard lasermodul',
    default_machine: 'Standardmaskin',
    default_machine_button: 'Tom',
    default_units: 'Standardenheter',
    diode_offset: 'Diodlaserforskyvning',
    diode_one_way_engraving: 'Diodlaser enveisgravering',
    diode_two_way_warning: 'Toveis lysutsendelse er raskere og kan gi unøyaktig laserposisjon. Anbefales å teste først.',
    disabled: 'Deaktivert',
    done: 'Bruk',
    enable_custom_backlash: 'Aktiver egen slakkkompensasjon',
    enable_low_speed: 'Aktiver lav hastighetsbevegelse',
    enable_uv_print_file: 'Aktiver UV-utskriftsfil',
    enabled: 'Aktivert',
    engraving_direction: 'Retning',
    fast_gradient: 'Hastighetsoptimalisering',
    font_convert: 'Tekst til bane konverterer',
    font_substitute: 'Erstatt ustøttede tegn',
    grouped_objects: 'Grupperte objekter',
    groups: {
      ador_modules: 'Ador-moduler',
      autosave: 'Autospar',
      camera: 'Kamera',
      connection: 'Tilkobling',
      editor: 'Rediger',
      engraving: 'Rastering (Skanning)',
      general: 'Generelt',
      mask: 'Arbeidsområdeklipping',
      modules: 'Tilleggsmodul',
      path: 'Vektor (Konturer)',
      privacy: 'Personvern',
      text_to_path: 'Tekst',
      update: 'Programvareoppdateringer',
    },
    guess_poke: 'Søk etter maskin-IP-adresse',
    guides: 'Veiledere',
    guides_origin: 'Veiledere opprinnelse',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      uv_print_export: 'https://support.flux3dp.com/hc/en-us/articles/12320887477135',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Høy',
    image_downsampling: 'Bildekvalitet',
    ip: 'Maskin-IP-adresse',
    keep_preview_result: 'Behold Forhåndsvisningsresultat',
    language: 'Språk',
    loop_compensation: 'Sløyfekompensasjon',
    low: 'Lav',
    low_laser_for_preview: 'Laser for Kjører ramme',
    mask: 'Arbeidsområdeklipping',
    medium: 'Middels',
    module_offset_2w_ir: '2W infrarød laserforskyvning',
    module_offset_10w: '10W diodlaserforskyvning',
    module_offset_20w: '20W diodlaserforskyvning',
    module_offset_printer: 'Skriverforskyvning',
    none: 'Ingen',
    normal: 'Normal',
    notification_off: 'Av',
    notification_on: 'På',
    notifications: 'Skrivebordsvarsler',
    off: 'Av',
    on: 'På',
    preview_movement_speed: 'Forhåndsvisning bevegelsesfart',
    printer_advanced_mode: 'Avansert skrivermodus',
    remove_default_machine_button: 'Fjern',
    reset: 'Tilbakestill Beam Studio',
    reset_now: 'Tilbakestill Beam Studio',
    segmented_engraving: 'Segmentert gravering',
    share_with_flux: 'Del Beam Studio-analyse',
    simplify_clipper_path: 'Forenklet bane',
    single_object: 'Enkelt objekt',
    tabs: {
      device: 'Enhet',
      general: 'Generelt',
    },
    text_path_calc_optimization: 'Baneberegningoptimalisering',
    top_down: 'Oppned',
    trace_output: 'Bildesporingsutdata',
    update_beta: 'Beta',
    update_latest: 'Siste',
    vector_speed_constraint: 'Fartsgrense',
    wrong_ip_format: 'Feil IP-format',
  },
  social_media: {
    facebook: 'Chat med FLUXers, still spørsmål og lær tips!',
    instagram: 'Få de nyeste inspirasjonene, tilbudene og gratisvarene!',
    youtube: 'Se Beam Studio-veiledninger og håndverksideer.',
  },
  support: {
    no_vcredist: 'Installer Visual C++ Redistributable 2015<br/>Den kan lastes ned på flux3dp.com',
    no_webgl: 'WebGL støttes ikke. Bruk en annen enhet.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Legg til objekter først',
      door_opened: 'Vennligst lukk dørdekselet for å aktivere lav laser for å kjøre ramme.',
      fail_to_connect_with_camera: '#803 Kunne ikke opprette tilkobling til maskinkamera. Start maskinen eller Beam Studio på nytt. Hvis feilen vedvarer, følg denne veiledningen',
      fail_to_get_door_status: 'Vennligst sørg for at dørdekselet er lukket for å aktivere lav laser for å kjøre ramme.',
      fail_to_start_preview: '#803 Kunne ikke starte forhåndsvisning. Start maskinen eller Beam Studio på nytt. Hvis feilen vedvarer, følg denne veiledningen',
      headtype_mismatch: 'Feil modul oppdaget. ',
      headtype_none: 'Modul ikke oppdaget. ',
      headtype_unknown: 'Ukjent modul oppdaget. ',
      install_correct_headtype: 'Vennligst installer 10W/20W diode laser moduler ordentlig for å aktivere lav laser for å kjøre ramme.',
      job_origin_unavailable: 'Å sette opp jobbopprinnelse krever firmwareversjon 4.3.5 / 5.3.5 eller nyere. Vil du oppdatere firmwaren nå?',
      job_origin_warning: 'Du bruker for øyeblikket “nåværende posisjon” som startpunkt. Sørg for at laserhodet flyttes til riktig posisjon for å unngå kollisjoner.',
      power_too_high: 'FOR HØY EFFEKT',
      power_too_high_confirm: 'FORSTÅTT',
      power_too_high_msg: 'Bruk av lavere lasereffekt (under 70%) forlenger laserens levetid.\nSkriv inn "FORSTÅTT" for å fortsette.',
      pwm_unavailable: 'Dybde-modus krever firmware-versjon 4.3.4 / 5.3.4 eller nyere. Vil du oppdatere fastvaren nå?',
      QcleanScene: 'Vil du slette tegningen?<br/>Dette vil også slette angrehistorikken din!',
      start_preview_timeout: '#803 Tidsavbrudd ved oppstart av forhåndsvisning. Start maskinen eller Beam Studio på nytt. Hvis feilen vedvarer, følg denne veiledningen',
    },
    frame_task: 'Kjører ramme',
    hint: {
      polygon: 'Trykk + / -tasten for å øke / redusere sider.',
    },
    menu: {
      about: 'Om Beam Studio',
      about_beam_studio: 'Om Beam Studio',
      account: 'Konto',
      add_new_machine: 'Maskinoppsett',
      align_center: 'Sentrer',
      anti_aliasing: 'Anti-Aliasing',
      auto_align: 'Automatisk Justering',
      borderless_mode: 'Rammeløs modus',
      bug_report: 'Feilrapport',
      calibrate_beambox_camera: 'Kalibrer kamera',
      calibrate_beambox_camera_borderless: 'Kalibrer kamera (åpen bunn)',
      calibrate_camera_advanced: 'Kalibrer kamera (Avansert)',
      calibrate_diode_module: 'Kalibrer diode lasermodul',
      calibrate_ir_module: 'Kalibrer infrarød modul',
      calibrate_printer_module: 'Kalibrer skrivermodul',
      calibration: 'Kalibreringer',
      camera_calibration_data: 'Kamera Kalibreringsdata',
      change_logs: 'Endringslogg',
      clear_scene: 'Nye filer',
      close: 'Lukk vindu',
      commands: 'Kommandoer',
      contact: 'Kontakt oss',
      copy: 'Kopier',
      cut: 'Klipp ut',
      dashboard: 'Kontrollpanel',
      decompose_path: 'Dekomponer',
      delete: 'Slett',
      design_market: 'Designmarked',
      dev_tool: 'Feilsøkingsverktøy',
      disassemble_use: 'Demontere',
      document_setting: 'Dokumentinnstillinger',
      document_setting_short: 'Dokument',
      download_data: 'Last ned Data',
      download_log: 'Last ned logger',
      download_log_canceled: 'Loggnedlasting avbrutt',
      download_log_error: 'Ukjent feil oppstod, vennligst prøv igjen senere',
      duplicate: 'Dupliser',
      edit: 'Rediger',
      example_files: 'Eksempelfiler',
      export_BVG: 'BVG',
      export_flux_task: 'FLUX-oppgave',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Eksporter til...',
      export_UV_print: 'UV-utskrift PDF',
      file: 'Fil',
      fit_to_window: 'Tilpass til vindu',
      follow_us: 'Følg oss',
      forum: 'Fellesskapsforum',
      group: 'Grupper',
      help: 'Hjelp',
      help_center: 'Hjelpesenter',
      hide: 'Skjul Beam Studio',
      hideothers: 'Skjul andre',
      image_crop: 'Beskjær',
      image_curve: 'Kurve',
      image_invert: 'Inverter',
      image_sharpen: 'Skarphet',
      image_stamp: 'Fas',
      image_vectorize: 'Spor',
      import_acrylic_focus_probe: 'Akrylfokusprobe - 3 mm',
      import_ador_laser_example: 'Eksempel på Ador Laser',
      import_ador_printing_example_full: 'Eksempel på Ador-utskrift - Fullfarge',
      import_ador_printing_example_single: 'Eksempel på Ador-utskrift - Enkelt farge',
      import_beambox_2_example: 'Eksempel på Beambox II',
      import_beambox_2_focus_probe: 'Beambox II Fokussonde - 3 mm',
      import_hello_beambox: 'Eksempel på Beambox',
      import_hello_beamo: 'Eksempel på beamo',
      import_hexa_example: 'Eksempel på HEXA',
      import_material_printing_test: 'Materialutskriftstest',
      import_material_testing_cut: 'Materialskjæringstest',
      import_material_testing_engrave: 'Materialgraveringstest',
      import_material_testing_line: 'Materiallinjetest',
      import_material_testing_old: 'Materialgraveringstest - Klassisk',
      import_material_testing_simple_cut: 'Materialskjæringstest - Enkel',
      import_promark_example: 'Eksempel på Promark',
      import_promark_mopa_20w_color: 'MOPA 20W Fargetest',
      import_promark_mopa_60w_color: 'MOPA 60W Fargetest',
      import_promark_mopa_100w_color: 'MOPA 100W Fargetest',
      keyboard_shortcuts: 'Tastatursnarveier',
      layer_color_config: 'Fargeinnstillinger',
      layer_setting: 'Lag',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Kamera',
        cloud: 'Sky',
        discover: 'Oppdag',
        hardware: 'Maskinvare',
        network: 'Nettverk',
        player: 'Spiller',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'USB-liste',
      },
      machine_info: 'Maskininfo',
      machines: 'Maskiner',
      manage_account: 'Administrer konto',
      material_test: 'Materialtest',
      minimize: 'Minimer',
      my_account: 'Min konto',
      network_testing: 'Test nettverksinnstillinger',
      object: 'Objekt',
      offset: 'Forskyv',
      open: 'Åpne',
      paste: 'Lim inn',
      paste_in_place: 'Lim inn på plass',
      path: 'Bane',
      photo_edit: 'Bilde',
      preferences: 'Innstillinger',
      promark_color_test: 'Promark Fargetest',
      questionnaire: 'Tilbakemeldingsskjema',
      quit: 'Avslutt',
      recent: 'Åpne Nylig',
      redo: 'Gjør om',
      reload_app: 'Last App på Nytt',
      reset: 'Tilbakestill',
      rotary_setup: 'Roterende Oppsett',
      rotate: 'Roter',
      samples: 'Eksempler',
      save_as: 'Lagre som...',
      save_scene: 'Lagre',
      save_to_cloud: 'Lagre til skyen',
      scale: 'Skaler',
      service: 'Tjenester',
      set_as_default: 'Angi som standard',
      show_gesture_tutorial: 'Håndbevegelseintroduksjon',
      show_grids: 'Vis rutenett',
      show_layer_color: 'Bruk lagfarge',
      show_rulers: 'Vis linjal',
      show_start_tutorial: 'Vis startopplæring',
      show_ui_intro: 'Vis grensesnittintroduksjon',
      sign_in: 'Logg inn',
      sign_out: 'Logg ut',
      software_update: 'Programvareoppdatering',
      svg_edit: 'SVG',
      switch_to_beta: 'Bytt til Beta-versjon',
      switch_to_latest: 'Bytt til Stabil versjon',
      tools: {
        box_generator: 'Boksgenerator',
        code_generator: 'Kodegenerator',
        material_test_generator: 'Materialtestgenerator',
        title: 'Verktøy',
      },
      tutorial: 'Start opplæring for Delta-serien',
      undo: 'Angre',
      ungroup: 'Del opp',
      update: 'Sjekk Oppdatering',
      update_firmware: 'Oppdater fastvare',
      upload_data: 'Last opp Data',
      using_beam_studio_api: 'Bruke Beam Studio API',
      view: 'Vis',
      window: 'Vindu',
      zoom_in: 'Zoom inn',
      zoom_out: 'Zoom ut',
      zoom_with_window: 'Auto tilpass til vindu',
    },
    preview: 'FORHÅNDSVISNING',
    preview_press_esc_to_stop: 'Trykk ESC for å stoppe kameraforhåndsvisningen.',
    rename_tab: 'Gi nytt navn til fane',
    select_machine: 'Velg en maskin',
    tag_names: {
      dxf: 'DXF-objekt',
      ellipse: 'Oval',
      g: 'Gruppe',
      image: 'Bilde',
      line: 'Linje',
      multi_select: 'Flere objekter',
      no_selection: 'Ingen valg',
      pass_through_object: 'Passer Gjennom Objekt',
      path: 'Bane',
      polygon: 'Polygon',
      rect: 'Rektangel',
      svg: 'SVG-objekt',
      text: 'Tekst',
      text_path: 'Tekst på bane',
      use: 'Importert objekt',
    },
    task_preview: 'Oppgaveforhåndsvisning',
    titles: {
      settings: 'Innstillinger',
    },
    untitled: 'Uten tittel',
  },
  topmenu: {
    credit: 'Beam Studio er muliggjort av <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> åpen kildekode-prosjektet og annen <a target="_blank" href="https://flux3dp.com/credits/">åpen kildekode-programvare</a>.',
    device: {
      download_log_canceled: 'Logg nedlasting avbrutt',
      download_log_error: 'Ukjent feil oppstod, vennligst prøv igjen senere',
      log: {
        usblist: 'USB-liste',
      },
      network_test: 'Test nettverk',
    },
    file: {
      all_files: 'Alle filer',
      clear_recent: 'Tøm nylig åpnet',
      converting: 'Konverterer til bilde...',
      fcode_files: 'FLUX Code',
      import: 'Importer',
      jpg_files: 'JPG',
      label: 'Fil',
      path_not_exit: 'Denne stien ser ikke ut til å eksistere lenger på disken.',
      png_files: 'PNG',
      save_fcode: 'Eksporter FLUX-oppgave',
      save_jpg: 'Eksporter JPG',
      save_png: 'Eksporter PNG',
      save_scene: 'Lagre scene',
      save_svg: 'Eksporter SVG',
      scene_files: 'Beam Studio Scene',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Versjon',
  },
  tutorial: {
    ask_retry_calibration: 'Vil du kalibrere kameraet på nytt?',
    camera_calibration_failed: 'Kamerakalibrering mislyktes',
    gesture: {
      click: 'Trykk for å velge objektet.',
      drag: 'Dra for å velge flere objekter.',
      hold: 'Trykk og hold for å åpne hurtigmenyen.',
      pan: 'Rull arbeidsområdet med to fingre.',
      zoom: 'Klyp med to fingre for å zoome inn/ut arbeidsområdet.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Søker etter maskin for opplæring...',
    needNewInterfaceTutorial: 'Vil du starte en veiledning for Beam Studios nye grensesnitt?<br/>(Du kan hoppe over den nå og starte senere ved å klikke på "Hjelp" > "Vis Grensesnittintroduksjon".)',
    needNewUserTutorial: 'Vil du starte en veiledning?<br/>(Du kan hoppe over den nå og starte senere ved å klikke på "Hjelp" > "Vis Startveiledning".)',
    newInterface: {
      add_new_layer: 'Legg til nytt lag',
      align_controls: 'Justeringskontroller',
      basic_shapes: 'Grunnleggende former',
      camera_preview: 'Kameraforhåndsvisning',
      drag_to_sort: 'Dra for å sortere',
      end_alert: 'Er du sikker på at du vil avslutte introduksjonen til nytt grensesnitt?',
      flip: 'Vend',
      group_controls: 'Gruppekontroller',
      layer_controls: 'Høyreklikk for å velge lagkontroller:\nDupliser / Slå sammen / Lås / Slett lag',
      object_actions: 'Objekt-handlinger',
      pen_tool: 'Pennverktøy',
      rename_by_double_click: 'Gi nytt navn ved å dobbeltklikke',
      select_image_text: 'Velg / Bilde / Tekst',
      select_machine: 'Velg en maskin',
      shape_operation: 'Formoperasjon',
      start_work: 'Start arbeid',
      switch_between_layer_panel_and_object_panel: 'Bytt mellom lags-panel og objekt-panel',
    },
    newUser: {
      add_new_layer: 'Legg til et nytt lag',
      adjust_focus: '2. Juster fokus',
      close_cover: '3. Lukk dekselet',
      drag_to_draw: 'Dra for å tegne',
      draw_a_circle: 'Tegn en sirkel',
      draw_a_rect: 'Tegn et rektangel',
      end_alert: 'Er du sikker på at du vil avslutte opplæringen?',
      end_preview_mode: 'Avslutt forhåndsvisningsmodus',
      infill: 'Slå på utfylling',
      please_select_wood_cutting: 'Vennligst velg "Tre - Skjæring" forhåndsinnstilling.',
      please_select_wood_engraving: 'Vennligst velg "Tre - Gravering" forhåndsinnstilling.',
      preview_the_platform: 'Forhåndsvis plattformen',
      put_wood: '1. Legg i prøvetreet',
      send_the_file: 'Send filen',
      set_preset_wood_cut: 'Angi forhåndsinnstilling: Tre - Skjæring',
      set_preset_wood_engraving: 'Angi forhåndsinnstilling: Tre - Gravering',
      switch_to_layer_panel: 'Bytt til lagpanel',
      switch_to_object_panel: 'Bytt til objektpanel',
      switch_to_preview_mode: 'Bytt til forhåndsvisningsmodus',
    },
    next: 'NESTE',
    retry: 'Prøv igjen',
    set_connection: 'Angi tilkobling',
    skip: 'Hopp over',
    skip_tutorial: 'Du har hoppet over opplæringen. Du kan alltid starte opplæringen ved å klikke "Hjelp" > "Vis startopplæring"',
    suggest_calibrate_camera_first: 'Vi anbefaler brukere å kalibrere kameraet først og fokusere på nytt før hver forhåndsvisning for optimale resultater.<br/>Vil du bekrefte å utføre kalibreringen nå?<br/>(Du kan hoppe over det nå og gjøre det senere ved å klikke på "Meny" > "Maskin" > [Din Maskin] > "Kalibrer Kamera".)',
    tutorial_complete: 'Det var alt for opplæringen. Nå er det på tide å begynne å lage!',
    unable_to_find_machine: 'Kunne ikke finne maskin for opplæring. Vil du gå til tilkoblingsside, prøve på nytt eller hoppe over opplæring?',
    welcome: 'VELKOMMEN',
  },
  update: {
    cannot_reach_internet: '#823 Serveren er utilgjengelig<br/>Sjekk internettforbindelsen',
    download: 'NETTOPPDATERING',
    firmware: {
      caption: 'En fastvareoppdatering til maskinen er tilgjengelig',
      confirm: 'LAST OPP',
      firmware_too_old_update_by_sdcard: 'Fastvareversjonen er for gammel. Oppdater fastvaren ved hjelp av et SD-kort.',
      force_update_message: '#814 Oppdater maskinen til siste fastvareversjon.',
      latest_firmware: {
        cant_get_latest: 'Kunne ikke hente informasjon om nyeste fastvare.',
        caption: 'Maskinvare oppdatering',
        message: 'Du har den nyeste maskinvarefastvaren',
        still_update: 'OPPDATER',
      },
      message_pattern_1: '"%s" er klar for fastvareoppdatering.',
      message_pattern_2: '%s fastvare v%s er nå tilgjengelig - Du har v%s.',
      too_old_for_web: 'Din nåværende maskinvarefastvare er v%s.\nHvis du vil bruke nettversjonen av Beam Studio, oppdater maskinvarefastvaren til siste versjon.',
      update_fail: '#822 Oppdatering mislyktes',
      update_success: 'Fastvareoppdatering lastet opp vellykket',
      upload_file: 'Fastvareopplasting (*.bin / *.fxfw)',
    },
    install: 'INSTALLER',
    later: 'SENERE',
    preparing: 'Forbereder...',
    release_note: 'Utgivelsesnotat:',
    skip: 'Hopp over denne versjonen',
    software: {
      available_switch: 'Beam Studio v%s er tilgjengelig nå. Du har v%s. Vil du bytte til denne versjonen?',
      available_update: 'Beam Studio v%s er tilgjengelig nå. Du har v%s. Vil du laste ned oppdateringen?',
      caption: 'En programvareoppdatering for Beam Studio er tilgjengelig',
      check_update: 'Se etter oppdateringer',
      checking: 'Ser etter oppdateringer',
      downloading: 'Laster ned oppdateringer i bakgrunnen, du kan klikke "OK" for å fortsette.',
      install_or_not: 'er klar for oppdatering. Vil du starte på nytt og oppdatere nå?',
      no: 'Nei',
      no_response: 'Kunne ikke koble til serveren, vennligst sjekk nettverksinnstillingene dine.',
      not_found: 'Du bruker den nyeste versjonen av Beam Studio.',
      switch_or_not: 'er klar for bytte. Vil du starte på nytt og bytte nå?',
      switch_version: 'Bytt versjon',
      switch_version_not_found: 'Byttbar versjon ikke funnet.',
      update_for_ador: 'Den nåværende programvareversjonen %s er ikke kompatibel, vennligst last ned den nyeste versjonen av Beam Studio for Ador.',
      yes: 'Ja',
    },
    update: 'Oppdater',
    updating: 'Oppdaterer...',
    upload: 'LAST OPP',
  },
  web_cam: {
    no_device: 'Kan ikke oppdage kameramaskinen. Vennligst koble til kameraet på nytt og prøv igjen.',
    no_permission: 'Beam Studio har ikke tillatelse til å få tilgang til kameraet. Sørg for at tillatelsen er gitt til Beam Studio i nettleserinnstillingene eller systeminnstillingene.',
  },
  z_speed_limit_test: {
    alert_before: 'Før du starter kurvegravering, vil systemet utføre en belastningstest for å forhindre trinntap på grunn av for høy vekt.',
    alert_failed: 'Vekten av det nåværende objektet overskrider belastningsgrensen for den nåværende Z-aksehastigheten. Reduser Z-aksehastigheten på maskinpanelet eller bytt ut graveringsobjektet før du tester på nytt.',
    ignore: 'Ignorer',
    retest: 'Test på nytt',
    testing: 'Utfører belastningstest...',
  },
};

export default lang;
