import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Abort',
    cancel: 'Cancel',
    caption: 'Error',
    close: 'Close',
    confirm: 'Confirm',
    dont_save: "Don't Save",
    dont_show_again: "Don't show again",
    duplicated_preset_name: 'Duplicated preset name',
    error: 'UH-OH',
    info: 'INFO',
    instruction: 'Instruction',
    learn_more: 'Learn More',
    no: 'No',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Oops...',
    retry: 'Retry',
    save: 'Save',
    stop: 'Stop',
    warning: 'WARNING',
    yes: 'Yes',
  },
  auto_fit: {
    artwork_size: 'Artwork Size',
    error_tip1: '1. Is the artwork placed on the material correctly?',
    error_tip2: '2. Are the material contours clear enough for recognition?',
    failed_to_find_contour: 'Failed to find any contours to auto fit',
    learn_more: 'View Tutorial.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Offset X',
    offset_y: 'Offset Y',
    pattern_size: 'Pattern Size',
    position_artwork: 'Position Artwork',
    position_step1: 'Adjust the size and position of your artwork on the pattern.',
    position_step2: 'Click "Apply" to apply Auto Fit onto this pattern.',
    preview_first: 'Please perform the camera preview first.',
    reset_position: 'Reset Position',
    rotation: 'Rotate',
    select_a_pattern: 'Select a Pattern',
    selected_artwork: 'Selected Artwork',
    step1: 'Choose a pattern where you would like to place the artwork.',
    step2: 'If no pattern is found, please camera preview again and apply Auto Fit.',
    step3: 'If it still fails to find the correct pattern, please ensure your workpiece is clearly recognizable and the work area is clean of debris.',
    title: 'Auto Fit',
  },
  barcode_generator: {
    bar_height: 'Bar Height',
    bar_width: 'Bar Width',
    barcode: {
      invalid_value: 'The value is invalid for the selected format.',
    },
    font: 'Font',
    font_size: 'Font Size',
    hide_text: 'Hide Text',
    invert_color: 'Invert Color',
    text_margin: 'Text Margin',
  },
  beambox: {
    announcement_panel: {
      title: 'Announcement',
    },
    banner: {
      auto_feeder: 'Auto Feeder Mode',
      camera_preview: 'Preview',
      camera_preview_borderless_mode: '(Open Bottom)',
      curve_engraving: 'Curve Engraving Mode',
      pass_through: 'Passthrough Mode',
      rotary: 'Rotary Mode',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Convert Text to Path...',
      export_file_error_ask_for_upload: 'Failed to export task. Are you willing to provide working scene to develop team for bug report?',
      retreive_image_data: 'Retrieve Image Data...',
    },
    context_menu: {
      copy: 'Copy',
      cut: 'Cut',
      delete: 'Delete',
      duplicate: 'Duplicate',
      group: 'Group',
      move_back: 'Send to Back',
      move_down: 'Send Backward',
      move_front: 'Bring to Front',
      move_up: 'Bring Forward',
      paste: 'Paste',
      paste_in_place: 'Paste in Place',
      ungroup: 'Ungroup',
    },
    document_panel: {
      add_on: 'Add-ons',
      auto_feeder: 'Auto Feeder',
      auto_feeder_scale: 'Auto Feeder Scale',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Open Bottom',
      current_position: 'Current Position',
      disable: 'Disable',
      document_settings: 'Document Settings',
      enable: 'Enable',
      enable_autofocus: 'Autofocus',
      enable_diode: 'Diode Laser',
      engrave_dpi: 'Resolution',
      frame_before_start: 'Frame before executing',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'High',
      job_origin: 'Job Origin',
      laser_source: 'Laser Source',
      low: 'Low',
      machine: 'Machine',
      medium: 'Medium',
      notification: {
        changeFromPrintingWorkareaTitle: 'Do you want to convert the Printing Layers into Laser Layers?',
      },
      origin: 'Origin',
      pass_through: 'Passthrough',
      pass_through_height_desc: 'Enter the length of the object to extend work area.',
      rotary_mode: 'Rotary',
      scale: 'Scale',
      start_from: 'Start From',
      start_position: 'Start Position',
      start_work_button: 'Execute Button',
      ultra: 'Ultra High',
      workarea: 'Working Area',
    },
    image_trace_panel: {
      back: 'Back',
      brightness: 'Brightness',
      cancel: 'Cancel',
      contrast: 'Contrast',
      next: 'Next',
      okay: 'Okay',
      threshold: 'Threshold',
      tuning: 'Parameters',
    },
    layer_color_config_panel: {
      add: 'Add',
      add_config: 'Add Color',
      color: 'Color',
      default: 'Reset To Default',
      in_use: 'This color is in use.',
      layer_color_config: 'Layer Color Configurations',
      no_input: 'Please input valid hex color code.',
      power: 'Power',
      repeat: 'Execute',
      speed: 'Speed',
      sure_to_delete: 'Are you sure to delete this color setting?',
      sure_to_reset: 'You will lose all customized parameters, are you sure to reset to default?',
    },
    left_panel: {
      borderless_blind_area: 'Non-engraving Area',
      borderless_preview: 'Open Bottom Mode Camera Preview',
      diode_blind_area: 'Hybrid Laser Add-On Blind Area',
      ellipse: 'Ellipse',
      image: 'Image',
      label: {
        adjust_height: 'Adjust Height',
        array: 'Array',
        boxgen: 'Boxgen',
        choose_camera: 'Camera',
        clear_preview: 'Clear Preview',
        cursor: 'Select',
        curve_engraving: {
          clear_area: 'Clear Selected Area',
          exit: 'Exit',
          preview_3d_curve: 'Preview Curve Engraving',
          select_area: 'Select Area',
          title: 'Curve Engraving',
        },
        end_preview: 'End Preview',
        line: 'Line',
        live_feed: 'Live Feed',
        my_cloud: 'My Cloud',
        oval: 'Oval',
        pass_through: 'Passthrough',
        pen: 'Pen',
        photo: 'Image',
        polygon: 'Polygon',
        preview: 'Camera Preview',
        qr_code: 'QR Code',
        rect: 'Rectangle',
        shapes: 'Elements',
        text: 'Text',
        trace: 'Trace Image',
      },
      line: 'Line',
      rectangle: 'Rectangle',
      text: 'Text',
      unpreviewable_area: 'Blind Area',
    },
    network_testing_panel: {
      average_response: 'Average Response Time',
      cannot_connect_1: '#840 Fail to connect to target IP.',
      cannot_connect_2: '#840 Fail to connect to target IP. Please make sure that the target is in the same network.',
      cannot_get_local: 'Access to local IP address failed.',
      connection_quality: 'Connection Quality',
      device_not_on_list: '#842 The machine is not on the list, but the connection Quality is >70 and the average response time is <100ms',
      empty_ip: '#818 Please enter target device IP first.',
      end: 'End',
      fail_to_start_network_test: '#817 Fail to start network testing.',
      hint_connect_camera_timeout: 'Timeout occurs when starting camera preview?',
      hint_connect_failed_when_sending_job: 'Failed to connect when sending a job?',
      hint_device_often_on_list: 'The machine is often not found on the list?',
      insert_ip: 'Target device IP address:',
      invalid_ip: '#818 Invalid IP address',
      ip_startswith_169: '#843 Machine IP address starts with 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'This error usually occurs due to insufficient permissions.\nKindly run "sudo beam-studio --no-sandbox" in terminal to obtain permissions and perform network testing.',
      local_ip: 'Local IP address:',
      network_testing: 'Network Testing',
      network_unhealthy: '#841 Connection quality <70 or average response time >100ms',
      start: 'Start',
      test_completed: 'Test Completed',
      test_fail: 'Test Failed',
      testing: 'Testing Network...',
    },
    object_panels: {
      lock_desc: 'Preserve the ratio of width and height (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Some texts were changed to other fonts when parsing texts to paths and some character may not converted normally.\nPlease check preview image again before sending task.',
        error_when_parsing_text: 'Error when converting text to path',
        font_substitute_pop: 'Your text contains characters which are not supported by current font. <br/>Would you like to use <strong>%s</strong> as substitution?',
        retry: 'Please retry later or choose another font',
        use_current_font: 'Use Current Font',
      },
      wait_for_parsing_font: 'Parsing font... Please wait a second',
    },
    path_preview: {
      current_position: 'Current Position',
      cut_distance: 'Cut Distance',
      cut_time: 'Cut Time',
      end_preview: 'End Preview',
      estimated_time: 'Total Time Estimated',
      invert: 'Invert',
      pause: 'Pause',
      play: 'Play',
      play_speed: 'Playback Speed',
      preview_info: 'Preview Information',
      rapid_distance: 'Travel Distance',
      rapid_time: 'Travel Time',
      remark: '* All pieces of information are estimated value for reference.',
      size: 'Size',
      start_here: 'Start Here',
      stop: 'Stop',
      travel_path: 'Travel Path',
    },
    photo_edit_panel: {
      aspect_ratio: 'Aspect Ratio',
      brightness: 'Brightness',
      brightness_and_contrast: 'Brightness / Contrast',
      cancel: 'Cancel',
      circumference: 'Circumference',
      compare: 'Compare',
      contrast: 'Contrast',
      crop: 'Crop',
      curve: 'Curve',
      diameter: 'Diameter',
      free: 'Free',
      invert: 'Invert Color',
      okay: 'Okay',
      original: 'Original',
      phote_edit: 'Photo Edit',
      processing: 'Processing',
      radius: 'Radius',
      rotary_warped: 'Rotary Warped',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Sharpen',
      sharpness: 'Sharpness',
      start: 'Start',
      warp: 'Warp',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Go',
        insufficient_credit: 'You are out of Credit',
        insufficient_credit_msg: 'You can not use the %s. Go to member center and purchase AI Credit.',
        relogin_to_use: 'Please re-login to use this function.',
      },
      auto_feeder_origin: 'Please use the Framing preview to check the laser head’s path and area, then adjust the material placement or engraving start position based on the results to avoid collisions during the engraving process.',
      auto_switch_tab: {
        message: 'A new option to automatically switch between the Layers panel and the Objects panel has been added to Preferences. This option is disabled by default. Would you like to enable automatic switching now?<br/>You can change this setting anytime in Preferences.',
        title: 'Auto Switching Layer and Object Panel',
      },
      backend_connect_failed_ask_to_upload: '#802 Errors keeps occurring when trying to connect to the backend. Do you want to upload your bug report log?',
      backend_error_hint: 'Features might be not working properly due to backend error.',
      both_power_and_speed_too_high: "Using lower laser power will extends laser tube's lifetime.\nAlso, too high speed at this resolution may result in the lower quality of shading engraving.",
      bug_report: 'Bug Report',
      change_workarea_before_preview: 'The workarea of %s does not match the currently set workarea. Do you want to switch current workarea?',
      convert_to_path_fail: 'Failed to convert to path.',
      dxf_bounding_box_size_over: 'Drawing size is out of workarea. Please move your drawing closer to origin in your CAD software, or make sure that the unit is set correctly.',
      dxf_version_waring: 'The version of this DXF file is not 2013, there might be potential incompatibility problems.',
      facebook_group_invitation: {
        already_joined: 'Already Joined',
        join_now: 'Count Me In',
        later: 'Maybe Later',
        message: "Join our official Facebook group to connect with other FLUX laser users, discuss FLUX lasers, share laser artwork, and stay up to date with the latest updates on our products. We can't wait to see you there!",
        title: 'Join FLUX Official User Group',
      },
      import_file_contain_invalid_path: '#808 Imported SVG file contains invalid image path. Please make sure all image files exist or embed image in the file',
      import_file_error_ask_for_upload: 'Failed to Imported SVG file. Are you willing to provide file to develop team for bug report ?',
      layer_by_color: 'Color',
      layer_by_layer: 'Layer',
      loading_image: 'Loading image, please wait...',
      more_than_two_object: 'Too many objects. Only support for 2 objects',
      mouse: 'Mouse',
      no_support_text: 'Beam Studio does not support text tag currently. Please transfer text to path before importing.',
      nolayer: 'Single Layer',
      not_support_object_type: 'Not support object type',
      or_turn_off_borderless_mode: ' Or turn off Open Bottom mode.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Error: Command pdf2svg not found. Please install pdf2svg with your package manager (e.g., "yum install pdf2svg" or "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Error when converting PDF to SVG:',
      },
      progress: {
        calculating: 'Calculating',
        uploading: 'Uploading',
      },
      questionnaire: {
        caption: 'Help Us Fill out the Questionnaire',
        message: 'Help us fill out the questionnaire to make Beam Studio better.',
        no_questionnaire_available: 'There is no questionnaire available right now.',
        unable_to_get_url: 'Failed to get the link to the questionnaire via internet. Please confirm your internet connection.',
      },
      recommend_downgrade_software: 'We detected an older firmware version. We are actively addressing compatibility issues, but for now, we recommend reverting to <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: 'We detected an older firmware version. We are actively addressing compatibility issues, but for now, we recommend updating to the latest firmware.',
      save_unsave_changed: 'Do you want to save unsaved changes?',
      select_at_least_two: 'Select two objects to proceed',
      select_first: 'Select an object first.',
      select_import_method: 'Select layering style:',
      select_import_module: 'Select Module:',
      sentry: {
        message: 'Do you agree to automatically upload relevant information to the development team when errors occur?',
        title: "Let's make Beam Studio better together",
      },
      should_update_firmware_to_continue: '#814 Your firmware does not support this version of Beam Studio. Kindly update firmware to continue. (Menu > Machine > [Your Machine] > Update Firmware)',
      speed_too_high_lower_the_quality: 'Using too high speed at this resolution may result in the lower quality of shading engraving.',
      still_continue: 'Continue',
      successfully_uploaded: 'File upload succeeded.',
      svg_1_1_waring: 'The version of this SVG file is v 1.1, there might be potential incompatibility problems.',
      svg_image_path_waring: 'This SVG file contains <image> loading from file path. This might cause fails when loading.\nTo avoid this risk, Please kindly use embed image when exporting SVG.',
      text_to_path: {
        caption: 'Text-to-Path Converter 2.0',
        message: "Beam Studio now introduces a new text-to-path converter (Converter 2.0), which produces more dependable results! Would you like to switch to it now? \nYou can also adjust this setting later in the 'Text-to-Path Converter' section within the preferences.",
      },
      too_fast_for_auto_feeder: "Using too high speed in layers containing path objects may result in lower precision when cutting.\nWe don't recommend using speed faster than %(limit)s when cutting with Auto Feeder.",
      too_fast_for_auto_feeder_and_constrain: 'Following layers: %(layers)s \ncontain vector path objects, and have speed exceeding %(limit)s.\nThe cutting speed of vector path objects with Auto Feeder will be constrained to %(limit)s.\nYou can remove this limit at Preferences Settings.',
      too_fast_for_curve: 'Using too high speeds for layers containing curve engraving objects may reduce engraving precision.\nWe recommend keeping the speed below %(limit)s for optimal results with curve engraving objects.',
      too_fast_for_curve_and_constrain: 'Following layers: %(layers)s exceed the speed limit of %(limit)s.\nThe engrave speed for curve objects will be constrained to %(limit)s.You can remove this limit at Preferences Settings.',
      too_fast_for_path: "Using too high speed in layers containing path objects may result in lower precision when cutting.\nWe don't recommend using speed faster than %(limit)s when cutting.",
      too_fast_for_path_and_constrain: 'Following layers: %(layers)s \ncontain vector path objects, and have speed exceeding %(limit)s.\nThe cutting speed of vector path objects will be constrained to %(limit)s.\nYou can remove this limit at Preferences Settings.',
      touchpad: 'TouchPad',
      ungroup_use: 'This will ungroup imported DXF or SVG. Because the file might contain a great number of elements, it might take time to ungroup. Are you sure to proceed?',
      upload_failed: '#819 File upload failed.',
      upload_file_too_large: '#819 File is too large for upload.',
      vectorize_shading_image: 'Gradient images takes more time to trace and are prone to noise. Please kindly turn off the image gradient before executing.',
    },
    rating_panel: {
      description: 'If you like Beam Studio, we would greatly appreciate it if you could rate us.',
      thank_you: 'Thank You!',
      title: 'Enjoy Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Advanced',
        backlash: 'Backlash',
        bi_directional: 'Bi-directional',
        by: 'By',
        ce_z_high_speed: 'High-Speed Z Axis',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Channel Adjustment',
        color_adjustment_short: 'Channel',
        color_strength: 'Strength',
        cross_hatch: 'Cross hatch',
        custom_preset: 'Custom',
        cut: 'Cut',
        diode: 'Diode Laser',
        do_not_adjust_default_para: 'Presets can not be adjusted.',
        dottingTime: 'Dotting Time',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Engraving',
            acrylic_3mm_cutting: 'Acrylic - 1/8″ Cutting',
            acrylic_5mm_cutting: 'Acrylic - 3/16″ Cutting',
            acrylic_8mm_cutting: 'Acrylic - 1/4″ Cutting',
            acrylic_10mm_cutting: 'Acrylic - 3/8″ Cutting',
            acrylic_engraving: 'Acrylic - Engraving',
            acrylic_printing: 'Acrylic - Printing',
            aluminum_engraving: 'Aluminum - Engraving',
            aluminum_light: 'Aluminum (light)',
            bamboo_printing: 'Bamboo - Printing',
            black_abs: 'Black ABS',
            black_acrylic_3mm_cutting: 'Black Acrylic - 1/8″ Cutting',
            black_acrylic_5mm_cutting: 'Black Acrylic - 3/16″ Cutting',
            black_acrylic_engraving: 'Black Acrylic - Engraving',
            brass_dark: 'Brass (dark)',
            brass_engraving: 'Brass - Engraving',
            brass_light: 'Brass (light)',
            canvas_printing: 'Canvas - Printing',
            cardstock_printing: 'Cardstock - Printing',
            copper: 'Copper',
            cork_printing: 'Cork - Printing',
            denim_1mm_cutting: 'Denim - 1/32″ Cutting',
            fabric_3mm_cutting: 'Fabric - 1/8″ Cutting',
            fabric_5mm_cutting: 'Fabric - 3/16″ Cutting',
            fabric_engraving: 'Fabric - Engraving',
            fabric_printing: 'Fabric - Printing',
            flat_stone_printing: 'Flat Stone - Printing',
            glass_bw_engraving: 'Glass - Engraving',
            glass_printing: 'Glass - Printing',
            gloss_leather_printing: 'Gloss Leather - Printing',
            gold_engraving: 'Gold - Engraving',
            iron_engraving: 'Iron - Engraving',
            leather_3mm_cutting: 'Leather - 1/8″ Cutting',
            leather_5mm_cutting: 'Leather - 3/16″ Cutting',
            leather_engraving: 'Leather - Engraving',
            mdf_3mm_cutting: 'New Zealand MDF - 1/8″ Cutting',
            mdf_5mm_cutting: 'New Zealand MDF - 3/16″ Cutting',
            mdf_engraving: 'New Zealand MDF - Engraving',
            metal_bw_engraving: 'Metal - Engraving',
            opaque_acrylic: 'Opaque Acrylic',
            pc_printing: 'PC - Printing',
            rubber_bw_engraving: 'Rubber - Engraving',
            silver_engraving: 'Silver - Engraving',
            stainless_steel_bw_engraving_diode: 'Metal - Engraving (Diode Laser)',
            stainless_steel_dark: 'Stainless Steel (dark)',
            stainless_steel_engraving: 'Stainless Steel - Engraving',
            stainless_steel_light: 'Stainless Steel (light)',
            stainless_steel_printing: 'Stainless Steel - Printing',
            steel_engraving_spray_engraving: 'Metal - Engraving',
            stone: 'Stone',
            ti_engraving: 'Titanium - Engraving',
            titanium_dark: 'Titanium (dark)',
            titanium_light: 'Titanium (light)',
            white_abs: 'White ABS',
            wood_3mm_cutting: 'Wood - 1/8″ Cutting',
            wood_5mm_cutting: 'Wood - 3/16″ Cutting',
            wood_7mm_cutting: 'Wood - 1/4″ Cutting',
            wood_8mm_cutting: 'Wood - 1/4″ Cutting',
            wood_10mm_cutting: 'Wood - 3/8″ Cutting',
            wood_engraving: 'Wood - Engraving',
            wood_printing: 'Wood - Printing',
          },
          mm: {
            abs_engraving: 'ABS - Engraving',
            acrylic_3mm_cutting: 'Acrylic - 3mm Cutting',
            acrylic_5mm_cutting: 'Acrylic - 5mm Cutting',
            acrylic_8mm_cutting: 'Acrylic - 8mm Cutting',
            acrylic_10mm_cutting: 'Acrylic - 10mm Cutting',
            acrylic_engraving: 'Acrylic - Engraving',
            acrylic_printing: 'Acrylic - Printing',
            aluminum_engraving: 'Aluminum - Engraving',
            aluminum_light: 'Aluminum (light)',
            bamboo_printing: 'Bamboo - Printing',
            black_abs: 'Black ABS',
            black_acrylic_3mm_cutting: 'Black Acrylic - 3mm Cutting',
            black_acrylic_5mm_cutting: 'Black Acrylic - 5mm Cutting',
            black_acrylic_engraving: 'Black Acrylic - Engraving',
            brass_dark: 'Brass (dark)',
            brass_engraving: 'Brass - Engraving',
            brass_light: 'Brass (light)',
            canvas_printing: 'Canvas - Printing',
            cardstock_printing: 'Cardstock - Printing',
            copper: 'Copper',
            cork_printing: 'Cork - Printing',
            denim_1mm_cutting: 'Denim - 1mm Cutting',
            fabric_3mm_cutting: 'Fabric - 3mm Cutting',
            fabric_5mm_cutting: 'Fabric - 5mm Cutting',
            fabric_engraving: 'Fabric - Engraving',
            fabric_printing: 'Fabric - Printing',
            flat_stone_printing: 'Flat Stone - Printing',
            glass_bw_engraving: 'Glass - Engraving',
            glass_printing: 'Glass - Printing',
            gloss_leather_printing: 'Gloss Leather - Printing',
            gold_engraving: 'Gold - Engraving',
            iron_engraving: 'Iron - Engraving',
            leather_3mm_cutting: 'Leather - 3mm Cutting',
            leather_5mm_cutting: 'Leather - 5mm Cutting',
            leather_engraving: 'Leather - Engraving',
            mdf_3mm_cutting: 'New Zealand MDF - 3mm Cutting',
            mdf_5mm_cutting: 'New Zealand MDF - 5mm Cutting',
            mdf_engraving: 'New Zealand MDF - Engraving',
            metal_bw_engraving: 'Metal - Engraving',
            opaque_acrylic: 'Opaque Acrylic',
            pc_printing: 'PC - Printing',
            rubber_bw_engraving: 'Rubber - Engraving',
            silver_engraving: 'Silver - Engraving',
            stainless_steel_bw_engraving_diode: 'Metal - Engraving (Diode Laser)',
            stainless_steel_dark: 'Stainless Steel (dark)',
            stainless_steel_engraving: 'Stainless Steel - Engraving',
            stainless_steel_light: 'Stainless Steel (light)',
            stainless_steel_printing: 'Stainless Steel - Printing',
            steel_engraving_spray_engraving: 'Metal - Engraving',
            stone: 'Stone',
            ti_engraving: 'Titanium - Engraving',
            titanium_dark: 'Titanium (dark)',
            titanium_light: 'Titanium (light)',
            white_abs: 'White ABS',
            wood_3mm_cutting: 'Wood - 3mm Cutting',
            wood_5mm_cutting: 'Wood - 5mm Cutting',
            wood_7mm_cutting: 'Wood - 7mm Cutting',
            wood_8mm_cutting: 'Wood - 8mm Cutting',
            wood_10mm_cutting: 'Wood - 10mm Cutting',
            wood_engraving: 'Wood - Engraving',
            wood_printing: 'Wood - Printing',
          },
          parameters: 'Presets',
          save: 'Add current parameters',
        },
        engrave: 'Engrave',
        existing_name: 'This name has been used.',
        fill_angle: 'Fill Angle',
        fill_interval: 'Fill Interval',
        fill_setting: 'Fill Setting',
        filled_path_only: 'For fill paths only',
        focus_adjustment: 'Focus Adjustment',
        frequency: 'Frequency',
        gradient_only: 'For gradient images only',
        halftone: 'Halftone',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Object Height',
        ink_saturation: 'Saturation',
        ink_type: {
          normal: 'Ink',
          text: 'Ink Type',
          UV: 'UV Ink',
        },
        laser_speed: {
          fast: 'Fast',
          max: 300,
          min: 3,
          slow: 'Slow',
          step: 0.1,
          text: 'Speed',
          unit: 'mm/s',
        },
        low_power_warning: 'Lower laser power (under 10%) might not emit the laser light.',
        low_speed_warning: 'Low speed might cause burning of the material.',
        lower_focus: 'Lower Focus',
        lower_focus_desc: 'Lowering the focus height by a specific distance after focusing to improve cutting performance.',
        module: 'Module',
        more: 'Manage',
        multi_layer: 'Multiple Layers',
        para_in_use: 'This parameter is being used.',
        parameters: 'Parameters',
        power: {
          high: 'High',
          low: 'Low',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Power',
        },
        preset_management: {
          add_new: 'Add New',
          delete: 'Delete',
          export: 'Export',
          export_preset_title: 'Export Presets',
          import: 'Import',
          laser: 'Laser',
          lower_focus_by: 'Lower focus by',
          new_preset_name: 'New Preset Name',
          preset: 'Preset',
          print: 'Print',
          reset: 'Reset',
          save_and_exit: 'Save and Exit',
          show_all: 'Show All',
          sure_to_import_presets: 'This will load arrangement of presets and override customized parameters, are you sure to proceed?',
          sure_to_reset: 'This will delete your customized parameters and reset all presets, are you sure to proceed?',
          title: 'Manage Parameters',
          wobble_diameter: 'Wobble Diameter',
          wobble_step: 'Wobble Step Size',
        },
        preset_setting: 'Parameter Settings (%s)',
        presets: 'Preset',
        print_multipass: 'Multi-pass',
        promark_speed_desc: 'Speed parameters are not applicable to gradient images.',
        pulse_width: 'Pulse Width',
        pwm_advanced_desc: 'Set a minimum power for Depth Mode.',
        pwm_advanced_hint: 'This setting applies specifically to gradient images when using Depth Mode.',
        pwm_advanced_setting: 'Depth Mode Power Settings',
        repeat: 'Pass Count',
        single_color: 'Single Color',
        single_color_desc: 'Only applicable to full color layer and cannot be used with expanded single color layer.',
        slider: {
          fast: 'Fast',
          high: 'High',
          low: 'Low',
          regular: 'Regular',
          slow: 'Slow',
          very_fast: 'Fastest',
          very_high: 'Max',
          very_low: 'Min',
          very_slow: 'Slowest',
        },
        speed: 'Speed',
        speed_constrain_warning: 'The cutting speed of vector path objects will be constrained to %(limit)s. You can remove this limit at Preferences Settings.',
        speed_constrain_warning_auto_feeder: 'The cutting speed of Auto Feeder objects will be constrained to %(limit)s. You can remove this limit at Preferences Settings.',
        speed_constrain_warning_curve_engraving: 'The speed of curve engraving objects will be constrained to %(limit)s. You can remove this limit at Preferences Settings.',
        stepwise_focusing: 'Stepwise Focusing',
        stepwise_focusing_desc: "Gradually lowering the focus distance based on the object's height during each pass count.",
        strength: 'Power',
        times: 'times',
        various_preset: 'Various Presets',
        white_ink: 'White Ink',
        white_ink_settings: 'White Ink Settings',
        wobble: 'Wobble',
        wobble_desc: 'Create tiny circles along the path, which is beneficial for the cutting tasks. (For path objects only.)',
        wobble_diameter: 'Diameter',
        wobble_step: 'Step size',
        z_step: 'Z Step',
      },
      layer_panel: {
        current_layer: 'Current Layer',
        layer1: 'Layer 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Cutting',
        layer_engraving: 'Engraving',
        layers: {
          del: 'Delete Layer',
          dupe: 'Duplicate Layer',
          fullColor: 'Full color',
          layer: 'Layer',
          layers: 'Layers',
          lock: 'Lock Layer',
          merge_all: 'Merge All',
          merge_down: 'Merge Down',
          merge_selected: 'Merge Selected Layers',
          move_down: 'Move Layer Down',
          move_elems_to: 'Move elements to:',
          move_selected: 'Move selected elements to a different layer',
          move_up: 'Move Layer Up',
          new: 'New Layer',
          rename: 'Rename Layer',
          splitFullColor: 'Expand Layer',
          switchToFullColor: 'Switch to full color layer',
          switchToSingleColor: 'Switch to single color layer',
          unlock: 'Unlock',
        },
        move_elems_to: 'Move elements to:',
        notification: {
          dupeLayerName: 'There is already a layer named that!',
          enterNewLayerName: 'Please enter the new layer name',
          enterUniqueLayerName: 'Please enter a unique layer name',
          layerHasThatName: 'Layer already has that name',
          mergeLaserLayerToPrintingLayerMsg: 'Please be aware that if you complete this operation, the settings of the laser layer will be removed and set according to the current layer.',
          mergeLaserLayerToPrintingLayerTitle: 'Do you want to merge these layers into one Printing layer?',
          mergePrintingLayerToLaserLayerMsg: 'Please be aware that if you complete this operation, the color settings of the printing layer will be removed and set according to the current layer.',
          mergePrintingLayerToLaserLayerTitle: 'Do you want to merge these layers into one Laser layer?',
          moveElemFromPrintingLayerMsg: 'Please note that if you complete this operation, the color settings of the selected element will be removed and set according to %s.',
          moveElemFromPrintingLayerTitle: 'Move selected element to %s and convert it into laser element?',
          moveElemToPrintingLayerMsg: 'Please note that if you complete this operation, the settings of the selected element will be removed and set according to %s.',
          moveElemToPrintingLayerTitle: 'Move selected element to %s and convert it into printing element?',
          newName: 'NEW NAME',
          QmoveElemsToLayer: "Move selected elements to layer '%s'?",
          splitColorMsg: 'Please notice that if you continue with this operation, you will not be able to fall back to the original color layers.',
          splitColorTitle: 'Do you want to expand the selected layer into CMYK layers?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Background Removal',
          ai_bg_removal_reminder: 'Pressing the button will immediately use 0.2 Credit, do you want to continue?',
          ai_bg_removal_short: 'BG Removal',
          array: 'Array',
          auto_fit: 'Auto Fit',
          bevel: 'Bevel',
          brightness: 'Brightness',
          convert_to_path: 'Convert to Path',
          create_textpath: 'Create Text on Path',
          create_textpath_short: 'Path Text',
          crop: 'Crop',
          decompose_path: 'Decompose',
          detach_path: 'Decompose Text on Path',
          detach_path_short: 'Decompose',
          disassemble_use: 'Disassemble',
          disassembling: 'Disassembling...',
          edit_path: 'Edit Path',
          fetching_web_font: 'Getting the online font...',
          grading: 'Grading',
          invert: 'Invert',
          offset: 'Offset',
          outline: 'Outline',
          replace_with: 'Replace With...',
          replace_with_short: 'Replace',
          sharpen: 'Sharpen',
          simplify: 'Simplify',
          smart_nest: 'Smart Nest',
          trace: 'Trace',
          ungrouping: 'Ungrouping...',
          uploading_font_to_machine: 'Uploading font to the machine...',
          wait_for_parsing_font: 'Parsing font... Please wait a second',
          weld_text: 'Weld Text',
        },
        align: 'Align',
        boolean: 'Boolean',
        bottom_align: 'Bottom Align',
        center_align: 'Center Align',
        difference: 'Difference',
        distribute: 'Distribute',
        flip: 'Flip',
        group: 'Group',
        hdist: 'Horizontal Distribute',
        hflip: 'Horizontal Flip',
        intersect: 'Intersect',
        left_align: 'Left Align',
        lock_aspect: 'Lock Aspect',
        middle_align: 'Middle Align',
        option_panel: {
          color: 'Color',
          fill: 'Infill',
          font_family: 'Font',
          font_size: 'Size',
          font_style: 'Style',
          letter_spacing: 'Letter spacing',
          line_spacing: 'Line spacing',
          path_infill: 'Path Infill',
          pwm_engraving: 'Depth Mode',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Rounded corner',
          shading: 'Gradient',
          sides: 'Sides',
          start_offset: 'Text Offset',
          stroke: 'Stroke',
          stroke_color: 'Stroke Color',
          stroke_width: 'Stroke Width',
          text_infill: 'Text Infill',
          threshold: 'Threshold brightness',
          threshold_short: 'Threshold',
          vertical_align: 'Alignment',
          vertical_text: 'Vertical text',
        },
        path_edit_panel: {
          connect: 'Connect',
          delete: 'Delete',
          disconnect: 'Disconnect',
          node_type: 'NODE TYPE',
          round: 'Round',
          sharp: 'Sharp',
        },
        right_align: 'Right Align',
        subtract: 'Subtract',
        top_align: 'Top Align',
        ungroup: 'Ungroup',
        union: 'Union',
        unlock_aspect: 'Unlock Aspect',
        vdist: 'Vertical Distribute',
        vflip: 'Vertical Flip',
        zoom: 'Zoom',
      },
      tabs: {
        layers: 'Layers',
        objects: 'Objects',
        path_edit: 'Path Edit',
      },
      uv_print_block: {
        title: 'UV Print',
      },
    },
    shapes_panel: {
      animals: 'Animal',
      arrow: 'Arrow',
      basic: 'Basic',
      birds: 'Bird',
      celebration: 'Celebration',
      circular: 'Circular',
      CNY: 'Chinese New Year',
      corner: 'Corner',
      decor: 'Decor',
      easter: 'Easter',
      elements: 'Elements',
      environment: 'Environment',
      graphics: 'Graphics',
      halloween: 'Halloween',
      holidays: 'Holiday',
      label: 'Label',
      land: 'Land',
      line: 'Line',
      nature: 'Nature',
      photo: 'Photo',
      plants: 'Plant',
      ribbon: 'Ribbon',
      sea: 'Sea',
      shape: 'Shape',
      speech: 'Speech',
      text: 'Text Frame',
      title: 'Elements',
      valentines: 'Valentines',
      weather: 'Weather',
      Xmas: 'Christmas',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Failed to fetch image from your clipboard',
      unnsupported_file_type: 'The file type is not directly supported. Please convert the file into SVG or bitmap',
    },
    tag: {
      g: 'Group',
      image: 'Image',
      text: 'Text',
      use: 'Import SVG',
    },
    time_est_button: {
      calculate: 'Estimate time',
      estimate_time: 'Estimated Time:',
    },
    tool_panels: {
      _nest: {
        end: 'Close',
        no_element: 'There is no element to arrange.',
        rotations: 'Possible Rotation',
        spacing: 'Spacing',
        start_nest: 'Arrange',
        stop_nest: 'Stop',
      },
      _offset: {
        corner_type: 'Corner',
        direction: 'Offset Direction',
        dist: 'Offset Distance',
        fail_message: 'Failed to Offset Objects.',
        inward: 'Inward',
        not_support_message: 'Selected elements containing unsupported SVG tag：\nImage, Group, Text and Imported Object.',
        outward: 'Outward',
        round: 'Round',
        sharp: 'Sharp',
      },
      array_dimension: 'Array Dimension',
      array_interval: 'Array Interval',
      cancel: 'Cancel',
      columns: 'Cols.',
      confirm: 'Confirm',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Create Grid Array',
      nest: 'Arrangement Optimization',
      offset: 'Offset',
      rows: 'Rows',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Fit to Window',
    },
  },
  boxgen: {
    add_option: 'Add Option',
    basic_box: 'Basic Box',
    beam_radius: 'Kerf compensation',
    beam_radius_warning: 'Remove Kerf compensation when the edges or joints of the box are short to ensure box assembly',
    cancel: 'Cancel',
    coming_soon: 'Coming Soon',
    continue_import: 'Continue to Import',
    control_tooltip: 'Left mouse to rotate\nScroll to zoom\nRight mouse to pan',
    control_tooltip_touch: 'Drag to rotate\nPinch to zoom\nTwo-finger to pan',
    cover: 'Cover',
    customize: 'Customize',
    depth: 'Depth',
    edge: 'Edge',
    finger: 'Finger',
    finger_warning: 'The internal side length of the box need to be at least 6mm (0.24 inches)  to be compatible with Finger joining.',
    height: 'Height',
    import: 'Import',
    inner: 'Inner',
    joints: 'Joint',
    max_dimension_tooltip: 'Max width/height/depth setting is %s. ',
    merge: 'Merge',
    outer: 'Outer',
    reset: 'Reset',
    tCount: 'T Count',
    tCount_tooltip: 'The T slot count applies to the short side; the quantity on the long side is calculated based on its length.',
    tDiameter: 'T Diameter',
    text_label: 'Label',
    thickness: 'Thickness',
    title: 'BOXGEN',
    tLength: 'T Length',
    tSlot: 'T-Slot',
    tSlot_warning: 'The box side length needs to be at least 30mm (1.18 inches) to be compatible with T-slot joining.',
    volume: 'Volume',
    width: 'Width',
    workarea: 'Workarea',
    zoom: 'Zoom',
  },
  buttons: {
    back: 'Back',
    back_to_beam_studio: 'Back to Beam Studio',
    done: 'Done',
    next: 'Next',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Press the "AF" icon on the main page of the machine for 3 seconds and let the probe touch the Focusing block.',
    ador_autofocus_material: 'Press the “AF” icon on the main page of the machine for 3 seconds and let the probe touch the material softly.',
    align_ilb: 'Position: Align with the engraved point on the inner bottom left.',
    align_ilt: 'Position: Align with the engraved point on the inner top left.',
    align_irb: 'Position: Align with the engraved point on the inner bottom right.',
    align_irt: 'Position: Align with the engraved point on the inner top right.',
    align_olb: 'Position: Align with the engraved point on the outer bottom left.',
    align_olt: 'Position: Align with the engraved point on the outer top left.',
    align_orb: 'Position: Align with the engraved point on the outer bottom right.',
    align_ort: 'Position: Align with the engraved point on the outer top right.',
    align_red_cross_cut: 'Please align the middle of the red cross with the cut cross.',
    align_red_cross_print: 'Please align the middle of the red cross with the printed cross.',
    analyze_result_fail: 'Fail to analyze captured image.<br/>Please make sure:<br/>1. Captured picture fully covered with white paper.<br/>2. The platform is focus properly.',
    ask_for_readjust: 'Do your want to skip the engraving step and then take photo and calibrate directly?',
    back: 'Back',
    calculating_camera_matrix: 'Calculating Camera Matrix...',
    calculating_regression_parameters: 'Calculating Regression Parameters...',
    calibrate_camera_before_calibrate_modules: 'Please perform camera calibration before calibrating modules.',
    calibrate_chessboard_success_msg: 'Successfully captured the checkerboard photo.<br/>The result for this photo is %s (%.2f).',
    calibrate_done: 'Calibration done. Better camera accuracy is given when focus precisely.',
    calibrate_done_diode: 'Calibration done. Offset of diode laser module has been saved.',
    calibrating: 'Calibrating...',
    calibrating_with_device_pictures: 'Calibrating with device pictures...',
    camera_calibration: 'Camera Calibration',
    camera_parameter_saved_successfully: 'Camera parameter saved successfully.',
    cancel: 'Cancel',
    check_checkpoint_data: 'Checkpoint Data',
    check_device_pictures: 'Check Device Pictures',
    checking_checkpoint: 'Checking checkpoint data...',
    checking_pictures: 'Checking device pictures...',
    diode_calibration: 'Diode Laser Module Calibration',
    do_engraving: 'Do Engraving',
    download_chessboard_file: 'Download chessboard file',
    downloading_checkpoint: 'Downloading checkpoint data...',
    downloading_pictures: 'Downloading Pictures...',
    drawing_calibration_image: 'Drawing calibration image...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Elevate and Cut',
    elevate_and_cut_step_1: 'Place an A4 or Letter size light-colored wooden in the workspace center, elevated to 20mm.',
    elevate_and_cut_step_1_prism_lift: 'Use Ador Prism Lift at 14mm max length with a wooden at least 6mm thick.',
    failed_to_calibrate_camera: '#848 Failed to calibrate camera, please contact FLUX Support.',
    failed_to_calibrate_chessboard: 'Failed to calibrate with the chessboard picture.',
    failed_to_calibrate_with_pictures: '#848 Failed to calibrate with device pictures.',
    failed_to_download_pictures: '#848 Failed to download pictures, please contact FLUX Support.',
    failed_to_move_laser_head: 'Failed to move the laser head.',
    failed_to_parse_checkpoint: 'Failed to parse the checkpoint data.',
    failed_to_save_calibration_results: '#849 Failed to save calibration results, please try again. If this keeps happening, please contact FLUX Support.',
    failed_to_save_camera_parameter: 'Failed to save camera parameter.',
    failed_to_solve_pnp: 'Failed to solve camera position.',
    finish: 'Done',
    found_checkpoint: 'Checkpoint data is found in your device. Do you want to recover from the checkpoint?',
    getting_plane_height: 'Getting Plane Height...',
    hint_adjust_parameters: 'Use these parameters to adjust the red square',
    hint_red_square: 'Please align the outside of the red square with the cut square.',
    module_calibration_2w_ir: 'Infrared Module Calibration',
    module_calibration_printer: 'Printer Module Calibration',
    moving_laser_head: 'Moving the laser head...',
    next: 'Next',
    no_picutre_found: '#846 Your device does not have any available raw photos for calibration. Please contact FLUX support.',
    perform_autofocus_bb2: 'Please go to the machine control panel and press AF to perform autofocus.',
    please_do_camera_calibration_and_focus: {
      beambox: 'When calibrating diode laser module, camera is needed.\nPlease make sure camera of this machine has been calibrated.\nAnd kindly adjust the platform to the focal point (the height of turned down acrylic)',
      beamo: 'When calibrating diode laser module, camera is needed.\nPlease make sure camera of this machine has been calibrated.\nAnd kindly adjust the laser head to focus on the engraving object (the height of turned down acrylic)',
    },
    please_goto_beambox_first: 'Please switch to Engraving Mode ( Beambox ) in order to use this feature.',
    please_place_dark_colored_paper: 'Please place an A4 or Letter size dark-colored paper in the center of the workarea.',
    please_place_paper: 'Please place an A4 or Letter size white paper at left-top corner of workarea.',
    please_place_paper_center: 'Please place an A4 or Letter size white paper at the center of workarea.',
    please_refocus: {
      beambox: 'Kindly adjust the platform to the focal point (the height of turned down acrylic)',
      beamo: 'Kindly adjust the laser head to focus on the engraving object (the height of turned down acrylic)',
      beamo_af: 'Kindly double-tap the side button of the autofocus add-on and let the probe touch the material softly.',
      hexa: 'Double click the height adjustment button to rise the honeycomb table up and make the probe touch the engraving material.',
    },
    preparing_to_take_picture: 'Preparing to take picture...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Place Chessboard',
    put_chessboard_1: 'Please go to the machine control panel and press AF to perform autofocus.',
    put_chessboard_2: 'Please move the chessboard paper or the laser head until the entire chessboard is confirmed to be within the red frame in the live preview window.',
    put_chessboard_3: 'Right-click to download the checkerboard image at the current location and check its clarity.',
    put_chessboard_bb2_desc_1: 'Please download the following chessboard file and print it on A4 or letter size paper (the printed chessboard should have 1x1 cm squares).',
    put_chessboard_bb2_desc_2: 'Secure the printed chessboard paper onto a non-deformable board, such as acrylic or glass, ensuring that the chessboard is smooth and free of wrinkles or lifting.',
    put_chessboard_bb2_desc_3: 'Place the board with the chessboard flatly at the center of the work area.',
    put_chessboard_promark_1: 'Adjust the focal distance to the appropriate focus for the field lens.',
    put_chessboard_promark_2: "Please ensure the checkerboard paper is clearly visible without glare. Click 'Next' to take a photo.",
    put_chessboard_promark_desc_1: 'Please use the checkerboard paper provided in the accessory box, or print the following checkerboard pattern on A4 paper for camera calibration.',
    put_chessboard_promark_desc_2: 'Please place the checkerboard flat and centered on the work platform',
    put_paper: 'Put Paper',
    put_paper_promark_1: 'Place the black cardboard from the accessory box on the work platform.',
    put_paper_promark_2: 'Adjust the focus properly, and then click ‘Start Engrave’ to proceed with engraving.',
    put_paper_skip: 'If the calibration drawing does not automatically proceed to taking picture, press "Skip" to take picture.',
    put_paper_step1: 'Please place an A4 or Letter size white paper at the center of workarea.',
    put_paper_step2: 'Secure the four corners of the paper to ensure it lays flat.',
    put_paper_step3: 'Click "START ENGRAVE"',
    res_average: 'Average',
    res_excellent: 'Excellent',
    res_poor: 'Poor',
    retake: 'Retake Picture',
    rotation_angle: 'Rotation',
    show_last_config: 'Show Last Result',
    skip: 'Skip',
    solve_pnp_step1: 'Please align the engraving points according to the number and expected position of each red marker point.',
    solve_pnp_step2: 'You can press "Retake Picture" to realign or manually adjust the marker positions.',
    solve_pnp_step3: 'If the captured image does not match the actual scene, please zoom or drag the screen to adjust the calibration point on the black calibration card to the center, then try to "Retake Picture".',
    solve_pnp_title: 'Align Marker Points',
    start_engrave: 'Start Engrave',
    start_printing: 'Start Printing',
    taking_picture: 'Taking Picture...',
    unable_to_load_camera_parameters: "#851 No camera calibration parameters are available on your device. Go to 'Calibration' > 'Calibrate Camera (Advanced)' to complete the calibration and obtain the parameters.",
    update_firmware_msg1: 'Your firmware does not support this function. Please update firmware to v',
    update_firmware_msg2: 'or above to continue。 (Menu > Machine > [Your Machine] > Update Firmware)',
    uploading_images: 'Uploading Images...',
    use_last_config: 'Use Last Calibration Value',
    use_old_camera_parameter: 'Do you want to use current camera lens parameter?',
    with_af: 'With Autofocus',
    with_prism_lift: 'With Ador Prism Lift',
    without_af: 'Without Autofocus',
    without_prism_lift: 'Without Ador Prism Lift',
    x_ratio: 'X Ratio',
    y_ratio: 'Y Ratio',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Successfully downloaded camera data.',
    downloading_data: 'Downloading data...',
    estimated_time_left: 'Estimated time left:',
    folder_not_exists: 'Selected folder does not exist.',
    incorrect_folder: 'Failed to upload of calibration data. Please check if the folder you selected is correct.',
    no_picture_found: 'No picture found in the machine.',
    title: 'Camera Data Backup',
    upload_success: 'Successfully uploaded camera data.',
    uploading_data: 'Uploading data...',
  },
  caption: {
    connectionTimeout: 'Connection timeout',
  },
  change_logs: {
    added: 'Added:',
    change_log: 'Change Logs:',
    changed: 'Changed:',
    fixed: 'Fixed:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'See Older Versions',
  },
  code_generator: {
    barcode: 'Barcode',
    qr_code: 'QR Code',
  },
  curve_engraving: {
    '921': 'Failed to auto focus.',
    '922': 'Failed to perform red-light measurement.',
    amount: 'Amount',
    apply_arkwork: 'Apply Artwork on Curve',
    apply_camera: 'Apply Camera Image on Curve',
    click_to_select_point: 'Click to select or unselect points to measure again.',
    coloumns: 'Columns',
    column_gap: 'Column Gap',
    err_object_over_range: 'The object exceeds the measurment range.',
    failed_to_take_reference: 'Failed to take reference',
    gap: 'Gap',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Measure Autofocus Area',
    preview_3d_curve: 'Preview Curve Engraving',
    remeasure: 'Re-measure',
    remeasuring_points: 'Remeasuring Points...',
    reselect_area: 'Re-select Area',
    row_gap: 'Row Gap',
    rows: 'Rows',
    set_object_height: 'Set Object Height',
    set_object_height_desc: 'Measure the max thickness of the object.',
    start_autofocus: 'Start Autofocus',
    starting_measurement: 'Starting Measurement...',
    sure_to_delete: 'Do you want to delete the curve engraving focus data?',
    take_reference: 'Take Reference',
    take_reference_desc: 'Please move the laser head to the highest point of the object, lower the focus probe and click "Confirm" to focus.',
  },
  device: {
    abort: 'Abort',
    aborted: 'Aborted',
    aborting: 'Aborting',
    busy: 'Busy',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Completed',
    completing: 'Completing',
    deviceList: 'Machine List',
    disable: 'Disable',
    disconnectedError: {
      caption: 'Machine disconnected',
      message: 'Please confirm if network access of %s is available',
    },
    enable: 'Enable',
    firmware_version: 'Firmware Version',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Model Name',
    occupied: 'Maintaining',
    pause: 'Pause',
    paused: 'Paused',
    pausedFromError: 'Paused from error',
    pausing: 'Pausing',
    please_wait: 'Please Wait...',
    preparing: 'Preparing',
    processing: 'Processing',
    quit: 'Quit',
    ready: 'Ready',
    reset: 'Reset (Kick)',
    resuming: 'Resuming',
    retry: 'Retry',
    running: 'Working',
    scanning: 'Scanning',
    select: 'Select',
    select_printer: 'Select Printer',
    serial_number: 'Serial Number',
    start: 'Start',
    starting: 'Starting',
    status: 'Status',
    submodule_type: 'Module',
    toolhead_change: 'Change Toolhead',
    unknown: 'Unknown',
    uploading: 'Uploading',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 We can\'t find your machine on the network.\nKindly follow the <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guide</a> to troubleshoot the connection problem.',
    no_device_web: '#801 Please check the status of your machine, or click on ‘Machine Setup’ below to set up machine.',
    select_usb_device: 'Select USB Device',
  },
  editor: {
    exposure: 'Preview Exposure',
    opacity: 'Preview Opacity',
    prespray_area: 'Priming Area',
  },
  error_pages: {
    screen_size: 'Please note that Beam Studio may not work optimally on your device. For an optimal experience, it should be a device with a screen width of at least 1024 pixels width.',
  },
  flux_id_login: {
    connection_fail: '#847 Failed to connect to FLUX member service.',
    email: 'Email',
    flux_plus: {
      access_monotype_feature: 'You do not have Monotype Fonts Add-on.',
      access_monotype_feature_note: 'You must have FLUX+ Pro membership or Monotype Fonts Add-on to access this feature.',
      access_plus_feature_1: 'You are accessing a',
      access_plus_feature_2: 'feature.',
      access_plus_feature_note: 'You must have FLUX+ membership to access this feature.',
      ai_credit_tooltip: 'For AI background removal',
      explore_plans: 'Explore FLUX+ Plans',
      features: {
        ai_bg_removal: 'AI Background Removal',
        boxgen: '3D Box Generator',
        dmkt: '1000+ Design Files',
        monotype: '250+ Premium Fonts',
        my_cloud: 'Unlimited Cloud Storage',
      },
      flux_credit_tooltip: 'For Design Market files and AI background removal',
      get_addon: 'Get Add-on',
      goto_member_center: 'Go to Member Center',
      learn_more: 'Learn More',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Subscribe now',
      thank_you: 'Thanks for being a valued member!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Forgot Password?',
    incorrect: 'Email address or password is not correct.',
    login: 'Sign In',
    login_success: 'You’re signed in.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'New to FLUX? Create an account.',
    not_verified: 'The email address has not been verified yet.',
    offline: 'Work Offline',
    password: 'Password',
    register: 'Create Your FLUX Account',
    remember_me: 'Remember me',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Login to unlock the shapes database.',
    work_offline: 'Work Offline',
  },
  framing: {
    area_check: 'Area Check',
    areacheck_desc: "Ensures the work area is safe by previewing the object's bounding box and the laser head's acceleration zone.",
    calculating_task: 'Calculating task...',
    framing: 'Framing',
    framing_desc: "Previews the object's bounding box.",
    hull: 'Hull',
    hull_desc: 'Previews a shape that closely follows the design, like a rubber band wrapped around the object.',
    low_laser: 'Low Laser',
    low_laser_desc: 'Set a low-power laser value for the framing task.',
    rotate_axis: 'Rotate Axis',
    rotateaxis_desc: 'Preview the rotation axis center, the axis itself does not move.',
    rotation_frame_warning: 'The frame preview will rotate once. Avoid stopping midway to prevent marking position misalignment.',
    rotation_framing_desc: 'Preview the full rotation range, the axis rotates according to the preview.',
    start_task: 'Start Task',
    start_task_description: 'After completing the frame preview, click "Start Task" to submit the job.',
  },
  general: {
    choose_folder: 'Choose Folder',
    processing: 'Processing...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] A status conflict occurred, please retry the action.',
    SUBSYSTEM_ERROR: '[SE] Error when machine firmware executing the task. Please restart the machine.',
    UNKNOWN_COMMAND: '[UC] Please update the Device Firmware',
    UNKNOWN_ERROR: '[UE] Unknown error occurred. Please restart Beam Studio and the machine.',
  },
  global: {
    apply: 'Apply',
    back: 'Back',
    cancel: 'Cancel',
    editing: {
      redo: 'Redo',
      reset: 'Reset',
      undo: 'Undo',
      zoom_in: 'Zoom In',
      zoom_out: 'Zoom Out',
    },
    mode_conflict: 'This option is not available in the current mode.',
    ok: 'OK',
    save: 'Save',
    stop: 'Stop',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Brush Size',
      description: 'Click or drag to manually erase unwanted areas.',
      title: 'Eraser',
    },
    magic_wand: {
      description: 'Select and remove adjacent areas based on color similarity.',
      title: 'Magic Wand',
      tolerance: 'Tolerance',
    },
    title: 'Edit Image',
  },
  initialize: {
    back: 'Back',
    cancel: 'Cancel',
    confirm: 'Confirm',
    connect: 'Connect',
    connect_ethernet: {
      title: 'Direct Connection',
      tutorial1: '1. Connect the machine with your computer with ethernet cable.',
      tutorial2_1: '2. Follow ',
      tutorial2_2: ' to make your computer as a router.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'this guide',
      tutorial3: '3. Click Next.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: `Unable to connect to server. Please restart Beam Studio and try again.`,
      },
      check_camera: 'Checking camera availability',
      check_connection: 'Checking Machine Connection',
      check_firmware: 'Checking firmware version',
      check_ip: 'Checking IP availability',
      check_swiftray_connection: 'Checking server connection',
      check_swiftray_connection_unreachable: 'server unreachable',
      check_usb: 'Check USB Connection',
      enter_ip: 'Enter Your Machine IP',
      finish_setting: 'Start Creating!',
      invalid_format: 'Invalid format',
      invalid_ip: 'IP Invalid: ',
      promark_hint: 'If you repeatedly fail to connect via USB, please refer to the <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">Help Center article</a>.',
      retry: 'Retry',
      starts_with_169254: 'Starts with 169.254',
      succeeded_message: 'Successfully connected 🎉',
      unreachable: 'IP unreachable',
    },
    connect_usb: {
      connect_camera: "Connect the machine's camera to your computer with USB cable.",
      title: 'USB Connection',
      title_sub: ' (HEXA & Ador Only)',
      turn_off_machine: 'Turn off the machine.',
      turn_on_machine: 'Turn on the machine.',
      tutorial1: 'Connect the machine to your computer with USB cable.',
      tutorial2: "Click 'Next'.",
      wait_for_turning_on: "Click 'Next' after the startup process is complete and you have entered the main screen.",
    },
    connect_wifi: {
      title: 'Connecting to Wi-Fi',
      tutorial1: '1. Go to Touch Panel > Click "Network" > "Connect to WiFi".',
      tutorial1_ador: '1. Go to Touch Panel > Click "MACHINE" > Click "Network" > "Connect to WiFi".',
      tutorial2: '2. Select and connect your preferred Wi-Fi.',
      what_if_1: "What if I don't see my Wi-Fi?",
      what_if_1_content: '1. The Wi-Fi encryption mode should be WPA2 or no password.\n2. The encryption mode can be set in the Wi-Fi router administration interface. If the router doesn’t support WPA2 and you need help picking out the right router, please contact FLUX Support.',
      what_if_2: "What if I don't see any Wi-Fi?",
      what_if_2_content: '1. Make sure the Wi-Fi dongle is fully plugged in.\n2. If there is no MAC Address of the wireless network on the touchscreen, please contact FLUX Support.\n3. The Wi-Fi channel should be 2.4Ghz (5Ghz is not supported).',
    },
    connect_wired: {
      title: 'Connecting to Wired Network',
      tutorial1: '1. Connect the machine with your router.',
      tutorial2: '2. Press "Network" to get the wired network IP.',
      tutorial2_ador: '2. Press "MACHINE" > "Network" to get the wired network IP.',
      what_if_1: 'What if the IP is empty?',
      what_if_1_content: '1. Make sure the Ethernet Cable is fully plugged in.\n2. If there is no MAC Address of the wired network on the touchscreen, please contact FLUX Support.',
      what_if_2: 'What if the IP starts with 169?',
      what_if_2_content: '1. If the IP address starts with 169.254, it should be a DHCP setting issue, please contact your ISP (internet service provider) for further assistance.\n2. If your computer connects to the internet directly using PPPoE, please change to using the router to connect using PPPoE, and enable DHCP feature in the router.',
    },
    connecting: 'Connecting...',
    connection_types: {
      ether2ether: 'Direct Connection',
      usb: 'USB Connection',
      wifi: 'Wi-Fi',
      wired: 'Wired Network',
    },
    next: 'Next',
    no_machine: "I don't have a machine now.",
    promark: {
      configuration_confirmation: 'This ensures your Promark is properly configured for optimal efficiency and accuracy.',
      or_complete_later: `Or, skip this step and complete the Promark Settings later at:
Machines > ‘Promark Name’ > Promark Settings`,
      qc_instructions: 'Fill in the Parameters located on the back of the ‘QC Pass’ Card',
      select_laser_source: 'Select Your Promark',
      select_workarea: 'Select Workarea',
      settings: 'Promark Settings',
    },
    retry: 'Retry',
    select_beambox: 'Select Your Beambox',
    select_connection_type: 'How do you wish to connect?',
    select_language: 'Select Language',
    select_machine_type: 'Select Your Machine Type',
    setting_completed: {
      back: 'Back',
      great: 'Welcome to Beam Studio',
      ok: 'START CREATING',
      setup_later: 'You can always set up your machine from Titlebar > "Machines" > "Machine Setup"',
      start: 'Start',
    },
    skip: 'Skip',
    start: 'Start',
  },
  input_machine_password: {
    connect: 'CONNECT',
    password: 'Password',
    require_password: '"%s" requires a password',
  },
  insecure_websocket: {
    extension_detected: 'Beam Studio Connect Extension Detected',
    extension_detected_description: 'We have detected that you have installed the Beam Studio Connect extension. Please click ‘Confirm’ to redirect to HTTPS, or click ‘Cancel’ to continue using HTTP.',
    extension_not_deteced: 'Unable to Detect Beam Studio Connect Extension',
    extension_not_deteced_description: 'To use HTTPS, please click ‘Confirm’ to install the Beam Studio Connect extension. After installing the extension, please refresh the page to activate it.<br/>Otherwise, click the link below to see how to use HTTP on Chrome.',
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: '2W Infrared Laser',
    laser_10w_diode: '10W Diode Laser',
    laser_20w_diode: '20W Diode Laser',
    non_working_area: 'Non-Working Area',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Please be aware that if you complete this operation, the settings of the laser layer will be removed and set according to the current layer.',
      convertFromLaserModuleTitle: 'Do you want to convert the Laser module into Printing module?',
      convertFromPrintingModuleMsg: 'Please be aware that if you complete this operation, the color settings of the printing layer will be removed and set according to the current layer.',
      convertFromPrintingModuleTitle: 'Do you want to convert the Printing module into Laser module?',
      importedDocumentContainsPrinting: 'The document contains printing layer, would you like to change workarea to Ador?',
      performIRCaliMsg: 'Click “Confirm” to execute the calibration, or access calibration through the top menu.<br />(Machine > [Your Machine Name] >  Calibrate Infrared Module)',
      performIRCaliTitle: 'Perform the Infrared module calibration',
      performPrintingCaliMsg: 'Click “Confirm” to execute the calibration, or access calibration through the top menu.<br />(Machine > [Your Machine Name] >  Calibrate Printing Module)',
      performPrintingCaliTitle: 'Perform the printing module calibration',
      printingLayersConverted: 'The printing layers have been converted into laser layers.',
    },
    printing: 'Printing',
    unknown: 'Unknown Module',
    uv_print: 'UV Print',
  },
  machine_status: {
    '-17': 'Cartridge IO Mode',
    '-10': 'Maintain mode',
    '-2': 'Scanning',
    '-1': 'Maintaining',
    0: 'Idle',
    1: 'Initiating',
    2: 'ST_TRANSFORM',
    4: 'Starting',
    6: 'Resuming',
    16: 'Working',
    18: 'Resuming',
    32: 'Paused',
    36: 'Paused',
    38: 'Pausing',
    48: 'Paused',
    50: 'Pausing',
    64: 'Completed',
    66: 'Completing',
    68: 'Preparing',
    128: 'Aborted',
    256: 'Alarm',
    512: 'Fatal',
    UNKNOWN: 'Unknown',
  },
  material_test_generator: {
    block_settings: 'Block Settings',
    columns: 'Columns',
    count: 'Count',
    cut: 'Cut',
    engrave: 'Engrave',
    export: 'Export',
    max: 'Max',
    min: 'Min',
    parameter: 'Parameter',
    preview: 'Preview',
    rows: 'Rows',
    size: 'Size (HxW)',
    spacing: 'Spacing',
    table_settings: 'Table Settings',
    text_settings: 'Text Settings',
    title: 'Material Test Generator',
  },
  menu: {
    inches: 'Inches',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Auth Error: Please update Beam Studio and machine firmware to latest version.',
    authenticating: 'Authenticating...',
    camera: {
      abort_preview: 'Abort',
      camera_cable_unstable: 'It is detected that the camera is transferring photos unstably. Preview can still be performed normally, but there may be the problem of slow preview or timeout.',
      continue_preview: 'Continue',
      fail_to_transmit_image: '#845 Something went wrong with image transmission. Please restart your machine or Beam Studio. If this error persists, please follow <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">this guide</a>.',
      ws_closed_unexpectly: '#844 Connection to machine camera has been closed unexpectedly. If this error persists, please follow <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">this guide</a>.',
    },
    cancelling: 'Cancelling...',
    cant_connect_to_device: '#827 Unable to connect the machine, please check your connection',
    connected: 'Connected',
    connecting: 'Connecting...',
    connectingCamera: 'Connecting camera...',
    connectingMachine: 'Connecting %s...',
    connectionTimeout: "#805 Device Connection Timeout. Please check your network state and your machine's Wi-Fi indicator.",
    device_blocked: {
      caption: 'Unauthorized Serial Number',
      offline: 'Your device serial number is restricted to offline usage as it appears to be deactivated. Please provide the machine serial number (located on the back of the device) to your retailer and have them contact support@flux3dp.com to activate online authorization. If you wish to use the device offline, please contact support@flux3dp.com directly.',
      online: 'Your device serial number appears to be deactivated. Please provide the machine serial number (located on the back of the device) to your retailer and have them contact support@flux3dp.com to activate device authorization.',
    },
    device_busy: {
      caption: 'Machine Busy',
      message: 'The machine is executing another task, try again later. If it stops working, please restart the machine.',
    },
    device_is_used: 'The machine is being used, do you want to abort current task?',
    device_not_found: {
      caption: 'Default Machine not found',
      message: "#812 Please check your machine's Wi-Fi indicator",
    },
    disconnected: 'Connection unstable, Please check device connection and try again later',
    endingLineCheckMode: 'Exiting line check mode...',
    endingRawMode: 'Exiting raw mode...',
    enteringLineCheckMode: 'Entering line check mode...',
    enteringRawMode: 'Entering raw mode...',
    enteringRedLaserMeasureMode: 'Entering red laser measuring mode...',
    exitingRotaryMode: 'Exiting rotary mode...',
    getProbePosition: 'Getting Probe Position...',
    gettingLaserSpeed: 'Reading laser head speed...',
    homing: 'Homing...',
    need_password: 'Need Password to Connect to the Machine',
    please_enter_dpi: 'Please enter the Unit of your file (in mm)',
    preview: {
      adjust: 'Adjust',
      adjust_height_tooltip: 'Click the checkbox to enable editing.',
      already_performed_auto_focus: 'You have already performed autofocus, use existing values?',
      auto_focus: 'Auto Focus',
      auto_focus_instruction: 'Please move laser module head above the object, and follow the animation instructions to press AF to focus.',
      camera_preview: 'Camera Preview',
      enter_manually: 'Enter Manually',
      please_enter_height: 'Please enter height of object. In order to capture precise camera shot.',
    },
    promark_disconnected: "#850 Device connection interrupted, please check the device's connection status.",
    redLaserTakingReference: 'Taking reference...',
    retrievingCameraOffset: 'Reading camera offset...',
    settingLaserSpeed: 'Setting laser head speed...',
    swiftray_disconnected: 'Unable to connect to the backend, attempting to reconnect.',
    swiftray_reconnected: 'Backend reconnected, please try resending the job.',
    time_remaining: 'Time remaining:',
    tryingToConenctMachine: 'Trying to connect to machine...',
    turningOffAirPump: 'Turning off air pump...',
    turningOffFan: 'Turning off fan...',
    unable_to_find_machine: 'Unable to find machine ',
    unable_to_start: '#830 Unable to start the task. Please try again. If this happens again, please contact us with bug report:\n',
    unavailableWorkarea: '#804 Current workarea exceeds the workarea of this machine. Please check the workarea of selected machine or set workarea from Edit > Document Setting.',
    unknown_device: '#826 Cannot connect to the machine, please make sure USB is attached to the machine',
    unknown_error: '#821 The application has encountered an unknown error, please use Help > Menu > Bug Report.',
    unsupport_osx_version: 'Current macOS Version %s may not support some functions. Please kindly update to macOS 11+.',
    unsupport_win_version: 'Current OS Version %s may not support some functions. Please kindly update to the latest version.',
    unsupported_example_file: 'The selected example file is not supported by current workarea.',
    uploading_fcode: 'Uploading FCode',
    usb_unplugged: 'USB connection is lost. Please check your USB connection',
    wrong_swiftray_version_message: 'Incorrect backend version (version: {version}). Please check if multiple instances of Beam Studio are running and close any extra instances before retrying.',
    wrong_swiftray_version_title: 'Incorrect backend version',
  },
  monitor: {
    ask_reconnect: 'The connection with the machine has broken. Do you want to reconnect?',
    bug_report: 'Bug Report',
    camera: 'Camera',
    cancel: 'Cancel',
    confirmFileDelete: 'Are you sure you want to delete this file?',
    connecting: 'Connecting, please wait...',
    DEVICE_ERROR: 'Something went wrong\nPlease restart the machine', // Deprecated in FW 3.3.1
    download: 'Download',
    extensionNotSupported: 'This file format is not supported',
    fileExistContinue: 'File already exists, do you want to replace it?',
    forceStop: 'Do you wish to abort current task?',
    go: 'Start',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Airflow sensor anomalous',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Bottom Opened. Close the bottom to continue.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Close the door to continue.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Drawer opened',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Flame sensor anomalous',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Incorrect module detected. Please install the correct module to continue.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Module not detected. Please ensure the module is installed properly to continue.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Unknown module detected. Please install the correct module to continue.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off Error',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Critical Error: Mainboard offline. Please contact FLUX Support.', // Deprecated in FW 3.3.1
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Overheated. Please wait for a few minutes.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-axis reset failed',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Printer module no response.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Please retract the probe.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Please check with your water tank.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 No rotary module detected',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Critical Error: Subsystem no response. Please contact FLUX Support.', // Deprecated in FW 3.3.1
    HARDWARE_FAILURE: 'Something went wrong\nPlease restart the machine', // Deprecated in FW 3.3.1
    hour: 'h',
    left: 'left',
    MAINBOARD_OFFLINE: '#905 Error when connecting to the mainboard.\nPlease restart the machine. ',
    minute: 'm',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 Error when connecting to the mainboard.\nPlease restart the machine.',
    pause: 'Pause',
    prepareRelocate: 'Preparing for Relocate',
    processing: 'Processing',
    record: 'Record',
    relocate: 'Relocate',
    RESOURCE_BUSY: 'The machine is busy\nIf it is not running, please restart the machine',
    resume: 'Resume',
    savingPreview: 'Generating thumbnails',
    second: 's',
    start: 'Start',
    stop: 'Stop',
    SUBSYSTEM_ERROR: '#402 Critical Error: Subsystem no response. Please contact FLUX Support.',
    task: {
      BEAMBOX: 'Laser Engraving',
      'N/A': 'Free Mode',
    },
    taskTab: 'Task',
    temperature: 'Temperature',
    upload: 'Upload',
    USER_OPERATION: 'Please follow the instructions on the device panel to continue.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Please insert the correct cartridge to continue.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Please insert the cartridge to continue.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Please install the correct module to continue.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Incorrect module detected. Please install the correct module to continue.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Module not detected. Please ensure the module is installed properly to continue.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Unknown module detected. Please install the correct module to continue.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Please remove the cartridge to continue.',
    USER_OPERATION_ROTARY_PAUSE: 'Please switch to the rotary motor.',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Are you sure you want to delete this file? This action cannot be undone.',
      delete: 'Delete',
      download: 'Download',
      duplicate: 'Duplicate',
      open: 'Open',
      rename: 'Rename',
    },
    file_limit: 'Free file',
    loading_file: 'Loading...',
    no_file_subtitle: 'Go to Menu > "File" > "Save to Cloud"',
    no_file_title: 'Save files to My Cloud to get started.',
    save_file: {
      choose_action: 'Save File:',
      input_file_name: 'Input file name:',
      invalid_char: 'Invalid characters:',
      save: 'Save',
      save_new: 'Save as new file',
      storage_limit_exceeded: 'Your cloud storage has reached upper limit. Please delete any unnecessary files before saving new ones.',
    },
    sort: {
      a_to_z: 'Name: A - Z',
      most_recent: 'Most Recent',
      oldest: 'Oldest',
      z_to_a: 'Name: Z - A',
    },
    title: 'My Cloud',
    upgrade: 'Upgrade',
  },
  noun_project_panel: {
    clear: 'Clear',
    elements: 'Elements',
    enjoy_shape_library: 'Enjoy the shapes database.',
    export_svg_title: 'Unable to Export SVG',
    export_svg_warning: 'This project contains objects that are protected by the intellectual property law. Therefore Beam Studio will automatically exclude these objects during the export. You can still save your project within Beam Studio Scene (.beam format) to keep your entire data. Do you still want to export?',
    learn_more: 'Learn More',
    login_first: 'Login to unlock the shapes database.',
    recent: 'Recent',
    search: 'Search',
    shapes: 'Shapes',
  },
  pass_through: {
    export: 'Export to Work Area',
    exporting: 'Exporting...',
    guide_mark: 'Guide Mark',
    guide_mark_desc: 'Guide marks will be engraved as a reference point to align the artwork.',
    guide_mark_length: 'Length:',
    guide_mark_x: 'X Coordinate:',
    height_desc: "Set each work area section's height.",
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'How to setup Passthrough for %(model)s?',
    object_length: 'Object Length',
    ref_layer: 'Reference Layer',
    ref_layer_desc: 'Please note that the execution of the reference layer is set to 0 by default. It will not be executed but is only for alignment reference.',
    ref_layer_name: 'Reference',
    title: 'Passthrough Mode',
    workarea_height: 'Work Area (Height):',
  },
  promark_connection_test: {
    description: 'The system will run a red light operation for about two minutes to check if the connection is stable.',
    health: 'Stability:',
    healthy_description: 'Stability is good, but avoid moving the computer significantly during engraving.',
    res_0: 'Excellent',
    res_1: 'Good',
    res_2: 'Low',
    res_3: 'Too Low',
    res_4: 'Very Low',
    restart: 'Restart Test',
    start: 'Start Test',
    stop: 'Stop Test',
    title: 'Connection Stability Test',
    unhealthy_description: 'Stability is too low. It is recommended to replace the network adapter or power cable.',
  },
  promark_settings: {
    angle: 'Angle',
    bulge: 'Bulge',
    field: 'Field',
    galvo_configuration: 'Galvo Configuration',
    mark: 'Mark',
    mark_parameters: 'Mark Parameters',
    offsetX: 'Offset X',
    offsetY: 'Offset Y',
    preview: 'Preview',
    red_dot: 'Red Dot',
    scale: 'Scale',
    scaleX: 'Scale X',
    scaleY: 'Scale Y',
    skew: 'Skew',
    switchXY: 'Switch X/Y',
    title: 'Promark Settings',
    trapezoid: 'Trapezoid',
    workarea_hint: 'You can change working area in "Document Settings"',
    z_axis_adjustment: {
      section1: 'Adjust the Z-axis height to fine-tune the focus.',
      title: 'Z-Axis Adjustment',
      tooltip1: 'Try marking a 1x1 cm square to confirm if the current focal distance is appropriate.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Error Tolerance',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Invert background color',
    placeholder: 'Input a link or text',
    preview: 'Preview',
    title: 'QR Code Generator',
  },
  rotary_settings: {
    circumference: 'Circumference',
    extend_workarea: 'Extend Workarea',
    mirror: 'Mirror',
    object_diameter: 'Object Diameter',
    overlap_size: 'Overlap size',
    rotary_scale: 'Rotation Scale',
    split_setting: 'Split setting',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Split size',
    type: 'Type',
  },
  select_device: {
    auth_failure: '#811 Authentication fail',
    select_device: 'Select Device',
    unable_to_connect: '#810 Unable to build a stable connection with machine',
  },
  settings: {
    anti_aliasing: 'Anti-Aliasing',
    auto_connect: 'Automatically select the only machine',
    auto_switch_tab: 'Auto Switch Layer and Object Panel',
    autofocus_offset: 'Autofocus Offset',
    autosave_enabled: 'Auto Save',
    autosave_interval: 'Save Every',
    autosave_number: 'Number of Auto Save',
    autosave_path: 'Auto Save Location',
    autosave_path_not_correct: 'Specified path not found.',
    blade_precut_position: 'Precut Position',
    blade_precut_switch: 'Blade Precut',
    blade_radius: 'Blade Radius',
    bottom_up: 'Bottom-up',
    calculation_optimization: 'Path Calculation Acceleration',
    cancel: 'Cancel',
    caption: 'Settings',
    check_updates: 'Auto Check',
    close: 'Close',
    confirm_remove_default: 'Default machine is going to be removed.',
    confirm_reset: 'Confirm reset Beam Studio?',
    continuous_drawing: 'Continuous Drawing',
    curve_engraving_speed_limit: 'Curve Engraving Speed Limit',
    custom_preview_height: 'Custom Preview Height',
    default_beambox_model: 'Default Document Setting',
    default_borderless_mode: 'Open Bottom Default',
    default_enable_autofocus_module: 'Autofocus Default',
    default_enable_diode_module: 'Diode Laser Default',
    default_font_family: 'Default Font',
    default_font_style: 'Default Font Style',
    default_laser_module: 'Default Laser Module',
    default_machine: 'Default Machine',
    default_machine_button: 'Empty',
    default_units: 'Default Units',
    diode_offset: 'Diode Laser Offset',
    diode_one_way_engraving: 'Diode Laser One Way Engraving',
    diode_two_way_warning: 'Bidirectional light emitting is faster and probably generate some imprecision on laser engraving position. Recommend to test first.',
    disabled: 'Disabled',
    done: 'Apply',
    enable_custom_backlash: 'Enable Custom Backlash Compensation',
    enable_low_speed: 'Enable slow speed movement',
    enable_uv_print_file: 'Enable UV Print File',
    enabled: 'Enabled',
    engraving_direction: 'Direction',
    fast_gradient: 'Speed Optimization',
    font_convert: 'Text-to-Path Converter',
    font_substitute: 'Substitute Unsupported Characters',
    grouped_objects: 'Grouped Objects',
    groups: {
      ador_modules: 'Ador Modules',
      autosave: 'Auto Save',
      camera: 'Camera',
      connection: 'Connection',
      editor: 'Editor',
      engraving: 'Rastering (Scanning)',
      general: 'General',
      mask: 'Workarea Clipping',
      modules: 'Add-on',
      path: 'Vector (Outlines)',
      privacy: 'Privacy',
      text_to_path: 'Text',
      update: 'Software Updates',
    },
    guess_poke: 'Search for machine IP address',
    guides: 'Guides',
    guides_origin: 'Guides Origin',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      uv_print_export: 'https://support.flux3dp.com/hc/en-us/articles/12320887477135',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'High',
    image_downsampling: 'Bitmap Preview Quality',
    ip: 'Machine IP Address',
    keep_preview_result: 'Keep Preview Result',
    language: 'Language',
    loop_compensation: 'Loop Compensation',
    low: 'Low',
    low_laser_for_preview: 'Laser for Running Frame',
    mask: 'Workarea Clipping',
    medium: 'Medium',
    module_offset_2w_ir: '2W Infrared Laser Offset',
    module_offset_10w: '10W Diode Laser Offset',
    module_offset_20w: '20W Diode Laser Offset',
    module_offset_printer: 'Printer Offset',
    none: 'None',
    normal: 'Normal',
    notification_off: 'Off',
    notification_on: 'On',
    notifications: 'Desktop Notifications',
    off: 'Off',
    on: 'On',
    preview_movement_speed: 'Preview Movement Speed',
    printer_advanced_mode: 'Printer Advanced Mode',
    remove_default_machine_button: 'Remove',
    reset: 'Reset Beam Studio',
    reset_now: 'Reset Beam Studio',
    segmented_engraving: 'Segmented Engraving',
    share_with_flux: 'Share Beam Studio Analytics',
    simplify_clipper_path: 'Optimize the Calculated Path',
    single_object: 'Single Object',
    tabs: {
      device: 'Machine',
      general: 'General',
    },
    text_path_calc_optimization: 'Path Calculation Optimization',
    top_down: 'Top-down',
    trace_output: 'Image Trace Output',
    update_beta: 'Beta',
    update_latest: 'Latest',
    vector_speed_constraint: 'Speed Limit',
    wrong_ip_format: 'Wrong IP Formats',
  },
  social_media: {
    facebook: 'Chat with FLUXers, ask questions, and learn tips!',
    instagram: 'Get the latest inspirations, deals, and freebies!',
    youtube: 'View Beam Studio tutorials and craft ideas.',
  },
  support: {
    no_vcredist: 'Please install Visual C++ Redistributable 2015<br/>That can be downloaded on flux3dp.com',
    no_webgl: 'WebGL is not supported. Please use other devices.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Please add objects first',
      door_opened: 'Please close the door cover to enable low laser for running frame.',
      fail_to_connect_with_camera: '#803 Failed to establish connection with machine camera. Please Restart your machine or Beam Studio. If this error persists, please follow <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">this guide</a>.',
      fail_to_get_door_status: 'Please make sure the door cover is closed to enable low laser for running frame.',
      fail_to_start_preview: '#803 Fail to start preview mode. Please Restart your machine or Beam Studio. If this error persists, please follow <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">this guide</a>.',
      headtype_mismatch: 'Incorrect module detected. ',
      headtype_none: 'Module not detected. ',
      headtype_unknown: 'Unknown module detected. ',
      install_correct_headtype: 'Please install 10W/20W diode laser modules properly to enable low laser for running frame.',
      job_origin_unavailable: 'Setting job Origin requires firmware version 4.3.5 / 5.3.5 or above. Would you like to update the firmware now?',
      job_origin_warning: 'You are currently using the “current position” as the starting point. Please ensure the laser head is moved to the correct position to avoid collisions.',
      power_too_high: 'POWER TOO HIGH',
      power_too_high_confirm: 'NOTED',
      power_too_high_msg: 'Using lower laser power (under 70%) will extends laser tube\'s lifetime.\nEnter "NOTED" to proceed.',
      pwm_unavailable: 'Depth mode requires firmware version 4.3.4 / 5.3.4 or above. Would you like to update the firmware now?',
      QcleanScene: 'Do you want to clear the drawing?<br/>This will also erase your undo history!',
      start_preview_timeout: '#803 Timeout has occurred when starting preview mode. Please Restart your machine or Beam Studio. If this error persists, please follow <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">this guide</a>.',
    },
    frame_task: 'Running Frame',
    hint: {
      polygon: 'Press + / - key to increase / decrease sides.',
    },
    menu: {
      about: 'About Beam Studio',
      about_beam_studio: 'About Beam Studio',
      account: 'Account',
      add_new_machine: 'Machine Setup',
      align_center: 'Align Center',
      anti_aliasing: 'Anti-Aliasing',
      auto_align: 'Auto Align',
      borderless_mode: 'Borderless Mode',
      bug_report: 'Bug Report',
      calibrate_beambox_camera: 'Calibrate Camera',
      calibrate_beambox_camera_borderless: 'Calibrate Camera (Open Bottom)',
      calibrate_camera_advanced: 'Calibrate Camera (Advanced)',
      calibrate_diode_module: 'Calibrate Diode Laser Module',
      calibrate_ir_module: 'Calibrate Infrared Module',
      calibrate_printer_module: 'Calibrate Printing Module',
      calibration: 'Calibration',
      camera_calibration_data: 'Camera Calibration Data',
      change_logs: 'Change Logs',
      clear_scene: 'New',
      close: 'Close Window',
      commands: 'Commands',
      contact: 'Contact Us',
      copy: 'Copy',
      cut: 'Cut',
      dashboard: 'Dashboard',
      decompose_path: 'Decompose',
      delete: 'Delete',
      design_market: 'Design Market',
      dev_tool: 'Debug Tool',
      disassemble_use: 'Disassemble',
      document_setting: 'Document Settings',
      document_setting_short: 'Document',
      download_data: 'Download Data',
      download_log: 'Download Logs',
      download_log_canceled: 'Log download canceled',
      download_log_error: 'Unknown error occurred, please try it again later',
      duplicate: 'Duplicate',
      edit: 'Edit',
      example_files: 'Example Files',
      export_BVG: 'BVG',
      export_flux_task: 'FLUX task',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Export To...',
      export_UV_print: 'UV Print PDF',
      file: 'File',
      fit_to_window: 'Fit to Window Size',
      follow_us: 'Follow Us',
      forum: 'Community Forum',
      group: 'Group',
      help: 'Help',
      help_center: 'Help Center',
      hide: 'Hide Beam Studio',
      hideothers: 'Hide Others',
      image_crop: 'Crop',
      image_curve: 'Curve',
      image_invert: 'Invert',
      image_sharpen: 'Sharpen',
      image_stamp: 'Bevel',
      image_vectorize: 'Trace',
      import_acrylic_focus_probe: 'Acrylic Focus Probe - 3mm',
      import_ador_laser_example: 'Example of Ador Laser',
      import_ador_printing_example_full: 'Example of Ador Printing - Full Color',
      import_ador_printing_example_single: 'Example of Ador Printing - Single Color',
      import_beambox_2_example: 'Example of Beambox II',
      import_beambox_2_focus_probe: 'Beambox II Focus Probe - 3mm',
      import_hello_beambox: 'Example of Beambox',
      import_hello_beamo: 'Example of beamo',
      import_hexa_example: 'Example of HEXA',
      import_material_printing_test: 'Material Printing Test',
      import_material_testing_cut: 'Material Cutting Test',
      import_material_testing_engrave: 'Material Engraving Test',
      import_material_testing_line: 'Material Line Test',
      import_material_testing_old: 'Material Engraving Test - Classic',
      import_material_testing_simple_cut: 'Material Cutting Test - Simple',
      import_promark_example: 'Example of Promark',
      import_promark_mopa_20w_color: 'MOPA 20W Color Test',
      import_promark_mopa_60w_color: 'MOPA 60W Color Test',
      import_promark_mopa_100w_color: 'MOPA 100W Color Test',
      keyboard_shortcuts: 'Keyboard shortcuts',
      layer_color_config: 'Color Settings',
      layer_setting: 'Layer',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Camera',
        cloud: 'Cloud',
        discover: 'Discover',
        hardware: 'Hardware',
        network: 'Network',
        player: 'Player',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'USB List',
      },
      machine_info: 'Machine Info',
      machines: 'Machines',
      manage_account: 'Manage My Account',
      material_test: 'Material Test',
      minimize: 'Minimize',
      my_account: 'My Account',
      network_testing: 'Test Network Settings',
      object: 'Object',
      offset: 'Offset',
      open: 'Open',
      paste: 'Paste',
      paste_in_place: 'Paste in Place',
      path: 'Path',
      photo_edit: 'Image',
      preferences: 'Preferences',
      promark_color_test: 'Promark Color Test',
      questionnaire: 'Feedback Questionnaire',
      quit: 'Quit',
      recent: 'Open Recent',
      redo: 'Redo',
      reload_app: 'Reload App',
      reset: 'Reset',
      rotary_setup: 'Rotary Setup',
      rotate: 'Rotate',
      samples: 'Examples',
      save_as: 'Save As...',
      save_scene: 'Save',
      save_to_cloud: 'Save to Cloud',
      scale: 'Scale',
      service: 'Services',
      set_as_default: 'Set as Default',
      show_gesture_tutorial: 'Hand Gesture Introduction',
      show_grids: 'Show Grids',
      show_layer_color: 'Use Layer Color',
      show_rulers: 'Show Rulers',
      show_start_tutorial: 'Show Start Tutorial',
      show_ui_intro: 'Show Interface Introduction',
      sign_in: 'Sign In',
      sign_out: 'Sign Out',
      software_update: 'Software Update',
      svg_edit: 'SVG',
      switch_to_beta: 'Switch To Beta Release',
      switch_to_latest: 'Switch To Stable Release',
      tools: {
        box_generator: 'Box Generator',
        code_generator: 'Code Generator',
        material_test_generator: 'Material Test Generator',
        title: 'Tools',
      },
      tutorial: 'Start Delta Family Printing Tutorial',
      undo: 'Undo',
      ungroup: 'Ungroup',
      update: 'Check Update',
      update_firmware: 'Update Firmware',
      upload_data: 'Upload Data',
      using_beam_studio_api: 'Using Beam Studio API',
      view: 'View',
      window: 'Window',
      zoom_in: 'Zoom In',
      zoom_out: 'Zoom Out',
      zoom_with_window: 'Auto Fit to Window Size',
    },
    preview: 'PREVIEW',
    preview_press_esc_to_stop: 'Press ESC to stop the camera preview.',
    rename_tab: 'Rename Tab',
    select_machine: 'Select a machine',
    tag_names: {
      dxf: 'DXF Object',
      ellipse: 'Oval',
      g: 'Group',
      image: 'Image',
      line: 'Line',
      multi_select: 'Multiple Objects',
      no_selection: 'No Selection',
      pass_through_object: 'Pass Through Object',
      path: 'Path',
      polygon: 'Polygon',
      rect: 'Rectangle',
      svg: 'SVG Object',
      text: 'Text',
      text_path: 'Text on Path',
      use: 'Imported Object',
    },
    task_preview: 'Path preview',
    titles: {
      settings: 'Preferences',
    },
    untitled: 'Untitled',
  },
  topmenu: {
    credit: 'Beam Studio is made possible by the <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> open source project and other <a target="_blank" href="https://flux3dp.com/credits/">open source software</a>.',
    device: {
      download_log_canceled: 'Log download canceled',
      download_log_error: 'Unknown error occurred, please try it again later',
      log: {
        usblist: 'USB List',
      },
      network_test: 'Test Network',
    },
    file: {
      all_files: 'All Files',
      clear_recent: 'Clear Recently Opened',
      converting: 'Converting to Image...',
      fcode_files: 'FLUX Code',
      import: 'Import',
      jpg_files: 'JPG',
      label: 'File',
      path_not_exit: 'This path does not seem to exist anymore on disk.',
      png_files: 'PNG',
      save_fcode: 'Export FLUX Task',
      save_jpg: 'Export JPG',
      save_png: 'Export PNG',
      save_scene: 'Save Scene',
      save_svg: 'Export SVG',
      scene_files: 'Beam Studio Scene',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Version',
  },
  tutorial: {
    ask_retry_calibration: 'Would you like to calibrate the camera again?',
    camera_calibration_failed: 'Camera Calibration Failed',
    gesture: {
      click: 'Tap to select the object.',
      drag: 'Drag to select the multiple objects.',
      hold: 'Press and hold to open the context menu.',
      pan: 'Scroll the canvas with two fingers.',
      zoom: 'Pinch with two fingers to zoom in/out the canvas.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Searching machine for tutorial...',
    needNewInterfaceTutorial: 'Would you like to start a tutorial for Beam Studio\'s new interface?<br/>(You can skip it now and start it later by clicking on "Help" > "Show Interface Introduction".)',
    needNewUserTutorial: 'Would you like to start a tutorial?<br/>(You can skip it now and start it later by clicking on "Help" > "Show Start Tutorial".)',
    newInterface: {
      add_new_layer: 'Add New Layer',
      align_controls: 'Align Controls',
      basic_shapes: 'Basic Shapes',
      camera_preview: 'Camera Preview',
      drag_to_sort: 'Drag to sort',
      end_alert: 'Are you sure to end new UI introduction?',
      flip: 'Flip',
      group_controls: 'Group Controls',
      layer_controls: 'Right Click to select Layer Controls:\nDuplicate / Merge / Lock / Delete Layers',
      object_actions: 'Object Actions',
      pen_tool: 'Pen Tool',
      rename_by_double_click: 'Rename by double click',
      select_image_text: 'Select / Image / Text',
      select_machine: 'Select a machine',
      shape_operation: 'Shape Operation',
      start_work: 'Start Work',
      switch_between_layer_panel_and_object_panel: 'Switch between Layer Panel and Object Panel',
    },
    newUser: {
      add_new_layer: 'Add a New Layer',
      adjust_focus: '2. Adjust the focus',
      close_cover: '3. Close the cover',
      drag_to_draw: 'Drag to Draw',
      draw_a_circle: 'Draw a Circle',
      draw_a_rect: 'Draw a Rectangle',
      end_alert: 'Are you sure to end tutorial?',
      end_preview_mode: 'End Preview Mode',
      infill: 'Turn on Infill',
      please_select_wood_cutting: 'Kindly select "Wood - Cutting" Preset.',
      please_select_wood_engraving: 'Kindly select "Wood - Engraving" Preset.',
      preview_the_platform: 'Preview the Platform',
      put_wood: '1. Put the sample wood',
      send_the_file: 'Send the File',
      set_preset_wood_cut: 'Set Preset: Wood - Cutting',
      set_preset_wood_engraving: 'Set Preset: Wood - Engraving',
      switch_to_layer_panel: 'Switch to Layer Panel',
      switch_to_object_panel: 'Switch to Object Panel',
      switch_to_preview_mode: 'Switch to Preview Mode',
    },
    next: 'NEXT',
    retry: 'Retry',
    set_connection: 'Set Connection',
    skip: 'Skip',
    skip_tutorial: 'You have skipped the tutorial. You can always start tutorial by click "Help" > "Show Start Tutorial"',
    suggest_calibrate_camera_first: 'We advise users to calibrate the camera initially and refocus before each preview for optimal results.<br/>Please confirm to conduct calibration now?<br/>(You can skip it now and do it later by clicking on "Menu" > "Machine" > [Your Machine] > "Calibrate Camera".)',
    tutorial_complete: "That's all for the tutorial. Now it's time to create!",
    unable_to_find_machine: 'Unable to find machine for Tutorial. Do you want to go to connection setting page, retry or skip tutorial?',
    welcome: 'WELCOME',
  },
  update: {
    cannot_reach_internet: '#823 Server is unreachable<br/>Please checking internet connection',
    download: 'ONLINE UPDATE',
    firmware: {
      caption: 'A Firmware Update to the machine is available',
      confirm: 'UPLOAD',
      firmware_too_old_update_by_sdcard: 'The firmware version is too old. Please update the firmware using an SD card.',
      force_update_message: '#814 Please update your machine to the latest firmware version.',
      latest_firmware: {
        cant_get_latest: 'Unable to get information of the latest firmware.',
        caption: 'Machine firmware Update',
        message: 'You have the latest Machine firmware',
        still_update: 'UPDATE',
      },
      message_pattern_1: '"%s" is now ready for firmware update.',
      message_pattern_2: '%s Firmware v%s is now available - You have v%s.',
      too_old_for_web: 'Your current machine firmware version is v%s.\nIf you want to use the online version of the Beam Studio, please update the machine firmware to the latest version.',
      update_fail: '#822 Update Fail',
      update_success: 'Firmware update successfully uploaded',
      upload_file: 'Firmware upload (*.bin / *.fxfw)',
    },
    install: 'INSTALL',
    later: 'LATER',
    preparing: 'Preparing...',
    release_note: 'Release Note:',
    skip: 'Skip This Version',
    software: {
      available_switch: 'Beam Studio v%s is available now. You have v%s. Do you want to switch to this version?',
      available_update: 'Beam Studio v%s is available now. You have v%s. Do you want to download the update?',
      caption: 'A Software Update to Beam Studio is available',
      check_update: 'Check for Update',
      checking: 'Checking for Update',
      downloading: 'Downloading updates in the background, you can click "OK" to continue your work.',
      install_or_not: 'is ready for update. Would you like restart and update now?',
      no: 'No',
      no_response: 'Failed to connect to server, please check you network setting.',
      not_found: 'You are using the latest version Beam Studio.',
      switch_or_not: 'is ready for switch. Would you like restart and switch now?',
      switch_version: 'Switch Version',
      switch_version_not_found: 'Switchable Version not found.',
      update_for_ador: 'The current software version %s is incompatible, please download the latest version of Beam Studio for Ador.',
      yes: 'Yes',
    },
    update: 'Update',
    updating: 'Updating...',
    upload: 'UPLOAD',
  },
  web_cam: {
    no_device: 'Unable to detect camera device. Please reconnect the camera and try again.',
    no_permission: 'Beam Studio does not have permission to access the camera. Please make sure the permission is granted to Beam Studio in system settings or browser settings.',
  },
  z_speed_limit_test: {
    alert_before: 'Before starting curve engraving, the system will perform a load test to prevent step loss due to excessive weight.',
    alert_failed: "The current object's weight exceeds the load limit for the current Z-axis speed. Please reduce the Z-axis speed on the machine panel or replace the engraving object before retesting.",
    ignore: 'Ignore',
    retest: 'Retest',
    testing: 'Performing load test...',
  },
};

export default lang;
