import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Abbrechen',
    cancel: 'Abbrechen',
    caption: 'Fehler',
    close: 'schließen',
    confirm: 'Bestätige',
    dont_save: 'Nicht speichern',
    dont_show_again: 'Nicht erneut anzeigen',
    duplicated_preset_name: 'Duplizierter Voreinstellungsname',
    error: 'UH-OH',
    info: 'INFO',
    instruction: 'Anleitung',
    learn_more: 'Erfahren Sie mehr',
    no: 'Nein',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Hoppla...',
    retry: 'Wiederhole',
    save: 'Speichern',
    stop: 'Halt',
    warning: 'WARNUNG',
    yes: 'Ja',
  },
  auto_fit: {
    artwork_size: 'Größe des Kunstwerks',
    error_tip1: '1. Ist das Kunstwerk korrekt auf dem Material platziert?',
    error_tip2: '2. Sind die Materialkonturen klar genug zur Erkennung?',
    failed_to_find_contour: 'Keine Konturen für die automatische Anpassung gefunden',
    learn_more: 'Tutorial anzeigen.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Versatz X',
    offset_y: 'Versatz Y',
    pattern_size: 'Mustergröße',
    position_artwork: 'Kunstwerk positionieren',
    position_step1: 'Passen Sie die Größe und Position Ihres Kunstwerks im Muster an.',
    position_step2: 'Klicken Sie auf „Anwenden“, um Auto Fit auf dieses Muster anzuwenden.',
    preview_first: 'Bitte führen Sie zuerst die Kamera-Vorschau durch.',
    reset_position: 'Position zurücksetzen',
    rotation: 'Drehen',
    select_a_pattern: 'Wählen Sie ein Muster',
    selected_artwork: 'Ausgewähltes Kunstwerk',
    step1: 'Wählen Sie ein Muster aus, auf dem Sie das Kunstwerk platzieren möchten.',
    step2: 'Wenn kein Muster gefunden wird, prüfen Sie die Kameravorschau erneut und verwenden Sie Automatische Anpassung.',
    step3: 'Wenn weiterhin kein korrektes Muster gefunden wird, stellen Sie sicher, dass Ihr Werkstück klar erkennbar ist und der Arbeitsbereich frei von Ablagerungen ist.',
    title: 'Automatische Anpassung',
  },
  barcode_generator: {
    bar_height: 'Balkenhöhe',
    bar_width: 'Balkenbreite',
    barcode: {
      invalid_value: 'Der Wert ist ungültig für das ausgewählte Format.',
    },
    font: 'Schriftart',
    font_size: 'Schriftgröße',
    hide_text: 'Text ausblenden',
    invert_color: 'Farben umkehren',
    text_margin: 'Textabstand',
  },
  beambox: {
    announcement_panel: {
      title: 'Ankündigung',
    },
    banner: {
      auto_feeder: 'Automatischer Zuführmodus',
      camera_preview: 'Vorschau',
      camera_preview_borderless_mode: '(Offener Boden)',
      curve_engraving: 'Kurven-Graviermodus',
      pass_through: 'Durchlaufmodus',
      rotary: 'Drehmodus',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Text in Pfad konvertieren ...',
      export_file_error_ask_for_upload: 'Aufgabe konnte nicht exportiert werden. Bist du bereit, eine Arbeitsszene für die Entwicklung eines Teams für Fehlerberichte bereitzustellen?',
      retreive_image_data: 'Bilddaten abrufen ...',
    },
    context_menu: {
      copy: 'Kopieren',
      cut: 'Ausschneiden',
      delete: 'Löschen',
      duplicate: 'Duplizieren',
      group: 'Gruppieren',
      move_back: 'Nach ganz unten verschieben',
      move_down: 'Herunterschieben',
      move_front: 'Nach ganz oben verschieben',
      move_up: 'Hochschieben',
      paste: 'Einfügen',
      paste_in_place: 'Bei Originalposition einfügen',
      ungroup: 'Gruppierung aufheben',
    },
    document_panel: {
      add_on: 'Zusatz-Module',
      auto_feeder: 'Automatischer Einzug',
      auto_feeder_scale: 'Skala für den automatischen Einzug',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Öffnen Sie den Boden',
      current_position: 'Aktuelle Position',
      disable: 'Deaktivieren',
      document_settings: 'Dokumenteinstellungen',
      enable: 'Aktivieren',
      enable_autofocus: 'Autofokus',
      enable_diode: 'Diodenlaser',
      engrave_dpi: 'Auflösung',
      frame_before_start: 'Zuerst Rahmen, dann ausführen',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Hoch',
      job_origin: 'Job-Ursprung',
      laser_source: 'Laserquelle',
      low: 'Niedrig',
      machine: 'Maschine',
      medium: 'Mittel',
      notification: {
        changeFromPrintingWorkareaTitle: 'Möchten Sie die Druckschichten in Laserschichten umwandeln?',
      },
      origin: 'Ursprung',
      pass_through: 'Durchlauf',
      pass_through_height_desc: 'Geben Sie die Länge des Objekts ein, um den Arbeitsbereich zu erweitern.',
      rotary_mode: 'Drehtisch',
      scale: 'Skala',
      start_from: 'Start Von',
      start_position: 'Startposition',
      start_work_button: 'Ausführen-Taste',
      ultra: 'Ultra Hoch',
      workarea: 'Arbeitsbereich',
    },
    image_trace_panel: {
      back: 'Zurück',
      brightness: 'Helligkeit',
      cancel: 'Stornieren',
      contrast: 'Kontrast',
      next: 'Nächster',
      okay: 'okay',
      threshold: 'Schwellenwert',
      tuning: 'Parameter',
    },
    layer_color_config_panel: {
      add: 'Hinzufügen',
      add_config: 'Farbe hinzufügen',
      color: 'Farbe',
      default: 'Zurücksetzen',
      in_use: 'Diese Farbe wird verwendet.',
      layer_color_config: 'Ebenenfarbkonfigurationen',
      no_input: 'Bitte gebe einen gültigen Hex-Farbcode ein.',
      power: 'Leistung',
      repeat: 'Ausführen',
      speed: 'Geschwindigkeit',
      sure_to_delete: 'Bist du sicher, diese Farbeinstellung zu löschen?',
      sure_to_reset: 'Du verlierst alle benutzerdefinierten Parameter. Bist du sicher, dass du die Standardeinstellungen wiederherzustellen?',
    },
    left_panel: {
      borderless_blind_area: 'Nicht gravierender Bereich',
      borderless_preview: 'Kamera-Vorschau im randlosen Modus',
      diode_blind_area: 'Hybrid Laser Add-On Blindbereich',
      ellipse: 'Ellipse',
      image: 'Bild',
      label: {
        adjust_height: 'Höhe anpassen',
        array: 'Anordnung',
        boxgen: 'Boxgen',
        choose_camera: 'Kamera',
        clear_preview: 'Vorschau löschen',
        cursor: 'Wählen',
        curve_engraving: {
          clear_area: 'Ausgewählten Bereich löschen',
          exit: 'Beenden',
          preview_3d_curve: 'Vorschau der Kurvengravur',
          select_area: 'Bereich auswählen',
          title: 'Kurvengravur',
        },
        end_preview: 'Vorschau beenden',
        line: 'Linie',
        live_feed: 'Live-Übertragung',
        my_cloud: 'Meine Cloud',
        oval: 'Oval',
        pass_through: 'Durchlauf',
        pen: 'Stift',
        photo: 'Bild',
        polygon: 'Vieleck',
        preview: 'Kameravorschau',
        qr_code: 'QR-Code',
        rect: 'Rechteck',
        shapes: 'Formen',
        text: 'Text',
        trace: 'Bild verfolgen',
      },
      line: 'Linie',
      rectangle: 'Rechteck',
      text: 'Text',
      unpreviewable_area: 'Blinder Bereich',
    },
    network_testing_panel: {
      average_response: 'Durchschnittliche Reaktionszeit',
      cannot_connect_1: '#840 Verbindung zur Ziel-IP fehlgeschlagen.',
      cannot_connect_2: '#840 Verbindung zur Ziel-IP fehlgeschlagen. Stelle sicher, dass sich das Ziel im selben Netzwerk befindet.',
      cannot_get_local: 'Der Zugriff auf die lokale IP-Adresse ist fehlgeschlagen.',
      connection_quality: 'Verbindungsqualität',
      device_not_on_list: '#842 Das Gerät ist nicht in der Liste aufgeführt, aber der Verbindungsqualität beträgt > 70 und die durchschnittliche Antwortzeit < 100ms',
      empty_ip: '#818 Bitte gebe zuerst die IP des Zielgeräts ein.',
      end: 'Ende',
      fail_to_start_network_test: '#817 Starte den Netzwerktest nicht.',
      hint_connect_camera_timeout: 'Zeitüberschreitung beim Starten der Kameravorschau?',
      hint_connect_failed_when_sending_job: 'Verbindung beim Senden eines Auftrags fehlgeschlagen?',
      hint_device_often_on_list: 'Die Maschine ist oft nicht auf der Liste gefunden?',
      insert_ip: 'IP-Adresse des Zielgeräts:',
      invalid_ip: '#818 ungültige IP-Adresse',
      ip_startswith_169: '#843 Die IP-Adresse des Geräts beginnt mit 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Dieser Fehler tritt normalerweise aufgrund unzureichender Berechtigungen auf.\nFühren Sie bitte "sudo beam-studio --no-sandbox" im Terminal aus, um Berechtigungen zu erhalten und Netzwerktests durchzuführen.',
      local_ip: 'Lokale IP-Adresse:',
      network_testing: 'Netzwerktests',
      network_unhealthy: '#841 Verbindungsqualität < 70 oder durchschnittliche Antwortzeit > 100ms',
      start: 'Start',
      test_completed: 'Test abgeschlossen',
      test_fail: 'Im Test durchgefallen',
      testing: 'Netzwerk testen ...',
    },
    object_panels: {
      lock_desc: 'Behalte das Verhältnis von Breite und Höhe bei (UMSCHALTTASTE)',
      text_to_path: {
        check_thumbnail_warning: 'Einige Texte wurden beim Verwandeln von Texten in Pfade in andere Schriftarten geändert, und einige Zeichen werden möglicherweise nicht normal konvertiert.\nBitte überprüfe das Vorschaubild erneut, bevor du die Aufgabe sendest.',
        error_when_parsing_text: 'Fehler beim Konvertieren von Text in Pfad',
        font_substitute_pop: 'Ihr Text enthält Zeichen, die von der aktuellen Schriftart nicht unterstützt werden. <br/>Möchten Sie <strong>%s</strong> als Ersatz verwenden?',
        retry: 'Bitte versuchen Sie es später noch einmal oder wählen Sie eine andere Schriftart',
        use_current_font: 'Aktuelle Schriftart verwenden',
      },
      wait_for_parsing_font: 'Schriftart analysieren ... Bitte warten.',
    },
    path_preview: {
      current_position: 'Aktuelle Position',
      cut_distance: 'Schnittlänge',
      cut_time: 'Schneiddauer',
      end_preview: 'Vorschau beenden',
      estimated_time: 'Voraussichtliche Laufzeitlänge',
      invert: 'Farben umkehren',
      pause: 'Pause',
      play: 'Abspielen',
      play_speed: 'Wiedergabegeschwindigkeit',
      preview_info: 'Information Vorschau',
      rapid_distance: 'Bewegungsstrecke',
      rapid_time: 'Bewegungsdauer',
      remark: '* Alle angezeigten Werten dienen nur Referenzzwecken.',
      size: 'Größe',
      start_here: 'Hier anfangen',
      stop: 'Stoppen',
      travel_path: 'Bewegungspfad',
    },
    photo_edit_panel: {
      aspect_ratio: 'Seitenverhältnis',
      brightness: 'Helligkeit',
      brightness_and_contrast: 'Helligkeit / Kontrast',
      cancel: 'Stornieren',
      circumference: 'Umfang',
      compare: 'Vergleichen Sie',
      contrast: 'Kontrast',
      crop: 'Ernte',
      curve: 'Kurve',
      diameter: 'Durchmesser',
      free: 'Beliebig',
      invert: 'Farbe umkehren',
      okay: 'okay',
      original: 'Originalgröße',
      phote_edit: 'Fotobearbeitung',
      processing: 'wird bearbeitet',
      radius: 'Radius',
      rotary_warped: 'Drehverzerrt',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Schärfen',
      sharpness: 'Schärfe',
      start: 'Start',
      warp: 'Verzerren',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Gehen',
        insufficient_credit: 'Du hast kein Guthaben mehr',
        insufficient_credit_msg: 'Sie können %s nicht verwenden. Gehen Sie zum Mitgliedercenter und kaufen Sie AI Credit.',
        relogin_to_use: 'Bitte erneut einloggen, um diese Funktion zu verwenden.',
      },
      auto_feeder_origin: 'Bitte verwenden Sie die Rahmenvorschau, um den Pfad und Bereich des Laserkopfs zu überprüfen. Passen Sie dann die Materialplatzierung oder die Startposition der Gravur basierend auf den Ergebnissen an, um Kollisionen während des Gravurprozesses zu vermeiden.',
      auto_switch_tab: {
        message: 'Eine neue Option zum automatischen Umschalten zwischen dem Ebenen- und dem Objekt-Panel wurde zu den Voreinstellungen hinzugefügt. Diese Option ist standardmäßig deaktiviert. Möchten Sie das automatische Umschalten jetzt aktivieren? <br/>Sie können diese Einstellung jederzeit in den Voreinstellungen ändern.',
        title: 'Automatisches Umschalten zwischen Ebenen- und Objektfenster',
      },
      backend_connect_failed_ask_to_upload: '#802 Beim Versuch, eine Verbindung zum Server herzustellen, treten immer wieder Fehler auf. Möchtest du Ihr Fehlerberichtsprotokoll hochladen?',
      backend_error_hint: 'Funktionen funktionieren möglicherweise aufgrund eines Backend-Fehlers nicht ordnungsgemäß.',
      both_power_and_speed_too_high: 'Die Verwendung einer niedrigeren Laserleistung verlängert die Lebensdauer der Laserröhre!\nAußerdem kann eine zu hohe Geschwindigkeit bei dieser Auflösung zu einer schlechteren Qualität der Gravur führen.',
      bug_report: 'Fehlerbericht',
      change_workarea_before_preview: 'Der Arbeitsbereich von %s stimmt nicht mit dem aktuell festgelegten Arbeitsbereich überein. Möchten Sie den aktuellen Arbeitsbereich wechseln?',
      convert_to_path_fail: 'Konvertierung in Pfad fehlgeschlagen.',
      dxf_bounding_box_size_over: 'Die Zeichnungsgröße ist außerhalb des Arbeitsbereichs. Bewege bitte deine Zeichnung näher an den Ursprung in Ihrer CAD-Software oder stelle sicher, dass das Gerät richtig eingestellt ist.',
      dxf_version_waring: 'Die Version dieser DXF-Datei ist nicht 2013, möglicherweise treten Inkompatibilitätsprobleme auf.',
      facebook_group_invitation: {
        already_joined: 'Bereits angemeldet',
        join_now: 'Jetzt beitreten',
        later: 'Vielleicht später',
        message: 'Treten Sie unserer offiziellen Facebook-Gruppe bei, um mit anderen FLUX Laseranwendern in Kontakt zu treten, über FLUX Laser zu diskutieren, Laserkunstwerke auszutauschen und über die neuesten Updates zu unseren Produkten auf dem Laufenden zu bleiben. Wir können es kaum erwarten, Sie dort zu sehen!',
        title: 'Der offiziellen FLUX Benutzergruppe beitreten',
      },
      import_file_contain_invalid_path: '#808 Importierte SVG-Datei enthält ungültigen Bildpfad. Bitte stelle sicher, dass alle Bilddateien existieren oder betten Sie das Bild in die Datei',
      import_file_error_ask_for_upload: 'Importierte SVG-Datei konnte nicht importiert werden. Bist du bereit, dem Entwicklerteam eine Datei für den Fehlerbericht zur Verfügung zu stellen ?',
      layer_by_color: 'Farbe',
      layer_by_layer: 'Schicht',
      loading_image: 'Bild laden, bitte warten...',
      more_than_two_object: 'Zu viele Objekte. Es werden nur 2 Objekte unterstützt.',
      mouse: 'TouchPad',
      no_support_text: 'Beam Studio unterstützt derzeit keine Text-Tags. Bitte übertrage Text vor dem Importieren in den Pfad.',
      nolayer: 'Einzelne Schicht',
      not_support_object_type: 'Objekttyp wird nicht unterstützt.',
      or_turn_off_borderless_mode: ' Oder deaktivieren Sie den Open Bottom Modus.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Fehler: Befehl pdf2svg nicht gefunden. Bitte installieren Sie pdf2svg mit Ihrem Paketmanager (z. B. "yum install pdf2svg" oder "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Fehler beim Konvertieren von PDF in SVG:',
      },
      progress: {
        calculating: 'Berechnen',
        uploading: 'Hochladen',
      },
      questionnaire: {
        caption: 'Helfen Sie uns, den Fragebogen auszufüllen',
        message: 'Helfen Sie uns, den Fragebogen auszufüllen, um Beam Studio besser zu machen.',
        no_questionnaire_available: 'Derzeit ist kein Fragebogen verfügbar.',
        unable_to_get_url: 'Fehler beim Abrufen des Links zum Fragebogen über das Internet. Bitte bestätigen Sie Ihre Internetverbindung.',
      },
      recommend_downgrade_software: 'Wir haben eine ältere Firmware-Version erkannt. Wir arbeiten aktiv an der Behebung von Kompatibilitätsproblemen, aber vorerst empfehlen wir, auf <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a> zurückzukehren.',
      recommend_upgrade_firmware: 'Wir haben eine ältere Firmware-Version erkannt. Wir arbeiten aktiv an der Behebung von Kompatibilitätsproblemen, aber im Moment empfehlen wir ein Update auf die neueste Firmware.',
      save_unsave_changed: 'Nicht gespeicherte Änderungen speichern?',
      select_at_least_two: 'Wähle zwei Objekte zum Fortfahren aus.',
      select_first: 'Wähle zunächst ein Objekt aus.',
      select_import_method: 'Wählen Sie den Layering-Stil:',
      select_import_module: 'Modul auswählen:',
      sentry: {
        message: 'Stimmen Sie zu, relevante Informationen automatisch in das Entwicklungsteam hochzuladen, wenn Fehler auftreten?',
        title: 'Lassen Sie uns Beam Studio gemeinsam verbessern',
      },
      should_update_firmware_to_continue: '#814 Deine Firmware unterstützt diese Version von Beam Studio nicht. Bitte aktualisiere die Firmware, um fortzufahren. (Menü > Maschine > [Ihre Maschine] > Firmware aktualisieren)',
      speed_too_high_lower_the_quality: 'Die Verwendung einer zu hohen Geschwindigkeit bei dieser Auflösung kann zu einer geringeren Qualität der Gravur führen.',
      still_continue: 'Weiter',
      successfully_uploaded: 'Datei-Upload erfolgreich.',
      svg_1_1_waring: 'Die Version dieser SVG-Datei ist Version 1.1. Möglicherweise treten Inkompatibilitätsprobleme auf.',
      svg_image_path_waring: 'Diese SVG-Datei enthält das Laden von <image> aus dem Dateipfad. Dies kann beim Laden zu Fehlern führen.\nUm dieses Risiko zu vermeiden, verwenden Sie beim Exportieren von SVG bitte das eingebettete Bild.',
      text_to_path: {
        caption: 'Text-zu-Pfad-Konverter 2.0',
        message: 'Beam Studio führt nun einen neuen Text-zu-Pfad-Konverter (Konverter 2.0) ein, der zuverlässigere Ergebnisse liefert! Möchten Sie jetzt umsteigen? \nSie können diese Einstellung auch später im Abschnitt "Text-zu-Pfad-Konverter" in den Einstellungen anpassen.',
      },
      too_fast_for_auto_feeder: 'Die Verwendung einer zu hohen Geschwindigkeit in Schichten mit Pfadobjekten kann die Präzision beim Schneiden verringern.\nWir empfehlen keine höhere Geschwindigkeit als %(limit)s beim Schneiden mit dem Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'Folgende Schichten: %(layers)s \nenthalten Vektorpfade und überschreiten die Geschwindigkeit von %(limit)s.\nDie Schneidegeschwindigkeit für Vektorpfade mit dem Auto Feeder wird auf %(limit)s begrenzt.\nSie können dieses Limit in den Voreinstellungen entfernen.',
      too_fast_for_curve: 'Die Verwendung zu hoher Geschwindigkeiten für Schichten mit Kurvengravurobjekten kann die Gravurpräzision verringern.\nWir empfehlen, die Geschwindigkeit unter %(limit)s zu halten, um optimale Ergebnisse zu erzielen.',
      too_fast_for_curve_and_constrain: 'Folgende Schichten: %(layers)s überschreiten das Geschwindigkeitslimit von %(limit)s.\nDie Gravurgeschwindigkeit für Kurvenobjekte wird auf %(limit)s begrenzt. Sie können dieses Limit in den Einstellungen entfernen.',
      too_fast_for_path: 'Die Verwendung einer zu hohen Geschwindigkeit in Ebenen mit Pfadobjekten, kann zu einer geringeren Präzision beim Schneiden führen.\nWir empfehlen, beim Schneiden keine Geschwindigkeit von mehr als %(limit)s zu verwenden.',
      too_fast_for_path_and_constrain: 'Folgende Ebenen: %(layers)s \nenthalten Vektorpfadobjekte und eine Geschwindigkeit von mehr als %(limit)s \nDie Schnittgeschwindigkeit von Vektorpfadobjekten wird auf %(limit)s reduziert.\nDu kannst diese Beschränkung unter Präferenzen-Einstellungen entfernen.',
      touchpad: 'TouchPad',
      ungroup_use: 'Dadurch wird die Gruppierung von importiertem DXF oder SVG aufgehoben. Da die Datei möglicherweise eine große Anzahl von Elementen enthält, kann das Aufheben der Gruppierung einige Zeit dauern. Sind Sie sicher, fortzufahren?',
      upload_failed: '#819 Datei-Upload fehlgeschlagen.',
      upload_file_too_large: '#819 Datei ist zu groß zum Hochladen.',
      vectorize_shading_image: 'Das Vektorisieren von Schattierungsbildern dauert länger und ist anfällig für Rauschen. Bitte schalten Sie die Bildschattierung aus, bevor Sie sie ausführen.',
    },
    rating_panel: {
      description: 'Wenn Sie Beam Studio mögen, würden wir uns sehr freuen, wenn Sie uns bewerten könnten.',
      thank_you: 'Danke schön',
      title: 'Gefallen Sie Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Erweitert',
        backlash: 'Spiel',
        bi_directional: 'Bidirektional',
        by: 'Um',
        ce_z_high_speed: 'Hochgeschwindigkeits-Z-Achse',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Kanaleinstellung',
        color_adjustment_short: 'Kanal',
        color_strength: 'Stärke',
        cross_hatch: 'Kreuzschraffur',
        custom_preset: 'Benutzerdefiniert',
        cut: 'Ausschneiden',
        diode: 'Diodenlaser',
        do_not_adjust_default_para: 'Standardparameter kann nicht angepasst werden.',
        dottingTime: 'Punktierungszeit',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Gravur',
            acrylic_3mm_cutting: 'Acryl- 1/8″ Schneiden',
            acrylic_5mm_cutting: 'Acryl- 3/16″ Schneiden',
            acrylic_8mm_cutting: 'Acryl- 1/4″ Schneiden',
            acrylic_10mm_cutting: 'Acryl- 3/8″ Schneiden',
            acrylic_engraving: 'Acryl - Gravur',
            acrylic_printing: 'Acryl - Drucken',
            aluminum_engraving: 'Aluminium - Gravur',
            aluminum_light: 'Aluminium (hell)',
            bamboo_printing: 'Bambus - Drucken',
            black_abs: 'Schwarzes ABS',
            black_acrylic_3mm_cutting: 'Schwarzes Acryl - 1/8″ Schneiden',
            black_acrylic_5mm_cutting: 'Schwarzes Acryl - 3/16″ Schneiden',
            black_acrylic_engraving: 'Schwarzes Acryl - Gravur',
            brass_dark: 'Messing (dunkel)',
            brass_engraving: 'Messing - Gravur',
            brass_light: 'Messing (hell)',
            canvas_printing: 'Segeltuch - Drucken',
            cardstock_printing: 'Kartenmaterial - Drucken',
            copper: 'Kupfer',
            cork_printing: 'Kork - Drucken',
            denim_1mm_cutting: 'Denim - 1/32″ Schneiden',
            fabric_3mm_cutting: 'Stoff - 1/8″ Schneiden',
            fabric_5mm_cutting: 'Stoff - 3/16″ Schneiden',
            fabric_engraving: 'Stoff - Gravur',
            fabric_printing: 'Stoff - Drucken',
            flat_stone_printing: 'Flacher Stein - Drucken',
            glass_bw_engraving: 'Glas - Gravur',
            glass_printing: 'Glas - Drucken',
            gloss_leather_printing: 'Glänzendes Leder - Drucken',
            gold_engraving: 'Gold - Gravur',
            iron_engraving: 'Eisen - Gravur',
            leather_3mm_cutting: 'Leder - 1/8″ Schneiden',
            leather_5mm_cutting: 'Leder - 3/16″ Schneiden',
            leather_engraving: 'Leder - Gravur',
            mdf_3mm_cutting: 'Neuseeland MDF - 1/8″ Schneiden',
            mdf_5mm_cutting: 'Neuseeland MDF - 3/16″ Schneiden',
            mdf_engraving: 'Neuseeland MDF - Gravur',
            metal_bw_engraving: 'Metall - Gravur',
            opaque_acrylic: 'Undurchsichtiges Acryl',
            pc_printing: 'PC - Drucken',
            rubber_bw_engraving: 'Gummi - Gravur',
            silver_engraving: 'Silber - Gravur',
            stainless_steel_bw_engraving_diode: 'Metall - Gravur (Diodenlaser)',
            stainless_steel_dark: 'Edelstahl (dunkel)',
            stainless_steel_engraving: 'Edelstahl - Gravur',
            stainless_steel_light: 'Edelstahl (hell)',
            stainless_steel_printing: 'Rostfreier Stahl - Drucken',
            steel_engraving_spray_engraving: 'Metall - Gravur',
            stone: 'Stein',
            ti_engraving: 'Titan - Gravur',
            titanium_dark: 'Titan (dunkel)',
            titanium_light: 'Titan (hell)',
            white_abs: 'Weißes ABS',
            wood_3mm_cutting: 'Holz - 1/8″ Schneiden',
            wood_5mm_cutting: 'Holz - 3/16″ Schneiden',
            wood_7mm_cutting: 'Holz - 1/4″ Schneiden',
            wood_8mm_cutting: 'Holz - 1/4″ Schneiden',
            wood_10mm_cutting: 'Holz - 3/8″ Schneiden',
            wood_engraving: 'Holz - Gravur',
            wood_printing: 'Holz - Drucken',
          },
          mm: {
            abs_engraving: 'ABS - Gravur',
            acrylic_3mm_cutting: 'Acryl - 3mm Schneiden',
            acrylic_5mm_cutting: 'Acryl - 5mm Schneiden',
            acrylic_8mm_cutting: 'Acryl - 8mm Schneiden',
            acrylic_10mm_cutting: 'Acryl - 10mm Schneiden',
            acrylic_engraving: 'Acryl - Gravur',
            acrylic_printing: 'Acryl - Drucken',
            aluminum_engraving: 'Aluminium - Gravur',
            aluminum_light: 'Aluminium (hell)',
            bamboo_printing: 'Bambus - Drucken',
            black_abs: 'Schwarzes ABS',
            black_acrylic_3mm_cutting: 'Schwarzes Acryl - 3mm Schneiden',
            black_acrylic_5mm_cutting: 'Schwarzes Acryl - 5mm Schneiden',
            black_acrylic_engraving: 'Schwarzes Acryl - Gravur',
            brass_dark: 'Messing (dunkel)',
            brass_engraving: 'Messing - Gravur',
            brass_light: 'Messing (hell)',
            canvas_printing: 'Segeltuch - Drucken',
            cardstock_printing: 'Kartenmaterial - Drucken',
            copper: 'Kupfer',
            cork_printing: 'Kork - Drucken',
            denim_1mm_cutting: 'Denim - 1mm Schneiden',
            fabric_3mm_cutting: 'Stoff - 3mm Schneiden',
            fabric_5mm_cutting: 'Stoff - 5mm Schneiden',
            fabric_engraving: 'Stoff - Gravur',
            fabric_printing: 'Stoff - Drucken',
            flat_stone_printing: 'Flacher Stein - Drucken',
            glass_bw_engraving: 'Glas - Gravur',
            glass_printing: 'Glas - Drucken',
            gloss_leather_printing: 'Glänzendes Leder - Drucken',
            gold_engraving: 'Gold - Gravur',
            iron_engraving: 'Eisen - Gravur',
            leather_3mm_cutting: 'Leder - 3mm Schneiden',
            leather_5mm_cutting: 'Leder - 5mm Schneiden',
            leather_engraving: 'Leder - Gravur',
            mdf_3mm_cutting: 'Neuseeland MDF - 3mm Schneiden',
            mdf_5mm_cutting: 'Neuseeland MDF - 5mm Schneiden',
            mdf_engraving: 'Neuseeland MDF - Gravur',
            metal_bw_engraving: 'Metall - Gravur',
            opaque_acrylic: 'Undurchsichtiges Acryl',
            pc_printing: 'PC - Drucken',
            rubber_bw_engraving: 'Gummi - Gravur',
            silver_engraving: 'Silber - Gravur',
            stainless_steel_bw_engraving_diode: 'Metall - Gravur (Diodenlaser)',
            stainless_steel_dark: 'Edelstahl (dunkel)',
            stainless_steel_engraving: 'Edelstahl - Gravur',
            stainless_steel_light: 'Edelstahl (hell)',
            stainless_steel_printing: 'Rostfreier Stahl - Drucken',
            steel_engraving_spray_engraving: 'Metall - Gravur',
            stone: 'Stein',
            ti_engraving: 'Titan - Gravur',
            titanium_dark: 'Titan (dunkel)',
            titanium_light: 'Titan (hell)',
            white_abs: 'Weißes ABS',
            wood_3mm_cutting: 'Holz - 3mm Schneiden',
            wood_5mm_cutting: 'Holz - 5mm Schneiden',
            wood_7mm_cutting: 'Holz - 7mm Schneiden',
            wood_8mm_cutting: 'Holz - 8mm Schneiden',
            wood_10mm_cutting: 'Holz - 10mm Schneiden',
            wood_engraving: 'Holz - Gravur',
            wood_printing: 'Holz - Drucken',
          },
          parameters: 'Voreinstellungen',
          save: 'Aktuelle Parameter hinzufügen',
        },
        engrave: 'Gravieren',
        existing_name: 'Dieser Parametername wurde verwendet.',
        fill_angle: 'Füllwinkel',
        fill_interval: 'Füllintervall',
        fill_setting: 'Fülleinstellungen',
        filled_path_only: 'Nur für Füllpfade',
        focus_adjustment: 'Fokuseinstellung',
        frequency: 'Frequenz',
        gradient_only: 'Nur für Verlaufsbilder',
        halftone: 'Halbton',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Objekthöhe',
        ink_saturation: 'Sättigung',
        ink_type: {
          normal: 'Gewöhnliche Tinte',
          text: 'Tintentyp',
          UV: 'UV-Tinte',
        },
        laser_speed: {
          fast: 'Schnell',
          max: 300,
          min: 3,
          slow: 'Langsam',
          step: 0.1,
          text: 'Geschwindigkeit',
          unit: 'mm/s',
        },
        low_power_warning: 'Niedrigere Laserleistung (unter 10 %) emittiert das Laserlicht möglicherweise nicht.',
        low_speed_warning: 'Niedrigere Geschwindigkeit kann zu Verbrennungen des Materials führen.',
        lower_focus: 'Fokus Senken',
        lower_focus_desc: 'Senken der Fokushöhe um eine bestimmte Distanz nach dem Fokussieren, um die Schneidleistung zu verbessern.',
        module: 'Modul',
        more: 'Verwalten',
        multi_layer: 'Mehrere Schichten',
        para_in_use: 'Dieser Parameter wird verwendet.',
        parameters: 'Parameter',
        power: {
          high: 'Hoch',
          low: 'Niedrig',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Leistung',
        },
        preset_management: {
          add_new: 'Neu Hinzufügen',
          delete: 'Löschen',
          export: 'Export',
          export_preset_title: 'Parameter exportieren',
          import: 'Importieren',
          laser: 'Laser',
          lower_focus_by: 'Fokus Senken um',
          new_preset_name: 'Neuer Parametername',
          preset: 'Voreinstellung',
          print: 'Drucken',
          reset: 'Zurücksetzen',
          save_and_exit: 'Speichern und schließen',
          show_all: 'Alle Anzeigen',
          sure_to_import_presets: 'Dadurch wird die Anordnung der Voreinstellungen geladen und benutzerdefinierte Parameter überschrieben. Sind Sie sicher, dass Sie fortfahren möchten?',
          sure_to_reset: 'Dette vil slette dine tilpassede parametre og nulstille alle forudindstillinger, er du sikker på, at du vil fortsætte?',
          title: 'Parameter Verwalten',
          wobble_diameter: 'Wobble-Durchmesser',
          wobble_step: 'Wobble-Schrittgröße',
        },
        preset_setting: 'Parametereinstellung (%s)',
        presets: 'Voreinstellung',
        print_multipass: 'Mehrere Durchgänge',
        promark_speed_desc: 'Geschwindigkeitsparameter sind für Gradientenbilder nicht anwendbar.',
        pulse_width: 'Pulsbreite',
        pwm_advanced_desc: 'Stellen Sie eine minimale Leistung für den Tiefenmodus ein.',
        pwm_advanced_hint: 'Diese Einstellung gilt speziell für Gradientenbilder im Tiefenmodus.',
        pwm_advanced_setting: 'Leistungseinstellungen für den Tiefenmodus',
        repeat: 'Durchgangszahl',
        single_color: 'Einzelfarbe',
        single_color_desc: 'Nur anwendbar auf Vollfarb-Layer und nicht kompatibel mit erweitertem Einzelfarb-Layer.',
        slider: {
          fast: 'Schnell',
          high: 'Hoch',
          low: 'Niedrig',
          regular: 'Normal',
          slow: 'Langsam',
          very_fast: 'Am Schnellsten',
          very_high: 'Maximal',
          very_low: 'Minimal',
          very_slow: 'Am Langsamsten',
        },
        speed: 'Geschwindigkeit',
        speed_constrain_warning: 'Die Schnittgeschwindigkeit von Vektorpfadobjekten wird auf %(limit)s begrenzt. Sie können diese Grenze in den Einstellungen für Einstellungen entfernen.',
        speed_constrain_warning_auto_feeder: 'Die Schneidgeschwindigkeit der Auto Feeder-Objekte wird auf %(limit)s begrenzt. Sie können dieses Limit in den Einstellungen für Präferenzen entfernen.',
        speed_constrain_warning_curve_engraving: 'Die Geschwindigkeit der Kurvengravurobjekte wird auf %(limit)s begrenzt. Sie können dieses Limit in den Einstellungen entfernen.',
        stepwise_focusing: 'Schrittweises Fokussieren',
        stepwise_focusing_desc: 'Allmähliches Senken der Fokusdistanz basierend auf der Höhe des Objekts während jeder Durchgangszahl.',
        strength: 'Leistung',
        times: 'mal',
        various_preset: 'Mehrere Voreinstellungen',
        white_ink: 'Weiße Tinte',
        white_ink_settings: 'Einstellungen für Weiße Tinte',
        wobble: 'Wackeln',
        wobble_desc: 'Erstellt kleine Kreise entlang des Pfades, was für Schneidaufgaben von Vorteil ist. (Nur für Pfadobjekte.)',
        wobble_diameter: 'Durchmesser',
        wobble_step: 'Schrittgröße',
        z_step: 'Z Schritt',
      },
      layer_panel: {
        current_layer: 'Aktuelle Ebene',
        layer1: 'Ebene 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Schneiden',
        layer_engraving: 'Gravur',
        layers: {
          del: 'Ebene löschen',
          dupe: 'Ebene duplizieren',
          fullColor: 'Vollfarbe',
          layer: 'Ebene',
          layers: 'Ebenen',
          lock: 'Ebene sperren',
          merge_all: 'Alle zusammenführen',
          merge_down: 'Nach unten zusammenführen',
          merge_selected: 'Ausgewählte zusammenführen',
          move_down: 'Ebene nach unten verschieben',
          move_elems_to: 'Verschiebe ausgewählte Objekte:',
          move_selected: 'Verschiebe ausgewählte Objekte auf eine andere Ebene',
          move_up: 'Ebene nach oben verschieben',
          new: 'Neue Ebene',
          rename: 'Ebene umbenennen',
          splitFullColor: 'Ebene erweitern',
          switchToFullColor: 'Zu Vollfarben-Ebene wechseln',
          switchToSingleColor: 'Zu Einzelfarben-Ebene wechseln',
          unlock: 'Entsperren',
        },
        move_elems_to: 'Verschiebe ausgewählte Objekte:',
        notification: {
          dupeLayerName: 'TEine Ebene hat bereits diesen Namen',
          enterNewLayerName: 'Geben Sie bitte einen neuen Namen für die Ebene ein',
          enterUniqueLayerName: 'Verwenden Sie einen eindeutigen Namen für die Ebene',
          layerHasThatName: 'Eine Ebene hat bereits diesen Namen',
          mergeLaserLayerToPrintingLayerMsg: 'Bitte beachten Sie, dass bei Abschluss dieses Vorgangs die Einstellungen der Laser-Ebene entfernt und entsprechend der aktuellen Ebene eingestellt werden.',
          mergeLaserLayerToPrintingLayerTitle: 'Möchten Sie die Ebenen zu einer Druckebene zusammenführen?',
          mergePrintingLayerToLaserLayerMsg: 'Bitte beachten Sie, dass bei Abschluss dieses Vorgangs die Farbeinstellungen der Druckebene entfernt und entsprechend der aktuellen Ebene eingestellt werden.',
          mergePrintingLayerToLaserLayerTitle: 'Möchten Sie diese Ebenen zu einer Laser-Ebene zusammenführen?',
          moveElemFromPrintingLayerMsg: 'Bitte beachten Sie, dass bei Abschluss dieser Operation die Farbeinstellungen des ausgewählten Elements entfernt und entsprechend %s festgelegt werden.',
          moveElemFromPrintingLayerTitle: 'Ausgewähltes Element nach %s verschieben und in Laser-Element umwandeln?',
          moveElemToPrintingLayerMsg: 'Bitte beachten Sie, dass bei Abschluss dieser Operation die Einstellungen des ausgewählten Elements entfernt und entsprechend %s festgelegt werden.',
          moveElemToPrintingLayerTitle: 'Element auswählen und in Druckelement umwandeln, um es nach %s zu verschieben?',
          newName: 'NEUER NAME',
          QmoveElemsToLayer: "Verschiebe ausgewählte Objekte in die Ebene '%s'?",
          splitColorMsg: 'Bitte beachten Sie, dass Sie nach Fortsetzung dieser Operation nicht mehr zu den ursprünglichen Farbschichten zurückkehren können.',
          splitColorTitle: 'Die ausgewählte Ebene in CMYK-Ebenen erweitern?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'AI-Hintergrundentfernung',
          ai_bg_removal_reminder: 'Durch Drücken der Taste werden sofort 0,2 Credits verbraucht. Möchten Sie fortfahren?',
          ai_bg_removal_short: 'BG Entfernung',
          array: 'Anordnung',
          auto_fit: 'Automatische Anpassung',
          bevel: 'Fase',
          brightness: 'Helligkeit',
          convert_to_path: 'In Pfad konvertieren',
          create_textpath: 'Pfadtext erstellen',
          create_textpath_short: 'Pfadtext',
          crop: 'Ernte',
          decompose_path: 'Zerlegen',
          detach_path: 'Pfadtext aufteilen',
          detach_path_short: 'Zerlegen',
          disassemble_use: 'Zerlegen',
          disassembling: 'Zerlegen ...',
          edit_path: 'Pfad bearbeiten',
          fetching_web_font: 'Online Schriftstilen herunterladen...',
          grading: 'Benotung',
          invert: 'Farbe umkehren',
          offset: 'Offset',
          outline: 'Umriss',
          replace_with: 'Ersetzen mit...',
          replace_with_short: 'Ersetzen',
          sharpen: 'Schärfen',
          simplify: 'Vereinfachen',
          smart_nest: 'Smart Nest',
          trace: 'Verfolgen',
          ungrouping: 'Gruppierung aufheben...',
          uploading_font_to_machine: 'Schriftenstilen auf das Maschine aufladen...',
          wait_for_parsing_font: 'Schriftart analysieren ... Bitte warten.',
          weld_text: 'Text schweißen',
        },
        align: 'Ausrichten',
        boolean: 'Boolesch',
        bottom_align: 'Unten ausrichten',
        center_align: 'Zentrum ausrichten',
        difference: 'Ausschluss',
        distribute: 'Verteilen',
        flip: 'Flip',
        group: 'Gruppieren',
        hdist: 'Horizontal Abstand',
        hflip: 'Horizontal Spiegeln',
        intersect: 'Überschneidung',
        left_align: 'Links ausrichten',
        lock_aspect: 'Seitenverhältnis sperren',
        middle_align: 'Ausrichten Mitte',
        option_panel: {
          color: 'Farbe',
          fill: 'Füllen',
          font_family: 'Schriftart',
          font_size: 'Stil',
          font_style: 'Style',
          letter_spacing: 'Buchstaben-Abstand',
          line_spacing: 'Zeilenabstand',
          path_infill: 'Pfadfüllung',
          pwm_engraving: 'Tiefenmodus',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Gerundete Ecke',
          shading: 'Schattierung',
          sides: 'Seiten',
          start_offset: 'Text verschieben',
          stroke: 'Rahmen',
          stroke_color: 'Randfarbe',
          stroke_width: 'Rahmenbreite',
          text_infill: 'Textfüllung',
          threshold: 'Schwellenhelligkeit',
          threshold_short: 'Schwellenwert',
          vertical_align: 'Ausrichtung',
          vertical_text: 'Vertikaler Text',
        },
        path_edit_panel: {
          connect: 'Verbinden',
          delete: 'Löschen',
          disconnect: 'Trennen',
          node_type: 'Knotentyp',
          round: 'Glatt',
          sharp: 'Scharf',
        },
        right_align: 'Rechts ausrichten',
        subtract: 'Differenz',
        top_align: 'Oben ausrichten',
        ungroup: 'Gruppierung aufheben',
        union: 'Vereinigung',
        unlock_aspect: 'Seitenverhältni entsperren',
        vdist: 'Vertikal Abstand',
        vflip: 'Vertical Spiegeln',
        zoom: 'Zoomen',
      },
      tabs: {
        layers: 'Ebenen',
        objects: 'Objects',
        path_edit: 'Pfadbearbeitung',
      },
      uv_print_block: {
        title: 'UV-Druck',
      },
    },
    shapes_panel: {
      animals: 'Tier',
      arrow: 'Pfeil',
      basic: 'Grundlegend',
      birds: 'Vogel',
      celebration: 'Feier',
      circular: 'Kreisförmig',
      CNY: 'Chinesisches Neujahr',
      corner: 'Ecke',
      decor: 'Dekor',
      easter: 'Ostern',
      elements: 'Elemente',
      environment: 'Umwelt',
      graphics: 'Grafiken',
      halloween: 'Halloween',
      holidays: 'Feiertag',
      label: 'Symbol',
      land: 'Land',
      line: 'Linie',
      nature: 'Natur',
      photo: 'Foto',
      plants: 'Pflanzen',
      ribbon: 'Band',
      sea: 'Meer',
      shape: 'Form',
      speech: 'Rede',
      text: 'Textfeld',
      title: 'Elemente',
      valentines: 'Valentinstag',
      weather: 'Wetter',
      Xmas: 'Weihnachten',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Bild konnte nicht aus der Zwischenablage abgerufen werden',
      unnsupported_file_type: 'Der Dateityp wird nicht direkt unterstützt. Bitte konvertiere die Datei in SVG oder Bitmap.',
    },
    tag: {
      g: 'Gruppe',
      image: 'Bild',
      text: 'Text',
      use: 'SVG importieren',
    },
    time_est_button: {
      calculate: 'Zeit berechnen',
      estimate_time: 'Geschätzte benötigte Zeit:',
    },
    tool_panels: {
      _nest: {
        end: 'Schließen',
        no_element: 'Es ist kein Element zu arrangieren.',
        rotations: 'Mögliche Drehung',
        spacing: 'Abstand',
        start_nest: 'Ordnen',
        stop_nest: 'Halt',
      },
      _offset: {
        corner_type: 'Ecke',
        direction: 'Versatzrichtung',
        dist: 'Versetzte Entfernung',
        fail_message: 'Fehler beim Versetzen von Objekten.',
        inward: 'Innere',
        not_support_message: 'Ausgewählte Elemente mit nicht unterstütztem SVG-Tag:\nBild, Gruppe, Text, Importierte Objekt.',
        outward: 'Nach außen',
        round: 'Runden',
        sharp: 'Scharf',
      },
      array_dimension: 'Array-Dimension',
      array_interval: 'Array-Intervall',
      cancel: 'Stornieren',
      columns: 'Spalten',
      confirm: 'Bestätigen',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Grid Array erstellen',
      nest: 'Arrangement-Optimierung',
      offset: 'Offset',
      rows: 'Zeilen',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'An Fenster anpassen',
    },
  },
  boxgen: {
    add_option: 'Option hinzufügen',
    basic_box: 'Basic Box',
    beam_radius: 'Strahlradius',
    beam_radius_warning: 'Entfernen Sie die Kerfkompensation, wenn die Kanten oder Verbindungen der Box kurz sind, um die Montage der Box sicherzustellen',
    cancel: 'Abbrechen',
    coming_soon: 'Bald verfügbar',
    continue_import: 'Weiter importieren',
    control_tooltip: 'Linke Maustaste zum Drehen\nScrollen zum Zoomen\nRechte Maustaste zum Schwenken',
    control_tooltip_touch: 'Ziehen zum Drehen\nZusammenziehen zum Zoomen\nZwei-Finger zum Schwenken',
    cover: 'Abdeckung',
    customize: 'Anpassen',
    depth: 'Tiefe',
    edge: 'Kante',
    finger: 'Finger',
    finger_warning: 'Die innere Seitenlänge des Kastens muss mindestens 6 mm (0,24 Zoll) betragen, um mit dem Fingerfügen kompatibel zu sein.',
    height: 'Höhe',
    import: 'Importieren',
    inner: 'Innere',
    joints: 'Verbindung',
    max_dimension_tooltip: 'Max Breite/Höhe/Tiefe Einstellung ist %s.',
    merge: 'Zusammenführen',
    outer: 'Äußere',
    reset: 'Zurücksetzen',
    tCount: 'T Anzahl',
    tCount_tooltip: 'Die T Anzahl bezieht sich auf die kurze Seite; die Menge auf der langen Seite wird anhand ihrer Länge berechnet.',
    tDiameter: 'T Durchmesser',
    text_label: 'Textbeschriftung',
    thickness: 'Dicke',
    title: 'BOXGEN',
    tLength: 'T Länge',
    tSlot: 'T-Nut',
    tSlot_warning: 'Die Kastenseitenlänge muss mindestens 30 mm (1,18 Zoll) betragen, um mit dem T-Schlitz-Fügen kompatibel zu sein.',
    volume: 'Volumen',
    width: 'Breite',
    workarea: 'Arbeitsbereich',
    zoom: 'Vergrößern',
  },
  buttons: {
    back: 'Zurück',
    back_to_beam_studio: 'Zurück zu Beam Studio',
    done: 'Anwenden',
    next: 'Nächster',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Drücken Sie 3 Sekunden lang auf das "AF"-Symbol auf der Hauptseite der Maschine und lassen Sie die Sonde den Fokussierblock berühren.',
    ador_autofocus_material: 'Drücken Sie das "AF"-Symbol auf der Hauptseite des Geräts 3 Sekunden lang und lassen Sie die Sonde sanft das Material berühren.',
    align_ilb: 'Position: Richten Sie sich am gravierten Punkt unten links innen.',
    align_ilt: 'Position: Richten Sie sich am gravierten Punkt oben links innen.',
    align_irb: 'Position: Richten Sie sich am gravierten Punkt unten rechts innen.',
    align_irt: 'Position: Richten Sie sich am gravierten Punkt oben rechts innen.',
    align_olb: 'Position: Richten Sie sich am gravierten Punkt unten links außen aus.',
    align_olt: 'Position: Richten Sie sich am gravierten Punkt oben links außen aus.',
    align_orb: 'Position: Richten Sie sich am gravierten Punkt unten rechts außen aus.',
    align_ort: 'Position: Richten Sie sich am gravierten Punkt oben rechts außen aus.',
    align_red_cross_cut: 'Bitte alignieren Sie die Mitte des roten Kreuzes mit dem geschnittenen Kreuz.',
    align_red_cross_print: 'Bitte alignieren Sie die Mitte des roten Kreuzes mit dem gedruckten Kreuz.',
    analyze_result_fail: 'Konnte das aufgenommene Bild nichtanalysieren. <br/> Bitte stelle Folgendes sicher: <br/>1. Das aufgenommene Bild ist vollständig mit weißem Papier bedeckt.<br/>2. Die Plattform ist richtig fokussiert.',
    ask_for_readjust: 'Möchten Sie den Schneideschritt überspringen und Bilder und Kalibrierungen aufnehmen?',
    back: 'Zurück',
    calculating_camera_matrix: 'Berechnung der Kameramatrix...',
    calculating_regression_parameters: 'Berechnung der Regressionsparameter...',
    calibrate_camera_before_calibrate_modules: 'Bitte führen Sie zuerst eine Kamerakalibrierung durch, bevor Sie die Module kalibrieren.',
    calibrate_chessboard_success_msg: 'Das Schachbrettfoto wurde erfolgreich aufgenommen.<br/>Die Punktzahl für dieses Foto beträgt %s (%.2f).',
    calibrate_done: 'Kalibrierung abgeschlossen. Eine bessere Kameragenauigkeit ist gegeben, wenn genau fokussiert wird.',
    calibrate_done_diode: 'Kalibrierung durchgeführt. Offset des Diodenlasermodul wurde gespeichert.',
    calibrating: 'Kalibrierung...',
    calibrating_with_device_pictures: 'Kalibrieren mit Gerätebildern...',
    camera_calibration: 'Kamerakalibrierung',
    camera_parameter_saved_successfully: 'Kameraparameter erfolgreich gespeichert.',
    cancel: 'Stornieren',
    check_checkpoint_data: 'Checkpoint-Daten',
    check_device_pictures: 'Gerätebilder überprüfen',
    checking_checkpoint: 'Überprüfe Checkpoint-Daten...',
    checking_pictures: 'Überprüfe Gerätebilder...',
    diode_calibration: 'Kalibrierung des Diodenlasermodul',
    do_engraving: 'Gravieren',
    download_chessboard_file: 'Schachbrett-Datei herunterladen',
    downloading_checkpoint: 'Herunterladen von Checkpoint-Daten...',
    downloading_pictures: 'Bilder herunterladen...',
    drawing_calibration_image: 'Kalibrierungsbild zeichnen ...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Anheben und Schneiden',
    elevate_and_cut_step_1: 'Platzieren Sie ein A4-großes hellfarbenes Holz in der Mitte des Arbeitsbereichs und heben Sie es auf 20mm an.',
    elevate_and_cut_step_1_prism_lift: 'Verwenden Sie Ador Prism Lift mit einer maximalen Länge von 14mm zusammen mit einem Holz von mindestens 6mm Dicke.',
    failed_to_calibrate_camera: '#848 Fehler bei der Kamerakalibrierung, bitte kontaktieren Sie den FLUX Support.',
    failed_to_calibrate_chessboard: 'Kalibrierung mit dem Schachbrettbild fehlgeschlagen.',
    failed_to_calibrate_with_pictures: '#848 Kalibrierung mit Gerätebildern fehlgeschlagen.',
    failed_to_download_pictures: '#848 Fehler beim Herunterladen der Bilder, bitte kontaktieren Sie den FLUX Support.',
    failed_to_move_laser_head: 'Fehler beim Bewegen des Laserkopfs.',
    failed_to_parse_checkpoint: 'Fehler beim Analysieren der Checkpoint-Daten.',
    failed_to_save_calibration_results: '#849 Fehler beim Speichern der Kalibrierungsergebnisse, bitte versuchen Sie es erneut. Wenn dies weiterhin passiert, kontaktieren Sie bitte den FLUX Support.',
    failed_to_save_camera_parameter: 'Speichern der Kameraparameter fehlgeschlagen.',
    failed_to_solve_pnp: 'Die Kameraposition konnte nicht gelöst werden.',
    finish: 'Erledigt',
    found_checkpoint: 'Checkpoint-Daten auf Ihrem Gerät gefunden. Möchten Sie von dem Checkpoint wiederherstellen?',
    getting_plane_height: 'Ermittlung der Höhe der Ebene...',
    hint_adjust_parameters: 'Verwende diese Parameter, um das rote Quadrat anzupassen',
    hint_red_square: 'Bitte richten Sie das Äußere des roten Quadrats am ausgeschnittenen Quadrat aus.',
    module_calibration_2w_ir: 'Infrarot-Modul-Kalibrierung',
    module_calibration_printer: 'Druckermodul-Kalibrierung',
    moving_laser_head: 'Bewege den Laserkopf...',
    next: 'Nächster',
    no_picutre_found: '#846 Dein Gerät hat keine verfügbaren Rohfotos für die Kalibrierung. Bitte kontaktiere den FLUX-Support.',
    perform_autofocus_bb2: 'Bitte gehen Sie zum Maschinensteuerpult und drücken Sie AF, um den Autofokus auszuführen.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Beim Kalibrieren von Diodenlasermodul wird eine Kamera benötigt\nBitte stelle sicher, dass die Kamera dieser Maschine kalibriert wurde.\nUnd stelle bitte die Plattform auf den Brennpunkt ein (die Höhe des umgedrehten Acryls).',
      beamo: 'Beim Kalibrieren von Diodenlasermodul wird eine Kamera benötigt.\nBitte stelle sicher, dass die Kamera dieser Maschine kalibriert wurde.\nUnd stelle bitte den Laserkopf so ein, dass er auf das Gravurobjekt fokussiert ist (die Höhe des umgedrehten Acryls).',
    },
    please_goto_beambox_first: 'Bitte wechsle in den Gravurmodus (Beambox), um diese Funktion nutzen zu können.',
    please_place_dark_colored_paper: 'Bitte legen Sie ein A4- oder Letter-Format dunkles Papier in die Mitte des Arbeitsbereichs.',
    please_place_paper: 'Bitte lege ein weißes Papier im A4- oder Letter-Format in die linke obere Ecke des Arbeitsbereichs.',
    please_place_paper_center: 'Legen Sie ein A4- oder Letter-Format weißes Papier in die Mitte des Arbeitsbereichs.',
    please_refocus: {
      beambox: 'Stelle die Plattform bitte auf den Brennpunkt ein (die Höhe des umgedrehten Acryls).',
      beamo: 'Stelle den Laserkopf bitte so ein, dass er auf das Gravurobjekt fokussiert (Höhe des umgedrehten Acryls).',
      beamo_af: 'Tippen Sie bitte zweimal auf die Seitentaste des Autofokus-Add-Ons und lassen Sie die Sonde das Material sanft berühren.',
      hexa: 'Double click the height adjustment button to rise the honeycomb table up and make the probe touch the engraving material.',
    },
    preparing_to_take_picture: 'Vorbereitung zum Fotografieren...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Schachbrett Platzieren',
    put_chessboard_1: 'Bitte gehen Sie zum Maschinensteuerpult und drücken Sie AF, um den Autofokus auszuführen.',
    put_chessboard_2: 'Bitte bewegen Sie das Schachbrettpapier oder den Laserkopf, bis das gesamte Schachbrett im Vorschaufenster innerhalb des roten Rahmens bestätigt ist.',
    put_chessboard_3: 'Klicken Sie mit der rechten Maustaste, um das Schachbrettbild am aktuellen Ort herunterzuladen und dessen Klarheit zu überprüfen.',
    put_chessboard_bb2_desc_1: 'Laden Sie die folgende Schachbrettdatei herunter und drucken Sie sie auf A4-Papier aus (das gedruckte Schachbrett sollte Quadrate von 1x1 cm haben).',
    put_chessboard_bb2_desc_2: 'Befestigen Sie das gedruckte Schachbrettpapier auf einer unverformbaren Platte wie Acryl oder Glas und stellen Sie sicher, dass das Schachbrett glatt und frei von Falten oder Hebungen ist.',
    put_chessboard_bb2_desc_3: 'Legen Sie die Platte mit dem Schachbrett flach in die Mitte des Arbeitsbereichs.',
    put_chessboard_promark_1: 'Passen Sie den Brennabstand auf die geeignete Schärfe für das Feldobjektiv an.',
    put_chessboard_promark_2: 'Stellen Sie sicher, dass das Schachbrettmuster ohne Blendung klar sichtbar ist. Klicken Sie auf "Weiter", um ein Foto zu machen.',
    put_chessboard_promark_desc_1: 'Bitte verwenden Sie das im Zubehörkasten bereitgestellte Schachbrettmuster oder drucken Sie das folgende Schachbrettmuster auf A4-Papier für die Kamerakalibrierung.',
    put_chessboard_promark_desc_2: 'Stellen Sie den Fokus richtig ein und klicken Sie dann auf "Weiter", um mit dem Gravieren fortzufahren.',
    put_paper: 'Papier einlegen',
    put_paper_promark_1: 'Legen Sie die schwarze Pappe aus dem Zubehörkasten auf die Arbeitsplattform.',
    put_paper_promark_2: 'Stellen Sie den Fokus richtig ein und klicken Sie dann auf "Starte Gravur", um mit dem Gravieren fortzufahren.',
    put_paper_skip: 'Wenn die Kalibrierungszeichnung nicht automatisch mit der Aufnahme eines Bildes fortfährt, drücken Sie „Überspringen“, um ein Bild aufzunehmen.',
    put_paper_step1: 'Bitte legen Sie ein A4- oder Letter-Format weißes Papier in die Mitte des Arbeitsbereichs.',
    put_paper_step2: 'Sichern Sie die vier Ecken des Papiers, um sicherzustellen, dass es flach liegt.',
    put_paper_step3: 'Klicken Sie auf "Starte Gravur".',
    res_average: 'Durchschnittlich',
    res_excellent: 'Hervorragend',
    res_poor: 'Schlecht',
    retake: 'Foto erneut aufnehmen',
    rotation_angle: 'Drehung',
    show_last_config: 'Letztes Ergebnis anzeigen',
    skip: 'Überspringen',
    solve_pnp_step1: 'Bitte richten Sie die Gravurpunkte entsprechend der Nummer und der erwarteten Position jedes roten Markierungspunktes aus.',
    solve_pnp_step2: 'Sie können auf "Foto erneut aufnehmen" drücken, um die Ausrichtung zu wiederholen oder die Markierungspositionen manuell anzupassen.',
    solve_pnp_step3: "Wenn das aufgenommene Bild nicht mit der Realität übereinstimmt, bitte den Bildschirm zoomen oder verschieben, um den Kalibrierungspunkt auf der schwarzen Kalibrierkarte ins Zentrum zu bringen, und dann versuchen, das 'Tag billede igen'.",
    solve_pnp_title: 'Ausrichten von Markierungspunkten',
    start_engrave: 'Starte Gravur',
    start_printing: 'Drucken Starten',
    taking_picture: 'Foto machen...',
    unable_to_load_camera_parameters: "#851 Es sind keine Kamerakalibrierungsparameter auf Ihrem Gerät verfügbar. Gehen Sie zu 'Kalibrierung' > 'Kamera kalibrieren (Erweitert)', um die Kalibrierung abzuschließen und die Parameter zu erhalten.",
    update_firmware_msg1: 'Ihre Firmware unterstützt diese Funktion nicht. Bitte aktualisieren Sie die Firmware auf v',
    update_firmware_msg2: 'oder höher, um fortzufahren。 (Menü> Maschine> [Ihre Maschine]> Firmware aktualisieren)',
    uploading_images: 'Bilder hochladen...',
    use_last_config: 'Verwenden Sie den letzten Kalibrierungswert',
    use_old_camera_parameter: 'Möchten Sie die aktuellen Kameraparameter verwenden?',
    with_af: 'Mit Autofokus',
    with_prism_lift: 'Mit Ador Prism Lift',
    without_af: 'Ohne Autofukus',
    without_prism_lift: 'Ohne Ador Prism Lift',
    x_ratio: 'X-Verhältnis',
    y_ratio: 'Y-Verhältnis',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Kameradaten erfolgreich heruntergeladen.',
    downloading_data: 'Daten werden heruntergeladen...',
    estimated_time_left: 'Geschätzte verbleibende Zeit:',
    folder_not_exists: 'Ausgewählter Ordner existiert nicht.',
    incorrect_folder: 'Fehler beim Hochladen von Kalibrierungsdaten. Bitte überprüfen Sie, ob der ausgewählte Ordner korrekt ist.',
    no_picture_found: 'Kein Bild in der Maschine gefunden.',
    title: 'Kameradaten sichern',
    upload_success: 'Kameradaten erfolgreich hochgeladen.',
    uploading_data: 'Daten werden hochgeladen...',
  },
  caption: {
    connectionTimeout: 'Verbindungs-Timeout',
  },
  change_logs: {
    added: 'Hinzugefügt:',
    change_log: 'Protokolle ändern:',
    changed: 'Geändert:',
    fixed: 'Fest:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Siehe Ältere Versionen',
  },
  code_generator: {
    barcode: 'Barcode',
    qr_code: 'QR-Code',
  },
  curve_engraving: {
    '921': 'Automatische Fokussierung fehlgeschlagen.',
    '922': 'Fehlgeschlagen beim Messen mit rotem Licht.',
    amount: 'Menge',
    apply_arkwork: 'Kunstwerk auf Kurve anwenden',
    apply_camera: 'Kamerabild auf Kurve anwenden',
    click_to_select_point: 'Klicken, um Punkte zum erneuten Messen auszuwählen oder abzuwählen.',
    coloumns: 'Spalten',
    column_gap: 'Spaltenabstand',
    err_object_over_range: 'Das Objekt überschreitet den Messbereich.',
    failed_to_take_reference: 'Referenzaufnahme fehlgeschlagen',
    gap: 'Lücke',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Autofokusbereich Messen',
    preview_3d_curve: 'Vorschau der Kurvengravur',
    remeasure: 'Erneut messen',
    remeasuring_points: 'Punkte erneut messen...',
    reselect_area: 'Bereich erneut auswählen',
    row_gap: 'Reihenabstand',
    rows: 'Reihen',
    set_object_height: 'Objekt-Höhe einstellen',
    set_object_height_desc: 'Messen Sie die maximale Dicke des Objekts.',
    start_autofocus: 'Autofokus starten',
    starting_measurement: 'Messung starten...',
    sure_to_delete: 'Möchten Sie die Fokussierungsdaten für die Kurvengravur löschen?',
    take_reference: 'Referenz nehmen',
    take_reference_desc: 'Bitte bewegen Sie den Laser-Kopf zum höchsten Punkt des Objekts, senken Sie die Fokussier-Sonde ab und klicken Sie auf „Bestätigen“, um zu fokussieren.',
  },
  device: {
    abort: 'Abbrechen',
    aborted: 'Abgebrochen',
    aborting: 'Abbruch',
    busy: 'Beschäftigt',
    cartridge_info_read_failed: 'Bitte stellen Sie sicher, dass die Tintenpatrone vollständig eingesteckt ist. Versuchen Sie, die Tinte zu entfernen und erneut einzusetzen.',
    cartridge_info_verification_failed: 'Bitte überprüfen Sie, ob Ihre Tinte FLUX authentisch ist.',
    cartridge_serial_number: 'Tintenpatronen-Seriennummer',
    close_door_to_read_cartridge_info: 'Um auf Tinteninformationen zuzugreifen, schließen Sie bitte die Türabdeckung der Maschine.',
    completed: 'Abgeschlossen',
    completing: 'Abschliessen',
    deviceList: 'Maschinenliste',
    disable: 'Deaktivieren',
    disconnectedError: {
      caption: 'Maschine nicht angeschlossen',
      message: 'Bitte bestätige, ob ein Netzwerkzugriff von %s verfügbar ist',
    },
    enable: 'Aktivieren',
    firmware_version: 'Firmware Version',
    ink_color: 'Tintenfarbe',
    ink_level: 'Verbleibende Tintenmenge',
    ink_type: 'Tintenart',
    IP: 'IP',
    model_name: 'Modellname',
    occupied: 'in Wartung',
    pause: 'Pause',
    paused: 'Pause',
    pausedFromError: 'Wegen Fehler angehalten',
    pausing: 'Pause',
    please_wait: 'Bitte warten...',
    preparing: 'Vorbereiten',
    processing: 'wird bearbeitet',
    quit: 'Verlassen',
    ready: 'Bereit',
    reset: 'Zurücksetzen (Kick)',
    resuming: 'Wiederaufnahme',
    retry: 'Wiederholen',
    running: 'am Arbeiten',
    scanning: 'am Scannen',
    select: 'Wählen',
    select_printer: 'Wähle Drucker',
    serial_number: 'Seriennummer',
    start: 'Start',
    starting: 'Beginnend',
    status: 'Status',
    submodule_type: 'Modul',
    toolhead_change: 'Werkzeugkopf wechseln',
    unknown: 'Unbekannt',
    uploading: 'am Hochladen',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Wir können Ihren Computer nicht im Netzwerk finden.\nBefolgen Sie bitte <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">die Anleitung</a>, um das Verbindungsproblem zu beheben.',
    no_device_web: "#801 Überprüfen Sie bitte den Status Ihrer Maschine oder klicken Sie unten auf 'Maschineneinrichtung', um die Maschine einzurichten.",
    select_usb_device: 'USB-Gerät auswählen',
  },
  editor: {
    exposure: 'Vorschau Belichtung',
    opacity: 'Vorschau Transparenz',
    prespray_area: 'Grundierbereich',
  },
  error_pages: {
    screen_size: 'Please note that Beam Studio may not work optimally on your device. For an optimal experience, it should be a device with a screen width of at least 1024 pixels width.',
  },
  flux_id_login: {
    connection_fail: '#847 Verbindung zum FLUX Mitgliederservice fehlgeschlagen.',
    email: 'Email',
    flux_plus: {
      access_monotype_feature: 'Sie haben kein Monotype-Schriften-Add-on.',
      access_monotype_feature_note: 'Sie müssen FLUX+ Pro-Mitgliedschaft oder Monotype-Schriften-Add-on haben, um auf diese Funktion zuzugreifen.',
      access_plus_feature_1: 'Sie greifen auf eine',
      access_plus_feature_2: 'Funktion zu.',
      access_plus_feature_note: 'Sie müssen FLUX+ Mitgliedschaft haben, um auf diese Funktion zuzugreifen.',
      ai_credit_tooltip: 'Für KI-Hintergrundentfernung',
      explore_plans: 'Erkunden Sie FLUX+ Pläne',
      features: {
        ai_bg_removal: 'AI-Hintergrundentfernung',
        boxgen: '3D-Box-Generator',
        dmkt: '1000+ Design-Dateien',
        monotype: '250+ Premium-Schriftarten',
        my_cloud: 'Unbegrenzter Cloudspeicher',
      },
      flux_credit_tooltip: 'Für Designmarktdateien und KI-Hintergrundentfernung',
      get_addon: 'Add-on holen',
      goto_member_center: 'Zum Mitgliederzentrum gehen',
      learn_more: 'Mehr erfahren',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Jetzt abonnieren',
      thank_you: 'Vielen Dank für Ihre Mitgliedschaft!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Passwort vergessen?',
    incorrect: 'Email Adresse oder Passwort ist falsch.',
    login: 'Einloggen',
    login_success: 'Anmeldung erfolgreich',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Neu bei FLUX? Erstellen Sie einen Account.',
    not_verified: 'Die Email Adresse ist noch nicht bestätigt worden.',
    offline: 'Offline arbeiten',
    password: 'Passwort',
    register: 'FLUX-Benutzerkonto erstellen',
    remember_me: 'Passwort merken',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Melden Sie sich an, um die shapes Datenbank freizuschalten.',
    work_offline: 'Offline arbeiten',
  },
  framing: {
    area_check: 'Bereichsprüfung',
    areacheck_desc: 'Stellt die Sicherheit des Arbeitsbereichs sicher, indem der Begrenzungsrahmen des Objekts und die Beschleunigungszone des Laserkopfs angezeigt werden.',
    calculating_task: 'Aufgabe wird berechnet...',
    framing: 'Rahmung',
    framing_desc: 'Zeigt die Begrenzungsbox des Objekts in der Vorschau an.',
    hull: 'Hülle',
    hull_desc: 'Zeigt eine Form, die dem Design eng folgt, wie ein Gummiband, das um das Objekt gewickelt ist.',
    low_laser: 'Niedriger Laser',
    low_laser_desc: 'Stellen Sie einen niedrigen Laserwert für die Rahmenaufgabe ein.',
    rotate_axis: 'Drehachse',
    rotateaxis_desc: 'Vorschau der Rotationsachse, die Achse selbst bewegt sich nicht.',
    rotation_frame_warning: 'Die Rahmenvorschau dreht sich einmal. Bitte nicht währenddessen stoppen, um eine Verschiebung der Markierung zu vermeiden.',
    rotation_framing_desc: 'Vorschau des gesamten Drehbereichs, die Achse dreht sich gemäß der Vorschau.',
    start_task: 'Aufgabe Starten',
    start_task_description: 'Nach Abschluss der Rahmenvorschau klicken Sie auf „Aufgabe Starten“, um den Auftrag einzureichen.',
  },
  general: {
    choose_folder: 'Ordner Auswählen',
    processing: 'Verarbeitung...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] Ein Statuskonflikt ist aufgetreten. Bitte wiederholen Sie die Aktion.',
    SUBSYSTEM_ERROR: '[SE] Fehler beim Ausführen der Aufgabe durch die Maschinenfirmware. Bitte starte die Maschine neu.',
    UNKNOWN_COMMAND: '[UC] Bitte aktualisieren Sie die Geräte-Firmware',
    UNKNOWN_ERROR: '[UE] Bitte starte Beam Studio neu',
  },
  global: {
    apply: 'Anwenden',
    back: 'Zurück',
    cancel: 'Abbrechen',
    editing: {
      redo: 'Wiederherstellen',
      reset: 'Zurücksetzen',
      undo: 'Rückgängig',
      zoom_in: 'Vergrößern',
      zoom_out: 'Verkleinern',
    },
    mode_conflict: 'Diese Option ist im aktuellen Modus nicht verfügbar.',
    ok: 'OK',
    save: 'Speichern',
    stop: 'Stopp',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Pinselgröße',
      description: 'Klicken oder ziehen Sie, um unerwünschte Bereiche manuell zu löschen.',
      title: 'Radierer',
    },
    magic_wand: {
      description: 'Wählen und entfernen Sie angrenzende Bereiche basierend auf Farbähnlichkeit.',
      title: 'Zauberstab',
      tolerance: 'Toleranz',
    },
    title: 'Bild bearbeiten',
  },
  initialize: {
    back: 'Zurück',
    cancel: 'Stornieren',
    confirm: 'Bestätigen',
    connect: 'Verbinden',
    connect_ethernet: {
      title: 'Direkte Verbindung',
      tutorial1: '1. Verbinden Sie das Gerät mit einem Ethernet-Kabel mit Ihrem Computer.',
      tutorial2_1: '2. Befolgen ',
      tutorial2_2: ', um Ihren Computer als Router zu verwenden',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'Sie dieser Anleitung',
      tutorial3: '3. Klicken Sie auf "Nächster"',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Verbindung zum Server konnte nicht hergestellt werden. Starten Sie Beam Studio neu und versuchen Sie es erneut.',
      },
      check_camera: 'Überprüfung der Kameraverfügbarkeit',
      check_connection: 'Überprüfen der Maschinenverbindung',
      check_firmware: 'Firmware-Version überprüfen',
      check_ip: 'Überprüfen der IP-Verfügbarkeit',
      check_swiftray_connection: 'Serververbindung wird überprüft',
      check_swiftray_connection_unreachable: 'Server nicht erreichbar',
      check_usb: 'Überprüfen Sie die USB-Verbindung',
      enter_ip: 'Geben Sie die IP Ihres Computers ein',
      finish_setting: 'Einstellung beenden',
      invalid_format: 'Ungültiges Format',
      invalid_ip: 'IP ungültig: ',
      promark_hint: 'Wenn Sie wiederholt keine Verbindung über USB herstellen können, lesen Sie den <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">Artikel im Hilfe-Center</a>.',
      retry: 'Wiederholen',
      starts_with_169254: 'Beginnt mit 169.254',
      succeeded_message: 'Erfolgreich verbunden 🎉',
      unreachable: 'IP nicht erreichbar',
    },
    connect_usb: {
      connect_camera: 'Verbinden Sie die Kamera der Maschine mit Ihrem Computer über ein USB-Kabel.',
      title: 'USB-Verbindung',
      title_sub: ' (Nur für HEXA und Ador)',
      turn_off_machine: 'Schalten Sie Ihre Maschine aus.',
      turn_on_machine: 'Schalten Sie die Maschine ein.',
      tutorial1: 'Verbinden Sie die Maschine mit Ihrem Computer über das USB-Kabel.',
      tutorial2: "Klicken Sie auf 'Nächster'.",
      wait_for_turning_on: "Klicken Sie auf 'Nächster', nachdem der Startvorgang abgeschlossen ist und Sie den Hauptbildschirm betreten haben.",
    },
    connect_wifi: {
      title: 'Verbindung zu Wi-Fi',
      tutorial1: '1. Gehen Sie zu Touch Panel > Klicken Sie auf "Netzwerk" > "Stellen Sie eine Verbindung zu WiFi her".',
      tutorial1_ador: '1. Gehe zu Touch Panel > Klicke auf "MASCHINE" > Klicke auf "Netzwerk" > "Mit Wi-Fi verbinden".',
      tutorial2: '2. Wählen Sie Ihr bevorzugtes WLAN aus und verbinden Sie es.',
      what_if_1: 'Was ist, wenn ich mein WLAN nicht sehe?',
      what_if_1_content: '1. Der Wi-Fi-Verschlüsselungsmodus sollte WPA2 oder kein Kennwort sein.\n 2. Der Verschlüsselungsmodus kann in der Administrationsoberfläche des WLAN-Routers eingestellt werden. Wenn der Router WPA2 nicht unterstützt und Sie Hilfe bei der Auswahl des richtigen Routers benötigen, wenden Sie sich an den FLUX-Support.',
      what_if_2: 'Was ist, wenn ich kein WLAN sehe??',
      what_if_2_content: '1. Stellen Sie sicher, dass der Wi-Fi-Dongle vollständig angeschlossen ist.\n 2. Wenn auf dem Touchscreen keine MAC-Adresse des drahtlosen Netzwerks vorhanden ist, wenden Sie sich an den FLUX-Support.\n3. Der Wi-Fi-Kanal sollte 2.4 GHz betragen (5 GHz werden nicht unterstützt).',
    },
    connect_wired: {
      title: 'Verbindung zum kabelgebundenen Netzwerk herstellen',
      tutorial1: '1. Verbinden Sie das Gerät mit Ihrem Router.',
      tutorial2: '2. Drücken Sie "Netzwerk", um die IP des kabelgebundenen Netzwerks abzurufen.',
      tutorial2_ador: '2. Drücken Sie "MASCHINE" > "Netzwerk", um die IP des kabelgebundenen Netzwerks abzurufen.',
      what_if_1: 'Was ist, wenn die IP leer ist?',
      what_if_1_content: '1. Stellen Sie sicher, dass das Ethernet-Kabel vollständig eingesteckt ist.\n2. Wenn auf dem Touchscreen keine MAC-Adresse des kabelgebundenen Netzwerks vorhanden ist, wenden Sie sich an den FLUX-Support.',
      what_if_2: 'Was ist, wenn die IP mit 169 beginnt??',
      what_if_2_content: '1. Wenn die IP-Adresse mit 169.254 beginnt, sollte es sich um ein Problem mit der DHCP-Einstellung handeln. Wenden Sie sich an Ihren ISP (Internetdienstanbieter), um weitere Unterstützung zu erhalten.\n2. Wenn Ihr Computer über PPPoE eine direkte Verbindung zum Internet herstellt, wechseln Sie zur Verwendung des Routers, um eine Verbindung über PPPoE herzustellen, und aktivieren Sie die DHCP-Funktion im Router.',
    },
    connecting: 'Anschließen...',
    connection_types: {
      ether2ether: 'Direkte Verbindung',
      usb: 'USB-Verbindung',
      wifi: 'Wi-Fi',
      wired: 'Kabelgebundenes Netzwerk',
    },
    next: 'Nächster',
    no_machine: 'Ich habe jetzt keine Maschine.',
    promark: {
      configuration_confirmation: 'Dies stellt sicher, dass Ihr Promark für optimale Effizienz und Genauigkeit richtig konfiguriert ist.',
      or_complete_later: `Oder überspringen Sie diesen Schritt und vervollständigen Sie die Promark-Einstellungen später unter:
    Maschinen > „Promark Name“ > Promark-Einstellungen`,
      qc_instructions: 'Füllen Sie die Parameter auf der Rückseite der „QC Pass“-Karte aus',
      select_laser_source: 'Wählen Sie Ihren Promark',
      select_workarea: 'Arbeitsbereich auswählen',
      settings: 'Promark-Einstellungen',
    },
    retry: 'Wiederholen',
    select_beambox: 'Wählen Sie Ihren Beambox',
    select_connection_type: 'Wie möchten Sie eine Verbindung herstellen?',
    select_language: 'Sprache auswählen',
    select_machine_type: 'Wählen Sie Ihr Modell',
    setting_completed: {
      back: 'Zurück',
      great: 'Willkommen bei Beam Studio',
      ok: 'STARTE EIN PROJEKT',
      setup_later: 'Sie können Ihren Computer jederzeit über die Titelleiste> "Maschinen"> "Maschineneinrichtung" einrichten.',
      start: 'Start',
    },
    skip: 'Überspringen',
    start: 'Starten',
  },
  input_machine_password: {
    connect: 'VERBINDEN',
    password: 'Passwort',
    require_password: '%s erfordert ein Passwort',
  },
  insecure_websocket: {
    extension_detected: 'Beam Studio Connect-Erweiterung erkannt',
    extension_detected_description: "Wir haben festgestellt, dass Sie die Beam Studio Connect-Erweiterung installiert haben. Bitte klicken Sie auf 'Bestätige', um zu HTTPS umzuleiten, oder klicken Sie auf 'Abbrechen', um weiterhin HTTP zu verwenden.",
    extension_not_deteced: 'Beam Studio Connect-Erweiterung konnte nicht erkannt werden',
    extension_not_deteced_description: "Um HTTPS zu verwenden, klicken Sie bitte auf 'Bestätigen', um die Beam Studio Connect-Erweiterung zu installieren. Nach der Installation der Erweiterung aktualisieren Sie bitte die Seite, um sie zu aktivieren.<br/>Andernfalls klicken Sie auf den unten stehenden Link, um zu sehen, wie Sie HTTP in Chrome verwenden können.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: '2W Infrarotlaser',
    laser_10w_diode: '10W Diodenlaser',
    laser_20w_diode: '20W Diodenlaser',
    non_working_area: 'Nicht-Arbeitsbereich',
    none: 'Kein',
    notification: {
      convertFromLaserModuleMsg: 'Bitte beachten Sie, dass bei Abschluss dieser Operation die Einstellungen der Laser-Ebene entfernt und entsprechend der aktuellen Ebene festgelegt werden.',
      convertFromLaserModuleTitle: 'Möchten Sie das Lasermodul in ein Druckmodul umwandeln?',
      convertFromPrintingModuleMsg: 'Bitte beachten Sie, dass bei Abschluss dieser Operation die Farbeinstellungen der Druckebene entfernt und entsprechend der aktuellen Ebene festgelegt werden.',
      convertFromPrintingModuleTitle: 'Möchten Sie das Druckmodul in ein Lasermodul umwandeln?',
      importedDocumentContainsPrinting: 'Das Dokument enthält Druckebenen. Möchten Sie den Arbeitsbereich auf Ador ändern?',
      performIRCaliMsg: "Klicken Sie auf 'Bestätigen', um die Kalibrierung durchzuführen, oder greifen Sie über das Hauptmenü darauf zu.<br />(Maschine > [Ihr Maschinenname] > Infrarotmodul kalibrieren)",
      performIRCaliTitle: 'Führen Sie die Kalibrierung des Infrarotmoduls durch.',
      performPrintingCaliMsg: "Klicken Sie auf 'Bestätigen', um die Kalibrierung durchzuführen, oder greifen Sie über das Hauptmenü darauf zu.<br />(Maschine > [Ihr Maschinenname] > Druckmodul kalibrieren)",
      performPrintingCaliTitle: 'Führen Sie die Kalibrierung des Druckmoduls durch',
      printingLayersConverted: 'Die Druckschichten wurden in Laserschichten umgewandelt.',
    },
    printing: 'Drucken',
    unknown: 'Unbekanntes Modul',
    uv_print: 'UV-Druck',
  },
  machine_status: {
    '-17': 'Cartridge IO Mode',
    '-10': 'Modus beibehalten',
    '-2': 'Scannen',
    '-1': 'Aufrechterhaltung',
    0: 'Bereit',
    1: 'Initiieren',
    2: 'ST_TRANSFORM',
    4: 'Beginnend',
    6: 'Wiederaufnahme',
    16: 'Arbeiten',
    18: 'Wiederaufnahme',
    32: 'Pause',
    36: 'Pause',
    38: 'Pause',
    48: 'Pause',
    50: 'Pause',
    64: 'Abgeschlossen',
    66: 'Abschluss',
    68: 'Vorbereiten',
    128: 'Abgebrochen',
    256: 'Alarm',
    512: 'Fatal',
    UNKNOWN: 'Unbekannt',
  },
  material_test_generator: {
    block_settings: 'Blockeinstellungen',
    columns: 'Spalten',
    count: 'Anzahl',
    cut: 'Schneiden',
    engrave: 'Gravieren',
    export: 'Exportieren',
    max: 'Max',
    min: 'Min',
    parameter: 'Parameter',
    preview: 'Vorschau',
    rows: 'Zeilen',
    size: 'Größe (HxB)',
    spacing: 'Abstand',
    table_settings: 'Tabelleneinstellungen',
    text_settings: 'Texteinstellungen',
    title: 'Materialtest-Generator',
  },
  menu: {
    inches: 'Zoll',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Auth-Fehler: Bitte aktualisieren Sie Beam Studio und die Firmware des Computers auf die neueste Version.',
    authenticating: 'Authentifizierung...',
    camera: {
      abort_preview: 'Abbrechen',
      camera_cable_unstable: 'Es wird festgestellt, dass die Kamera Fotos instabil überträgt. Die Vorschau kann weiterhin normal ausgeführt werden, es kann jedoch das Problem einer langsamen Vorschau oder eines Zeitlimits auftreten.',
      continue_preview: 'Fortsetzen',
      fail_to_transmit_image: '#845 Bei der Bildübertragung ist ein Fehler aufgetreten. Bitte starten Sie Ihren Maschinen oder Beam Studio neu. Wenn dieser Fehler weiterhin besteht, befolgen Sie <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">diese Anleitung</a>.',
      ws_closed_unexpectly: '#844 Die Verbindung zur Maschinenkamera wurde unerwartet geschlossen. Wenn dieser Fehler weiterhin besteht, befolgen Sie <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402755805071">diese Anleitung</a>.',
    },
    cancelling: 'Abbrechen...',
    cant_connect_to_device: '#827 Das Gerät konnte nicht angeschlossen werden, bitte überprüfe Ihre Verbindung',
    connected: 'Verbunden',
    connecting: 'Verbinden...',
    connectingCamera: 'Kamera anschließen...',
    connectingMachine: 'Verbinden von %s...',
    connectionTimeout: '#805 Zeitlimit für Geräteverbindung. Bitte überprüfe den Zustand Ihres Netzwerks und die Wi-Fi-Anzeige Ihres Geräts.',
    device_blocked: {
      caption: 'Nicht autorisierte Seriennummer',
      offline: 'Die Seriennummer Ihres Geräts ist auf die Offline-Nutzung beschränkt, da sie deaktiviert zu sein scheint. Bitte geben Sie die Seriennummer des Geräts (auf der Rückseite des Geräts) an Ihren Händler weiter und bitten Sie ihn, support@flux3dp.com zu kontaktieren, um die Online-Autorisierung zu aktivieren. Wenn Sie das Gerät offline verwenden möchten, kontaktieren Sie bitte direkt support@flux3dp.com.',
      online: 'Die Seriennummer Ihres Geräts scheint deaktiviert zu sein. Bitte geben Sie die Seriennummer des Geräts (auf der Rückseite des Geräts) an Ihren Händler weiter und bitten Sie ihn, support@flux3dp.com zu kontaktieren, um die Geräteautorisierung zu aktivieren.',
    },
    device_busy: {
      caption: 'Maschine beschäftigt',
      message: 'Die Maschine führt eine andere Aufgabe aus, versuchen Sie es später noch einmal. Wenn sie nicht mehr funktioniert, starte die Maschine bitte neu.',
    },
    device_is_used: 'Die Maschine wird benutzt, möchten Sie die aktuelle Aufgabe abbrechen?',
    device_not_found: {
      caption: 'Standard-Maschine nicht gefunden',
      message: '#812 Bitte überprüfe die Wi-Fi-Anzeige Ihres Geräts',
    },
    disconnected: 'Verbindung instabil, Bitte überprüfe die Geräteverbindung und versuche es später erneut',
    endingLineCheckMode: 'Verlassen des Leitungsprüfmodus...',
    endingRawMode: 'Rohmodus verlassen...',
    enteringLineCheckMode: 'Aufrufen des Leitungsprüfmodus...',
    enteringRawMode: 'Rohmodus aufrufen...',
    enteringRedLaserMeasureMode: 'Roter Lasermessmodus wird gestartet...',
    exitingRotaryMode: 'Rotationsmodus verlassen...',
    getProbePosition: 'Sondenposition wird abgerufen...',
    gettingLaserSpeed: 'Laserkopfgeschwindigkeit ablesen...',
    homing: 'Homing...',
    need_password: 'Benötigen Passwort für die Verbindung mit der Maschine',
    please_enter_dpi: 'Bitte geben Sie die Einheit Ihrer Datei ein (in mm)',
    preview: {
      adjust: 'Anpassen',
      adjust_height_tooltip: 'Die ausgewählte Beispieldatei wird von der aktuellen Arbeitsfläche nicht unterstützt.',
      already_performed_auto_focus: 'Sie haben bereits den Autofokus durchgeführt. Möchten Sie die vorhandenen Werte verwenden?',
      auto_focus: 'Autofokus',
      auto_focus_instruction: 'Bewegen Sie bitte den Lasermodulkopf über das Objekt und befolgen Sie die Animationsanweisungen, um auf AF zu drücken, um den Fokus einzustellen.',
      camera_preview: 'Kameravorschau',
      enter_manually: 'Manuell eingeben',
      please_enter_height: 'Bitte geben Sie die Höhe des Objekts ein, um eine präzise Kameraaufnahme zu erfassen.',
    },
    promark_disconnected: '#850 Die Verbindung zum Gerät wurde unterbrochen, bitte überprüfen Sie den Verbindungsstatus des Geräts.',
    redLaserTakingReference: 'Referenz wird aufgenommen...',
    retrievingCameraOffset: 'Kamera-Offset lesen...',
    settingLaserSpeed: 'Einstellen der Laserkopfgeschwindigkeit...',
    swiftray_disconnected: 'Verbindung zum Backend fehlgeschlagen, erneuter Verbindungsversuch.',
    swiftray_reconnected: 'Das Backend wurde erneut verbunden, versuchen Sie erneut, den Auftrag zu senden.',
    time_remaining: 'Verbleibende Zeit:',
    tryingToConenctMachine: 'Versuch, eine Verbindung zur Maschine herzustellen...',
    turningOffAirPump: 'Luftpumpe ausschalten...',
    turningOffFan: 'Lüfter ausschalten...',
    unable_to_find_machine: 'Maschine konnte nicht gefunden werden',
    unable_to_start: '#830 Die Aufgabe konnte nicht gestartet werden. Bitte versuchen Sie es erneut. Wenn dies erneut geschieht, kontaktieren Sie uns bitte mit einem Fehlerbericht:\n',
    unavailableWorkarea: '#804 Der aktuelle Arbeitsbereich übersteigt den Arbeitsbereich dieser Maschine. Bitte überprüfe den Arbeitsbereich des ausgewählten Geräts oder stelle den Arbeitsbereich über Bearbeiten > Dokumenteinstellung ein.',
    unknown_device: '#826 Die Verbindung zum Gerät kann nicht hergestellt werden, Bitte stelle sicher, dass USB an das Gerät angeschlossen ist.',
    unknown_error: '#821 Die Anwendung ist auf einen unbekannten Fehler gestoßen, bitte benutzen Sie Hilfe > Menü > Fehlerbericht.',
    unsupport_osx_version: 'Die aktuelle macOS-Version %s unterstützt möglicherweise einige Funktionen nicht. Bitte aktualisieren Sie auf die macOS 11+.',
    unsupport_win_version: 'Die aktuelle Betriebssystemversion %s unterstützt möglicherweise einige Funktionen nicht. Bitte aktualisieren Sie auf die neueste Version.',
    unsupported_example_file: 'The selected example file is not supported by current workarea.',
    uploading_fcode: 'FCode hochladen',
    usb_unplugged: 'Die USB-Verbindung ist verloren gegangen. Bitte überprüfe Ihre USB-Verbindung',
    wrong_swiftray_version_message: 'Falsche Backend-Version (Version: {version}). Bitte überprüfen Sie, ob mehrere Instanzen von Beam Studio ausgeführt werden, und schließen Sie alle zusätzlichen Instanzen, bevor Sie es erneut versuchen.',
    wrong_swiftray_version_title: 'Falsche Backend-Version',
  },
  monitor: {
    ask_reconnect: 'Die Verbindung zur Maschine ist unterbrochen. Möchten Sie die Verbindung wiederherstellen?',
    bug_report: 'Fehlerbericht',
    camera: 'Kamera',
    cancel: 'Abbrechen',
    confirmFileDelete: 'Sind Sie sicher, dass Sie diese Datei löschen möchten?',
    connecting: 'Verbindung wird hergestellt. Bitte warten...',
    DEVICE_ERROR: 'Etwas ist schief gelaufen\nBitte starte die Maschine neu', // Deprecated in FW 3.3.1
    download: 'herunterladen',
    extensionNotSupported: 'Dieses Dateiformat wird nicht unterstützt.',
    fileExistContinue: 'Datei existiert bereits, möchten Sie sie ersetzen?',
    forceStop: 'Möchtest du die laufende Aufgabe abbrechen?',
    go: 'Start',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Luftstromsensor anomal',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Boden offen. Close the door to continue.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 schliesse die Tür, um fortzufahren.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Schublade geöffnet',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Flammensensor anomal',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Falsches Modul erkannt. Bitte installieren Sie das richtige Modul, um fortzufahren.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul nicht erkannt. Bitte stellen Sie sicher, dass das Modul ordnungsgemäß installiert ist, um fortzufahren.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Unbekanntes Modul erkannt. Bitte installieren Sie das richtige Modul, um fortzufahren.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off Error',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritischer Fehler: Mainboard offline. Bitte wenden Sie sich an den FLUX Support.', // Deprecated in FW 3.3.1
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Überhitzt. Bitte warten Sie einige Minuten.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Zurücksetzen der Z-Achse',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Druckermodul ohne Antwort.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Bitte ziehen Sie die Sonde zurück.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Bitte erkundigen Sie sich bei Ihrem Wassertank.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Kein Drehmodul erkannt',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritischer Fehler: Subsystem keine Antwort. Bitte wenden Sie sich an den FLUX Support', // Deprecated in FW 3.3.1
    HARDWARE_FAILURE: 'Etwas ist schief gelaufen\nBitte starte die Maschine neu', // Deprecated in FW 3.3.1
    hour: 'h',
    left: 'übrig',
    MAINBOARD_OFFLINE: '#905 Fehler beim Verbinden mit dem Mainboard.\nBitte starte die Maschine neu.',
    minute: 'm',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 Fehler beim Verbinden mit dem Mainboard.\nBitte starte die Maschine neu.',
    pause: 'Pause',
    prepareRelocate: 'Vorbereitung für den Umzug',
    processing: 'wird bearbeitet',
    record: 'Aufzeichnung',
    relocate: 'Umziehen',
    RESOURCE_BUSY: 'Die Maschine ist beschäftigt\nWenn es nicht läuft, starte den Computer neu',
    resume: 'Weitermachen',
    savingPreview: 'Beispiele generieren',
    second: 's',
    start: 'Start',
    stop: 'Halt',
    SUBSYSTEM_ERROR: '#402 Kritischer Fehler: Subsystem keine Antwort. Bitte wenden Sie sich an den FLUX Support.',
    task: {
      BEAMBOX: 'Laser-Gravur',
      'N/A': 'Freier Modus',
    },
    taskTab: 'Datei',
    temperature: 'Temperatur',
    upload: 'Hochladen',
    USER_OPERATION: 'Befolgen Sie bitte die Anweisungen auf dem Gerätepanel, um fortzufahren.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Bitte setzen Sie die richtige Patrone ein, um fortzufahren.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Bitte setzen Sie die Patrone ein, um fortzufahren.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Bitte installieren Sie das richtige Modul, um fortzufahren.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Falsches Modul erkannt. Bitte installieren Sie das richtige Modul, um fortzufahren.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul nicht erkannt. Bitte stellen Sie sicher, dass das Modul ordnungsgemäß installiert ist, um fortzufahren.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Unbekanntes Modul erkannt. Bitte installieren Sie das richtige Modul, um fortzufahren.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Bitte entfernen Sie die Patrone, um fortzufahren.',
    USER_OPERATION_ROTARY_PAUSE: 'Bitte wechseln Sie zum Rotationsmotor',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Möchten Sie diese Datei wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
      delete: 'Löschen',
      download: 'Herunterladen',
      duplicate: 'Duplizieren',
      open: 'Öffnen',
      rename: 'Umbenennen',
    },
    file_limit: 'Kostenlose Datei',
    loading_file: 'Lädt...',
    no_file_subtitle: 'Gehen Sie zum Menü > "Datei" > "In die Cloud speichern"',
    no_file_title: 'Speichern Sie Dateien in Meine Cloud, um loszulegen.',
    save_file: {
      choose_action: 'Datei speichern:',
      input_file_name: 'Dateinamen eingeben:',
      invalid_char: 'Ungültige Zeichen:',
      save: 'Speichern',
      save_new: 'Als neue Datei speichern',
      storage_limit_exceeded: 'Ihr Cloudspeicher hat das obere Limit erreicht. Bitte löschen Sie vor dem Speichern neuer Dateien alle nicht benötigten Dateien.',
    },
    sort: {
      a_to_z: 'Name: A - Z',
      most_recent: 'Neueste',
      oldest: 'Älteste',
      z_to_a: 'Name: Z - A',
    },
    title: 'Meine Cloud',
    upgrade: 'Aktualisieren',
  },
  noun_project_panel: {
    clear: 'Löschen',
    elements: 'Elemente',
    enjoy_shape_library: 'Genießen Sie die shapes Datenbank.',
    export_svg_title: 'SVG kann nicht exportiert werden',
    export_svg_warning: 'Dieses Projekt enthält Objekte, die durch das Gesetz zum Schutz des geistigen Eigentums geschützt sind. Daher schließt Beam Studio diese Objekte beim Export automatisch aus. Sie können Ihr Projekt weiterhin in Beam-Studio-Datei (.beam-Format) speichern, um alle Daten zu behalten. Möchten Sie noch exportieren?',
    learn_more: 'Erfahren Sie mehr',
    login_first: 'Melden Sie sich an, um die shapes Datenbank freizuschalten.',
    recent: 'Kürzlich',
    search: 'Suche',
    shapes: 'Elemente',
  },
  pass_through: {
    export: 'In Arbeitsbereich exportieren',
    exporting: 'Exportieren...',
    guide_mark: 'Führungsmarkierung',
    guide_mark_desc: 'Führungsmarkierungen werden als Referenzpunkt zur Ausrichtung des Kunstwerks eingraviert.',
    guide_mark_length: 'Länge:',
    guide_mark_x: 'X-Koordinate:',
    height_desc: 'Stellen Sie die Höhe jeder Arbeitsbereichssektion ein.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Wie richtet man den Durchlauf für %(model)s ein?',
    object_length: 'Objektlänge',
    ref_layer: 'Referenzschicht',
    ref_layer_desc: 'Bitte beachten Sie, dass die Ausführung der Referenzschicht standardmäßig auf 0 gesetzt ist. Sie wird nicht ausgeführt, sondern dient nur als Ausrichtungsreferenz.',
    ref_layer_name: 'Referenz',
    title: 'Durchlaufmodus',
    workarea_height: 'Arbeitsbereich (Höhe):',
  },
  promark_connection_test: {
    description: 'Das System führt etwa zwei Minuten lang einen Rotlichtbetrieb durch, um zu überprüfen, ob die Verbindung stabil ist.',
    health: 'Stabilität:',
    healthy_description: 'Die Stabilität ist gut, aber vermeiden Sie während der Gravur große Bewegungen des Computers.',
    res_0: 'Hervorragend',
    res_1: 'Gut',
    res_2: 'Niedrig',
    res_3: 'Sehr niedrig',
    res_4: 'Extrem niedrig',
    restart: 'Test neu starten',
    start: 'Test starten',
    stop: 'Test stoppen',
    title: 'Verbindungsstabilitätstest',
    unhealthy_description: 'Die Stabilität ist zu niedrig. Es wird empfohlen, den Netzwerkadapter oder das Netzkabel auszutauschen.',
  },
  promark_settings: {
    angle: 'Winkel',
    bulge: 'Wölbung',
    field: 'Feld',
    galvo_configuration: 'Galvo-Konfiguration',
    mark: 'Markieren',
    mark_parameters: 'Markierungsparameter',
    offsetX: 'Versatz X',
    offsetY: 'Versatz Y',
    preview: 'Vorschau',
    red_dot: 'Roter Punkt',
    scale: 'Skalierung',
    scaleX: 'Skalierung X',
    scaleY: 'Skalierung Y',
    skew: 'Schrägstellung',
    switchXY: 'X/Y wechseln',
    title: 'Promark-Einstellungen',
    trapezoid: 'Trapez',
    workarea_hint: 'Sie können den Arbeitsbereich in den "Dokumenteinstellungen" ändern.',
    z_axis_adjustment: {
      section1: 'Passen Sie die Höhe der Z-Achse an, um den Fokus fein abzustimmen.',
      title: 'Z-Achsen-Einstellung',
      tooltip1: 'Versuchen Sie, ein 1x1 cm Quadrat zu markieren, um zu überprüfen, ob die aktuelle Brennweite geeignet ist.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Fehlertoleranz',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Hintergrundfarbe umkehren',
    placeholder: 'Eingabe eines Links oder Textes',
    preview: 'Vorschau',
    title: 'QR-Code-Generator',
  },
  rotary_settings: {
    circumference: 'Umfang',
    extend_workarea: 'Arbeitsbereich erweitern',
    mirror: 'Spiegeln',
    object_diameter: 'Objektdurchmesser',
    overlap_size: 'Überlappungsgröße',
    rotary_scale: 'Drehskala',
    split_setting: 'Teilungseinstellung',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Teilungsgröße',
    type: 'Typ',
  },
  select_device: {
    auth_failure: '#811 Authentifizierung fehlgeschlagen',
    select_device: 'Gerät wählen',
    unable_to_connect: '#810 Es kann keine stabile Verbindung zur Maschine hergestellt werden',
  },
  settings: {
    anti_aliasing: 'Anti-Aliasing',
    auto_connect: 'Wählen Sie automatisch die einzige Maschine aus',
    auto_switch_tab: 'Automatisches Umschalten von Ebenen- und Objektpanel',
    autofocus_offset: 'Autofokus-Versatz',
    autosave_enabled: 'Automatisch Speichern',
    autosave_interval: 'Speichern Alle',
    autosave_number: 'Anzahl von Automatisch Speichern',
    autosave_path: 'Automatisch Speichern Speicherort',
    autosave_path_not_correct: 'Der angegebene Pfad wurde nicht gefunden.',
    blade_precut_position: 'Vorgeschnittene Position',
    blade_precut_switch: 'Klingenvorschnitt',
    blade_radius: 'Klingenradius',
    bottom_up: 'Von unten nach oben',
    calculation_optimization: 'Wegberechnungsbeschleunigung',
    cancel: 'Stornieren',
    caption: 'die Einstellungen',
    check_updates: 'Auto-Check',
    close: 'Schließen',
    confirm_remove_default: 'Die Standardmaschine wird entfernt.',
    confirm_reset: 'Bestätige das Zurücksetzen von Beam Studio?',
    continuous_drawing: 'Kontinuierliches Zeichnen',
    curve_engraving_speed_limit: 'Geschwindigkeitsbegrenzung für Kurvengravur',
    custom_preview_height: 'Benutzerdefinierte Vorschauhöhe',
    default_beambox_model: 'Standarddokumenteinstellung',
    default_borderless_mode: 'Boden offen',
    default_enable_autofocus_module: 'Standardeinstellung für Autofokusmodul',
    default_enable_diode_module: 'Diodenlaser Standard',
    default_font_family: 'Standardschriftart',
    default_font_style: 'Standardschriftstil',
    default_laser_module: 'Standard-Lasermodul',
    default_machine: 'Standardmaschine',
    default_machine_button: 'Leeren',
    default_units: 'Standardeinheiten',
    diode_offset: 'Diodenlaser Offset',
    diode_one_way_engraving: 'Diodenlaser-Einweggravur',
    diode_two_way_warning: 'Die bidirektionale Lichtemission ist schneller und führt wahrscheinlich zu einer gewissen Ungenauigkeit der Lasergravurposition. Empfehlen Sie zuerst zu testen.',
    disabled: 'ausgeschaltet',
    done: 'Anwenden',
    enable_custom_backlash: 'Benutzerdefinierte Backlash-Kompensation aktivieren',
    enable_low_speed: 'Langsame Bewegung aktivieren',
    enable_uv_print_file: 'UV-Druckdatei aktivieren',
    enabled: 'Aktiviert',
    engraving_direction: 'Gravurrichtung',
    fast_gradient: 'Geschwindigkeitsoptimierung',
    font_convert: 'Text-zu-Pfad-Konverter',
    font_substitute: 'Ersetzen Sie nicht unterstützte Zeichen',
    grouped_objects: 'Gruppierte Objekte',
    groups: {
      ador_modules: 'Ador-Modul',
      autosave: 'Automatisch Speichern',
      camera: 'Kamera',
      connection: 'Verbindung',
      editor: 'Editor',
      engraving: 'Rasterung (Scannen)',
      general: 'Allgemeines',
      mask: 'Arbeitsbereich-Ausschnitt',
      modules: 'Zusatz-Module',
      path: 'Vektor (Umrisse)',
      privacy: 'Privatsphäre',
      text_to_path: 'Text',
      update: 'Software-Updates',
    },
    guess_poke: 'Suchen Sie nach der IP-Adresse des Geräts',
    guides: 'Anleitungen',
    guides_origin: 'Referenzpunkt',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      uv_print_export: 'https://support.flux3dp.com/hc/en-us/articles/12320887477135',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Hoch',
    image_downsampling: 'Bitmap-Vorschau der Qualität',
    ip: 'IP-Adresse des Geräts',
    keep_preview_result: 'Vorschauergebnis Beibehalten',
    language: 'Sprache',
    loop_compensation: 'Schleifenkompensation',
    low: 'Niedrig',
    low_laser_for_preview: 'Laser für Frame-Vorschau',
    mask: 'Arbeitsbereich-Ausschnitt',
    medium: 'Mittel',
    module_offset_2w_ir: '2W Infrarotlaser-Versatz',
    module_offset_10w: '10W Diodenlaser-Versatz',
    module_offset_20w: '20W Diodenlaser-Versatz',
    module_offset_printer: 'Druckerversatz',
    none: 'Keiner',
    normal: 'Normal',
    notification_off: 'Aus',
    notification_on: 'An',
    notifications: 'Desktop-Benachrichtigungen',
    off: 'Aus',
    on: 'An',
    preview_movement_speed: 'Vorschau der Bewegungsgeschwindigkeit',
    printer_advanced_mode: 'Druckerfortgeschrittener Modus',
    remove_default_machine_button: 'Entfernen',
    reset: 'Beam Studio zurücksetzen',
    reset_now: 'Beam Studio zurücksetzen',
    segmented_engraving: 'Segmentierte Gravur',
    share_with_flux: 'Teilen Sie Beam Studio Analytics',
    simplify_clipper_path: 'Optimieren Sie den berechneten Pfad',
    single_object: 'Einzelobjekt',
    tabs: {
      device: 'Maschine',
      general: 'Allgemeines',
    },
    text_path_calc_optimization: 'Optimierung der Pfadberechnung',
    top_down: 'Von oben nach unten',
    trace_output: 'Bildverfolgungsausgabe',
    update_beta: 'Beta',
    update_latest: 'Neueste',
    vector_speed_constraint: 'Geschwindigkeitsbegrenzung',
    wrong_ip_format: 'Falsche IP-Formate',
  },
  social_media: {
    facebook: 'Chatte mit FLUXern, stelle Fragen und lerne Tipps!',
    instagram: 'Erhalte die neuesten Inspirationen, Angebote und Gratisartikel!',
    youtube: 'Schau dir Beam Studio Tutorials und Bastelideen an.',
  },
  support: {
    no_vcredist: 'Bitte installieren Sie Visual C ++ Redistributable 2015 <br/> Das kann auf flux3dp.com heruntergeladen werden',
    no_webgl: 'WebGL wird nicht unterstützt. Bitte verwenden Sie andere Geräte.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Bitte fügen Sie zuerst Objekte hinzu',
      door_opened: 'Bitte schließen Sie die Tür abdeckung, um Niedriglaser zum Ausführen des Rahmens zu aktivieren.',
      fail_to_connect_with_camera: '#803 Verbindung zur Maschinenkamera konnte nicht hergestellt werden. Bitte starten Sie Ihren Maschinen oder Beam Studio neu. Wenn dieser Fehler weiterhin besteht, befolgen Sie <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">diese Anleitung</a>.',
      fail_to_get_door_status: 'Bitte stellen Sie sicher, dass die Tür abdeckung geschlossen ist, um Niedriglaser zum Ausführen des Rahmens zu aktivieren.',
      fail_to_start_preview: '#803 Fehler beim Starten des Vorschaumodus. Bitte starten Sie Ihren Maschinen oder Beam Studio neu. Wenn dieser Fehler weiterhin besteht, befolgen Sie <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">diese Anleitung</a>.',
      headtype_mismatch: 'Falsches Modul erkannt. ',
      headtype_none: 'Modul nicht erkannt. ',
      headtype_unknown: 'Unbekanntes Modul erkannt. ',
      install_correct_headtype: 'Bitte installieren Sie 10W/20W-Diodenlasermodule ordnungsgemäß, um Niedriglaser zum Ausführen des Rahmens zu aktivieren.',
      job_origin_unavailable: 'Das Festlegen des Arbeitsursprungs erfordert die Firmware-Version 4.3.5 / 5.3.5 oder höher. Möchten Sie die Firmware jetzt aktualisieren?',
      job_origin_warning: 'Sie verwenden derzeit die „aktuelle Position“ als Startpunkt. Stellen Sie sicher, dass der Laserkopf an die richtige Position bewegt wird, um Kollisionen zu vermeiden.',
      power_too_high: 'Leistung zu hoch',
      power_too_high_confirm: 'OK',
      power_too_high_msg: 'Die Verwendung einer niedrigeren Laserleistung (unter 70%) verlängert die Lebensdauer der Laserröhre!\nGeben Sie "OK" ein, um fortzufahren.',
      pwm_unavailable: 'Der Tiefenmodus erfordert Firmware-Version 4.3.4 / 5.3.4 oder höher. Möchten Sie die Firmware jetzt aktualisieren?',
      QcleanScene: 'Möchtest du die Zeichnung löschen?<br/>Dies löscht auch deine Rückgängig-Historie!',
      start_preview_timeout: '#803 Beim Starten des Vorschaumodus ist eine Zeitüberschreitung aufgetreten. Bitte starten Sie Ihren Maschinen oder Beam Studio neu. Wenn dieser Fehler weiterhin besteht, befolgen Sie <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">diese Anleitung</a>.',
    },
    frame_task: 'Frame-Vorschau',
    hint: {
      polygon: 'Press + / - key to increse / decrease sides.',
    },
    menu: {
      about: 'Über Beam Studio',
      about_beam_studio: 'Über Beam Studio',
      account: 'Konto',
      add_new_machine: 'Maschineneinrichtung',
      align_center: 'Zentrieren',
      anti_aliasing: 'Anti-Aliasing',
      auto_align: 'Automatische Ausrichtung',
      borderless_mode: 'Randloser Modus',
      bug_report: 'Fehlerbericht',
      calibrate_beambox_camera: 'Kamera kalibrieren',
      calibrate_beambox_camera_borderless: 'Kamera kalibrieren (randlos)',
      calibrate_camera_advanced: 'Kamera kalibrieren (Erweitert)',
      calibrate_diode_module: 'Diodenlasermodul kalibrieren',
      calibrate_ir_module: 'Infrarotmodul kalibrieren',
      calibrate_printer_module: 'Druckmodul kalibrieren',
      calibration: 'Kalibrierungen',
      camera_calibration_data: 'Kamerakalibrierungsdaten',
      change_logs: 'Protokolle ändern',
      clear_scene: 'Neue Dateien',
      close: 'Fenster schließen',
      commands: 'Befehle',
      contact: 'Kontaktiere uns',
      copy: 'Kopieren',
      cut: 'Ausschneiden',
      dashboard: 'Dashboard',
      decompose_path: 'Zerlegen',
      delete: 'Löschen',
      design_market: 'Design Market',
      dev_tool: 'Debug-Tool',
      disassemble_use: 'Zerlegen',
      document_setting: 'Dokumenteinstellungen',
      document_setting_short: 'Dokument',
      download_data: 'Daten herunterladen',
      download_log: 'Protokolle herunterladen',
      download_log_canceled: 'Protokoll-Download abgebrochen',
      download_log_error: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut',
      duplicate: 'Duplizieren',
      edit: 'Bearbeiten',
      example_files: 'Beispieldateien',
      export_BVG: 'BVG',
      export_flux_task: 'FLUX-Datei',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Exportieren nach...',
      export_UV_print: 'UV-Druck-PDF',
      file: 'Datei',
      fit_to_window: 'An Fenstergröße anpassen',
      follow_us: 'Folge uns',
      forum: 'Gemeinschaftsforum',
      group: 'Gruppe',
      help: 'Hilfe',
      help_center: 'Hilfezentrum',
      hide: 'Beam Studio ausblenden',
      hideothers: 'Andere verstecken',
      image_crop: 'Zuschneiden',
      image_curve: 'Kurve',
      image_invert: 'Farbe umkehren',
      image_sharpen: 'Schärfen',
      image_stamp: 'Fase',
      image_vectorize: 'Verfolgen',
      import_acrylic_focus_probe: 'Acrylische Fokus-Sonde - 3mm',
      import_ador_laser_example: 'Beispiel für Ador Laser',
      import_ador_printing_example_full: 'Beispiel für Ador Druck - Vollfarbe',
      import_ador_printing_example_single: 'Beispiel für Ador Druck - Einzelfarbe',
      import_beambox_2_example: 'Beispiel für Beambox II',
      import_beambox_2_focus_probe: 'Beambox II Fokussonde - 3 mm',
      import_hello_beambox: 'Beispiel einer Beambox',
      import_hello_beamo: 'Beispiel für Beamo',
      import_hexa_example: 'Beispiel für HEXA',
      import_material_printing_test: 'Material Drucktest',
      import_material_testing_cut: 'Material Schneidetest',
      import_material_testing_engrave: 'Material Gravurtest',
      import_material_testing_line: 'Material Linientest',
      import_material_testing_old: 'Material Gravurtest - Klassisch',
      import_material_testing_simple_cut: 'Material Schneidetest - Einfach',
      import_promark_example: 'Beispiel für Promark',
      import_promark_mopa_20w_color: 'MOPA 20W Farbtest',
      import_promark_mopa_60w_color: 'MOPA 60W Farbtest',
      import_promark_mopa_100w_color: 'MOPA 100W Farbtest',
      keyboard_shortcuts: 'Tastenkombinationen',
      layer_color_config: 'Farbeinstellungen',
      layer_setting: 'Ebene',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Kamera',
        cloud: 'Cloud',
        discover: 'Entdecken',
        hardware: 'Hardware',
        network: 'Netzwerk',
        player: 'Spieler',
        robot: 'Roboter',
        usb: 'USB',
        usblist: 'USB-Liste',
      },
      machine_info: 'Maschineninfo',
      machines: 'Maschinen',
      manage_account: 'Mein Konto verwalten',
      material_test: 'Materialtest',
      minimize: 'Minimieren',
      my_account: 'Mein Konto',
      network_testing: 'Testen Sie die Netzwerkeinstellungen',
      object: 'Objekt',
      offset: 'Offset',
      open: 'Öffnen',
      paste: 'Einfügen',
      paste_in_place: 'Bei Originalposition einfügen',
      path: 'Pfad',
      photo_edit: 'Bild',
      preferences: 'Einstellungen',
      promark_color_test: 'Promark Farbtest',
      questionnaire: 'Feedback-Fragebogen',
      quit: 'Verlassen',
      recent: 'Zuletzt geöffnet',
      redo: 'Wiederholen',
      reload_app: 'App neu laden',
      reset: 'Zurücksetzen',
      rotary_setup: 'Drehvorrichtung Einrichten',
      rotate: 'Drehen',
      samples: 'Beispiele',
      save_as: 'Speichern als...',
      save_scene: 'Speichern',
      save_to_cloud: 'In die Cloud speichern',
      scale: 'Rahmen',
      service: 'Dienstleistungen',
      set_as_default: 'Als Standard einstellen',
      show_gesture_tutorial: 'Einleitung zur Gesten',
      show_grids: 'Gitter anzeigen',
      show_layer_color: 'Ebenenfarbe verwenden',
      show_rulers: 'Lineale anzeigen',
      show_start_tutorial: 'Erstmaliges Tutorial anzeigen',
      show_ui_intro: 'Einführung in die Benutzeroberfläche anzeigen',
      sign_in: 'Anmelden',
      sign_out: 'Ausloggen',
      software_update: 'Software-Update',
      svg_edit: 'SVG',
      switch_to_beta: 'Wechsle zur Beta-Version',
      switch_to_latest: 'Wechsle zur stabilen Version',
      tools: {
        box_generator: 'Box-Generator',
        code_generator: 'Code-Generator',
        material_test_generator: 'Materialtest-Generator',
        title: 'Werkzeuge',
      },
      tutorial: 'Starte das Delta Family Printing Tutorial',
      undo: 'Rückgängig machen',
      ungroup: 'Gruppierung aufheben',
      update: 'Aktualisierung überprüfen',
      update_firmware: 'Firmware aktualisieren',
      upload_data: 'Daten hochladen',
      using_beam_studio_api: 'Verwenden Sie die Beam Studio API',
      view: 'Ansicht',
      window: 'Fenster',
      zoom_in: 'Zoom In',
      zoom_out: 'Zoom Out',
      zoom_with_window: 'Automatische Anpassung an Fenstergröße',
    },
    preview: 'VORSCHAU',
    preview_press_esc_to_stop: 'Drücken Sie ESC, um die Kameravorschau zu stoppen.',
    rename_tab: 'Tab umbenennen',
    select_machine: 'Wählen Sie eine Maschine aus',
    tag_names: {
      dxf: 'DXF-Objekt',
      ellipse: 'Oval',
      g: 'Gruppe',
      image: 'Bild',
      line: 'Linie',
      multi_select: 'Mehrfachauswahl',
      no_selection: 'Keine Auswahl',
      pass_through_object: 'Objekt Durchlaufen',
      path: 'Pfad',
      polygon: 'Vieleck',
      rect: 'Rechteck',
      svg: 'SVG-Objekt',
      text: 'Text',
      text_path: 'Pfadtext',
      use: 'Importierte Objekt',
    },
    task_preview: 'Pfadvorschau',
    titles: {
      settings: 'Einstellungen',
    },
    untitled: 'Ohne Titel',
  },
  topmenu: {
    credit: 'Beam Studio ist dank <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a>Open-Sorce-Projekt sowie anderen <a target="_blank" href="https://flux3dp.com/credits/">Open-Source-Softwares</a> ermöglicht.',
    device: {
      download_log_canceled: 'Protokoll-Download abgebrochen',
      download_log_error: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut',
      log: {
        usblist: 'USB-Liste',
      },
      network_test: 'Netzwerk testen',
    },
    file: {
      all_files: 'Alle Dateien',
      clear_recent: 'Vor kurzem geöffnet löschen',
      converting: 'In Bild konvertieren ...',
      fcode_files: 'FLUX-Code',
      import: 'Importieren',
      jpg_files: 'JPG',
      label: 'Datei',
      path_not_exit: 'Dieser Pfad scheint auf dem Schreibtisch nicht mehr zu existieren.',
      png_files: 'PNG',
      save_fcode: 'FLUX-Aufgabe exportieren',
      save_jpg: 'JPG exportieren',
      save_png: 'PNG exportieren',
      save_scene: 'Datei speichern',
      save_svg: 'SVG exportieren',
      scene_files: 'Beam-Studio-Datei',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Ausführung',
  },
  tutorial: {
    ask_retry_calibration: 'Möchten Sie die Kamera noch einmal kalibrieren?',
    camera_calibration_failed: 'Kamera Kalibrierung fehlgeschlagen',
    gesture: {
      click: 'Tippen Sie leicht das Objekt um zu wählen.',
      drag: 'Legen und ziehen mit einem Finger um mehrere Objekten zu wählen. ',
      hold: 'Finger lang auf einem Objekt legen um das Kontextmenü zu öffnen.',
      pan: 'Scrollen mit 2 Fingern auf dem Bildschirm.',
      zoom: 'Ziehen Sie mit 2 Fingern zum Auszoomen auseinander, und ziehen Sie zum Einzoomen aufeinander zu.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Suchmaschine für Tutorial ...',
    needNewInterfaceTutorial: 'Möchten Sie ein Tutorial für das neue Interface von Beam Studio starten?<br/>(Sie können es jetzt überspringen und später durch Klicken auf "Hilfe" > "Interface-Einführung anzeigen" starten.)',
    needNewUserTutorial: 'Möchten Sie ein Tutorial starten?<br/>(Sie können es jetzt überspringen und später durch Klicken auf "Hilfe" > "Start-Tutorial anzeigen" starten.)',
    newInterface: {
      add_new_layer: 'Neue Ebene hinzufügen',
      align_controls: 'Ausrichten Steuerelemente',
      basic_shapes: 'Grundformen',
      camera_preview: 'Kameravorschau',
      drag_to_sort: 'Zum Sortieren ziehen',
      end_alert: 'Sind Sie sicher, die neue Einführung in die Benutzeroberfläche zu beenden?',
      flip: 'Flip',
      group_controls: 'Gruppensteuerelemente',
      layer_controls: 'Klicken Sie mit der rechten Maustaste, um Ebenensteuerelemente auszuwählen:\nEbenen duplizieren / zusammenführen / sperren / löschen',
      object_actions: 'Objektaktionen',
      pen_tool: 'Stiftwerkzeug',
      rename_by_double_click: 'Umbenennen durch Doppelklick',
      select_image_text: 'Wählen Sie / Bild / Text',
      select_machine: 'Wählen Sie eine Maschine aus',
      shape_operation: 'Formbetrieb',
      start_work: 'Arbeit beginnen',
      switch_between_layer_panel_and_object_panel: 'Wechseln Sie zwischen Ebenenbedienfeld und Objektbedienfeld',
    },
    newUser: {
      add_new_layer: 'Fügen Sie eine neue Ebene hinzu',
      adjust_focus: '2. Stellen Sie den Fokus ein',
      close_cover: '3. Schließen Sie die Abdeckung',
      drag_to_draw: 'Zum Zeichnen ziehen',
      draw_a_circle: 'Zeichne einen Kreis',
      draw_a_rect: 'Zeichne ein Rechteck',
      end_alert: 'Sind Sie sicher, das Tutorial zu beenden?',
      end_preview_mode: 'Beenden Sie den Vorschaumodus',
      infill: 'Füllen einschalten',
      please_select_wood_cutting: 'Bitte wählen Sie die Voreinstellung "Holz - Schneiden".',
      please_select_wood_engraving: 'Bitte wählen Sie die Voreinstellung "Holz - Gravur".',
      preview_the_platform: 'Vorschau der Plattform',
      put_wood: '1. Legen Sie das Musterholz',
      send_the_file: 'Senden Sie die Datei',
      set_preset_wood_cut: 'Set Preset: Holz - Schneiden',
      set_preset_wood_engraving: 'Set Preset: Holz - Gravur',
      switch_to_layer_panel: 'Wechseln Sie zum Ebenenbedienfeld',
      switch_to_object_panel: 'Wechsel zum Objektpanel',
      switch_to_preview_mode: 'Wechseln Sie in den Vorschaumodus',
    },
    next: 'NÄCHSTER',
    retry: 'Wiederholen',
    set_connection: 'Verbindung einstellen',
    skip: 'Überspringen',
    skip_tutorial: 'Sie haben das Tutorial übersprungen. Sie können das Tutorial jederzeit starten, indem Sie auf "Hilfe"> "Erstmaliges Tutorial anzeigen" klicken.',
    suggest_calibrate_camera_first: 'Wir empfehlen Benutzern, die Kamera zuerst zu kalibrieren und vor jeder Vorschau neu zu fokussieren, um optimale Ergebnisse zu erzielen.<br/>Möchten Sie die Kalibrierung jetzt durchführen?<br/>(Sie können es jetzt überspringen und später durch Klicken auf "Menü" > "Maschine" > [Ihre Maschine] > "Kamera kalibrieren".)',
    tutorial_complete: 'Das ist alles für das Tutorial. Jetzt ist es Zeit zu erstellen!',
    unable_to_find_machine: 'Maschine für Tutorial konnte nicht gefunden werden. Möchten Sie zur Verbindungseinstellungsseite gehen, das Tutorial wiederholen oder überspringen?',
    welcome: 'WILLKOMMEN',
  },
  update: {
    cannot_reach_internet: '#823 Server ist nicht erreichbar <br/> Bitte überprüfe die Internetverbindung',
    download: 'ONLINE-UPDATE',
    firmware: {
      caption: 'Ein Firmware-Update für das Gerät ist verfügbar',
      confirm: 'HOCHLADEN',
      firmware_too_old_update_by_sdcard: 'Die Firmware ist zu alt. Bitte aktualisieren Sie die Firmware mit einer SD-Karte.',
      force_update_message: '#814 Bitte aktualisieren Sie Ihre Maschine auf die neueste Firmware-Version.',
      latest_firmware: {
        cant_get_latest: 'Informationen zur neuesten Firmware können nicht abgerufen werden.',
        caption: 'Maschinenfirmware-Update',
        message: 'Du hast die neueste Maschinenfirmware',
        still_update: 'AKTUALISIEREN',
      },
      message_pattern_1: '%s ist jetzt bereit für das Firmware-Update.',
      message_pattern_2: '%s Firmware v%s ist jetzt verfügbar - Du hast v%s.',
      too_old_for_web: '"Ihre Firmwarenversion ist zurzeit v%s.\nWenn Sie die Online-version von Beam Studio benutzen möchten, aktualisieren Sie bitte zur neusten Version."',
      update_fail: '#822 Update fehlgeschlagen',
      update_success: 'Firmware-Update erfolgreich hochgeladen',
      upload_file: 'Firmware-Upload (* .bin / * .fxfw)',
    },
    install: 'INSTALLIEREN',
    later: 'SPÄTER',
    preparing: 'Vorbereiten...',
    release_note: 'Veröffentlichungshinweis:',
    skip: 'Überspringe diese Version',
    software: {
      available_switch: 'Beam Studio v%s ist ab sofort verfügbar,Du hast v%s. Möchtest du zu dieser Version wechseln?',
      available_update: 'Beam Studio v%s ist ab sofort verfügbar,Du hast v%s. Möchtest du das Update herunterladen?',
      caption: 'Ein Software-Update für Beam Studio ist verfügbar',
      check_update: 'Überprüfe auf Updates',
      checking: 'Suche nach Updates',
      downloading: 'Wenn Sie Updates im Hintergrund herunterladen, können Sie auf "OK" klicken, um Ihre Arbeit fortzusetzen.',
      install_or_not: 'ist bereit für das Update. Möchtest du jetzt neu starten und aktualisieren?',
      no: 'Nein',
      no_response: 'Fehler beim Herstellen einer Verbindung zum Server. Überprüfe die Netzwerkeinstellungen.',
      not_found: 'Sie verwenden die neueste Version von Beam Studio.',
      switch_or_not: 'ist schaltbereit. Möchtest du jetzt neu starten und wechseln?',
      switch_version: 'Version wechseln',
      switch_version_not_found: 'Wechselbare Version nicht gefunden.',
      update_for_ador: 'Die aktuelle Softwareversion %s ist veraltet, bitte laden Sie die neueste Version von Beam Studio für Ador herunter.',
      yes: 'Ja',
    },
    update: 'Aktualisieren',
    updating: 'Aktualisierung...',
    upload: 'HOCHLADEN',
  },
  web_cam: {
    no_device: 'Kameragerät kann nicht erkannt werden. Bitte schließen Sie die Kamera erneut an und versuchen Sie es erneut.',
    no_permission: 'Beam Studio hat keine Berechtigung, auf die Kamera zuzugreifen. Stellen Sie sicher, dass die Berechtigung für Beam Studio in den Browser- oder Systemeinstellungen erteilt wurde.',
  },
  z_speed_limit_test: {
    alert_before: 'Vor Beginn der Kurvengravur führt das System einen Belastungstest durch, um Schrittverluste aufgrund übermäßigen Gewichts zu verhindern.',
    alert_failed: 'Das Gewicht des aktuellen Objekts überschreitet die Belastungsgrenze für die aktuelle Z-Achsen-Geschwindigkeit. Bitte reduzieren Sie die Z-Achsen-Geschwindigkeit im Maschinenpanel oder ersetzen Sie das Gravurobjekt, bevor Sie den Test erneut durchführen.',
    ignore: 'Ignorieren',
    retest: 'Erneut testen',
    testing: 'Durchführen des Belastungstests...',
  },
};

export default lang;
