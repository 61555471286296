import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: '放棄',
    cancel: '取消',
    caption: '錯誤',
    close: '關閉',
    confirm: '確認',
    dont_save: '不要儲存',
    dont_show_again: '不再顯示',
    duplicated_preset_name: '重複的預設名稱',
    error: '錯誤',
    info: '訊息',
    instruction: '提示',
    learn_more: '了解更多',
    no: '否',
    ok: '確定',
    ok2: '好',
    oops: '哎呀',
    retry: '重試',
    save: '儲存',
    stop: '停止',
    warning: '警告',
    yes: ' 是',
  },
  auto_fit: {
    artwork_size: '圖檔大小',
    error_tip1: '1. 物件是否正確放置在材料上？',
    error_tip2: '2. 材料的輪廓是否足夠清晰以便識別？',
    failed_to_find_contour: '未能找到用於自動適配的輪廓',
    learn_more: '查看教學。',
    learn_more_url: 'https://support.flux3dp.com/hc/zh-tw/articles/10273384373775',
    offset_x: '偏移 X',
    offset_y: '偏移 Y',
    pattern_size: '形狀大小',
    position_artwork: '放置圖檔',
    position_step1: '調整圖檔在形狀上的大小和位置。',
    position_step2: '點擊「套用」以將自動對位套用於此形狀。',
    preview_first: '請先進行相機預覽',
    reset_position: '重置位置',
    rotation: '旋轉',
    select_a_pattern: '選擇形狀',
    selected_artwork: '已選圖檔',
    step1: '選擇您希望放置圖檔的形狀。',
    step2: '如果未找到形狀，請再次進行相機預覽並應用自動對位。',
    step3: '如果仍然無法找到正確的形狀，請確保您的工件清晰可辨，且工作區域乾淨無雜物。',
    title: '自動對位',
  },
  barcode_generator: {
    bar_height: '條高',
    bar_width: '條寬',
    barcode: {
      invalid_value: '該值對於所選格式無效。',
    },
    font: '字體',
    font_size: '字體大小',
    hide_text: '隱藏文字',
    invert_color: '反轉顏色',
    text_margin: '文字邊距',
  },
  beambox: {
    announcement_panel: {
      title: '公告',
    },
    banner: {
      auto_feeder: '自動送料模式',
      camera_preview: '預覽',
      camera_preview_borderless_mode: '(開放底部)',
      curve_engraving: '曲面雕刻模式',
      pass_through: '廣域雕刻模式',
      rotary: '旋轉軸模式',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: '部分字型在不同系統間有差異，輸出前請將字體轉換成路徑，以確保文字正確顯示。轉換文字至路徑中...',
      export_file_error_ask_for_upload: '匯出工作時發生錯誤，是否願意上傳工作場景回報錯誤給開發團隊？',
      retreive_image_data: '擷取影像資料中...',
    },
    context_menu: {
      copy: '複製',
      cut: '剪下',
      delete: '刪除',
      duplicate: '重製',
      group: '群組',
      move_back: '移至最後',
      move_down: '往後移',
      move_front: '移至最前',
      move_up: '往前移',
      paste: '貼上',
      paste_in_place: '原地貼上',
      ungroup: '解散群組',
    },
    document_panel: {
      add_on: '擴充模組',
      auto_feeder: '自動送料',
      auto_feeder_scale: '自動送料倍率',
      auto_feeder_url: 'https://support.flux3dp.com/hc/zh-tw/articles/11688230498575',
      borderless_mode: '開蓋模式',
      current_position: '當前位置',
      disable: '關閉',
      document_settings: '文件設定',
      enable: '啟用',
      enable_autofocus: '自動對焦',
      enable_diode: '二極體雷射',
      engrave_dpi: '雕刻解析度',
      frame_before_start: '先預覽後執行',
      frame_before_start_url: 'https://support.flux3dp.com/hc/zh-tw/articles/11494925637135',
      high: '高',
      job_origin: '工作原點',
      laser_source: '雷射光源',
      low: '低',
      machine: '機器',
      medium: '中',
      notification: {
        changeFromPrintingWorkareaTitle: '您要將列印圖層轉換至雷射圖層嗎？',
      },
      origin: '原點',
      pass_through: '廣域雕刻',
      pass_through_height_desc: '輸入物體長度以擴展工作區域。',
      rotary_mode: '旋轉軸',
      scale: '倍率',
      start_from: '開始於',
      start_position: '開始位置',
      start_work_button: '開始工作鈕',
      ultra: '極高',
      workarea: '工作範圍',
    },
    image_trace_panel: {
      back: '上一步',
      brightness: '曝光',
      cancel: '取消',
      contrast: '對比',
      next: '下一步',
      okay: '完成',
      threshold: '臨界值',
      tuning: '描圖參數',
    },
    layer_color_config_panel: {
      add: '新增',
      add_config: '新增顏色',
      color: '顏色',
      default: '回復預設',
      in_use: '此顏色已在使用中。',
      layer_color_config: '圖層顏色參數設定',
      no_input: '請輸入顏色色碼。',
      power: '功率',
      repeat: '次數',
      speed: '速度',
      sure_to_delete: '確定要刪除這項顏色參數。',
      sure_to_reset: '您將會失去所有自訂顏色參數，確定要回復到預設值？',
    },
    left_panel: {
      borderless_blind_area: '非雕刻範圍',
      borderless_preview: '開蓋模式相機預覽',
      diode_blind_area: '非雕刻範圍',
      ellipse: '橢圓形',
      image: '圖片',
      label: {
        adjust_height: '調整高度',
        array: '陣列',
        boxgen: 'Boxgen',
        choose_camera: '相機預覽',
        clear_preview: '清除預覽',
        cursor: '選取',
        curve_engraving: {
          clear_area: '清除選取區域',
          exit: '退出',
          preview_3d_curve: '預覽曲面雕刻',
          select_area: '選擇區域',
          title: '曲面雕刻',
        },
        end_preview: '結束預覽',
        line: '線段',
        live_feed: '即時視訊',
        my_cloud: '我的雲端',
        oval: '橢圓',
        pass_through: '廣域雕刻',
        pen: '鋼筆',
        photo: '圖片',
        polygon: '多邊形',
        preview: '相機預覽',
        qr_code: 'QR 碼',
        rect: '方塊',
        shapes: '元素',
        text: '文字',
        trace: '影像描圖',
      },
      line: '線段',
      rectangle: '長方形',
      text: '文字',
      unpreviewable_area: '非相機預覽範圍',
    },
    network_testing_panel: {
      average_response: '平均回覆時間',
      cannot_connect_1: '#840 無法與目標 IP 建立連線',
      cannot_connect_2: '#840 無法與目標 IP 建立連線，請確認是否與目標 IP 在同一網路',
      cannot_get_local: '無法取得本地 IP 位置',
      connection_quality: '連線品質',
      device_not_on_list: '#842 列表上看不到機器，連線品質 > 70 且平均回覆時間 < 100ms',
      empty_ip: '#818 請先輸入目標 IP 位置',
      end: '結束',
      fail_to_start_network_test: '#817 無法開始網路檢測。',
      hint_connect_camera_timeout: '啟動相機預覽時超時？',
      hint_connect_failed_when_sending_job: '送出工作時出現無法連接？',
      hint_device_often_on_list: '列表上經常找不到機器？',
      insert_ip: '目標 IP 位置：',
      invalid_ip: '#818 錯誤的 IP 位置',
      ip_startswith_169: '#843 目標 IP 開頭為 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/zh-tw/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/zh-tw/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/zh-tw/articles/360001841636',
      linux_permission_hint: '此問題通常是因為權限不足而發生，請在終端機以 "sudo beam-studio --no-sandbox" 以獲得權限進行網路檢測。',
      local_ip: '本機 IP 位置：',
      network_testing: '網路檢測',
      network_unhealthy: '#841 連線品質 < 70 或平均回覆時間 > 100ms',
      start: '檢測',
      test_completed: '檢測完成',
      test_fail: '檢測失敗',
      testing: '網路檢測中...',
    },
    object_panels: {
      lock_desc: '縮放時固定比例 (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: '轉換文字時出現字體不支援的情形，請在送出工作前，再次檢查預覽圖確認文字是否如預期轉換。',
        error_when_parsing_text: '文字轉換路徑時發生錯誤',
        font_substitute_pop: '您的文字包含當前字型不支援的字元。<br/>您想使用<strong>%s</strong>作為替換嗎?',
        retry: '請稍後重試或選擇其他字型',
        use_current_font: '使用當前字體',
      },
      wait_for_parsing_font: '解析字體中...',
    },
    path_preview: {
      current_position: '當下位置',
      cut_distance: '切割距離',
      cut_time: '切割時間',
      end_preview: '結束預覽',
      estimated_time: '預估總時間',
      invert: '色彩反轉',
      pause: '暫停',
      play: '播放',
      play_speed: '播放速度',
      preview_info: '預覽資訊',
      rapid_distance: '快速移動距離',
      rapid_time: '快速移動時間',
      remark: '*所有資訊皆為預估數值，僅供參考。',
      size: '尺寸',
      start_here: '從這裡開始',
      stop: '停止',
      travel_path: '運行軌跡',
    },
    photo_edit_panel: {
      aspect_ratio: '長寬比',
      brightness: '亮度',
      brightness_and_contrast: '亮度對比',
      cancel: '取消',
      circumference: '周長',
      compare: '原圖比較',
      contrast: '對比',
      crop: '裁剪',
      curve: '曲線',
      diameter: '直徑',
      free: '任意',
      invert: '色彩反轉',
      okay: '完成',
      original: '原圖',
      phote_edit: '影像編輯',
      processing: '處理中',
      radius: '鋭化半徑',
      rotary_warped: '旋轉軸變形',
      rotary_warped_link: 'https://support.flux3dp.com/hc/zh-tw/articles/10828006201103',
      sharpen: '銳化',
      sharpness: '鋭化強度',
      start: '開始',
      warp: '變形',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/zh-TW/credit',
        go: '前往',
        insufficient_credit: '您的 Credit 餘額不足',
        insufficient_credit_msg: '您無法使用%s，請前往會員中心購買 AI Credit。',
        relogin_to_use: '請重新登入以使用此功能。',
      },
      auto_feeder_origin: '請先使用外框預覽檢查雷射頭的運行路徑與範圍，然後根據結果調整板材位置或雕刻起點，以防止雕刻過程中的碰撞。',
      auto_switch_tab: {
        message: '已在偏好設定中新增在圖層面板和物件面板之間自動切換的新選項。此選項預設為關閉。是否要啟用自動切換？ <br/>您可以隨時在偏好設定中更改此設定。',
        title: '自動切換圖層和物件面板',
      },
      backend_connect_failed_ask_to_upload: '#802 連接後端程式時持續發生錯誤，請問您是否要將錯誤報告上傳到雲端?',
      backend_error_hint: '後端程式無法連接，部分功能可能無法使用。',
      both_power_and_speed_too_high: '雷射管在高功率下耗損較快，使用低功率可以延長雷試管使用壽命。<br/>並且在此雕刻解析度使用過快的速度可能導致漸層雕刻的品質較差。',
      bug_report: '錯誤回報',
      change_workarea_before_preview: '%s 的工作範圍與目前設定的工作範圍不相符，是否要切換目前的工作範圍？',
      convert_to_path_fail: '轉換成路徑失敗。',
      dxf_bounding_box_size_over: '圖像超出工作範圍，請在 CAD 軟體中將圖像放置於原點附近，或確定圖檔單位是否正確設定。',
      dxf_version_waring: '此 DXF 檔版本非 2013 版，可能有潛在的不相容風險。',
      facebook_group_invitation: {
        already_joined: '已加入用戶社群',
        join_now: '馬上加入',
        later: '稍後加入',
        message: '即刻加入 FLUX 官方用戶社群，與廣大 FLUX 用戶分享使用心得、展示雷雕作品以及獲取最新的產品資訊。',
        title: '加入 FLUX 官方用戶社群',
      },
      import_file_contain_invalid_path: '#808 匯入的SVG檔案中含有不存在的圖片路徑，請確認檔案中所有連結之圖片皆存在，或改將圖片嵌入檔案中。',
      import_file_error_ask_for_upload: '讀取 SVG 檔時發生錯誤，是否願意上傳檔案回報錯誤給開發團隊？',
      layer_by_color: '依顏色分層',
      layer_by_layer: '依圖層分層',
      loading_image: '載入圖片中，請稍候...',
      more_than_two_object: '太多物件，只支援兩物件操作',
      mouse: '滑鼠',
      no_support_text: 'Beam Studio 目前不支援由外部匯入文字標籤，請由向量繪圖軟體將文字轉成路徑後再匯入。',
      nolayer: '不分層',
      not_support_object_type: '不支援的物件類型',
      or_turn_off_borderless_mode: '或是關閉開蓋模式',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 無法找到 pdf2svg 指令，請使用您的套件管理裝置安裝 pdf2svg（e.g., "yum install pdf2svg" or "apt-get install pdf2svg"）。',
        error_when_converting_pdf: '#824 將 PDF 轉換成 SVG 時發生錯誤：',
      },
      progress: {
        calculating: '計算中',
        uploading: '上傳中',
      },
      questionnaire: {
        caption: '協助我們填寫問券',
        message: '協助我們填寫問券，讓產品變得更好。',
        no_questionnaire_available: '目前沒有可供填寫的問卷。',
        unable_to_get_url: '無法透過網路取得目前最新問卷的連結，請確認您的網路連線狀況。',
      },
      recommend_downgrade_software: '偵測到較舊的韌體版本，我們仍然在積極解決相容問題，但現在建議您先退回 <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>',
      recommend_upgrade_firmware: '我們偵測到較舊的韌體版本。我們正在積極解決相容問題，但現在建議您先更新至最新的韌體。',
      save_unsave_changed: '請問是否要儲存未儲存的變更，否則變更將會遺失？',
      select_at_least_two: '請選取兩個物件以繼續',
      select_first: '請先選取物件以繼續',
      select_import_method: '選擇分層方式:',
      select_import_module: '選擇模組:',
      sentry: {
        message: '請問您是否同意在遇到錯誤時將相關資訊自動上傳給開發團隊？',
        title: '我們一起讓 Beam Studio 變得更好',
      },
      should_update_firmware_to_continue: '#814 您的韌體版本不支援最新的軟體改善。為了更良好的使用經驗與雕刻品質，請先更新 Beambox 的韌體以繼續。 (主選單 > 機器 > [ Your Beambox ] > 韌體更新)',
      speed_too_high_lower_the_quality: '在此雕刻解析度使用過快的速度，可能導致漸層雕刻的品質較差。',
      still_continue: '繼續',
      successfully_uploaded: '檔案已成功上傳。',
      svg_1_1_waring: '此檔案標示之 SVG 版本為 1.1 版，可能有潛在的不相容風險。',
      svg_image_path_waring: '此檔案內含有以路徑讀取的圖片，可能會有讀取失敗的風險。請在做圖匯出 SVG 時，當點陣圖相關選項改成嵌入。',
      text_to_path: {
        caption: '文字轉路徑 2.0',
        message: 'Beam Studio 現在推出全新的文字轉路徑 2.0，為您帶來更穩定的使用體驗！您是否要立即切換？\n您也可以稍後於偏好設定中的「文字轉路徑」修改此設定。',
      },
      too_fast_for_auto_feeder: '含有路徑物件的圖層速度過快，可能導致切割時位置誤差。<br/>不建議在自動送料時使用 %(limit)s 以上的路徑速度 。',
      too_fast_for_auto_feeder_and_constrain: '以下圖層： <strong>%(layers)s</strong><br/>含有向量路徑物件且速度超過自動送料的路徑速限 %(limit)s，為維持雕刻的精度，向量路徑速度將被限制在 %(limit)s ，您可以在偏好設定解除此限制。',
      too_fast_for_curve: '對包含曲面雕刻物件的圖層使用過高速度可能會降低雕刻精度。\n建議將速度保持在%(limit)s以下，以獲得曲面雕刻物件的最佳效果。',
      too_fast_for_curve_and_constrain: '以下圖層: %(layers)s 超過了速度限制 %(limit)s。\n曲面雕刻物件的雕刻速度將被限制為 %(limit)s。您可以在偏好設定中移除此限制。',
      too_fast_for_path: '含有路徑物件的圖層速度過快，可能導致切割時位置誤差。<br/>不建議超過在切割路徑時超過 %(limit)s 。',
      too_fast_for_path_and_constrain: '以下圖層： <strong>%(layers)s</strong><br/>含有向量路徑物件且速度超過 %(limit)s，為維持雕刻的精度，向量路徑速度將被限制在 %(limit)s ，您可以在偏好設定解除此限制。',
      touchpad: '觸控板',
      ungroup_use: '正要解散外部匯入的 DXF 或是 SVG ，在含有物件較多的情況，可能會需要等一陣子，是否確定解散？',
      upload_failed: '#819 檔案上傳失敗。',
      upload_file_too_large: '#819 檔案大小過大，請聯絡客服。',
      vectorize_shading_image: '漸層影像在向量化時將花費較多時間，且容易有雜點，請將影像漸層關閉後再執行。',
    },
    rating_panel: {
      description: '如果您喜歡 Beam Studio，請給我們評分，我們將不勝感激。',
      thank_you: '謝謝您的回饋',
      title: '喜歡 Beam Studio 嗎？',
    },
    right_panel: {
      laser_panel: {
        advanced: '進階設定',
        backlash: '背隙調整',
        bi_directional: '雙向',
        by: '', // keep empty on purpose
        ce_z_high_speed: '高速 Z 軸',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/zh-tw/articles/10364060644495',
        color_adjustment: '色版調整',
        color_adjustment_short: '色版',
        color_strength: '強度',
        cross_hatch: '交叉填充',
        custom_preset: '自訂',
        cut: '切割',
        diode: '二極體雷射',
        do_not_adjust_default_para: '無法調整預設參數。',
        dottingTime: '打點時間',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - 刻印',
            acrylic_3mm_cutting: '壓克力 - 1/8″ 切割',
            acrylic_5mm_cutting: '壓克力 - 3/16″ 切割',
            acrylic_8mm_cutting: '壓克力 - 1/4″ 切割',
            acrylic_10mm_cutting: '壓克力 - 3/8″ 切割',
            acrylic_engraving: '壓克力 - 刻印',
            acrylic_printing: '壓克力 - 列印',
            aluminum_engraving: '鋁 - 刻印',
            aluminum_light: '鋁 (淺)',
            bamboo_printing: '竹子 - 列印',
            black_abs: '黑 ABS',
            black_acrylic_3mm_cutting: '黑色壓克力 - 1/8″ 切割',
            black_acrylic_5mm_cutting: '黑色壓克力 - 3/16″ 切割',
            black_acrylic_engraving: '黑色壓克力 - 刻印',
            brass_dark: '黃銅 (深)',
            brass_engraving: '黃銅 - 刻印',
            brass_light: '黃銅 (淺)',
            canvas_printing: '帆布 - 列印',
            cardstock_printing: '卡紙 - 列印',
            copper: '紅銅',
            cork_printing: '軟木 - 列印',
            denim_1mm_cutting: '牛仔布 - 1/32″ 切割',
            fabric_3mm_cutting: '布料 - 1/8″ 切割',
            fabric_5mm_cutting: '布料 - 3/16″ 切割',
            fabric_engraving: '布料 - 刻印',
            fabric_printing: '布料 - 列印',
            flat_stone_printing: '石板 - 列印',
            glass_bw_engraving: '玻璃 - 刻印',
            glass_printing: '玻璃 - 列印',
            gloss_leather_printing: '亮面皮革 - 列印',
            gold_engraving: '金 - 刻印',
            iron_engraving: '鐵 - 刻印',
            leather_3mm_cutting: '皮革 - 1/8″ 切割',
            leather_5mm_cutting: '皮革 - 3/16″ 切割',
            leather_engraving: '皮革 - 刻印',
            mdf_3mm_cutting: '紐西蘭密迪板 - 1/8″ 切割',
            mdf_5mm_cutting: '紐西蘭密迪板 - 3/16″ 切割',
            mdf_engraving: '紐西蘭密迪板 - 刻印',
            metal_bw_engraving: '不鏽鋼 - 刻印',
            opaque_acrylic: '不透明壓克力',
            pc_printing: 'PC - 列印',
            rubber_bw_engraving: '印章墊 - 刻印',
            silver_engraving: '銀 - 刻印',
            stainless_steel_bw_engraving_diode: '不鏽鋼 - 刻印（二極體雷射）',
            stainless_steel_dark: '不銹鋼 (深)',
            stainless_steel_engraving: '不鏽鋼 - 刻印',
            stainless_steel_light: '不銹鋼 (淺)',
            stainless_steel_printing: '不鏽鋼 - 列印',
            steel_engraving_spray_engraving: '不鏽鋼噴劑 - 刻印',
            stone: '岩石',
            ti_engraving: '鈦 - 刻印',
            titanium_dark: '鈦 (深)',
            titanium_light: '鈦 (淺)',
            white_abs: '白 ABS',
            wood_3mm_cutting: '木板 - 1/8″ 切割',
            wood_5mm_cutting: '木板 - 3/16″ 切割',
            wood_7mm_cutting: '木板 - 1/4″ 切割',
            wood_8mm_cutting: '木板 - 1/4″ 切割',
            wood_10mm_cutting: '木板 - 3/8″ 切割',
            wood_engraving: '木板 - 刻印',
            wood_printing: '木材 - 列印',
          },
          mm: {
            abs_engraving: 'ABS - 刻印',
            acrylic_3mm_cutting: '壓克力 - 3mm 切割',
            acrylic_5mm_cutting: '壓克力 - 5mm 切割',
            acrylic_8mm_cutting: '壓克力 - 8mm 切割',
            acrylic_10mm_cutting: '壓克力 - 10mm 切割',
            acrylic_engraving: '壓克力 - 刻印',
            acrylic_printing: '壓克力 - 列印',
            aluminum_engraving: '鋁 - 刻印',
            aluminum_light: '鋁 (淺)',
            bamboo_printing: '竹子 - 列印',
            black_abs: '黑 ABS',
            black_acrylic_3mm_cutting: '黑色壓克力 - 3mm 切割',
            black_acrylic_5mm_cutting: '黑色壓克力 - 5mm 切割',
            black_acrylic_engraving: '黑色壓克力 - 刻印',
            brass_dark: '黃銅 (深)',
            brass_engraving: '黃銅 - 刻印',
            brass_light: '黃銅 (淺)',
            canvas_printing: '帆布 - 列印',
            cardstock_printing: '卡紙 - 列印',
            copper: '紅銅',
            cork_printing: '軟木 - 列印',
            denim_1mm_cutting: '牛仔布 - 1mm 切割',
            fabric_3mm_cutting: '布料 - 3mm 切割',
            fabric_5mm_cutting: '布料 - 5mm 切割',
            fabric_engraving: '布料 - 刻印',
            fabric_printing: '布料 - 列印',
            flat_stone_printing: '石板 - 列印',
            glass_bw_engraving: '玻璃 - 刻印',
            glass_printing: '玻璃 - 列印',
            gloss_leather_printing: '亮面皮革 - 列印',
            gold_engraving: '金 - 刻印',
            iron_engraving: '鐵 - 刻印',
            leather_3mm_cutting: '皮革 - 3mm 切割',
            leather_5mm_cutting: '皮革 - 5mm 切割',
            leather_engraving: '皮革 - 刻印',
            mdf_3mm_cutting: '紐西蘭密迪板 - 3mm 切割',
            mdf_5mm_cutting: '紐西蘭密迪板 - 5mm 切割',
            mdf_engraving: '紐西蘭密迪板 - 刻印',
            metal_bw_engraving: '不鏽鋼 - 刻印',
            opaque_acrylic: '不透明壓克力',
            pc_printing: 'PC - 列印',
            rubber_bw_engraving: '印章墊 - 刻印',
            silver_engraving: '銀 - 刻印',
            stainless_steel_bw_engraving_diode: '不鏽鋼 - 刻印（二極體雷射）',
            stainless_steel_dark: '不銹鋼 (深)',
            stainless_steel_engraving: '不鏽鋼 - 刻印',
            stainless_steel_light: '不銹鋼 (淺)',
            stainless_steel_printing: '不鏽鋼 - 列印',
            steel_engraving_spray_engraving: '不鏽鋼噴劑 - 刻印',
            stone: '岩石',
            ti_engraving: '鈦 - 刻印',
            titanium_dark: '鈦 (深)',
            titanium_light: '鈦 (淺)',
            white_abs: '白 ABS',
            wood_3mm_cutting: '木板 - 3mm 切割',
            wood_5mm_cutting: '木板 - 5mm 切割',
            wood_7mm_cutting: '木板 - 7mm 切割',
            wood_8mm_cutting: '木板 - 8mm 切割',
            wood_10mm_cutting: '木板 - 10mm 切割',
            wood_engraving: '木板 - 刻印',
            wood_printing: '木材 - 列印',
          },
          parameters: '選擇參數',
          save: '新增目前參數',
        },
        engrave: '雕刻',
        existing_name: '已存在此名稱的自訂參數。',
        fill_angle: '填充角度',
        fill_interval: '填充間隔',
        fill_setting: '填充設定',
        filled_path_only: '僅適用於填充路徑',
        focus_adjustment: '對焦調整',
        frequency: '頻率',
        gradient_only: '僅適用於漸層圖片',
        halftone: '半色調',
        halftone_link: 'https://support.flux3dp.com/hc/zh-tw/articles/9402670389647',
        height: '物件高度',
        ink_saturation: '飽和度',
        ink_type: {
          normal: '一般墨',
          text: '墨水類型',
          UV: 'UV 墨',
        },
        laser_speed: {
          fast: '快',
          max: 300,
          min: 1,
          slow: '慢',
          step: 0.1,
          text: '速度',
          unit: 'mm/s',
        },
        low_power_warning: '功率較低時（低於 10 %），有可能不出光。',
        low_speed_warning: '低速下可能會導致材料燃燒。',
        lower_focus: '降低焦距',
        lower_focus_desc: '在對焦後將對焦高度降低特定距離，用於提升切割效果。',
        module: '模組',
        more: '管理',
        multi_layer: '多個圖層',
        para_in_use: '此參數已在使用中。',
        parameters: '選擇參數',
        power: {
          high: '強',
          low: '弱',
          max: 100,
          min: 1,
          step: 0.1,
          text: '功率',
        },
        preset_management: {
          add_new: '新增',
          delete: '刪除',
          export: '匯出參數',
          export_preset_title: '匯出參數',
          import: '匯入參數',
          laser: '雷射',
          lower_focus_by: '降低焦距',
          new_preset_name: '新參數名稱',
          preset: '預設',
          print: '列印',
          reset: '恢復預設',
          save_and_exit: '儲存並離開',
          show_all: '顯示全部',
          sure_to_import_presets: '將要讀取預設參數的排序與使用狀況，並覆蓋自訂參數，確定要繼續進行嗎？',
          sure_to_reset: '這將刪除您的自訂參數並重設所有預設值，您確定要繼續嗎？',
          title: '管理參數',
          wobble_diameter: '擺動直徑',
          wobble_step: '擺動間距',
        },
        preset_setting: '參數調整（%s）',
        presets: '預設',
        print_multipass: '多重列印',
        promark_speed_desc: '速度參數不適用於漸層圖片。',
        pulse_width: '脈衝寬度',
        pwm_advanced_desc: '設定深度模式的最小功率。',
        pwm_advanced_hint: '此設定專門適用於深度模式下的漸層圖像。',
        pwm_advanced_setting: '深度模式功率設定',
        repeat: '執行次數',
        single_color: '單色圖層',
        single_color_desc: '僅適用於彩色圖層，不適用於已展開的單色圖層。',
        slider: {
          fast: '快',
          high: '高',
          low: '低',
          regular: '普通',
          slow: '慢',
          very_fast: '最快',
          very_high: '最高',
          very_low: '最低',
          very_slow: '最慢',
        },
        speed: '速度',
        speed_constrain_warning: '向量路徑速度將被限制在 %(limit)s，您可以在偏好設定解除此限制。',
        speed_constrain_warning_auto_feeder: '自動送料下物件的切割速度將限制為%(limit)s。您可以在偏好設定中移除此限制。',
        speed_constrain_warning_curve_engraving: '曲面物件的雕刻速度將被限制在 %(limit)s。您可以在偏好設定解除此限制。',
        stepwise_focusing: '逐步對焦',
        stepwise_focusing_desc: '根據物件的高度，在每次執行次數中逐漸降低對焦距離。',
        strength: '功率',
        times: '次',
        various_preset: '多個參數',
        white_ink: '白墨',
        white_ink_settings: '白墨設定',
        wobble: '擺動',
        wobble_desc: '沿路徑產生小圓圈，對切割工作有幫助。（僅適用於路徑物件。）',
        wobble_diameter: '直徑',
        wobble_step: '間距',
        z_step: '每次遞降',
      },
      layer_panel: {
        current_layer: '目前圖層',
        layer1: '預設圖層',
        layer_bitmap: '點陣圖層',
        layer_cutting: '切割圖層',
        layer_engraving: '雕刻圖層',
        layers: {
          del: '刪除圖層',
          dupe: '複製圖層',
          fullColor: '全彩',
          layer: '圖層',
          layers: 'Layers',
          lock: '鎖定圖層',
          merge_all: '合併全部圖層',
          merge_down: '向下合併圖層',
          merge_selected: '合併選取圖層',
          move_down: '向下移動圖層',
          move_elems_to: '移動物件',
          move_selected: '移動被點選的物件其他圖層',
          move_up: '向上移動圖層',
          new: '新增圖層',
          rename: '重新命名圖層',
          splitFullColor: '展開圖層',
          switchToFullColor: '切換成彩色圖層',
          switchToSingleColor: '切換成單色圖層',
          unlock: '解鎖',
        },
        move_elems_to: '移動到：',
        notification: {
          dupeLayerName: '圖層名稱與現有圖層重複，請使用別的名稱。',
          enterNewLayerName: '請輸入新圖層的名稱',
          enterUniqueLayerName: '請輸入一個不重複的名稱',
          layerHasThatName: '圖層本來就是這個名稱',
          mergeLaserLayerToPrintingLayerMsg: '請注意，如果您繼續此操作，雷射圖層的設定將會被刪除，並根據當前圖層參數而定。',
          mergeLaserLayerToPrintingLayerTitle: '您是否要將多個圖層合併為一個列印圖層？',
          mergePrintingLayerToLaserLayerMsg: '請注意，如果您繼續此操作，列印圖層的顏色設定將會被刪除，並根據當前圖層參數而定。',
          mergePrintingLayerToLaserLayerTitle: '您是否要將多個圖層合併為一個雷射圖層？',
          moveElemFromPrintingLayerMsg: '請注意，如果您繼續此操作，選取物件的顏色設定將會被刪除，並根據 %s 參數而定。',
          moveElemFromPrintingLayerTitle: '您是否要移動選取物件至 %s 並轉換為雷射物件？',
          moveElemToPrintingLayerMsg: '請注意，如果您繼續此操作，選取物件的設定將會被刪除，並根據 %s 參數而定。',
          moveElemToPrintingLayerTitle: '您是否要移動選取物件至 %s 並轉換為列印物件？',
          newName: '新圖層名稱',
          QmoveElemsToLayer: "要搬移所選取的物件到'%s'嗎?",
          splitColorMsg: '請注意，如果您完成此操作，展開的圖層將無法重新合併。',
          splitColorTitle: '您是否要將所選圖層展開為 CMYK 圖層？',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: '一鍵去背',
          ai_bg_removal_reminder: '按下按鈕將立即使用 0.2 Credit，是否繼續？',
          ai_bg_removal_short: 'AI去背',
          array: '陣列',
          auto_fit: '自動對位',
          bevel: '生成斜角',
          brightness: '亮度對比',
          convert_to_path: '轉換為路徑',
          create_textpath: '建立路徑文字',
          create_textpath_short: '路徑文字',
          crop: '裁剪',
          decompose_path: '解散非連續路徑',
          detach_path: '解散路徑文字',
          detach_path_short: '解散',
          disassemble_use: '解散圖檔',
          disassembling: '解散中...',
          edit_path: '編輯路徑',
          fetching_web_font: '取得線上字體中...',
          grading: '曲線',
          invert: '色彩反轉',
          offset: '位移複製',
          outline: '勾勒輪廓',
          replace_with: '替換影像',
          replace_with_short: '替換影像',
          sharpen: '銳化',
          simplify: '路徑優化',
          smart_nest: '智慧排版',
          trace: '向量化',
          ungrouping: '解散群組中...',
          uploading_font_to_machine: '上傳字體到機器中...',
          wait_for_parsing_font: '解析字體中...',
          weld_text: '合併文字',
        },
        align: '對齊',
        boolean: '布林',
        bottom_align: '底部對齊',
        center_align: '置中對齊',
        difference: '排除重疊形狀',
        distribute: '均分',
        flip: '翻轉',
        group: '群組',
        hdist: '水平均分',
        hflip: '水平翻轉',
        intersect: '形狀區域相交',
        left_align: '靠左對齊',
        lock_aspect: '鎖定長寬比',
        middle_align: '中線對齊',
        option_panel: {
          color: '顏色',
          fill: '填充',
          font_family: '字體',
          font_size: '字級',
          font_style: '字型',
          letter_spacing: '字距',
          line_spacing: '行距',
          path_infill: '路徑填充',
          pwm_engraving: '深度模式',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/zh-tw/articles/10419884701327',
          rounded_corner: '圓角',
          shading: '漸層',
          sides: '邊數',
          start_offset: '文字偏移',
          stroke: '邊框',
          stroke_color: '邊框顏色',
          stroke_width: '邊框寬度',
          text_infill: '文字填充',
          threshold: '臨界值',
          threshold_short: '臨界值',
          vertical_align: '對齊',
          vertical_text: '直書',
        },
        path_edit_panel: {
          connect: '連接',
          delete: '刪除',
          disconnect: '斷開',
          node_type: '節點類型',
          round: '平滑',
          sharp: '銳利',
        },
        right_align: '靠右對齊',
        subtract: '去除前面形狀',
        top_align: '頂端對齊',
        ungroup: '解散群組',
        union: '組合形狀',
        unlock_aspect: '解鎖長寬比',
        vdist: '垂直均分',
        vflip: '垂直翻轉',
        zoom: '縮放',
      },
      tabs: {
        layers: '圖層',
        objects: '物件',
        path_edit: '路徑編輯',
      },
      uv_print_block: {
        title: 'UV 印刷',
      },
    },
    shapes_panel: {
      animals: '動物',
      arrow: '箭頭',
      basic: '基本',
      birds: '鳥類',
      celebration: '慶祝',
      circular: '圓形',
      CNY: '農曆新年',
      corner: '角落',
      decor: '裝飾',
      easter: '復活節',
      elements: '元素',
      environment: '環境',
      graphics: '圖形',
      halloween: '萬聖節',
      holidays: '節日',
      label: '標籤',
      land: '陸地',
      line: '線條',
      nature: '自然',
      photo: '相框',
      plants: '植物',
      ribbon: '緞帶',
      sea: '海洋',
      shape: '形狀',
      speech: '對話',
      text: '文字方塊',
      title: '元素',
      valentines: '情人節',
      weather: '天氣',
      Xmas: '聖誕節',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: '無法讀取複製連結中的檔案',
      unnsupported_file_type: 'Beam Studio 不直接支援此檔案格式。請先輸出成圖片檔或 SVG 格式',
    },
    tag: {
      g: '群組',
      image: '圖片',
      text: '文字',
      use: '匯入圖檔',
    },
    time_est_button: {
      calculate: '計算時間',
      estimate_time: '預計所需時間：',
    },
    tool_panels: {
      _nest: {
        end: '結束',
        no_element: '沒有物件可以進行排列。',
        rotations: '旋轉方向數',
        spacing: '間距',
        start_nest: '開始排列',
        stop_nest: '停止排列',
      },
      _offset: {
        corner_type: '邊角',
        direction: '偏移方向',
        dist: '偏移距離',
        fail_message: '生成偏移物件失敗',
        inward: '向內',
        not_support_message: '選取物件中含有不支援的類型：<br/>圖片、群組、文字、匯入圖檔。',
        outward: '向外',
        round: '圓角',
        sharp: '尖角',
      },
      array_dimension: '陣列維度',
      array_interval: '陣列間隔',
      cancel: '取消',
      columns: '行',
      confirm: '確認',
      dx: '寬',
      dy: '高',
      grid_array: '生成陣列',
      nest: '排列最佳化',
      offset: '偏移物件',
      rows: '列',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: '配合視窗尺寸',
    },
  },
  boxgen: {
    add_option: '添加選項',
    basic_box: '基本款',
    beam_radius: '切口補償',
    beam_radius_warning: '建議在盒子或榫接邊較短時取消補償，以確保盒子的接合。',
    cancel: '取消',
    coming_soon: '即將推出',
    continue_import: '繼續匯入',
    control_tooltip: '左鍵旋轉\n滾輪縮放\n右鍵移動',
    control_tooltip_touch: '拖曳旋轉\n捏合縮放\n雙指移動',
    cover: '蓋子',
    customize: '自定義',
    depth: '深度',
    edge: '邊緣',
    finger: '指接榫',
    finger_warning: '盒子內邊長需要至少 6mm (0.24英吋) 以適用於指接榫接合方式。',
    height: '高度',
    import: '匯入',
    inner: '內部',
    joints: '接合',
    max_dimension_tooltip: '最大寬度/高度/深度設定為 %s。',
    merge: '合併',
    outer: '外部',
    reset: '重置',
    tCount: 'T 槽數量',
    tCount_tooltip: 'T 槽數量適用於短邊；長邊的數量則根據其長度計算。',
    tDiameter: 'T 槽直徑',
    text_label: '標籤',
    thickness: '厚度',
    title: 'BOXGEN',
    tLength: 'T 槽長度',
    tSlot: 'T 型槽',
    tSlot_warning: '盒子邊長需要至少 30mm (1.18英吋) 以適用於 T 型槽接合方式。',
    volume: '尺寸',
    width: '寬度',
    workarea: '工作區',
    zoom: '縮放',
  },
  buttons: {
    back: '上一步',
    back_to_beam_studio: '回到 Beam Studio',
    done: '完成',
    next: '下一步',
  },
  calibration: {
    ador_autofocus_focusing_block: '在機器面板上長壓「AF」圖示 3 秒鐘，讓探針觸碰對焦塊。',
    ador_autofocus_material: '在機器的主頁上長按「AF」圖示 3 秒鐘，並讓探針輕輕觸碰材料。',
    align_ilb: '位置：對齊內側左下角的雕刻點。',
    align_ilt: '位置：對齊內側左上角的雕刻點。',
    align_irb: '位置：對齊內側右下角的雕刻點。',
    align_irt: '位置：對齊內側右上角的雕刻點。',
    align_olb: '位置：對齊外側左下角的雕刻點。',
    align_olt: '位置：對齊外側左上角的雕刻點。',
    align_orb: '位置：對齊外側右下角的雕刻點。',
    align_ort: '位置：對齊外側右上角的雕刻點。',
    align_red_cross_cut: '請將紅色十字的中心對齊切割出來的十字中心。',
    align_red_cross_print: '請將紅色十字的中心對齊列印出來的十字中心。',
    analyze_result_fail: '校正失敗<br/>請確認:<br/>1. 校正圖片完整畫在 A4 紙上<br/>2. 已旋轉升降平台旋鈕，直到輕觸焦距螺絲，完成對焦',
    ask_for_readjust: '是否需要跳過切割步驟，進行拍照及校正？',
    back: '上一步',
    calculating_camera_matrix: '計算相機矩陣中...',
    calculating_regression_parameters: '計算回歸參數中...',
    calibrate_camera_before_calibrate_modules: '在校正模組前，請先進行相機校正。',
    calibrate_chessboard_success_msg: '成功拍攝棋盤格照片，此次照片分數為 %s (%.2f)。',
    calibrate_done: '校正相機完成！使用時請正確對焦以取得良好的預覽效果。',
    calibrate_done_diode: '校正完成！二極體雷射模組偏移值已自動儲存。',
    calibrating: '校正中...',
    calibrating_with_device_pictures: '使用機器照片進行校正...',
    camera_calibration: '相機校正',
    camera_parameter_saved_successfully: '相機參數保存成功。',
    cancel: '取消',
    check_checkpoint_data: '檢查點資料',
    check_device_pictures: '檢查機器照片',
    checking_checkpoint: '正在檢查檢查點資料...',
    checking_pictures: '正在檢查機器照片...',
    diode_calibration: '二極體雷射模組校正',
    do_engraving: '執行切割',
    download_chessboard_file: '下載棋盤格文件',
    downloading_checkpoint: '下載檢查點資料...',
    downloading_pictures: '下載圖片中...',
    drawing_calibration_image: '繪製校正圖片中...',
    dx: '水平位移',
    dy: '垂直位移',
    elevate_and_cut: '抬升和切割',
    elevate_and_cut_step_1: '請將 A4 尺寸的淺色木板放在工作區的中央，並將其墊高至 20mm 高。',
    elevate_and_cut_step_1_prism_lift: '使用 Ador 刀條的最長邊約 14mm 搭配 6mm 以上木板，將其墊高至 20mm 高。',
    failed_to_calibrate_camera: '#848 校正相機失敗，請聯絡 FLUX 客服。',
    failed_to_calibrate_chessboard: '無法使用棋盤圖片進行校正。',
    failed_to_calibrate_with_pictures: '#848 使用機器照片進行校正失敗。',
    failed_to_download_pictures: '#848 下載圖片失敗，請聯絡 FLUX 客服。',
    failed_to_move_laser_head: '無法移動雷射頭。',
    failed_to_parse_checkpoint: '無法解析檢查點資料.',
    failed_to_save_calibration_results: '#849 儲存校正結果失敗，請再試一次。若此錯誤持續發生，請聯絡 FLUX 客服。',
    failed_to_save_camera_parameter: '保存相機參數失敗。',
    failed_to_solve_pnp: '無法解出相機位置。',
    finish: '完成',
    found_checkpoint: '在您的機器上找到檢查點資料。您想從檢查點還原嗎？',
    getting_plane_height: '取得平面高度...',
    hint_adjust_parameters: '由這些參數來調整紅框的位置，旋轉與大小',
    hint_red_square: '請將紅框外側對齊切割出來的方塊',
    module_calibration_2w_ir: '紅外線模組校正',
    module_calibration_printer: '列印模組校正',
    moving_laser_head: '移動雷射頭中...',
    next: '下一步',
    no_picutre_found: '#846 您的機器中沒有可使用的照片以用於校正，請聯繫 FLUX 客服。',
    perform_autofocus_bb2: '請前往機器控制面板並按下 AF 以執行自動對焦。',
    please_do_camera_calibration_and_focus: {
      beambox: '校正二極體雷射需要使用相機校正參數，請確認您的機器已進行過相機校正。並請旋轉升降平台旋鈕，直到輕觸焦距螺絲或焦距尺，完成對焦',
      beamo: '校正二極體雷射需要使用相機校正參數，請確認您的機器已進行過相機校正。並請轉開焦距固定環，調整雷射頭至平台輕觸焦距尺，完成對焦',
    },
    please_goto_beambox_first: '請先選擇 Beambox 功能，再進行校正',
    please_place_dark_colored_paper: '請將乾淨 A4 深色紙放在工作區的中央。',
    please_place_paper: '請將乾淨 A4 白紙放在工作區域的左上角',
    please_place_paper_center: '請將乾淨 A4 白紙放在工作區的中央。',
    please_refocus: {
      beambox: '請旋轉升降平台旋鈕，直到輕觸焦距螺絲或焦距尺，完成對焦後，轉回對焦尺。',
      beamo: '請轉開焦距固定環，調整雷射頭至平台輕觸焦距尺，完成對焦後，旋緊固定環、轉回對焦尺。',
      beamo_af: '請雙擊自動對焦套件側邊按鈕，使探針輕觸雕刻材料。',
      hexa: '請連續按壓升降平台左側按鈕 2 次，使平台上移、自動對焦探針輕觸雕刻材料。',
    },
    preparing_to_take_picture: '準備拍攝...',
    promark_help_link: 'https://support.flux3dp.com/hc/zh-tw/articles/11173605809295',
    put_chessboard: '放置棋盤板',
    put_chessboard_1: '請前往機器控制面板並按下 AF 以執行自動對焦。',
    put_chessboard_2: '請移動棋盤紙或雷射頭，直到確認整個棋盤位於即時預覽視窗中的紅框內。',
    put_chessboard_3: '你可以右鍵點擊下載當前位置的棋盤格照片，檢查其清晰度。',
    put_chessboard_bb2_desc_1: '請下載以下棋盤格檔案，並將其列印於 A4 紙上（列印後的棋盤格需為 1x1 cm）。',
    put_chessboard_bb2_desc_2: '將列印好的棋盤紙平整地貼在壓克力或玻璃等不易變形的板材上，務必確保棋盤格平整且無皺摺或浮起。',
    put_chessboard_bb2_desc_3: '將貼有棋盤格的板材平放於工作區域的中央。',
    put_chessboard_promark_1: '將焦距調整至該場鏡的適當焦距。',
    put_chessboard_promark_2: '請確認棋盤格清晰可見，避免反光。點選「下一步」以拍攝照片。',
    put_chessboard_promark_desc_1: '請使用配件盒內提供的棋盤格紙，或將以下棋盤格圖檔列印於 A4 紙上，以進行相機校正。',
    put_chessboard_promark_desc_2: '請將棋盤格平放置於工作平台中央。',
    put_paper: '放置紙張',
    put_paper_promark_1: '將配件盒中的黑卡紙放置在工作平台上。',
    put_paper_promark_2: '適當調整焦距，點選「開始繪製校正圖形」以繪製圖形。',
    put_paper_skip: '若繪製校正圖形結束後未自動進入拍攝動作，可按「跳過」以進行拍攝。',
    put_paper_step1: '請將乾淨 A4 白紙放在工作區的中央。',
    put_paper_step2: '將紙張四個角落固定，確保紙張沒有浮起。',
    put_paper_step3: '點選「開始繪製校正圖形」以繪製圖形。',
    res_average: '中等',
    res_excellent: '良好',
    res_poor: '較差',
    retake: '重拍照片',
    rotation_angle: '旋轉角度',
    show_last_config: '顯示前次校正結果',
    skip: '跳過',
    solve_pnp_step1: '請根據每個紅色標記點的編號和預期位置對齊雕刻點。',
    solve_pnp_step2: '您可以按下「重拍照片」以重試對齊或手動調整標記點位置。',
    solve_pnp_step3: '若拍攝畫面與實際不符，請縮放或拖曳畫面，將黑卡紙上校正點的範圍調整至正中央，再嘗試「重拍照片」。',
    solve_pnp_title: '對齊標記點',
    start_engrave: '開始繪製校正圖形',
    start_printing: '開始列印校正圖形',
    taking_picture: '擷取圖片中...',
    unable_to_load_camera_parameters: '#851 您的設備未找到可用的相機校正參數。請前往「相機校正」>「相機校正（進階）」完成校正以取得參數。',
    update_firmware_msg1: '您的韌體版本不支援此功能。請先更新 Beambox 的韌體至 v',
    update_firmware_msg2: '以上以繼續。 (主選單 > 機器 > [ Your Beambox ] > 韌體更新',
    uploading_images: '上傳圖片中...',
    use_last_config: '匯入前次校正數值',
    use_old_camera_parameter: '您要使用目前的相機鏡頭參數嗎？',
    with_af: '有自動對焦',
    with_prism_lift: '使用 Ador 刀條',
    without_af: '無自動對焦',
    without_prism_lift: '不使用 Ador 刀條',
    x_ratio: '水平比例',
    y_ratio: '垂直比例',
    zendesk_link: 'https://support.flux3dp.com/hc/zh-tw/articles/360001811416',
  },
  camera_data_backup: {
    download_success: '相機數據下載成功。',
    downloading_data: '正在下載資料...',
    estimated_time_left: '預計剩餘時間：',
    folder_not_exists: '所選的資料夾不存在。',
    incorrect_folder: '相機資料上傳失敗。請檢查您選擇的資料夾是否正確。',
    no_picture_found: '機器中未找到任何圖片。',
    title: '相機資料備份',
    upload_success: '相機數據上傳成功。',
    uploading_data: '正在上傳資料...',
  },
  caption: {
    connectionTimeout: '連線逾時',
  },
  change_logs: {
    added: '新增：',
    change_log: '更新日誌',
    changed: '更改：',
    fixed: '修正：',
    help_center_url: 'https://support.flux3dp.com/hc/zh-tw/sections/360000421876',
    see_older_version: '查看之前版本',
  },
  code_generator: {
    barcode: '條碼',
    qr_code: 'QR碼',
  },
  curve_engraving: {
    '921': '自動對焦失敗。',
    '922': '紅光量測失敗。',
    amount: '數量',
    apply_arkwork: '將藝術作品套用於曲面',
    apply_camera: '將相機圖像套用於曲面',
    click_to_select_point: '點擊選擇或取消選擇要重新測量的點。',
    coloumns: '列',
    column_gap: '列間距',
    err_object_over_range: '物件超出可量測距離。',
    failed_to_take_reference: '無法取得參考點。',
    gap: '間距',
    help_center_url: 'https://support.flux3dp.com/hc/zh-tw/articles/10364060644495',
    measure_audofocus_area: '測量自動對焦區域',
    preview_3d_curve: '預覽曲面雕刻',
    remeasure: '重新測量',
    remeasuring_points: '正在重新測量點...',
    reselect_area: '重新選擇區域',
    row_gap: '行間距',
    rows: '行',
    set_object_height: '設定物件高度',
    set_object_height_desc: '測量物件的最大厚度。',
    start_autofocus: '開始自動對焦',
    starting_measurement: '正在開始測量...',
    sure_to_delete: '您是否要刪除曲面雕刻對焦資料？',
    take_reference: '取得參考點',
    take_reference_desc: '請將雷射頭移至雕刻物件的最高處，並將對焦探針放下，點擊「確認」進行對焦。',
  },
  device: {
    abort: '取消工作',
    aborted: '已終止',
    aborting: '取消工作中',
    busy: '忙碌中',
    cartridge_info_read_failed: '請確保墨匣完全插入。嘗試將墨水取出並重新安裝。',
    cartridge_info_verification_failed: '請確認您是否使用 FLUX 原廠墨匣。',
    cartridge_serial_number: '墨匣序號',
    close_door_to_read_cartridge_info: '請關閉機器的門蓋以取得墨匣資訊。',
    completed: '已完成',
    completing: '完成中',
    deviceList: '機器列表',
    disable: '關閉',
    disconnectedError: {
      caption: '機器連線中斷',
      message: '請確認 %s 的網路連線是否正常',
    },
    enable: '開啟',
    firmware_version: '韌體版本',
    ink_color: '墨水顏色',
    ink_level: '剩餘墨水量',
    ink_type: '墨水類型',
    IP: 'IP',
    model_name: '型號',
    occupied: '機器被佔用',
    pause: '暫停',
    paused: '已暫停',
    pausedFromError: '發生錯誤暫停',
    pausing: '正在暫停',
    please_wait: '請稍待...',
    preparing: '準備中',
    processing: '處理中',
    quit: '中斷連結',
    ready: '待命中',
    reset: '重設(kick)',
    resuming: '恢復中',
    retry: '重試',
    running: '工作中',
    scanning: '掃描',
    select: '選擇',
    select_printer: '選擇成型機',
    serial_number: '序號',
    start: '開始',
    starting: '啟動中',
    status: '狀態',
    submodule_type: '模組',
    toolhead_change: '更換模組頭',
    unknown: '未知狀態',
    uploading: '上傳中',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 我們在網路上找不到您的機器，<br/>此功能需要在與機器連線的狀態下才會運作，請先新增機器設定連線，<br/>請參考<a target="_blank" href="https://support.flux3dp.com/hc/zh-tw/articles/360001683556">此指南</a>排除連線問題！',
    no_device_web: '#801 請檢查機器狀態，或者點選以下『新增或設定機器』，進行機器設定。',
    select_usb_device: '選擇 USB 裝置',
  },
  editor: {
    exposure: '相機預覽曝光值',
    opacity: '相機預覽不透明度',
    prespray_area: '預噴區',
  },
  error_pages: {
    screen_size: '請注意，Beam Studio 無法在您的裝置上順利執行。若要獲得最佳使用體驗，裝置螢幕寬度至少應有 1024 像素。',
  },
  flux_id_login: {
    connection_fail: '#847 無法連接至 FLUX 會員中心。',
    email: '電子信箱',
    flux_plus: {
      access_monotype_feature: '您無法使用 Monotype 字型。',
      access_monotype_feature_note: '您必須擁有 FLUX+ Pro 會員資格或加購 Monotype 字型才能使用此功能。',
      access_plus_feature_1: '您正在使用',
      access_plus_feature_2: '功能。',
      access_plus_feature_note: '您必須擁有 FLUX+ 會員資格才能使用此功能。',
      ai_credit_tooltip: '用於 AI 去背',
      explore_plans: '探索 FLUX+ 方案',
      features: {
        ai_bg_removal: 'AI 去背',
        boxgen: '3D 盒子產生器',
        dmkt: '1000+ 設計檔案',
        monotype: '250+ 商用字型',
        my_cloud: '雲端儲存空間',
      },
      flux_credit_tooltip: '用於 Design Market 檔案和 AI 去背',
      get_addon: '加購',
      goto_member_center: '前往會員中心',
      learn_more: '了解更多',
      member_center_url: 'https://member.flux3dp.com/zh-TW/subscription',
      subscribe_now: '立即訂閱',
      thank_you: '感謝您成為我們的會員!',
      website_url: 'https://tw.flux3dp.com/subscription',
    },
    forget_password: '忘記密碼',
    incorrect: '信箱或密碼輸入錯誤。',
    login: '登入',
    login_success: '登入成功',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: '還不是會員？免費註冊新帳號',
    not_verified: '信箱尚未認證。',
    offline: '離線使用',
    password: '密碼',
    register: '註冊 FLUX 帳號',
    remember_me: '記住我',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: '登入使用百萬圖形資源',
    work_offline: '我要離線使用',
  },
  framing: {
    area_check: '區域檢查',
    areacheck_desc: '藉由預覽物件的邊界框與雷射頭的加速區，確保工作區的安全。',
    calculating_task: '正在計算外框工作...',
    framing: '外框',
    framing_desc: '預覽物件的邊界框。',
    hull: '凸包',
    hull_desc: '預覽緊密貼合設計的形狀，像橡皮筋纏繞著物件一樣。',
    low_laser: '弱出光',
    low_laser_desc: '設定低功率雷射值以進行外框任務。',
    rotate_axis: '旋轉軸',
    rotateaxis_desc: '預覽旋轉軸中心，旋轉軸本身不移動。',
    rotation_frame_warning: '外框預覽會旋轉一圈，請避免中途停止，以免導致打點位置偏移。',
    rotation_framing_desc: '預覽完整旋轉範圍，旋轉軸會隨著預覽旋轉。',
    start_task: '開始任務',
    start_task_description: '完成外框預覽後，點擊「開始任務」提交工作。',
  },
  general: {
    choose_folder: '選擇檔案夾',
    processing: '處理中...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] 機器發生狀態衝突，請再試一次',
    SUBSYSTEM_ERROR: '[SE] 韌體執行雕刻程序錯誤，請嘗試重新啟動機器',
    UNKNOWN_COMMAND: '[UC] 請更新機器韌體',
    UNKNOWN_ERROR: '[UE] 發生未知的錯誤，請重新啟動 Beam Studio 以及機器',
  },
  global: {
    apply: '套用',
    back: '返回',
    cancel: '取消',
    editing: {
      redo: '重做',
      reset: '重置',
      undo: '復原',
      zoom_in: '放大',
      zoom_out: '縮小',
    },
    mode_conflict: '當前模式下無法使用此選項。',
    ok: '確定',
    save: '儲存',
    stop: '停止',
  },
  image_edit_panel: {
    eraser: {
      brush_size: '畫筆大小',
      description: '點擊或拖曳以手動擦除不需要的區域。',
      title: '橡皮擦',
    },
    magic_wand: {
      description: '根據顏色相似性選擇並移除相鄰區域。',
      title: '魔術棒',
      tolerance: '容差',
    },
    title: '編輯圖片',
  },
  initialize: {
    back: '返回',
    cancel: '取消',
    confirm: '確認',
    connect: '連接',
    connect_ethernet: {
      title: '網路線直連',
      tutorial1: '1. 將您的機器與您的電腦以乙太網路線連接。',
      tutorial2_1: '2. 依照',
      tutorial2_2: '使您的電腦同時扮演路由器的角色。',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/zh-tw/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/zh-tw/articles/360001507715',
      tutorial2_a_text: '這篇文章',
      tutorial3: '3. 點選 下一步。',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: '無法連接到伺服器。請重新啟動Beam Studio並重試。',
      },
      check_camera: '確認相機',
      check_connection: '確認機器連線',
      check_firmware: '確認韌體版本',
      check_ip: '確認 IP',
      check_swiftray_connection: '正在檢查伺服器連線',
      check_swiftray_connection_unreachable: '伺服器無法連線',
      check_usb: '確認 USB 連線',
      enter_ip: '請輸入您的機器 IP',
      finish_setting: '開始創作',
      invalid_format: '格式不符合',
      invalid_ip: 'IP 錯誤：',
      promark_hint: '如果您持續遇到 USB 連線失敗問題，請參考<a target="_blank" href="https://support.flux3dp.com/hc/zh-tw/articles/11318820440591">說明中心文章</a>。',
      retry: '重試',
      starts_with_169254: '由 169.254 開頭',
      succeeded_message: '連線成功 🎉',
      unreachable: '無法連接至指定 IP',
    },
    connect_usb: {
      connect_camera: '將機器的攝影機與您的電腦以 USB 線連接。',
      title: 'USB 連線',
      title_sub: '（僅支援 HEXA & Ador）',
      turn_off_machine: '將您的機器關機',
      turn_on_machine: '將您的機器開機',
      tutorial1: '將您的機器與您的電腦以 USB 線連接。',
      tutorial2: '點選 下一步。',
      wait_for_turning_on: '待開機程序完成進入主畫面後，點選 下一步',
    },
    connect_wifi: {
      title: '連接 Wi-Fi',
      tutorial1: '1. 到機器控制面板 > 點擊「網路」 > 「設定 Wi-Fi」。',
      tutorial1_ador: '1. 到機器控制面板 > 點擊「機器」 > 點擊「網路」 > 「設定 Wi-Fi」。',
      tutorial2: '2. 選擇並連接您想使用的 Wi-Fi 。',
      what_if_1: '機器找不到我想使用的 Wi-Fi',
      what_if_1_content: '1. Wi-Fi 加密方式需為 WPA2 或無密碼。\n2. 加密方式可由 Wi-Fi 路由器設定，如果路由器不支援 WPA2，可聯繫客服購買，如果不確定路由器是否支援，可以將型號傳給客服詢問。',
      what_if_2: '機器找不到任何 Wi-Fi',
      what_if_2_content: '1. 確認您的 Wi-Fi 接收器是否有著實插上。\n2. 如果面板上沒有出現無線網路硬體位置，請聯繫客服。\n3. Wi-Fi 頻道為 2.4Ghz (不支援 5Ghz)。',
    },
    connect_wired: {
      title: '連接有線網路',
      tutorial1: '1. 請將您的機器以乙太網路線與路由器連接。',
      tutorial2: '2. 在觸控面板點擊「網路」以獲得有線網路 IP 。',
      tutorial2_ador: '',
      what_if_1: '機器顯示的有線網路 IP 是空的',
      what_if_1_content: '1. 確認您的乙太網路線是否有著實插上。\n2. 如果面板上沒有出現有線網路硬體位置，請聯繫客服。',
      what_if_2: '機器顯示的 IP 開頭為 169',
      what_if_2_content: '1. IP 地址為 169.254 開頭通常為 DHCP 設定問題，需要聯繫網路服務提供商或是網路設定者來協助。\n2. 如果工作環境的網路是由電腦直接 PPPoE 連網，請改由路由器直接 PPPoE 聯網，並在路由器中開啟DHCP 功能。',
    },
    connecting: '連接中',
    connection_types: {
      ether2ether: '網路線直連',
      usb: 'USB 連線',
      wifi: 'Wi-Fi',
      wired: '有線網路',
    },
    next: '下一步',
    no_machine: '目前沒有機器或已設定過連線，跳過此步驟',
    promark: {
      configuration_confirmation: '這可確保您的 Promark 已正確配置，以實現最佳效率和精確性。',
      or_complete_later: `或者，跳過此步驟，稍後在以下位置完成 Promark 設定：
    機器 > 「Promark 名稱」 > Promark 設定`,
      qc_instructions: '填寫「QC Pass」卡背面的參數',
      select_laser_source: '選擇您的 Promark',
      select_workarea: '選擇工作區域',
      settings: 'Promark 設定',
    },
    retry: '重試',
    select_beambox: '請選擇您的 Beambox',
    select_connection_type: '請選擇您的連接方式',
    select_language: '請選擇你想使用的語言',
    select_machine_type: '請選擇您的機種',
    setting_completed: {
      back: '回到 Wi-Fi 設定',
      great: '歡迎使用 Beam Studio',
      ok: '開始使用',
      setup_later: '您可以隨時從選單 >「機器」>「新增或設定機器」來設定連線。',
      start: '開始使用',
    },
    skip: '跳過',
    start: '開始使用',
  },
  input_machine_password: {
    connect: '連接',
    password: '密碼',
    require_password: '"%s" 需要密碼',
  },
  insecure_websocket: {
    extension_detected: '偵測到 Beam Studio Connect 擴充功能',
    extension_detected_description: '我們偵測到您已安裝了 Beam Studio Connect 擴充功能。請按下「確認」重新導向至 HTTPS，或者選擇「取消」以繼續使用目前的 HTTP 網址。',
    extension_not_deteced: '無法偵測到 Beam Studio Connect 擴充功能',
    extension_not_deteced_description: '若要使用 HTTPS，請按下「確認」以前往安裝 Beam Studio Connect 擴充功能，安裝擴充功能後，請重新頁面以啟用。<br/>否則，請點擊下面的連結查看如何在 Chrome 中使用 HTTP。',
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/zh-tw/articles/9935859456271">連結</a>',
  },
  layer_module: {
    general_laser: '雷射',
    laser_2w_infrared: '2W 紅外線雷射',
    laser_10w_diode: '10W 二極體雷射',
    laser_20w_diode: '20W 二極體雷射',
    non_working_area: '非工作區域',
    none: '無',
    notification: {
      convertFromLaserModuleMsg: '請注意，如果您繼續此操作，雷射圖層的設定將會被刪除，並根據當前圖層參數而定。',
      convertFromLaserModuleTitle: '您是否要將雷射圖層轉換為列印圖層？',
      convertFromPrintingModuleMsg: '請注意，如果您繼續此操作，列印圖層的顏色設定將會被刪除，並根據當前圖層參數而定。',
      convertFromPrintingModuleTitle: '您是否要將列印圖層轉換為雷射圖層？',
      importedDocumentContainsPrinting: '這份文件包含列印圖層，您是否要將工作範圍切換成 Ador ？',
      performIRCaliMsg: '點選 「確認」 以執行校正，或者透過上方選單中的「機器」>「您的機器名稱」>「校正紅外線模組」進行校正。',
      performIRCaliTitle: '執行紅外線模組校正',
      performPrintingCaliMsg: '點選 「確認」 以執行校正，或者透過上方選單中的「機器」>「您的機器名稱」>「校正列印模組」進行校正。',
      performPrintingCaliTitle: '執行列印模組校正',
      printingLayersConverted: '列印圖層已轉換為雷射圖層。',
    },
    printing: '列印',
    unknown: '未知模組',
    uv_print: 'UV 印刷',
  },
  machine_status: {
    '-17': '墨匣模式',
    '-10': '動作模式',
    '-2': '掃描中',
    '-1': '維護中',
    0: '待命中',
    1: '初始化',
    2: 'ST_TRANSFORM',
    4: '啟動中',
    6: '回復中',
    16: '工作中',
    18: '回復中',
    32: '已暫停',
    36: '已暫停',
    38: '暫停中',
    48: '已暫停',
    50: '暫停中',
    64: '已完成',
    66: '完成中',
    68: '準備中',
    128: '已中斷',
    256: 'Alarm',
    512: 'Fatal',
    UNKNOWN: '-',
  },
  material_test_generator: {
    block_settings: '區塊設定',
    columns: '行',
    count: '數量',
    cut: '切割',
    engrave: '刻印',
    export: '匯出',
    max: '最大值',
    min: '最小值',
    parameter: '參數',
    preview: '預覽',
    rows: '列',
    size: '尺寸 (高x寬)',
    spacing: '間距',
    table_settings: '參數設定',
    text_settings: '文字設定',
    title: '材質測試產生器',
  },
  menu: {
    inches: '英吋',
    mm: '毫米',
  },
  message: {
    auth_error: '#820 認證失敗：請將 Beam Studio 以及機器韌體更新至最新版。',
    authenticating: '密碼驗證中...',
    camera: {
      abort_preview: '中斷預覽',
      camera_cable_unstable: '偵測到相機傳輸照片時不穩定，仍能正常進行相機預覽，但可能會有預覽速度較慢或超時的問題。<a target="_blank" href="https://support.flux3dp.com/hc/zh-tw/articles/360001791895">了解更多</a>',
      continue_preview: '繼續預覽',
      fail_to_transmit_image: '#845 相機傳輸照片異常，請將機器重新開機。如果問題持續發生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/4402756056079">此則引導</a>。',
      ws_closed_unexpectly: '#844 與機器相機的連線無預期的中斷。如果問題持續發生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/4402755805071">此則引導</a>。',
    },
    cancelling: '取消中...',
    cant_connect_to_device: '#827 無法連結機器，請確認機器是否開啟，以及與機器的連結方式',
    connected: '已連線',
    connecting: '連線中...',
    connectingCamera: '正在連接相機...',
    connectingMachine: '連接 %s 中...',
    connectionTimeout: '#805 連接機器逾時，請確認你的網路狀態和機器的 Wi-Fi 訊號符號。',
    device_blocked: {
      caption: '未授權的序號',
      offline: '您的設備序號的離線使用已被限制，因為似乎已被停用。請將設備序號（位於設備背面）提供給您的經銷商，並請他們聯繫 support@flux3dp.com 以啟用線上授權。如果您希望離線使用該設備，請直接聯繫 support@flux3dp.com。',
      online: '您的設備序號似乎已被停用。請將設備序號（位於設備背面）提供給您的經銷商，並請他們聯繫 support@flux3dp.com 以啟用設備授權。',
    },
    device_busy: {
      caption: '機器忙碌中',
      message: '機器正在進行另外一項工作，請稍候再試。如果機器持續沒有回應，請將機器重新啟動。',
    },
    device_is_used: '機器正被使用中，是否要終止現在任務？',
    device_not_found: {
      caption: '找不到預設機器',
      message: '#812 請確認預設機器的 Wi-Fi 指示燈，或取消設定預設機器',
    },
    disconnected: '連線不穩，請確認機器連線狀況並稍後再試一次',
    endingLineCheckMode: '正在結束可靠傳輸模式...',
    endingRawMode: '正在結束基本動作模式...',
    enteringLineCheckMode: '正在進入可靠傳輸模式...',
    enteringRawMode: '正在進入基本動作模式...',
    enteringRedLaserMeasureMode: '正在進入紅光測量模式...',
    exitingRotaryMode: '正在結束旋轉軸模式...',
    getProbePosition: '取得探針位置中...',
    gettingLaserSpeed: '正在取得雷射頭移動速度...',
    homing: '歸零中...',
    need_password: '需要密碼與機器建立連線',
    please_enter_dpi: '請輸入該檔案的單位長度 (公釐)',
    preview: {
      adjust: '調整',
      adjust_height_tooltip: '點選方塊以啟用編輯。',
      already_performed_auto_focus: '您已經執行了自動對焦，要使用現有數值嗎？',
      auto_focus: '自動對焦',
      auto_focus_instruction: '請將雷射模組頭移至物體上方，並依照動畫指示按下AF進行對焦。',
      camera_preview: '相機預覽',
      enter_manually: '手動輸入',
      please_enter_height: '請輸入物體的高度，以拍攝精確的相機照片。',
    },
    promark_disconnected: '#850 設備連線中斷，請確認設備的連線狀態。',
    redLaserTakingReference: '正在取得參考點...',
    retrievingCameraOffset: '正在取得相機參數...',
    settingLaserSpeed: '正在設定雷射頭移動速度...',
    swiftray_disconnected: '無法連線到後端，正在重新嘗試連線。',
    swiftray_reconnected: '後端已重新連線，請嘗試重新送出工作。',
    time_remaining: '剩餘時間：',
    tryingToConenctMachine: '嘗試連接機器中...',
    turningOffAirPump: '正在關閉空氣幫浦...',
    turningOffFan: '正在關閉抽氣風扇...',
    unable_to_find_machine: '無法連接到機器 ',
    unable_to_start: '#830 無法開始工作，如果持續發生，請附上錯誤回報，與我們聯絡:<br/>',
    unavailableWorkarea: '#804 目前設定的工作範圍超過目標機器的工作範圍。請確認選擇的機器型號，或從 編輯 > 文件設定 更改工作範圍。',
    unknown_device: '#826 無法與機器建立連線，請確認 USB 有連接於機器',
    unknown_error: '#821 無法與機器建立連線，請使用「功能表 > 說明 > 錯誤回報」',
    unsupport_osx_version: '目前系統版本 macOS %s 較舊，部分功能可能無法使用，請更新到 macOS 11+。',
    unsupport_win_version: '目前系統版本 %s 較舊，部分功能可能無法使用，請更新到最新版。',
    unsupported_example_file: '選取範例文件不支援的目前工作範圍。',
    uploading_fcode: '正在上傳 fcode',
    usb_unplugged: 'USB 連線逾時，請確認與機器的連接',
    wrong_swiftray_version_message: '後端版本有誤 (版本: {version})，請確認是否有多個 Beam Studio 程式正在運行，並嘗試關閉多餘的程式後重試。',
    wrong_swiftray_version_title: '後端版本有誤',
  },
  monitor: {
    ask_reconnect: '與機器的連線中斷，是否嘗試重新連接？',
    bug_report: '下載錯誤回報檔案',
    camera: '相機',
    cancel: '取消',
    confirmFileDelete: '是否確定要刪除這個檔案？',
    connecting: '連線中，請稍候',
    DEVICE_ERROR: '韌體發生錯誤<br/>請重新啟動機器', // Deprecated in FW 3.3.1
    download: '下載',
    extensionNotSupported: '上傳檔案不支援此檔案格式',
    fileExistContinue: '檔案已存在，是否要覆蓋？',
    forceStop: '是否強制停止現在工作?',
    go: '開始',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 氣流偵測異常',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 底蓋開啟，將底蓋關上以繼續',
    HARDWARE_ERROR_DOOR_OPENED: '#901 門蓋開啟，將門蓋關上以繼續',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 抽屜開啟',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 火焰偵測異常',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 偵測到不相符的模組，請安裝正確的模組頭以繼續操作。',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 未偵測到模組，請確認模組頭已正確安裝以繼續操作。',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 偵測到未知模組。請安裝正確的模組以繼續。',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 歸零 PULL-OFF 失敗',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 主板沒有回應。請聯繫 FLUX 客服。', // Deprecated in FW 3.3.1
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 水溫過高，請稍後再繼續',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z 軸歸零失敗',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 列印頭無回應。',
    HARDWARE_ERROR_PROBE_SHOWED: '請收回探針。',
    HARDWARE_ERROR_PUMP_ERROR: '#900 水冷未開，請聯繫客服。',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 未偵測到旋轉軸',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 子系統沒有回應。請聯繫 FLUX 客服。', // Deprecated in FW 3.3.1
    HARDWARE_FAILURE: '韌體發生錯誤<br/>請重新啟動機器', // Deprecated in FW 3.3.1
    hour: '小時',
    left: '完成',
    MAINBOARD_OFFLINE: '#905 連接主板時發生錯誤<br/>請重新啟動機器',
    minute: '分',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 連接主板時發生錯誤<br/>請重新啟動機器',
    pause: '暫停',
    prepareRelocate: '準備重新定位中',
    processing: '處理中',
    record: 'RECORD',
    relocate: '重新定位',
    RESOURCE_BUSY: '機器忙碌中<br/>如果機器沒有在進行動作， 請重新啟動機器',
    resume: '繼續',
    savingPreview: '正在產生預覽圖',
    second: '秒',
    start: '開始',
    stop: '停止',
    SUBSYSTEM_ERROR: '#402 子系統沒有回應。請聯繫 FLUX 客服。',
    task: {
      BEAMBOX: '雷射工作',
      'N/A': '自由模式',
    },
    taskTab: '工作',
    temperature: '溫度',
    upload: '上傳',
    USER_OPERATION: '請查看機器面板上的指示以繼續。',
    USER_OPERATION_CHANGE_CARTRIDGE: '請安裝正確的墨匣以繼續。',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: '請安裝墨匣以繼續。',
    USER_OPERATION_CHANGE_TOOLHEAD: '請安裝正確的模組以繼續。',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 偵測到不相符的模組，請安裝正確的模組頭以繼續操作。',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 未偵測到模組，請確認模組頭已正確安裝以繼續操作。',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 偵測到未知模組。請安裝正確的模組以繼續。',
    USER_OPERATION_REMOVE_CARTRIDGE: '請移除墨水匣以繼續操作。',
    USER_OPERATION_ROTARY_PAUSE: '請切換旋轉軸馬達開關',
  },
  my_cloud: {
    action: {
      confirmFileDelete: '您確定要刪除此檔案嗎？此操作無法復原。',
      delete: '刪除',
      download: '下載',
      duplicate: '複製',
      open: '開啟',
      rename: '重新命名',
    },
    file_limit: '免費檔案',
    loading_file: '載入中...',
    no_file_subtitle: '轉到選單 > “檔案” > “儲存到雲端”',
    no_file_title: '儲存檔案到我的雲端開始使用。',
    save_file: {
      choose_action: '保存檔案：',
      input_file_name: '另存新檔為：',
      invalid_char: '無效字元:',
      save: '覆蓋舊檔案',
      save_new: '另存新檔',
      storage_limit_exceeded: '您的雲端儲存空間已滿。在儲存新檔案前，請先刪除您不需要的檔案。',
    },
    sort: {
      a_to_z: '名稱: A - Z',
      most_recent: '最近',
      oldest: '最早',
      z_to_a: '名稱: Z - A',
    },
    title: '我的雲端',
    upgrade: '升級',
  },
  noun_project_panel: {
    clear: '清除',
    elements: '元素',
    enjoy_shape_library: '新增百萬圖形資源，請盡情使用。',
    export_svg_title: '無法匯出 SVG',
    export_svg_warning: '專案中含有受知識產權法律的保護的 Noun Project 物件，因此匯出時 Beam Studio 會自動幫您排除這類型之物件，您仍可以透過儲存場景 (.beam 檔) 的方式保留您的專案，請問是否要繼續匯出？',
    learn_more: '更多資訊',
    login_first: '請先登入會員，完成後即可啟用。',
    recent: '最近使用',
    search: '搜尋',
    shapes: '形狀',
  },
  pass_through: {
    export: '輸出到工作區域',
    exporting: '輸出中...',
    guide_mark: '輔助標記',
    guide_mark_desc: '雕刻輔助標記以作為參考點來對齊物件。',
    guide_mark_length: '長度：',
    guide_mark_x: 'X 座標：',
    height_desc: '設定每個區域的幅寬。',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/zh-tw/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/zh-tw/articles/11570021253263',
    },
    help_text: '如何為 %(model)s 設置廣域雕刻？',
    object_length: '物體長度',
    ref_layer: '參考圖層',
    ref_layer_desc: '參考圖層的執行次數預設為 0。它不會被執行，只用於對齊。',
    ref_layer_name: '參考圖層',
    title: '廣域雕刻',
    workarea_height: '工作區域（幅高）',
  },
  promark_connection_test: {
    description: '系統會運行約兩分鐘的紅光工作，以檢查連線是否穩定。',
    health: '健康度：',
    healthy_description: '穩定度良好，但需避免在雕刻過程中大幅移動電腦設備。',
    res_0: '非常良好',
    res_1: '良好',
    res_2: '低',
    res_3: '過低',
    res_4: '非常低',
    restart: '重新測試',
    start: '開始測試',
    stop: '停止測試',
    title: '連線穩定度測試',
    unhealthy_description: '穩定度過低，建議更換網路轉接器或電源線。',
  },
  promark_settings: {
    angle: '角度',
    bulge: '桶形',
    field: '區域',
    galvo_configuration: '振鏡設定',
    mark: '標記',
    mark_parameters: '標記參數',
    offsetX: 'X 偏移',
    offsetY: 'Y 偏移',
    preview: '預覽',
    red_dot: '紅點',
    scale: '縮放',
    scaleX: 'X 縮放',
    scaleY: 'Y 縮放',
    skew: '傾斜',
    switchXY: '切換 X/Y',
    title: 'Promark 設定',
    trapezoid: '梯形',
    workarea_hint: '您可以在「文件設定」中更改工作範圍。',
    z_axis_adjustment: {
      section1: '調整Z軸高度以微調焦點。',
      title: 'Z軸調整',
      tooltip1: '嘗試標記一個1x1公分的方形以確認當前焦距是否合適。',
    },
  },
  qr_code_generator: {
    error_tolerance: '容錯率',
    error_tolerance_link: 'https://support.flux3dp.com/hc/zh-tw/articles/9113705072143',
    invert: '反轉背景顏色',
    placeholder: '輸入連結或文字',
    preview: '預覽',
    title: 'QR 碼產生器',
  },
  rotary_settings: {
    circumference: '圓周',
    extend_workarea: '擴展工作區域',
    mirror: '鏡像',
    object_diameter: '物件直徑',
    overlap_size: '重疊大小',
    rotary_scale: '旋轉倍率',
    split_setting: '分割設定',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: '分割大小',
    type: '類型',
  },
  select_device: {
    auth_failure: '#811 認證失敗',
    select_device: '選擇機器',
    unable_to_connect: '#810 無法與機器建立穩定連線',
  },
  settings: {
    anti_aliasing: '反鋸齒',
    auto_connect: '自動選擇唯一機器',
    auto_switch_tab: '自動切換圖層與物件面板',
    autofocus_offset: '自動對焦高度補償',
    autosave_enabled: '自動儲存',
    autosave_interval: '儲存間隔',
    autosave_number: '自動儲存數',
    autosave_path: '檔案夾',
    autosave_path_not_correct: '找不到指定的路徑',
    blade_precut_position: '預切位置',
    blade_precut_switch: '旋轉刀預切',
    blade_radius: '旋轉刀半徑',
    bottom_up: '由下往上',
    calculation_optimization: '路徑計算加速',
    cancel: '取消',
    caption: '設定',
    check_updates: '自動檢查',
    close: '關閉',
    confirm_remove_default: '將會刪除預設機器',
    confirm_reset: '確認要重置 Beam Studio?',
    continuous_drawing: '連續繪製',
    curve_engraving_speed_limit: '曲面雕刻速度限制',
    custom_preview_height: '自訂預覽高度',
    default_beambox_model: '預設文件設定',
    default_borderless_mode: '開蓋模式預設',
    default_enable_autofocus_module: '自動對焦預設',
    default_enable_diode_module: '二極體雷射預設',
    default_font_family: '預設字體',
    default_font_style: '預設字型',
    default_laser_module: '預設雷射模組',
    default_machine: '預設機器',
    default_machine_button: '無',
    default_units: '預設單位',
    diode_offset: '二極體雷射偏移值',
    diode_one_way_engraving: '二極體雷射單向雕刻',
    diode_two_way_warning: '雙向出光，速度較快時可能導致雕刻時位置誤差，建議預先使用材料進行測試。',
    disabled: '不啟用',
    done: '套用',
    enable_custom_backlash: '啟用自訂背隙補償',
    enable_low_speed: '啟用慢速移動',
    enable_uv_print_file: '啟用 UV 印刷檔案',
    enabled: '啟用',
    engraving_direction: '雕刻方向',
    fast_gradient: '速度優化',
    font_convert: '文字轉路徑',
    font_substitute: '自動替換字體',
    grouped_objects: '多個物件群組',
    groups: {
      ador_modules: 'Ador 模组',
      autosave: '自動儲存',
      camera: '相機',
      connection: '連線',
      editor: '編輯器',
      engraving: '雕刻 (掃描)',
      general: '一般',
      mask: '工作範圍剪裁',
      modules: '擴充模組',
      path: '路徑 (線段)',
      privacy: '隱私權',
      text_to_path: '文字',
      update: '軟體更新',
    },
    guess_poke: '自動搜尋機器 IP',
    guides: '參考線',
    guides_origin: '參考線座標',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/zh-tw/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/zh-tw/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/zh-tw/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/zh-tw/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/zh-tw/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/zh-tw/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/zh-tw/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/zh-tw/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/zh-tw/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/zh-tw/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/zh-tw/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/zh-tw/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/zh-tw/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/zh-tw/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/zh-tw/articles/360004407276',
      uv_print_export: 'https://support.flux3dp.com/hc/zh-tw/articles/12320887477135',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/zh-tw/articles/360004496495',
    },
    high: '高',
    image_downsampling: '點陣圖預覽品質',
    ip: '機器 IP 位址',
    keep_preview_result: '保留預覽結果',
    language: '語言',
    loop_compensation: '封閉路徑補償',
    low: '低',
    low_laser_for_preview: '外框預覽弱出光',
    mask: '工作範圍剪裁',
    medium: '中',
    module_offset_2w_ir: '2W 紅外線雷射偏移',
    module_offset_10w: '10W 二極體雷射偏移',
    module_offset_20w: '20W 二極體雷射偏移',
    module_offset_printer: '列印模組偏移',
    none: '無',
    normal: '正常',
    notification_off: '關閉',
    notification_on: '開啟',
    notifications: '通知',
    off: '關',
    on: '開',
    preview_movement_speed: '相機預覽移動速度',
    printer_advanced_mode: '列印進階模式',
    remove_default_machine_button: '刪除',
    reset: '重置所有設定',
    reset_now: '重置所有設定',
    segmented_engraving: '分段雕刻',
    share_with_flux: '分享 Beam Studio 分析',
    simplify_clipper_path: '路徑計算優化',
    single_object: '單一物件',
    tabs: {
      device: '機器',
      general: '一般',
    },
    text_path_calc_optimization: '路徑計算優化',
    top_down: '由上往下',
    trace_output: '向量化及影像描圖輸出',
    update_beta: 'Beta',
    update_latest: '穩定版',
    vector_speed_constraint: '限制上限速度',
    wrong_ip_format: 'IP格式錯誤',
  },
  social_media: {
    facebook: '與 FLUX 使用者交流、提出問題並學習技巧！',
    instagram: '獲取最新靈感、優惠與贈品！',
    youtube: '查看 Beam Studio 教學與手作靈感。',
  },
  support: {
    no_vcredist: '請安裝 Visual C++ Redistributable 2015<br/>可以在flux3dp.com找到',
    no_webgl: '您的系統不支援 WebGL，建議您使用其他電腦開啟 Beam Studio',
  },
  topbar: {
    alerts: {
      add_content_first: '請先新增物件',
      door_opened: '請將門蓋關閉以進行弱出光。',
      fail_to_connect_with_camera: '#803 與機器相機建立連線時失敗，請重新開啟您的機器或是 Beam Studio ，如果此錯誤持續發生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/360001111355">此則引導</a>。',
      fail_to_get_door_status: '請確保門蓋關閉以進行弱出光。',
      fail_to_start_preview: '#803 啟動相機預覽失敗，請重新開啟您的機器或是 Beam Studio ，如果此錯誤持續發生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/360001111355">此則引導</a>。',
      headtype_mismatch: '偵測到不相符的模組，',
      headtype_none: '未偵測到模組，',
      headtype_unknown: '偵測到未知模組，',
      install_correct_headtype: '請正確安裝10W或20W雷射模組以進行弱出光。',
      job_origin_unavailable: '設定工作起點需要韌體版本 4.3.4 / 5.3.4 或更高版本。您現在要更新韌體嗎？',
      job_origin_warning: '您目前使用「當前位置」作為起點，請確認雷射頭已移至正確位置，以避免碰撞。',
      power_too_high: '功率過高',
      power_too_high_confirm: '知道了',
      power_too_high_msg: '雷射管在高功率（70％ 以上）下耗損較快，使用低功率可以延長雷試管使用壽命。\n輸入「知道了」以繼續。',
      pwm_unavailable: '深度模式需要韌體版本 4.3.4 / 5.3.4 或更高版本。您現在要更新韌體嗎？',
      QcleanScene: '您確定清除場景嗎？<br/>這同時也會刪除您的操作歷史記錄。',
      start_preview_timeout: '#803 啟動相機預覽時超時，請重新開啟您的機器或是 Beam Studio ，如果此錯誤持續發生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/360001111355">此則引導</a>。',
    },
    frame_task: '外框預覽',
    hint: {
      polygon: '按下 + / - 鍵以增加 / 減少邊數。',
    },
    menu: {
      about: '關於 Beam Studio',
      about_beam_studio: '關於 Beam Studio',
      account: '帳號',
      add_new_machine: '新增或設定機器',
      align_center: '置中',
      anti_aliasing: '反鋸齒',
      auto_align: '自動對齊',
      borderless_mode: '開蓋模式',
      bug_report: '錯誤回報',
      calibrate_beambox_camera: '校正相機',
      calibrate_beambox_camera_borderless: '校正相機 (開蓋模組）',
      calibrate_camera_advanced: '校正相機（進階）',
      calibrate_diode_module: '校正二極體雷射模組',
      calibrate_ir_module: '校正紅外線模組',
      calibrate_printer_module: '校正列印模組',
      calibration: '校正',
      camera_calibration_data: '相機校正資料',
      change_logs: '更新日誌',
      clear_scene: '新增檔案',
      close: '關閉視窗',
      commands: '指令',
      contact: '聯絡我們',
      copy: '複製',
      cut: '剪下',
      dashboard: '儀表版',
      decompose_path: '解散非連續路徑',
      delete: '刪除',
      design_market: 'Design Market',
      dev_tool: '偵錯工具',
      disassemble_use: '解散圖檔',
      document_setting: '文件設定',
      document_setting_short: '文件設定',
      download_data: '下載資料',
      download_log: '匯出機器日誌',
      download_log_canceled: '取消日誌下載',
      download_log_error: '不明錯誤發生，請稍候再試一次',
      duplicate: '重製',
      edit: '編輯',
      example_files: '範例檔案',
      export_BVG: 'BVG',
      export_flux_task: 'FLUX 工作',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: '匯出成...',
      export_UV_print: 'UV 印刷 PDF',
      file: '檔案',
      fit_to_window: '配合視窗尺寸',
      follow_us: '追蹤我們',
      forum: '社群論壇',
      group: '群組化',
      help: '說明',
      help_center: '說明中心',
      hide: '隱藏 Beam Studio',
      hideothers: '隱藏其他',
      image_crop: '裁剪',
      image_curve: '曲線',
      image_invert: '色彩反轉',
      image_sharpen: '鋭化',
      image_stamp: '生成印章斜角',
      image_vectorize: '向量化',
      import_acrylic_focus_probe: '壓克力對焦尺 - 3mm',
      import_ador_laser_example: 'Ador 雷射範例',
      import_ador_printing_example_full: 'Ador 印刷範例 - 彩色',
      import_ador_printing_example_single: 'Ador 列印範例 - 單色',
      import_beambox_2_example: 'Beambox II 範例',
      import_beambox_2_focus_probe: 'Beambox II 對焦尺 - 3mm',
      import_hello_beambox: 'Beambox 範例',
      import_hello_beamo: 'beamo 範例',
      import_hexa_example: 'HEXA 範例',
      import_material_printing_test: '材質列印測試',
      import_material_testing_cut: '材質切割測試',
      import_material_testing_engrave: '材質雕刻測試',
      import_material_testing_line: '材質線段測試',
      import_material_testing_old: '材質雕刻測試 - 經典',
      import_material_testing_simple_cut: '材質切割測試 - 簡易',
      import_promark_example: 'Promark 範例',
      import_promark_mopa_20w_color: 'MOPA 20W 色彩測試',
      import_promark_mopa_60w_color: 'MOPA 60W 色彩測試',
      import_promark_mopa_100w_color: 'MOPA 100W 色彩測試',
      keyboard_shortcuts: '快捷鍵',
      layer_color_config: '顏色設定',
      layer_setting: '圖層',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/zh-tw/articles/10003978157455',
      },
      log: {
        camera: 'Camera',
        cloud: 'Cloud',
        discover: 'Discover',
        hardware: 'Hardware',
        network: 'Network',
        player: 'Player',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'USB List',
      },
      machine_info: '機器資訊',
      machines: '機器',
      manage_account: '管理我的帳號',
      material_test: '材質測試',
      minimize: '最小化',
      my_account: '我的帳號',
      network_testing: '檢測網路設定',
      object: '物件',
      offset: '位移複製',
      open: '打開',
      paste: '貼上',
      paste_in_place: '原地貼上',
      path: '路徑',
      photo_edit: '影像',
      preferences: '偏好設定',
      promark_color_test: 'Promark 色彩測試',
      questionnaire: '問卷回饋',
      quit: '離開',
      recent: '最近使用',
      redo: '重做',
      reload_app: '重新整理',
      reset: '重設',
      rotary_setup: '旋轉軸設定',
      rotate: '旋轉',
      samples: '範例',
      save_as: '另存新檔',
      save_scene: '儲存',
      save_to_cloud: '儲存到雲端',
      scale: '縮放',
      service: '服務',
      set_as_default: '設為預設',
      show_gesture_tutorial: '觸控手勢說明',
      show_grids: '顯示格線',
      show_layer_color: '顯示圖層顏色',
      show_rulers: '顯示尺規',
      show_start_tutorial: '顯示新手教學',
      show_ui_intro: '顯示介面介紹',
      sign_in: '登入',
      sign_out: '登出',
      software_update: '軟體更新',
      svg_edit: 'SVG',
      switch_to_beta: '切換至 Beta 版',
      switch_to_latest: '切換至穩定版',
      tools: {
        box_generator: '盒子產生器',
        code_generator: '條碼產生器',
        material_test_generator: '材質測試產生器',
        title: '工具',
      },
      tutorial: 'Delta Family 列印教學',
      undo: '復原',
      ungroup: '解散群組',
      update: '檢查更新',
      update_firmware: '韌體更新',
      upload_data: '上傳資料',
      using_beam_studio_api: '使用 Beam Studio API',
      view: '檢視',
      window: '視窗',
      zoom_in: '放大',
      zoom_out: '縮小',
      zoom_with_window: '自動配合視窗尺寸',
    },
    preview: '相機預覽',
    preview_press_esc_to_stop: '按下 ESC 鍵以停止相機預覽。',
    rename_tab: '重新命名分頁',
    select_machine: '選擇機器',
    tag_names: {
      dxf: 'DXF 物件',
      ellipse: '橢圓',
      g: '群組',
      image: '影像',
      line: '線段',
      multi_select: '多個物件',
      no_selection: '無選取物件',
      pass_through_object: '廣域雕刻物件',
      path: '路徑',
      polygon: '多邊形',
      rect: '矩形',
      svg: 'SVG 物件',
      text: '文字',
      text_path: '路徑文字',
      use: '匯入物件',
    },
    task_preview: '工作預覽',
    titles: {
      settings: '偏好設定',
    },
    untitled: '未命名',
  },
  topmenu: {
    credit: 'Beam Studio 的開發仰賴 <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> 開放原始碼計畫與其他<a target="_blank" href="https://flux3dp.com/credits/">開放原始碼的軟體</a>的協助才得以完成。',
    device: {
      download_log_canceled: '取消日誌下載',
      download_log_error: '不明錯誤發生，請稍候再試一次',
      log: {
        usblist: 'USB 清單',
      },
      network_test: '網路檢測',
    },
    file: {
      all_files: '所有檔案',
      clear_recent: '清除歷史紀錄',
      converting: '轉換成圖片中...',
      fcode_files: 'FLUX Code',
      import: '匯入',
      jpg_files: 'JPG',
      label: '檔案',
      path_not_exit: '此路徑似乎已不存在於電腦中，請確認是否有更改檔案位置。',
      png_files: 'PNG',
      save_fcode: '匯出工作',
      save_jpg: '匯出 JPG',
      save_png: '匯出 PNG',
      save_scene: '匯出場景',
      save_svg: '匯出 SVG',
      scene_files: 'Beam Studio 場景',
      svg_files: 'SVG',
    },
    ok: '確定',
    version: '版本',
  },
  tutorial: {
    ask_retry_calibration: '請問是否重新執行相機校正？',
    camera_calibration_failed: '相機校正失敗',
    gesture: {
      click: '點按以選擇物件。',
      drag: '拖曳以多選物件。',
      hold: '按住一段時間打開選單。',
      pan: '雙指平移讓畫布隨著您的手指方向移動。',
      zoom: '雙指靠攏以縮小，或雙指分開來放大畫布。',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: '尋找機器中',
    needNewInterfaceTutorial: '請問是否需要為您介紹 Beam Studio 的新介面？<br/>（您也可以先跳過，並在之後從「說明」>「顯示介面介紹」開始介紹。）',
    needNewUserTutorial: '請問您是否需要 Beam Studio 的教學？<br/>（您也可以先跳過，並在之後從「說明」>「顯示新手教學」進行教學。）',
    newInterface: {
      add_new_layer: '新增圖層',
      align_controls: '排列控制',
      basic_shapes: '基本幾何形狀',
      camera_preview: '相機預覽',
      drag_to_sort: '拖曳以排序',
      end_alert: '請問您是否確定要結束新介面介紹？',
      flip: '翻轉',
      group_controls: '群組、解散群組',
      layer_controls: '右鍵點擊以呼叫圖層控制功能：\n複製、合併、鎖定、刪除圖層',
      object_actions: '物件操作',
      pen_tool: '鋼筆工具',
      rename_by_double_click: '雙擊以重新命名',
      select_image_text: '選取、圖片、文字工具',
      select_machine: '選擇機器',
      shape_operation: '幾何圖形操作',
      start_work: '開始工作',
      switch_between_layer_panel_and_object_panel: '在圖層面板與物件面板間切換',
    },
    newUser: {
      add_new_layer: '新增圖層',
      adjust_focus: '2. 調整焦距',
      close_cover: '3. 將門蓋關上',
      drag_to_draw: '拖曳以生成圖形',
      draw_a_circle: '畫一個圓',
      draw_a_rect: '畫一個方形',
      end_alert: '請問您是否確定要結束教學？',
      end_preview_mode: '結束相機預覽模式',
      infill: '設定填充',
      please_select_wood_cutting: '請選擇「木板 - 切割」參數。',
      please_select_wood_engraving: '請選擇「木板 - 刻印」參數。',
      preview_the_platform: '預覽工作平台',
      put_wood: '1. 放進木板',
      send_the_file: '送出工作',
      set_preset_wood_cut: '設定參數：木板 - 切割',
      set_preset_wood_engraving: '設定參數：木板 - 刻印',
      switch_to_layer_panel: '切換到圖層面板',
      switch_to_object_panel: '切換至物件面板',
      switch_to_preview_mode: '切換到相機預覽模式',
    },
    next: '下一步',
    retry: '重試',
    set_connection: '連線設定',
    skip: '跳過教學',
    skip_tutorial: '已跳過新手教學，您可以在「說明」>「顯示新手教學」再次啟動新手教學。',
    suggest_calibrate_camera_first: '我們建議使用者在第一次使用機器時校正相機，並在每次使用時將平台對焦，以取得最好的效果。<br/>請問您要現在進行相機校正嗎？<br/>（您也可以先跳過，並在之後從上方選單「機器」>「您的機器名稱」>「校正相機」進行校正。）',
    tutorial_complete: '介紹完畢，開始創作吧！',
    unable_to_find_machine: '無法找到可用於新手教學的機器，是否進行要進行連線設定、重試或是跳過教學？',
    welcome: '歡迎使用',
  },
  update: {
    cannot_reach_internet: '#823 伺服器無法連接<br/>請確認網路連線',
    download: '線上更新',
    firmware: {
      caption: '有新的機器韌體更新',
      confirm: '上傳',
      firmware_too_old_update_by_sdcard: '韌體太舊，請使用 SD 卡更新韌體。',
      force_update_message: '#814 請將您的機器更新至最新的韌體版本。',
      latest_firmware: {
        cant_get_latest: '無法取得最新版本韌體資訊。',
        caption: '韌體更新',
        message: '韌體已經是最新版本',
        still_update: '檔案更新',
      },
      message_pattern_1: '"%s" 有新的韌體更新。',
      message_pattern_2: '%s 韌體 v%s 可使用 - 你的版本為 v%s.',
      too_old_for_web: '你韌體的版本為 v%s。<br/>欲使用線上版的 Beam Studio 請更新至最新韌體。',
      update_fail: '#822 更新失敗',
      update_success: '韌體更新上傳成功',
      upload_file: '韌體上傳',
    },
    install: '下載',
    later: '稍後',
    preparing: '準備中...',
    release_note: '版本訊息:',
    skip: '跳過此版本',
    software: {
      available_switch: 'Beam Studio v%s 現可提供切換，您的版本為 v%s，是否要切換至此此版本？',
      available_update: 'Beam Studio v%s 現可提供下載，您的版本為 v%s，是否要下載更新？',
      caption: 'Beam Studio 有新的軟體更新',
      check_update: '檢查更新',
      checking: '檢查更新中',
      downloading: '正在背景下載中，您可以按確定以繼續您的工作。',
      install_or_not: '已準備好更新，是否重新啟動以套用更新？',
      no: '否',
      no_response: '無法連接到伺服器，請確認您目前的網路狀態。',
      not_found: 'Beam Studio 已是最新版本。',
      switch_or_not: '已準備完成，是否重新啟動以切換？',
      switch_version: '版本切換',
      switch_version_not_found: '無法找到可切換的版本',
      update_for_ador: '目前軟體版本 %s 較舊，請下載最新版 Beam Studio for Ador 。',
      yes: '是',
    },
    update: '更新',
    updating: '更新中...',
    upload: '上傳',
  },
  web_cam: {
    no_device: '無法檢測到相機設備。請重新連接相機，然後重試。',
    no_permission: 'Beam Studio 沒有權限存取相機。請確保在系統設定或瀏覽器設定中已授予 Beam Studio 權限。',
  },
  z_speed_limit_test: {
    alert_before: '在進行曲面雕刻前，系統將先測試物體的負重，以防止因過重導致雕刻過程中失步。',
    alert_failed: '目前物件的重量已超過當前 Z 軸速度的負重上限，請至機器面板降低 Z 軸速度或更換雕刻物件後再重新測試。',
    ignore: '忽略',
    retest: '重新測試',
    testing: '正在執行負重測試...',
  },
};

export default lang;
