import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: '중단',
    cancel: '취소',
    caption: '오류',
    close: '닫기',
    confirm: '확인',
    dont_save: '저장 안 함',
    dont_show_again: '다시 표시 안 함',
    duplicated_preset_name: '프리셋 이름 중복됨.',
    error: 'UH-OH',
    info: 'INFO',
    instruction: '지시',
    learn_more: '자세히 알아보기',
    no: '아니요',
    ok: '확인',
    ok2: '확인',
    oops: '이런...',
    retry: '재시도',
    save: '저장',
    stop: '정지',
    warning: '경고',
    yes: '예',
  },
  auto_fit: {
    artwork_size: '작품 크기',
    error_tip1: '1. 예술 작품이 재료에 올바르게 배치되었습니까?',
    error_tip2: '2. 재료의 윤곽이 인식할 수 있을 만큼 명확합니까?',
    failed_to_find_contour: '자동 맞춤을 위한 윤곽을 찾지 못했습니다',
    learn_more: '튜토리얼 보기.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: '오프셋 X',
    offset_y: '오프셋 Y',
    pattern_size: '패턴 크기',
    position_artwork: '아트워크 위치 지정',
    position_step1: '패턴에 아트워크의 크기와 위치를 조정하세요.',
    position_step2: '"적용"을 클릭하여 이 패턴에 Auto Fit을 적용하세요.',
    preview_first: '먼저 카메라 미리보기를 수행하세요.',
    reset_position: '위치 초기화',
    rotation: '회전',
    select_a_pattern: '패턴 선택',
    selected_artwork: '선택된 아트워크',
    step1: '아트워크를 배치할 패턴을 선택하세요.',
    step2: '패턴을 찾을 수 없는 경우 카메라 미리보기를 다시 실행하고 자동 맞춤을 적용하세요.',
    step3: '그래도 올바른 패턴을 찾지 못하면, 작업물이 명확히 인식 가능하고 작업 영역이 깨끗한지 확인하세요.',
    title: '자동 맞춤',
  },
  barcode_generator: {
    bar_height: '바 높이',
    bar_width: '바 너비',
    barcode: {
      invalid_value: '선택한 형식에 대해 값이 유효하지 않습니다.',
    },
    font: '글꼴',
    font_size: '글꼴 크기',
    hide_text: '텍스트 숨기기',
    invert_color: '색상 반전',
    text_margin: '텍스트 여백',
  },
  beambox: {
    announcement_panel: {
      title: '공지사항',
    },
    banner: {
      auto_feeder: '자동 공급 모드',
      camera_preview: '미리보기',
      camera_preview_borderless_mode: '(하단 개방)',
      curve_engraving: '곡선 조각 모드',
      pass_through: '패스스루 모드',
      rotary: '회전 모드',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: '텍스트를 패스로 변환...',
      export_file_error_ask_for_upload: '작업 내보내기 실패. 버그 보고를 위해 작업 중인 씬을 개발팀에 제공할 의사가 있으신가요?',
      retreive_image_data: '이미지 데이터 검색...',
    },
    context_menu: {
      copy: '복사',
      cut: '잘라내기',
      delete: '삭제',
      duplicate: '복제',
      group: '그룹화',
      move_back: '맨 아래로 보내기',
      move_down: '뒤로 보내기',
      move_front: '맨 위로 가져오기',
      move_up: '앞으로 가져오기',
      paste: '붙여넣기',
      paste_in_place: '원위치에 붙여넣기',
      ungroup: '그룹 해제',
    },
    document_panel: {
      add_on: '애드온',
      auto_feeder: '자동 공급 장치',
      auto_feeder_scale: '자동 공급기 스케일',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: '열린 바닥',
      current_position: '현재 위치',
      disable: '비활성화',
      document_settings: '문서 설정',
      enable: '사용',
      enable_autofocus: '오토포커스',
      enable_diode: '다이오드 레이저',
      engrave_dpi: '해상도',
      frame_before_start: '실행 전에 프레임',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: '높음',
      job_origin: '작업 원점',
      laser_source: '레이저 소스',
      low: '낮음',
      machine: '기계',
      medium: '보통',
      notification: {
        changeFromPrintingWorkareaTitle: '인쇄 레이어를 레이저 레이어로 변환하시겠습니까?',
      },
      origin: '원점',
      pass_through: '패스스루',
      pass_through_height_desc: '작업 영역을 확장하기 위해 객체의 길이를 입력하십시오.',
      rotary_mode: '로터리',
      scale: '스케일',
      start_from: '에서 시작',
      start_position: '시작 위치',
      start_work_button: '실행 버튼',
      ultra: '최고',
      workarea: '작업 영역',
    },
    image_trace_panel: {
      back: '뒤로',
      brightness: '밝기',
      cancel: '취소',
      contrast: '대조',
      next: '다음',
      okay: '확인',
      threshold: '임계값',
      tuning: '매개변수',
    },
    layer_color_config_panel: {
      add: '추가',
      add_config: '색상 추가',
      color: '색깔',
      default: '기본값으로 재설정',
      in_use: '이 색상을 사용 중입니다.',
      layer_color_config: '레이어 색상 구성',
      no_input: '유효한 16진수 색상 코드를 입력하십시오.',
      power: '파워',
      repeat: '실행',
      speed: '속도',
      sure_to_delete: '이 색상 설정을 삭제하시겠습니까?',
      sure_to_reset: '모든 사용자 정의 매개변수가 삭제됩니다. 기본값으로 재설정하시겠습니까?',
    },
    left_panel: {
      borderless_blind_area: '비조각 영역',
      borderless_preview: '열린 바닥 모드 카메라 미리보기',
      diode_blind_area: 'Hybrid Laser 추가 기능 블라인드 영역',
      ellipse: '타원',
      image: '이미지',
      label: {
        adjust_height: '높이 조절',
        array: '어레이',
        boxgen: 'Boxgen',
        choose_camera: '카메라',
        clear_preview: '미리보기 지우기',
        cursor: '선택',
        curve_engraving: {
          clear_area: '선택 영역 지우기',
          exit: '종료',
          preview_3d_curve: '곡선 조각 미리보기',
          select_area: '영역 선택',
          title: '곡선 조각',
        },
        end_preview: '미리보기 종료',
        line: '선',
        live_feed: '라이브 피드',
        my_cloud: '내 클라우드',
        oval: '타원',
        pass_through: '패스스루',
        pen: '펜',
        photo: '이미지',
        polygon: '다각형',
        preview: '카메라 미리보기',
        qr_code: 'QR 코드',
        rect: '사각형',
        shapes: '요소들',
        text: '텍스트',
        trace: '이미지 추적',
      },
      line: '선',
      rectangle: '사각형',
      text: '텍스트',
      unpreviewable_area: '블라인드 영역',
    },
    network_testing_panel: {
      average_response: '평균 응답 시간',
      cannot_connect_1: '#840 대상 IP에 연결하지 못했습니다.',
      cannot_connect_2: '#840 대상이 동일한 네트워크에 있는지 확인하십시오.',
      cannot_get_local: '로컬 IP 주소에 액세스할 수 없습니다.',
      connection_quality: '연결 품질',
      device_not_on_list: '#842 기계가 목록에 없지만 연결 품질은 >70이고 평균 응답 시간은 <100ms입니다.',
      empty_ip: '#818 대상 장치 IP 주소를 입력하세요.',
      end: '종료',
      fail_to_start_network_test: '#817 네트워크 테스트 시작 실패.',
      hint_connect_camera_timeout: '카메라 미리 보기 시작 시 타임아웃이 발생하나요?',
      hint_connect_failed_when_sending_job: '작업을 보낼 때 연결할 수 없나요?',
      hint_device_often_on_list: '기계가 목록에서 자주 찾을 수 없나요?',
      insert_ip: '대상 장치 IP 주소:',
      invalid_ip: '#818 잘못된 IP 주소입니다.',
      ip_startswith_169: '#843 기계 IP 주소가 169.254로 시작합니다.',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: '이 오류는 일반적으로 권한이 충분하지 않은 경우 발생합니다. 터미널에서 "sudo beam-studio --no-sandbox" 를 실행하여 권한을 얻고 네트워크 테스트를 수행하십시오.',
      local_ip: '로컬 IP 주소:',
      network_testing: '네트워크 테스트',
      network_unhealthy: '#841 연결 품질 <70 또는 평균 응답 시간> 100ms',
      start: '시작',
      test_completed: '테스트 완료',
      test_fail: '테스트 실패',
      testing: '네트워크 테스트 중...',
    },
    object_panels: {
      lock_desc: '너비와 높이의 비율 유지하기 (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: '문자열을 패스로 변환하는 동안 일부 문자열이 다른 글꼴로 변경되어 일부 문자가 제대로 변환되지 않을 수 있습니다. 작업을 보내기 전 미리보기 이미지를 다시 확인해주세요.',
        error_when_parsing_text: '텍스트를 패스로 변환하는 동안 오류가 발생했습니다. 오류 메시지',
        font_substitute_pop: '귀하의 텍스트에 현재 글꼴에서 지원하지 않는 문자가 포함되어 있습니다. <br/>대신 <strong>%s</strong>을(를) 사용하시겠습니까?',
        retry: '다시 시도하거나 다른 글꼴을 선택하세요',
        use_current_font: '현재 글꼴 사용',
      },
      wait_for_parsing_font: '글꼴 분석 중... 잠시만 기다려주세요.',
    },
    path_preview: {
      current_position: '현재 위치',
      cut_distance: '잘라내기 거리',
      cut_time: '잘라내기 시간',
      end_preview: '미리 보기 종료',
      estimated_time: '전체 예상 시간',
      invert: '반전',
      pause: '일시 중지',
      play: '재생',
      play_speed: '재생 속도',
      preview_info: '미리 보기 정보',
      rapid_distance: '이동 거리',
      rapid_time: '이동 시간',
      remark: '* 모든 정보는 참고용으로 추정된 값입니다.',
      size: '크기',
      start_here: '여기서 시작',
      stop: '정지',
      travel_path: '이동 경로',
    },
    photo_edit_panel: {
      aspect_ratio: '종횡비',
      brightness: '밝기',
      brightness_and_contrast: '밝기 / 명암',
      cancel: '취소',
      circumference: '둘레',
      compare: '비교',
      contrast: '명암 대비',
      crop: '자르기',
      curve: '곡선',
      diameter: '직경',
      free: '임의',
      invert: '색상 반전',
      okay: '확인',
      original: '원본 크기',
      phote_edit: '사진 편집',
      processing: '처리중',
      radius: '반경',
      rotary_warped: '회전 왜곡',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: '선명하게',
      sharpness: '선명도',
      start: '시작',
      warp: '왜곡',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: '가다',
        insufficient_credit: '크레딧이 부족합니다',
        insufficient_credit_msg: '%s 사용할 수 없습니다. 회원센터로 이동하여 AI 크레딧을 구매하세요.',
        relogin_to_use: '이 기능을 사용하려면 다시 로그인하세요.',
      },
      auto_feeder_origin: '프레이밍 미리보기를 사용하여 레이저 헤드의 경로와 영역을 확인한 후, 결과를 기반으로 재료 배치 또는 조각 시작 위치를 조정하여 조각 과정에서 충돌이 발생하지 않도록 하세요.',
      auto_switch_tab: {
        message: '레이어 패널과 객체 패널 간 자동 전환 옵션이 기본 설정에 추가되었습니다. 이 옵션은 기본적으로 비활성화되어 있습니다. 지금 자동 전환을 활성화하시겠습니까? <br/>이 설정은 언제든지 기본 설정에서 변경할 수 있습니다.',
        title: '레이어 및 오브젝트 패널 자동 전환',
      },
      backend_connect_failed_ask_to_upload: '#802 백엔드에 연결하려는 중 오류가 계속 발생합니다. 버그 리포트 로그를 업로드 하시겠습니까?',
      backend_error_hint: '백엔드 오류로 인해 기능이 제대로 작동하지 않을 수 있습니다.',
      both_power_and_speed_too_high: '낮은 레이저 파워를 사용하면 레이저 튜브의 수명이 연장됩니다. 또한, 이 해상도에서 너무 높은 속도를 사용하면 그림자 조각에 대한 품질이 낮아질 수 있습니다.',
      bug_report: '버그 리포트',
      change_workarea_before_preview: '작업 영역(%s)이 현재 설정된 작업 영역과 일치하지 않습니다. 현재 작업 영역을 전환하시겠습니까?',
      convert_to_path_fail: '경로로 변환 실패.',
      dxf_bounding_box_size_over: '도면 크기가 작업 영역을 벗어났습니다. CAD 소프트웨어에서 도면을 원점에 가깝게 이동하거나, 단위가 올바르게 설정되었는지 확인하세요.',
      dxf_version_waring: '이 DXF 파일의 버전이 2013이 아닌 경우 호환성 문제가 있을 수 있습니다.',
      facebook_group_invitation: {
        already_joined: '이미 가입',
        join_now: '카운트 인',
        later: '어쩌면 나중에',
        message: '공식 Facebook 그룹에 가입하여 다른 FLUX 레이저 사용자들과 교류하고, FLUX 레이저에 대해 토론하고, 레이저 아트웍을 공유하고, 제품에 대한 최신 업데이트를 받아보세요. 여러분을 빨리 만나고 싶습니다!',
        title: 'FLUX 공식 사용자 그룹 가입',
      },
      import_file_contain_invalid_path: '#808 가져온 SVG 파일에는 잘못된 이미지 경로가 포함되어 있습니다. 모든 이미지 파일이 존재하거나 이미지를 파일에 포함시켰는지 확인해주세요.',
      import_file_error_ask_for_upload: 'SVG 파일을 가져오는 데 실패했습니다. 버그 보고를 위해 개발팀에 파일을 제공하시겠습니까?',
      layer_by_color: '색상',
      layer_by_layer: '레이어',
      loading_image: '이미지를 로드 중입니다. 잠시 기다려주세요...',
      more_than_two_object: '오브젝트 수가 너무 많습니다. 2개의 오브젝트만 지원됩니다.',
      mouse: '마우스',
      no_support_text: 'Beam Studio는 현재 텍스트 태그를 지원하지 않습니다. 가져오기 전에 텍스트를 경로로 변환해주세요.',
      nolayer: '단일 레이어',
      not_support_object_type: '오브젝트 유형이 지원되지 않습니다.',
      or_turn_off_borderless_mode: '혹은 Open Bottom 모드를 끄세요.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 오류: pdf2svg 명령어를 찾을 수 없습니다. 패키지 매니저를 이용하여 pdf2svg를 설치해주세요. (예: "yum install pdf2svg" 또는 "apt-get install pdf2svg")',
        error_when_converting_pdf: '#824 PDF를 SVG로 변환하는 중 오류가 발생했습니다.',
      },
      progress: {
        calculating: '계산 중',
        uploading: '업로드 중',
      },
      questionnaire: {
        caption: '설문조사 작성에 도움을 주세요.',
        message: 'Beam Studio를 더욱 개선하기 위해 설문조사 작성에 도움을 주세요.',
        no_questionnaire_available: '현재 이용 가능한 설문조사가 없습니다.',
        unable_to_get_url: '인터넷 연결을 확인해주세요. 설문조사 링크를 가져올 수 없습니다.',
      },
      recommend_downgrade_software: '오래된 펌웨어 버전이 감지되었습니다. 우리는 현재 호환성 문제를 적극적으로 해결하고 있지만 현재로서는 <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>로 되돌아가는 것을 권장합니다.',
      recommend_upgrade_firmware: '더 오래된 펌웨어 버전을 감지했습니다. 우리는 활발하게 호환성 문제를 다루고 있지만 지금 당장은 최신 펌웨어로 업데이트하는 것을 권장합니다.',
      save_unsave_changed: '저장하지 않은 변경 사항을 저장하시겠습니까?',
      select_at_least_two: '진행하려면 두 개의 오브젝트를 선택해주세요.',
      select_first: '먼저 오브젝트를 선택해주세요.',
      select_import_method: '레이어링 스타일 선택:',
      select_import_module: '모듈 선택:',
      sentry: {
        message: '오류 발생 시 관련 정보를 개발팀에 자동으로 업로드 하시겠습니까?',
        title: '함께 Beam Studio를 개선해보아요.',
      },
      should_update_firmware_to_continue: '#814 당신의 펌웨어는 이 버전의 Beam Studio를 지원하지 않습니다. 펌웨어를 업데이트해주세요. (메뉴 > 기계 > [당신의 기계] > 펌웨어 업데이트)',
      speed_too_high_lower_the_quality: '이 해상도에서 너무 높은 속도를 사용하면 그림자 조각에 대한 품질이 낮아질 수 있습니다.',
      still_continue: '계속',
      successfully_uploaded: '파일 업로드가 성공했습니다.',
      svg_1_1_waring: '이 SVG 파일의 버전은 v 1.1이며, 호환성 문제가 있을 수 있습니다.',
      svg_image_path_waring: '이 SVG 파일은 파일 경로에서 <image>를 로딩하고 있습니다. 이로 인해 로딩 실패가 발생할 수 있습니다. 이러한 위험을 피하기 위해 SVG 내장 이미지를 사용해 주세요.',
      text_to_path: {
        caption: '텍스트-경로 변환기 2.0',
        message: "Beam Studio에 새로운 텍스트-경로 변환기(Converter 2.0)가 추가되었습니다. 이는 더 신뢰할 수 있는 결과를 제공합니다! 지금 전환하시겠습니까?\\n환경 설정의 '텍스트-경로 변환기' 섹션에서 나중에 이 설정을 조정할 수도 있습니다.",
      },
      too_fast_for_auto_feeder: '패스 객체가 포함된 레이어에서 너무 높은 속도를 사용하면 절단 정밀도가 낮아질 수 있습니다.\n자동 공급 장치 로 절단할 때는 %(limit)s 이상의 속도를 사용하지 않는 것이 좋습니다.',
      too_fast_for_auto_feeder_and_constrain: '다음 레이어: %(layers)s \n에는 벡터 경로 객체가 포함되어 있으며 속도가 %(limit)s를 초과합니다.\n자동 공급 장치 로 벡터 경로 객체의 절단 속도는 %(limit)s로 제한됩니다.\n이 제한은 환경 설정에서 제거할 수 있습니다.',
      too_fast_for_curve: '곡선 조각 개체가 포함된 레이어에서 너무 높은 속도를 사용하면 조각 정밀도가 저하될 수 있습니다.\n최적의 결과를 위해 속도를 %(limit)s 이하로 유지하는 것이 좋습니다.',
      too_fast_for_curve_and_constrain: '다음 레이어: %(layers)s는 속도 제한 %(limit)s을 초과합니다.\n곡선 개체의 조각 속도가 %(limit)s로 제한됩니다. 환경 설정에서 이 제한을 제거할 수 있습니다.',
      too_fast_for_path: '경로 객체를 포함한 레이어에서 너무 높은 속도를 사용하면 절단 정밀도가 낮아질 수 있습니다. 절단할 때 %(limit)s보다 빠른 속도를 사용하지 않는 것이 좋습니다.',
      too_fast_for_path_and_constrain: '다음 레이어: %(layers)s \n벡터 경로 객체를 포함하고, 속도가 %(limit)s를 초과합니다. 벡터 경로 객체의 절단 속도는 %(limit)s로 제한됩니다. 이 제한을 기본 설정에서 제거할 수 있습니다.',
      touchpad: '터치패드',
      ungroup_use: '이 작업은 가져온 DXF 또는 SVG를 언그룹화합니다. 파일이 많은 요소를 포함하고 있기 때문에 시간이 걸릴 수 있습니다. 계속 진행하시겠습니까?',
      upload_failed: '#819 파일 업로드 실패.',
      upload_file_too_large: '#819 파일이 업로드하기에 너무 큽니다.',
      vectorize_shading_image: '그라데이션 이미지는 추적하는 데 시간이 더 오래 걸리며, 잡음이 발생하기 쉽습니다. 실행하기 전에 이미지 그라데이션을 꺼주세요.',
    },
    rating_panel: {
      description: 'Beam Studio를 좋아하신다면 평가해 주시면 감사하겠습니다.',
      thank_you: '감사합니다!',
      title: 'Beam Studio를 즐기시겠습니까?',
    },
    right_panel: {
      laser_panel: {
        advanced: '고급',
        backlash: '백래시',
        bi_directional: '양방향',
        by: '', // keep empty on purpose
        ce_z_high_speed: '고속 Z 축',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: '컬러 버전 조정',
        color_adjustment_short: '컬러 버전',
        color_strength: '강도',
        cross_hatch: '교차 해칭',
        custom_preset: '사용자 정의',
        cut: '절단',
        diode: '다이오드 레이저',
        do_not_adjust_default_para: '프리셋은 조정할 수 없습니다.',
        dottingTime: '타점 시간',
        dropdown: {
          inches: {
            abs_engraving: 'ABS 플라스틱 - 각인',
            acrylic_3mm_cutting: '아크릴 - 1/8″ 절단',
            acrylic_5mm_cutting: '아크릴 - 3/16″ 절단',
            acrylic_8mm_cutting: '아크릴 - 1/4″ 절단',
            acrylic_10mm_cutting: '아크릴 - 3/8″ 절단',
            acrylic_engraving: '아크릴 - 각인',
            acrylic_printing: '아크릴 - 인쇄',
            aluminum_engraving: '알루미늄 - 각인',
            aluminum_light: '알루미늄 (밝은)',
            bamboo_printing: '대나무 - 인쇄',
            black_abs: '검은 ABS',
            black_acrylic_3mm_cutting: '검정 아크릴 - 1/8″ 절단',
            black_acrylic_5mm_cutting: '검정 아크릴 - 3/16″ 절단',
            black_acrylic_engraving: '검정 아크릴 - 각인',
            brass_dark: '황동 (어두운)',
            brass_engraving: '황동 - 각인',
            brass_light: '황동 (밝은)',
            canvas_printing: '캔버스 - 인쇄',
            cardstock_printing: '카드지 - 인쇄',
            copper: '구리',
            cork_printing: '코르크 - 인쇄',
            denim_1mm_cutting: '청 - 1/32″ 절단',
            fabric_3mm_cutting: '섬유 - 1/8″ 절단',
            fabric_5mm_cutting: '섬유 - 3/16″ 절단',
            fabric_engraving: '섬유 - 각인',
            fabric_printing: '패브릭 - 인쇄',
            flat_stone_printing: '평평한 돌 - 인쇄',
            glass_bw_engraving: '유리 - 각인',
            glass_printing: '유리 - 인쇄',
            gloss_leather_printing: '광택 가죽 - 인쇄',
            gold_engraving: '금 - 각인',
            iron_engraving: '철 - 각인',
            leather_3mm_cutting: '가죽 - 1/8″ 절단',
            leather_5mm_cutting: '가죽 - 3/16″ 절단',
            leather_engraving: '가죽 - 각인',
            mdf_3mm_cutting: '뉴질랜드 MDF - 1/8″ 절단',
            mdf_5mm_cutting: '뉴질랜드 MDF - 3/16″ 절단',
            mdf_engraving: '뉴질랜드 MDF - 각인',
            metal_bw_engraving: '금속 - 각인',
            opaque_acrylic: '불투명 아크릴',
            pc_printing: 'PC - 인쇄',
            rubber_bw_engraving: '고무 - 각인',
            silver_engraving: '은 - 각인',
            stainless_steel_bw_engraving_diode: '금속 - 각인 (다이오드 레이저)',
            stainless_steel_dark: '스테인리스강 (어두운)',
            stainless_steel_engraving: '스테인리스 스틸 - 각인',
            stainless_steel_light: '스테인리스강 (밝은)',
            stainless_steel_printing: '스테인리스 스틸 - 인쇄',
            steel_engraving_spray_engraving: '금속 - 각인',
            stone: '돌',
            ti_engraving: '티타늄 - 각인',
            titanium_dark: '티타늄 (어두운)',
            titanium_light: '티타늄 (밝은)',
            white_abs: '흰색 ABS',
            wood_3mm_cutting: '목재 - 1/8″ 절단',
            wood_5mm_cutting: '목재 - 3/16″ 절단',
            wood_7mm_cutting: '목재 - 1/4″ 절단',
            wood_8mm_cutting: '목재 - 1/4″ 절단',
            wood_10mm_cutting: '목재 - 3/8″ 절단',
            wood_engraving: '목재 - 각인',
            wood_printing: '목재 - 인쇄',
          },
          mm: {
            abs_engraving: 'ABS 플라스틱 - 각인',
            acrylic_3mm_cutting: '아크릴 - 3mm 절단',
            acrylic_5mm_cutting: '아크릴 - 5mm 절단',
            acrylic_8mm_cutting: '아크릴 - 8mm 절단',
            acrylic_10mm_cutting: '아크릴 - 10mm 절단',
            acrylic_engraving: '아크릴 - 각인',
            acrylic_printing: '아크릴 - 인쇄',
            aluminum_engraving: '알루미늄 - 각인',
            aluminum_light: '알루미늄 (밝은)',
            bamboo_printing: '대나무 - 인쇄',
            black_abs: '검은 ABS',
            black_acrylic_3mm_cutting: '검정 아크릴 - 3mm 절단',
            black_acrylic_5mm_cutting: '검정 아크릴 - 5mm 절단',
            black_acrylic_engraving: '검정 아크릴 - 각인',
            brass_dark: '황동 (어두운)',
            brass_engraving: '황동 - 각인',
            brass_light: '황동 (밝은)',
            canvas_printing: '캔버스 - 인쇄',
            cardstock_printing: '카드지 - 인쇄',
            copper: '구리',
            cork_printing: '코르크 - 인쇄',
            denim_1mm_cutting: '청 - 1mm 절단',
            fabric_3mm_cutting: '섬유 - 3mm 절단',
            fabric_5mm_cutting: '섬유 - 5mm 절단',
            fabric_engraving: '섬유 - 각인',
            fabric_printing: '패브릭 - 인쇄',
            flat_stone_printing: '평평한 돌 - 인쇄',
            glass_bw_engraving: '유리 - 각인',
            glass_printing: '유리 - 인쇄',
            gloss_leather_printing: '광택 가죽 - 인쇄',
            gold_engraving: '금 - 각인',
            iron_engraving: '철 - 각인',
            leather_3mm_cutting: '가죽 - 3mm 절단',
            leather_5mm_cutting: '가죽 - 5mm 절단',
            leather_engraving: '가죽 - 각인',
            mdf_3mm_cutting: '뉴질랜드 MDF - 3mm 절단',
            mdf_5mm_cutting: '뉴질랜드 MDF - 5mm 절단',
            mdf_engraving: '뉴질랜드 MDF - 각인',
            metal_bw_engraving: '금속 - 각인',
            opaque_acrylic: '불투명 아크릴',
            pc_printing: 'PC - 인쇄',
            rubber_bw_engraving: '고무 - 각인',
            silver_engraving: '은 - 각인',
            stainless_steel_bw_engraving_diode: '금속 - 각인 (다이오드 레이저)',
            stainless_steel_dark: '스테인리스강 (어두운)',
            stainless_steel_engraving: '스테인리스 스틸 - 각인',
            stainless_steel_light: '스테인리스강 (밝은)',
            stainless_steel_printing: '스테인리스 스틸 - 인쇄',
            steel_engraving_spray_engraving: '금속 - 각인',
            stone: '돌',
            ti_engraving: '티타늄 - 각인',
            titanium_dark: '티타늄 (어두운)',
            titanium_light: '티타늄 (밝은)',
            white_abs: '흰색 ABS',
            wood_3mm_cutting: '목재 - 3mm 절단',
            wood_5mm_cutting: '목재 - 5mm 절단',
            wood_7mm_cutting: '목재 - 7mm 절단',
            wood_8mm_cutting: '목재 - 8mm 절단',
            wood_10mm_cutting: '목재 - 10mm 절단',
            wood_engraving: '목재 - 각인',
            wood_printing: '목재 - 인쇄',
          },
          parameters: '프리셋',
          save: '현재 파라미터 추가',
        },
        engrave: '각인',
        existing_name: '이름이 이미 사용되었습니다.',
        fill_angle: '채우기 각도',
        fill_interval: '채우기 간격',
        fill_setting: '채우기 설정',
        filled_path_only: '채우기 경로에만 해당',
        focus_adjustment: '초점 조절',
        frequency: '주파수',
        gradient_only: '그라데이션 이미지에만 해당',
        halftone: '반톤',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: '오브젝트 높이',
        ink_saturation: '포화',
        ink_type: {
          normal: '일반 잉크',
          text: '잉크 종류',
          UV: 'UV 잉크',
        },
        laser_speed: {
          fast: '빠름',
          max: 300,
          min: 3,
          slow: '느림',
          step: 0.1,
          text: '속도',
          unit: 'mm/s',
        },
        low_power_warning: '10% 이하의 레이저 파워는 레이저 빛을 내지 못할 수 있습니다.',
        low_speed_warning: '속도가 낮으면 재료가 타버릴 수 있습니다.',
        lower_focus: '초점 낮추기',
        lower_focus_desc: '절단 성능을 향상시키기 위해 초점을 맞춘 후 특정 거리만큼 초점 높이를 낮춥니다.',
        module: '모듈',
        more: '관리',
        multi_layer: '다중 레이어',
        para_in_use: '이 매개 변수가 사용 중입니다.',
        parameters: '파라미터',
        power: {
          high: '높음',
          low: '낮음',
          max: 100,
          min: 1,
          step: 0.1,
          text: '전원',
        },
        preset_management: {
          add_new: '새로 추가',
          delete: '삭제',
          export: '내보내기',
          export_preset_title: '프리셋 내보내기',
          import: '가져오기',
          laser: '레이저',
          lower_focus_by: '초점을 낮추기',
          new_preset_name: '새 프리셋 이름',
          preset: '사전 설정',
          print: '인쇄',
          reset: '리셋',
          save_and_exit: '저장하고 종료',
          show_all: '모두 보기',
          sure_to_import_presets: '이 작업은 프리셋 배열을 로드하고 사용자 정의 매개변수를 덮어씁니다. 계속 진행하시겠습니까?',
          sure_to_reset: '이렇게 하면 사용자 정의 매개변수가 삭제되고 모든 프리셋이 재설정됩니다. 계속 진행하시겠습니까?',
          title: '매개변수 관리',
          wobble_diameter: '웨이블 직경',
          wobble_step: '웨이블 단계 크기',
        },
        preset_setting: '파라미터 설정 (%s)',
        presets: '프리셋',
        print_multipass: '다중 인쇄',
        promark_speed_desc: '속도 매개변수는 그라데이션 이미지에 적용되지 않습니다.',
        pulse_width: '펄스 폭',
        pwm_advanced_desc: '깊이 모드를 위한 최소 전력을 설정하세요.',
        pwm_advanced_hint: '이 설정은 깊이 모드를 사용할 때 그라데이션 이미지에 특히 적용됩니다.',
        pwm_advanced_setting: '깊이 모드 전력 설정',
        repeat: '통과 횟수',
        single_color: '단일 색상',
        single_color_desc: '전체 색상 레이어에만 적용되며, 확장된 단일 색상 레이어와는 사용할 수 없습니다.',
        slider: {
          fast: '빠름',
          high: '높음',
          low: '낮음',
          regular: '표준',
          slow: '느림',
          very_fast: '가장 빠름',
          very_high: '최대',
          very_low: '최소',
          very_slow: '가장 느림',
        },
        speed: '스피드',
        speed_constrain_warning: '벡터 경로 객체의 절단 속도는 %(limit)s 로 제한됩니다. 환경설정에서 이 제한을 삭제할 수 있습니다.',
        speed_constrain_warning_auto_feeder: '자동 공급 장치 객체의 절단 속도는 %(limit)s로 제한됩니다. 이 제한은 환경 설정에서 제거할 수 있습니다.',
        speed_constrain_warning_curve_engraving: '곡선 조각 개체의 속도가 %(limit)s로 제한됩니다. 환경 설정에서 이 제한을 제거할 수 있습니다.',
        stepwise_focusing: '단계별 초점 맞추기',
        stepwise_focusing_desc: '각 통과 횟수 동안 물체의 높이에 따라 초점 거리를 점진적으로 낮춥니다.',
        strength: '파워',
        times: '회',
        various_preset: '다양한 프리셋',
        white_ink: '화이트 잉크',
        white_ink_settings: '화이트 잉크 설정',
        wobble: '흔들림',
        wobble_desc: '경로를 따라 작은 원을 만들어 절단 작업에 유리합니다. (경로 객체에만 해당됩니다.)',
        wobble_diameter: '직경',
        wobble_step: '단계 크기',
        z_step: 'Z 스텝',
      },
      layer_panel: {
        current_layer: '현재 레이어',
        layer1: '레이어 1',
        layer_bitmap: '비트맵',
        layer_cutting: '절단',
        layer_engraving: '조각',
        layers: {
          del: '레이어 삭제',
          dupe: '레이어 복제',
          fullColor: '풀 컬러',
          layer: '레이어',
          layers: '레이어들',
          lock: '레이어 잠금',
          merge_all: '모두 병합',
          merge_down: '아래로 병합',
          merge_selected: '선택한 레이어 병합',
          move_down: '아래 레이어로 이동',
          move_elems_to: '요소 이동:',
          move_selected: '선택한 요소를 다른 레이어로 이동하시겠습니까?',
          move_up: '위 레이어로 이동',
          new: '새 레이어',
          rename: '레이어 이름 바꾸기',
          splitFullColor: '레이어 확장',
          switchToFullColor: '전체 색상 레이어로 전환',
          switchToSingleColor: '단일 색상 레이어로 전환',
          unlock: '잠금 해제',
        },
        move_elems_to: '요소 이동:',
        notification: {
          dupeLayerName: '해당 이름의 레이어가 이미 존재합니다!',
          enterNewLayerName: '새 레이어 이름을 입력하세요.',
          enterUniqueLayerName: '고유한 레이어 이름을 입력하세요.',
          layerHasThatName: '해당 이름의 레이어가 이미 존재합니다.',
          mergeLaserLayerToPrintingLayerMsg: '이 프로시저를 완료하면 레이저 레이어의 설정이 삭제되고 현재 레이어에 맞게 설정됩니다.',
          mergeLaserLayerToPrintingLayerTitle: '레이어를 하나의 인쇄 레이어로 병합하시겠습니까?',
          mergePrintingLayerToLaserLayerMsg: '이 프로시저를 완료하면 인쇄 레이어의 색상 설정이 삭제되고 현재 레이어에 맞게 설정됩니다.',
          mergePrintingLayerToLaserLayerTitle: '이 레이어를 하나의 레이저 레이어로 병합하시겠습니까?',
          moveElemFromPrintingLayerMsg: '이 작업을 완료하면 선택한 요소의 색상 설정이 제거되어 %s에 따라 설정됩니다.',
          moveElemFromPrintingLayerTitle: '선택한 요소를 %s 로 이동하고 레이저 요소로 변환하시겠습니까?',
          moveElemToPrintingLayerMsg: '이 작업을 완료하면 선택한 요소의 설정이 제거되어 %s 에 따라 설정됩니다.',
          moveElemToPrintingLayerTitle: '선택한 요소를 %s로 이동하고 인쇄 요소로 변환하시겠습니까?',
          newName: '새 이름',
          QmoveElemsToLayer: "선택한 요소를 '%s' 레이어로 이동하시겠습니까?",
          splitColorMsg: '이 작업을 계속하면 원래의 색상 레이어로 돌아갈 수 없게 됩니다.',
          splitColorTitle: '선택한 레이어를 CMYK 레이어로 확장하시겠습니까?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: '인공 지능 배경 제거',
          ai_bg_removal_reminder: '버튼을 누르면 즉시 0.2 크레딧이 사용됩니다. 계속하시겠습니까?',
          ai_bg_removal_short: '배경 제거',
          array: '어레이',
          auto_fit: '자동 맞춤',
          bevel: '경계선 처리',
          brightness: '밝기',
          convert_to_path: '경로로 변환',
          create_textpath: '경로상 텍스트 만들기',
          create_textpath_short: '경로 텍스트',
          crop: '자르기',
          decompose_path: '분해',
          detach_path: '경로 상의 텍스트 분해',
          detach_path_short: '분해 ',
          disassemble_use: '해체',
          disassembling: '분해 중...',
          edit_path: '경로 편집',
          fetching_web_font: '온라인 글꼴 가져 오는 중...',
          grading: '그레이딩',
          invert: '반전',
          offset: '오프셋',
          outline: '윤곽 그리기',
          replace_with: '다른 것으로 바꾸기...',
          replace_with_short: '대체',
          sharpen: '선명하게',
          simplify: '단순화',
          smart_nest: '스마트 네스트',
          trace: '추적하기',
          ungrouping: '그룹해제...',
          uploading_font_to_machine: '기계로 글꼴 업로드 중...',
          wait_for_parsing_font: '글꼴 구문 분석 중... 잠시만 기다려주세요.',
          weld_text: '텍스트 용접',
        },
        align: '정렬',
        boolean: '부울',
        bottom_align: '하단 맞춤',
        center_align: '가운데 맞춤',
        difference: '차이',
        distribute: '분배',
        flip: '뒤집기',
        group: '그룹화',
        hdist: '수평 분배',
        hflip: '수평 반전',
        intersect: '교차',
        left_align: '왼쪽 맞춤',
        lock_aspect: '비율 고정',
        middle_align: '중앙 맞춤',
        option_panel: {
          color: '색깔',
          fill: '내부 채우기',
          font_family: '글꼴',
          font_size: '크기',
          font_style: '스타일',
          letter_spacing: '자간',
          line_spacing: '줄 간격',
          path_infill: '경로 내부 채우기',
          pwm_engraving: '깊이 모드',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: '둥근 모서리',
          shading: '그라데이션',
          sides: '면의 수',
          start_offset: '텍스트 오프셋',
          stroke: '액자',
          stroke_color: '테두리 색상',
          stroke_width: '테두리 너비',
          text_infill: '텍스트 내부 채우기',
          threshold: '임계 밝기',
          threshold_short: '임계값',
          vertical_align: '정렬',
          vertical_text: '수직 텍스트',
        },
        path_edit_panel: {
          connect: '연결',
          delete: '삭제',
          disconnect: '연결 해제',
          node_type: '노드 타입',
          round: '둥근',
          sharp: '날카로운',
        },
        right_align: '오른쪽 맞춤',
        subtract: '빼기',
        top_align: '상단 맞춤',
        ungroup: '그룹 해제',
        union: '합치기',
        unlock_aspect: '비율 고정 해제',
        vdist: '수직 분배',
        vflip: '수직 반전',
        zoom: '확대',
      },
      tabs: {
        layers: '레이어',
        objects: '개체',
        path_edit: '경로 편집',
      },
      uv_print_block: {
        title: 'UV 인쇄',
      },
    },
    shapes_panel: {
      animals: '동물',
      arrow: '화살표',
      basic: '기본',
      birds: '새',
      celebration: '축하',
      circular: '원형',
      CNY: '설날',
      corner: '코너',
      decor: '장식',
      easter: '부활절',
      elements: '요소',
      environment: '환경',
      graphics: '그래픽',
      halloween: '할로윈',
      holidays: '휴일',
      label: '레이블',
      land: '육지',
      line: '선',
      nature: '자연',
      photo: '사진',
      plants: '식물',
      ribbon: '리본',
      sea: '바다',
      shape: '모양',
      speech: '연설',
      text: '텍스트 상자',
      title: '요소들',
      valentines: '발렌타인 데이',
      weather: '날씨',
      Xmas: '크리스마스',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: '클립보드에서 이미지를 가져올 수 없습니다.',
      unnsupported_file_type: '해당 파일 유형은 직접 지원되지 않습니다. 파일을 SVG 또는 비트맵으로 변환하십시오.',
    },
    tag: {
      g: '그룹',
      image: '이미지',
      text: '텍스트',
      use: 'SVG 가져오기',
    },
    time_est_button: {
      calculate: '예상 시간',
      estimate_time: '예상 시간:',
    },
    tool_panels: {
      _nest: {
        end: '닫기',
        no_element: '정렬할 요소가 없습니다.',
        rotations: '가능한 회전',
        spacing: '간격',
        start_nest: '정렬',
        stop_nest: '중지',
      },
      _offset: {
        corner_type: '구석',
        direction: '오프셋 방향',
        dist: '오프셋 거리',
        fail_message: '객체 오프셋 실패',
        inward: '내부',
        not_support_message: '이미지, 그룹, 텍스트 및 가져온 객체를 포함하는 선택한 요소가 지원되지 않는 SVG 태그를 포함하고 있습니다.',
        outward: '외부',
        round: '둥근',
        sharp: '뾰족한',
      },
      array_dimension: '배열 크기',
      array_interval: '배열 간격',
      cancel: '취소',
      columns: '열',
      confirm: '확인',
      dx: 'X',
      dy: 'Y',
      grid_array: '격자형 배열 생성',
      nest: '배열 최적화',
      offset: '오프셋',
      rows: '행',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: '화면에 맞게 조정',
    },
  },
  boxgen: {
    add_option: '옵션 추가',
    basic_box: '기본 상자',
    beam_radius: '빔 보정',
    beam_radius_warning: '박스의 가장자리나 접합부가 짧을 때 박스 조립을 보장하기 위해 빔 반경 보정을 제거하십시오',
    cancel: '취소',
    coming_soon: '곧 출시',
    continue_import: '계속 가져오기',
    control_tooltip: '왼쪽 마우스로 회전\n스크롤로 확대/축소\n오른쪽 마우스로 이동',
    control_tooltip_touch: '드래그로 회전\n핀치로 확대/축소\n두 손가락으로 이동',
    cover: '덮개',
    customize: '사용자 정의',
    depth: '깊이',
    edge: '가장자리',
    finger: '핀거',
    finger_warning: '상자의 내부 길이는 핑거 조인과 호환되기 위해 최소 6mm (0.24 인치) 이상이어야 합니다.',
    height: '높이',
    import: '가져오기',
    inner: '내부',
    joints: '접합부',
    max_dimension_tooltip: '최대 너비/높이/깊이 설정은 %s입니다.',
    merge: '병합',
    outer: '외부',
    reset: '리셋',
    tCount: 'T 수',
    tCount_tooltip: 'T 슬롯의 수는 짧은 변에 적용됩니다. 긴 변의 수량은 길이에 따라 자동 계산됩니다.',
    tDiameter: 'T 지름',
    text_label: '라벨',
    thickness: '두께',
    title: 'BOXGEN',
    tLength: 'T 길이',
    tSlot: 'T 슬롯',
    tSlot_warning: '상자의 길이는 T 슬롯 조인과 호환되기 위해 최소 30mm (1.18 인치) 이상이어야 합니다.',
    volume: '부피',
    width: '너비',
    workarea: '작업 영역',
    zoom: '확대/축소',
  },
  buttons: {
    back: '뒤로',
    back_to_beam_studio: 'Beam Studio로 돌아가기',
    done: '완료',
    next: '다음',
  },
  calibration: {
    ador_autofocus_focusing_block: '기계의 메인 페이지에서 "AF" 아이콘을 3초 동안 누르고 프로브를 포커싱 블록에 닿게 하십시오.',
    ador_autofocus_material: '기계의 본 화면에서 "AF" 아이콘을 3초 동안 누르고 프로브가 소재를 부드럽게 접촉하게 해주세요.',
    align_ilb: '위치: 내부 왼쪽 아래에 새겨진 점에 맞추십시오.',
    align_ilt: '위치: 내부 왼쪽 위에 새겨진 점에 맞추십시오.',
    align_irb: '위치: 내부 오른쪽 아래에 새겨진 점에 맞추십시오.',
    align_irt: '위치: 내부 오른쪽 위에 새겨진 점에 맞추십시오.',
    align_olb: '위치: 외부 왼쪽 아래에 새겨진 점에 맞추십시오.',
    align_olt: '위치: 외부 왼쪽 위에 새겨진 점에 맞추십시오.',
    align_orb: '위치: 외부 오른쪽 아래에 새겨진 점에 맞추십시오.',
    align_ort: '위치: 외부 오른쪽 위에 새겨진 점에 맞추십시오.',
    align_red_cross_cut: '빨간 십자의 중심을 잘린 십자와 맞추십시오.',
    align_red_cross_print: '빨간 십자의 중심을 인쇄된 십자와 맞추십시오.',
    analyze_result_fail: '캡처 된 이미지를 분석하지 못했습니다. <br/>확인해주세요:<br/>1. 촬영된 사진이 완전히 흰 종이로 덮여 있는지 확인하십시오.<br/>2. 플랫폼이 적절하게 초점이 맞춰졌는지 확인하십시오.',
    ask_for_readjust: '각인 단계를 건너 뛰고 즉시 사진을 찍고 보정하시겠습니까?',
    back: '뒤로',
    calculating_camera_matrix: '카메라 매트릭스 계산 중...',
    calculating_regression_parameters: '회귀 파라미터 계산 중...',
    calibrate_camera_before_calibrate_modules: '모듈 보정을 하기 전에 카메라 보정을 수행해 주십시오.',
    calibrate_chessboard_success_msg: '체스보드 사진을 성공적으로 캡처했습니다.<br/>이 사진의 점수는 %s (%.2f)입니다.',
    calibrate_done: '보정이 완료되었습니다. 초점이 정확하게 맞을 때 카메라 정확도가 더욱 향상됩니다.',
    calibrate_done_diode: '보정 완료. 다이오드 레이저 모듈의 오프셋이 저장되었습니다.',
    calibrating: '보정 중...',
    calibrating_with_device_pictures: '장치 사진을 사용하여 보정 중...',
    camera_calibration: '카메라 보정',
    camera_parameter_saved_successfully: '카메라 매개변수가 성공적으로 저장되었습니다.',
    cancel: '취소',
    check_checkpoint_data: '체크포인트 데이터',
    check_device_pictures: '장치 사진 확인',
    checking_checkpoint: '체크포인트 데이터 확인 중...',
    checking_pictures: '장치 사진 확인 중...',
    diode_calibration: '다이오드 레이저 모듈 보정',
    do_engraving: '각인 하기',
    download_chessboard_file: '체스보드 파일 다운로드',
    downloading_checkpoint: '체크포인트 데이터 다운로드 중...',
    downloading_pictures: '사진 다운로드 중...',
    drawing_calibration_image: '보정 이미지 그리는 중...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: '들어 올리고 자르기',
    elevate_and_cut_step_1: '작업 공간의 중앙에 A4 크기의 밝은색 나무를 놓고 20mm로 올려놓으십시오.',
    elevate_and_cut_step_1_prism_lift: '최대 길이가 14mm인 Ador Prism Lift와 적어도 6mm 두께의 나무를 사용하십시오.',
    failed_to_calibrate_camera: '#848 카메라 보정에 실패했습니다. FLUX 지원팀에 문의하십시오.',
    failed_to_calibrate_chessboard: '체커보드 사진으로 보정에 실패했습니다.',
    failed_to_calibrate_with_pictures: '#848 장치 사진을 사용한 보정에 실패했습니다.',
    failed_to_download_pictures: '#848 사진 다운로드에 실패했습니다. FLUX 지원팀에 문의하십시오.',
    failed_to_move_laser_head: '레이저 헤드를 이동하지 못했습니다.',
    failed_to_parse_checkpoint: '체크포인트 데이터 분석에 실패했습니다.',
    failed_to_save_calibration_results: '#849 보정 결과 저장에 실패했습니다. 다시 시도하십시오. 이 문제가 계속 발생하면, FLUX 지원팀에 문의하십시오.',
    failed_to_save_camera_parameter: '카메라 매개변수 저장에 실패했습니다.',
    failed_to_solve_pnp: '카메라 위치를 해결하지 못했습니다.',
    finish: '완료',
    found_checkpoint: '당신의 장치에서 체크포인트 데이터를 발견했습니다. 체크포인트에서 복구하시겠습니까?',
    getting_plane_height: '평면 높이 가져오는 중...',
    hint_adjust_parameters: '빨간색 사각형을 조정하기 위해 이러한 매개 변수를 사용하세요.',
    hint_red_square: '빨간색 사각형의 바깥쪽을 절단된 사각형에 맞춰 조정하세요.',
    module_calibration_2w_ir: '적외선 모듈 보정',
    module_calibration_printer: '프린터 모듈 보정',
    moving_laser_head: '레이저 헤드를 이동 중...',
    next: '다음',
    no_picutre_found: '#846 귀하의 장치에는 보정용으로 사용 가능한 원시 사진이 없습니다. FLUX 지원에 문의하십시오.',
    perform_autofocus_bb2: '기계 제어 패널로 가서 AF를 눌러 자동 초점을 수행하십시오.',
    please_do_camera_calibration_and_focus: {
      beambox: '다이오드 레이저 모듈 보정시 카메라가 필요합니다. 이 기계의 카메라가 보정되었는지 확인해주세요. 그리고 플랫폼을 초점에 맞추십시오 (뒤집힌 아크릴의 높이).',
      beamo: '다이오드 레이저 모듈 보정시 카메라가 필요합니다. 이 기계의 카메라가 보정되었는지 확인해주세요. 그리고 레이저 헤드를 조정하여 조각 된 아크릴의 높이에 초점을 맞추십시오.',
    },
    please_goto_beambox_first: '이 기능을 사용하려면 Engraving Mode (Beambox)로 전환하십시오.',
    please_place_dark_colored_paper: 'A4 또는 레터 사이즈의 어두운 색 종이를 작업 영역 중앙에 놓아주세요.',
    please_place_paper: '좌측 상단 모서리에 A4 또는 Letter 크기의 흰색 종이를 놓으십시오.',
    please_place_paper_center: 'A4 또는 편지 사이즈의 흰 종이를 작업 영역 가운데 놓아 주십시오.',
    please_refocus: {
      beambox: '대상물의 초점 (뒤집힌 아크릴의 높이)에 대한 플랫폼을 조정하십시오.',
      beamo: '레이저 헤드를 각인 대상물 (뒤집힌 아크릴의 높이)에 맞추십시오.',
      beamo_af: '오토포커스 애드온 측면 버튼을 두 번 클릭하여 프로브를 부드럽게 물질에 닿게하십시오.',
      hexa: '높이 조정 버튼을 두 번 클릭하여 허니콤 테이블을 들어 올려 프로브가 각인 재료에 닿게하십시오.',
    },
    preparing_to_take_picture: '사진 촬영 준비 중...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: '체스보드 놓기',
    put_chessboard_1: '기계 제어 패널로 가서 AF를 눌러 자동 초점을 수행하십시오.',
    put_chessboard_2: '체커보드 용지 또는 레이저 헤드를 이동하여 라이브 미리보기 창에서 빨간 프레임 내에 체커보드 전체가 확인될 때까지 이동하십시오.',
    put_chessboard_3: '현재 위치에서 체스보드 이미지를 다운로드하려면 오른쪽 클릭을 하고 선명도를 확인하십시오.',
    put_chessboard_bb2_desc_1: '아래의 체스보드 파일을 다운로드하여 A4 용지에 인쇄하세요 (인쇄된 체스보드의 격자는 1x1 cm여야 합니다).',
    put_chessboard_bb2_desc_2: '인쇄된 체스보드를 아크릴 또는 유리와 같은 변형되지 않는 보드에 고정하고 체스보드가 매끄럽고 주름이나 들뜸이 없도록 확인하세요.',
    put_chessboard_bb2_desc_3: '체스보드가 있는 보드를 작업 영역 중앙에 평평하게 놓으세요.',
    put_chessboard_promark_1: '필드 렌즈에 적합한 초점 거리로 조정하세요.',
    put_chessboard_promark_2: '체커보드 용지가 빛 반사 없이 선명하게 보이는지 확인해 주세요. "다음"을 클릭하여 사진을 촬영하세요.',
    put_chessboard_promark_desc_1: '부속품 상자에 제공된 체커보드 용지를 사용하거나, 아래 체커보드 패턴을 A4 용지에 인쇄하여 카메라 캘리브레이션을 수행하십시오.',
    put_chessboard_promark_desc_2: '체커보드를 평평하게 놓고 작업 플랫폼의 중앙에 배치하세요.',
    put_paper: '종이를 놓으세요',
    put_paper_promark_1: '액세서리 박스에서 검은색 판지를 작업 플랫폼에 놓으세요.',
    put_paper_promark_2: '초점을 제대로 맞추고 "각인 시작"을 클릭하여 조각 작업을 진행하세요.',
    put_paper_skip: '보정 도면이 자동으로 사진 촬영으로 진행되지 않으면, "건너뛰기"를 눌러 사진을 촬영하세요.',
    put_paper_step1: 'A4 또는 Letter 크기의 흰색 종이를 작업 영역 중앙에 놓으십시오.',
    put_paper_step2: '종이의 네 귀퉁이를 고정하여 평평하게 유지하십시오.',
    put_paper_step3: '"각인 시작"을 클릭하십시오.',
    res_average: '보통',
    res_excellent: '우수',
    res_poor: '나쁨',
    retake: '사진 다시 찍기',
    rotation_angle: '회전',
    show_last_config: '마지막 결과 표시',
    skip: '건너 뛰기',
    solve_pnp_step1: '각 빨간색 마커 포인트의 번호와 예상 위치에 따라 조각 지점을 정렬하십시오.',
    solve_pnp_step2: '다시 시도를 눌러 마커 위치를 재조정하거나 수동으로 조정할 수 있습니다.',
    solve_pnp_step3: '촬영한 이미지가 실제 장면과 일치하지 않으면 화면을 확대하거나 드래그하여 검은색 보정 카드의 보정점을 중앙으로 조정한 후 "사진 다시 찍기"을 시도해 보세요.',
    solve_pnp_title: '마커 포인트 정렬',
    start_engrave: '각인 시작',
    start_printing: '인쇄 시작',
    taking_picture: '사진 찍는 중...',
    unable_to_load_camera_parameters: "#851 기기에서 사용할 수 있는 카메라 보정 매개변수가 없습니다. '보정' > '카메라 보정 (고급)'으로 이동하여 보정을 완료하고 매개변수를 얻으세요.",
    update_firmware_msg1: '당신의 펌웨어는 이 기능을 지원하지 않습니다. 계속하려면 최신 펌웨어 버전으로 업데이트하십시오.',
    update_firmware_msg2: '(메뉴> 기계> [당신의 기계]> 펌웨어 업데이트)',
    uploading_images: '이미지 업로드 중...',
    use_last_config: '마지막 보정 값 사용',
    use_old_camera_parameter: '현재 카메라 렌즈 매개변수를 사용하시겠습니까?',
    with_af: '오토 포커스 사용',
    with_prism_lift: 'Ador Prism Lift와 함께',
    without_af: '오토 포커스 없음',
    without_prism_lift: 'Ador Prism Lift없이',
    x_ratio: 'X 비율',
    y_ratio: 'Y 비율',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: '카메라 데이터 다운로드에 성공했습니다.',
    downloading_data: '데이터 다운로드 중...',
    estimated_time_left: '예상 남은 시간:',
    folder_not_exists: '선택한 폴더가 존재하지 않습니다.',
    incorrect_folder: '캘리브레이션 데이터 업로드에 실패했습니다. 선택한 폴더가 올바른지 확인해주세요.',
    no_picture_found: '기계에 이미지가 없습니다.',
    title: '카메라 데이터 백업',
    upload_success: '카메라 데이터 업로드에 성공했습니다.',
    uploading_data: '데이터 업로드 중...',
  },
  caption: {
    connectionTimeout: '연결 시간 초과',
  },
  change_logs: {
    added: '추가됨:',
    change_log: '변경 기록:',
    changed: '변경됨:',
    fixed: '고침:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: '이전 버전 보기',
  },
  code_generator: {
    barcode: '바코드',
    qr_code: 'QR 코드',
  },
  curve_engraving: {
    '921': '자동 초점 조정 실패.',
    '922': '적색광 측정 실패.',
    amount: '수량',
    apply_arkwork: '곡선에 아트워크 적용',
    apply_camera: '곡선에 카메라 이미지 적용',
    click_to_select_point: '다시 측정할 포인트를 선택하거나 선택 해제하려면 클릭하세요.',
    coloumns: '열',
    column_gap: '열 간격',
    err_object_over_range: '객체가 측정 범위를 초과합니다.',
    failed_to_take_reference: '참조 획득 실패',
    gap: '간격',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: '자동 초점 영역 측정',
    preview_3d_curve: '곡선 조각 미리보기',
    remeasure: '다시 측정',
    remeasuring_points: '포인트를 다시 측정 중...',
    reselect_area: '영역 다시 선택',
    row_gap: '행 간격',
    rows: '행',
    set_object_height: '객체 높이 설정',
    set_object_height_desc: '객체의 최대 두께를 측정하십시오.',
    start_autofocus: '자동 초점 시작',
    starting_measurement: '측정 시작 중...',
    sure_to_delete: '곡선 조각 초점 데이터를 삭제하시겠습니까?',
    take_reference: '참조 가져오기',
    take_reference_desc: '레이저 헤드를 객체의 가장 높은 지점으로 이동시키고, 초점 프로브를 내린 후 "확인"을 클릭하여 초점을 맞추세요.',
  },
  device: {
    abort: '중단',
    aborted: '중단됨',
    aborting: '중단 중',
    busy: '사용 중',
    cartridge_info_read_failed: '잉크 카트리지가 완전히 삽입되었는지 확인해주세요. 잉크를 제거하고 다시 설치해보십시오.',
    cartridge_info_verification_failed: '귀하의 잉크가 FLUX 정품인지 확인해주세요.',
    cartridge_serial_number: '잉크 카트리지 일련 번호',
    close_door_to_read_cartridge_info: '잉크 정보에 접근하려면 기계의 도어 커버를 닫아주세요.',
    completed: '완료됨',
    completing: '완료 중',
    deviceList: '기계 목록',
    disable: '비활성화',
    disconnectedError: {
      caption: '기계 연결 끊김',
      message: '%s의 네트워크 접속이 가능한지 확인해주세요.',
    },
    enable: '활성화',
    firmware_version: '펌웨어 버전',
    ink_color: '잉크 색상',
    ink_level: '잉크 잔량',
    ink_type: '잉크 유형',
    IP: 'IP',
    model_name: '모델 이름',
    occupied: '유지보수 중',
    pause: '일시 정지',
    paused: '일시 정지됨',
    pausedFromError: '오류로 인한 일시정지',
    pausing: '일시 정지 중',
    please_wait: '잠시 기다려주세요...',
    preparing: '준비 중',
    processing: '처리 중',
    quit: '취소',
    ready: '준비됨',
    reset: '초기화 (Kick)',
    resuming: '다시 시작 중',
    retry: '재시도',
    running: '작업 중',
    scanning: '스캔 중',
    select: '선택',
    select_printer: '프린터 선택',
    serial_number: '일련 번호',
    start: '시작',
    starting: '시작 중',
    status: '상태',
    submodule_type: '모듈',
    toolhead_change: '툴 헤드 교체',
    unknown: '알 수 없음',
    uploading: '업로드 중',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 네트워크에서 기계를 찾을 수 없습니다. 친절하게 <a target=""_blank"" href=""https://support.flux3dp.com/hc/en-us/articles/360001683556"">안내</a>를 따라 연결 문제를 해결해 주세요.',
    no_device_web: "#801 기계 상태를 확인하거나 아래의 '기계 설정'을 클릭하여 기계를 설정하십시오.",
    select_usb_device: 'USB 장치를 선택하십시오',
  },
  editor: {
    exposure: '미리보기 노출',
    opacity: '미리보기 투명도',
    prespray_area: '프라이밍 영역',
  },
  error_pages: {
    screen_size: 'Beam Studio가 사용 중인 장치에서 최적으로 작동하지 않을 수 있습니다. 최적의 경험을 위해서는 최소한 1024 픽셀 너비의 스크린이 있는 장치를 사용해야 합니다.',
  },
  flux_id_login: {
    connection_fail: '#847 FLUX 멤버 서비스에 연결하지 못했습니다.',
    email: '이메일',
    flux_plus: {
      access_monotype_feature: '모노타입 글꼴 추가 기능이 없습니다.',
      access_monotype_feature_note: '이 기능을 사용하려면 FLUX+ 프로 멤버십 또는 모노타입 글꼴 추가 기능이 필요합니다.',
      access_plus_feature_1: '귀하는',
      access_plus_feature_2: '기능에 접속하고 있습니다.',
      access_plus_feature_note: '이 기능을 사용하려면 FLUX+ 멤버십이 필요합니다.',
      ai_credit_tooltip: 'AI 배경 제거를 위한',
      explore_plans: 'FLUX+ 플랜 살펴보기',
      features: {
        ai_bg_removal: 'AI 배경 제거',
        boxgen: '3D 상자 생성기',
        dmkt: '1000+ 디자인 템플릿',
        monotype: '250+ 프리미엄 글꼴',
        my_cloud: '무제한 클라우드 스토리지',
      },
      flux_credit_tooltip: '디자인 마켓 파일 및 AI 배경 제거를 위한',
      get_addon: '추가 기능 받기',
      goto_member_center: '회원 센터 방문하기',
      learn_more: '자세히 알아보기',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: '지금 구독하기',
      thank_you: '소중한 회원이 되어주셔서 감사합니다!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: '비밀번호를 잊으셨나요?',
    incorrect: '이메일 주소 또는 비밀번호가 올바르지 않습니다.',
    login: '로그인',
    login_success: '로그인 성공.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'FLUX에 처음이신가요? 계정 만들기.',
    not_verified: '이메일 주소가 아직 인증되지 않았습니다.',
    offline: '오프라인 작업',
    password: '비밀번호',
    register: 'FLUX 계정 만들기',
    remember_me: '기억하기',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: '도형 데이터베이스 잠금 해제를 위해 로그인하세요.',
    work_offline: '오프라인 작업',
  },
  framing: {
    area_check: '영역 확인',
    areacheck_desc: '작업 영역이 안전한지 객체의 경계 상자와 레이저 헤드의 가속 영역을 미리 보아 확인합니다.',
    calculating_task: '작업 계산 중...',
    framing: '프레이밍',
    framing_desc: '객체의 경계 상자를 미리 봅니다.',
    hull: '외형',
    hull_desc: '객체 주위에 고무줄이 감겨 있는 것처럼 디자인을 밀접하게 따르는 모양을 미리 봅니다.',
    low_laser: '저출력 레이저',
    low_laser_desc: '프레임 작업을 위한 저출력 레이저 값을 설정하세요.',
    rotate_axis: '회전 축',
    rotateaxis_desc: '로타리 축 중심을 미리 봅니다. 로타리 자체는 움직이지 않습니다.',
    rotation_frame_warning: '프레임 미리보기는 한 바퀴 회전합니다. 중간에 멈추면 타점 위치가 어긋날 수 있으므로 멈추지 마세요.',
    rotation_framing_desc: '전체 회전 범위를 미리 봅니다. 로타리는 미리보기대로 회전합니다.',
    start_task: '작업 시작',
    start_task_description: '프레임 미리보기가 완료된 후 "작업 시작"을 클릭하여 작업을 제출합니다.',
  },
  general: {
    choose_folder: '폴더를 선택해 주세요.',
    processing: '처리 중...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] 상태 충돌이 발생했습니다. 작업을 다시 시도해 주세요.',
    SUBSYSTEM_ERROR: '[SE] 기계 펌웨어가 작업을 실행하는 중 오류가 발생했습니다. 기계를 다시 시작해 주세요.',
    UNKNOWN_COMMAND: '[UC] 기기 펌웨어를 업데이트 해주세요.',
    UNKNOWN_ERROR: '[UE] 알 수 없는 오류가 발생했습니다. Beam Studio와 기계를 다시 시작해 주세요.',
  },
  global: {
    apply: '적용',
    back: '뒤로',
    cancel: '취소',
    editing: {
      redo: '다시 실행',
      reset: '초기화',
      undo: '실행 취소',
      zoom_in: '확대',
      zoom_out: '축소',
    },
    mode_conflict: '이 옵션은 현재 모드에서 사용할 수 없습니다.',
    ok: '확인',
    save: '저장',
    stop: '중지',
  },
  image_edit_panel: {
    eraser: {
      brush_size: '브러시 크기',
      description: '클릭하거나 드래그하여 원치 않는 영역을 수동으로 지웁니다.',
      title: '지우개',
    },
    magic_wand: {
      description: '색상 유사성을 기반으로 인접한 영역을 선택하여 제거합니다.',
      title: '마법봉',
      tolerance: '허용 오차',
    },
    title: '이미지 편집',
  },
  initialize: {
    back: '뒤로',
    cancel: '취소',
    confirm: '확인',
    connect: '연결',
    connect_ethernet: {
      title: '직접 연결',
      tutorial1: '1. 이더넷 케이블로 기계를 컴퓨터에 연결하세요.',
      tutorial2_1: '2. 다음 가이드를  ',
      tutorial2_2: ' 컴퓨터를 라우터로 설정하세요.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: '따라',
      tutorial3: '3. 다음을 클릭하세요.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: '서버에 연결할 수 없습니다. Beam Studio를 다시 시작하고 다시 시도하십시오.',
      },
      check_camera: '카메라 가용성 확인',
      check_connection: '기계 연결 확인',
      check_firmware: '펌웨어 버전 확인',
      check_ip: 'IP 가용성 확인',
      check_swiftray_connection: '서버 연결 확인 중',
      check_swiftray_connection_unreachable: '서버에 연결할 수 없음',
      check_usb: 'USB 연결 확인',
      enter_ip: '기계 IP를 입력하세요.',
      finish_setting: '만들기 시작!',
      invalid_format: '잘못된 형식',
      invalid_ip: 'IP 유효하지 않음:',
      promark_hint: 'USB 연결에 반복적으로 실패할 경우 <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">도움말 센터의 기사</a>를 참조하십시오.',
      retry: '재시도',
      starts_with_169254: '169.254로 시작함',
      succeeded_message: '성공적으로 연결됨 🎉',
      unreachable: 'IP에 연결할 수 없음',
    },
    connect_usb: {
      connect_camera: '기계의 카메라를 USB 케이블로 컴퓨터에 연결하세요.',
      title: 'USB 연결',
      title_sub: '(HEXA 및 Ador 전용)',
      turn_off_machine: '기계를 끄십시오.',
      turn_on_machine: '기계의 전원을 켜십시오.',
      tutorial1: 'USB 케이블로 기계를 컴퓨터에 연결하세요.',
      tutorial2: "'다음'을 클릭하세요.",
      wait_for_turning_on: "시작 프로세스가 완료되고 주 화면에 접속한 후 '다음'을 클릭하세요.",
    },
    connect_wifi: {
      title: 'Wi-Fi 연결 중',
      tutorial1: '1. 터치 패널로 이동 > "네트워크" 클릭 > "Wi-Fi 연결".',
      tutorial1_ador: '1. 터치 패널로 이동 > "기계"를 클릭 > "네트워크"를 클릭 > "Wi-Fi 접속"을 클릭합니다.',
      tutorial2: '2. 선호하는 Wi-Fi 선택 및 연결.',
      what_if_1: 'Wi-Fi를 찾지 못하면 어떻게 하나요?',
      what_if_1_content: '1. Wi-Fi 암호화 모드는 WPA2 또는 비밀번호가 없어야 합니다. 2. 암호화 모드는 Wi-Fi 라우터 관리 인터페이스에서 설정할 수 있습니다. 라우터가 WPA2를 지원하지 않고 적합한 라우터를 선택하는 데 도움이 필요하면 FLUX 지원팀에 문의하십시오.',
      what_if_2: 'Wi-Fi를 전혀 찾지 못하면 어떻게 하나요?',
      what_if_2_content: '1. Wi-Fi 동글이 완전히 꽂혀 있는지 확인하세요. 2. 터치스크린에 무선 네트워크의 MAC 주소가 없으면 FLUX 지원팀에 문의하십시오. 3. Wi-Fi 채널은 2.4Ghz여야 합니다(5Ghz는 지원되지 않음).',
    },
    connect_wired: {
      title: '유선 네트워크 연결 중',
      tutorial1: '1. 기계를 라우터와 연결합니다.',
      tutorial2: '2. 네트워크를 눌러 유선 네트워크 IP를 확인하세요.',
      tutorial2_ador: '2. "기계" > "네트워크"를 눌러 유선 네트워크 IP를 확인하세요.',
      what_if_1: '만약 IP가 비어있다면?',
      what_if_1_content: '1. 이더넷 케이블이 완전히 꽂혀 있는지 확인하세요. 2. 만약 터치스크린에 유선 네트워크의 MAC 주소가 없다면, FLUX 지원팀에 문의하세요.',
      what_if_2: '만약 IP가 169로 시작한다면?',
      what_if_2_content: '1. IP 주소가 169.254로 시작된다면, DHCP 설정 문제일 수 있습니다. 인터넷 서비스 제공자에게 문의하세요. 2. 만약 컴퓨터가 PPPoE를 사용하여 직접 인터넷에 연결되어 있다면, 라우터를 사용하여 PPPoE를 사용하도록 변경하고 라우터에서 DHCP 기능을 사용하도록 설정하세요.',
    },
    connecting: '연결 중…',
    connection_types: {
      ether2ether: '직접 연결',
      usb: 'USB 연결',
      wifi: 'Wi-Fi',
      wired: '유선 네트워크',
    },
    next: '다음',
    no_machine: '지금 기계가 없습니다.',
    promark: {
      configuration_confirmation: '이는 Promark가 최적의 효율성과 정확성을 위해 올바르게 구성되었는지 확인합니다.',
      or_complete_later: `또는 이 단계를 건너뛰고 나중에 Promark 설정을 완료하세요:
    기계 > "Promark 이름" > Promark 설정`,
      qc_instructions: '"QC Pass" 카드 뒷면에 있는 매개변수를 입력하세요',
      select_laser_source: 'Promark를 선택하세요',
      select_workarea: '작업 영역을 선택하세요',
      settings: 'Promark 설정',
    },
    retry: '재시도',
    select_beambox: 'Beambox를 선택하세요',
    select_connection_type: '어떻게 연결하시겠습니까?',
    select_language: '언어 선택',
    select_machine_type: '모델 선택',
    setting_completed: {
      back: '뒤로',
      great: 'Beam Studio에 오신 것을 환영합니다.',
      ok: '만들기 시작',
      setup_later: '항상 제목 표시줄 > "기계" > "기계 설정"에서 기계를 설정할 수 있습니다.',
      start: '시작',
    },
    skip: '건너뛰기',
    start: '시작',
  },
  input_machine_password: {
    connect: '연결',
    password: '비밀번호',
    require_password: '%s은(는) 비밀번호가 필요합니다.',
  },
  insecure_websocket: {
    extension_detected: 'Beam Studio Connect 확장 프로그램이 감지되었습니다',
    extension_detected_description: "Beam Studio Connect 확장 프로그램이 설치되어 있음을 감지했습니다. HTTPS로 리디렉션하려면 '확인'을 클릭하거나 HTTP를 계속 사용하려면 '취소'를 클릭하십시오.",
    extension_not_deteced: 'Beam Studio Connect 확장 프로그램을 감지할 수 없습니다',
    extension_not_deteced_description: "HTTPS를 사용하려면 '확인'을 클릭하여 Beam Studio Connect 확장 기능을 설치하세요. 확장 기능을 설치한 후 페이지를 새로 고쳐 활성화하세요.<br/>그렇지 않으면 아래 링크를 클릭하여 Chrome에서 HTTP를 사용하는 방법을 확인하세요.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">링크</a>',
  },
  layer_module: {
    general_laser: '레이저',
    laser_2w_infrared: '2W 적외선 레이저',
    laser_10w_diode: '10W 다이오드 레이저',
    laser_20w_diode: '20W 다이오드 레이저',
    non_working_area: '비 작업 영역',
    none: '없음',
    notification: {
      convertFromLaserModuleMsg: '이 작업을 완료하면 레이저 레이어의 설정이 제거되어 현재 레이어에 따라 설정됩니다.',
      convertFromLaserModuleTitle: '레이저 모듈을 인쇄 모듈로 변환하시겠습니까?',
      convertFromPrintingModuleMsg: '이 작업을 완료하면 인쇄 레이어의 색상 설정이 제거되어 현재 레이어에 따라 설정됩니다.',
      convertFromPrintingModuleTitle: '인쇄 모듈을 레이저 모듈로 변환하시겠습니까?',
      importedDocumentContainsPrinting: '이 문서에는 인쇄 레이어가 포함되어 있습니다. 작업 영역을 Ador로 변경하시겠습니까?',
      performIRCaliMsg: '확인"을 클릭하여 보정을 실행하거나 상단 메뉴에서 보정에 접근하십시오.<br />(기계 > [귀하의 기계 이름] > 적외선 모듈 보정)',
      performIRCaliTitle: '적외선 모듈 보정을 수행하세요."',
      performPrintingCaliMsg: '확인"을 클릭하여 보정을 실행하거나 상단 메뉴에서 보정에 접근하십시오.<br />(기계 > [귀하의 기계 이름] > 프린터 모듈 보정)',
      performPrintingCaliTitle: '인쇄 모듈 캘리브레이션을 수행하세요.',
      printingLayersConverted: '인쇄 레이어가 레이저 레이어로 변환되었습니다.',
    },
    printing: '인쇄',
    unknown: '알 수 없는 모듈',
    uv_print: 'UV 인쇄',
  },
  machine_status: {
    '-17': 'Cartridge IO Mode',
    '-10': '유지보수 모드',
    '-2': '스캔 중',
    '-1': '유지보수 중',
    0: '대기',
    1: '시작 중',
    2: 'ST_TRANSFORM',
    4: '시작',
    6: '재개',
    16: '작업 중',
    18: '재개',
    32: '일시 정지',
    36: '일시 정지',
    38: '일시 정지',
    48: '일시 정지',
    50: '일시 정지',
    64: '완료됨',
    66: '완료 중',
    68: '준비 중',
    128: '중단됨',
    256: '경고',
    512: '치명적 오류',
    UNKNOWN: '알 수 없음',
  },
  material_test_generator: {
    block_settings: '블록 설정',
    columns: '열',
    count: '수량',
    cut: '자르기',
    engrave: '새기기',
    export: '내보내기',
    max: '최대',
    min: '최소',
    parameter: '매개변수',
    preview: '미리보기',
    rows: '행',
    size: '크기 (HxW)',
    spacing: '간격',
    table_settings: '테이블 설정',
    text_settings: '텍스트 설정',
    title: '재질 테스트 생성기',
  },
  menu: {
    inches: '인치',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 인증 오류: Beam Studio와 기계 펌웨어를 최신 버전으로 업데이트해주세요.',
    authenticating: '인증 중...',
    camera: {
      abort_preview: '중지',
      camera_cable_unstable: '카메라 전송이 불안정하게 이루어지는 것이 감지되었습니다. 미리보기는 정상적으로 수행될 수 있지만, 느린 미리보기 또는 시간 초과의 문제가 발생할 수 있습니다.',
      continue_preview: '계속하기',
      fail_to_transmit_image: '#845 이미지 전송 중 오류가 발생했습니다. 기계나 Beam Studio를 다시 시작해주세요. 만약 이 오류가 계속 발생한다면, <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">이 가이드</a>를 따라주세요.',
      ws_closed_unexpectly: '#844 기계 카메라 연결이 예기치 않게 종료되었습니다. 만약 이 오류가 계속 발생한다면, <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">이 가이드</a>를 따라주세요.',
    },
    cancelling: '취소 중...',
    cant_connect_to_device: '#827 기기에 연결할 수 없습니다. 연결을 확인해주세요.',
    connected: '연결됨',
    connecting: '연결 중...',
    connectingCamera: '카메라에 연결 중입니다...',
    connectingMachine: '%s 연결 중...',
    connectionTimeout: '#805 기기 연결 시간 초과. 네트워크 상태와 기기의 Wi-Fi 표시기를 확인해주세요.',
    device_blocked: {
      caption: '허가되지 않은 시리얼 번호',
      offline: '기기의 시리얼 번호는 비활성화된 것으로 보이며 오프라인 사용으로 제한됩니다. 기기의 뒷면에 있는 시리얼 번호를 소매업체에 제공하고 support@flux3dp.com에 연락하여 온라인 인증을 활성화하세요. 기기를 오프라인으로 사용하려면 support@flux3dp.com에 직접 문의하세요.',
      online: '기기의 시리얼 번호가 비활성화된 것으로 보입니다. 기기의 뒷면에 있는 시리얼 번호를 소매업체에 제공하고 support@flux3dp.com에 연락하여 기기 인증을 활성화하도록 요청하세요.',
    },
    device_busy: {
      caption: '기계가 바쁩니다',
      message: '기계가 다른 작업을 실행 중입니다. 나중에 다시 시도해보세요. 만약 기계가 작동을 멈춘다면, 기계를 다시 시작하세요.',
    },
    device_is_used: '기계가 사용 중입니다. 현재 작업을 중단하시겠습니까?',
    device_not_found: {
      caption: '기본 기기를 찾을 수 없습니다',
      message: '#812 기기의 Wi-Fi 표시기를 확인해주세요.',
    },
    disconnected: '연결이 불안정합니다. 기기 연결을 확인하고 나중에 다시 시도해주세요.',
    endingLineCheckMode: '라인 체크 모드에서 빠져나옵니다...',
    endingRawMode: 'Raw 모드에서 빠져나옵니다...',
    enteringLineCheckMode: '라인 체크 모드로 진입 중...',
    enteringRawMode: 'Raw 모드로 진입 중...',
    enteringRedLaserMeasureMode: '빨간 레이저 측정 모드로 전환 중...',
    exitingRotaryMode: '로터리 모드에서 빠져나나옵니다...',
    getProbePosition: '프로브 위치 가져오는 중...',
    gettingLaserSpeed: '레이저 헤드 속도를 읽고 있습니다...',
    homing: '홈을 찾고 있습니다...',
    need_password: '기계에 연결하려면 비밀번호가 필요합니다',
    please_enter_dpi: '파일의 단위를 입력해주세요 (mm)',
    preview: {
      adjust: '조절',
      adjust_height_tooltip: '편집을 활성화하려면 확인란을 클릭하세요.',
      already_performed_auto_focus: '이미 오토포커스를 수행했습니다. 기존 값 사용하시겠습니까?',
      auto_focus: '오토포커스',
      auto_focus_instruction: '레이저 모듈 헤드를 물체 위로 이동하고 포커스를 맞추려면 애니메이션 지침을 따라 주십시오.',
      camera_preview: '카메라 미리보기',
      enter_manually: '수동 입력',
      please_enter_height: '물체의 높이를 입력하여 정확한 카메라 촬영을 위해.',
    },
    promark_disconnected: '#850 장치 연결이 중단되었습니다. 장치의 연결 상태를 확인하세요.',
    redLaserTakingReference: '기준값을 측정 중...',
    retrievingCameraOffset: '카메라 오프셋을 읽고 있습니다...',
    settingLaserSpeed: '레이저 헤드 속도를 설정합니다...',
    swiftray_disconnected: '백엔드에 연결할 수 없습니다. 다시 연결을 시도 중입니다.',
    swiftray_reconnected: '백엔드가 다시 연결되었습니다. 작업을 다시 보내세요.',
    time_remaining: '남은 시간:',
    tryingToConenctMachine: '기계에 연결을 시도 중입니다...',
    turningOffAirPump: '공기 펌프를 끕니다...',
    turningOffFan: '팬을 끕니다...',
    unable_to_find_machine: '기계를 찾을 수 없습니다',
    unable_to_start: '#830 작업을 시작할 수 없습니다. 다시 시도해주세요. 이 오류가 계속 발생하면 버그 보고서를 제출해주세요:\n',
    unavailableWorkarea: '#804 현재 작업 영역이 이 기계의 작업 영역을 초과합니다. 선택한 기계의 작업 영역을 확인하거나 편집 > 문서 설정에서 작업 영역을 설정해주세요.',
    unknown_device: '#826 기계에 연결할 수 없습니다. USB가 기계에 연결되어 있는지 확인해주세요.',
    unknown_error: '#821 알 수 없는 오류가 발생했습니다. 도움말 > 메뉴 > 버그 보고서를 사용해주세요.',
    unsupport_osx_version: '현재 macOS 버전 %s은(는) 일부 기능을 지원하지 않을 수 있습니다. macOS 11+로 업데이트해 주세요.',
    unsupport_win_version: '현재 OS 버전 %s에서는 일부 기능을 지원하지 않을 수 있습니다. 최신 버전으로 업데이트해주세요.',
    unsupported_example_file: '선택한 예제 파일은 현재 작업 영역에서 지원되지 않습니다.',
    uploading_fcode: 'FCode 업로드 중',
    usb_unplugged: 'USB 연결이 끊어졌습니다. USB 연결을 확인해주세요.',
    wrong_swiftray_version_message: '잘못된 백엔드 버전입니다 (버전: {version}). 여러 개의 Beam Studio 인스턴스가 실행 중인지 확인하고 불필요한 인스턴스를 닫은 후 다시 시도하세요.',
    wrong_swiftray_version_title: '잘못된 백엔드 버전',
  },
  monitor: {
    ask_reconnect: '기계와의 연결이 끊겼습니다. 다시 연결하시겠습니까?',
    bug_report: '버그 보고서',
    camera: '카메라',
    cancel: '취소',
    confirmFileDelete: '이 파일을 삭제하시겠습니까?',
    connecting: '연결 중입니다. 기다려주세요...',
    DEVICE_ERROR: '문제가 발생했습니다. 기계를 다시 시작하세요.', // Deprecated in FW 3.3.1
    download: '다운로드',
    extensionNotSupported: '이 파일 형식은 지원되지 않습니다.',
    fileExistContinue: '파일이 이미 있습니다. 대체하시겠습니까?',
    forceStop: '현재 작업을 중단하시겠습니까?',
    go: '시작',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 공기 흐름 센서 이상',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 바닥이 열려 있습니다. 계속하려면 바닥을 닫으세요.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 계속하려면 문을 닫으세요.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 서랍(Drawer) 열림',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 화염 센서 이상',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 잘못된 모듈이 감지되었습니다. 계속하려면 올바른 모듈을 설치하십시오.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 모듈이 감지되지 않습니다. 계속하려면 모듈이 올바르게 설치되었는지 확인하십시오.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 알 수 없는 모듈이 감지되었습니다. 계속하려면 올바른 모듈을 설치하십시오.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 호밍(Homing) 풀오프 오류',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 치명적인 오류: 메인보드 오프라인. FLUX 지원팀에 문의하세요.', // Deprecated in FW 3.3.1
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 과열되었습니다. 몇 분 기다려주세요.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z축 리셋 실패',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 프린터 모듈 응답 없음.',
    HARDWARE_ERROR_PROBE_SHOWED: '프로브를 철회해주세요.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 워터탱크를 확인해주세요.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 로터리 모듈이 감지되지 않습니다.',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 치명적인 오류: 하위 시스템 응답 없음. FLUX 지원팀에 문의하세요.', // Deprecated in FW 3.3.1
    HARDWARE_FAILURE: '문제가 발생했습니다. 기계를 다시 시작하세요.', // Deprecated in FW 3.3.1
    hour: '시',
    left: '남음',
    MAINBOARD_OFFLINE: '#905 메인보드 연결 오류. 기계를 다시 시작하세요.',
    minute: '분',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 메인보드 연결 오류. 기계를 다시 시작하세요.',
    pause: '일시 정지',
    prepareRelocate: '이동 준비 중',
    processing: '처리 중',
    record: '녹화',
    relocate: '이동',
    RESOURCE_BUSY: '기계가 작동 중입니다. 작동하지 않으면 기계를 다시 시작하세요.',
    resume: '재개',
    savingPreview: '섬네일 생성 중',
    second: '초',
    start: '시작',
    stop: '정지',
    SUBSYSTEM_ERROR: '#402 치명적인 오류: 하위 시스템 응답 없음. FLUX 지원팀에 문의하세요.',
    task: {
      BEAMBOX: '레이저 조각',
      'N/A': '자유 모드',
    },
    taskTab: '작업',
    temperature: '온도',
    upload: '업로드',
    USER_OPERATION: '계속하려면 장치 패널의 지시 사항을 따르세요.',
    USER_OPERATION_CHANGE_CARTRIDGE: '계속하려면 올바른 카트리지를 삽입하세요.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: '계속하려면 카트리지를 삽입하세요.',
    USER_OPERATION_CHANGE_TOOLHEAD: '계속하려면 올바른 모듈을 설치하십시오.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 잘못된 모듈이 감지되었습니다. 계속하려면 올바른 모듈을 설치하십시오.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 모듈이 감지되지 않습니다. 계속하려면 모듈이 올바르게 설치되었는지 확인하십시오.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 알 수 없는 모듈이 감지되었습니다. 계속하려면 올바른 모듈을 설치하십시오.',
    USER_OPERATION_REMOVE_CARTRIDGE: '계속하려면 카트리지를 제거하세요.',
    USER_OPERATION_ROTARY_PAUSE: '로터리 모터로 전환하세요',
  },
  my_cloud: {
    action: {
      confirmFileDelete: '이 파일을 정말 삭제하시겠습니까? 이 작업은 취소할 수 없습니다.',
      delete: '삭제',
      download: '다운로드',
      duplicate: '복제',
      open: '열기',
      rename: '이름 바꾸기',
    },
    file_limit: '무료 파일',
    loading_file: '로딩 중...',
    no_file_subtitle: '메뉴 > "파일" > "클라우드에 저장"',
    no_file_title: '파일을 내 클라우드에 저장하여 시작하세요.',
    save_file: {
      choose_action: '파일 저장:',
      input_file_name: '파일 이름 입력:',
      invalid_char: '잘못된 문자:',
      save: '저장',
      save_new: '새 파일로 저장',
      storage_limit_exceeded: '클라우드 저장소 용량이 상한에 도달했습니다. 새 파일을 저장하기 전에 불필요한 파일을 삭제하세요.',
    },
    sort: {
      a_to_z: '이름: A-Z',
      most_recent: '최신 순',
      oldest: '오래된 순',
      z_to_a: '이름: Z-A',
    },
    title: '내 클라우드',
    upgrade: '업그레이드',
  },
  noun_project_panel: {
    clear: '모두 지우기',
    elements: '요소',
    enjoy_shape_library: '도형 데이터베이스를 즐겨보세요.',
    export_svg_title: 'SVG 내보내기 실패',
    export_svg_warning: '이 프로젝트에는 지적 재산권으로 보호되는 객체가 포함되어 있습니다. 따라서 Beam Studio는 내보내기 중 이러한 객체를 자동으로 제외합니다. 전체 데이터를 유지하려면 Beam Studio Scene(.beam 포맷)으로 프로젝트를 저장할 수 있습니다. 여전히 내보내시겠습니까?',
    learn_more: '자세히 알아보기',
    login_first: '도형 데이터베이스 잠금 해제를 위해 로그인하세요.',
    recent: '최근',
    search: '검색',
    shapes: '도형',
  },
  pass_through: {
    export: '작업 영역으로 내보내기',
    exporting: '내보내는 중...',
    guide_mark: '가이드 마크',
    guide_mark_desc: '가이드 마크는 작품 정렬을 위한 기준점으로 새겨집니다.',
    guide_mark_length: '길이:',
    guide_mark_x: 'X 좌표:',
    height_desc: '각 작업 영역 섹션의 높이를 설정합니다.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: '%(model)s의 패스스루 설정 방법은?',
    object_length: '객체 길이',
    ref_layer: '참조 레이어',
    ref_layer_desc: '참조 레이어의 실행이 기본값으로 0으로 설정되어 있음을 유의하십시오. 실행되지 않으며 정렬 참조 용도입니다.',
    ref_layer_name: '참조 레이어',
    title: '패스스루 모드',
    workarea_height: '작업 영역 (높이):',
  },
  promark_connection_test: {
    description: '시스템이 약 2분 동안 적색광 작동을 실행하여 연결 안정성을 확인합니다.',
    health: '안정성:',
    healthy_description: '안정성이 양호하지만, 조각 중 컴퓨터를 크게 이동하지 마세요.',
    res_0: '우수',
    res_1: '좋음',
    res_2: '낮음',
    res_3: '매우 낮음',
    res_4: '극도로 낮음',
    restart: '테스트 다시 시작',
    start: '테스트 시작',
    stop: '테스트 중지',
    title: '연결 안정성 테스트',
    unhealthy_description: '안정성이 너무 낮습니다. 네트워크 어댑터 또는 전원 케이블 교체를 권장합니다.',
  },
  promark_settings: {
    angle: '각도',
    bulge: '볼록함',
    field: '필드',
    galvo_configuration: '갈보 설정',
    mark: '표시',
    mark_parameters: '마킹 매개변수',
    offsetX: '오프셋 X',
    offsetY: '오프셋 Y',
    preview: '미리보기',
    red_dot: '빨간 점',
    scale: '스케일',
    scaleX: '스케일 X',
    scaleY: '스케일 Y',
    skew: '기울기',
    switchXY: 'X/Y 전환',
    title: 'Promark 설정',
    trapezoid: '사다리꼴',
    workarea_hint: '작업 영역은 "문서 설정"에서 변경할 수 있습니다.',
    z_axis_adjustment: {
      section1: 'Z축 높이를 조정하여 초점을 미세 조정합니다.',
      title: 'Z축 조정',
      tooltip1: '현재 초점 거리가 적절한지 확인하려면 1x1 cm 정사각형을 표시해 보세요.',
    },
  },
  qr_code_generator: {
    error_tolerance: '오류 보정 레벨',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: '배경색 반전',
    placeholder: '링크나 텍스트를 입력해 주세요',
    preview: '미리보기',
    title: 'QR 코드 생성기',
  },
  rotary_settings: {
    circumference: '원주',
    extend_workarea: '작업 영역 확장',
    mirror: '미러',
    object_diameter: '물체 직경',
    overlap_size: '겹침 크기',
    rotary_scale: '회전 스케일',
    split_setting: '분할 설정',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: '분할 크기',
    type: '유형',
  },
  select_device: {
    auth_failure: '#811 인증 실패',
    select_device: '기기 선택',
    unable_to_connect: '#810 기기와 안정적인 연결을 구축할 수 없습니다.',
  },
  settings: {
    anti_aliasing: '앤티앨리어싱',
    auto_connect: '유일한 기계 자동 선택',
    auto_switch_tab: '레이어 및 객체 패널 자동 전환',
    autofocus_offset: '자동 초점 오프셋',
    autosave_enabled: '자동 저장',
    autosave_interval: '매 저장마다 저장',
    autosave_number: '자동 저장 횟수',
    autosave_path: '자동 저장 위치',
    autosave_path_not_correct: '지정된 경로를 찾을 수 없습니다.',
    blade_precut_position: '미리 자른 위치',
    blade_precut_switch: '미리 자른 칼날',
    blade_radius: '칼날 반지름',
    bottom_up: '아래에서 위로',
    calculation_optimization: '경로 계산 가속',
    cancel: '취소',
    caption: '설정',
    check_updates: '자동 확인',
    close: '닫기',
    confirm_remove_default: '기본 기계가 제거됩니다.',
    confirm_reset: 'Beam Studio 재설정을 확인하시겠습니까?',
    continuous_drawing: '연속 그리기',
    curve_engraving_speed_limit: '곡선 조각 속도 제한',
    custom_preview_height: '사용자 정의 미리보기 높이',
    default_beambox_model: '기본 문서 설정',
    default_borderless_mode: '열린 밑면 기본 설정',
    default_enable_autofocus_module: '오토포커스 기본 설정',
    default_enable_diode_module: '다이오드 레이저 기본 설정',
    default_font_family: '기본 글꼴',
    default_font_style: '기본 글꼴 스타일',
    default_laser_module: '기본 레이저 모듈',
    default_machine: '기본 기계',
    default_machine_button: '비어 있음',
    default_units: '기본 단위',
    diode_offset: '다이오드 레이저 오프셋',
    diode_one_way_engraving: '다이오드 레이저 일방향 조각',
    diode_two_way_warning: '양방향 조명은 레이저 조각 위치에 정확도 문제가 발생할 가능성이 있습니다. 먼저 테스트를 권장합니다.',
    disabled: '사용 안 함',
    done: '적용',
    enable_custom_backlash: '사용자 정의 백래시 활성화',
    enable_low_speed: '저속 이동 활성화',
    enable_uv_print_file: 'UV 인쇄 파일 활성화',
    enabled: '사용',
    engraving_direction: '방향',
    fast_gradient: '속도 최적화',
    font_convert: '텍스트-경로 변환기',
    font_substitute: '지원되지 않는 문자 대체',
    grouped_objects: '그룹화된 객체',
    groups: {
      ador_modules: '아도르 모듈',
      autosave: '자동 저장',
      camera: '카메라',
      connection: '연결',
      editor: '에디터',
      engraving: '레스터링 (스캔)',
      general: '일반',
      mask: '작업 영역 클리핑',
      modules: '애드온',
      path: '벡터 (윤곽)',
      privacy: '개인 정보',
      text_to_path: '텍스트',
      update: '소프트웨어 업데이트',
    },
    guess_poke: '기계 IP 주소 검색',
    guides: '가이드',
    guides_origin: '가이드 원점',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      uv_print_export: 'https://support.flux3dp.com/hc/en-us/articles/12320887477135',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: '높음',
    image_downsampling: '비트맵 미리보기 품질',
    ip: '기계 IP 주소',
    keep_preview_result: '미리보기 결과 유지',
    language: '언어',
    loop_compensation: '루프 보상',
    low: '낮은',
    low_laser_for_preview: '프레임 미리보기용 레이저',
    mask: '작업 영역 클리핑',
    medium: '중간',
    module_offset_2w_ir: '2W 적외선 레이저 오프셋',
    module_offset_10w: '10W 다이오드 레이저 오프셋',
    module_offset_20w: '20W 다이오드 레이저 오프셋',
    module_offset_printer: '프린터 오프셋',
    none: '없음',
    normal: '보통',
    notification_off: '끄기',
    notification_on: '켜기',
    notifications: '데스크톱 알림',
    off: '끄기',
    on: '켜기',
    preview_movement_speed: '미리보기 이동 속도',
    printer_advanced_mode: '프린터 고급 모드',
    remove_default_machine_button: '제거',
    reset: 'Beam Studio 재설정',
    reset_now: 'Beam Studio 재설정',
    segmented_engraving: '분할 조각',
    share_with_flux: 'Beam Studio 분석 공유',
    simplify_clipper_path: '계산된 경로 최적화',
    single_object: '단일 객체',
    tabs: {
      device: '기계',
      general: '일반',
    },
    text_path_calc_optimization: '경로 계산 최적화',
    top_down: '위에서 아래로',
    trace_output: '이미지 추적 출력',
    update_beta: '베타',
    update_latest: '최신',
    vector_speed_constraint: '속도 제한',
    wrong_ip_format: '잘못된 IP 형식',
  },
  social_media: {
    facebook: 'FLUX 사용자와 채팅하고 질문하며 팁을 배워보세요!',
    instagram: '최신 영감, 거래 및 무료 아이템을 받아보세요!',
    youtube: 'Beam Studio 튜토리얼과 공예 아이디어를 확인하세요.',
  },
  support: {
    no_vcredist: 'Visual C++ Redistributable 2015를 설치해 주세요. flux3dp.com에서 다운로드 가능합니다.',
    no_webgl: 'WebGL이 지원되지 않습니다. 다른 기기를 사용해 주세요.',
  },
  topbar: {
    alerts: {
      add_content_first: '먼저 개체를 추가해 주세요.',
      door_opened: '문을 닫아 저전력 레이저를 활성화하고 프레임을 실행하세요.',
      fail_to_connect_with_camera: '#803 기계의 카메라와의 접속을 설정하지 못했습니다. 기계 또는 빔스튜디오를 다시 시작해 주세요. 이 에러가 지속된다면 다음 가이드를 따라 주세요.<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">this guide</a>',
      fail_to_get_door_status: '문이 닫혀 있는지 확인하여 저전력 레이저를 활성화하고 프레임을 실행하세요.',
      fail_to_start_preview: '#803 미리보기 모드 시작 실패. 기계 또는 빔스튜디오를 다시 시작해 주세요. 이 에러가 지속된다면 다음 가이드를 따라 주세요. <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">this guide</a>',
      headtype_mismatch: '잘못된 모듈이 감지되었습니다.',
      headtype_none: '모듈이 감지되지 않았습니다.',
      headtype_unknown: '알 수 없는 모듈이 감지되었습니다.',
      install_correct_headtype: '10W/20W 다이오드 레이저 모듈을 제대로 설치하여 저전력 레이저를 활성화하고 프레임을 실행하세요.',
      job_origin_unavailable: '작업 원점을 설정하려면 펌웨어 버전 4.3.5 / 5.3.5 이상이 필요합니다. 지금 펌웨어를 업데이트하시겠습니까?',
      job_origin_warning: '현재 "현재 위치"를 시작 지점으로 사용 중입니다. 충돌을 방지하려면 레이저 헤드를 올바른 위치로 이동시키십시오.',
      power_too_high: '파워 너무 높음',
      power_too_high_confirm: '확인',
      power_too_high_msg: '70% 미만의 낮은 레이저 파워를 사용하면 레이저 튜브의 수명이 연장됩니다. 계속하려면 "확인"을 눌러주세요.',
      pwm_unavailable: '깊이 모드는 펌웨어 버전 4.3.4 / 5.3.4 이상이 필요합니다. 지금 펌웨어를 업데이트하시겠습니까?',
      QcleanScene: '그림을 지우시겠습니까?<br/>이것은 또한 취소 기록을 지울 것입니다!',
      start_preview_timeout: '#803 미리보기 모드로 시작할 때 시간초과가 발생합니다. 기계 또는 빔스튜디오를 다시 시작해 주세요. 이 에러가 지속된다면 다음 가이드를 따라주세요. <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">this guide</a>',
    },
    frame_task: '프레임 미리보기',
    hint: {
      polygon: '늘리기 / 줄이기를 위해 + /- 키를 누르세요.',
    },
    menu: {
      about: 'Beam Studio 소개',
      about_beam_studio: '빔스튜디오에 대해',
      account: '계정',
      add_new_machine: '기계 설정',
      align_center: '중앙 정렬',
      anti_aliasing: '앤티앨리어싱',
      auto_align: '자동 정렬',
      borderless_mode: '전체 화면 모드',
      bug_report: '버그 신고',
      calibrate_beambox_camera: '카메라 교정',
      calibrate_beambox_camera_borderless: '카메라 교정 (열린 하단)',
      calibrate_camera_advanced: '카메라 보정 (고급)',
      calibrate_diode_module: '다이오드 레이저 모듈 교정',
      calibrate_ir_module: '적외선 모듈 보정',
      calibrate_printer_module: '프린팅 모듈 보정',
      calibration: '캘리브레이션',
      camera_calibration_data: '카메라 보정 데이터',
      change_logs: '변경 로그',
      clear_scene: '새 파일',
      close: '윈도우 닫기',
      commands: '명령',
      contact: '문의하기',
      copy: '복사하기',
      cut: '자르기',
      dashboard: '대시보드',
      decompose_path: '분해',
      delete: '지우기',
      design_market: '디자인 마켓',
      dev_tool: '디버그 도구',
      disassemble_use: '분해하기',
      document_setting: '문서 설정',
      document_setting_short: '문서',
      download_data: '데이터 다운로드',
      download_log: '로그 다운로드',
      download_log_canceled: '로그 다운로드 취소',
      download_log_error: '알 수 없는 오류 발생, 나중에 다시 시도해 주세요',
      duplicate: '복사본 만들기',
      edit: '편집',
      example_files: '예제 파일',
      export_BVG: 'BVG',
      export_flux_task: '플럭스 태스크',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: '내보내기...',
      export_UV_print: 'UV 인쇄 PDF',
      file: '파일',
      fit_to_window: '창 크기에 맞추기',
      follow_us: '팔로우하세요',
      forum: '커뮤니티 포럼',
      group: '그룹',
      help: '도움말',
      help_center: '도움말',
      hide: '빔스튜디오 숨기기',
      hideothers: '다른 것 숨기기',
      image_crop: '자르기',
      image_curve: '곡선',
      image_invert: '반전',
      image_sharpen: '선명하게',
      image_stamp: '베벨',
      image_vectorize: '추적하기',
      import_acrylic_focus_probe: '아크릴 포커스 프로우브 - 3mm',
      import_ador_laser_example: 'Ador 레이저 예시',
      import_ador_printing_example_full: 'Ador 인쇄 예시 - 풀 컬러',
      import_ador_printing_example_single: 'Ador 인쇄 예시 - 단색',
      import_beambox_2_example: 'Beambox II 예제',
      import_beambox_2_focus_probe: 'Beambox II 초점 탐침 - 3mm',
      import_hello_beambox: 'Beambox 예시',
      import_hello_beamo: '비모 예시',
      import_hexa_example: 'HEXA 예제',
      import_material_printing_test: '재료 인쇄 테스트',
      import_material_testing_cut: '재료의 커팅 테스트 - 간단',
      import_material_testing_engrave: '재료의 각인 테스트',
      import_material_testing_line: '재료의 라인 테스트',
      import_material_testing_old: '재료의 각인 테스트 - 표준',
      import_material_testing_simple_cut: '재료의 커팅 테스트 - 간단',
      import_promark_example: 'Promark 예시',
      import_promark_mopa_20w_color: 'MOPA 20W 색상 테스트',
      import_promark_mopa_60w_color: 'MOPA 60W 색상 테스트',
      import_promark_mopa_100w_color: 'MOPA 100W 색상 테스트',
      keyboard_shortcuts: '단축키',
      layer_color_config: '색상 설정',
      layer_setting: '레이어 ',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: '카메라',
        cloud: '클라우드',
        discover: '찾기',
        hardware: '하드웨어',
        network: '네트워크',
        player: '플레이어',
        robot: '로봇',
        usb: 'USB',
        usblist: 'USB 목록',
      },
      machine_info: '기계 정보',
      machines: '기계',
      manage_account: '내 계정 관리',
      material_test: '재료 테스트',
      minimize: '최소화',
      my_account: '내 계정',
      network_testing: '네트워크 설정 테스트',
      object: '기체',
      offset: '오프셋',
      open: '열기',
      paste: '붙여넣기',
      paste_in_place: '공간에 붙여넣기',
      path: '경로',
      photo_edit: '이미지',
      preferences: '환경설정',
      promark_color_test: 'Promark 색상 테스트',
      questionnaire: '피드백 설문조사',
      quit: '끝내기',
      recent: '최근 열기',
      redo: '다시 실행',
      reload_app: '앱 다시 로드',
      reset: '재설정',
      rotary_setup: '회전 설정',
      rotate: '돌리기',
      samples: '예시',
      save_as: '다른 이름으로 저장하기',
      save_scene: '저장하기',
      save_to_cloud: '클라우드에 저장',
      scale: '스케일',
      service: '서비스',
      set_as_default: '기본값으로 설정',
      show_gesture_tutorial: '손 제스처 소개',
      show_grids: '그리드 보이기',
      show_layer_color: '레이어 색상 사용',
      show_rulers: '자 모양 보이기',
      show_start_tutorial: '시작 튜토리얼 보기',
      show_ui_intro: '인터페이스 소개 보기',
      sign_in: '로그인',
      sign_out: '로그아웃',
      software_update: '소프트웨어 업데이트',
      svg_edit: 'SVG',
      switch_to_beta: '베타 릴리스로 전환',
      switch_to_latest: '안정 릴리스로 전환',
      tools: {
        box_generator: '박스 생성기',
        code_generator: '코드 생성기',
        material_test_generator: '재질 테스트 생성기',
        title: '도구',
      },
      tutorial: '델타 프린터 가족용 튜토리얼 시작하기',
      undo: '이전 실행',
      ungroup: '그룹해제',
      update: '업데이트 확인',
      update_firmware: '펌웨어 업데이트',
      upload_data: '데이터 업로드',
      using_beam_studio_api: 'Beam Studio API 사용하기',
      view: '보기',
      window: '윈도우',
      zoom_in: '확대',
      zoom_out: '축소',
      zoom_with_window: '자동 창 크기 맞춤',
    },
    preview: '미리보기',
    preview_press_esc_to_stop: 'ESC를 눌러 카메라 미리보기를 중지하세요.',
    rename_tab: '탭 이름 변경',
    select_machine: '기계를 선택하세요',
    tag_names: {
      dxf: 'DXF 개체',
      ellipse: '타원형',
      g: '그룹',
      image: '이미지',
      line: '라인',
      multi_select: '여러 개체',
      no_selection: '선택 없음',
      pass_through_object: '객체 통과',
      path: '경로',
      polygon: '다각형',
      rect: '직사각형',
      svg: 'SVG 개체',
      text: '텍스트',
      text_path: '텍스트 경로',
      use: '가져온 개체',
    },
    task_preview: '경로 미리보기',
    titles: {
      settings: '환경설정',
    },
    untitled: '언타이틀',
  },
  topmenu: {
    credit: 'Beam Studio는 <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> 오픈 소스 프로젝트와 다른 <a target="_blank" href="https://flux3dp.com/credits/">오픈 소스 소프트웨어</a>에 의해 가능하게 되었습니다.',
    device: {
      download_log_canceled: '로그 다운로드 취소',
      download_log_error: '알 수 없는 오류가 발생했습니다. 나중에 다시 시도해 주세요',
      log: {
        usblist: 'USB 목록',
      },
      network_test: '네트워크 테스트',
    },
    file: {
      all_files: '모든 파일',
      clear_recent: '최근 열린 항목 지우기',
      converting: '이미지로 변환 중...',
      fcode_files: 'FLUX 코드',
      import: '가져오기',
      jpg_files: 'JPG',
      label: '파일',
      path_not_exit: '이 경로는 디스크에 더 이상 존재하지 않는 것 같습니다.',
      png_files: 'PNG',
      save_fcode: 'FLUX 작업 내보내기',
      save_jpg: 'JPG 내보내기',
      save_png: 'PNG 내보내기',
      save_scene: '장면 저장',
      save_svg: 'SVG 내보내기',
      scene_files: 'Beam Studio 장면',
      svg_files: 'SVG',
    },
    ok: '확인',
    version: '버전',
  },
  tutorial: {
    ask_retry_calibration: '카메라를 다시 보정하시겠습니까?',
    camera_calibration_failed: '카메라 보정 실패',
    gesture: {
      click: '객체를 선택하려면 탭하세요.',
      drag: '여러 객체를 선택하려면 드래그하세요.',
      hold: '컨텍스트 메뉴를 열려면 누르고 누르고 있습니다.',
      pan: '두 손가락으로 캔버스를 스크롤합니다.',
      zoom: '두 손가락으로 핀치하여 캔버스를 확대/축소합니다.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: '튜토리얼을 위해 기기를 찾는 중입니다...',
    needNewInterfaceTutorial: 'Beam Studio의 새 인터페이스 자습서를 시작하시겠습니까?<br/>(이제 이것을 건너뛰고 "[도움말] > [인터페이스 소개 표시]"를 클릭하여 나중에 시작할 수 있습니다.)',
    needNewUserTutorial: '자습서를 시작하시겠습니까?<br/>(이제 이것을 건너뛰고 "[도움말] > [시작 자습서 표시]"를 클릭하여 나중에 시작할 수 있습니다.)',
    newInterface: {
      add_new_layer: '새 레이어 추가',
      align_controls: '정렬 제어',
      basic_shapes: '기본 도형',
      camera_preview: '카메라 미리보기',
      drag_to_sort: '드래그하여 정렬',
      end_alert: '새 UI 소개를 종료하시겠습니까?',
      flip: '뒤집기',
      group_controls: '그룹 제어',
      layer_controls: '레이어 제어를 선택하려면 마우스 오른쪽 버튼을 클릭하세요: 레이어 복제 / 병합 / 잠금 / 레이어 삭제',
      object_actions: '객체 동작',
      pen_tool: '펜 도구',
      rename_by_double_click: '더블 클릭하여 이름 바꾸기',
      select_image_text: '선택 / 이미지 / 텍스트',
      select_machine: '기계를 선택하세요',
      shape_operation: '도형 조작',
      start_work: '작업 시작',
      switch_between_layer_panel_and_object_panel: '레이어 패널과 객체 패널 사이 전환',
    },
    newUser: {
      add_new_layer: '새 레이어 추가',
      adjust_focus: '2. 초점 조정',
      close_cover: '3. 커버를 닫으세요',
      drag_to_draw: '그리기 위해 드래그',
      draw_a_circle: '원 그리기',
      draw_a_rect: '사각형 그리기',
      end_alert: '정말 튜토리얼을 종료하시겠습니까?',
      end_preview_mode: '미리보기 모드 종료',
      infill: '인피룰 켜기',
      please_select_wood_cutting: '목재 - 절단 사전 설정을 선택해주세요.',
      please_select_wood_engraving: '목재 - 각인 사전 설정을 선택해주세요.',
      preview_the_platform: '플랫폼 미리보기',
      put_wood: '1. 샘플 목재를 넣으세요',
      send_the_file: '파일 보내기',
      set_preset_wood_cut: '사전 설정: 목재 - 절단',
      set_preset_wood_engraving: '사전 설정: 목재 - 각인',
      switch_to_layer_panel: '레이어 패널로 전환',
      switch_to_object_panel: '객체 패널로 전환',
      switch_to_preview_mode: '미리보기 모드로 전환',
    },
    next: '다음',
    retry: '재시도',
    set_connection: '연결 설정',
    skip: '건너뛰기',
    skip_tutorial: '튜토리얼을 건너뛰셨습니다. 언제든지 "도움말" > "튜토리얼 시작"을 클릭하여 튜토리얼을 다시 시작할 수 있습니다.',
    suggest_calibrate_camera_first: '사용자들에게 최적의 결과를 위해 각 미리보기 전에 카메라를 초기에 보정하고 다시 초점을 맞출 것을 권장합니다.<br/>지금 보정을 수행할까요?<br/>(이제 이것을 건너뛰고 "[메뉴] > [기계] > [귀하의 기계] > "카메라 보정"을 클릭하여 나중에 수행할 수 있습니다.)',
    tutorial_complete: '튜토리얼은 여기까지입니다. 이제 창작의 시간입니다!',
    unable_to_find_machine: '튜토리얼을 위한 기기를 찾지 못했습니다. 연결 설정 페이지로 이동하거나 다시 시도하거나 튜토리얼을 건너뛰시겠습니까?',
    welcome: '환영합니다.',
  },
  update: {
    cannot_reach_internet: '#823 서버에 연결할 수 없습니다. 인터넷 연결을 확인해 주세요',
    download: '온라인 업데이트',
    firmware: {
      caption: '기계에 펌웨어 업데이트가 가능합니다',
      confirm: '업로드',
      firmware_too_old_update_by_sdcard: '펌웨어 버전이 너무 오래되었습니다. SD 카드를 사용하여 펌웨어를 업데이트하십시오.',
      force_update_message: '#814 기계를 최신 펌웨어 버전으로 업데이트하십시오.',
      latest_firmware: {
        cant_get_latest: '최신 펌웨어 정보를 가져올 수 없습니다.',
        caption: '기계 펌웨어 업데이트',
        message: '최신 기계 펌웨어를 사용 중입니다',
        still_update: '업데이트',
      },
      message_pattern_1: '%s이(가) 펌웨어 업데이트를 위해 준비되었습니다.',
      message_pattern_2: '%s 펌웨어 v%s이(가) 사용 가능합니다 - 현재 버전은 v%s입니다.',
      too_old_for_web: '현재 기계 펌웨어 버전은 v%s입니다. Beam Studio 온라인 버전을 사용하려면 기계 펌웨어를 최신 버전으로 업데이트해 주세요.',
      update_fail: '#822 업데이트 실패',
      update_success: '펌웨어 업데이트가 성공적으로 업로드되었습니다',
      upload_file: '펌웨어 업로드 (*.bin / *.fxfw)',
    },
    install: '설치',
    later: '나중에',
    preparing: '준비 중...',
    release_note: '릴리스 노트:',
    skip: '이 버전 건너뛰기',
    software: {
      available_switch: 'Beam Studio v%s이(가) 이제 사용 가능합니다. 현재 버전은 v%s입니다. 이 버전으로 전환하시겠습니까?',
      available_update: 'Beam Studio v%s이(가) 이제 사용 가능합니다. 현재 버전은 v%s입니다. 업데이트를 다운로드하시겠습니까?',
      caption: 'Beam Studio에 소프트웨어 업데이트가 있습니다',
      check_update: '업데이트 확인',
      checking: '업데이트 확인 중',
      downloading: '백그라운드에서 업데이트를 다운로드하고 있습니다. 계속 작업하려면 "확인"을 클릭하세요.',
      install_or_not: '업데이트를 위해 준비되었습니다. 지금 다시 시작하고 업데이트하시겠습니까?',
      no: '아니요',
      no_response: '서버에 연결하지 못했습니다. 네트워크 설정을 확인해 주세요.',
      not_found: '최신 버전의 Beam Studio를 사용 중입니다.',
      switch_or_not: '버전 전환을 위해 준비되었습니다. 지금 다시 시작하고 버전을 전환하시겠습니까?',
      switch_version: '버전 전환',
      switch_version_not_found: '전환 가능한 버전을 찾을 수 없습니다.',
      update_for_ador: '현재 소프트웨어 버전 %s가 오래되었습니다. Ador용 Beam Studio의 최신 버전을 다운로드하세요.',
      yes: '예',
    },
    update: '업데이트',
    updating: '업데이트 중...',
    upload: '업로드',
  },
  web_cam: {
    no_device: '카메라 장치를 감지할 수 없습니다. 카메라를 다시 연결하고 다시 시도하십시오.',
    no_permission: 'Beam Studio는 카메라에 액세스할 권한이 없습니다. 브라우저 설정 또는 시스템 설정에서 Beam Studio에 권한이 부여되었는지 확인하십시오.',
  },
  z_speed_limit_test: {
    alert_before: '곡선 조각을 시작하기 전에 시스템이 하중 테스트를 수행하여 과도한 무게로 인한 스텝 손실을 방지합니다.',
    alert_failed: '현재 객체의 무게가 현재 Z축 속도의 하중 한계를 초과했습니다. 기계 패널에서 Z축 속도를 줄이거나 조각할 객체를 교체한 후 다시 테스트하세요.',
    ignore: '무시',
    retest: '재테스트',
    testing: '부하 테스트 수행 중...',
  },
};

export default lang;
