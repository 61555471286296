import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: '放弃',
    cancel: '取消',
    caption: '错误',
    close: '关闭',
    confirm: '确认',
    dont_save: '不要储存',
    dont_show_again: '不再显示',
    duplicated_preset_name: '重复的默认名称',
    error: '错误',
    info: '消息',
    instruction: '提示',
    learn_more: '了解更多',
    no: '否',
    ok: '确定',
    ok2: '好',
    oops: '哎呀',
    retry: '重试',
    save: '储存',
    stop: '停止',
    warning: '警告',
    yes: ' 是',
  },
  auto_fit: {
    artwork_size: '作品大小',
    error_tip1: '1. 物体是否正确放置在材料上？',
    error_tip2: '2. 材料的轮廓是否足够清晰以便识别？',
    failed_to_find_contour: '未能找到用于自动适配的轮廓',
    learn_more: '查看教程。',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: '偏移 X',
    offset_y: '偏移 Y',
    pattern_size: '图案大小',
    position_artwork: '放置作品',
    position_step1: '调整作品在图案上的大小和位置。',
    position_step2: '点击“套用”以将自动适配应用于此图案。',
    preview_first: '请先进行相机预览。',
    reset_position: '重置位置',
    rotation: '旋转',
    select_a_pattern: '选择图案',
    selected_artwork: '已选作品',
    step1: '选择您希望放置作品的图案。',
    step2: '如果未找到图案，请再次预览并应用自动适配。',
    step3: '如果仍然无法找到正确的图案，请确保您的工件清晰可识别，并且工作区域干净无杂物。',
    title: '自动适配',
  },
  barcode_generator: {
    bar_height: '条高',
    bar_width: '条宽',
    barcode: {
      invalid_value: '该值对于所选格式无效。',
    },
    font: '字体',
    font_size: '字体大小',
    hide_text: '隐藏文本',
    invert_color: '反转颜色',
    text_margin: '文本边距',
  },
  beambox: {
    announcement_panel: {
      title: '公告',
    },
    banner: {
      auto_feeder: '自动送料模式',
      camera_preview: '预览',
      camera_preview_borderless_mode: '(打开底部)',
      curve_engraving: '曲面雕刻模式',
      pass_through: '广域雕刻模式',
      rotary: '旋转轴模式',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: '部分字体在不同系统间有差异，输出前请将字体转换成路径，以确保文本正确显示。转换文本至路径中...',
      export_file_error_ask_for_upload: '汇出工作时发生错误，是否愿意上传工作场景回报错误给开发团队？',
      retreive_image_data: '撷取影像资料中...',
    },
    context_menu: {
      copy: '复制',
      cut: '剪切',
      delete: '删除',
      duplicate: '重製',
      group: '组合',
      move_back: '移至底部',
      move_down: '向下移动',
      move_front: '移至顶部',
      move_up: '向上移动',
      paste: '粘贴',
      paste_in_place: '粘贴到原位置',
      ungroup: '取消组合元素',
    },
    document_panel: {
      add_on: '扩充模组',
      auto_feeder: '自动送料',
      auto_feeder_scale: '自动送料倍率',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: '开盖模式',
      current_position: '当前的位置',
      disable: '关闭',
      document_settings: '文件设定',
      enable: '啟用',
      enable_autofocus: '自动对焦',
      enable_diode: '二极管激光',
      engrave_dpi: '雕刻分辨率',
      frame_before_start: '先预览后执行',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: '高',
      job_origin: '工作起点',
      laser_source: '激光源',
      low: '低',
      machine: '机器',
      medium: '中',
      notification: {
        changeFromPrintingWorkareaTitle: '您要将打印图层转换至雷射图层吗？',
      },
      origin: '原点',
      pass_through: '广域雕刻',
      pass_through_height_desc: '输入物体长度以扩展工作区域。',
      rotary_mode: '旋转轴',
      scale: '倍率',
      start_from: '开始位置',
      start_position: '开始位置',
      start_work_button: '开始工作按钮',
      ultra: '极高',
      workarea: '工作范围',
    },
    image_trace_panel: {
      back: '上一步',
      brightness: '曝光',
      cancel: '取消',
      contrast: '对比',
      next: '下一步',
      okay: '完成',
      threshold: '临界值',
      tuning: '描图参数',
    },
    layer_color_config_panel: {
      add: '新增',
      add_config: '新增颜色',
      color: '颜色',
      default: '回复预设',
      in_use: '此颜色已在使用中。',
      layer_color_config: '图层颜色参数设定',
      no_input: '请输入颜色色码。',
      power: '功率',
      repeat: '执行次数',
      speed: '速度',
      sure_to_delete: '确定要删除这项颜色参数。',
      sure_to_reset: '您将会失去所有自订颜色参数，确定要回复到预设值？',
    },
    left_panel: {
      borderless_blind_area: '非雕刻范围',
      borderless_preview: '开盖模式相机预览',
      diode_blind_area: '非雕刻范围',
      ellipse: '椭圆形',
      image: '图片',
      label: {
        adjust_height: '调整高度',
        array: '阵列',
        boxgen: 'Boxgen',
        choose_camera: '相机预览',
        clear_preview: '清除预览',
        cursor: '选取',
        curve_engraving: {
          clear_area: '清除选定区域',
          exit: '退出',
          preview_3d_curve: '预览曲面雕刻',
          select_area: '选择区域',
          title: '曲面雕刻',
        },
        end_preview: '结束预览',
        line: '线段',
        live_feed: '实时视频',
        my_cloud: '我的云端',
        oval: '椭圆',
        pass_through: '广域雕刻',
        pen: '钢笔',
        photo: '图片',
        polygon: '多边形',
        preview: '相机预览',
        qr_code: '二维码',
        rect: '方块',
        shapes: '元素',
        text: '文字',
        trace: '影像描图',
      },
      line: '线段',
      rectangle: '长方形',
      text: '文本',
      unpreviewable_area: '非相机预览范围',
    },
    network_testing_panel: {
      average_response: '平均回覆时间',
      cannot_connect_1: '#840 无法与目标 IP 建立连线',
      cannot_connect_2: '#840 无法与目标 IP 建立连线，请确认是否与目标 IP 在同一网路',
      cannot_get_local: '无法取得本地 IP 位置',
      connection_quality: '连接质量',
      device_not_on_list: '#842 列表上看不到机器，连接质量 > 70 且平均回覆时间 < 100ms',
      empty_ip: '#818 请先输入目标 IP 位置',
      end: '結束',
      fail_to_start_network_test: '#817 無法開始网路检测。',
      hint_connect_camera_timeout: '启动相机预览时超时？',
      hint_connect_failed_when_sending_job: '送出工作时出现无法连接？',
      hint_device_often_on_list: '列表上经常找不到机器？',
      insert_ip: '目标 IP 位置：',
      invalid_ip: '#818 错误的 IP 位置',
      ip_startswith_169: '#843 目标 IP 开头为 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: '此问题通常是因为权限不足而发生，请在终端机以 "sudo beam-studio --no-sandbox" 以获得权​​限进行网路检测。',
      local_ip: '本机 IP 位置：',
      network_testing: '网路检测',
      network_unhealthy: '#841 连接质量 < 70 或平均回覆时间 > 100ms',
      start: '检测',
      test_completed: '检测完成',
      test_fail: '检测失敗',
      testing: '网路检测中...',
    },
    object_panels: {
      lock_desc: '缩放时固定比例 (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: '转换文字时出现字体不支援的情形，请在送出工作前，再次检查预览图确认文字是否如预期转换。',
        error_when_parsing_text: '将文本转换为路径时出错',
        font_substitute_pop: '您的文本包含当前字体不支持的字符。<br/>您想使用『%s』来替换吗?',
        retry: '请稍后重试或选择其他字体',
        use_current_font: '使用当前字体',
      },
      wait_for_parsing_font: '解析字体中...',
    },
    path_preview: {
      current_position: '当下位置',
      cut_distance: '切割距离',
      cut_time: '切割时间',
      end_preview: '结束预览',
      estimated_time: '预估总时间',
      invert: '色彩反转',
      pause: '暫停',
      play: '播放',
      play_speed: '播放速度',
      preview_info: '预览资讯',
      rapid_distance: '快速移动距离',
      rapid_time: '快速移动时间',
      remark: '*所有资讯皆为预估数值，仅供参考。',
      size: '尺寸',
      start_here: '从这里开始',
      stop: '停止',
      travel_path: '运行轨迹',
    },
    photo_edit_panel: {
      aspect_ratio: '长宽比',
      brightness: '亮度',
      brightness_and_contrast: '亮度对比',
      cancel: '取消',
      circumference: '周长',
      compare: '原图比较',
      contrast: '对比',
      crop: '裁剪',
      curve: '曲线',
      diameter: '直径',
      free: '任意',
      invert: '色彩反转',
      okay: '完成',
      original: '原图',
      phote_edit: '影像编辑',
      processing: '处理中',
      radius: '鋭化半径',
      rotary_warped: '旋转扭曲',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: '锐化',
      sharpness: '鋭化强度',
      start: '开始',
      warp: '扭曲',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: '前往',
        insufficient_credit: '您的 Credit 已用完',
        insufficient_credit_msg: '您无法使用%s，请前往会员中心充值 AI Credit。',
        relogin_to_use: '请重新登录以使用此功能。',
      },
      auto_feeder_origin: '请使用框架预览检查激光头的路径和区域，然后根据结果调整材料放置或雕刻起始位置，以避免雕刻过程中发生碰撞。',
      auto_switch_tab: {
        message: '已在首选项中添加了在图层面板和对象面板之间自动切换的新选项。此选项默认情况下处于禁用状态。是否要现在启用自动切换？ <br/>您可以随时在首选项中更改此设置。',
        title: '自动切换图层和物件面板',
      },
      backend_connect_failed_ask_to_upload: '#802 连接后端程式时持续发生错误，请问您是否要将错误报告上传到云端?',
      backend_error_hint: '后端程式无法连接，部分功能可能无法使用。',
      both_power_and_speed_too_high: '激光管在高功率下耗损较快，使用低功率可以延长雷试管使用寿命。\n同时在此雕刻分辨率使用过高速度，可能导致渐层雕刻較差品质。',
      bug_report: '错误回报',
      change_workarea_before_preview: '%s 的工作范围与目前设定的工作范围不相符，是否要切换目前的工作范围？',
      convert_to_path_fail: '转换成路径失败。',
      dxf_bounding_box_size_over: '图像超出工作范围，请在 CAD 软体中将图像放置于原点附近，或确定图档单位是否正确设定。',
      dxf_version_waring: '此 DXF 档版本非 2013 版，可能有潜在的不相容风险。',
      facebook_group_invitation: {
        already_joined: '已加入用户社群',
        join_now: '立马加入',
        later: '稍后加入',
        message: '即刻加入 FLUX 官方用户社群，与广大 FLUX 用户分享使用心得、展示雷雕作品以及获取最新的产品资讯。',
        title: '加入 FLUX 官方用户社群',
      },
      import_file_contain_invalid_path: '#808 汇入的SVG档案中含有不存在的图片路径，请确认档案中所有连结之图片皆存在，或改将图片嵌入档案中。',
      import_file_error_ask_for_upload: '读取 SVG 档时发生错误，是否愿意上传档案回报错误给开发团队？',
      layer_by_color: '依颜色分层',
      layer_by_layer: '依图层分层',
      loading_image: '载入图片中，请稍候...',
      more_than_two_object: '太多物件，只支援两物件操作',
      mouse: '鼠标',
      no_support_text: 'Beam Studio 目前不支持由外部导入文本标签，请由矢量绘图软件将文本转成路径后再导入。',
      nolayer: '不分层',
      not_support_object_type: '不支援的物件类型',
      or_turn_off_borderless_mode: '或是关闭开盖模式',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 无法找到 pdf2svg 指令，请使用您的套件管理装置安装 pdf2svg（e.g., "yum install pdf2svg" or "apt-get install pdf2svg"）。',
        error_when_converting_pdf: '#824 将 PDF 转换成 SVG 时发生错误：',
      },
      progress: {
        calculating: '计算中',
        uploading: '上传中',
      },
      questionnaire: {
        caption: '协助我们填写问卷',
        message: '协助我们填写问券，让产品变得更好。',
        no_questionnaire_available: '目前没有可供填写的问卷。',
        unable_to_get_url: '无法透过网路取得目前最新问卷的连结，请确认您的网路连线状况。',
      },
      recommend_downgrade_software: '检测到较旧的固件版本。我们正在积极解决兼容性问题，但目前建议您回退到 <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>。',
      recommend_upgrade_firmware: '我们检测到较旧的固件版本。我们正在积极解决兼容性问题，但目前建议更新到最新的固件。',
      save_unsave_changed: '请问是否要储存未储存的变更，否则变更将会遗失？',
      select_at_least_two: '请选取两个物件以继续',
      select_first: '请先选取物件以继续',
      select_import_method: '选择分层方式:',
      select_import_module: '选择模块：',
      sentry: {
        message: '请问您是否同意在遇到错误时将相关资讯自动上传给开发团队？',
        title: '我们一起让 Beam Studio 变得更好',
      },
      should_update_firmware_to_continue: '#814 您的固件版本不支持最新的软件改善。为了更良好的使用经验与雕刻品质，请先更新手机切膜机的固件以继续。 (主菜单 > 机器 > [ Your手机切膜机] > 固件更新)',
      speed_too_high_lower_the_quality: '在此雕刻分辨率使用过高速度，可能导致渐层雕刻較差品质。',
      still_continue: '继续',
      successfully_uploaded: '档案已成功上传。',
      svg_1_1_waring: '此档案标示之 SVG 版本为 1.1 版，可能有潜在的不相容风险。',
      svg_image_path_waring: '此档案内含有以路径读取的图片，可能会有读取失败的风险。请在做图汇出 SVG 时，当点阵图相关选项改成嵌入。',
      text_to_path: {
        caption: '文字转路径 2.0',
        message: 'Beam Studio 现在推出全新的文字转路径2.0，为您带来更稳定的使用体验！您是否要立即切换？\n您也可以稍后于偏好设定中的「文字转路径」修改此设定。 ',
      },
      too_fast_for_auto_feeder: '在包含路径对象的图层中使用过高的速度可能会降低切割精度。\n我们不建议在使用自动送料器切割时使用超过%(limit)s的速度。',
      too_fast_for_auto_feeder_and_constrain: '以下图层：%(layers)s \n包含矢量路径对象，并且速度超过%(limit)s。\n使用自动送料器切割矢量路径对象的速度将限制为%(limit)s。\n您可以在首选项设置中删除此限制。',
      too_fast_for_curve: '对包含曲面雕刻对象的图层使用过高速度可能会降低雕刻精度。\n建议将速度保持在%(limit)s以下，以获得曲面雕刻对象的最佳效果。',
      too_fast_for_curve_and_constrain: '以下图层: %(layers)s 超过了速度限制 %(limit)s。\n曲面雕刻对象的雕刻速度将被限制为 %(limit)s。您可以在首选项设置中移除此限制。',
      too_fast_for_path: '含有路径物件的图层速度过快，可能导致切割时位置误差。\n不建议超过在切割路径时超过 %(limit)s。',
      too_fast_for_path_and_constrain: '以下图层： %(layers)s \n含有向量路径物件且速度超过 %(limit)s，为维持雕刻的精度，向量路径速度将被限制在 %(limit)s，您可以在偏好设定解除此限制。',
      touchpad: '触摸板',
      ungroup_use: '正要解散外部汇入的 DXF 或是 SVG ，在含有物件较多的情况，可能会需要等一阵子，是否确定解散？',
      upload_failed: '#819 档案上传失败。',
      upload_file_too_large: '#819 档案大小过大，请联络客服。',
      vectorize_shading_image: '渐层影像在向量化时将花费较多时间，且容易有杂点，请将影像渐层关闭后再执行。',
    },
    rating_panel: {
      description: '如果您喜欢 Beam Studio，请给我们评分，我们将不胜感激。',
      thank_you: '谢谢您',
      title: '喜欢 Beam Studio 吗？',
    },
    right_panel: {
      laser_panel: {
        advanced: '高级設置',
        backlash: '背隙调整',
        bi_directional: '双向',
        by: '',
        ce_z_high_speed: '高速 Z 轴',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: '色版调整',
        color_adjustment_short: '色版',
        color_strength: '强度',
        cross_hatch: '交叉填充',
        custom_preset: '自订',
        cut: '切割',
        diode: '二极体雷射',
        do_not_adjust_default_para: '无法调整预设参数。',
        dottingTime: '打点时间',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - 刻印',
            acrylic_3mm_cutting: '压克力 - 1/8″ 切割',
            acrylic_5mm_cutting: '压克力 - 3/16″ 切割',
            acrylic_8mm_cutting: '压克力 - 1/4″ 切割',
            acrylic_10mm_cutting: '压克力 - 3/8″ 切割',
            acrylic_engraving: '压克力 - 刻印',
            acrylic_printing: '亚克力 - 打印',
            aluminum_engraving: '铝 - 刻印',
            aluminum_light: '铝 (浅)',
            bamboo_printing: '竹子 - 打印',
            black_abs: '黑色 ABS',
            black_acrylic_3mm_cutting: '黑色压克力 - 1/8″ 切割',
            black_acrylic_5mm_cutting: '黑色压克力 - 3/16″ 切割',
            black_acrylic_engraving: '黑色压克力 - 刻印',
            brass_dark: '黄铜 (深)',
            brass_engraving: '黄铜 - 刻印',
            brass_light: '黄铜 (浅)',
            canvas_printing: '画布 - 打印',
            cardstock_printing: '卡纸 - 打印',
            copper: '红铜',
            cork_printing: '软木 - 打印',
            denim_1mm_cutting: '牛仔布 - 1/32″ 切割',
            fabric_3mm_cutting: '布料 - 1/8″ 切割',
            fabric_5mm_cutting: '布料 - 3/16″ 切割',
            fabric_engraving: '布料 - 刻印',
            fabric_printing: '布料 - 打印',
            flat_stone_printing: '石板 - 打印',
            glass_bw_engraving: '玻璃 - 刻印',
            glass_printing: '玻璃 - 打印',
            gloss_leather_printing: '亮面皮革 - 打印',
            gold_engraving: '金 - 刻印',
            iron_engraving: '铁 - 刻印',
            leather_3mm_cutting: '皮革 - 1/8″ 切割',
            leather_5mm_cutting: '皮革 - 3/16″ 切割',
            leather_engraving: '皮革 - 刻印',
            mdf_3mm_cutting: '新西兰密迪板 - 1/8″ 切割',
            mdf_5mm_cutting: '新西兰密迪板 - 3/16″ 切割',
            mdf_engraving: '新西兰密迪板 - 刻印',
            metal_bw_engraving: '不锈钢 - 刻印',
            opaque_acrylic: '不透明亚克力',
            pc_printing: 'PC - 打印',
            rubber_bw_engraving: '印章垫 - 刻印',
            silver_engraving: '银 - 刻印',
            stainless_steel_bw_engraving_diode: '不锈钢 - 刻印（二极体雷射）',
            stainless_steel_dark: '不锈钢 (深)',
            stainless_steel_engraving: '不锈钢 - 刻印',
            stainless_steel_light: '不锈钢 (浅)',
            stainless_steel_printing: '不锈钢 - 打印',
            steel_engraving_spray_engraving: '不锈钢喷剂 - 刻印',
            stone: '石头',
            ti_engraving: '钛 - 刻印',
            titanium_dark: '钛 (深)',
            titanium_light: '钛 (浅)',
            white_abs: '白色 ABS',
            wood_3mm_cutting: '木板 - 1/8″ 切割',
            wood_5mm_cutting: '木板 - 3/16″ 切割',
            wood_7mm_cutting: '木板 - 1/4″ 切割',
            wood_8mm_cutting: '木板 - 1/4″ 切割',
            wood_10mm_cutting: '木板 - 3/8″ 切割',
            wood_engraving: '木板 - 刻印',
            wood_printing: '木材 - 打印',
          },
          mm: {
            abs_engraving: 'ABS - 刻印',
            acrylic_3mm_cutting: '压克力 - 3mm 切割',
            acrylic_5mm_cutting: '压克力 - 5mm 切割',
            acrylic_8mm_cutting: '压克力 - 8mm 切割',
            acrylic_10mm_cutting: '压克力 - 10mm 切割',
            acrylic_engraving: '压克力 - 刻印',
            acrylic_printing: '亚克力 - 打印',
            aluminum_engraving: '铝 - 刻印',
            aluminum_light: '铝 (浅)',
            bamboo_printing: '竹子 - 打印',
            black_abs: '黑色 ABS',
            black_acrylic_3mm_cutting: '黑色压克力 - 3mm 切割',
            black_acrylic_5mm_cutting: '黑色压克力 - 5mm 切割',
            black_acrylic_engraving: '黑色压克力 - 刻印',
            brass_dark: '黄铜 (深)',
            brass_engraving: '黄铜 - 刻印',
            brass_light: '黄铜 (浅)',
            canvas_printing: '画布 - 打印',
            cardstock_printing: '卡纸 - 打印',
            copper: '红铜',
            cork_printing: '软木 - 打印',
            denim_1mm_cutting: '牛仔布 - 1mm 切割',
            fabric_3mm_cutting: '布料 - 3mm 切割',
            fabric_5mm_cutting: '布料 - 5mm 切割',
            fabric_engraving: '布料 - 刻印',
            fabric_printing: '布料 - 打印',
            flat_stone_printing: '石板 - 打印',
            glass_bw_engraving: '玻璃 - 刻印',
            glass_printing: '玻璃 - 打印',
            gloss_leather_printing: '亮面皮革 - 打印',
            gold_engraving: '金 - 刻印',
            iron_engraving: '铁 - 刻印',
            leather_3mm_cutting: '皮革 - 3mm 切割',
            leather_5mm_cutting: '皮革 - 5mm 切割',
            leather_engraving: '皮革 - 刻印',
            mdf_3mm_cutting: '新西兰密迪板 - 3 mm 切割',
            mdf_5mm_cutting: '新西兰密迪板 - 5 mm 切割',
            mdf_engraving: '新西兰密迪板 - 刻印',
            metal_bw_engraving: '不锈钢 - 刻印',
            opaque_acrylic: '不透明亚克力',
            pc_printing: 'PC - 打印',
            rubber_bw_engraving: '印章垫 - 刻印',
            silver_engraving: '银 - 刻印',
            stainless_steel_bw_engraving_diode: '不锈钢 - 刻印（二极体雷射）',
            stainless_steel_dark: '不锈钢 (深)',
            stainless_steel_engraving: '不锈钢 - 刻印',
            stainless_steel_light: '不锈钢 (浅)',
            stainless_steel_printing: '不锈钢 - 打印',
            steel_engraving_spray_engraving: '不锈钢喷剂 - 刻印',
            stone: '石头',
            ti_engraving: '钛 - 刻印',
            titanium_dark: '钛 (深)',
            titanium_light: '钛 (浅)',
            white_abs: '白色 ABS',
            wood_3mm_cutting: '木板 - 3mm 切割',
            wood_5mm_cutting: '木板 - 5mm 切割',
            wood_7mm_cutting: '木板 - 7mm 切割',
            wood_8mm_cutting: '木板 - 8mm 切割',
            wood_10mm_cutting: '木板 - 10mm 切割',
            wood_engraving: '木板 - 刻印',
            wood_printing: '木材 - 打印',
          },
          parameters: '选择参数',
          save: '新增目前参数',
        },
        engrave: '雕刻',
        existing_name: '已存在此名称的自订参数。',
        fill_angle: '填充间隔',
        fill_interval: '填充间隔',
        fill_setting: '填充设置',
        filled_path_only: '仅适用于填充路径',
        focus_adjustment: '对焦调整',
        frequency: '频率',
        gradient_only: '仅适用于渐变图片',
        halftone: '半色调',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: '物件高度',
        ink_saturation: '饱和度',
        ink_type: {
          normal: '一般墨',
          text: '墨水类型',
          UV: 'UV 墨',
        },
        laser_speed: {
          fast: '快',
          max: 300,
          min: 1,
          slow: '慢',
          step: 0.1,
          text: '速度',
          unit: 'mm/s',
        },
        low_power_warning: '功率较低时（低于 10 %），有可能不出光。',
        low_speed_warning: '低速度下可能会导致材料燃烧。',
        lower_focus: '降低焦距',
        lower_focus_desc: '对焦后通过特定的距离降低焦距高度，以提高切割性能。',
        module: '模块',
        more: '管理',
        multi_layer: '多个图层',
        para_in_use: '此参数已在使用中。',
        parameters: '選擇參數',
        power: {
          high: '强',
          low: '弱',
          max: 100,
          min: 1,
          step: 0.1,
          text: '功率',
        },
        preset_management: {
          add_new: '添加新参数',
          delete: '删除',
          export: '汇出参数',
          export_preset_title: '汇出参数',
          import: '汇入参数',
          laser: '激光',
          lower_focus_by: '降低焦点',
          new_preset_name: '新参数名称',
          preset: '预设',
          print: '打印',
          reset: '恢复预设',
          save_and_exit: '保存并退出',
          show_all: '显示全部',
          sure_to_import_presets: '将要读取预设参数的排序与使用状况，并覆盖自订参数，确定要继续进行吗？',
          sure_to_reset: '这将删除您的自定义参数并重置所有预设，您确定要继续吗？',
          title: '管理参数',
          wobble_diameter: '摆动直径',
          wobble_step: '摆动步距',
        },
        preset_setting: '参数调整（%s）',
        presets: '预设',
        print_multipass: '多重打印',
        promark_speed_desc: '速度参数不适用于渐层图像。',
        pulse_width: '脉冲宽度',
        pwm_advanced_desc: '设置深度模式的最小功率。',
        pwm_advanced_hint: '此设置专门用于深度模式下的渐变图像。',
        pwm_advanced_setting: '深度模式功率设置',
        repeat: '运行次数',
        single_color: '单色',
        single_color_desc: '仅适用于全色层，不能用于扩展的单色层。',
        slider: {
          fast: '快',
          high: '高',
          low: '低',
          regular: '普通',
          slow: '慢',
          very_fast: '最快',
          very_high: '最高',
          very_low: '最低',
          very_slow: '最慢',
        },
        speed: '速度',
        speed_constrain_warning: '矢量路径速度将被限制在 %(limit)s，您可以在偏好设定解除此限制。',
        speed_constrain_warning_auto_feeder: '自动送料物件的切割速度將限制為%(limit)s。您可以在偏好設定中移除此限制。',
        speed_constrain_warning_curve_engraving: '曲面雕刻的速度将被限制为%(limit)s。您可以在偏好设置中移除此限制。',
        stepwise_focusing: '逐步对焦',
        stepwise_focusing_desc: '根据每次运行次数，在对象高度的基础上逐步降低焦距。',
        strength: '功率',
        times: '次',
        various_preset: '多个参数',
        white_ink: '白墨',
        white_ink_settings: '白墨设置',
        wobble: '抖动',
        wobble_desc: '沿路径生成小圆圈，有助于切割任务。（仅适用于路径对象。）',
        wobble_diameter: '直径',
        wobble_step: '步距',
        z_step: '每次递降',
      },
      layer_panel: {
        current_layer: '目前图层',
        layer1: '默认图层',
        layer_bitmap: '位图层',
        layer_cutting: '切割图层',
        layer_engraving: '雕刻图层',
        layers: {
          del: '删除图层',
          dupe: '复制图层',
          fullColor: '全彩',
          layer: '图层',
          layers: '图层',
          lock: '锁定图层',
          merge_all: '全部合并',
          merge_down: '向下合并',
          merge_selected: '合并选取图层',
          move_down: '向下移动图层',
          move_elems_to: '移动元素至:',
          move_selected: '移动元素至另一个图层',
          move_up: '向上移动图层',
          new: '新建图层',
          rename: '重命名图层',
          splitFullColor: '展开图层',
          switchToFullColor: '切换到全彩图层',
          switchToSingleColor: '切换到单色图层',
          unlock: '解锁',
        },
        move_elems_to: '移动到：',
        notification: {
          dupeLayerName: '图层名称与现有图层重复，请使用别的名称。',
          enterNewLayerName: '请输入新的图层名称',
          enterUniqueLayerName: '请输入一个唯一的图层名称',
          layerHasThatName: '图层已经采用了该名称',
          mergeLaserLayerToPrintingLayerMsg: '请注意，如果您继续此操作，激光图层的设置将会被删除，并根据当前图层参数而定。',
          mergeLaserLayerToPrintingLayerTitle: '您是否要将多个图层合并为一个打印图层？',
          mergePrintingLayerToLaserLayerMsg: '请注意，如果您继续此操作，打印图层的颜色设置将会被删除，并根据当前图层参数而定。',
          mergePrintingLayerToLaserLayerTitle: '您是否要将多个图层合并为一个激光图层？',
          moveElemFromPrintingLayerMsg: '请注意，如果您继续此操作，选取物件的颜色设置将会被删除，并根据 %s 参数而定。',
          moveElemFromPrintingLayerTitle: '您是否要移动选取物件至 %s 并转换为激光物件？',
          moveElemToPrintingLayerMsg: '请注意，如果您继续此操作，选取物件的设置将会被删除，并根据 %s 参数而定。',
          moveElemToPrintingLayerTitle: '您是否要移动选取物件至 %s 并转换为打印物件？',
          newName: '新图层名称',
          QmoveElemsToLayer: "您确定移动所选元素到图层'%s'吗?",
          splitColorMsg: '请注意，如果您完成此操作，展开的图层将无法重新合并。',
          splitColorTitle: '您是否要将所选图层展开为 CMYK 图层？',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: '智能去背',
          ai_bg_removal_reminder: '按下按钮将立即使用 0.2 Credit，是否继续？',
          ai_bg_removal_short: 'AI抠图',
          array: '阵列',
          auto_fit: '自动适配',
          bevel: '生成斜角',
          brightness: '亮度对比',
          convert_to_path: '转换为路径',
          create_textpath: '建立路径文本',
          create_textpath_short: '建立路径文本',
          crop: '裁剪',
          decompose_path: '解散非连续路径',
          detach_path: '解散路径文本',
          detach_path_short: '解散路径文本',
          disassemble_use: '解散图档',
          disassembling: '解散中...',
          edit_path: '编辑路径',
          fetching_web_font: '取得线上字体中',
          grading: '曲线',
          invert: '色彩反转',
          offset: '位移複製',
          outline: '勾勒轮廓',
          replace_with: '替换影像',
          replace_with_short: '替换影像',
          sharpen: '鋭化',
          simplify: '路径优化',
          smart_nest: '智能排版',
          trace: '向量化',
          ungrouping: '解散群组中...',
          uploading_font_to_machine: '上传字体到机器中',
          wait_for_parsing_font: '解析字体中...',
          weld_text: '合併文字',
        },
        align: '对齐',
        boolean: '布林',
        bottom_align: '底部对齐',
        center_align: '置中对齐',
        difference: '排除重叠形状',
        distribute: '均分',
        flip: '翻转',
        group: '群组',
        hdist: '水平均分',
        hflip: '水平翻转',
        intersect: '形状区域相交',
        left_align: '靠左对齐',
        lock_aspect: '锁定长宽比',
        middle_align: '中线对齐',
        option_panel: {
          color: '颜色',
          fill: '填充',
          font_family: '字体',
          font_size: '字级',
          font_style: '字型',
          letter_spacing: '字距',
          line_spacing: '行距',
          path_infill: '路径填充',
          pwm_engraving: '深度模式',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: '圆角',
          shading: '渐层',
          sides: '边数',
          start_offset: '文本偏移',
          stroke: '边框',
          stroke_color: '边框颜色',
          stroke_width: '边框宽度',
          text_infill: '文本填充',
          threshold: '曝光阈值',
          threshold_short: '临界值',
          vertical_align: '对齐',
          vertical_text: '直书',
        },
        path_edit_panel: {
          connect: '连接',
          delete: '删除',
          disconnect: '断开',
          node_type: '节点类型',
          round: '平滑',
          sharp: '锐利',
        },
        right_align: '靠右对齐',
        subtract: '去除前面形状',
        top_align: '顶端对齐',
        ungroup: '解散群组',
        union: '组合形状',
        unlock_aspect: '解锁长宽比',
        vdist: '垂直均分',
        vflip: '垂直翻转',
        zoom: '缩放',
      },
      tabs: {
        layers: '图层',
        objects: '物件',
        path_edit: '路径编辑',
      },
      uv_print_block: {
        title: 'UV打印',
      },
    },
    shapes_panel: {
      animals: '动物',
      arrow: '箭头',
      basic: '基本',
      birds: '鸟类',
      celebration: '庆祝',
      circular: '圆形',
      CNY: '农历新年',
      corner: '角落',
      decor: '装饰',
      easter: '复活节',
      elements: '元素',
      environment: '环境',
      graphics: '图形',
      halloween: '万圣节',
      holidays: '节日',
      label: '标签',
      land: '陆地',
      line: '线条',
      nature: '自然',
      photo: '相框',
      plants: '植物',
      ribbon: '丝带',
      sea: '海洋',
      shape: '形状',
      speech: '对话框',
      text: '文本框',
      title: '元素',
      valentines: '情人节',
      weather: '天气',
      Xmas: '圣诞节',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: '无法读取复制连结中的档案',
      unnsupported_file_type: 'Beam Studio 不直接支持此文件格式。请先输出成图片档或 SVG 格式',
    },
    tag: {
      g: '群组',
      image: '图片',
      text: '文字',
      use: '汇入图档',
    },
    time_est_button: {
      calculate: '预估时间',
      estimate_time: '预计所需时间：',
    },
    tool_panels: {
      _nest: {
        end: '结束',
        no_element: '没有物件可以进行排列。',
        rotations: '旋转方向数距',
        spacing: '间距',
        start_nest: '开始排列',
        stop_nest: '停止排列',
      },
      _offset: {
        corner_type: '边角',
        direction: '偏移物件',
        dist: '偏移距离',
        fail_message: '生成偏移物件失败',
        inward: '向內',
        not_support_message: '选取物件中含有不支援的类型：\n图片、群组、文字、汇入图档。',
        outward: '向外',
        round: '圆角',
        sharp: '尖角',
      },
      array_dimension: '阵列维度',
      array_interval: '阵列间隔',
      cancel: '取消',
      columns: '行',
      confirm: '确认',
      dx: '宽',
      dy: '高',
      grid_array: '生成阵列',
      nest: '排列最佳化',
      offset: '偏移物件',
      rows: '列',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: '配合视窗尺寸',
    },
  },
  boxgen: {
    add_option: '添加选项',
    basic_box: '基本箱体',
    beam_radius: '切口补偿',
    beam_radius_warning: '建议在盒子或榫接边较短时取消补偿，以确保盒子的接合。',
    cancel: '取消',
    coming_soon: '即将推出',
    continue_import: '继续导入',
    control_tooltip: '左键旋转\n滚轮缩放\n右键平移',
    control_tooltip_touch: '拖动旋转\n捏合缩放\n双指平移',
    cover: '盖板',
    customize: '自定义',
    depth: '深度',
    edge: '边缘',
    finger: '指接榫',
    finger_warning: '盒子内侧长度需要至少6mm(0.24inch)，才能使用指接榫拼接。',
    height: '高度',
    import: '导入',
    inner: '内部',
    joints: '拼接',
    max_dimension_tooltip: '最大宽度/高度/深度设置为 %s。',
    merge: '合并',
    outer: '外部',
    reset: '重置',
    tCount: 'T 数量',
    tCount_tooltip: 'T型槽数量适用于短边;长边的数量根据其长度计算。',
    tDiameter: 'T 直径',
    text_label: '文本标签',
    thickness: '厚度',
    title: 'BOXGEN',
    tLength: 'T 长度',
    tSlot: 'T 型槽',
    tSlot_warning: '盒子侧长需要至少30mm(1.18inch)，才能使用 T 型槽拼接。',
    volume: '体积',
    width: '宽度',
    workarea: '工作区',
    zoom: '缩放',
  },
  buttons: {
    back: '上一步',
    back_to_beam_studio: '返回 Beam Studio',
    done: '完成',
    next: '下一步',
  },
  calibration: {
    ador_autofocus_focusing_block: '在机器主页上按“AF”图标3秒钟，让探头触碰对焦块。',
    ador_autofocus_material: '在机器的主页面上长按“AF”图标3秒，并让探针轻轻触碰材料。',
    align_ilb: '位置：对齐左下角内侧的雕刻点。',
    align_ilt: '位置：对齐左上角内侧的雕刻点。',
    align_irb: '位置：对齐右下角内侧的雕刻点。',
    align_irt: '位置：对齐右上角内侧的雕刻点。',
    align_olb: '位置：对齐左下角外侧的雕刻点。',
    align_olt: '位置：对齐左上角外侧的雕刻点。',
    align_orb: '位置：对齐右下角外侧的雕刻点。',
    align_ort: '位置：对齐右上角外侧的雕刻点。',
    align_red_cross_cut: '请将红色十字的中心与切割十字对齐。',
    align_red_cross_print: '请将红色十字的中心与印刷十字对齐。',
    analyze_result_fail: '校正失败<br/>请确认:<br/>1. 校正图片完整画在 A4 纸上<br/>2. 已旋转升降平台旋钮，直到轻触焦距螺丝，完成对焦',
    ask_for_readjust: '是否需要跳过切割步骤，进行拍照及校正？',
    back: '上一步',
    calculating_camera_matrix: '正在计算相机矩阵...',
    calculating_regression_parameters: '正在计算回归参数...',
    calibrate_camera_before_calibrate_modules: '请在校准模块之前进行相机校准。',
    calibrate_chessboard_success_msg: '成功捕获了棋盘照片。<br/>此照片的得分为 %s (%.2f)。',
    calibrate_done: '校正相机完成！使用时请正确对焦以取得良好的预览效果。',
    calibrate_done_diode: '校正完成！二极管激光模组偏移值已自动储存。',
    calibrating: '正在校准...',
    calibrating_with_device_pictures: '使用设备图片进行校准...',
    camera_calibration: '相机校正',
    camera_parameter_saved_successfully: '相机参数保存成功。',
    cancel: '取消',
    check_checkpoint_data: '检查点数据',
    check_device_pictures: '检查设备图片',
    checking_checkpoint: '正在检查检查点数据...',
    checking_pictures: '正在检查设备图片...',
    diode_calibration: '二极管激光模组校正',
    do_engraving: '执行切割',
    download_chessboard_file: '下载棋盘板文件',
    downloading_checkpoint: '下载检查点数据...',
    downloading_pictures: '正在下载图片...',
    drawing_calibration_image: '绘制校正图片中...',
    dx: '水平位移',
    dy: '垂直位移',
    elevate_and_cut: '抬升和裁剪',
    elevate_and_cut_step_1: '将一块A4大小的浅色木材放置在工作区中心，并将其抬高至20mm。',
    elevate_and_cut_step_1_prism_lift: '使用Ador Prism Lift，最大长度为14mm，搭配至少6mm厚度的木材。',
    failed_to_calibrate_camera: '#848 相机校准失败，请联系FLUX支持。',
    failed_to_calibrate_chessboard: '无法使用棋盘图片进行校准。',
    failed_to_calibrate_with_pictures: '#848 使用设备图片进行校准失败。',
    failed_to_download_pictures: '#848 图片下载失败，请联系FLUX支持。',
    failed_to_move_laser_head: '无法移动激光头。',
    failed_to_parse_checkpoint: '无法解析检查点数据。',
    failed_to_save_calibration_results: '#849 保存校准结果失败，请重试。如果问题持续发生，请联系FLUX支持。',
    failed_to_save_camera_parameter: '保存相机参数失败。',
    failed_to_solve_pnp: '无法解出相机位置。',
    finish: '完成',
    found_checkpoint: '在您的设备上找到了检查点数据。您想从检查点恢复吗？',
    getting_plane_height: '获取平面高度...',
    hint_adjust_parameters: '由这些参数来调整红框的位置，旋转与大小',
    hint_red_square: '请将红框对齐切割出来的方块',
    module_calibration_2w_ir: '红外模块校准',
    module_calibration_printer: '打印机模块校准',
    moving_laser_head: '正在移动激光头...',
    next: '下一步',
    no_picutre_found: '#846 您的设备没有可用于校准的原始照片。请联系FLUX支持。',
    perform_autofocus_bb2: '请前往机器控制面板，按下AF以执行自动对焦。请前往机器控制面板，按下AF以执行自动对焦。',
    please_do_camera_calibration_and_focus: {
      beambox: '校正二极管激光需要使用相机校正参数，请确认您的机器已进行过相机校正。并请旋转升降平台旋钮，直到轻触焦距螺丝或焦距尺，完成对焦',
      beamo: '校正二极管激光需要使用相机校正参数，请确认您的机器已进行过相机校正。并请转开焦距固定环，调整雷射头至平台轻触焦距尺，完成对焦',
    },
    please_goto_beambox_first: '请先选择 Beambox 功能，再进行校正',
    please_place_dark_colored_paper: '请将 A4 深色纸放置在工作区的中央。',
    please_place_paper: '请将干净 A4 白纸放在工作区域的左上角',
    please_place_paper_center: '请将干净 A4 白纸放在工作区的中央。',
    please_refocus: {
      beambox: '请旋转升降平台旋钮，直到轻触焦距螺丝或焦距尺，完成对焦后，转回对焦尺。',
      beamo: '请转开焦距固定环，调整雷射头至平台轻触焦距尺，完成对焦后，旋紧固定环、转回对焦尺。',
      beamo_af: '请双击自动对焦套件侧边按钮，使探针轻触雕刻材料。',
      hexa: '请连续按压升降平台左侧按钮 2 次，使平台上移、自动对焦探针轻触雕刻材料。',
    },
    preparing_to_take_picture: '准备拍照...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: '放置棋盘板',
    put_chessboard_1: '请前往机器控制面板，按下AF以执行自动对焦。',
    put_chessboard_2: '请移动棋盘纸或激光头，直到确认整个棋盘在实时预览窗口中的红框内。',
    put_chessboard_3: '单击右键以下载当前位置的棋盘图像并检查其清晰度。',
    put_chessboard_bb2_desc_1: '请下载以下棋盘文件，并将其打印在A4纸上（打印的棋盘应为1x1厘米的方格）。',
    put_chessboard_bb2_desc_2: '将打印好的棋盘纸固定在不可变形的板材上，例如亚克力或玻璃，确保棋盘平整，无皱褶或翘起。',
    put_chessboard_bb2_desc_3: '将带有棋盘的板材平放在工作区域的中心位置。',
    put_chessboard_promark_1: '调整焦距至适合场镜的焦点。',
    put_chessboard_promark_2: '请确保棋盘纸清晰可见且无眩光。点击“下一步”拍照。',
    put_chessboard_promark_desc_1: '请使用附件盒中提供的棋盘纸，或将以下棋盘图案打印在A4纸上用于相机校准。',
    put_chessboard_promark_desc_2: '请将棋盘纸平放并置于工作平台中央。',
    put_paper: '放置纸张',
    put_paper_promark_1: '将配件盒中的黑色硬纸板放置在工作平台上。',
    put_paper_promark_2: '正确调整焦距，然后点击“开始绘制校正图片”以继续雕刻。',
    put_paper_skip: '如果校准图未自动进入拍照，请按“跳过”进行拍照。',
    put_paper_step1: '请将A4或信纸大小的白纸放置在工作区的中心。',
    put_paper_step2: '固定纸的四个角以确保它平放。',
    put_paper_step3: '点击“开始绘制校正图片”。',
    res_average: '一般',
    res_excellent: '优秀',
    res_poor: '差',
    retake: '重拍照片',
    rotation_angle: '旋转角度',
    show_last_config: '显示前次校正结果',
    skip: '跳過',
    solve_pnp_step1: '请根据每个红色标记点的编号和预期位置对齐雕刻点。',
    solve_pnp_step2: '您可以按「重拍照片」重新对齐或手动调整标记位置。',
    solve_pnp_step3: '若拍摄画面与实际不符，请缩放或拖曳画面，将黑卡纸上校正点的范围调整至正中央，再尝试「重拍照片」。',
    solve_pnp_title: '对齐标记点',
    start_engrave: '开始绘制校正图片',
    start_printing: '开始打印校正图片',
    taking_picture: '截取图片中...',
    unable_to_load_camera_parameters: '#851 您的设备上没有可用的相机校准参数。请前往“校准” > “校正相机（高级）”完成校准并获取参数。',
    update_firmware_msg1: '您的固件版本不支援此功能。请先更新 Beambox 的固件至 v',
    update_firmware_msg2: '以上以继续。 (主选单 > 机器 > [ Your Beambox ] > 固件更新',
    uploading_images: '正在上传图片...',
    use_last_config: '汇入前次校正数值',
    use_old_camera_parameter: '您要使用当前的相机镜头参数吗？',
    with_af: '有自动对焦',
    with_prism_lift: '使用 Ador Prism Lift',
    without_af: '无自动对焦',
    without_prism_lift: '不使用 Ador Prism Lift',
    x_ratio: '水平比例',
    y_ratio: '垂直比例',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: '相机数据下载成功。',
    downloading_data: '正在下载数据...',
    estimated_time_left: '预计剩余时间：',
    folder_not_exists: '所选文件夹不存在。',
    incorrect_folder: '相机数据上传失败。请检查您选择的文件夹是否正确。',
    no_picture_found: '机器中未找到任何图片。',
    title: '相机数据备份',
    upload_success: '相机数据上传成功。',
    uploading_data: '正在上传数据...',
  },
  caption: {
    connectionTimeout: '连接逾时',
  },
  change_logs: {
    added: '新增：',
    change_log: '更改日志：',
    changed: '更改：',
    fixed: '修正：',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: '查看之前版本',
  },
  code_generator: {
    barcode: '条形码',
    qr_code: '二维码',
  },
  curve_engraving: {
    '921': '自动对焦失败。',
    '922': '红光测量失败。',
    amount: '数量',
    apply_arkwork: '应用艺术作品到曲面',
    apply_camera: '应用相机图像到曲面',
    click_to_select_point: '点击选择或取消选择要重新测量的点。',
    coloumns: '列',
    column_gap: '列间距',
    err_object_over_range: '物体超出测量范围。',
    failed_to_take_reference: '获取参考失败。',
    gap: '间距',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: '测量自动对焦区域',
    preview_3d_curve: '预览曲面雕刻',
    remeasure: '重新测量',
    remeasuring_points: '正在重新测量...',
    reselect_area: '重新选择区域',
    row_gap: '行间距',
    rows: '行',
    set_object_height: '设置物件高度',
    set_object_height_desc: '测量物件的最大厚度。',
    start_autofocus: '开始自动对焦',
    starting_measurement: '开始测量...',
    sure_to_delete: '您要删除曲面雕刻的对焦数据吗？',
    take_reference: '获取参考',
    take_reference_desc: '请将激光头移动到物体的最高点，放下焦点探针，然后点击“确认”以进行对焦。',
  },
  device: {
    abort: '取消工作',
    aborted: '已终止',
    aborting: '取消工作中',
    busy: '忙碌中',
    cartridge_info_read_failed: '请确认墨盒完全插入。尝试将墨水取出并重新安装。',
    cartridge_info_verification_failed: '请确认您是否使用 FLUX 原厂墨盒。',
    cartridge_serial_number: '墨盒序号',
    close_door_to_read_cartridge_info: '请关闭机器的门盖以访问墨盒信息。',
    completed: '已完成',
    completing: '完成中',
    deviceList: '机器列表',
    disable: '关闭',
    disconnectedError: {
      caption: '机器连接中断',
      message: '请确认 %s 的网络连接是否正常',
    },
    enable: '开启',
    firmware_version: '固件版本',
    ink_color: '墨水颜色',
    ink_level: '剩余墨水量',
    ink_type: '墨水类型',
    IP: 'IP',
    model_name: '型号',
    occupied: '机器被占用',
    pause: '暂停',
    paused: '已暂停',
    pausedFromError: '发生错误暂停',
    pausing: '正在暂停',
    please_wait: '请稍待...',
    preparing: '准备中',
    processing: '处理中',
    quit: '中断链接',
    ready: '待命中',
    reset: '重设(kick)',
    resuming: '恢复中',
    retry: '重试',
    running: '工作中',
    scanning: '扫描',
    select: '选择',
    select_printer: '选择成型机',
    serial_number: '序号',
    start: '开始',
    starting: '启动中',
    status: '状态',
    submodule_type: '模块',
    toolhead_change: '更换工具头',
    unknown: '未知状态',
    uploading: '上传中',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 我们在网路上找不到您的机器，\n请参考<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">此指南</a>排除连线问题！',
    no_device_web: '#801 请检查您的机器状态，或点击下方的‘机器设置’来设置机器。',
    select_usb_device: '选择 USB 设备',
  },
  editor: {
    exposure: '预览预览曝光值',
    opacity: '相机预览不透明度',
    prespray_area: '预喷区',
  },
  error_pages: {
    screen_size: '请注意，Beam Studio 无法在您的装置上顺利执行。若要获得最佳使用体验，装置萤幕宽度至少应有 1024 像素。',
  },
  flux_id_login: {
    connection_fail: '#847 无法连接至 FLUX 会员中心。',
    email: '电子信箱',
    flux_plus: {
      access_monotype_feature: '您无法使用 Monotype 字体。',
      access_monotype_feature_note: '您必须是 FLUX+ Pro 会员或购买 Monotype 字体加购才能访问此功能。',
      access_plus_feature_1: '您正在访问一个',
      access_plus_feature_2: '功能。',
      access_plus_feature_note: '您必须是 FLUX+ 会员才能访问此功能。',
      ai_credit_tooltip: '用于 AI 抠图',
      explore_plans: '探索 FLUX+ 计划',
      features: {
        ai_bg_removal: 'AI 抠图',
        boxgen: '3D 盒子生成器',
        dmkt: '1000+ 设计模板',
        monotype: '250+ 高级字体',
        my_cloud: '云存储',
      },
      flux_credit_tooltip: '用于 Design Market 和 AI 抠图',
      get_addon: '购买加购',
      goto_member_center: '前往会员中心',
      learn_more: '了解更多',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: '立即订阅',
      thank_you: '感谢您成为我们的重要会员!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: '忘记密码',
    incorrect: '信箱或密码输入错误',
    login: '登入',
    login_success: '登入成功',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: '还不是会员？免费注册新帐号',
    not_verified: '信箱尚未认证。',
    offline: '离线使用',
    password: '密码',
    register: '注册 FLUX 帐号',
    remember_me: '记住我',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: '登入使用百万图形资源',
    work_offline: '我要离线使用',
  },
  framing: {
    area_check: '区域检查',
    areacheck_desc: '通过预览对象的边界框和激光头的加速区，确保工作区的安全。',
    calculating_task: '正在计算任务...',
    framing: '外框',
    framing_desc: '预览对象的边界框。',
    hull: '凸包',
    hull_desc: '预览一个紧随设计的形状，就像橡皮筋缠绕在对象周围一样。',
    low_laser: '低功率激光',
    low_laser_desc: '为框架任务设置低功率激光值。',
    rotate_axis: '旋转轴',
    rotateaxis_desc: '预览旋转轴中心，旋转轴本身不移动。',
    rotation_frame_warning: '外框预览会旋转一圈，请避免中途停止，以免导致打点位置偏移。',
    rotation_framing_desc: '预览完整旋转范围，旋转轴会随着预览旋转。',
    start_task: '开始任务',
    start_task_description: '完成外框预览后，点击“开始任务”提交工作。',
  },
  general: {
    choose_folder: '选择档案夹',
    processing: '处理中...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] 机器发生状态冲突，请再试一次',
    SUBSYSTEM_ERROR: '[SE] 固件执行雕刻程序错误，请尝试重新启动机器',
    UNKNOWN_COMMAND: '[UC] 请更新机器固件',
    UNKNOWN_ERROR: '[UE] 请重启 Beam Studio',
  },
  global: {
    apply: '套用',
    back: '返回',
    cancel: '取消',
    editing: {
      redo: '重做',
      reset: '重置',
      undo: '撤销',
      zoom_in: '放大',
      zoom_out: '缩小',
    },
    mode_conflict: '此选项在当前模式下不可用。',
    ok: '确定',
    save: '储存',
    stop: '停止',
  },
  image_edit_panel: {
    eraser: {
      brush_size: '画笔大小',
      description: '单击或拖动以手动擦除不需要的区域。',
      title: '橡皮擦',
    },
    magic_wand: {
      description: '根据颜色相似性选择并移除相邻区域。',
      title: '魔术棒',
      tolerance: '容差',
    },
    title: '编辑图片',
  },
  initialize: {
    back: '返回',
    cancel: '取消',
    confirm: '确认',
    connect: '连接',
    connect_ethernet: {
      title: '网路线直连',
      tutorial1: '1. 将您的机器与您的电脑以乙太网路线连接。',
      tutorial2_1: '2. 依照',
      tutorial2_2: '使您的电脑同时扮演路由器的角色。',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: '这篇文章',
      tutorial3: "3. 点击 '下一步'。",
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: '无法连接到服务器。请重新启动Beam Studio并重试。',
      },
      check_camera: '确认相机',
      check_connection: '确认机器连线',
      check_firmware: '确认韧体版本',
      check_ip: '确认 IP',
      check_swiftray_connection: '正在检查服务器连接',
      check_swiftray_connection_unreachable: '服务器无法访问',
      check_usb: '确认 USB 连线',
      enter_ip: '请输入您的机器 IP',
      finish_setting: '开始创作',
      invalid_format: '格式不符合',
      invalid_ip: 'IP 错误：',
      promark_hint: '如果您持续遇到 USB 连线失败问题，请参考<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">说明中心文章</a>。',
      retry: '重试',
      starts_with_169254: '由 169.254 开头',
      succeeded_message: '连线成功 🎉',
      unreachable: '无法连接至指定 IP',
    },
    connect_usb: {
      connect_camera: '将机器的摄像头与您的电脑以 USB 线连接。',
      title: 'USB 连线',
      title_sub: '（仅支援 HEXA & Ador）',
      turn_off_machine: '关闭机器',
      turn_on_machine: '打开机器',
      tutorial1: '将您的机器与您的电脑以 USB 线连接。',
      tutorial2: "点击 '下一步'。",
      wait_for_turning_on: "启动过程完成并进入主屏幕后，点击 '下一步'。",
    },
    connect_wifi: {
      title: '连接 Wi-Fi',
      tutorial1: '1. 到机器控制面板 > 点击 「网路」 > 「设定 Wi-Fi」。',
      tutorial1_ador: '1. 到机器控制面板 > 点击 「机器」> 点击 「网路」 > 「设定 Wi-Fi」。',
      tutorial2: '2. 选择并连接您想使用的 Wi-Fi 。',
      what_if_1: '机器找不到我想使用的 Wi-Fi',
      what_if_1_content: '1. Wi-Fi 加密方式需为 WPA2 或无密码。 \n2. 加密方式可由 Wi-Fi 路由器设定，如果路由器不支援 WPA2，可联系客服购买，如果不确定路由器是否支援，可以将型号传给客服询问。',
      what_if_2: '机器找不到任何 Wi-Fi',
      what_if_2_content: '1. 确认您的 Wi-Fi 接收器是否有着实插上。 \n2. 如果面板上没有出现无线网路硬体位置，请联系客服。 \n3. Wi-Fi 频道为 2.4Ghz (不支援 5Ghz)。',
    },
    connect_wired: {
      title: '连接有线网路',
      tutorial1: '1. 请将您的机器以乙太网路线与路由器连接。',
      tutorial2: '2. 在触控面板点击「网路」以获得有线网路 IP 。',
      tutorial2_ador: '在触控面板点击「机器」>「网路」以获得有线网路 IP 。',
      what_if_1: '机器显示的有线网路 IP 是空的',
      what_if_1_content: '1. 确认您的乙太网路线是否有着实插上。 \n2. 如果面板上没有出现有线网路硬体位置，请联系客服。',
      what_if_2: '机器显示的 IP 开头为 169',
      what_if_2_content: '1. IP 地址为 169.254 开头通常为 DHCP 设定问题，需要联系网路服务提供商或是网路设定者来协助。 \n2. 如果工作环境的网路是由电脑直接 PPPoE 连网，请改由路由器直接 PPPoE 联网，并在路由器中开启DHCP 功能。',
    },
    connecting: '连接中',
    connection_types: {
      ether2ether: '网路线直连',
      usb: 'USB 連線',
      wifi: 'Wi-Fi',
      wired: '有线网路',
    },
    next: '下一步',
    no_machine: '目前没有机器或已设置过连接，跳过此步骤',
    promark: {
      configuration_confirmation: '这可确保您的 Promark 已正确配置以实现最佳效率和精确性。',
      or_complete_later: `或者，跳过此步骤并稍后在以下位置完成 Promark 设置：
    机器 > “Promark 名称” > Promark 设置`,
      qc_instructions: '填写“QC Pass”卡背面的参数',
      select_laser_source: '选择您的 Promark',
      select_workarea: '选择工作区域',
      settings: 'Promark 设置',
    },
    retry: '重试',
    select_beambox: '请选择您的 Beambox',
    select_connection_type: '请选择您的连接方式',
    select_language: '请选择你想使用的语言',
    select_machine_type: '请选择您的机种',
    setting_completed: {
      back: '回到 Wi-Fi 设置',
      great: '欢迎使用 Beam Studio',
      ok: '开始使用',
      setup_later: '您可以随时从选单 >「机器」>「新增或设定机器」来设定连线。',
      start: '开始使用',
    },
    skip: '跳过',
    start: '开始设置',
  },
  input_machine_password: {
    connect: '连接',
    password: '密码',
    require_password: '"%s" 需要密码',
  },
  insecure_websocket: {
    extension_detected: '检测到 Beam Studio Connect 扩展',
    extension_detected_description: '我们检测到您已安装了 Beam Studio Connect 扩展。请点击“确认”以重定向到 HTTPS，或点击“取消”以继续使用 HTTP。',
    extension_not_deteced: '无法检测到 Beam Studio Connect 扩展',
    extension_not_deteced_description: '要使用 HTTPS，请点击“确认”以安装 Beam Studio Connect 扩展。安装扩展后，请刷新页面以激活它。<br/>否则，请点击下面的链接查看如何在 Chrome 中使用 HTTP。',
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">链接</a>',
  },
  layer_module: {
    general_laser: '激光',
    laser_2w_infrared: '2W 红外激光器',
    laser_10w_diode: '10W 二极管激光器',
    laser_20w_diode: '20W 二极管激光器',
    non_working_area: '非工作区域',
    none: '无',
    notification: {
      convertFromLaserModuleMsg: '请注意，如果您继续此操作，激光图层的设置将会被删除，并根据当前图层参数而定。',
      convertFromLaserModuleTitle: '您是否要将激光图层转换为打印图层？',
      convertFromPrintingModuleMsg: '请注意，如果您继续此操作，打印图层的颜色设置将会被删除，并根据当前图层参数而定。',
      convertFromPrintingModuleTitle: '您是否要将打印图层转换为激光图层？',
      importedDocumentContainsPrinting: '文档包含打印图层，您想将工作区更改为Ador吗？',
      performIRCaliMsg: '点击「确认」执行校准，或通过顶部菜单中的「机器」 > 「您的机器名称」 > 「校准红外模块」进行校准。',
      performIRCaliTitle: '执行红外模块校准',
      performPrintingCaliMsg: '点击「确认」执行校准，或通过顶部菜单中的「机器」 > 「您的机器名称」 > 「校准打印模块」进行校准。',
      performPrintingCaliTitle: '执行打印模块校准',
      printingLayersConverted: '打印图层已转换为激光图层。',
    },
    printing: '打印',
    unknown: '未知模块',
    uv_print: 'UV打印',
  },
  machine_status: {
    '-17': '墨匣模式',
    '-10': '動作模式',
    '-2': '扫描中',
    '-1': '维护中',
    0: '待命中',
    1: '初始化',
    2: 'ST_TRANSFORM',
    4: '启动中',
    6: '回复中',
    16: '工作中',
    18: '回复中',
    32: '已暂停',
    36: '已暂停',
    38: '暂停中',
    48: '已暂停',
    50: '暂停中',
    64: '已完成',
    66: '完成中',
    68: '准备中',
    128: '已中断',
    256: 'Alarm',
    512: 'Fatal',
    UNKNOWN: '-',
  },
  material_test_generator: {
    block_settings: '块设置',
    columns: '列',
    count: '数量',
    cut: '切割',
    engrave: '雕刻',
    export: '导出',
    max: '最大值',
    min: '最小值',
    parameter: '参数',
    preview: '预览',
    rows: '行',
    size: '尺寸 (高x宽)',
    spacing: '间距',
    table_settings: '表格设置',
    text_settings: '文本设置',
    title: '材质测试生成器',
  },
  menu: {
    inches: '英吋',
    mm: '毫米',
  },
  message: {
    auth_error: '#820 认证失败：请将 Beam Studio 以及机器韧体更新至最新版。',
    authenticating: '密码验证中...',
    camera: {
      abort_preview: '中断预览',
      camera_cable_unstable: '侦测到相机传输照片时不稳定，仍能正常进行相机预览，但可能会有预览速度较慢或超时的问题。<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001791895">了解更多</a>',
      continue_preview: '继续预览',
      fail_to_transmit_image: '#845 相机传输照片异常，请将机器重新开机。如果问题持续发生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">此则引导</a>。',
      ws_closed_unexpectly: '#844 与机器相机的连线无预期的中断。如果问题持续发生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402755805071">此则引导</a>。',
    },
    cancelling: '取消中...',
    cant_connect_to_device: '#827 无法链接机器，请确认机器是否开启，以及与机器的链接方式',
    connected: '已连接',
    connecting: '连接中...',
    connectingCamera: '正在连接相机...',
    connectingMachine: '连接 %s 中...',
    connectionTimeout: '#805 连接机器逾时，请确认你的网路状态和机器的 Wi-Fi 讯号符号。',
    device_blocked: {
      caption: '未授权的序列号',
      offline: '您的设备序列号的离线使用已被限制，因为似乎已被停用。请将设备序列号（位于设备背面）提供给您的经销商，并请他们联系 support@flux3dp.com 以激活在线授权。如果您希望离线使用该设备，请直接联系 support@flux3dp.com。',
      online: '您的设备序列号似乎已被停用。请将设备序列号（位于设备背面）提供给您的经销商，并请他们联系 support@flux3dp.com 以激活设备授权。',
    },
    device_busy: {
      caption: '机器忙碌中',
      message: '机器正在进行另外一项工作，请稍候再试。如果机器持续没有回应，请将机器重新启动。',
    },
    device_is_used: '机器正被使用中，是否要终止现在任务？',
    device_not_found: {
      caption: '找不到默认机器',
      message: '#812 请确认默认机器的 Wi-Fi 指示灯，或取消设置默认机器',
    },
    disconnected: '连接不稳，请确认机器连接状况并稍后再试一次',
    endingLineCheckMode: '正在结束可靠传输模式...',
    endingRawMode: '正在结束基本动作模式...',
    enteringLineCheckMode: '正在进入可靠传输模式...',
    enteringRawMode: '正在进入基本动作模式...',
    enteringRedLaserMeasureMode: '正在进入红色激光测量模式...',
    exitingRotaryMode: '正在结束旋转轴模式...',
    getProbePosition: '获取探针位置中...',
    gettingLaserSpeed: '正在取得雷射头移动速度...',
    homing: '归零中...',
    need_password: '需要密码与机器创建连接',
    please_enter_dpi: '请输入该文件的 dpi (in mm)',
    preview: {
      adjust: '调整',
      adjust_height_tooltip: '单击复选框以启用编辑。',
      already_performed_auto_focus: '您已经执行了自动对焦，要使用现有数值吗？',
      auto_focus: '自动对焦',
      auto_focus_instruction: '请将激光模块头移至物体上方，并按照动画指示按下AF进行对焦。',
      camera_preview: '相机预览',
      enter_manually: '手动输入',
      please_enter_height: '请输入物体的高度，以拍摄精确的相机照片。',
    },
    promark_disconnected: '#850 设备连接中断，请检查设备的连接状态。',
    redLaserTakingReference: '正在获取参考点...',
    retrievingCameraOffset: '正在取得相机参数...',
    settingLaserSpeed: '正在设定雷射头移动速度...',
    swiftray_disconnected: '无法连接到后端，正在尝试重新连接。',
    swiftray_reconnected: '后端已重新连接，请尝试重新发送工作。',
    time_remaining: '剩余时间：',
    tryingToConenctMachine: '连接机器中...',
    turningOffAirPump: '正在关闭空气帮浦...',
    turningOffFan: '正在关闭抽气风扇...',
    unable_to_find_machine: '无法连接到机器 ',
    unable_to_start: '#830 无法开始工作，如果持续发生，请附上错误回报，与我们联系:\n',
    unavailableWorkarea: '#804 目前设定的工作范围超过目标机器的工作范围。请确认选择的机器型号，或从 编辑 > 文件设定 更改工作范围。',
    unknown_device: '#826 无法与机器创建连接，请确认 USB 有连接于机器',
    unknown_error: '#821 无法与机器创建连接，请使用“功能表 > 说明 > 错误回报”',
    unsupport_osx_version: '目前系统版本 MacOS X %s 较旧，部分功能可能无法使用，请更新到 macOS 11+。',
    unsupport_win_version: '目前系统版本 %s 较旧，部分功能可能无法使用，请更新到最新版。',
    unsupported_example_file: '所选示例文件不受当前工作区支持。',
    uploading_fcode: '正在上传 fcode',
    usb_unplugged: 'USB 连接逾时，请确认与机器的连接',
    wrong_swiftray_version_message: '后端版本有误 (版本: {version})，请检查是否有多个 Beam Studio 实例正在运行，并尝试关闭多余的实例后重试。',
    wrong_swiftray_version_title: '后端版本有误',
  },
  monitor: {
    ask_reconnect: '与机器的连线中断，是否尝试重新连接？',
    bug_report: '下载错误回报档案',
    camera: '相机',
    cancel: '取消',
    confirmFileDelete: '是否确定要删除这个文件？',
    connecting: '连接中，请稍候',
    DEVICE_ERROR: '固件发生错误\n请重新启动机器', // Deprecated in FW 3.3.1
    download: '下载',
    extensionNotSupported: '上传文件不支持此文件格式',
    fileExistContinue: '文件已存在，是否要覆盖？',
    forceStop: '是否强制停止现在工作?',
    go: '开始',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 气流侦测异常',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 底盖开启，将底盖关上以继续',
    HARDWARE_ERROR_DOOR_OPENED: '#901 门盖开启，将门盖关上以继续',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 抽屉开启',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 火焰侦测异常',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 检测到错误的模块。请安装正确的模块以继续。',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 未检测到模块。请确保模块已正确安装以继续。',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 检测到未知模块。请安装正确的模块以继续。',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 归零 PULL-OFF 失败',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 主板没有回应。请联系 FLUX 客服。', // Deprecated in FW 3.3.1
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 水温过高，请稍后再继续',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z轴归零失败',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 打印模块无响应。',
    HARDWARE_ERROR_PROBE_SHOWED: '请收回探针。',
    HARDWARE_ERROR_PUMP_ERROR: '#900 水冷未开，请联系客服 (02) 2651-3171',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 未侦测到旋转轴',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 子系统没有回应。请联系 FLUX 客服。', // Deprecated in FW 3.3.1
    HARDWARE_FAILURE: '固件发生错误\n请重新启动机器', // Deprecated in FW 3.3.1
    hour: '小时',
    left: '完成',
    MAINBOARD_OFFLINE: '#905 连接主板时发生错误\n请重新启动机器。',
    minute: '分',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 连接主板时发生错误\n请重新启动机器。',
    pause: '暂停',
    prepareRelocate: '准备重新定位中',
    processing: '处理中',
    record: 'RECORD',
    relocate: '重新定位',
    RESOURCE_BUSY: '机器忙碌中\n如果机器没有在进行动作， 请重新启动机器',
    resume: '繼續',
    savingPreview: '正在产生预览图',
    second: '秒',
    start: '开始',
    stop: '停止',
    SUBSYSTEM_ERROR: '#402 子系统没有回应。请联系 FLUX 客服。',
    task: {
      BEAMBOX: '激光雕刻',
      'N/A': '自由模式',
    },
    taskTab: '工作',
    temperature: '温度',
    upload: '上传',
    USER_OPERATION: '请遵循设备面板上的指示以继续。',
    USER_OPERATION_CHANGE_CARTRIDGE: '请插入正确的墨盒以继续。',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: '请插入墨盒以继续。',
    USER_OPERATION_CHANGE_TOOLHEAD: '请安装正确的模块以继续。',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 检测到错误的模块。请安装正确的模块以继续。',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 未检测到模块。请确保模块已正确安装以继续。',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 检测到未知模块。请安装正确的模块以继续。',
    USER_OPERATION_REMOVE_CARTRIDGE: '请取出墨盒以继续。',
    USER_OPERATION_ROTARY_PAUSE: '请切换旋转轴马达开关',
  },
  my_cloud: {
    action: {
      confirmFileDelete: '确定要删除此文件吗？此操作无法撤消。',
      delete: '删除',
      download: '下载',
      duplicate: '复制',
      open: '打开',
      rename: '重命名',
    },
    file_limit: '免费文件',
    loading_file: '正在加载...',
    no_file_subtitle: '转到菜单 > "档案" > "保存到云端"',
    no_file_title: '保存文件到我的云端开始使用',
    save_file: {
      choose_action: '保存档案：',
      input_file_name: '另存新档为：',
      invalid_char: '无效字符:',
      save: '覆盖旧档案',
      save_new: '另存新档',
      storage_limit_exceeded: '您的云端储存空间已满。在储存新档案前，请先删除您不需要的档案。',
    },
    sort: {
      a_to_z: '名称: A - Z',
      most_recent: '最近',
      oldest: '最旧',
      z_to_a: '名称: Z - A',
    },
    title: '我的云端',
    upgrade: '升级',
  },
  noun_project_panel: {
    clear: '清除',
    elements: '元素',
    enjoy_shape_library: '新增百万图形资源，请尽情使用。',
    export_svg_title: '无法汇出 SVG',
    export_svg_warning: '专案中含有受知识产权法律的保护的Noun Project 物件，因此汇出时Beam Studio 会自动帮您排除这类型之物件，您仍可以透过储存场景(.beam 档) 的方式保留您的专案，请问是否要继续汇出？',
    learn_more: '更多资讯',
    login_first: '请先登入会员，完成后即可启用。',
    recent: '最近使用',
    search: '搜寻',
    shapes: '形状',
  },
  pass_through: {
    export: '导出到工作区域',
    exporting: '导出中...',
    guide_mark: '引导标记',
    guide_mark_desc: '引导标记将被刻为对齐艺术作品的参考点。',
    guide_mark_length: '长度：',
    guide_mark_x: 'X坐标：',
    height_desc: '设置每次工作区域部分的高度。',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: '如何为%(model)s设置广域雕刻？',
    object_length: '物体长度',
    ref_layer: '参考层',
    ref_layer_desc: '请注意，参考层的执行默认设置为0。它不会被执行，仅用于对齐参考。',
    ref_layer_name: '参考层',
    title: '广域雕刻',
    workarea_height: '工作区域（高度）：',
  },
  promark_connection_test: {
    description: '系统将运行约两分钟的红光操作，以检查连接是否稳定。',
    health: '稳定性：',
    healthy_description: '稳定性良好，但在雕刻过程中请避免大幅移动电脑。',
    res_0: '极好',
    res_1: '良好',
    res_2: '低',
    res_3: '过低',
    res_4: '极低',
    restart: '重新测试',
    start: '开始测试',
    stop: '停止测试',
    title: '连接稳定性测试',
    unhealthy_description: '稳定性过低，建议更换网络适配器或电源线。',
  },
  promark_settings: {
    angle: '角度',
    bulge: '桶形',
    field: '区域',
    galvo_configuration: 'Galvo 配置',
    mark: '标记',
    mark_parameters: '打标参数',
    offsetX: '偏移 X',
    offsetY: '偏移 Y',
    preview: '预览',
    red_dot: '红点',
    scale: '缩放',
    scaleX: '缩放 X',
    scaleY: '缩放 Y',
    skew: '倾斜',
    switchXY: '切换 X/Y',
    title: 'Promark设置',
    trapezoid: '梯形',
    workarea_hint: '您可以在“文档设置”中更改工作区域。',
    z_axis_adjustment: {
      section1: '调整Z轴高度以微调焦点。',
      title: 'Z轴调整',
      tooltip1: '尝试标记一个1x1厘米的方块以确认当前的焦距是否合适。',
    },
  },
  qr_code_generator: {
    error_tolerance: '容错级别',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: '反转背景色',
    placeholder: '输入链接或文本',
    preview: '预览',
    title: '二维码生成器',
  },
  rotary_settings: {
    circumference: '周长',
    extend_workarea: '扩展工作区域',
    mirror: '镜像',
    object_diameter: '对象直径',
    overlap_size: '重叠大小',
    rotary_scale: '旋转倍率',
    split_setting: '分割设置',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: '分割大小',
    type: '类型',
  },
  select_device: {
    auth_failure: '#811 认证失败',
    select_device: '选择机器',
    unable_to_connect: '#810 无法与机器创建稳定连接',
  },
  settings: {
    anti_aliasing: '抗锯齿',
    auto_connect: '自动选择唯一机器',
    auto_switch_tab: '自动切换图层和物件面板',
    autofocus_offset: '自动对焦高度补偿',
    autosave_enabled: '自动储存',
    autosave_interval: '储存间隔',
    autosave_number: '自动储存数',
    autosave_path: '档案夹',
    autosave_path_not_correct: '找不到指定的路径',
    blade_precut_position: '预切位置',
    blade_precut_switch: '旋转刀预切',
    blade_radius: '旋转刀半径',
    bottom_up: '由下往上',
    calculation_optimization: '路径计算加速',
    cancel: '取消',
    caption: '设置',
    check_updates: '自动检查',
    close: '关闭',
    confirm_remove_default: '将会删除默认机器',
    confirm_reset: '确认要重置 Beam Studio?',
    continuous_drawing: '连续绘制',
    curve_engraving_speed_limit: '曲面雕刻速度限制',
    custom_preview_height: '自訂预览高度',
    default_beambox_model: '预设文件设定',
    default_borderless_mode: '开盖模式预设',
    default_enable_autofocus_module: '自动对焦预设',
    default_enable_diode_module: '二极管激光预设',
    default_font_family: '预设字体',
    default_font_style: '预设字型',
    default_laser_module: '默认激光模块',
    default_machine: '默认机器',
    default_machine_button: '无',
    default_units: '预设单位',
    diode_offset: '二极管激光偏移值',
    diode_one_way_engraving: '二极管激光单向雕刻',
    diode_two_way_warning: '双向出光，速度较快时可能导致雕刻时位置误差，建议预先使用材料进行测试。',
    disabled: '不启用',
    done: '套用',
    enable_custom_backlash: '启用自訂背隙補償',
    enable_low_speed: '启用慢速移动',
    enable_uv_print_file: '启用 UV 打印文件',
    enabled: '启用',
    engraving_direction: '雕刻方向',
    fast_gradient: '速度优化',
    font_convert: '文本转路径',
    font_substitute: '自动替换字体',
    grouped_objects: '多个物件群组',
    groups: {
      ador_modules: 'Ador 模组',
      autosave: '自动储存',
      camera: '相机',
      connection: '连线',
      editor: '编辑器',
      engraving: '雕刻 (扫描)',
      general: '一般',
      mask: '工作范围剪裁',
      modules: '扩充模组',
      path: '路径 (线段)',
      privacy: '隐私',
      text_to_path: '文本',
      update: '软体更新',
    },
    guess_poke: '自动搜寻机器 IP',
    guides: '参考线',
    guides_origin: '参考线座标',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/en-us/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      uv_print_export: 'https://support.flux3dp.com/hc/en-us/articles/12320887477135',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: '高',
    image_downsampling: '点阵图预览品质',
    ip: '机器 IP 位址',
    keep_preview_result: '保留预览结果',
    language: '语言',
    loop_compensation: '封闭路径补偿',
    low: '低',
    low_laser_for_preview: '外框预览低激光',
    mask: '工作范围剪裁',
    medium: '中',
    module_offset_2w_ir: '2W 红外激光偏移',
    module_offset_10w: '10W 二极管激光偏移',
    module_offset_20w: '20W 二极管激光偏移',
    module_offset_printer: '打印偏移',
    none: '无',
    normal: '正常',
    notification_off: '关闭',
    notification_on: '开启',
    notifications: '显示桌面通知',
    off: '关',
    on: '开',
    preview_movement_speed: '相机预览移动速度',
    printer_advanced_mode: '打印机高级模式',
    remove_default_machine_button: '删除',
    reset: '重置所有设置',
    reset_now: '重置所有设置',
    segmented_engraving: '分段雕刻',
    share_with_flux: '与 FLUX 分享',
    simplify_clipper_path: '路径计算优化',
    single_object: '单一物件',
    tabs: {
      device: '机器',
      general: '一般',
    },
    text_path_calc_optimization: '路径计算优化',
    top_down: '由上往下',
    trace_output: '向量化及影像描图输出',
    update_beta: 'Beta',
    update_latest: '稳定版',
    vector_speed_constraint: '限制上限速度',
    wrong_ip_format: 'IP格式错误',
  },
  social_media: {
    facebook: '与 FLUX 用户交流，提出问题并学习技巧！',
    instagram: '获取最新灵感、优惠和赠品！',
    youtube: '查看 Beam Studio 教程与手工创意。',
  },
  support: {
    no_vcredist: '请安装 Visual C++ Redistributable 2015<br/>可以在flux3dp.com找到',
    no_webgl: '您的系统不支持 WebGL，建议您使用其他电脑开启 Mozu Studio',
  },
  topbar: {
    alerts: {
      add_content_first: '请先新增物件',
      door_opened: '请将门盖关闭以进入低激光模式。',
      fail_to_connect_with_camera: '#803 与机器相机建立连线时失败，请重新开启您的机器或是 Beam Studio ，如果此错误持续发生，请参考<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">此則引導</a>。',
      fail_to_get_door_status: '请确保门盖关闭以进入低激光模式。',
      fail_to_start_preview: '#803 启动相机预览失败，请重新开启您的机器或是 Beam Studio，如果此错误持续发生，请参考<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">此则引导</a>。',
      headtype_mismatch: '检测到错误的模块，',
      headtype_none: '未检测到模块，',
      headtype_unknown: '检测到未知模块，',
      install_correct_headtype: '请正确安装10W或20W雷射模块以进入低激光模式。',
      job_origin_unavailable: '设置工作原点需要固件版本4.3.5 / 5.3.5或更高。您现在要更新固件吗？',
      job_origin_warning: '您当前正在使用“当前位置”作为起点。请确保将激光头移动到正确的位置，以避免碰撞。',
      power_too_high: '功率过高',
      power_too_high_confirm: '知道了',
      power_too_high_msg: '激光管在高功率（70％ 以上）下耗损较快，使用低功率可以延长雷试管使用寿命。\n输入「知道了」以继续。',
      pwm_unavailable: '深度模式需要固件版本 4.3.4 / 5.3.4 或更高版本。您要立即更新固件吗？',
      QcleanScene: '您要清除绘图吗？<br/>这还将擦除您的撤消历史！',
      start_preview_timeout: '#803 启动相机预览时超时，请重新开启您的机器或是 Beam Studio ，如果此错误持续发生，请参考<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">此则引导</a>。',
    },
    frame_task: '外框预览',
    hint: {
      polygon: '按下 + / - 键以增加 / 减少边数。',
    },
    menu: {
      about: '关于 Beam Studio',
      about_beam_studio: '关于 Beam Studio',
      account: '帐号',
      add_new_machine: '新增或设定机器',
      align_center: '置中',
      anti_aliasing: '抗锯齿',
      auto_align: '自动对齐',
      borderless_mode: '开盖模式',
      bug_report: '错误回报',
      calibrate_beambox_camera: '校正相机',
      calibrate_beambox_camera_borderless: '校正相机 (开盖模组）',
      calibrate_camera_advanced: '校正相机（高级）',
      calibrate_diode_module: '校正二极管激光模组',
      calibrate_ir_module: '校准红外模块',
      calibrate_printer_module: '校准打印模块',
      calibration: '校准',
      camera_calibration_data: '相机校准数据',
      change_logs: '更新日志',
      clear_scene: '新建文件',
      close: '关闭视窗',
      commands: '指令',
      contact: '联络我们',
      copy: '复制',
      cut: '剪下',
      dashboard: '仪表版',
      decompose_path: '解散非连续路径',
      delete: '删除',
      design_market: 'Design Market',
      dev_tool: '侦错工具',
      disassemble_use: '解散图档',
      document_setting: '文件设定',
      document_setting_short: '文件设定',
      download_data: '下载数据',
      download_log: '汇出机器日志',
      download_log_canceled: '取消日志下载',
      download_log_error: '不明错误发生，请稍候再试一次',
      duplicate: '重製',
      edit: '编辑',
      example_files: '示例文件',
      export_BVG: 'BVG',
      export_flux_task: 'FLUX 工作',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: '汇出成...',
      export_UV_print: 'UV打印PDF',
      file: '档案',
      fit_to_window: '配合视窗尺寸',
      follow_us: '关注我们',
      forum: '社群论坛',
      group: '群組化',
      help: '说明',
      help_center: '说明中心',
      hide: '隐藏 Beam Studio',
      hideothers: '隐藏其他',
      image_crop: '裁剪',
      image_curve: '曲线',
      image_invert: '色彩反转',
      image_sharpen: '鋭化',
      image_stamp: '生成印章斜角',
      image_vectorize: '向量化',
      import_acrylic_focus_probe: '压克力对焦尺 - 3mm',
      import_ador_laser_example: 'Ador 激光范例',
      import_ador_printing_example_full: 'Ador 打印范例 - 全彩',
      import_ador_printing_example_single: 'Ador 打印范例 - 单色',
      import_beambox_2_example: 'Beambox II 范例',
      import_beambox_2_focus_probe: 'Beambox II 对焦尺 - 3mm',
      import_hello_beambox: 'Beambox 范例',
      import_hello_beamo: 'beamo 范例',
      import_hexa_example: 'HEXA 范例',
      import_material_printing_test: '材质打印测试',
      import_material_testing_cut: '材质切割测试档',
      import_material_testing_engrave: '材质雕刻测试',
      import_material_testing_line: '材质线段测试',
      import_material_testing_old: '材质雕刻测试 - 经典',
      import_material_testing_simple_cut: '材质切割测试 - 简易',
      import_promark_example: 'Promark 范例',
      import_promark_mopa_20w_color: 'MOPA 20W 色彩测试',
      import_promark_mopa_60w_color: 'MOPA 60W 色彩测试',
      import_promark_mopa_100w_color: 'MOPA 100W 色彩测试',
      keyboard_shortcuts: '快捷键',
      layer_color_config: '颜色设定',
      layer_setting: '图层',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Camera',
        cloud: 'Cloud',
        discover: 'Discover',
        hardware: 'Hardware',
        network: 'Network',
        player: 'Player',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'USB List',
      },
      machine_info: '机器资讯',
      machines: '机器',
      manage_account: '管理我的帐号',
      material_test: '材质测试',
      minimize: '最小化',
      my_account: '我的帐号',
      network_testing: '检测网路设定',
      object: '物件',
      offset: '位移複製',
      open: '打开',
      paste: '贴上',
      paste_in_place: '粘贴到原位置',
      path: '路径',
      photo_edit: '影像',
      preferences: '偏好设定',
      promark_color_test: 'Promark 色彩测试',
      questionnaire: '问卷回馈',
      quit: '离开',
      recent: '最近使用',
      redo: '重做',
      reload_app: '重新整理',
      reset: '重設',
      rotary_setup: '旋转轴设置',
      rotate: '旋轉',
      samples: '范例',
      save_as: '另存新档',
      save_scene: '储存',
      save_to_cloud: '保存到云端',
      scale: '縮放',
      service: '服务',
      set_as_default: '设为预设',
      show_gesture_tutorial: '触控手势说明',
      show_grids: '显示格线',
      show_layer_color: '显示图层颜色',
      show_rulers: '显示尺规',
      show_start_tutorial: '显示新手教学',
      show_ui_intro: '显示界面介绍',
      sign_in: '登入',
      sign_out: '登出',
      software_update: '软件更新',
      svg_edit: 'SVG',
      switch_to_beta: '切换至 Beta 版',
      switch_to_latest: '切换至稳定版',
      tools: {
        box_generator: '盒子生成器',
        code_generator: '条码生成器',
        material_test_generator: '材质测试生成器',
        title: '工具',
      },
      tutorial: 'Delta Family 打印教学',
      undo: '复原',
      ungroup: '解散群組',
      update: '检查更新',
      update_firmware: '韌體更新',
      upload_data: '上传数据',
      using_beam_studio_api: '使用 Beam Studio API',
      view: '检视',
      window: '视窗',
      zoom_in: '放大',
      zoom_out: '缩小',
      zoom_with_window: '自动配合视窗尺寸',
    },
    preview: '相机预览',
    preview_press_esc_to_stop: '按下 ESC 以停止相机预览。',
    rename_tab: '重命名标签页',
    select_machine: '选择机器',
    tag_names: {
      dxf: 'DXF 物件',
      ellipse: '椭圆',
      g: '群组',
      image: '影像',
      line: '線段',
      multi_select: '多个物件',
      no_selection: '无选择',
      pass_through_object: '广域雕刻物件',
      path: '路径',
      polygon: '多边形',
      rect: '矩形',
      svg: 'SVG 物件',
      text: '文本',
      text_path: '路径文本',
      use: '汇入物件',
    },
    task_preview: '工作预览',
    titles: {
      settings: '偏好设定',
    },
    untitled: '无标题',
  },
  topmenu: {
    credit: 'Beam Studio 的开发仰赖 <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> 开放原始码计画与其他<a target="_blank" href="https://flux3dp.com/credits/">开放原始码的软体</a>的协助才得以完成。',
    device: {
      download_log_canceled: '取消日志下载',
      download_log_error: '不明错误发生，请稍候再试一次',
      log: {
        usblist: 'USB 清单',
      },
      network_test: '网路检测',
    },
    file: {
      all_files: '所有文件',
      clear_recent: '清除历史纪录',
      converting: '转换成图片...',
      fcode_files: 'FLUX Code',
      import: '导入',
      jpg_files: 'JPG',
      label: '文件',
      path_not_exit: '此路径似乎已不存在于电脑中，请确认是否有更改档案位置。',
      png_files: 'PNG',
      save_fcode: '导出工作',
      save_jpg: '汇出 JPG',
      save_png: '汇出 PNG',
      save_scene: '导出场景',
      save_svg: '汇出 SVG',
      scene_files: 'Beam Studio 场景',
      svg_files: 'SVG',
    },
    ok: '确定',
    version: '版本',
  },
  tutorial: {
    ask_retry_calibration: '请问是否重新执行相机校正？',
    camera_calibration_failed: '相机校正失败',
    gesture: {
      click: '点按以选择物件。',
      drag: '拖曳以多选物件。',
      hold: '按住一段时间打开选单。',
      pan: '双指平移让画布随着您的手指方向移动。',
      zoom: '双指靠拢以缩小，或双指分开来放大画布。',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: '寻找机器中',
    needNewInterfaceTutorial: '请问是否需要为您介绍 Beam Studio 的新介面？ <br/>（您也可以先跳过，并在之后从「说明」>「显示介面介绍」进行介绍。）',
    needNewUserTutorial: '请问您是否需要 Beam Studio 的教学？<br/>（您也可以先跳过，并在之后从「说明」>「显示新手教学」进行教学。）',
    newInterface: {
      add_new_layer: '新增图层',
      align_controls: '排列控制项',
      basic_shapes: '基本几何形状',
      camera_preview: '相机预览',
      drag_to_sort: '拖曳以排序',
      end_alert: '请问您是否确定要结束新介面介绍？',
      flip: '翻转',
      group_controls: '群组、解散群组',
      layer_controls: '右键点击以呼叫图层控制功能：\n复制、合并、锁定、删除图层',
      object_actions: '物件操作',
      pen_tool: '钢笔工具',
      rename_by_double_click: '双击以重新命名',
      select_image_text: '选取、图片、文字工具',
      select_machine: '选择机器',
      shape_operation: '几何图形操作',
      start_work: '开始工作',
      switch_between_layer_panel_and_object_panel: '在图层面板与物件面板间切换',
    },
    newUser: {
      add_new_layer: '新增图层',
      adjust_focus: '2. 调整焦距',
      close_cover: '3. 将门盖关上',
      drag_to_draw: '拖曳以生成图形',
      draw_a_circle: '画一个圆',
      draw_a_rect: '画一个方形',
      end_alert: '请问您是否确定要结束教学？',
      end_preview_mode: '结束相机预览模式',
      infill: '设定填充',
      please_select_wood_cutting: '请选择「木板 - 切割」参数。',
      please_select_wood_engraving: '请选择「木板 - 刻印」参数。',
      preview_the_platform: '预览工作平台',
      put_wood: '1. 放进木板',
      send_the_file: '送出工作',
      set_preset_wood_cut: '設定參數：木板 - 切割',
      set_preset_wood_engraving: '設定參數：木板 - 刻印',
      switch_to_layer_panel: '切换到图层面板',
      switch_to_object_panel: '切换到物件面板',
      switch_to_preview_mode: '切换到相机预览模式',
    },
    next: '下一步',
    retry: '重试',
    set_connection: '连线设定',
    skip: '跳过教学',
    skip_tutorial: '已跳过新手教学，您可以在「说明」>「显示新手教学」再次启动新手教学。',
    suggest_calibrate_camera_first: '我们建议使用者在第一次使用机器时校正相机，并在每次使用时将平台对焦，以取得最好的效果。<br/>请问您要现在进行相机校正吗？<br/>（您也可以先跳过，并在之后从上方选单「机器」>「您的机器名称」>「校正相机」进行校正。）',
    tutorial_complete: '介绍完毕，开始创作吧！',
    unable_to_find_machine: '无法找到可用于新手教学的机器，是否进行要进行连线设定、重试或是跳过教学？',
    welcome: '欢迎使用',
  },
  update: {
    cannot_reach_internet: '#823 服务器无法连接<br/>请确认网络连接',
    download: '在线更新',
    firmware: {
      caption: '有新的机器固件更新',
      confirm: '上传',
      firmware_too_old_update_by_sdcard: '固件版本太旧，请使用SD卡更新固件。',
      force_update_message: '#814 请将您的机器更新至最新的固件版本。',
      latest_firmware: {
        cant_get_latest: '无法取得最新版本固件资讯。',
        caption: '固件更新',
        message: '固件已经是最新版本',
        still_update: '文件更新',
      },
      message_pattern_1: '"%s" 有新的固件更新。',
      message_pattern_2: '%s 固件 v%s 可使用 - 你的版本为 v%s.',
      too_old_for_web: '你韧体的版本为 v3.5.1。\n欲使用线上版的 Beam Studio 请更新至最新韧体。',
      update_fail: '#822 更新失败',
      update_success: '固件更新上传成功',
      upload_file: '固件上传',
    },
    install: '下载',
    later: '稍候',
    preparing: '准备中...',
    release_note: '版本消息:',
    skip: '跳过此版本',
    software: {
      available_switch: 'Beam Studio v%s 现可提供切换，你的版本为 v%s ，是否要切换至此此版本？',
      available_update: 'Beam Studio v%s 现可提供下载，你的版本为 v%s ，是否要下载更新？',
      caption: 'Beam Studio 有新的软件更新',
      check_update: '检查更新',
      checking: '检查更新中',
      downloading: '正在背景下载中，您可以按确定以继续您的工作。',
      install_or_not: '已准备好更新，是否重新启动以套用更新？',
      no: '否',
      no_response: '无法连接到伺服器，请确认您目前的网路状态。',
      not_found: 'Beam Studio 已是最新版本。',
      switch_or_not: '已准备完成，是否重新启动以切换？',
      switch_version: '版本切换',
      switch_version_not_found: '無法找到可切換的版本',
      update_for_ador: '当前的软件版本 %s 较旧，请下载 Beam Studio for Ador 的最新版本。',
      yes: '是',
    },
    update: '更新',
    updating: '更新中...',
    upload: '上传',
  },
  web_cam: {
    no_device: '无法检测到摄像设备。请重新连接摄像头，然后重试。',
    no_permission: 'Beam Studio 没有权限访问相机。请确保在浏览器设置或系统设置中已授予 Beam Studio 权限。',
  },
  z_speed_limit_test: {
    alert_before: '在开始曲面雕刻之前，系统将进行负载测试，以防止因过重导致步进丢失。',
    alert_failed: '当前对象的重量超出了当前Z轴速度的负载限制。请在机器面板中降低Z轴速度，或更换雕刻对象后再重新测试。',
    ignore: '忽略',
    retest: '重新测试',
    testing: '正在执行负载测试...',
  },
};

export default lang;
